import { PRODUCTUUID } from '../utils/productUUIDConstants';
export var URL;
(function (URL) {
    URL["ACCEPT"] = "/accept";
    URL["ACCOUNTS"] = "/v1/payment/accounts";
    URL["ACCOUNT_EMAIL"] = "/v1/account/email";
    URL["ACCOUNT_EMAIL_OTP"] = "/v1/account/email/otp";
    URL["ACCOUNT_PHONE"] = "/v1/account/phone";
    URL["ACCOUNT_PHONE_OTP"] = "/v1/account/phone/otp";
    URL["ACCOUNT_PREFERENCE"] = "/v2/accounts";
    URL["ANALYTICS"] = "/v1/analytics";
    URL["APPLICATIONS"] = "/v1/applications";
    URL["APPS"] = "/v2/applications";
    URL["AUTHORIZED_ACCOUNTS"] = "/v1/authorizedAccounts";
    URL["AUTHORIZED_USER"] = "/v1/authorizedUser";
    URL["AUTH_APPLICATION"] = "/v1/authApplication";
    URL["CANCEL"] = "/cancel";
    URL["CARD_ENDPOINT"] = "/v1/card";
    URL["CHECKOUT"] = "/v2/consumer/checkout";
    URL["CHECK_APPLICATION"] = "/checkApplication";
    URL["CONFIRM_PHONE"] = "/v1/consumer/phone";
    URL["CONSUMER_LOGOUT"] = "/v1/consumer/logout";
    URL["CONSUMER_MFA_PIN"] = "/v1/consumer/mfa/pin";
    URL["CONSUMER_PIN_VERIFY"] = "/v1/consumer/pin/verify";
    URL["CREATE_PIN"] = "/v1/consumer/pin";
    URL["FIND_ACCOUNT"] = "/v1/consumer/findby";
    URL["HOME"] = "/home";
    URL["HOME_API"] = "/v2/home";
    URL["IDV_STATUS"] = "/v1/consumer/idv";
    URL["IS_CONVERTED_USER"] = "/v1/account/conversion";
    URL["INITIALSC"] = "/v2/application/sc";
    URL["LANGUAGE"] = "/v1/consumer/language";
    URL["LINK_TOKEN"] = "/v1/payment/link-token";
    URL["LOAD_ACTIVITY"] = "/v1/activity/fetch";
    URL["LOAD_PRODUCT_ACCOUNT"] = "/v2/productAccount";
    URL["LOGIN"] = "/v1/consumer/login";
    URL["OFFER"] = "/offer";
    URL["PAYMENT"] = "/v2/payment";
    URL["PAYMENT_PLAN"] = "/v2/payment/plan";
    URL["PIN_FORGOT"] = "/v1/consumer/pin/forgot";
    URL["PROMPT"] = "/v2/application/prompt";
    URL["REJECTION_PDF"] = "/v2/applications";
    URL["RESET_PIN"] = "/v1/account/pin";
    URL["REWARDS_REDEEM"] = "/v1/rewards/redeem";
    URL["REWARDS_DETAILS"] = "/content";
    URL["PACT_REWARD_DETAILS"] = "/v1/{productAccountUUID}/reward/details";
    URL["STATEMENTS"] = "/v1/payment/statements";
    URL["SUBSCRIBE"] = "/v1/subscribe";
    URL["UPDATE_ADDRESS"] = "/v1/account/address";
    URL["VERIFY"] = "/v2/consumer/login/otp";
    URL["VERIFY_DOB"] = "/v1/consumer/mfa/dob";
    URL["LINK_ACCOUNT"] = "/v1/membership";
    URL["LINK_ACCOUNT_V2"] = "/v2/membership";
    URL["GET_MEMBERSHIP"] = "/v1/membership/{productAccountUUID}";
    URL["UPDATE_MEMBERSHIP"] = "/v1/membership/{productAccountUUID}";
})(URL || (URL = {}));
export var ImprintConstants;
(function (ImprintConstants) {
    ImprintConstants["ImprintPrivacy"] = "https://www.imprint.co/en-us/legal/privacy-notice";
    ImprintConstants["ImprintPrivacyES"] = "https://www.imprint.co/es-us/legal/privacy-notice";
    ImprintConstants["ImprintEsign"] = "https://www.imprint.co/en-us/legal/esign-policy";
    ImprintConstants["ImprintEsignES"] = "https://www.imprint.co/es-us/legal/esign-policy";
    ImprintConstants["ImprintTos"] = "https://www.imprint.co/terms-of-service.pdf";
    ImprintConstants["ImprintZendesk"] = "https://imprintpayments.zendesk.com";
    ImprintConstants["ImprintSupport"] = "https://www.imprint.co/help";
    ImprintConstants["PaymentConsent"] = "https://imprint.co/legal/payment-consent";
    ImprintConstants["PaymentConsentES"] = "https://imprint.co/es-us/legal/payment-consent";
    ImprintConstants["UfreezeLink"] = "https://www.transunion.com/blog/credit-advice/what-should-i-do-after-i-freeze-my-credit";
    ImprintConstants["DownloadLinkDisplay"] = "app.imprint.co/download";
    ImprintConstants["PlaidOauthRedirect"] = "https://app.imprint.co/plaid/link/oauth";
    ImprintConstants["PlaidOauthRedirectStg"] = "https://app.stg.imprint.co/plaid/link/oauth";
    ImprintConstants["Transunion"] = "https://www.transunion.com/";
    ImprintConstants["HelpDownload"] = "https://help.imprint.co/download";
    ImprintConstants["Help"] = "https://help.imprint.co";
})(ImprintConstants || (ImprintConstants = {}));
export var IMPRINT_DOWNLOAD_LINK = 'https://' + ImprintConstants.DownloadLinkDisplay;
export var RESPONSE_CODE;
(function (RESPONSE_CODE) {
    RESPONSE_CODE[RESPONSE_CODE["NO_CONTENT"] = 204] = "NO_CONTENT";
})(RESPONSE_CODE || (RESPONSE_CODE = {}));
export var ACTION_TYPE;
(function (ACTION_TYPE) {
    ACTION_TYPE["SHOW_OFFER_SCREEN"] = "show_offer_screen";
    ACTION_TYPE["SHOW_EXPIRED_APPLICATION_SCREEN"] = "show_expired_application_screen";
    ACTION_TYPE["SHOW_REJECTION_SCREEN"] = "show_rejection_screen";
})(ACTION_TYPE || (ACTION_TYPE = {}));
export var SIGN_UP_ELIGIBILITY_STATUS;
(function (SIGN_UP_ELIGIBILITY_STATUS) {
    SIGN_UP_ELIGIBILITY_STATUS["ELIGIBLE"] = "eligible";
    SIGN_UP_ELIGIBILITY_STATUS["REJECTED_WITHIN_COOLDOWN_WINDOW"] = "rejectedWithinCooldownWindow";
    SIGN_UP_ELIGIBILITY_STATUS["REJECTED_AFTER_COOLDOWN_WINDOW"] = "rejectedAfterCooldownWindow";
    SIGN_UP_ELIGIBILITY_STATUS["REAPPLY"] = "reapply";
    SIGN_UP_ELIGIBILITY_STATUS["FROZEN"] = "frozen";
})(SIGN_UP_ELIGIBILITY_STATUS || (SIGN_UP_ELIGIBILITY_STATUS = {}));
export var ACTIVATE_PHYSICAL_CARD_RESPONSE_CODE;
(function (ACTIVATE_PHYSICAL_CARD_RESPONSE_CODE) {
    ACTIVATE_PHYSICAL_CARD_RESPONSE_CODE[ACTIVATE_PHYSICAL_CARD_RESPONSE_CODE["INCORRECT_LAST_4"] = 310] = "INCORRECT_LAST_4";
})(ACTIVATE_PHYSICAL_CARD_RESPONSE_CODE || (ACTIVATE_PHYSICAL_CARD_RESPONSE_CODE = {}));
export var ActivityType;
(function (ActivityType) {
    ActivityType["auth"] = "AUTH";
    ActivityType["transaction"] = "TRANSACTION";
    ActivityType["dispute"] = "DISPUTE";
    ActivityType["payment"] = "PAYMENT";
    ActivityType["refund"] = "REFUND";
    ActivityType["reward"] = "REWARD";
    ActivityType["withdraw"] = "WITHDRAW";
    ActivityType["funding"] = "FUNDING";
    ActivityType["latePaymentFee"] = "LATE_PAYMENT_FEE";
    ActivityType["rejectedPaymentFee"] = "REJECTED_PAYMENT_FEE";
    ActivityType["fee"] = "FEE";
    ActivityType["interest"] = "INTEREST";
    ActivityType["paymentCheck"] = "PAYMENT_CHECK";
    ActivityType["feePositiveAdjustment"] = "FEE_POSTIVE_ADJUSTMENT";
    ActivityType["feeNegativeAdjustment"] = "FEE_NEGATIVE_ADJUSTMENT";
    ActivityType["interestPositiveAdjustment"] = "INTEREST_POSTIVE_ADJUSTMENT";
    ActivityType["interestNegativeAdjustment"] = "INTEREST_NEGATIVE_ADJUSTMENT";
    ActivityType["rewardWithdrawalACH"] = "REWARD_WITHDRAWAL_ACH";
    ActivityType["rewardWithdrawalCheck"] = "REWARD_WITHDRAWAL_CHECK";
    ActivityType["interestCharge"] = "INTEREST_CHARGE";
    ActivityType["credit"] = "CREDIT";
    ActivityType["oneTime"] = "ONE_TIME";
    ActivityType["offer"] = "OFFER";
    ActivityType["signup"] = "SIGNUP";
})(ActivityType || (ActivityType = {}));
export var PAYMENT_STATUS;
(function (PAYMENT_STATUS) {
    PAYMENT_STATUS["scheduled"] = "SCHEDULED";
    PAYMENT_STATUS["pending"] = "PENDING";
    PAYMENT_STATUS["confirmed"] = "CONFIRMED";
    PAYMENT_STATUS["rejected"] = "REJECTED";
    PAYMENT_STATUS["canceled"] = "CANCELED";
    PAYMENT_STATUS["submitted"] = "SUBMITTED";
})(PAYMENT_STATUS || (PAYMENT_STATUS = {}));
export var validAccountLinkMerchants = [PRODUCTUUID.TURKISH, PRODUCTUUID.EB];
