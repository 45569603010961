var _a, _b, _c, _d, _e, _f, _g;
import { useMemo } from 'react';
import { useAppSelector } from '../app/hooks';
import { selectLanguage } from '../app/languageSelectors';
import { LanguageCode } from './languageConstants';
import { PRODUCTUUID } from './productUUIDConstants';
export var NOT_FOUND = 'not_found';
/**
 * Hook that provides replaceMerchantContent, a function that takes a string input
 * and returns modified version with merchant-specific copy
 * (eg. 'My Miles&Smiles' -> 'My Adventure Rewards')
 *
 * @param productUUID - used to get the merchant-specific dynamic content
 * @returns - replaceMerchantContent: (string) -> string
 */
export function usePartnerLanguage(productUUID) {
    var language = useAppSelector(selectLanguage);
    var turkish = useMemo(function () { return ({
        programName: language.tkProgramName,
        prefixedProgramName: "a ".concat(language.tkProgramName),
        currency: language.tkCurrency,
        cardName: language.tkCardName,
        tooltipPart2: language.findMembershipBody2,
    }); }, [language]);
    var eddiebauer = useMemo(function () { return ({
        programName: language.ebProgramName,
        prefixedProgramName: "an ".concat(language.tkProgramName),
        currency: language.ebCurrency,
        cardName: language.ebCardName,
        tooltipPart2: '.',
    }); }, [language]);
    var partnerDynamicContent = useMemo(function () {
        var _a;
        return ((_a = {},
            _a[PRODUCTUUID.TURKISH] = turkish,
            _a[PRODUCTUUID.EB] = eddiebauer,
            _a)[productUUID]);
    }, [productUUID]);
    var keyOrder = [
        'cardName',
        'prefixedProgramName',
        'programName',
        'currency',
        'tooltipPart2',
    ];
    var memo = {};
    // TODO: [TD] Deprecate this logic, the content varies too much from merchant to merchant
    var replaceMerchantContent = function (before) {
        if (!partnerDynamicContent)
            return before;
        if (!(before in memo)) {
            var after = before;
            for (var _i = 0, keyOrder_1 = keyOrder; _i < keyOrder_1.length; _i++) {
                var key = keyOrder_1[_i];
                after = after.replace(turkish[key], partnerDynamicContent[key]);
            }
            memo[before] = after;
        }
        return memo[before];
    };
    return replaceMerchantContent;
}
var turkishMembershipInfo = {
    partnerMembershipURL: (_a = {},
        _a[LanguageCode.English] = 'https://www.turkishairlines.com/en-int/',
        _a[LanguageCode.Spanish] = 'https://www.turkishairlines.com/es-int/',
        _a),
    partnerMembershipSignUpURL: (_b = {},
        _b[LanguageCode.English] = 'https://www.turkishairlines.com/en-int/miles-and-smiles/sign-up-form/',
        _b[LanguageCode.Spanish] = 'https://www.turkishairlines.com/es-int/miles-and-smiles/sign-up-form/',
        _b),
    partnerContactSupportURL: (_c = {},
        _c[LanguageCode.English] = 'https://www.turkishairlines.com/en-int/any-questions/customer-relations/feedback/',
        _c[LanguageCode.Spanish] = 'https://www.turkishairlines.com/es-int/any-questions/customer-relations/feedback/',
        _c),
};
var eddiebauerMembershipInfo = {
    partnerMembershipURL: (_d = {},
        _d[LanguageCode.English] = 'https://www.eddiebauer.com/acc-login',
        _d[LanguageCode.Spanish] = 'https://www.eddiebauer.com/acc-login',
        _d),
    partnerMembershipSignUpURL: (_e = {},
        _e[LanguageCode.English] = 'https://www.eddiebauer.com/signup',
        _e[LanguageCode.Spanish] = 'https://www.eddiebauer.com/signup',
        _e),
    partnerContactSupportURL: (_f = {},
        _f[LanguageCode.English] = 'https://www.eddiebauer.com/contactus',
        _f[LanguageCode.Spanish] = 'https://www.eddiebauer.com/contactus',
        _f),
};
export var PartnerMembershipInfos = (_g = {},
    _g[PRODUCTUUID.TURKISH] = turkishMembershipInfo,
    _g[PRODUCTUUID.EB] = eddiebauerMembershipInfo,
    _g);
