var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ImpButton, ImpContainer, ImpFlex, ImpPad, ImpSpacer, ImpText, colors, } from '@imprint-payments/imprint-ui';
import { RewardRedemptionType } from '../../types/RewardRedemptionType';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { RewardsRedemptionPage, setRewardsRedemptionPage, } from '../../app/rewardsRedemptionSlice';
import { formatPaymentAccount } from '../../utils/formatUtils';
import { PaymentAccountStatus } from '../dashboard/Dashboard';
export function RewardsDestination(_a) {
    var paymentAccount = _a.paymentAccount, address = _a.address, _b = _a.cannotRedeem, cannotRedeem = _b === void 0 ? false : _b, selectedRedemptionType = _a.selectedRedemptionType;
    var dispatch = useAppDispatch();
    var language = useAppSelector(function (state) { return state.languageSelector.languageLocalization; });
    var handleEditBankClick = function () {
        dispatch(setRewardsRedemptionPage(RewardsRedemptionPage.UpdateBank));
    };
    var handleEditAddressClick = function () {
        dispatch(setRewardsRedemptionPage(RewardsRedemptionPage.UpdateAddress));
    };
    if (cannotRedeem) {
        return (_jsxs(ImpContainer, __assign({ textAlign: "center" }, { children: [_jsx(ImpSpacer, { height: "l" }), _jsx(ImpFlex, __assign({ direction: "row", justify: "center" }, { children: _jsx(ImpText, __assign({ typography: "body3", color: colors.content.onBackgroundVariant }, { children: language.cannotRedeem })) }))] })));
    }
    switch (selectedRedemptionType) {
        case RewardRedemptionType.StatementCredit:
            return (_jsxs(ImpContainer, __assign({ textAlign: "center" }, { children: [_jsx(ImpSpacer, { height: "l" }), _jsx(ImpFlex, __assign({ direction: "row", justify: "center" }, { children: _jsx(ImpText, __assign({ typography: "body3", color: colors.content.onBackgroundVariant }, { children: language.disclosurePayOffBalance })) }))] })));
        case RewardRedemptionType.BankWithdrawal:
            return (_jsx(ImpContainer, __assign({ width: "100%" }, { children: _jsx(ImpPad, __assign({ padding: "12px" }, { children: _jsxs(ImpFlex, __assign({ direction: "column", align: "center" }, { children: [_jsx(ImpText, __assign({ typography: "label1", color: colors.content.onBackgroundVariant }, { children: language.withdrawToBankLabel })), _jsx(ImpFlex, __assign({ direction: "column", align: "center" }, { children: paymentAccount.status !==
                                    PaymentAccountStatus.Approved ? (_jsx(ImpText, { children: language.needToLinkBankPrompt })) : (_jsxs(_Fragment, { children: [_jsx(ImpText, __assign({ typography: "body1" }, { children: formatPaymentAccount(paymentAccount, language) })), _jsx(ImpText, __assign({ color: "var(--merchant-color)" }, { children: _jsx(ImpButton, __assign({ buttonStyle: "branded", variant: "inline", onClick: handleEditBankClick }, { children: language.editBank })) }))] })) }))] })) })) })));
        case RewardRedemptionType.MailCheck:
            return (_jsx(ImpContainer, __assign({ width: "100%" }, { children: _jsx(ImpPad, __assign({ padding: "12px" }, { children: _jsxs(ImpFlex, __assign({ direction: "column", align: "center" }, { children: [_jsx(ImpText, __assign({ typography: "label1", color: colors.content.onBackgroundVariant }, { children: language.mailCheckToLabel })), _jsxs(ImpFlex, __assign({ direction: "column", align: "center" }, { children: [formatMultiLineAddress(address), _jsx(ImpButton, __assign({ buttonStyle: "branded", variant: "inline", onClick: handleEditAddressClick }, { children: language.editAddress }))] }))] })) })) })));
        default:
            return _jsx(_Fragment, {});
    }
}
var formatMultiLineAddress = function (address) {
    return (_jsx(ImpContainer, { children: _jsxs(ImpFlex, __assign({ direction: "column", align: "center" }, { children: [_jsx(ImpText, __assign({ typography: "body1" }, { children: address.streetLine1 })), address.streetLine2 && (_jsx(ImpText, __assign({ typography: "body1" }, { children: address.streetLine2 }))), _jsx(ImpText, __assign({ typography: "body1" }, { children: "".concat(address.city, ", ").concat(address.state, " ").concat(address.zipCode) }))] })) }));
};
