var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { ImpBreadcrumbs, ImpContainer, ImpFlex, ImpSpacer } from '@imprint-payments/imprint-ui';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { selectSelectedProductAccountUUID } from '../../app/homeSelectors';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectLanguage } from '../../app/languageSelectors';
import HeaderServicing from '../../components/HeaderServicing/HeaderServicing';
import { navigateToAccountPage } from '../../utils/functionUtils';
import { PAGE } from '../../utils/routeConstant';
import { UpcomingPayments } from '../payments/UpcomingPayments';
import { CardBalanceAction } from './CardBalanceAction';
import { CardBalanceRing } from './CardBalanceRing';
import { CardBalanceStats } from './CardBalanceStats';
import { CardBalanceCardTerms } from './CardBalanceTerms';
import { Statements } from './Statements';
export function CardBalanceDetailsPage() {
    var dispatch = useAppDispatch();
    var navigate = useNavigate();
    var language = useAppSelector(selectLanguage);
    var selectedProductAccountUUID = useAppSelector(selectSelectedProductAccountUUID);
    useEffect(function () {
        if (!selectedProductAccountUUID) {
            navigate(PAGE.HOME);
        }
    }, [selectedProductAccountUUID]);
    return (_jsxs(_Fragment, { children: [_jsx(HeaderServicing, {}), _jsx(ImpBreadcrumbs, { breadcrumbs: [language.cardBalance], onBackClick: function () { return navigateToAccountPage(dispatch, navigate); }, onCancelClick: function () { return navigateToAccountPage(dispatch, navigate); } }), _jsx(ImpSpacer, { height: "2xl", mobileHeight: "xl" }), _jsx(CardBalanceDetails, {}), _jsx(ImpSpacer, { height: "2xl", mobileHeight: "xl" })] }));
}
function CardBalanceDetails() {
    return (_jsx(ImpFlex, __assign({ fluid: true, justify: "center" }, { children: _jsx(ImpContainer, __assign({ width: "min(100% - 32px, 1140px)" }, { children: _jsxs(ImpFlex, __assign({ gap: "8px", mobileGap: "s", mobileDirection: "column" }, { children: [_jsxs(ImpFlex, __assign({ direction: "column", fluid: true, gap: "8px" }, { children: [_jsx(CardBalanceRing, {}), _jsx(CardBalanceAction, {}), _jsx(CardBalanceStats, {}), _jsx(UpcomingPayments, {})] })), _jsxs(ImpFlex, __assign({ fluid: true, direction: "column", gap: "8px" }, { children: [_jsx(CardBalanceCardTerms, {}), _jsx(Statements, {})] }))] })) })) })));
}
