var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { colors, ImpCallout, ImpContainer, ImpFlex, ImpSpacer, ImpText, } from '@imprint-payments/imprint-ui';
import { selectMaintenanceWindowFeatureFlags } from '../../app/featureFlagSelectors';
import { useAppSelector } from '../../app/hooks';
import { selectLanguage, selectLanguageCode } from '../../app/languageSelectors';
export function MaintenanceIndicator() {
    var _a = useAppSelector(selectMaintenanceWindowFeatureFlags), isMaintenanceIndicatorEnabled = _a.isMaintenanceIndicatorEnabled, maintenanceWindowStartEpochMS = _a.maintenanceWindowStartEpochMS, maintenanceWindowEndEpochMS = _a.maintenanceWindowEndEpochMS;
    var currentTime = Date.now();
    var languageCode = useAppSelector(selectLanguageCode);
    if (!isMaintenanceIndicatorEnabled ||
        !maintenanceWindowStartEpochMS ||
        !maintenanceWindowEndEpochMS) {
        return null;
    }
    // Calculate maintenance status
    var maintenanceStatus = (function () {
        if (currentTime < maintenanceWindowStartEpochMS) {
            return 'upcoming';
        }
        else if (currentTime >= maintenanceWindowStartEpochMS &&
            currentTime <= maintenanceWindowEndEpochMS) {
            return 'inProgress';
        }
        return 'completed';
    })();
    var _b = formatMaintenanceTime({
        maintenanceWindowStartEpochMS: maintenanceWindowStartEpochMS,
        maintenanceWindowEndEpochMS: maintenanceWindowEndEpochMS,
        locale: languageCode,
    }), date = _b.date, startTime = _b.startTime, endTime = _b.endTime, duration = _b.duration;
    if (maintenanceStatus === 'completed') {
        return null;
    }
    return (_jsx(ImpContainer, __assign({ width: "100%" }, { children: _jsxs(ImpFlex, __assign({ fluid: true, align: "center", direction: "column" }, { children: [_jsx(ImpContainer, __assign({ width: "100%", mobileWidth: "calc(100% - 48px)" }, { children: _jsx(ImpCallout, __assign({ variant: "info" }, { children: _jsx(ImpContainer, __assign({ width: "100%" }, { children: _jsx(ImpFlex, __assign({ direction: "column", gap: "4px" }, { children: _jsx(MaintenanceMessage, { status: maintenanceStatus, date: date, startTime: startTime, endTime: endTime, duration: duration }) })) })) })) })), _jsx(ImpSpacer, { height: "l" })] })) })));
}
function UpcomingIndicator(_a) {
    var date = _a.date, startTime = _a.startTime, duration = _a.duration;
    var language = useAppSelector(selectLanguage);
    var maintenanceWindowTitle = language.maintenanceWindowTitle, maintenanceWindowDescription = language.maintenanceWindowDescription;
    var localizedDuration = duration
        .replace('{hours}', language.hours)
        .replace('{minutes}', language.minutes)
        .replace('{hour}', language.hour)
        .replace('{minute}', language.minute);
    return (_jsxs(_Fragment, { children: [_jsx(ImpText, __assign({ color: colors.content.onSurfaceInfo, typography: "label2" }, { children: maintenanceWindowTitle
                    .replace('{duration}', localizedDuration)
                    .replace('{day}', date)
                    .replace('{time}', startTime) })), _jsx(ImpText, __assign({ color: colors.content.onSurfaceInfo }, { children: maintenanceWindowDescription }))] }));
}
function formatMaintenanceTime(_a) {
    var _b;
    var maintenanceWindowStartEpochMS = _a.maintenanceWindowStartEpochMS, maintenanceWindowEndEpochMS = _a.maintenanceWindowEndEpochMS, _c = _a.timeZone, timeZone = _c === void 0 ? Intl.DateTimeFormat().resolvedOptions().timeZone : _c, _d = _a.locale, locale = _d === void 0 ? 'en-US' : _d;
    var startDate = new Date(maintenanceWindowStartEpochMS);
    var endDate = new Date(maintenanceWindowEndEpochMS);
    var durationMinutes = Math.round((maintenanceWindowEndEpochMS - maintenanceWindowStartEpochMS) / (1000 * 60));
    var timeZoneName = ((_b = new Intl.DateTimeFormat(locale, {
        timeZoneName: 'short',
        timeZone: timeZone,
    })
        .formatToParts(startDate)
        .find(function (part) { return part.type === 'timeZoneName'; })) === null || _b === void 0 ? void 0 : _b.value) || timeZone;
    // Format duration into hours and minutes
    var formatDuration = function (minutes) {
        if (minutes < 60) {
            return "".concat(minutes, " {minutes}");
        }
        var hours = Math.floor(minutes / 60);
        var remainingMinutes = minutes % 60;
        if (remainingMinutes === 0) {
            return hours === 1 ? '1 {hour}' : "".concat(hours, " {hours}");
        }
        var hourText = hours === 1 ? '1 {hour}' : "".concat(hours, " {hours}");
        return "".concat(hourText, " ").concat(remainingMinutes, " {minutes}");
    };
    return {
        date: startDate.toLocaleDateString(locale, {
            month: 'long',
            day: 'numeric',
            timeZone: timeZone,
        }),
        startTime: startDate.toLocaleTimeString(locale, {
            hour: 'numeric',
            minute: '2-digit',
            hour12: true,
            timeZone: timeZone,
        }) +
            ' ' +
            timeZoneName,
        endTime: endDate.toLocaleTimeString(locale, {
            hour: 'numeric',
            minute: '2-digit',
            hour12: true,
            timeZone: timeZone,
        }) +
            ' ' +
            timeZoneName,
        timeZone: timeZone,
        duration: formatDuration(durationMinutes),
    };
}
function MaintenanceMessage(_a) {
    var status = _a.status, date = _a.date, startTime = _a.startTime, endTime = _a.endTime, duration = _a.duration;
    switch (status) {
        case 'upcoming':
            return _jsx(UpcomingIndicator, { date: date, startTime: startTime, duration: duration });
        // if some day we want to show maintenacne in progress
        case 'inProgress':
            return _jsx(UpcomingIndicator, { date: date, startTime: startTime, duration: duration });
        default:
            return null;
    }
}
