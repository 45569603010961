var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { formatDisplayAmount } from '@imprint-payments/imprint-lib';
import { colors, Icons, ImpActivity, ImpButton, ImpContainer, ImpFlex, ImpIcon, } from '@imprint-payments/imprint-ui';
import { useEffect, useMemo, useState } from 'react';
import { EVENT } from '../../analytics/analyticsConsts';
import { ACTIVITY_FEED_MAX, loadActivity, } from '../../api/loadActivity/loadActivity';
import { FlyoutContext, setFlyoutActivity, setFlyoutContext, setIsFlyoutVisible, } from '../../app/flyoutSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { ANALYTICS } from '../../firebase/firebase';
import { getIcon } from '../ActivityDetailsHandler/activityDetailsHelpers';
import { loadBadge } from './activityFeedConsts';
import DownloadActivityFeed from './DownloadActivityFeed';
var transactionDetails = ['AUTH', 'TRANSACTION'];
var paymentDetails = ['PAYMENT'];
var rewardDetails = ['REWARD', 'ONE_TIME', 'OFFER', 'SIGNUP'];
var filterOptions = [
    'All',
    'Payments',
    'Purchases',
    'Redemptions',
    'Offers',
    'Refunds',
    'Disputes',
];
var filterEventMap = {
    'Payments': EVENT.ACTIVITY_FILTER_PAYMENTS_CLICKED,
    'Purchases': EVENT.ACTIVITY_FILTER_PURCHASES_CLICKED,
    'Redemptions': EVENT.ACTIVITY_FILTER_REDEMPTIONS_CLICKED,
    'Offers': EVENT.ACTIVITY_FILTER_OFFERS_CLICKED,
    'Refunds': EVENT.ACTIVITY_FILTER_REFUNDS_CLICKED,
    'Disputes': EVENT.ACTIVITY_FILTER_DISPUTES_CLICKED,
    'All': EVENT.ACTIVITY_FILTER_ALL_CLICKED,
};
export default function ActivityFeed() {
    var dispatch = useAppDispatch();
    var language = useAppSelector(function (state) { return state.languageSelector.languageLocalization; });
    var productAccountUUID = useAppSelector(function (state) { var _a; return (_a = state.homeSelector.selectedProductAccount) === null || _a === void 0 ? void 0 : _a.productAccountUUID; });
    var productUUID = useAppSelector(function (state) { var _a; return (_a = state.homeSelector.selectedProductAccount) === null || _a === void 0 ? void 0 : _a.productUUID; });
    var _a = useState(false), isLoadingMore = _a[0], setIsLoadingMore = _a[1];
    var _b = useState(true), isLoading = _b[0], setIsLoading = _b[1];
    var _c = useState(language.filterNoActivityMap['All']), emptyText = _c[0], setEmptyText = _c[1];
    var _d = useState([]), activityFeed = _d[0], setActivityFeed = _d[1];
    var _e = useState(''), nextToken = _e[0], setNextToken = _e[1];
    var _f = useState(false), showLoadMore = _f[0], setShowLoadMore = _f[1];
    var _g = useState('All'), filterOption = _g[0], setFilterOption = _g[1];
    function onError() {
        setIsLoading(false);
        setIsLoadingMore(false);
        setEmptyText(language.fetchActivityError);
    }
    useEffect(function () {
        setIsLoading(true);
        setActivityFeed([]);
        setNextToken('');
        setEmptyText(language.filterNoActivityMap[filterOption]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
        if (productAccountUUID && productAccountUUID !== '') {
            var onSuccess = function (res) {
                var activity = res.activity;
                var _a = res.summary, lastID = _a.lastID, nextToken = _a.nextToken;
                setIsLoading(false);
                setActivityFeed(activity);
                setNextToken(nextToken !== null && nextToken !== void 0 ? nextToken : '');
                setShowLoadMore(nextToken != undefined && nextToken != '' && activity.length === ACTIVITY_FEED_MAX);
                setIsLoadingMore(false);
            };
            loadActivity({
                productAccountUUID: productAccountUUID,
                nextToken: '',
                filterOption: filterOption,
                onError: onError,
                onSuccess: onSuccess,
            });
        }
    }, [productAccountUUID, filterOption]);
    var handleLoadMore = function () {
        var onSuccess = function (res) {
            var activity = res.activity;
            var _a = res.summary, lastID = _a.lastID, nextToken = _a.nextToken;
            setIsLoading(false);
            setActivityFeed(activityFeed.concat(activity));
            setNextToken(nextToken !== null && nextToken !== void 0 ? nextToken : '');
            // use nextToken to check whether more activities are available
            setShowLoadMore(nextToken != undefined && nextToken != '' && activity.length === ACTIVITY_FEED_MAX);
            setIsLoadingMore(false);
        };
        setIsLoadingMore(true);
        loadActivity({
            productAccountUUID: productAccountUUID || '',
            nextToken: nextToken,
            filterOption: filterOption,
            onError: onError,
            onSuccess: onSuccess,
        });
    };
    var getItems = function (activityFeed) {
        var activities = Array();
        // Return error message if we see an error in fetch activity
        if (emptyText === language.fetchActivityError) {
            return activities;
        }
        activityFeed.forEach(function (activity) {
            var _a = getIcon(activity), displayImageSrc = _a.displayImageSrc, displayImageStroke = _a.displayImageStroke, displayImageBackgroundColor = _a.displayImageBackgroundColor;
            activities.push({
                leadingIconSrc: displayImageSrc,
                leadingIconStroke: displayImageStroke,
                leadingIconBackgroundColor: displayImageBackgroundColor,
                badgeSrc: loadBadge(activity.type, activity.status) || '',
                title: activity.header,
                subtitle: activity.subheader2
                    ? "".concat(activity.subheader1, " \u2022 ").concat(activity.subheader2)
                    : activity.subheader1,
                trailingTitle: formatDisplayAmount(activity.amount),
                trailingSubtitle: activity.amountSubtextPrefix,
                trailingHighlight: activity.amountSubtextHighlight && formatDisplayAmount(activity.amountSubtextHighlight),
                onClick: isActivityClickable(activity) ? function () { return handleItemClick(activity); } : undefined,
            });
        });
        return activities;
    };
    var handleItemClick = function (item) {
        if (isActivityClickable(item)) {
            ANALYTICS.logEvent("".concat(EVENT.ACTIVITY_DETAIL_VIEWED), {
                item_type: item.type,
                item_status: item.status,
            });
            dispatch(setFlyoutActivity(item));
            dispatch(setFlyoutContext(FlyoutContext.Activity));
            dispatch(setIsFlyoutVisible(true));
        }
    };
    var isActivityClickable = function (item) {
        return (paymentDetails.includes(item.type) ||
            transactionDetails.includes(item.type) ||
            (rewardDetails.includes(item.type) && item.details !== undefined));
    };
    var getLoadMoreButtonState = function () {
        return isLoadingMore ? 'submitting' : 'enabled';
    };
    var BottomButton = function () { return (_jsx(ImpButton, __assign({ state: getLoadMoreButtonState(), variant: "text", onClick: handleLoadMore }, { children: language.viewMore }))); };
    var getHeaderTrailingContent = function () { return (_jsxs(ImpFlex, __assign({ direction: "row", justify: "center" }, { children: [_jsx(DownloadActivityFeed, { filterOption: filterOption }), _jsxs(ImpContainer, __assign({ position: "relative" }, { children: [_jsx("select", __assign({ style: {
                            position: 'absolute',
                            right: 0,
                            bottom: 0,
                            opacity: 0,
                            zIndex: 1,
                        }, onClick: function (e) {
                            ANALYTICS.logEvent(EVENT.ACTIVITY_FILTER_CLICKED, {
                                product_uuid: productUUID,
                            });
                        }, onChange: function (e) {
                            setFilterOption(e.target.value);
                            ANALYTICS.logEvent(filterEventMap[e.target.value] || EVENT.ACTIVITY_FILTER_ALL_CLICKED, { product_uuid: productUUID });
                        }, "aria-label": "activity-select" }, { children: filterOptions.map(function (optionText, index) {
                            return (_jsx("option", __assign({ value: optionText }, { children: language.filterOptionMap[optionText] }), index));
                        }) })), _jsx(ImpIcon, { iconSrc: Icons.filter, stroke: filterOption !== filterOptions[0] ? "var(--merchant-color)" : colors.content.onBackgroundVariant, size: "24px", underlineSize: filterOption !== filterOptions[0] ? 'm' : undefined })] }))] }))); };
    var items = useMemo(function () { return getItems(activityFeed); }, [activityFeed]);
    return (_jsx(ImpContainer, __assign({ width: "100%" }, { children: _jsx(ImpActivity, { items: items, title: language.activity, headerTrailingContent: getHeaderTrailingContent(), emptyText: emptyText, bottomButton: showLoadMore ? _jsx(BottomButton, {}) : undefined, isLoading: isLoading }) })));
}
