var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ImpButton, ImpContainer, ImpFlex, ImpInput, ImpSpacer, ImpText, } from '@imprint-payments/imprint-ui';
import { useAppSelector } from '../../app/hooks';
import { ValidationResponse, validatePersonalInfo, } from '@imprint-payments/imprint-lib';
import { useState } from 'react';
import { ANALYTICS } from '../../firebase/firebase';
import { EVENT } from '../../analytics/analyticsConsts';
import { FORM_BUTTON_WIDTH } from '../../assets/consts/const';
export function EditEmailPage(_a) {
    var _b;
    var onContinue = _a.onContinue;
    var language = useAppSelector(function (state) { return state.languageSelector.languageLocalization; });
    var homeSelector = useAppSelector(function (state) { return state.homeSelector; });
    var continueButtonState = function () {
        if (emailError !== ValidationResponse.VALID &&
            emailError !== ValidationResponse.EMAIL_WARNING_TYPO) {
            return 'disabled';
        }
        return 'enabled';
    };
    var _c = useState(''), email = _c[0], setEmail = _c[1];
    var _d = useState(ValidationResponse.EMPTY), emailError = _d[0], setEmailError = _d[1];
    // TODO: move to imprint-lib
    var ValidationString = (_b = {},
        _b[ValidationResponse.EMPTY] = language.emailTypo,
        _b[ValidationResponse.EMAIL_INVALID_LONG] = language.invalidEmailLong,
        _b[ValidationResponse.EMAIL_INVALID] = language.invalidEmail,
        _b[ValidationResponse.EMAIL_WARNING_TYPO] = language.emailTypo,
        _b[ValidationResponse.EMAIL_INVALID_SAME] = language.sameEmailError,
        _b);
    var showError = function () {
        return (emailError !== ValidationResponse.VALID &&
            emailError !== ValidationResponse.EMAIL_WARNING_TYPO &&
            emailError !== ValidationResponse.EMPTY);
    };
    return (_jsx(ImpContainer, __assign({ width: "min(552px, 100%)" }, { children: _jsxs(ImpFlex, __assign({ direction: "column", align: "center", fluid: true }, { children: [_jsx(ImpText, __assign({ typography: "headline2" }, { children: language.editEmailHeader })), _jsx(ImpSpacer, { height: 'xl' }), _jsx(ImpInput, { label: language.personalInfoEmailPlaceholder, value: email, errorMessage: ValidationString[emailError], showError: showError(), showWarning: emailError === ValidationResponse.EMAIL_WARNING_TYPO, instrumentation: function () {
                        return ANALYTICS.logEvent(EVENT.PERSONAL_INFO_ADDRESS_EDIT, {
                            field: 'email',
                        });
                    }, onChange: function (e) {
                        setEmail(e.target.value);
                    }, onBlur: function () {
                        var emailError = validatePersonalInfo.email(email, homeSelector.userInformation.email);
                        setEmailError(emailError);
                    } }), _jsx(ImpSpacer, { height: '2xl' }), _jsx(ImpContainer, __assign({ width: FORM_BUTTON_WIDTH }, { children: _jsx(ImpButton, __assign({ size: "fill", buttonStyle: "imprint", onClick: function () { return onContinue(email); }, state: continueButtonState() }, { children: language.continueBtn })) }))] })) })));
}
