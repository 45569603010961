var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { colors, ImpActionPage, ImpButton, ImpContainer, ImpFlex, ImpInput, ImpSpacer, ImpText, } from '@imprint-payments/imprint-ui';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { EVENT } from '../../analytics/analyticsConsts';
import { findAccount } from '../../api/findAccount/findAccount.api';
import { clearAccountOnboardingDetails } from '../../app/accountOnboardingSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectLanguage } from '../../app/languageSelectors';
import { selectMerchantData } from '../../app/merchantDataSelectors';
import { SCREEN, setScreen } from '../../app/screenStateSlice';
import { FORM_INPUT_WIDTH } from '../../assets/consts/const';
import { DobInput } from '../../components/DateOfBirth/DobInput';
import { HEADER_SERVICING_HEIGHT } from '../../components/HeaderServicing/HeaderServicing';
import { SSNInput } from '../../components/SSNInput/SSNInput';
import { ANALYTICS } from '../../firebase/firebase';
import { getShowError, getValidationString, validateName, ValidationResponse, } from '../../utils/validateUtils';
export function FindYourAccountPage(_a) {
    var isForgotPin = _a.isForgotPin;
    var navigate = useNavigate();
    var dispatch = useAppDispatch();
    var language = useAppSelector(selectLanguage);
    var merchantData = useAppSelector(selectMerchantData);
    var _b = useState(''), lastName = _b[0], setLastName = _b[1];
    var _c = useState(ValidationResponse.empty), lastNameError = _c[0], setLastNameError = _c[1];
    var _d = useState({ month: '', day: '', year: '', isValid: false }), dob = _d[0], setDob = _d[1];
    var _e = useState({ ssn: '', isValid: false }), ssn = _e[0], setSSN = _e[1];
    var _f = useState(false), isSubmitting = _f[0], setIsSubmitting = _f[1];
    useEffect(function () {
        dispatch(clearAccountOnboardingDetails());
        ANALYTICS.logEvent(EVENT.FIND_ACCOUNT_VIEWED, {
            product_uuid: merchantData.productUUID,
        });
    }, []);
    useEffect(function () {
        setLastNameError(validateName(lastName, false, false));
    }, [lastName]);
    var handleBackClick = function () {
        ANALYTICS.logEvent(EVENT.FIND_ACCOUNT_BACK_CLICKED, {
            product_uuid: merchantData.productUUID,
        });
        navigate(-1);
        dispatch(setScreen(SCREEN.LOGIN));
    };
    var handleContinueClick = function () {
        ANALYTICS.logEvent(EVENT.FIND_ACCOUNT_CONTINUE_CLICKED, {
            product_uuid: merchantData.productUUID,
        });
        setIsSubmitting(true);
        findAccount({
            lastName: lastName,
            dob: {
                day: parseInt(dob.day),
                month: parseInt(dob.month),
                year: parseInt(dob.year),
            },
            ssn: ssn.ssn,
            onFail: function () {
                setIsSubmitting(false);
                dispatch(setScreen(SCREEN.FIND_ACCOUNT_ERROR));
            },
            onSuccess: function (showWelcome, nextStep) {
                setIsSubmitting(false);
                if (isForgotPin)
                    return;
                dispatch(setScreen(nextStep === 'setPIN'
                    ? SCREEN.PIN_CREATE
                    : showWelcome
                        ? SCREEN.WELCOME
                        : SCREEN.CONFIRM_PHONE));
            },
        });
    };
    var getContinueButtonState = function () {
        return isSubmitting
            ? 'submitting'
            : lastNameError === ValidationResponse.valid && dob.isValid && ssn.isValid && lastName
                ? 'enabled'
                : 'disabled';
    };
    return (_jsxs(ImpActionPage, __assign({ footer: _jsx(ImpButton, __assign({ buttonStyle: "imprint", onClick: handleContinueClick, state: getContinueButtonState() }, { children: language.continueBtn })), onBackClick: handleBackClick, offset: HEADER_SERVICING_HEIGHT }, { children: [_jsx(ImpSpacer, { height: "2xl" }), _jsx(ImpText, __assign({ typography: "headline2" }, { children: isForgotPin ? language.forgotPinTitle : language.findYourAccountTitle })), _jsx(ImpSpacer, { height: "xs" }), _jsx(ImpText, __assign({ typography: "body2", color: colors.content.onSurfaceVariant }, { children: isForgotPin ? language.findYourAccountSubtitleForgotPin : language.findYourAccountSubtitle })), _jsx(ImpSpacer, { height: "l" }), _jsx(ImpContainer, __assign({ width: FORM_INPUT_WIDTH }, { children: _jsxs(ImpFlex, __assign({ direction: "column", align: "left", gap: "s", fluid: true }, { children: [_jsx(ImpInput, { label: language.lastName, isDisabled: isSubmitting, onChange: function (e) { return setLastName(e.target.value); }, onBlur: function () {
                                return ANALYTICS.logEvent(EVENT.FIND_ACCOUNT_NAME_FILLED_OUT, {
                                    product_uuid: merchantData.productUUID,
                                });
                            }, showError: getShowError(lastNameError), errorMessage: getValidationString(lastNameError, language) }), _jsx(ImpText, __assign({ typography: "body3", color: colors.content.onSurfaceVariant }, { children: language.birthDay })), _jsx(DobInput, { isDisabled: isSubmitting, onDobChange: function (newDob) { return setDob(newDob); } }), _jsx(SSNInput, { isDisabled: isSubmitting, onSSNChange: function (newSsn) { return setSSN(newSsn); } })] })) })), _jsx(ImpSpacer, { height: "xl" })] })));
}
