var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { ImpBreadcrumbs, ImpContainer, ImpFlex, ImpList, ImpModal, ImpSpacer, useModal, } from '@imprint-payments/imprint-ui';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { EVENT } from '../../analytics/analyticsConsts';
import { expireAuthApplication } from '../../api/expireAuthApplication/expireAuthApplication';
import { loadHome } from '../../api/loadHome.api';
import { loadProductAccount } from '../../api/loadProductAccount/loadProductAccount';
import { selectSelectedAuthorizedUser } from '../../app/authUserSelectors';
import { AuthUsersPage, setAuthUsersPage, setReturningIncompleteAuthUser, setSelectedAuthorizedUser, } from '../../app/authUsersSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import HeaderServicing from '../../components/HeaderServicing/HeaderServicing';
import { ANALYTICS } from '../../firebase/firebase';
import { getEmptyAuthorizedUser } from '../../types/AuthorizedUser';
import { DisplayState } from '../../types/DisplayState';
import { displayTemporarySnackbar, navigateToAccountPage } from '../../utils/functionUtils';
import { PAGE } from '../../utils/routeConstant';
import { AuthorizedUserInfo } from './AuthorizedUserInfo';
import { generateUsersList } from './authUsersHelpers';
import { IncompleteApplicationModal } from './infoModals/IncompleteApplicationModal';
import { PendingApplicationModal } from './infoModals/PendingApplicationModal';
export function AuthorizedUsersPage() {
    var _this = this;
    var _a = useState(true), isLoading = _a[0], setIsLoading = _a[1];
    var _b = useState(undefined), popupAuthUUID = _b[0], setPopupAuthUUID = _b[1];
    var _c = useState(undefined), popupAuthName = _c[0], setPopupAuthName = _c[1];
    var productAccount = useAppSelector(function (state) { return state.productAccountSelector; });
    var navigate = useNavigate();
    var dispatch = useAppDispatch();
    var language = useAppSelector(function (state) { return state.languageSelector.languageLocalization; });
    var authUserState = useAppSelector(function (state) { return state.authUsersSelector; });
    var homeSelector = useAppSelector(function (state) { return state.homeSelector; });
    var selectedAuthorizedUser = useAppSelector(selectSelectedAuthorizedUser);
    var featureFlags = useAppSelector(function (state) { return state.featureFlagSelector.featureFlags; });
    var _d = useState(false), isExpiring = _d[0], setIsExpiring = _d[1];
    var _e = useModal(false), isOpenPendingDecision = _e[0], isClosingPendingDecision = _e[1], openModalPendingDecision = _e[2], closeModalPendingDecision = _e[3];
    var _f = useModal(false), isOpenIncompleteApp = _f[0], isClosingIncompleteApp = _f[1], openModalIncompleteApp = _f[2], closeModalIncompleteApp = _f[3];
    if (featureFlags.authUsers !== 'true') {
        navigateToAccountPage(dispatch, navigate);
        return null;
    }
    useEffect(function () {
        dispatch(loadHome()).then(function () {
            var _a;
            loadProductAccount({
                productAccountUUID: ((_a = homeSelector.selectedProductAccount) === null || _a === void 0 ? void 0 : _a.productAccountUUID) || '',
            }).then(function () {
                setIsLoading(false);
            });
        });
    }, []);
    var setPopupInfo = function (_a) {
        var name = _a.name, authApplicationUUID = _a.authApplicationUUID;
        setPopupAuthUUID(authApplicationUUID);
        setPopupAuthName(name);
    };
    var usersList = useMemo(function () {
        return generateUsersList(productAccount, dispatch, navigate, language, setPopupInfo, openModalPendingDecision, openModalIncompleteApp);
    }, [authUserState, productAccount, language]);
    var handleBackClick = function () {
        switch (authUserState.page) {
            case AuthUsersPage.ACTIVATE_PHYSICAL_CARD:
            case AuthUsersPage.REPLACE_CARD:
            case AuthUsersPage.DEACTIVATE_USER:
            case AuthUsersPage.USER_DEACTIVATED:
            case AuthUsersPage.VERIFY_PIN_ADDRESS:
            case AuthUsersPage.UPDATE_ADDRESS:
                dispatch(setAuthUsersPage(AuthUsersPage.DEFAULT));
                break;
            default:
                isAuthorizedUserSelected(selectedAuthorizedUser)
                    ? dispatch(setSelectedAuthorizedUser(getEmptyAuthorizedUser()))
                    : navigate(PAGE.HOME);
        }
    };
    var discardApp = function () {
        ANALYTICS.logEvent(EVENT.AUTH_USER_DISCARD_APP_CLICKED);
        var authApplicationUUID = popupAuthUUID || 'unset uuid';
        var before = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                closeModalIncompleteApp();
                setIsLoading(true);
                loadProductAccount({
                    productAccountUUID: ((_a = homeSelector.selectedProductAccount) === null || _a === void 0 ? void 0 : _a.productAccountUUID) || '',
                }).then(function () {
                    setIsLoading(false);
                    setIsExpiring(false);
                });
                return [2 /*return*/];
            });
        }); };
        setIsExpiring(true);
        expireAuthApplication({
            authApplicationUUID: authApplicationUUID,
            onSuccess: function () {
                before();
                displayTemporarySnackbar(language.forgotPinSuccessTitle, dispatch);
            },
            onError: function () {
                before();
                displayTemporarySnackbar(language.somethingWentWrong, dispatch);
            },
        });
    };
    var continueApp = function () {
        ANALYTICS.logEvent(EVENT.AUTH_USER_CONTINUE_APP_CLICKED);
        var authApplicationUUID = popupAuthUUID || 'unset uuid';
        var name = popupAuthName || { firstName: '', lastName: '' };
        var displayState = DisplayState.DisplayStateNewApplicationWithFullSSN;
        dispatch(setReturningIncompleteAuthUser({
            authApplicationUUID: authApplicationUUID,
            displayState: displayState,
            name: name,
        }));
        navigate(PAGE.ADD_AUTHORIZED_USER);
    };
    var getPageBody = function () {
        var _a;
        if (isAuthorizedUserSelected(selectedAuthorizedUser)) {
            return (_jsx(ImpFlex, __assign({ direction: "row", justify: "center", fluid: true }, { children: _jsx(AuthorizedUserInfo, {}) })));
        }
        return (_jsxs(_Fragment, { children: [_jsx(ImpList, { isLoading: isLoading, hideSquareLoader: true, useSurface: true, items: usersList, caption: language.authPageNote.replace('%max%', ((_a = productAccount.authUserDetails) === null || _a === void 0 ? void 0 : _a.limitOfAuthorizedUsers) || 0) }), _jsx(ImpModal, __assign({ isOpen: isOpenPendingDecision, isClosing: isClosingPendingDecision, closeModal: closeModalPendingDecision }, { children: _jsx(PendingApplicationModal, { closeModal: closeModalPendingDecision }) })), _jsx(ImpModal, __assign({ closeModal: closeModalIncompleteApp, isOpen: isOpenIncompleteApp, isClosing: isClosingIncompleteApp, respectContentHeight: true }, { children: _jsx(IncompleteApplicationModal, { closeModal: closeModalIncompleteApp, onPrimaryClick: continueApp, onSecondaryClick: discardApp, isLoading: isExpiring }) }))] }));
    };
    return (_jsxs(ImpContainer, { children: [_jsx(HeaderServicing, {}), _jsx(ImpBreadcrumbs, { breadcrumbs: [language.authorizedUsers], onBackClick: handleBackClick, onCancelClick: function () { return navigateToAccountPage(dispatch, navigate); } }), _jsx(ImpSpacer, { height: "2xl" }), _jsx(ImpFlex, __assign({ direction: "column", align: "center", fluid: true }, { children: _jsx(ImpContainer, __assign({ width: "min(748px, 100vw - 32px)" }, { children: getPageBody() })) }))] }));
}
function isAuthorizedUserSelected(selectedAuthorizedUser) {
    return (selectedAuthorizedUser === null || selectedAuthorizedUser === void 0 ? void 0 : selectedAuthorizedUser.authorizedUserUUID) !== '';
}
