var _a;
import { createSlice } from '@reduxjs/toolkit';
export var ForgotPinScreen;
(function (ForgotPinScreen) {
    ForgotPinScreen[ForgotPinScreen["VERIFY_DOB"] = 0] = "VERIFY_DOB";
    ForgotPinScreen[ForgotPinScreen["VERIFY_PII"] = 1] = "VERIFY_PII";
    ForgotPinScreen[ForgotPinScreen["SET_PIN"] = 2] = "SET_PIN";
    ForgotPinScreen[ForgotPinScreen["VERIFY_OTP"] = 3] = "VERIFY_OTP";
    ForgotPinScreen[ForgotPinScreen["SOMETHING_WENT_WRONG"] = 4] = "SOMETHING_WENT_WRONG";
    ForgotPinScreen[ForgotPinScreen["SUCCESS"] = 5] = "SUCCESS";
})(ForgotPinScreen || (ForgotPinScreen = {}));
export var ForgotPinScreenState;
(function (ForgotPinScreenState) {
    ForgotPinScreenState[ForgotPinScreenState["VALID"] = 0] = "VALID";
    ForgotPinScreenState[ForgotPinScreenState["INCORRECT_DOB"] = 1] = "INCORRECT_DOB";
    ForgotPinScreenState[ForgotPinScreenState["ACCOUNT_NOT_FOUND"] = 2] = "ACCOUNT_NOT_FOUND";
    ForgotPinScreenState[ForgotPinScreenState["SOMETHING_WENT_WRONG"] = 3] = "SOMETHING_WENT_WRONG";
    ForgotPinScreenState[ForgotPinScreenState["LOADING"] = 4] = "LOADING";
})(ForgotPinScreenState || (ForgotPinScreenState = {}));
var initialState = {
    screenState: ForgotPinScreenState.LOADING,
    screen: ForgotPinScreen.VERIFY_DOB,
    requestID: '',
    nextStep: '',
};
var cardDesignSlice = createSlice({
    name: 'forgotPin',
    initialState: initialState,
    reducers: {
        setForgotPinScreen: function (state, action) {
            state.screen = action.payload;
        },
        setForgotPinRequestID: function (state, action) {
            state.requestID = action.payload;
        },
        setForgotPinNextStep: function (state, action) {
            state.nextStep = action.payload;
        },
        setForgotPinScreenState: function (state, action) {
            state.screenState = action.payload;
        },
        resetForgotPinSlice: function () { return initialState; },
    },
});
export var setForgotPinScreen = (_a = cardDesignSlice.actions, _a.setForgotPinScreen), setForgotPinRequestID = _a.setForgotPinRequestID, setForgotPinNextStep = _a.setForgotPinNextStep, setForgotPinScreenState = _a.setForgotPinScreenState, resetForgotPinSlice = _a.resetForgotPinSlice;
export default cardDesignSlice.reducer;
