var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { AuthUsersPage, setAuthUsersPage, } from '../../app/authUsersSlice';
import { displayTemporarySnackbar } from '../../utils/functionUtils';
import { useState } from 'react';
import { ConfirmationScreen } from './ConfirmationScreen';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { deactivateAuthUser } from '../../api/deactivateAuthUser/deactivateAuthUser.api';
import { ImpButton } from '@imprint-payments/imprint-ui';
import { ANALYTICS } from '../../firebase/firebase';
import { EVENT } from '../../analytics/analyticsConsts';
export function AuthDeactivateUser() {
    var _a = useState(false), isSubmitting = _a[0], setIsSubmitting = _a[1];
    var language = useAppSelector(function (state) { return state.languageSelector.languageLocalization; });
    var dispatch = useAppDispatch();
    var authorizedUserState = useAppSelector(function (state) { return state.authUsersSelector; });
    var getPrimaryButtonState = function () {
        if (isSubmitting) {
            return 'submitting';
        }
        return 'enabled';
    };
    var getSecondaryButtonState = function () {
        if (isSubmitting) {
            return 'disabled';
        }
        return 'enabled';
    };
    var handleConfirmClick = function () {
        setIsSubmitting(true);
        var authorizedUserAccountUUID = authorizedUserState.selectedAuthorizedUser.authorizedUserAccountUUID;
        deactivateAuthUser({
            authorizedUserAccountUUID: authorizedUserAccountUUID,
            onSuccess: function () {
                setIsSubmitting(false);
                ANALYTICS.logEvent(EVENT.AUTH_USER_CARD_DEACTIVATED);
                dispatch(setAuthUsersPage(AuthUsersPage.USER_DEACTIVATED));
            },
            onFail: function () {
                setIsSubmitting(false);
                displayTemporarySnackbar(language.somethingWentWrong, dispatch);
            },
        });
    };
    return (_jsx(ConfirmationScreen, { title: language.cancelConfirmationHeader, subtitle: language.deactivatingThisUser, actionButton: _jsx(ImpButton, __assign({ size: "fill", buttonStyle: "branded", variant: "primary", onClick: handleConfirmClick, state: getPrimaryButtonState() }, { children: language.deactivateUser })), cancelButton: _jsx(ImpButton, __assign({ size: "fill", onClick: function () {
                dispatch(setAuthUsersPage(AuthUsersPage.DEFAULT));
            }, buttonStyle: "branded", variant: "secondary", state: getSecondaryButtonState() }, { children: language.goBackBtn })) }));
}
