var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ImpActivity, ImpButton, ImpContainer, ImpDivider, ImpFlex, ImpPad, ImpSpacer, colors, } from '@imprint-payments/imprint-ui';
var CardSection = function () {
    return (_jsx(ImpFlex, __assign({ direction: "row", justify: "flex-start", mobileJustify: "center", fluid: true }, { children: _jsxs(ImpFlex, __assign({ direction: "column", gap: "s", align: "center" }, { children: [_jsx(ImpContainer, { height: "194px", width: "310px", backgroundColor: "white", borderRadius: "12px" }), _jsx(ImpContainer, { width: "200px", height: "20px", isLoading: true })] })) })));
};
var HomeItem = function () {
    return (_jsxs(ImpContainer, __assign({ width: "100%", backgroundColor: "white", borderRadius: "12px" }, { children: [_jsx(ImpPad, __assign({ padding: "16px 24px" }, { children: _jsx(ImpContainer, __assign({ height: "92px" }, { children: _jsxs(ImpFlex, __assign({ direction: "row", justify: "space-between", align: "center", fluid: true }, { children: [_jsx(ImpContainer, __assign({ width: "50%" }, { children: _jsxs(ImpFlex, __assign({ direction: "column", gap: "xs", justify: "center" }, { children: [_jsx(ImpContainer, { isLoading: true, height: "22px", width: "60%" }), _jsx(ImpContainer, { isLoading: true, height: "22px", width: "90%" })] })) })), _jsx(ImpContainer, __assign({ width: "min(144px, 90%)" }, { children: _jsx(ImpButton, { children: undefined, size: "fill", state: "loading" }) }))] })) })) })), _jsx(ImpContainer, __assign({ width: "100%" }, { children: _jsx(ImpDivider, { color: colors.border.borderVariant }) })), _jsx(ImpPad, __assign({ padding: "16px 24px" }, { children: _jsx(ImpContainer, __assign({ width: "50%", height: "48px" }, { children: _jsxs(ImpFlex, __assign({ direction: "column", gap: "xs", justify: "center" }, { children: [_jsx(ImpContainer, { isLoading: true, height: "20px", width: "60%" }), _jsx(ImpContainer, { isLoading: true, height: "20px", width: "90%" })] })) })) }))] })));
};
var InfoSection = function () {
    return (_jsx(ImpContainer, __assign({ width: "100%" }, { children: _jsxs(ImpFlex, __assign({ direction: "row", justify: "space-between", mobileDirection: "column", fluid: true, gap: "xl", mobileGap: "s" }, { children: [_jsx(HomeItem, {}), _jsx(HomeItem, {})] })) })));
};
var ActivitySection = function () {
    return (_jsx(ImpContainer, __assign({ width: "100%" }, { children: _jsx(ImpActivity, { items: [], isLoading: true, emptyText: '' }) })));
};
export function HomeLoader() {
    return (_jsxs(ImpContainer, __assign({ width: "min(1140px, 100vw - 48px)" }, { children: [_jsx(ImpSpacer, { height: "xl" }), _jsxs(ImpFlex, __assign({ direction: "column", fluid: true, gap: "xl", mobileGap: "s" }, { children: [_jsx(CardSection, {}), _jsx(InfoSection, {}), _jsx(ActivitySection, {})] })), _jsx(ImpSpacer, { height: "2xl" })] })));
}
