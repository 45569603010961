var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { colors, ImpButton, ImpContainer, ImpFlex, ImpOtpField, ImpSpacer, ImpText, } from '@imprint-payments/imprint-ui';
import { useEffect, useState } from 'react';
import { EVENT } from '../../analytics/analyticsConsts';
import { createPin } from '../../api/api';
import { isConvertedUser } from '../../api/findAccount/isConvertedUser.api';
import { loadHome } from '../../api/loadHome.api';
import { PINContext } from '../../api/verifyPin.api';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectLanguage } from '../../app/languageSelectors';
import { SCREEN, setScreen } from '../../app/screenStateSlice';
import { TEMP_SNACKBAR_DURATION } from '../../app/snackbarSlice';
import { TEXT_WIDTH } from '../../assets/consts/const';
import { ANALYTICS } from '../../firebase/firebase';
import { displayTemporarySnackbar } from '../../utils/functionUtils';
import { getValidationString, isValidPin, ValidationResponse } from '../../utils/validateUtils';
import Spinner from '../spinner/Spinner';
export function LoginCreatePin(_a) {
    var resetPin = _a.resetPin;
    var dispatch = useAppDispatch();
    var language = useAppSelector(selectLanguage);
    var appScreenState = useAppSelector(function (state) { return state.applicationScreenSelector; });
    var _b = useState(''), input = _b[0], setInput = _b[1];
    var _c = useState(''), pinToConfirm = _c[0], setPinToConfirm = _c[1];
    var _d = useState(false), confirmingPin = _d[0], setConfirmingPin = _d[1];
    var _e = useState(false), clearInput = _e[0], setClearInput = _e[1];
    var _f = useState(false), invalidPinCriteria = _f[0], setInvalidPinCriteria = _f[1];
    var _g = useState(ValidationResponse.empty), pinNotMatchError = _g[0], setPinNotMatchError = _g[1];
    var showError = pinNotMatchError === ValidationResponse.invalidPinNoMatch;
    var analyticsParams = resetPin ? { context: 'forgot_pin' } : undefined;
    useEffect(function () {
        ANALYTICS.logEvent(EVENT.CREATE_PIN_VIEWED, analyticsParams);
    }, []);
    function getHeaderText() {
        if (confirmingPin)
            return language.reEnterYourPIN;
        if (resetPin)
            return language.resetPin;
        return language.createAPIN;
    }
    function handleGoBackClick() {
        if (showError) {
            ANALYTICS.logEvent(EVENT.CONFIRM_PIN_TRY_AGAIN_RESET_PIN_CLICKED);
        }
        else {
            ANALYTICS.logEvent(EVENT.CONFIRM_PIN_RESET_PIN_CLICKED);
        }
        clearErrors();
        setPinToConfirm('');
        setConfirmingPin(false);
        setClearInput(true);
    }
    function clearErrors() {
        setPinNotMatchError(ValidationResponse.empty);
        setInvalidPinCriteria(false);
    }
    function handleChange(s) {
        setClearInput(false);
        if (input)
            clearErrors();
        setInput(s);
    }
    var handleInputFull = function (s) {
        if (confirmingPin) {
            ANALYTICS.logEvent(EVENT.CONFIRM_PIN_SUBMITTED, analyticsParams);
            comparePins(s);
        }
        else {
            ANALYTICS.logEvent(EVENT.CREATE_PIN_SUBMITTED, analyticsParams);
            validatePin(s);
        }
    };
    function comparePins(currentInput) {
        if (currentInput === void 0) { currentInput = input; }
        if (currentInput === pinToConfirm) {
            handleMatchingPins();
        }
        else {
            handleNonMatchingPins();
        }
    }
    function routeToConversionLogin() {
        dispatch(setScreen(SCREEN.CONFIRM_EMAIL));
    }
    function routeToRegularLogin() {
        dispatch(loadHome());
    }
    function handleMatchingPins(currentInput) {
        if (currentInput === void 0) { currentInput = input; }
        setPinNotMatchError(ValidationResponse.valid);
        dispatch(createPin(currentInput, resetPin ? PINContext.ResetPin : undefined, function () {
            // On fail
            ANALYTICS.logEvent(EVENT.PIN_SERVER_ERROR);
            setTimeout(function () {
                setClearInput(true);
            }, TEMP_SNACKBAR_DURATION);
            displayTemporarySnackbar(language.somethingWentWrong, dispatch);
        }, function () {
            // TODO: this is some nasty nesting
            isConvertedUser({
                onSuccess: function (isConvertedUser) {
                    if (isConvertedUser) {
                        routeToConversionLogin();
                    }
                    else {
                        routeToRegularLogin();
                    }
                },
                onFail: function () {
                    // it is safer to assume conversion than to allow a conversion user to be treated as a new user
                    routeToConversionLogin();
                },
            });
        }));
    }
    function handleNonMatchingPins() {
        ANALYTICS.logEvent(EVENT.CREATE_PIN_ERROR_MATCH, analyticsParams);
        setPinNotMatchError(ValidationResponse.invalidPinNoMatch);
    }
    function validatePin(pin) {
        if (isValidPin(pin) === ValidationResponse.valid) {
            setPinToConfirm(pin);
            setConfirmingPin(true);
            setClearInput(true);
        }
        else {
            ANALYTICS.logEvent(EVENT.CREATE_PIN_ERROR_CRITERIA, analyticsParams);
            setInvalidPinCriteria(true);
        }
    }
    return (_jsxs(ImpContainer, __assign({ textAlign: "center", width: TEXT_WIDTH }, { children: [_jsxs(ImpFlex, __assign({ direction: "column", align: "center", fluid: true }, { children: [_jsx(ImpSpacer, { height: "2xl" }), _jsx(ImpText, __assign({ typography: "headline2" }, { children: getHeaderText() })), !resetPin && (_jsxs(_Fragment, { children: [_jsx(ImpSpacer, { height: "xs" }), _jsx(ImpText, __assign({ typography: "body2", color: colors.content.onSurfaceVariant }, { children: language.createPinSubtitle }))] })), _jsx(ImpSpacer, { height: "xl" })] })), _jsx(ImpOtpField, { autoFocus: true, numInputs: 4, onChange: handleChange, onFull: handleInputFull, clearWithError: showError || invalidPinCriteria, clear: clearInput, mask: true, autoComplete: "one-time-code" }), showError && (_jsxs(_Fragment, { children: [_jsx(ImpSpacer, { height: "xl" }), _jsx(ImpText, __assign({ typography: "body2", color: colors.content.onSurfaceError }, { children: getValidationString(pinNotMatchError, language) }))] })), _jsx(ImpSpacer, { height: "xl" }), !confirmingPin && (_jsx(ImpText, __assign({ typography: "body3", color: invalidPinCriteria ? colors.content.onSurfaceError : colors.content.onSurfaceVariant }, { children: language.pinCreationNote }))), confirmingPin && !appScreenState.isLoading && (_jsxs(ImpFlex, __assign({ direction: "column", align: "center" }, { children: [_jsx(ImpSpacer, { height: "xl" }), _jsx(ImpButton, __assign({ onClick: handleGoBackClick, buttonStyle: "imprint", variant: "text" }, { children: language.startOver }))] }))), appScreenState.isLoading && _jsx(Spinner, {})] })));
}
