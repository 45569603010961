var _a;
import { createSlice } from '@reduxjs/toolkit';
export var SECTION;
(function (SECTION) {
    SECTION[SECTION["PERSONALINFO"] = 0] = "PERSONALINFO";
    SECTION[SECTION["INCOME"] = 1] = "INCOME";
})(SECTION || (SECTION = {}));
export var MODAL_ENUM;
(function (MODAL_ENUM) {
    MODAL_ENUM[MODAL_ENUM["noModal"] = 0] = "noModal";
    MODAL_ENUM[MODAL_ENUM["showDetailModal"] = 1] = "showDetailModal";
    MODAL_ENUM[MODAL_ENUM["showSmartphoneRequiredModal"] = 2] = "showSmartphoneRequiredModal";
    MODAL_ENUM[MODAL_ENUM["showCantApplyModal"] = 3] = "showCantApplyModal";
    MODAL_ENUM[MODAL_ENUM["showPhoneRequiredModal"] = 4] = "showPhoneRequiredModal";
    MODAL_ENUM[MODAL_ENUM["showCreditAPRModal"] = 5] = "showCreditAPRModal";
    MODAL_ENUM[MODAL_ENUM["showHowToUnfreezeModal"] = 6] = "showHowToUnfreezeModal";
    MODAL_ENUM[MODAL_ENUM["showPaymentDefinitionModal"] = 7] = "showPaymentDefinitionModal";
    MODAL_ENUM[MODAL_ENUM["showPaymentDefinitionAutoPayModal"] = 8] = "showPaymentDefinitionAutoPayModal";
    MODAL_ENUM[MODAL_ENUM["showMinimumAutoPayModal"] = 9] = "showMinimumAutoPayModal";
    MODAL_ENUM[MODAL_ENUM["showAdjustedAutoPayModal"] = 10] = "showAdjustedAutoPayModal";
    MODAL_ENUM[MODAL_ENUM["showIncomeHousingModal"] = 11] = "showIncomeHousingModal";
})(MODAL_ENUM || (MODAL_ENUM = {}));
var initialState = {
    requireFullSSN: false,
    section: SECTION.PERSONALINFO,
    showModal: MODAL_ENUM.noModal,
    eligibilityVerified: false,
    loading: false,
    subscribed: false,
    showLinkHHScreen: false,
};
var applicationScreenSlice = createSlice({
    name: 'applicationScreen',
    initialState: initialState,
    reducers: {
        requireFullSSN: function (state, action) {
            state.requireFullSSN = action.payload;
        },
        setSection: function (state, action) {
            state.section = action.payload;
        },
        setShowModal: function (state, action) {
            state.showModal = action.payload;
        },
        setEligibilityVerified: function (state, action) {
            state.eligibilityVerified = action.payload;
        },
        setLoading: function (state, action) {
            state.loading = action.payload;
        },
        setSubscribed: function (state, action) {
            state.subscribed = action.payload;
        },
        setShowLinkHHScreen: function (state, action) {
            state.showLinkHHScreen = action.payload;
        },
    },
});
export var requireFullSSN = (_a = applicationScreenSlice.actions, _a.requireFullSSN), setSection = _a.setSection, setShowModal = _a.setShowModal, setEligibilityVerified = _a.setEligibilityVerified, setLoading = _a.setLoading, setSubscribed = _a.setSubscribed, setShowLinkHHScreen = _a.setShowLinkHHScreen;
export default applicationScreenSlice.reducer;
