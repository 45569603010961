var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { EVENT, PKG } from '../analytics/analyticsConsts';
import { setVerifyPIIRequestID } from '../app/accountOnboardingSlice';
import { setDeviceID } from '../app/apiScreenSlice';
import { setLoading } from '../app/applicationScreenSlice';
import { setExpiresAt } from '../app/applicationStatusSlice';
import { setAuth } from '../app/authSlice';
import { setHomeFlow } from '../app/homeSlice';
import { setProductUUID, setRewardDetails } from '../app/merchantDataSlice';
import { setIncorrectOtpCode, setOtpServerError } from '../app/otpScreenSlice';
import { SCREEN, setScreen } from '../app/screenStateSlice';
import { ANALYTICS } from '../firebase/firebase';
import { getNewDeviceID } from '../utils/functionUtils';
import { getColor, prompt, showError, verifyOtp } from './api';
import { SIGN_UP_ELIGIBILITY_STATUS } from './apiConstants';
export var verifyServicingOtp = function (_a) {
    var code = _a.code;
    return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
        var auth, homeFlow, requestID, deviceID, verifyOtpData, verifyOtpError, productUUID, rewardDetails, _a, promptData, promptError;
        var _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    dispatch(setLoading(true));
                    auth = getState().authSlice;
                    homeFlow = getState().homeSelector.homeFlow;
                    requestID = auth.requestID;
                    if (getState().apiScreenScreenSelector.deviceID === '') {
                        dispatch(setDeviceID(getNewDeviceID()));
                    }
                    deviceID = getState().apiScreenScreenSelector.deviceID;
                    return [4 /*yield*/, verifyOtp({
                            code: code,
                            requestId: requestID,
                            deviceID: deviceID,
                        })];
                case 1:
                    _b = _c.sent(), verifyOtpData = _b[0], verifyOtpError = _b[1];
                    if (!((verifyOtpError === null || verifyOtpError === void 0 ? void 0 : verifyOtpError.code) === '101')) return [3 /*break*/, 2];
                    dispatch(setIncorrectOtpCode(true));
                    dispatch(setLoading(false));
                    return [2 /*return*/, verifyOtpError];
                case 2:
                    if (!((verifyOtpError === null || verifyOtpError === void 0 ? void 0 : verifyOtpError.code) === '201')) return [3 /*break*/, 3];
                    dispatch(setHomeFlow(__assign(__assign({}, homeFlow), { pinLockOutMessage: verifyOtpError === null || verifyOtpError === void 0 ? void 0 : verifyOtpError.message })));
                    dispatch(setScreen(SCREEN.VERIFICATION_FAILED));
                    dispatch(setLoading(false));
                    return [2 /*return*/, verifyOtpError];
                case 3:
                    if (!verifyOtpError) return [3 /*break*/, 4];
                    dispatch(setOtpServerError(true));
                    dispatch(setLoading(false));
                    return [2 /*return*/, verifyOtpError];
                case 4:
                    if (!((verifyOtpData === null || verifyOtpData === void 0 ? void 0 : verifyOtpData.nextStep) == 'verifyDOB')) return [3 /*break*/, 5];
                    dispatch(setAuth(__assign(__assign({}, auth), { requestID: verifyOtpData.requestID })));
                    dispatch(setScreen(SCREEN.DOB));
                    return [3 /*break*/, 11];
                case 5:
                    if (!((verifyOtpData === null || verifyOtpData === void 0 ? void 0 : verifyOtpData.nextStep) == 'verifyPII')) return [3 /*break*/, 6];
                    dispatch(setVerifyPIIRequestID(verifyOtpData.requestID));
                    dispatch(setScreen(SCREEN.FIND_ACCOUNT));
                    return [3 /*break*/, 11];
                case 6:
                    if (!((verifyOtpData === null || verifyOtpData === void 0 ? void 0 : verifyOtpData.nextStep) == 'verifyPIN')) return [3 /*break*/, 7];
                    // remainingAttempts could be undefined or int
                    if (verifyOtpData === null || verifyOtpData === void 0 ? void 0 : verifyOtpData.remainingAttempts) {
                        dispatch(setHomeFlow(__assign(__assign({}, homeFlow), { pinRemainingAttempts: verifyOtpData === null || verifyOtpData === void 0 ? void 0 : verifyOtpData.remainingAttempts })));
                    }
                    dispatch(setAuth(__assign(__assign({}, auth), { requestID: verifyOtpData.requestID })));
                    dispatch(setScreen(SCREEN.PIN));
                    return [3 /*break*/, 11];
                case 7:
                    if (!((verifyOtpData === null || verifyOtpData === void 0 ? void 0 : verifyOtpData.nextStep) == 'setPIN')) return [3 /*break*/, 8];
                    dispatch(setLoading(false));
                    dispatch(setAuth(__assign(__assign({}, auth), { requestID: verifyOtpData.requestID })));
                    dispatch(setScreen(SCREEN.PIN_CREATE));
                    return [3 /*break*/, 11];
                case 8:
                    if (!((verifyOtpData === null || verifyOtpData === void 0 ? void 0 : verifyOtpData.nextStep) == 'showSignUp')) return [3 /*break*/, 11];
                    if (!verifyOtpData.productUUID) return [3 /*break*/, 10];
                    productUUID = verifyOtpData.productUUID;
                    dispatch(setProductUUID(productUUID));
                    dispatch(getColor(productUUID));
                    rewardDetails = getState().merchantDataSelector.rewardDetails;
                    dispatch(setAuth(__assign(__assign({}, auth), { token: verifyOtpData.token })));
                    return [4 /*yield*/, prompt({
                            productUUID: productUUID,
                            deviceID: deviceID,
                        })];
                case 9:
                    _a = _c.sent(), promptData = _a[0], promptError = _a[1];
                    if (promptError) {
                        showError(dispatch);
                        ANALYTICS.funcError(PKG, 'prompt', JSON.stringify(promptError));
                        return [2 /*return*/, promptError];
                    }
                    if (!promptData) {
                        showError(dispatch);
                        ANALYTICS.funcError(PKG, 'prompt', 'missing prompt data');
                        return [2 /*return*/, null];
                    }
                    switch (promptData.signUpEligibilityStatus) {
                        case SIGN_UP_ELIGIBILITY_STATUS.REJECTED_WITHIN_COOLDOWN_WINDOW: {
                            dispatch(setRewardDetails(__assign(__assign({}, rewardDetails), { merchantName: promptData.merchantName, cardName: promptData.cardName })));
                            ANALYTICS.logEvent(EVENT.SERVICING_DECISION_DENIED_VIEWED, {
                                product_uuid: productUUID,
                            });
                            dispatch(setScreen(SCREEN.APPLICATION_NOT_APPROVED_WITHIN_COOLDOWN));
                            break;
                        }
                        case SIGN_UP_ELIGIBILITY_STATUS.REJECTED_AFTER_COOLDOWN_WINDOW: {
                            dispatch(setRewardDetails(__assign(__assign({}, rewardDetails), { merchantName: promptData.merchantName, cardName: promptData.cardName })));
                            ANALYTICS.logEvent(EVENT.SERVICING_DECISION_DENIED_VIEWED, {
                                product_uuid: productUUID,
                            });
                            dispatch(setScreen(SCREEN.APPLICATION_NOT_APPROVED_AFTER_COOLDOWN));
                            break;
                        }
                        case SIGN_UP_ELIGIBILITY_STATUS.FROZEN: {
                            dispatch(setRewardDetails(__assign(__assign({}, rewardDetails), { merchantName: promptData.merchantName, cardName: promptData.cardName })));
                            dispatch(setExpiresAt(promptData.frozenExpiresAt));
                            ANALYTICS.logEvent(EVENT.SERVICING_DECISION_FROZEN_VIEWED, {
                                product_uuid: productUUID,
                            });
                            dispatch(setProductUUID(productUUID));
                            dispatch(setScreen(SCREEN.CREDIT_FROZEN));
                            break;
                        }
                        case SIGN_UP_ELIGIBILITY_STATUS.ELIGIBLE: {
                            dispatch(setRewardDetails(__assign(__assign({}, rewardDetails), { merchantName: promptData.merchantName, cardName: promptData.cardName })));
                            ANALYTICS.logEvent(EVENT.SERVICING_DECISION_ELIGIBLE_VIEWED, {
                                product_uuid: productUUID,
                            });
                            dispatch(setProductUUID(productUUID));
                            dispatch(setScreen(SCREEN.ELIGIBLE));
                            break;
                        }
                        case SIGN_UP_ELIGIBILITY_STATUS.REAPPLY: {
                            dispatch(setRewardDetails(__assign(__assign({}, rewardDetails), { merchantName: promptData.merchantName, cardName: promptData.cardName })));
                            ANALYTICS.logEvent(EVENT.SERVICING_DECISION_REAPPLY_VIEWED, {
                                product_uuid: productUUID,
                            });
                            dispatch(setProductUUID(productUUID));
                            dispatch(setScreen(SCREEN.REAPPLY));
                            break;
                        }
                        default: {
                            // TODO: for status of eligible, will handle in phase 2 of returning user
                            // i.e. re-application
                            dispatch(setScreen(SCREEN.ACCOUNT_PENDING));
                            break;
                        }
                    }
                    dispatch(setLoading(false));
                    return [2 /*return*/];
                case 10:
                    dispatch(setScreen(SCREEN.ACCOUNT_PENDING));
                    _c.label = 11;
                case 11:
                    dispatch(setLoading(false));
                    return [2 /*return*/];
            }
        });
    }); };
};
