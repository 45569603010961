var _a;
import { createSlice } from '@reduxjs/toolkit';
export var ScreenStateContext;
(function (ScreenStateContext) {
    ScreenStateContext[ScreenStateContext["None"] = 0] = "None";
    ScreenStateContext[ScreenStateContext["FailedCardDetailsPIN"] = 1] = "FailedCardDetailsPIN";
    ScreenStateContext[ScreenStateContext["FailedEmailValidationPIN"] = 2] = "FailedEmailValidationPIN";
    ScreenStateContext[ScreenStateContext["FailedSecurityValidationPIN"] = 3] = "FailedSecurityValidationPIN";
})(ScreenStateContext || (ScreenStateContext = {}));
export var SCREEN;
(function (SCREEN) {
    SCREEN[SCREEN["ACCEPT"] = 0] = "ACCEPT";
    SCREEN[SCREEN["ACCESS_MORE"] = 1] = "ACCESS_MORE";
    SCREEN[SCREEN["ACCOUNT"] = 2] = "ACCOUNT";
    SCREEN[SCREEN["ACCOUNT_PENDING"] = 3] = "ACCOUNT_PENDING";
    SCREEN[SCREEN["APPLICATION"] = 4] = "APPLICATION";
    SCREEN[SCREEN["APP_OTPSCREEN"] = 5] = "APP_OTPSCREEN";
    SCREEN[SCREEN["AUTOPAY"] = 6] = "AUTOPAY";
    SCREEN[SCREEN["AUTH_USERS_PAGE"] = 7] = "AUTH_USERS_PAGE";
    SCREEN[SCREEN["BANKING"] = 8] = "BANKING";
    SCREEN[SCREEN["CARD_BALANCE"] = 9] = "CARD_BALANCE";
    SCREEN[SCREEN["CONFIRM"] = 10] = "CONFIRM";
    SCREEN[SCREEN["CONSUMER_DOCUMENTS"] = 11] = "CONSUMER_DOCUMENTS";
    SCREEN[SCREEN["DOB"] = 12] = "DOB";
    SCREEN[SCREEN["DOCUMENTS"] = 13] = "DOCUMENTS";
    SCREEN[SCREEN["DOWNLOAD_APP"] = 14] = "DOWNLOAD_APP";
    SCREEN[SCREEN["EDU_APP_WEB"] = 15] = "EDU_APP_WEB";
    SCREEN[SCREEN["ERROR"] = 16] = "ERROR";
    SCREEN[SCREEN["FORGOT_PIN"] = 17] = "FORGOT_PIN";
    SCREEN[SCREEN["IDV"] = 18] = "IDV";
    SCREEN[SCREEN["IDV_ERROR"] = 19] = "IDV_ERROR";
    SCREEN[SCREEN["LANGUAGE_DISCLAIMER"] = 20] = "LANGUAGE_DISCLAIMER";
    SCREEN[SCREEN["LOGIN"] = 21] = "LOGIN";
    SCREEN[SCREEN["OFFER"] = 22] = "OFFER";
    SCREEN[SCREEN["ONE_TIME_PAYMENT"] = 23] = "ONE_TIME_PAYMENT";
    SCREEN[SCREEN["PAYMENTS"] = 24] = "PAYMENTS";
    SCREEN[SCREEN["PERSONAL_INFO"] = 25] = "PERSONAL_INFO";
    SCREEN[SCREEN["PIN"] = 26] = "PIN";
    SCREEN[SCREEN["PIN_CREATE"] = 27] = "PIN_CREATE";
    SCREEN[SCREEN["SERVICE_OTPSCREEN"] = 28] = "SERVICE_OTPSCREEN";
    SCREEN[SCREEN["STATEMENT_SETTINGS"] = 29] = "STATEMENT_SETTINGS";
    SCREEN[SCREEN["STATUS"] = 30] = "STATUS";
    SCREEN[SCREEN["SUCCESS"] = 31] = "SUCCESS";
    SCREEN[SCREEN["TRANSITION"] = 32] = "TRANSITION";
    SCREEN[SCREEN["VERIFICATION_FAILED"] = 33] = "VERIFICATION_FAILED";
    SCREEN[SCREEN["APPLICATION_IN_REVIEW"] = 34] = "APPLICATION_IN_REVIEW";
    SCREEN[SCREEN["APPLICATION_NOT_APPROVED_WITHIN_COOLDOWN"] = 35] = "APPLICATION_NOT_APPROVED_WITHIN_COOLDOWN";
    SCREEN[SCREEN["APPLICATION_NOT_APPROVED_AFTER_COOLDOWN"] = 36] = "APPLICATION_NOT_APPROVED_AFTER_COOLDOWN";
    SCREEN[SCREEN["CREDIT_FROZEN"] = 37] = "CREDIT_FROZEN";
    SCREEN[SCREEN["APPLICATION_EXPIRED"] = 38] = "APPLICATION_EXPIRED";
    SCREEN[SCREEN["REWARDS"] = 39] = "REWARDS";
    SCREEN[SCREEN["ELIGIBLE"] = 40] = "ELIGIBLE";
    SCREEN[SCREEN["REAPPLY"] = 41] = "REAPPLY";
    SCREEN[SCREEN["FIND_ACCOUNT"] = 42] = "FIND_ACCOUNT";
    SCREEN[SCREEN["FIND_ACCOUNT_ERROR"] = 43] = "FIND_ACCOUNT_ERROR";
    SCREEN[SCREEN["WELCOME"] = 44] = "WELCOME";
    SCREEN[SCREEN["CONFIRM_PHONE"] = 45] = "CONFIRM_PHONE";
    SCREEN[SCREEN["CONFIRM_EMAIL"] = 46] = "CONFIRM_EMAIL";
    SCREEN[SCREEN["GO_PAPERLESS"] = 47] = "GO_PAPERLESS";
})(SCREEN || (SCREEN = {}));
var initialState = {
    screen: SCREEN.LOGIN,
    previousScreen: SCREEN.LOGIN,
    screenContext: ScreenStateContext.None,
};
var screenStateSlice = createSlice({
    name: 'screenState',
    initialState: initialState,
    reducers: {
        setScreen: function (state, action) {
            state.screen = action.payload;
        },
        setPreviousScreen: function (state, action) {
            state.previousScreen = action.payload;
        },
        setScreenContext: function (state, action) {
            state.screenContext = action.payload;
        },
        resetScreenState: function () { return initialState; },
    },
});
export var setScreen = (_a = screenStateSlice.actions, _a.setScreen), setPreviousScreen = _a.setPreviousScreen, setScreenContext = _a.setScreenContext, resetScreenState = _a.resetScreenState;
export default screenStateSlice.reducer;
