var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { PKG } from '../../analytics/analyticsConsts';
import { setLoading } from '../../app/applicationScreenSlice';
import { ANALYTICS } from '../../firebase/firebase';
import { METHOD, Request } from '../../utils/http';
import { URL } from '../apiConstants';
export var CardActions;
(function (CardActions) {
    CardActions["REISSUE"] = "REISSUE";
    CardActions["ACTIVATE"] = "ACTIVATE";
    CardActions["DEACTIVATE"] = "DEACTIVATE";
})(CardActions || (CardActions = {}));
export var CardActionReason;
(function (CardActionReason) {
    CardActionReason["LOST"] = "LOST";
    CardActionReason["STOLEN"] = "STOLEN";
    CardActionReason["DAMAGED"] = "DAMAGED";
    CardActionReason["USER_REQUESTED"] = "USER_REQUESTED";
    CardActionReason["NONE"] = "";
})(CardActionReason || (CardActionReason = {}));
function physicalCardResponseToString(response) {
    var cardUUID = response.cardUUID, status = response.status;
    return "\n    Card UUID: ".concat(cardUUID, "\n    Status: ").concat(status, "\n  ");
}
export var requestNewCardNumber = function (_a) {
    var cardUUID = _a.cardUUID, last4 = _a.last4, onSuccess = _a.onSuccess, onError = _a.onError;
    return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
        var auth, deviceID, token, _a, requestNewCardNumberResponse, requestNewCardNumberError;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    auth = getState().authSlice;
                    deviceID = getState().apiScreenScreenSelector.deviceID;
                    token = auth.token;
                    dispatch(setLoading(true));
                    return [4 /*yield*/, physicalCardRequestHelper(CardActions.REISSUE, cardUUID, deviceID, token, undefined, CardActionReason.USER_REQUESTED)];
                case 1:
                    _a = _b.sent(), requestNewCardNumberResponse = _a[0], requestNewCardNumberError = _a[1];
                    if (requestNewCardNumberError || !requestNewCardNumberResponse) {
                        if (requestNewCardNumberError) {
                            ANALYTICS.funcError(PKG, 'requestNewCardNumber', physicalCardResponseToString(requestNewCardNumberError));
                        }
                        dispatch(setLoading(false));
                        onError(requestNewCardNumberError === null || requestNewCardNumberError === void 0 ? void 0 : requestNewCardNumberError.code);
                        return [2 /*return*/];
                    }
                    onSuccess();
                    return [2 /*return*/];
            }
        });
    }); };
};
export var replaceCard = function (_a) {
    var cardUUID = _a.cardUUID, reason = _a.reason, onSuccess = _a.onSuccess, onError = _a.onError;
    return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
        var auth, deviceID, token, _a, replaceCardResponse, replaceCardError;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    auth = getState().authSlice;
                    deviceID = getState().apiScreenScreenSelector.deviceID;
                    token = auth.token;
                    dispatch(setLoading(true));
                    return [4 /*yield*/, physicalCardRequestHelper(CardActions.REISSUE, cardUUID, deviceID, token, undefined, reason)];
                case 1:
                    _a = _b.sent(), replaceCardResponse = _a[0], replaceCardError = _a[1];
                    if (replaceCardError || !replaceCardResponse) {
                        if (replaceCardError) {
                            ANALYTICS.funcError(PKG, 'replaceCard', physicalCardResponseToString(replaceCardError));
                        }
                        onError(replaceCardError === null || replaceCardError === void 0 ? void 0 : replaceCardError.code);
                        dispatch(setLoading(false));
                        return [2 /*return*/];
                    }
                    onSuccess();
                    return [2 /*return*/];
            }
        });
    }); };
};
export var requestNewPhysicalCard = function (_a) {
    var token = _a.token, productAccountUUID = _a.productAccountUUID, deviceID = _a.deviceID, onSuccess = _a.onSuccess, onError = _a.onError;
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var _a, requestPhysicalCardResponse, requestPhysicalCardError;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    dispatch(setLoading(true));
                    return [4 /*yield*/, requestPhysicalCardHelper(productAccountUUID, CardType.PHYSICAL, token, deviceID)];
                case 1:
                    _a = _b.sent(), requestPhysicalCardResponse = _a[0], requestPhysicalCardError = _a[1];
                    if (requestPhysicalCardError || !requestPhysicalCardResponse) {
                        if (requestPhysicalCardError) {
                            onError && onError(requestPhysicalCardError === null || requestPhysicalCardError === void 0 ? void 0 : requestPhysicalCardError.code);
                            ANALYTICS.funcError(PKG, 'requestPhysicalCard', physicalCardResponseToString(requestPhysicalCardError));
                        }
                        return [2 /*return*/];
                    }
                    onSuccess && onSuccess();
                    dispatch(setLoading(false));
                    return [2 /*return*/];
            }
        });
    }); };
};
var CardType;
(function (CardType) {
    CardType["PHYSICAL"] = "PHYSICAL";
    CardType["VIRTUAL"] = "VIRTUAL";
})(CardType || (CardType = {}));
export var requestPhysicalCardHelper = function (productAccountUUID, cardType, token, deviceID) { return __awaiter(void 0, void 0, void 0, function () {
    var data, error_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, Request({
                        url: "".concat(URL.CARD_ENDPOINT),
                        method: METHOD.POST,
                        body: {
                            productAccountUUID: productAccountUUID,
                            cardType: cardType,
                        },
                        token: token,
                        deviceID: deviceID,
                    })];
            case 1:
                data = _a.sent();
                return [2 /*return*/, [data, null]];
            case 2:
                error_1 = _a.sent();
                return [2 /*return*/, [null, error_1]];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var physicalCardRequestHelper = function (action, cardUUID, deviceID, token, last4, reason) { return __awaiter(void 0, void 0, void 0, function () {
    var getRequestBody, data, error_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                getRequestBody = function () {
                    if (action === CardActions.ACTIVATE) {
                        return {
                            action: action,
                            reason: reason,
                            last4: last4,
                        };
                    }
                    else if (action === CardActions.DEACTIVATE) {
                        return {
                            action: action,
                            reason: reason,
                        };
                    }
                    else if (action === CardActions.REISSUE) {
                        return {
                            action: action,
                            reason: reason,
                        };
                    }
                    return {
                        action: action,
                        reason: reason,
                        last4: last4,
                    };
                };
                return [4 /*yield*/, Request({
                        url: "".concat(URL.CARD_ENDPOINT, "/").concat(cardUUID),
                        method: METHOD.PUT,
                        body: getRequestBody(),
                        deviceID: deviceID,
                        token: token,
                    })];
            case 1:
                data = _a.sent();
                return [2 /*return*/, [data, null]];
            case 2:
                error_2 = _a.sent();
                return [2 /*return*/, [null, error_2]];
            case 3: return [2 /*return*/];
        }
    });
}); };
