var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ImpActionPage, ImpButton, ImpSpacer, ImpText, colors } from '@imprint-payments/imprint-ui';
import { useEffect } from 'react';
import { EVENT } from '../../analytics/analyticsConsts';
import { useAppSelector } from '../../app/hooks';
import { selectLanguage } from '../../app/languageSelectors';
import { selectMerchantData } from '../../app/merchantDataSelectors';
import { imprintSupportPhone } from '../../assets/consts/const';
import { HEADER_SERVICING_HEIGHT } from '../../components/HeaderServicing/HeaderServicing';
import { ANALYTICS } from '../../firebase/firebase';
import { formatPhoneNumber } from '../../utils/formatUtils';
export function IDVErrorPage() {
    var language = useAppSelector(selectLanguage);
    var merchantData = useAppSelector(selectMerchantData);
    useEffect(function () {
        ANALYTICS.logEvent(EVENT.UPDATE_PHONE_IDV_ERROR_VIEWED, {
            product_uuid: merchantData.productUUID,
        });
    }, []);
    var handleCallSupport = function () {
        ANALYTICS.logEvent(EVENT.UPDATE_PHONE_IDV_ERROR_SUPPORT_CLICKED, {
            product_uuid: merchantData.productUUID,
        });
        window.location.href = "tel:+1".concat(imprintSupportPhone);
    };
    return (_jsxs(ImpActionPage, __assign({ footer: _jsxs(ImpButton, __assign({ variant: "secondary", onClick: handleCallSupport }, { children: [language.contact, " ", formatPhoneNumber(imprintSupportPhone)] })), offset: HEADER_SERVICING_HEIGHT }, { children: [_jsx(ImpSpacer, { height: "2xl" }), _jsx(ImpText, __assign({ typography: "headline2" }, { children: language.unableToCompleteSetup })), _jsx(ImpSpacer, { height: "xs" }), _jsx(ImpText, __assign({ typography: "body2", color: colors.content.onSurfaceVariant }, { children: language.contactSupportForHelpSubtitle })), _jsx(ImpSpacer, { height: "xl" })] })));
}
