var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ImpBreadcrumbs, ImpButton, ImpContainer, ImpFlex, ImpFlexItem, ImpList, ImpSpacer, ImpText, } from '@imprint-payments/imprint-ui';
import HeaderServicing from '../../components/HeaderServicing/HeaderServicing';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { RewardsRedemptionPage, setRewardsRedemptionPage, } from '../../app/rewardsRedemptionSlice';
import { redeemRewards } from '../../api/redeemRewards/redeemRewards';
import { createDisplayAmount, formatDisplayAmount, } from '@imprint-payments/imprint-lib';
import { currencyToMinUnit, currencyToNumber, formatPaymentAccount, } from '../../utils/formatUtils';
import { RewardRedemptionType } from '../../types/RewardRedemptionType';
import { displayTemporarySnackbar, getAddressText, navigateToAccountPage, } from '../../utils/functionUtils';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { ANALYTICS } from '../../firebase/firebase';
import { EVENT } from '../../analytics/analyticsConsts';
var generateConfirmationItems = function (language, rewardsRedemptionState, homeSelector) {
    var displayNames = new Map([
        [RewardRedemptionType.StatementCredit, language.statementCredit],
        [RewardRedemptionType.BankWithdrawal, language.bankWithdrawal],
        [RewardRedemptionType.MailCheck, language.mailCheck],
    ]);
    var confirmationItems = [
        {
            title: language.redemptionAmount,
            subtitle: rewardsRedemptionState.redeemVal,
        },
        {
            title: language.redemptionType,
            subtitle: displayNames.get(rewardsRedemptionState.redemptionType),
        },
    ];
    if (rewardsRedemptionState.redemptionType ===
        RewardRedemptionType.BankWithdrawal) {
        confirmationItems.push({
            title: language.bank,
            subtitle: formatPaymentAccount(homeSelector.primaryPaymentAccount, language),
        });
    }
    else if (rewardsRedemptionState.redemptionType === RewardRedemptionType.MailCheck) {
        confirmationItems.push({
            title: language.personalInfoAddress,
            subtitle: getAddressText(homeSelector.userInformation.address),
        });
    }
    return confirmationItems;
};
export function ConfirmRedemptionPage() {
    var dispatch = useAppDispatch();
    var rewardsRedemptionState = useAppSelector(function (state) { return state.rewardsRedemptionSelector; });
    var language = useAppSelector(function (state) { return state.languageSelector.languageLocalization; });
    var homeSelector = useAppSelector(function (state) { return state.homeSelector; });
    var selectedProductAccount = useAppSelector(function (state) { return state.homeSelector.selectedProductAccount; });
    var _a = useState(false), isSubmitting = _a[0], setIsSubmitting = _a[1];
    var productAccount = useAppSelector(function (state) { return state.productAccountSelector; });
    var navigate = useNavigate();
    useEffect(function () {
        ANALYTICS.logEvent(EVENT.REWARDS_CONFIRM_VIEWED, {
            card_balance: formatDisplayAmount(productAccount.cardBalanceV2.totalSpend),
            available_balance: formatDisplayAmount(productAccount.rewards.rewardBalances.balances.available.amount),
            redeeming: rewardsRedemptionState.redeemVal,
            method: rewardsRedemptionState.redemptionType,
        });
    }, []);
    var getSuccessText = function () {
        var toDisplay = '';
        switch (rewardsRedemptionState.redemptionType) {
            case RewardRedemptionType.StatementCredit:
                toDisplay = language.successStatementCredit;
                break;
            case RewardRedemptionType.BankWithdrawal:
                toDisplay = language.successWithdraw;
                break;
            case RewardRedemptionType.MailCheck:
                toDisplay = language.successMail;
                break;
        }
        toDisplay = toDisplay.replace('{amount}', rewardsRedemptionState.redeemVal);
        return toDisplay;
    };
    var onSuccess = function () {
        setIsSubmitting(false);
        navigateToAccountPage(dispatch, navigate);
        displayTemporarySnackbar(getSuccessText(), dispatch, 'success');
    };
    var onError = function () {
        setIsSubmitting(false);
        displayTemporarySnackbar(language.somethingWentWrong, dispatch);
    };
    var handleActionButtonConfirmClick = function () {
        var _a;
        ANALYTICS.logEvent(EVENT.REWARDS_CONFIRM_ACTION_BUTTON_CLICKED, {
            card_balance: formatDisplayAmount(productAccount.cardBalanceV2.totalSpend),
            available_balance: formatDisplayAmount(productAccount.rewards.rewardBalances.balances.available.amount),
            redeeming: rewardsRedemptionState.redeemVal,
            method: rewardsRedemptionState.redemptionType,
        });
        var displayValue = String(currencyToNumber(rewardsRedemptionState.redeemVal));
        // TODO: once new version of imprint-lib is merged, we can remove this
        var sanitizedDisplayval = displayValue.replace(/,/g, '');
        var valueInMinUnit = currencyToMinUnit(sanitizedDisplayval);
        var amount = createDisplayAmount({
            valueInMinUnit: valueInMinUnit,
            displayValue: displayValue,
        });
        var redemptionType = rewardsRedemptionState.redemptionType;
        var paymentAccountUUID = (_a = homeSelector.primaryPaymentAccount) === null || _a === void 0 ? void 0 : _a.paymentAccountUUID;
        setIsSubmitting(true);
        redeemRewards({
            amount: amount,
            redemptionType: redemptionType,
            paymentAccountUUID: paymentAccountUUID,
            productAccountUUID: selectedProductAccount === null || selectedProductAccount === void 0 ? void 0 : selectedProductAccount.productAccountUUID,
            onError: onError,
            onSuccess: onSuccess,
        });
    };
    var handleGoBack = function () {
        ANALYTICS.logEvent(EVENT.REWARDS_CONFIRM_SECONDARY_BUTTON_CLICKED, {
            card_balance: formatDisplayAmount(productAccount.cardBalanceV2.totalSpend),
            available_balance: formatDisplayAmount(productAccount.rewards.rewardBalances.balances.available.amount),
            redeeming: rewardsRedemptionState.redeemVal,
            method: rewardsRedemptionState.redemptionType,
        });
        dispatch(setRewardsRedemptionPage(RewardsRedemptionPage.ConfigureRedemption));
    };
    var confirmationItems = generateConfirmationItems(language, rewardsRedemptionState, homeSelector);
    return (_jsxs(ImpContainer, { children: [_jsx(HeaderServicing, {}), _jsx(ImpBreadcrumbs, { breadcrumbs: [language.useRewards], onBackClick: handleGoBack, onCancelClick: function () { return navigateToAccountPage(dispatch, navigate); } }), _jsx(ImpSpacer, { height: "2xl" }), _jsx(ImpFlex, __assign({ direction: "column", align: "center", fluid: true }, { children: _jsx(ImpContainer, __assign({ width: "min(748px, 100vw - 32px)", textAlign: "center" }, { children: _jsxs(ImpFlex, __assign({ direction: "column", align: "center", fluid: true }, { children: [_jsx(ImpText, __assign({ typography: "headline2" }, { children: language.confirmRedemption })), _jsx(ImpSpacer, { height: "2xl" }), _jsx(ImpContainer, __assign({ textAlign: "left", width: "100%" }, { children: _jsx(ImpList, { useSurface: true, items: confirmationItems }) })), _jsx(ImpSpacer, { height: "2xl" }), _jsx(ImpContainer, __assign({ width: "100%" }, { children: _jsxs(ImpFlex, __assign({ direction: "row", gap: "xl", mobileGap: "l", align: "center", mobileDirection: "column", mobileAlign: "stretch", fluid: true }, { children: [_jsx(ImpFlexItem, __assign({ flex: "1 1 0" }, { children: _jsx(ImpButton, __assign({ buttonStyle: "branded", variant: "secondary", onClick: handleGoBack, state: isSubmitting ? 'disabled' : 'enabled', size: "fill" }, { children: language.editRedemption })) })), _jsx(ImpFlexItem, __assign({ flex: "1 1 0" }, { children: _jsx(ImpButton, __assign({ buttonStyle: "branded", state: isSubmitting ? 'submitting' : 'enabled', onClick: handleActionButtonConfirmClick, size: "fill" }, { children: language.confirmBtn })) }))] })) }))] })) })) }))] }));
}
