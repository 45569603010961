var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useRef, useEffect } from 'react';
import styles from './Toggle.module.css';
import SimpleSpinner from '../spinner/SimpleSpinner';
export function Toggle(_a) {
    var checked = _a.checked, _b = _a.loading, loading = _b === void 0 ? false : _b, callback = _a.callback;
    var _c = useState(checked || false), isChecked = _c[0], setIsChecked = _c[1];
    var inputRef = useRef(null);
    useEffect(function () {
        setIsChecked(checked || false);
    }, [checked]);
    var handleChange = function (event) {
        var isChecked = event.target.checked;
        setIsChecked(isChecked);
        callback && callback(isChecked);
    };
    return (_jsx("div", __assign({ className: styles.container }, { children: loading ? (_jsx(SimpleSpinner, {})) : (_jsxs(_Fragment, { children: [_jsx("input", { ref: inputRef, checked: isChecked, type: "checkbox", onChange: handleChange, id: "toggle" }), _jsx("div", { className: styles.track })] })) })));
}
