export var selectMerchantData = function (state) { return state.merchantDataSelector; };
export var selectCardTerms = function (state) { return state.merchantDataSelector.cardTerms; };
export var selectRewardsBonus = function (state) {
    return state.merchantDataSelector.rewardDetails.rewardsBonus;
};
export var selectRewardsTiers = function (state) {
    return state.merchantDataSelector.rewardDetails.rewardsTiers;
};
export var selectRewardsTitle = function (state) {
    return state.merchantDataSelector.rewardDetails.rewardDetailsHeader;
};
export var selectCardBenefits = function (state) {
    return state.merchantDataSelector.rewardDetails.cardBenefits;
};
export var selectProductUUID = function (state) { return state.merchantDataSelector.productUUID; };
