import { getEmptyAmount } from './Amount';
import { getEmptyDate } from './Date';
import { getEmptyStatementSpendDisplay } from './StatementSpendDisplay';
export var getEmptyPaymentDetails = function () {
    return {
        minDue: getEmptyAmount(),
        dueDay: getEmptyDate(),
        hasDue: false,
        dueDayLabel: '',
        pendingPayment: getEmptyAmount(),
        processedPayment: getEmptyStatementSpendDisplay(),
    };
};
