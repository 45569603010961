var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Icons, ImpButton, ImpContainer, ImpFlex, ImpFlexItem, ImpIcon, ImpOtpField, ImpPad, ImpSpacer, ImpText, } from '@imprint-payments/imprint-ui';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { EVENT } from '../../analytics/analyticsConsts';
import { loadProductAccount, } from '../../api/loadProductAccount/loadProductAccount';
import { activatePhysicalCard } from '../../api/physicalCardActions/activatePhysicalCard.api';
import { deactivatePhysicalCard } from '../../api/physicalCardActions/deactivatePhysicalCard.api';
import { PINContext, verifyPin } from '../../api/verifyPin.api';
import { setRevealCardNumber } from '../../app/homeSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { SCREEN, ScreenStateContext, setScreen, setScreenContext, } from '../../app/screenStateSlice';
import { AuthUserIcon } from '../../assets/icons/AuthUserIcon';
import { CustomArrowLeft } from '../../assets/icons/CustomArrowLeft';
import { CustomEyeIcon } from '../../assets/icons/CustomEyeIcon';
import { CustomLockIcon } from '../../assets/icons/CustomLockIcon';
import { ANALYTICS } from '../../firebase/firebase';
import { displayTemporarySnackbar, logOutUser } from '../../utils/functionUtils';
import { PRODUCTUUID } from '../../utils/productUUIDConstants';
import { EXTERNAL_URLS, PAGE } from '../../utils/routeConstant';
import { ITEM_STYLE, LIST_STYLE, ListSection } from '../list/List';
import SimpleSpinner from '../spinner/SimpleSpinner';
import { Toggle } from '../Toggle/Toggle';
import styles from './CardDetailsPage.module.css';
import { CardWithDetails } from './CardWithDetails';
import { useStatsigClient } from '@statsig/react-bindings';
import { Gates } from "../../statsig/gates";
// TODO: must consolidate ProductAccount type
export var shouldShowActivatePhysicalCardOption = function (productAccount) {
    var cardActivationAvailable = productAccount.physicalCard &&
        !(productAccount.physicalCard.status === 'ACTIVE') &&
        (!productAccount.physicalCard.last4 || productAccount.physicalCard.last4 === '');
    if (cardActivationAvailable) {
        return true;
    }
    return false;
};
// TODO: must consolidate ProductAccount type
export var shouldShowAuthUserSection = function (featureFlags, productAccount) {
    return featureFlags.authUsers === 'true' && (productAccount === null || productAccount === void 0 ? void 0 : productAccount.authUserDetails) !== undefined;
};
export function CardDetailsPage(_a) {
    var _b, _c;
    var onGoBackClick = _a.onGoBackClick;
    var navigate = useNavigate();
    var dispatch = useAppDispatch();
    var homeSelector = useAppSelector(function (state) { return state.homeSelector; });
    var _d = useState((_b = homeSelector.revealCardNumber) !== null && _b !== void 0 ? _b : false), showCardNumber = _d[0], setShowCardNumber = _d[1];
    var merchantData = useAppSelector(function (state) { return state.merchantDataSelector; });
    var productAccount = useAppSelector(function (state) { return state.productAccountSelector; });
    var language = useAppSelector(function (state) { return state.languageSelector.languageLocalization; });
    var featureFlags = useAppSelector(function (state) { return state.featureFlagSelector.featureFlags; });
    var _e = useState(false), isRequestingPIN = _e[0], setIsRequestingPIN = _e[1];
    var checkGate = useStatsigClient().checkGate;
    var cardNumberFeatureEnabled = featureFlags.showCardNumberEnabled === 'true';
    // checks if PAN is available (not Stripe issued)
    var _f = useState(((_c = productAccount.virtualCard) === null || _c === void 0 ? void 0 : _c.pan) !== undefined), validMerchant = _f[0], setValidMerchant = _f[1];
    var MAX_CARD_NUMBER_DISPLAY = 60 * 1000 * 2; // 2 minutes
    var timeoutIDRef = useRef(null);
    var showGetPhysicalCard = !productAccount.physicalCard && productAccount.productDetails.physicalCardOptionAvailable;
    // Lock Physical card
    var physicalCardInfo = useAppSelector(function (state) { return state.productAccountSelector.physicalCard; });
    var cardUUID = physicalCardInfo === null || physicalCardInfo === void 0 ? void 0 : physicalCardInfo.cardUUID;
    var last4 = (physicalCardInfo === null || physicalCardInfo === void 0 ? void 0 : physicalCardInfo.last4) || '';
    var physicalCardActivated = !(!(physicalCardInfo === null || physicalCardInfo === void 0 ? void 0 : physicalCardInfo.last4) || physicalCardInfo.last4 === '');
    var physicalCardLocked = (physicalCardInfo === null || physicalCardInfo === void 0 ? void 0 : physicalCardInfo.status) !== 'ACTIVE';
    var _g = useState(false), isLoadingLockingPhysicalCard = _g[0], setIsLoadingLockingPhysicalCard = _g[1];
    function onSuccessLockingPhysicalCard() {
        loadProductAccount({
            productAccountUUID: homeSelector.selectedProductAccount.productAccountUUID,
        });
        displayTemporarySnackbar(language.physicalCardLocked, dispatch);
        setIsLoadingLockingPhysicalCard(false);
    }
    function onErrorLockingPhysicalCard() {
        displayTemporarySnackbar(language.somethingWentWrong, dispatch);
        setIsLoadingLockingPhysicalCard(false);
    }
    function onSuccessUnlockingPhysicalCard() {
        loadProductAccount({
            productAccountUUID: homeSelector.selectedProductAccount.productAccountUUID,
        });
        displayTemporarySnackbar(language.physicalCardUnlocked, dispatch);
        setIsLoadingLockingPhysicalCard(false);
    }
    function onErrorUnlockingPhysicalCard() {
        displayTemporarySnackbar(language.somethingWentWrong, dispatch);
        setIsLoadingLockingPhysicalCard(false);
    }
    var getPhysicalCardOptions = function () {
        var _a, _b, _c, _d, _e;
        var physicalCardOptions = [];
        if (shouldShowActivatePhysicalCardOption(productAccount)) {
            physicalCardOptions.push({
                title: language.activatePhysicalCard,
                titleTopBottomMargin: true,
                leadingIconComponent: (_jsx(ImpIcon, { iconSrc: Icons.card, size: "24px", stroke: "var(--merchant-color)" })),
                style: ITEM_STYLE.CHEVRON_RIGHT,
                handleClick: function () {
                    ANALYTICS.logEvent(EVENT.ACTIVATE_PHYSICAL_CARD_CLICKED);
                    navigate(PAGE.ACTIVATE_PHYSICAL_CARD);
                },
            });
        }
        if (shouldShowAuthUserSection(featureFlags, productAccount)) {
            var numSharedCards_1 = ((_b = (_a = productAccount.authUserDetails) === null || _a === void 0 ? void 0 : _a.authorizedUsers) === null || _b === void 0 ? void 0 : _b.length) || 0;
            var maxSharedCards_1 = ((_c = productAccount.authUserDetails) === null || _c === void 0 ? void 0 : _c.limitOfAuthorizedUsers) || 0;
            var numPendingCards_1 = ((_e = (_d = productAccount.authUserDetails) === null || _d === void 0 ? void 0 : _d.pendingAuthorizedUsers) === null || _e === void 0 ? void 0 : _e.length) || 0;
            var handleClick = function () {
                if (numSharedCards_1 === 0 && numPendingCards_1 === 0) {
                    navigate(PAGE.ADD_AUTHORIZED_USER);
                }
                else {
                    ANALYTICS.logEvent(EVENT.AUTH_USER_MANAGE_USERS_CLICKED);
                    navigate(PAGE.AUTHORIZED_USERS_PAGE);
                }
            };
            var getTitle = function () {
                if (numSharedCards_1 === maxSharedCards_1 || numPendingCards_1 > 0) {
                    return language.manageAuthorizedUsers;
                }
                return language.addAuthorizedUser;
            };
            var getSubtitle = function () {
                if (numSharedCards_1 === 0) {
                    return undefined;
                }
                else if (numSharedCards_1 === 1) {
                    return language.oneSharedCard;
                }
                else {
                    return language.xSharedCards.replace('%', numSharedCards_1.toString());
                }
            };
            physicalCardOptions.push({
                title: getTitle(),
                titleTopBottomMargin: true,
                leadingIconComponent: _jsx(AuthUserIcon, { stroke: merchantData.color.light }),
                style: ITEM_STYLE.CHEVRON_RIGHT,
                handleClick: handleClick,
                subtitle: getSubtitle(),
            });
        }
        // Add lock/unlock physical card option if the card is activated
        // we show regular items if the card is shipped
        if (physicalCardActivated && cardUUID) {
            var replacePhysicalCard = {
                title: language.replaceCard,
                titleTopBottomMargin: true,
                style: ITEM_STYLE.CHEVRON_RIGHT,
                leadingIconComponent: (_jsx(ImpIcon, { iconSrc: Icons.repeat, size: "24px", stroke: "var(--merchant-color)" })),
                handleClick: function () {
                    navigate(PAGE.REPLACE_CARD);
                },
            };
            var lockPhysicalCard = {
                title: language.lockPhysicalCard,
                titleTopBottomMargin: true,
                style: ITEM_STYLE.TOGGLE,
                leadingIconComponent: _jsx(CustomLockIcon, { stroke: merchantData.color.light }),
                trailingComponent: (_jsx(Toggle, { checked: physicalCardLocked, loading: isLoadingLockingPhysicalCard })),
                handleClick: function () {
                    setIsLoadingLockingPhysicalCard(true);
                    if (physicalCardLocked) {
                        activatePhysicalCard({
                            cardUUID: cardUUID,
                            last4: last4,
                            onSuccess: onSuccessUnlockingPhysicalCard,
                            onError: onErrorUnlockingPhysicalCard,
                        });
                    }
                    else {
                        deactivatePhysicalCard({
                            cardUUID: cardUUID,
                            onSuccess: onSuccessLockingPhysicalCard,
                            onError: onErrorLockingPhysicalCard,
                        });
                    }
                },
            };
            physicalCardOptions.unshift(replacePhysicalCard);
            physicalCardOptions.unshift(lockPhysicalCard);
        }
        else if (showGetPhysicalCard) {
            // we show option to get physical card if the card is not shipped
            var getPhysicalCard = {
                title: language.getPhysicalCard,
                titleTopBottomMargin: true,
                style: ITEM_STYLE.CHEVRON_RIGHT,
                leadingIconComponent: (_jsx(ImpIcon, { iconSrc: Icons.card, size: "24px", stroke: "var(--merchant-color)" })),
                handleClick: function () {
                    navigate(PAGE.GET_PHYSICAL_CARD);
                },
            };
            physicalCardOptions.unshift(getPhysicalCard);
        }
        return physicalCardOptions;
    };
    useEffect(function () {
        if (showCardNumber) {
            resetCardNumberTimeout();
            window.addEventListener('click', resetCardNumberTimeout);
            window.addEventListener('touchstart', resetCardNumberTimeout);
            window.addEventListener('touchend', resetCardNumberTimeout);
            window.addEventListener('keydown', resetCardNumberTimeout);
        }
        else {
            window.removeEventListener('click', resetCardNumberTimeout);
            window.removeEventListener('touchstart', resetCardNumberTimeout);
            window.removeEventListener('touchend', resetCardNumberTimeout);
            window.removeEventListener('keydown', resetCardNumberTimeout);
        }
        return function () {
            window.removeEventListener('click', resetCardNumberTimeout);
            window.removeEventListener('touchstart', resetCardNumberTimeout);
            window.removeEventListener('touchend', resetCardNumberTimeout);
            window.removeEventListener('keydown', resetCardNumberTimeout);
        };
    }, [showCardNumber]);
    function resetCardNumberTimeout() {
        if (timeoutIDRef.current) {
            clearTimeout(timeoutIDRef.current);
        }
        timeoutIDRef.current = setTimeout(function () {
            ANALYTICS.logEvent(EVENT.CARD_DETAILS_TIMEOUT_TRIGGERED);
            setShowCardNumber(false);
        }, MAX_CARD_NUMBER_DISPLAY);
    }
    useEffect(function () {
        var _a;
        setValidMerchant(((_a = productAccount.virtualCard) === null || _a === void 0 ? void 0 : _a.pan) !== undefined);
    }, [productAccount.virtualCard.pan]);
    useEffect(function () {
        ANALYTICS.logEvent(EVENT.CARD_MENU_VIEWED);
    }, []);
    // Handle the browser back action
    useEffect(function () {
        window.addEventListener('popstate', onGoBackClick);
        return function () {
            window.removeEventListener('popstate', onGoBackClick);
        };
    }, []);
    useEffect(function () {
        return function () {
            dispatch(setRevealCardNumber(false));
        };
    }, []);
    var virtualCardOptions = [];
    if (cardNumberFeatureEnabled && validMerchant) {
        virtualCardOptions.push.apply(virtualCardOptions, [
            {
                title: language.showCardNumber,
                titleTopBottomMargin: true,
                style: ITEM_STYLE.TOGGLE,
                leadingIconComponent: _jsx(CustomEyeIcon, { stroke: merchantData.color.light }),
                trailingComponent: _jsx(Toggle, { checked: showCardNumber, loading: isRequestingPIN }),
                handleClick: function () {
                    var _a;
                    if (!showCardNumber) {
                        ANALYTICS.logEvent(EVENT.CARD_DETAILS_SHOW_CARD_NUMBER_CLICKED);
                        if (((_a = productAccount.productDetails) === null || _a === void 0 ? void 0 : _a.productUUID) === PRODUCTUUID.WG) {
                            setIsRequestingPIN(!isRequestingPIN);
                        }
                        else {
                            setShowCardNumber(true);
                        }
                    }
                    else {
                        ANALYTICS.logEvent(EVENT.CARD_DETAILS_HIDE_CARD_NUMBER_CLICKED);
                        setShowCardNumber(!showCardNumber);
                    }
                },
            },
            {
                title: language.getNewCardNumber,
                titleTopBottomMargin: true,
                style: ITEM_STYLE.CHEVRON_RIGHT,
                leadingIconComponent: (_jsx(ImpIcon, { iconSrc: Icons.cardNumber, size: "24px", stroke: "var(--merchant-color)" })),
                handleClick: function () {
                    ANALYTICS.logEvent(EVENT.GET_NEW_CARD_NUMBER_CLICKED);
                    navigate(PAGE.GET_NEW_CARD_NUMBER);
                },
            },
        ]);
        if (checkGate(Gates.displayCardLift)) {
            virtualCardOptions.push({
                title: language.addYourCardToChrome,
                subtitle: language.checkoutWithCard.replace('[MERCHANT_NAME]', merchantData.merchantName),
                style: ITEM_STYLE.NAVIGATE,
                leadingIconComponent: (_jsx(ImpIcon, { iconSrc: Icons.chromeLogo, size: "24px", stroke: "var(--merchant-color)" })),
                handleClick: function () {
                    ANALYTICS.logEvent(EVENT.CARDLIFT_DOWNLOAD_DETAIL_CLICKED);
                    window.open(EXTERNAL_URLS.cardLiftDownload);
                },
            });
        }
    }
    var emptyPage = !shouldShowActivatePhysicalCardOption(productAccount) &&
        !shouldShowAuthUserSection(featureFlags, productAccount);
    var goBackItem = [
        {
            title: language.goBackBtn,
            titleTopBottomMargin: true,
            leadingIconComponent: _jsx(CustomArrowLeft, { stroke: merchantData.color.light }),
            handleClick: onGoBackClick,
        },
    ];
    var moreFeaturesComingSoon = [
        {
            title: language.moreFeaturesComingSoon,
            titleTopBottomMargin: true,
        },
    ];
    return (_jsxs("div", __assign({ className: styles.container }, { children: [isRequestingPIN && (_jsx(PinEntryOverlay, { onSuccess: function () {
                    ANALYTICS.logEvent(EVENT.CARD_DETAILS_CARD_NUMBER_VIEWED);
                    setIsRequestingPIN(false);
                    setShowCardNumber(true);
                }, onCancel: function () {
                    ANALYTICS.logEvent(EVENT.CARD_DETAILS_PIN_ENTRY_GO_BACK_CLICKED);
                    setIsRequestingPIN(false);
                } })), _jsxs(ImpContainer, __assign({ width: "min(1140px, 100vw - 32px)" }, { children: [_jsx(ImpSpacer, { height: "xl" }), _jsx(ImpFlex, __assign({ direction: "row", fluid: true, justify: "center" }, { children: _jsxs("div", __assign({ className: styles.cardDetailsContainer }, { children: [_jsx(ImpFlexItem, __assign({ flex: "0 0 auto" }, { children: _jsx(CardWithDetails, { showCardNumber: showCardNumber, virtualCard: productAccount.virtualCard, featureEnabled: cardNumberFeatureEnabled, validMerchant: validMerchant }) })), _jsxs(ImpFlex, __assign({ direction: "column", fluid: true }, { children: [_jsx(ListSection, { style: LIST_STYLE.DEFAULT, items: goBackItem }), _jsx(ListSection, { header: language.virtualCard, style: LIST_STYLE.DEFAULT, items: virtualCardOptions }), _jsx(ListSection, { header: language.physicalCard, style: LIST_STYLE.DEFAULT, items: getPhysicalCardOptions() }), emptyPage && (_jsx(ListSection, { header: ' ', style: LIST_STYLE.DEFAULT, items: moreFeaturesComingSoon }))] }))] })) }))] }))] })));
}
function PinEntryOverlay(_a) {
    var onSuccess = _a.onSuccess, onCancel = _a.onCancel;
    var dispatch = useAppDispatch();
    var _b = useState(''), input = _b[0], setInput = _b[1];
    var _c = useState(false), isLoading = _c[0], setIsLoading = _c[1];
    var _d = useState(false), incorrectDigitsError = _d[0], setIncorrectDigitsError = _d[1];
    var _e = useState(false), requestFailed = _e[0], setRequestFailed = _e[1];
    var language = useAppSelector(function (state) { return state.languageSelector.languageLocalization; });
    useEffect(function () {
        ANALYTICS.logEvent(EVENT.CARD_DETAILS_PIN_ENTRY_VIEWED);
    }, []);
    function handleChange(s) {
        if (input != '') {
            setRequestFailed(false);
            setIncorrectDigitsError(false);
        }
        setInput(s);
    }
    var handleFull = function (s) {
        ANALYTICS.logEvent(EVENT.CARD_DETAILS_PIN_ENTRY_SUBMITTED);
        setIsLoading(true);
        dispatch(verifyPin(s, PINContext.ViewCardNumber, handleSuccess, handleIncorrectResponse, handleError, handleRejection));
    };
    function handleSuccess() {
        setIsLoading(false);
        onSuccess();
    }
    function handleIncorrectResponse() {
        setIsLoading(false);
        setIncorrectDigitsError(true);
    }
    function handleError() {
        setIsLoading(false);
        setRequestFailed(true);
    }
    function handleRejection() {
        setIsLoading(false);
        logOutUser(dispatch).then(function () {
            dispatch(setScreen(SCREEN.VERIFICATION_FAILED));
            dispatch(setScreenContext(ScreenStateContext.FailedCardDetailsPIN));
        });
    }
    return (_jsx("div", __assign({ className: styles.pinEntryOverlay }, { children: _jsxs("div", __assign({ className: styles.pinEntryOverlayBody }, { children: [_jsx(ImpPad, __assign({ padding: "0 24px" }, { children: _jsxs(ImpFlex, __assign({ direction: "column", align: "center" }, { children: [_jsx(ImpSpacer, { height: "2xl" }), _jsx(ImpText, __assign({ typography: "headline2", mobileTypography: "headline3" }, { children: language.enterYourPin })), _jsx(ImpSpacer, { height: "xs" }), _jsx(ImpText, __assign({ typography: "body2" }, { children: language.cardNumberSubtitle })), _jsxs(ImpFlex, __assign({ direction: "column" }, { children: [_jsx(ImpSpacer, { height: "xl" }), _jsx(ImpOtpField, { autoFocus: true, numInputs: 4, onChange: handleChange, onFull: handleFull, clearWithError: incorrectDigitsError || requestFailed, mask: true })] })), _jsxs("div", __assign({ className: styles.messageContainer }, { children: [incorrectDigitsError && (_jsx("div", __assign({ className: styles.message }, { children: language.incorrectPIN }))), requestFailed && _jsx("div", __assign({ className: styles.message }, { children: language.somethingWentWrong }))] }))] })) })), _jsx("div", { className: styles.divider }), _jsx("div", __assign({ className: styles.pinEntryOverlayButton }, { children: isLoading ? (_jsx(SimpleSpinner, {})) : (_jsx(ImpButton, __assign({ variant: "text", buttonStyle: "imprint", onClick: onCancel }, { children: language.goBackBtn }))) }))] })) })));
}
