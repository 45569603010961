var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ImpButton, ImpFlex, ImpPad, ImpSpacer, ImpText, } from '@imprint-payments/imprint-ui';
import { useAppSelector } from '../../../app/hooks';
import { supportEmail } from '../../../assets/consts/const';
import { useEffect } from 'react';
import { ANALYTICS } from '../../../firebase/firebase';
import { EVENT } from '../../../analytics/analyticsConsts';
export function PendingApplicationModal(_a) {
    var closeModal = _a.closeModal;
    var language = useAppSelector(function (state) { return state.languageSelector.languageLocalization; });
    useEffect(function () {
        ANALYTICS.logEvent(EVENT.AUTH_USER_INCOMPLETE_VIEWED);
    }, []);
    return (_jsx(ImpFlex, __assign({ direction: "column", fluid: true, align: "center" }, { children: _jsx(ImpPad, __assign({ padding: "0px 32px 32px" }, { children: _jsxs(ImpFlex, __assign({ fluid: true, direction: "column", align: "center" }, { children: [_jsx(ImpText, __assign({ typography: "headline3" }, { children: language.authPendingAppTitle })), _jsx(ImpSpacer, { height: "m" }), _jsx(ImpText, __assign({ typography: "body2" }, { children: language.authPendingAppSubtitle.replace('%support%', supportEmail) })), _jsx(ImpSpacer, { height: "l" }), _jsx(ImpButton, __assign({ onClick: closeModal, variant: "text" }, { children: language.authPendingActionButton }))] })) })) })));
}
