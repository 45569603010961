var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ImpFlex } from '@imprint-payments/imprint-ui';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getColor } from '../../api/api';
import { clearAccountOnboarding } from '../../app/accountOnboardingSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { setShowDisclaimer } from '../../app/languageSlice';
import { selectMerchantData } from '../../app/merchantDataSelectors';
import { SCREEN, setScreen } from '../../app/screenStateSlice';
import HeaderServicing, { HEADER_SERVICING_TYPE, } from '../../components/HeaderServicing/HeaderServicing';
import LanguageDisclaimer from '../../components/LanguageDisclaimer/LanguageDisclaimer';
import { PAGE } from '../../utils/routeConstant';
import { FindAccountOtpPage } from '../FindYourAccount/FindAccountOtpPage';
import { ConfirmEmailPage } from '../confirmEmail/ConfirmEmailPage';
import { PaperlessPreferencePage } from '../paperlessPreference/PaperlessPreferencePage';
export default function AccountSetupFlow() {
    var dispatch = useAppDispatch();
    var navigate = useNavigate();
    var merchantData = useSelector(selectMerchantData);
    var screenState = useAppSelector(function (state) { return state.screenStateSelector; });
    var _a = useState(SCREEN.CONFIRM_EMAIL), currentScreen = _a[0], setCurrentScreen = _a[1];
    // Only allow screen navigation when the following values are set
    var allowedScreens = [
        SCREEN.CONFIRM_EMAIL,
        SCREEN.GO_PAPERLESS,
        SCREEN.APP_OTPSCREEN,
        SCREEN.WELCOME,
    ];
    var showingLanguageSelectDisclaimer = useAppSelector(function (state) { return state.languageSelector.showDisclaimer; });
    useEffect(function () {
        dispatch(setScreen(SCREEN.CONFIRM_EMAIL));
        if (!merchantData.productUUID)
            return;
        dispatch(getColor(merchantData.productUUID));
    }, []);
    useEffect(function () {
        // Prevent unexpected setScreen from loadHome response
        if (allowedScreens.includes(screenState.screen)) {
            if (screenState.screen === SCREEN.WELCOME) {
                navigate(PAGE.HOME);
                dispatch(clearAccountOnboarding());
                return;
            }
            setCurrentScreen(screenState.screen);
        }
    }, [screenState.screen]);
    var goBackToConfirmEmail = function () {
        dispatch(setScreen(SCREEN.CONFIRM_EMAIL));
    };
    var handleLanguageDisclaimerCancel = function () {
        dispatch(setScreen(currentScreen));
        dispatch(setShowDisclaimer(false));
    };
    var renderBody = function () {
        switch (currentScreen) {
            case SCREEN.CONFIRM_EMAIL:
                return _jsx(ConfirmEmailPage, {});
            case SCREEN.GO_PAPERLESS:
                return _jsx(PaperlessPreferencePage, {});
            case SCREEN.APP_OTPSCREEN:
                return _jsx(FindAccountOtpPage, { method: "email", onBackClick: goBackToConfirmEmail });
            default:
                return undefined;
        }
    };
    return showingLanguageSelectDisclaimer ? (_jsx(LanguageDisclaimer, { page: 'account_setup', onCancel: handleLanguageDisclaimerCancel })) : (_jsxs(ImpFlex, __assign({ direction: "column", align: "center" }, { children: [currentScreen !== SCREEN.WELCOME && _jsx(HeaderServicing, { type: HEADER_SERVICING_TYPE.LOGIN }), renderBody()] })));
}
