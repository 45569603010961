var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { ImpFlex } from '@imprint-payments/imprint-ui';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { setShowDisclaimer } from '../../app/languageSlice';
import { SCREEN, setScreen } from '../../app/screenStateSlice';
import HeaderServicing, { HEADER_SERVICING_TYPE, } from '../../components/HeaderServicing/HeaderServicing';
import LanguageDisclaimer from '../../components/LanguageDisclaimer/LanguageDisclaimer';
import { LoginCreatePin } from '../../components/pinEntry/LoginCreatePin';
import PinEntry from '../../components/pinEntry/PinEntry';
import useNextPage from '../../hooks/nextPage';
import { PRODUCTUUID } from '../../utils/productUUIDConstants';
import { ErrorPage } from '../ForgotPinFlow/ErrorPage';
import ForgotPinFlowV2 from '../ForgotPinFlow/ForgotPinFlowV2';
import { ConfirmPhonePage } from '../confirmPhone/ConfirmPhonePage';
import { ConvertedAccountFoundPage } from '../convertedAccount/ConvertedAccountFoundPage';
import { FindAccountErrorPage } from './FindAccountErrorPage';
import { FindAccountIDVPage } from './FindAccountIDVPage';
import { FindAccountOtpPage } from './FindAccountOtpPage';
import { FindYourAccountPage } from './FindYourAccountPage';
import { IDVErrorPage } from './IDVErrorPage';
export default function FindYourAccountFlow() {
    var dispatch = useAppDispatch();
    var nextPage = useNextPage();
    var screenState = useAppSelector(function (state) { return state.screenStateSelector; });
    var productUUID = useAppSelector(function (state) { return state.merchantDataSelector.productUUID; });
    var _a = useState(SCREEN.FIND_ACCOUNT), currentScreen = _a[0], setCurrentScreen = _a[1];
    // Only allow screen navigation when the following values are set
    var allowedScreens = [
        SCREEN.FIND_ACCOUNT,
        SCREEN.FIND_ACCOUNT_ERROR,
        SCREEN.WELCOME,
        SCREEN.CONFIRM_PHONE,
        SCREEN.IDV,
        SCREEN.IDV_ERROR,
        SCREEN.PIN,
        SCREEN.PIN_CREATE,
        SCREEN.FORGOT_PIN,
        SCREEN.SERVICE_OTPSCREEN,
    ];
    var showingLanguageSelectDisclaimer = useAppSelector(function (state) { return state.languageSelector.showDisclaimer; });
    useEffect(function () {
        dispatch(setScreen(SCREEN.FIND_ACCOUNT));
    }, []);
    useEffect(function () {
        switch (screenState.screen) {
            case SCREEN.CONFIRM_EMAIL: // converted user onboarding
            case SCREEN.ACCOUNT: // after enter PIN
                nextPage();
                return;
            case SCREEN.LOGIN:
                // Ignore any setScreen(SCREEN.LOGIN) in this flow, e.g. change language
                // Go to login should use navigate, not setScreen
                return;
            default:
                if (allowedScreens.includes(screenState.screen)) {
                    setCurrentScreen(screenState.screen);
                }
                else if (productUUID !== PRODUCTUUID.BB) {
                    // Handle non-BB accounts as usual, e.g. offer, review
                    nextPage();
                }
                return;
        }
    }, [screenState.screen, productUUID]);
    var goBackToConfirmPhone = function () {
        dispatch(setScreen(SCREEN.CONFIRM_PHONE));
    };
    var handleLanguageDisclaimerCancel = function () {
        dispatch(setScreen(currentScreen));
        dispatch(setShowDisclaimer(false));
    };
    var renderBody = function () {
        switch (currentScreen) {
            case SCREEN.FIND_ACCOUNT:
                return _jsx(FindYourAccountPage, {});
            case SCREEN.FIND_ACCOUNT_ERROR:
                return _jsx(FindAccountErrorPage, {});
            case SCREEN.WELCOME:
                return _jsx(ConvertedAccountFoundPage, {});
            case SCREEN.CONFIRM_PHONE:
                return _jsx(ConfirmPhonePage, {});
            case SCREEN.IDV:
                return _jsx(FindAccountIDVPage, {});
            case SCREEN.IDV_ERROR:
                return _jsx(IDVErrorPage, {});
            case SCREEN.PIN:
                return _jsx(PinEntry, {});
            case SCREEN.PIN_CREATE:
                return _jsx(LoginCreatePin, {});
            case SCREEN.FORGOT_PIN:
                return _jsx(ForgotPinFlowV2, {});
            case SCREEN.SERVICE_OTPSCREEN:
                return _jsx(FindAccountOtpPage, { method: "phone", onBackClick: goBackToConfirmPhone });
            case SCREEN.CONFIRM_EMAIL:
                return _jsx(_Fragment, {});
            default:
                return _jsx(ErrorPage, {});
        }
    };
    return showingLanguageSelectDisclaimer ? (_jsx(LanguageDisclaimer, { page: 'find_account', onCancel: handleLanguageDisclaimerCancel })) : (_jsxs(ImpFlex, __assign({ direction: "column", align: "center" }, { children: [_jsx(HeaderServicing, { type: HEADER_SERVICING_TYPE.LOGIN }), renderBody()] })));
}
