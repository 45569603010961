var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useAppSelector } from '../../app/hooks';
import styles from './Popup.module.css';
import { useDispatch } from 'react-redux';
import { setShowModal, MODAL_ENUM } from '../../app/applicationScreenSlice';
import { useEffect } from 'react';
import { EVENT } from '../../analytics/analyticsConsts';
import { ANALYTICS } from '../../firebase/firebase';
import close from '../../assets/icons/close.svg';
import { CustomBullet } from '../../assets/icons/CustomBullet';
import { defaultBulletColor } from '../../assets/consts/const';
export default function IncomeHousingModal() {
    var dispatch = useDispatch();
    var language = useAppSelector(function (state) { return state.languageSelector.languageLocalization; });
    var merchantData = useAppSelector(function (state) { return state.merchantDataSelector; });
    var appScreenState = useAppSelector(function (state) { return state.applicationScreenSelector; });
    useEffect(function () {
        if (appScreenState.showModal === MODAL_ENUM.showIncomeHousingModal) {
            ANALYTICS.logEvent(EVENT.PERSONAL_INFO_INCOME_HOUSING_MODAL_VIEWED);
        }
    }, [appScreenState.showModal]);
    if (language === null) {
        // eslint-disable-next-line react/jsx-no-useless-fragment
        return _jsx(_Fragment, {});
    }
    return (_jsx("div", __assign({ className: styles.overlay }, { children: _jsxs("div", __assign({ className: styles.modal }, { children: [_jsxs("div", __assign({ className: styles.headerContainer }, { children: [_jsx("p", __assign({ className: styles.header }, { children: _jsx("b", { children: language.personalInfoIncomeHousing }) })), _jsx("img", { src: close, alt: "close", className: styles.close, onClick: function () { return dispatch(setShowModal(MODAL_ENUM.noModal)); } })] })), _jsx("div", { className: styles.paymentHeaderUnderline }), _jsx("p", __assign({ className: styles.paymentHeader }, { children: _jsx("b", { children: language.annualIncome }) })), _jsx("p", __assign({ className: styles.paymentBody }, { children: language.annualIncomeSubheader1 })), _jsxs("div", __assign({ className: styles.bulletGroup }, { children: [_jsx(BulletPointComponent, { text: language.annualIncomeSubheader2, stroke: merchantData.color.light }), _jsx(BulletPointComponent, { text: language.annualIncomeSubheader3, stroke: merchantData.color.light }), _jsx(BulletPointComponent, { text: language.annualIncomeSubheader4, stroke: merchantData.color.light }), _jsx(BulletPointComponent, { text: language.annualIncomeSubheader5, stroke: merchantData.color.light }), _jsx(BulletPointComponent, { text: language.annualIncomeSubheader6, stroke: merchantData.color.light })] })), _jsx("p", __assign({ className: styles.paymentHeader }, { children: _jsx("b", { children: language.monthlyHousingCost }) })), _jsx("p", __assign({ className: styles.paymentBody }, { children: language.monthlyHousingCostSubheader }))] })) })));
}
export function BulletPointComponent(_a) {
    var text = _a.text, stroke = _a.stroke;
    var strokeColor = stroke ? stroke : defaultBulletColor;
    return (_jsxs("div", __assign({ className: styles.divPoint }, { children: [_jsx(CustomBullet, { stroke: strokeColor }), _jsx("p", __assign({ className: "".concat(styles.bulletText, " ").concat(styles.regularSizeText) }, { children: text }))] })));
}
