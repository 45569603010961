import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { ForgotPinScreen, setForgotPinScreen } from '../../app/forgotPinSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { LoginCreatePin } from '../../components/pinEntry/LoginCreatePin';
import { ErrorPage } from './ErrorPage';
import SuccessPage from './SuccessPage';
import { VerifyDobPage } from './VerifyDobPage';
export default function ForgotPinFlow() {
    var forgotPinState = useAppSelector(function (state) { return state.forgotPinSlice; });
    var dispatch = useAppDispatch();
    useEffect(function () {
        switch (forgotPinState.nextStep) {
            case 'verifyDOB':
                dispatch(setForgotPinScreen(ForgotPinScreen.VERIFY_DOB));
                break;
            case 'setPIN':
                dispatch(setForgotPinScreen(ForgotPinScreen.SET_PIN));
                break;
            default:
                dispatch(setForgotPinScreen(ForgotPinScreen.SOMETHING_WENT_WRONG));
        }
    }, [forgotPinState.nextStep]);
    switch (forgotPinState.screen) {
        case ForgotPinScreen.VERIFY_DOB:
            return _jsx(VerifyDobPage, {});
        case ForgotPinScreen.SET_PIN:
            return _jsx(LoginCreatePin, { resetPin: true });
        case ForgotPinScreen.SUCCESS:
            return _jsx(SuccessPage, {});
        default:
            return _jsx(ErrorPage, {});
    }
}
