export var statsigSandboxKey = 'client-ySFqbVNlH2cZg13P87ICu9FzBldwkBAkCAi5nGlOY7x';
export var statsigProductAccountUUIDKey = 'client-UPPFBPsHRXUKhVU4xbR05CD1xB0vdgb3MsHNNx0ILzo';
export var statsigEnvironment = (function () {
    var url = new URL(window.location.href);
    var domain = url.hostname;
    var environment;
    if (domain === 'account.imprint.co') {
        environment = 'production';
    }
    else if (domain === 'account.stg.imprintapi.co') {
        environment = 'staging';
    }
    else {
        environment = 'sandbox';
    }
    return { tier: environment };
})();
