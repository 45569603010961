var _a;
import { createSlice } from '@reduxjs/toolkit';
export var BASE_URL;
(function (BASE_URL) {
    BASE_URL["PROD"] = "https://api.imprint.co";
    BASE_URL["STG"] = "https://int.stg.imprintapi.co";
    BASE_URL["SBX"] = "https://sbx.imprint.co";
    BASE_URL["ASSET"] = "https://app.stg.imprint.co";
    BASE_URL["PREPRD"] = "https://int.preprd.imprintapi.co";
})(BASE_URL || (BASE_URL = {}));
export var ENV;
(function (ENV) {
    ENV["LOCAL"] = "localhost";
    ENV["STG"] = "stg";
    ENV["SBX"] = "sbx";
    ENV["PROD"] = "prod";
    ENV["INTERNAL_PROD"] = "int-imprint";
    ENV["PREPRD"] = "preprd";
})(ENV || (ENV = {}));
var initialState = {
    baseURL: '',
    env: '',
    apiKey: '',
    auth: false,
    deviceID: '',
    sessionID: '',
};
var apiScreenSlice = createSlice({
    name: 'apiState',
    initialState: initialState,
    reducers: {
        setBaseURL: function (state, action) {
            state.baseURL = action.payload;
        },
        setEnv: function (state, action) {
            state.env = action.payload;
        },
        setApiKey: function (state, action) {
            state.apiKey = action.payload;
        },
        setAuthState: function (state, action) {
            state.auth = action.payload;
        },
        setDeviceID: function (state, action) {
            state.deviceID = action.payload;
        },
        resetDeviceID: function (state) {
            state.deviceID = initialState.deviceID;
        },
        setSessionID: function (state, action) {
            state.sessionID = action.payload;
        },
        resetSessionID: function (state) {
            state.sessionID = initialState.sessionID;
        },
    },
});
export var setBaseURL = (_a = apiScreenSlice.actions, _a.setBaseURL), setEnv = _a.setEnv, setApiKey = _a.setApiKey, setAuthState = _a.setAuthState, setDeviceID = _a.setDeviceID, resetDeviceID = _a.resetDeviceID, setSessionID = _a.setSessionID, resetSessionID = _a.resetSessionID;
export default apiScreenSlice.reducer;
