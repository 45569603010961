var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
// Apple Wallet guidelines: https://developer.apple.com/wallet/add-to-apple-wallet-guidelines/
import appleImageEn from '../../assets/images/addToAppleWallet_en.svg';
import appleImageEs from '../../assets/images/addToAppleWallet_es.svg';
import { ImpImage } from '@imprint-payments/imprint-ui';
import { LanguageCode } from '../../utils/languageConstants';
import { useExternalScript } from '../../utils/useExternalScript';
import { provisionAppleWallet } from '../../api/addToWallet/provisionAppleWallet';
import { useEffect } from 'react';
var APPLE_WALLET_WPP_SCRIPT_URL = 'https://smp-device-content.apple.com/navweb/asset/initAddToAppleWallet.js';
var APPLE_WALLET_WPP_PARTNER_ID = 'ORG-43b4ff7d-5052-44c4-af92-7760c28c37c3';
export default function AddToAppleWalletButton(_a) {
    var cardUUID = _a.cardUUID, languageCode = _a.languageCode, onClick = _a.onClick, onSuccess = _a.onSuccess, onError = _a.onError;
    var scriptStatus = useExternalScript(APPLE_WALLET_WPP_SCRIPT_URL);
    var jwtResolver = function () {
        return provisionAppleWallet({
            cardUUID: cardUUID,
        });
    };
    var resultResolver = function (result) {
        //TODO: [WS] add more cases here
        // Mock handlers for test only
        if (result.status === '200') {
            onSuccess();
        }
        else {
            console.error("non 200 from Apple resultResolver: ", result);
            onError();
        }
    };
    useEffect(function () {
        if (scriptStatus === 'ready' && window.initAddToAppleWallet) {
            var config = {
                buttonId: 'apple-wallet-button',
                partnerId: APPLE_WALLET_WPP_PARTNER_ID,
                domain: 'https://apple-pay.apple.com',
                jwtResolver: jwtResolver,
                resultResolver: resultResolver,
            };
            try {
                window.initAddToAppleWallet(config);
            }
            catch (error) {
                console.error('Error initializing Apple Wallet:', error);
            }
        }
    }, [scriptStatus]);
    return scriptStatus === 'ready' ? (_jsx("button", __assign({ id: "apple-wallet-button", onClick: onClick }, { children: _jsx(ImpImage, { src: languageCode === LanguageCode.English ? appleImageEn : appleImageEs, alt: 'Add to Apple Wallet', height: "44px" }) }))) : null;
}
