var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useNavigate } from 'react-router-dom';
import { SCREEN, setScreen } from '../../app/screenStateSlice';
import HeaderServicing from '../../components/HeaderServicing/HeaderServicing';
import { MerchantInfos, PRODUCTUUID_TO_MERCHANT, } from '../../utils/productUUIDConstants';
import { PAGE } from '../../utils/routeConstant';
import { colors, Icons, ImpBreadcrumbs, ImpContainer, ImpFlex, ImpIcon, ImpList, ImpListHeader, ImpPad, ImpSpacer, ImpSurface, ImpText, } from '@imprint-payments/imprint-ui';
import { EVENT } from '../../analytics/analyticsConsts';
import { getStatements } from '../../api/api';
import { ImprintConstants } from '../../api/apiConstants';
import { loadHome } from '../../api/loadHome.api';
import { ANALYTICS } from '../../firebase/firebase';
import { navigateToAccountPage } from '../../utils/functionUtils';
import { LanguageCode } from '../../utils/languageConstants';
import { Statements } from '../CardBalanceDetailsPage/Statements';
import { DOCUMENT_TYPE, setConsumerDocumentType } from '../../app/homeSlice';
import { selectConsumerDocumentsFlag } from '../../app/featureFlagSelectors';
export default function Documents() {
    var dispatch = useAppDispatch();
    var navigate = useNavigate();
    var languageLocalization = useAppSelector(function (state) { return state.languageSelector.languageLocalization; });
    var languageCode = useAppSelector(function (state) { return state.languageSelector.languageCode; });
    var homeState = useAppSelector(function (state) { return state.homeSelector; });
    var consumerDocumentsEnabled = useAppSelector(selectConsumerDocumentsFlag);
    var initialStatementSetting = useAppSelector(function (state) { return state.statementsSelector.statementMedium; });
    var paperlessStatementDisabled = initialStatementSetting === '';
    useEffect(function () {
        var _a;
        // If there is no selected product account, go back to account page.
        if (!((_a = homeState.selectedProductAccount) === null || _a === void 0 ? void 0 : _a.productAccountUUID)) {
            navigateToAccountPage(dispatch, navigate);
        }
        else {
            dispatch(loadHome()).then(function () {
                dispatch(getStatements());
            });
        }
        ANALYTICS.logEvent(EVENT.DOCUMENTS_VIEWED);
    }, []);
    var hideDocs = function () {
        // leave the function if there is future documents to hide with logic
        return false;
    };
    var getLegalDocs = function () {
        var _a;
        var productUUID = ((_a = homeState.selectedProductAccount) === null || _a === void 0 ? void 0 : _a.productUUID) || '';
        var relevantMerchantInfo = MerchantInfos[PRODUCTUUID_TO_MERCHANT[productUUID]];
        if (!relevantMerchantInfo)
            return;
        var legalDocs = [
            {
                title: languageLocalization.privacyNotice,
                onClick: function () {
                    ANALYTICS.logEvent(EVENT.DOCUMENTS_DOCUMENT_CLICKED, {
                        url: ImprintConstants.ImprintPrivacy,
                    });
                    languageCode === LanguageCode.English
                        ? window.open(ImprintConstants.ImprintPrivacy, '_blank')
                        : window.open(ImprintConstants.ImprintPrivacyES, '_blank');
                },
                trailingContent: _jsx(ImpIcon, { iconSrc: Icons.chevronRight }),
            },
            {
                title: languageLocalization.eSignPolicy,
                onClick: function () {
                    ANALYTICS.logEvent(EVENT.DOCUMENTS_DOCUMENT_CLICKED, {
                        url: ImprintConstants.ImprintEsign,
                    });
                    languageCode === LanguageCode.English
                        ? window.open(ImprintConstants.ImprintEsign, '_blank')
                        : window.open(ImprintConstants.ImprintEsignES, '_blank');
                },
                trailingContent: _jsx(ImpIcon, { iconSrc: Icons.chevronRight }),
            },
        ];
        if (!hideDocs()) {
            legalDocs.push({
                title: languageLocalization.creditCardholderAgreement,
                onClick: function () {
                    var _a, _b;
                    ANALYTICS.logEvent(EVENT.DOCUMENTS_DOCUMENT_CLICKED, {
                        url: (_a = homeState.selectedProductAccount) === null || _a === void 0 ? void 0 : _a.cardholderAgreementURL,
                    });
                    window.open((_b = homeState.selectedProductAccount) === null || _b === void 0 ? void 0 : _b.cardholderAgreementURL, '_blank');
                },
                trailingContent: _jsx(ImpIcon, { iconSrc: Icons.chevronRight }),
            });
            legalDocs.push({
                title: languageLocalization.rewardsTermsConditions,
                onClick: function () {
                    ANALYTICS.logEvent(EVENT.DOCUMENTS_DOCUMENT_CLICKED, {
                        url: MerchantInfos[PRODUCTUUID_TO_MERCHANT[productUUID]].rewardTerms[languageCode],
                    });
                    window.open(MerchantInfos[PRODUCTUUID_TO_MERCHANT[productUUID]].rewardTerms[languageCode], '_blank');
                },
                trailingContent: _jsx(ImpIcon, { iconSrc: Icons.chevronRight }),
            });
        }
        if (productUUID) {
            legalDocs.push({
                title: languageLocalization.cardholderInfoSharingNotice,
                onClick: function () {
                    ANALYTICS.logEvent(EVENT.DOCUMENTS_DOCUMENT_CLICKED, {
                        url: MerchantInfos[PRODUCTUUID_TO_MERCHANT[productUUID]].infoSharing[languageCode],
                    });
                    window.open(MerchantInfos[PRODUCTUUID_TO_MERCHANT[productUUID]].infoSharing[languageCode], '_blank');
                },
                trailingContent: _jsx(ImpIcon, { iconSrc: Icons.chevronRight }),
            });
        }
        return legalDocs;
    };
    var getPaperlessSetting = function () {
        var paperlessSetting = [
            {
                title: languageLocalization.managePaperlessSetting,
                onClick: function () {
                    dispatch(setScreen(SCREEN.STATEMENT_SETTINGS));
                    navigate(PAGE.STATEMENT_SETTINGS);
                },
                trailingContent: _jsx(ImpIcon, { iconSrc: Icons.chevronRight }),
            },
        ];
        return paperlessSetting;
    };
    var getOtherDocs = function () {
        if (!consumerDocumentsEnabled)
            return;
        var consumerDocuments = homeState.selectedProductAccount.consumerDocuments;
        var noDoc = (_jsx(ImpContainer, __assign({ width: "100%" }, { children: _jsxs(ImpSurface, __assign({ hasBorderRadius: true }, { children: [_jsx(ImpListHeader, { title: languageLocalization.otherDocuments }), _jsx(ImpPad, __assign({ fluid: true, padding: "32px" }, { children: _jsx(ImpContainer, __assign({ textAlign: "center" }, { children: _jsx(ImpFlex, __assign({ justify: "center", align: "center", direction: "column" }, { children: _jsx(ImpText, __assign({ typography: "body1", color: colors.content.onSurfaceVariant }, { children: languageLocalization.noOtherDocs })) })) })) }))] })) })));
        if (!consumerDocuments || consumerDocuments.length == 0)
            return noDoc;
        var otherDocsExist = consumerDocuments.find(function (doc) {
            return doc.documentType !== DOCUMENT_TYPE.UNSPECIFIED;
        });
        if (!otherDocsExist)
            return noDoc;
        var historicalCitiStatementList = consumerDocuments.find(function (doc) {
            return doc.documentType === DOCUMENT_TYPE.CITI_HISTORY_STATEMENT;
        }) ?
            [{
                    title: languageLocalization.historicalCitiStatements,
                    onClick: function () {
                        dispatch(setConsumerDocumentType(DOCUMENT_TYPE.CITI_HISTORY_STATEMENT));
                        dispatch(setScreen(SCREEN.CONSUMER_DOCUMENTS));
                        navigate(PAGE.CONSUMER_DOCUMENTS);
                    },
                    trailingContent: _jsx(ImpIcon, { iconSrc: Icons.chevronRight }),
                }] : [];
        var previousCHAList = consumerDocuments.find(function (doc) {
            return doc.documentType === DOCUMENT_TYPE.HISTORY_CHA;
        }) ?
            [{
                    title: languageLocalization.previousCardholderAgreements,
                    onClick: function () {
                        dispatch(setConsumerDocumentType(DOCUMENT_TYPE.HISTORY_CHA));
                        dispatch(setScreen(SCREEN.CONSUMER_DOCUMENTS));
                        navigate(PAGE.CONSUMER_DOCUMENTS);
                    },
                    trailingContent: _jsx(ImpIcon, { iconSrc: Icons.chevronRight }),
                }] : [];
        return (_jsx(ImpList, { title: languageLocalization.otherDocuments, useSurface: true, items: __spreadArray(__spreadArray([], historicalCitiStatementList, true), previousCHAList, true), hideSquareLoader: true }));
    };
    return (_jsxs(ImpContainer, { children: [_jsx(HeaderServicing, {}), _jsx(ImpBreadcrumbs, { breadcrumbs: [languageLocalization.documents], onBackClick: function () { return navigateToAccountPage(dispatch, navigate); }, onCancelClick: function () { return navigateToAccountPage(dispatch, navigate); } }), _jsx(ImpFlex, __assign({ direction: "column", align: "center" }, { children: _jsx(ImpContainer, __assign({ width: "var(--max-width-desktop)" }, { children: _jsxs(ImpFlex, __assign({ direction: "column", fluid: true, align: "center" }, { children: [_jsx(ImpSpacer, { height: "2xl", mobileHeight: "xl" }), _jsx(Statements, {}), _jsx(ImpSpacer, { height: "xl", mobileHeight: "xl" }), !paperlessStatementDisabled && (_jsx(ImpContainer, __assign({ width: "100%" }, { children: _jsx(ImpList, { title: languageLocalization.paperless, useSurface: true, items: getPaperlessSetting(), hideSquareLoader: true }) }))), _jsx(ImpSpacer, { height: "xl", mobileHeight: "xl" }), _jsx(ImpContainer, __assign({ width: "100%" }, { children: _jsx(ImpList, { title: languageLocalization.legal, useSurface: true, items: getLegalDocs(), hideSquareLoader: true }) })), _jsx(ImpSpacer, { height: "xl", mobileHeight: "xl" }), _jsx(ImpContainer, __assign({ width: "100%" }, { children: getOtherDocs() }))] })) })) })), _jsx(ImpSpacer, { height: "2xl" })] }));
}
