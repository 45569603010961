var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { borderRadius, colors, ImpButton, ImpCheckbox, ImpContainer, ImpFlex, ImpInput, ImpPad, ImpSpacer, ImpText, useSnackbar, } from '@imprint-payments/imprint-ui';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { EVENT } from '../../analytics/analyticsConsts';
import { getCardFront, getColor, serviceLoginThunk, verifyServiceDob } from '../../api/api';
import { verifyServicingOtp } from '../../api/verifyServicingOtp';
import { setDeviceID } from '../../app/apiScreenSlice';
import { selectFindAccountFlag } from '../../app/featureFlagSelectors';
import { setHomeFlow } from '../../app/homeSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { SCREEN } from '../../app/screenStateSlice';
import { dismissSnackbar } from '../../app/snackbarSlice';
import { FORM_BUTTON_WIDTH } from '../../assets/consts/const';
import downloadAppQRCodeGeneric from '../../assets/images/downloadAppQRCode_generic.svg';
import HeaderServicing, { HEADER_SERVICING_TYPE, } from '../../components/HeaderServicing/HeaderServicing';
import { ANALYTICS } from '../../firebase/firebase';
import useNextPage from '../../hooks/nextPage';
import ForgotPinFlow from '../../pages/ForgotPinFlow/ForgotPinFlow';
import ForgotPinFlowV2 from '../../pages/ForgotPinFlow/ForgotPinFlowV2';
import { formatDob, formatPhoneNumber } from '../../utils/formatUtils';
import { clearRememberMe, clearServiceLoginState, getNewDeviceID, getRememberMe, isDateExpired, saveRememberMe, } from '../../utils/functionUtils';
import { getLoginMerchant } from '../../utils/productUUIDConstants';
import { isGenericLogin, PAGE } from '../../utils/routeConstant';
import { validateBirthDate, validatePhoneNumber, ValidationResponse, } from '../../utils/validateUtils';
import Button, { ButtonColorScheme } from '../button/Button';
import { OtpCard } from '../otp/OtpCard';
import { LoginCreatePin } from '../pinEntry/LoginCreatePin';
import PinEntry from '../pinEntry/PinEntry';
import { LoginFailed } from './LoginFailed';
import styles from './LoginPage.module.css';
import { LoginImage, LoginText, useScreenWidth } from './loginPageUtils';
var otpAnalyticsEvents = {
    viewed: EVENT.LOGIN_OTP_VIEWED,
    submitted: EVENT.LOGIN_OTP_SUBMITTED,
    incorrect: EVENT.LOGIN_OTP_TRY_AGAIN_VIEWED,
    resend: EVENT.LOGIN_OTP_SEND_CODE_AGAIN_CLICKED,
    callInstead: EVENT.LOGIN_OTP_SEND_VOICE_CODE_CLICKED,
    callAgain: EVENT.LOGIN_OTP_SEND_VOICE_CODE_AGAIN_CLICKED,
};
export default function LoginPage() {
    var _this = this;
    var dispatch = useAppDispatch();
    var nextPage = useNextPage();
    var navigate = useNavigate();
    var clearAllSnackbars = useSnackbar().clearAllSnackbars;
    var language = useAppSelector(function (state) { return state.languageSelector.languageLocalization; });
    var appScreenState = useAppSelector(function (state) { return state.applicationScreenSelector; });
    var homeState = useAppSelector(function (state) { return state.homeSelector; });
    var otpScreenState = useAppSelector(function (state) { return state.otpScreenSelector; });
    var screenState = useAppSelector(function (state) { return state.screenStateSelector; });
    var merchantData = useAppSelector(function (state) { return state.merchantDataSelector; });
    var apiScreenState = useAppSelector(function (state) { return state.apiScreenScreenSelector; });
    var isFindAccountEnabled = useAppSelector(selectFindAccountFlag);
    var phoneNotRecognized = homeState.homeFlow.loginError;
    var _a = useState(''), phoneNumber = _a[0], setPhoneNumber = _a[1];
    var _b = useState(''), birthDay = _b[0], setBirthDay = _b[1];
    var _c = useState('disabled'), primaryButtonState = _c[0], setPrimaryButtonState = _c[1];
    var _d = useState(false), failedOtp = _d[0], setFailedOtp = _d[1];
    var _e = useState(false), rememberMe = _e[0], setRememberMe = _e[1];
    var screenSizeStatus = useScreenWidth();
    useEffect(function () {
        var productUUID = getLoginMerchant(window.location.pathname);
        if (productUUID) {
            dispatch(getCardFront(productUUID));
            dispatch(getColor(productUUID));
        }
    }, []);
    useEffect(function () {
        if (screenState.screen === SCREEN.LOGIN) {
            var productUUID = merchantData.productUUID || getLoginMerchant(window.location.pathname);
            ANALYTICS.logEvent(EVENT.ENTER_PHONE_NUMBER_VIEWED, {
                product_uuid: productUUID,
            });
        }
        else if (screenState.screen === SCREEN.DOB) {
            ANALYTICS.logEvent(EVENT.VERIFY_DOB_VIEWED);
        }
        else if (screenState.screen === SCREEN.PIN) {
            if (homeState.userInformation.firstTimeUser) {
                ANALYTICS.logEvent(EVENT.CREATE_PIN_VIEWED);
            }
            else {
                ANALYTICS.logEvent(EVENT.ENTER_PIN_VIEWED);
            }
        }
        else if (screenState.screen === SCREEN.SERVICE_OTPSCREEN) {
            // handled in the screen itself
        }
        else if (screenState.screen === SCREEN.VERIFICATION_FAILED) {
            ANALYTICS.logEvent(EVENT.LOGIN_FAILED_VIEWED);
        }
        else if (screenState.screen === SCREEN.ACCOUNT_PENDING) {
            ANALYTICS.logEvent(EVENT.ISSUE_WITH_APPLICATION_VIEWED);
        }
        else if (screenState.screen === SCREEN.FIND_ACCOUNT) {
            navigate(PAGE.FIND_USER_ACCOUNT);
            return;
        }
        nextPage();
    }, [screenState.screen]);
    useEffect(function () {
        if (homeState.homeFlow.dobError) {
            ANALYTICS.logEvent(EVENT.VERIFY_DOB_TRY_AGAIN_VIEWED);
        }
    }, [homeState.homeFlow.dobError]);
    useEffect(function () {
        if (screenState.screen !== SCREEN.LOGIN)
            return;
        if (appScreenState.loading) {
            setPrimaryButtonState('submitting');
            return;
        }
        if (phoneNumber !== '') {
            dispatch(dismissSnackbar());
        }
        setPrimaryButtonState('disabled');
        if (failedOtp ||
            (validatePhoneNumber(phoneNumber) === ValidationResponse.valid && phoneNumber !== '')) {
            setPrimaryButtonState('enabled');
        }
    }, [phoneNumber, failedOtp, screenState.screen, appScreenState.loading]);
    useEffect(function () {
        if (screenState.screen !== SCREEN.DOB)
            return;
        if (appScreenState.loading) {
            setPrimaryButtonState('submitting');
            return;
        }
        if (validateBirthDate(birthDay) === ValidationResponse.valid && birthDay !== '') {
            setPrimaryButtonState('enabled');
        }
        else {
            setPrimaryButtonState('disabled');
        }
    }, [birthDay, screenState.screen]);
    useEffect(function () {
        if (otpScreenState.reachedMaxRetries) {
            setFailedOtp(true);
        }
        else {
            setFailedOtp(false);
        }
    }, [otpScreenState.reachedMaxRetries]);
    useEffect(function () {
        if (phoneNotRecognized) {
            ANALYTICS.logEvent(EVENT.ENTER_PHONE_NUMBER_NOT_RECOGNIZED_VIEWED, {
                product_uuid: merchantData.productUUID,
            });
            dispatch(setHomeFlow(__assign(__assign({}, homeState.homeFlow), { loginError: false })));
        }
    }, [phoneNumber]);
    useEffect(function () {
        if (homeState.homeFlow.dobError) {
            ANALYTICS.logEvent(EVENT.VERIFY_DOB_TRY_AGAIN_CONTINUE_CLICKED);
            dispatch(setHomeFlow(__assign(__assign({}, homeState.homeFlow), { dobError: false })));
        }
    }, [birthDay]);
    useEffect(function () {
        // [APPROV-311] A work around to avoid setting a new device id when DOM tree changes.
        // Need to refactor the entire LoginPage and decouple the non-login logics in future.
        if (screenState !== SCREEN.LOGIN) {
            return;
        }
        var _a = getRememberMe(), deviceID = _a.deviceID, phoneNumber = _a.phoneNumber, deviceIDExpiration = _a.deviceIDExpiration;
        if (deviceID !== undefined && phoneNumber !== undefined && !isDateExpired(deviceIDExpiration)) {
            dispatch(setDeviceID(deviceID));
            setPhoneNumber(phoneNumber);
            setRememberMe(true);
        }
        else {
            dispatch(setDeviceID(getNewDeviceID()));
            clearRememberMe();
        }
    }, []);
    var loginHandler = function (isResend, sendVoice) { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            if (!isResend) {
                ANALYTICS.logEvent(EVENT.ENTER_PHONE_NUMBER_LOG_IN_CLICKED, {
                    product_uuid: merchantData.productUUID,
                });
            }
            if (rememberMe) {
                saveRememberMe({ phoneNumber: phoneNumber, deviceID: apiScreenState === null || apiScreenState === void 0 ? void 0 : apiScreenState.deviceID });
            }
            else {
                clearRememberMe();
            }
            dispatch(serviceLoginThunk({
                phone: phoneNumber
                    .split('')
                    .filter(function (val) { return !isNaN(+val) && val != ' '; })
                    .join(''),
                resend: isResend,
                sendVoice: sendVoice,
            }));
            return [2 /*return*/];
        });
    }); };
    var submitOtpHandler = function (code) {
        dispatch(verifyServicingOtp({
            code: code,
        }));
        return;
    };
    var verifyDobHandler = function () {
        ANALYTICS.logEvent(EVENT.VERIFY_DOB_CONTINUE_CLICKED);
        var date = new Date(birthDay);
        var dob = {
            year: date.getFullYear(),
            month: date.getMonth() + 1,
            day: date.getDate(),
        };
        dispatch(verifyServiceDob(dob));
    };
    var isLoginPageBranded = function () {
        return !isGenericLogin() && merchantData.productUUID;
    };
    var renderLogin = function () {
        var renderFormControls = function () {
            return (_jsxs(ImpFlex, __assign({ direction: "column", align: "left" }, { children: [_jsx(ImpInput, { id: "ServiceLogin", label: language.loginPhoneField, value: phoneNumber, maxLength: 16, errorMessage: isFindAccountEnabled ? language.invalidServiceLoginV2 : language.invalidServiceLogin, onChange: function (e) {
                            setPhoneNumber(formatPhoneNumber(e.target.value));
                        }, instrumentation: function () {
                            return ANALYTICS.logEvent(EVENT.ENTER_PHONE_NUMBER_FILLED_OUT, {
                                product_uuid: merchantData.productUUID,
                            });
                        }, onEnter: function () {
                            handleLoginButtonClick(false);
                        }, isDisabled: primaryButtonState === 'submitting', showError: phoneNotRecognized, numeric: true, type: "tel", onFocus: function () { return clearAllSnackbars(); } }), _jsx(ImpSpacer, { height: "s" }), _jsx(ImpPad, __assign({ paddingLeft: "s", paddingTop: "s", paddingBottom: "s" }, { children: _jsx(ImpCheckbox, { label: language.rememberMeLabel, checked: rememberMe, isDisabled: primaryButtonState !== 'enabled', handleClick: function (checked) {
                                ANALYTICS.logEvent(EVENT.REMEMBER_ME_CHECKED);
                                if (!checked) {
                                    ANALYTICS.logEvent(EVENT.REMEMBER_ME_UNCHECKED);
                                    clearRememberMe();
                                    dispatch(setDeviceID(getNewDeviceID()));
                                }
                                setRememberMe(checked);
                            } }) })), _jsx(ImpSpacer, { height: "s" }), renderLoginButtons()] })));
        };
        var renderHorizontalLayout = function () {
            return (_jsx(ImpContainer, __assign({ width: "100%", borderRadius: borderRadius.l, textAlign: "left", backgroundColor: colors.surface.surfaceHigh }, { children: _jsx(ImpPad, __assign({ paddingLeft: "2xl", paddingRight: "2xl", paddingTop: "2xl", paddingBottom: "2xl" }, { children: _jsxs(ImpFlex, __assign({ direction: "row", align: "center", justify: "center", gap: "2xl", fluid: true }, { children: [_jsx(LoginImage, { isBranded: isLoginPageBranded() }), _jsx(ImpContainer, __assign({ maxWidth: "416px" }, { children: _jsxs(ImpFlex, __assign({ direction: "column", align: "left", justify: "center", fluid: true }, { children: [_jsx(LoginText, {}), _jsx(ImpSpacer, { height: "xl" }), renderFormControls()] })) }))] })) })) })));
        };
        var renderVerticalLayout = function () {
            return (_jsxs(ImpFlex, __assign({ direction: "column", align: "center", fluid: true }, { children: [_jsx(ImpContainer, __assign({ width: "100%", borderRadius: borderRadius.l, textAlign: "center", backgroundColor: colors.surface.surfaceHigh, maxWidth: FORM_BUTTON_WIDTH }, { children: _jsxs(ImpPad, __assign({ paddingLeft: "xl", paddingRight: "xl", paddingTop: "xl", paddingBottom: "xl" }, { children: [screenSizeStatus === 'mobile' && (_jsxs(_Fragment, { children: [_jsx(HeaderServicing, { type: HEADER_SERVICING_TYPE.LOGIN, isScreenHeader: false }), _jsx(ImpSpacer, { height: "xl" })] })), _jsxs(ImpFlex, __assign({ direction: "column", align: "center" }, { children: [_jsx(LoginImage, { isBranded: isLoginPageBranded() }), _jsx(ImpSpacer, { height: "l" }), _jsx(LoginText, {})] }))] })) })), _jsx(ImpSpacer, { height: "l" }), renderFormControls()] })));
        };
        return screenSizeStatus === 'desktop' ? renderHorizontalLayout() : renderVerticalLayout();
    };
    var renderDob = function () {
        var body = homeState.homeFlow.dobError ? language.invalidServiceDob : '';
        return (_jsx(ImpContainer, __assign({ textAlign: "center" }, { children: _jsxs(ImpFlex, __assign({ direction: "column", align: "center" }, { children: [_jsx(ImpText, __assign({ typography: "headline2" }, { children: language.loginDobHeader })), _jsx(ImpPad, __assign({ padding: "8px 0px" }, { children: _jsx(ImpText, __assign({ typography: "body2", color: colors.content.onBackgroundVariant }, { children: body })) })), _jsx(ImpContainer, __assign({ width: "100%" }, { children: _jsx(ImpPad, __assign({ padding: "12px 0 0" }, { children: _jsx(ImpInput, { id: "ServiceLogin", label: language.loginDobField, value: birthDay, maxLength: 10, errorMessage: language.invalidServiceDob, inputStyle: 'centered', onChange: function (e) {
                                    setBirthDay(formatDob(e.target.value));
                                }, instrumentation: function () { return ANALYTICS.logEvent(EVENT.VERIFY_DOB_FILLED_OUT); }, onEnter: verifyDobHandler, isDisabled: primaryButtonState === 'submitting', showError: homeState.homeFlow.dobError, numeric: true, type: "text" }) })) })), _jsx(ImpSpacer, { height: "l" }), _jsx(ImpContainer, __assign({ width: FORM_BUTTON_WIDTH }, { children: _jsx(ImpButton, __assign({ variant: "primary", buttonStyle: merchantData.productUUID ? 'branded' : 'imprint', onClick: verifyDobHandler, state: primaryButtonState }, { children: language.continueBtn })) }))] })) })));
    };
    function handleLoginButtonClick(sendVoice) {
        if (primaryButtonState === 'disabled') {
            return;
        }
        loginHandler(false, sendVoice);
    }
    var renderLoginButtons = function () {
        return (_jsxs(_Fragment, { children: [_jsx(ImpButton, __assign({ variant: "primary", buttonStyle: isLoginPageBranded() ? 'branded' : 'imprint', onClick: function () {
                        handleLoginButtonClick(false);
                    }, state: primaryButtonState }, { children: language.LogIn })), isFindAccountEnabled && phoneNotRecognized && (_jsxs(_Fragment, { children: [_jsx(ImpSpacer, { height: "s" }), _jsx(ImpButton, __assign({ variant: "secondary", buttonStyle: isLoginPageBranded() ? 'branded' : 'imprint', onClick: function () {
                                ANALYTICS.logEvent(EVENT.ENTER_PHONE_FIND_ACCOUNT_CLICKED, {
                                    product_uuid: merchantData.productUUID,
                                });
                                navigate(PAGE.FIND_USER_ACCOUNT);
                            } }, { children: language.findAccountButton }))] }))] }));
    };
    return (_jsx(ImpFlex, __assign({ fluid: true, justify: "center" }, { children: _jsx(LoginScreen, { setPhoneNumber: setPhoneNumber, submitOtpHandler: submitOtpHandler, loginHandler: loginHandler, failedOtp: failedOtp, renderLogin: renderLogin, renderDob: renderDob }) })));
}
function LoginScreen(_a) {
    var setPhoneNumber = _a.setPhoneNumber, submitOtpHandler = _a.submitOtpHandler, loginHandler = _a.loginHandler, renderLogin = _a.renderLogin, renderDob = _a.renderDob, failedOtp = _a.failedOtp;
    var screenState = useAppSelector(function (state) { return state.screenStateSelector; });
    var homeState = useAppSelector(function (state) { return state.homeSelector; });
    var language = useAppSelector(function (state) { return state.languageSelector.languageLocalization; });
    var dispatch = useAppDispatch();
    var isFindAccountEnabled = useAppSelector(selectFindAccountFlag);
    useEffect(function () {
        if (screenState.screen === SCREEN.VERIFICATION_FAILED) {
            setPhoneNumber('');
        }
    }, [screenState.screen]);
    switch (screenState.screen) {
        case SCREEN.PIN:
            return homeState.userInformation.firstTimeUser ? _jsx(LoginCreatePin, {}) : _jsx(PinEntry, {});
        case SCREEN.FORGOT_PIN:
            return isFindAccountEnabled ? _jsx(ForgotPinFlowV2, {}) : _jsx(ForgotPinFlow, {});
        case SCREEN.SERVICE_OTPSCREEN:
            return (_jsx(OtpCard, { handleGoBack: function () {
                    ANALYTICS.logEvent(EVENT.LOGIN_OTP_GO_BACK_CLICKED);
                    dispatch(clearServiceLoginState);
                    setPhoneNumber('');
                }, onFull: submitOtpHandler, handleResend: function (sendVoice) { return loginHandler(true, sendVoice); }, events: otpAnalyticsEvents }));
        case SCREEN.ACCOUNT_PENDING:
            return (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: styles.qrContainer }, { children: _jsx("img", { className: styles.qr, src: downloadAppQRCodeGeneric }) })), _jsxs("h1", __assign({ className: "".concat(styles.continueApplicationHeader, " ").concat(styles.failedHeader) }, { children: [language.accountPendingHeader, _jsx("br", {}), language.accountPendingHeader2] })), _jsx("p", __assign({ className: styles.continueApplicationBody }, { children: language.accountPendingBody })), _jsx(Button, { value: language.goBackBtn, handleClick: function () {
                            ANALYTICS.logEvent(EVENT.ISSUE_WITH_APPLICATION_GO_BACK_CLICKED);
                            dispatch(clearServiceLoginState);
                            setPhoneNumber('');
                        }, colorScheme: ButtonColorScheme.fillImprint })] }));
        case SCREEN.VERIFICATION_FAILED:
            return _jsx(LoginFailed, { failedOtp: failedOtp });
        case SCREEN.DOB:
            return _jsx("div", __assign({ className: styles.headerContainer }, { children: renderDob() }));
        default:
            return renderLogin();
    }
}
