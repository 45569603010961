// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EX02FP2HfudRYUD_fRYR {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  text-align: center;\n  margin-left: auto;\n  margin-right: auto;\n  max-width: min(552px, 100vw - 32px);\n\n  margin-top: 36px;\n}\n\n\n@media (max-width: 600px) {\n  .EX02FP2HfudRYUD_fRYR {\n    max-width: calc(100vw - 32px);\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/pages/cardMenu/GetNewCardNumber.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,kBAAkB;EAClB,iBAAiB;EACjB,kBAAkB;EAClB,mCAAmC;;EAEnC,gBAAgB;AAClB;;;AAGA;EACE;IACE,6BAA6B;EAC/B;AACF","sourcesContent":[".body {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  text-align: center;\n  margin-left: auto;\n  margin-right: auto;\n  max-width: min(552px, 100vw - 32px);\n\n  margin-top: 36px;\n}\n\n\n@media (max-width: 600px) {\n  .body {\n    max-width: calc(100vw - 32px);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": "EX02FP2HfudRYUD_fRYR"
};
module.exports = ___CSS_LOADER_EXPORT___;
