import { colors } from '@imprint-payments/imprint-ui';
import { useMemo } from 'react';
import { selectPaymentDueWidget, selectPaymentOverdueWidget, selectPaymentDetails, selectUpcomingPaymentNoAutopayWidget, selectAutopayAmount, } from '../../app/productAccountSelectors';
import { selectLanguage } from '../../app/languageSelectors';
import { useAppSelector } from '../../app/hooks';
import { formatDisplayAmount } from '@imprint-payments/imprint-lib';
import { ANALYTICS } from '../../firebase/firebase';
import { PKG } from '../../analytics/analyticsConsts';
export var getUpcomingPaymentsText = function (language, numPaymentsScheduled) {
    if (numPaymentsScheduled === 1) {
        return language.oneUpcomingPayment;
    }
    else if (numPaymentsScheduled === 0) {
        return language.noUpcomingPayments;
    }
    return language.xUpcomingPayments.replace('{0}', numPaymentsScheduled);
};
export var usePaymentWidgetConfig = function () {
    var language = useAppSelector(selectLanguage);
    var paymentDetails = useAppSelector(selectPaymentDetails);
    var paymentOverdueWidget = useAppSelector(selectPaymentOverdueWidget);
    var paymentDueWidget = useAppSelector(selectPaymentDueWidget);
    var upcomingPaymentsNoAutopay = useAppSelector(selectUpcomingPaymentNoAutopayWidget);
    var autopayAmount = useAppSelector(selectAutopayAmount);
    var result = useMemo(function () {
        var _a, _b, _c, _d;
        var autopayEnabled = autopayAmount !== undefined;
        var OVERDUE_LABEL = 'OverDue';
        var upcomingPayments = ((_a = upcomingPaymentsNoAutopay === null || upcomingPaymentsNoAutopay === void 0 ? void 0 : upcomingPaymentsNoAutopay.length) !== null && _a !== void 0 ? _a : 0) + (autopayEnabled ? 1 : 0);
        var minDue = (_b = paymentDetails === null || paymentDetails === void 0 ? void 0 : paymentDetails.minDue) === null || _b === void 0 ? void 0 : _b.valueInMinUnit;
        var isOverdue = (paymentDetails === null || paymentDetails === void 0 ? void 0 : paymentDetails.dueDayLabel) === OVERDUE_LABEL;
        var numberOfDaysUntilDue = (_c = paymentDueWidget === null || paymentDueWidget === void 0 ? void 0 : paymentDueWidget.params) === null || _c === void 0 ? void 0 : _c.numberOfDaysUntilDue;
        var daysPastDue = (_d = paymentOverdueWidget === null || paymentOverdueWidget === void 0 ? void 0 : paymentOverdueWidget.params) === null || _d === void 0 ? void 0 : _d.daysPastDue;
        var getTitle = function () {
            if (isOverdue) {
                if (daysPastDue === undefined) {
                    return language.paymentOverdue;
                }
                var parsedDays_1 = parseInt(daysPastDue);
                if (parsedDays_1 === 1) {
                    return language.oneDayOverdue;
                }
                else if (parsedDays_1 > 1) {
                    return language.xDaysOverdue.replace('{0}', parsedDays_1);
                }
                return language.paymentOverdue;
            }
            if (numberOfDaysUntilDue === undefined || minDue === 0) {
                return getUpcomingPaymentsText(language, upcomingPayments);
            }
            var parsedDays = parseInt(numberOfDaysUntilDue);
            if (parsedDays === 1) {
                return language.paymentDueIn1Day;
            }
            else if (parsedDays > 1) {
                return language.paymentDueInX.replace('{0}', parsedDays);
            }
            return language.paymentDue;
        };
        var getSubtitle = function () {
            if (minDue === 0) {
                return language.noActionRequired;
            }
            if (!isOverdue && upcomingPayments > 0 && paymentDueWidget) {
                return language.makeSureYouPayX.replace('{0}', formatDisplayAmount(paymentDetails.minDue));
            }
            if (minDue > 0) {
                return language.payAtLeastX.replace('{0}', formatDisplayAmount(paymentDetails.minDue));
            }
            ANALYTICS.funcError(PKG, 'paymentWidget > getSubtitle', 'unexpected value for minDue');
            return language.checkBackLater;
        };
        var getIndicatorColor = function () {
            if (isOverdue) {
                return colors.content.onSurfaceError;
            }
            else if (minDue > 0) {
                return colors.content.onSurfaceWarning;
            }
            return colors.content.onSurfaceSuccess;
        };
        var getPayNowVariant = function () {
            if (isOverdue ||
                (numberOfDaysUntilDue && parseInt(numberOfDaysUntilDue) === 0)) {
                return 'primary';
            }
            else if (minDue > 0 && !upcomingPayments) {
                return 'secondary';
            }
            return undefined;
        };
        var title = getTitle();
        var subtitle = getSubtitle();
        var indicatorColor = getIndicatorColor();
        var payNowVariant = getPayNowVariant();
        if (!title || !subtitle || !indicatorColor) {
            return {
                title: language.requestCardErrorTitle,
                subtitle: language.appStatusFailedBody,
                indicatorColor: colors.content.onSurfaceError,
                payNowVariant: undefined,
            };
        }
        return {
            title: title,
            subtitle: subtitle,
            indicatorColor: indicatorColor,
            payNowVariant: payNowVariant,
        };
    }, [
        language,
        paymentDetails,
        paymentOverdueWidget,
        paymentDueWidget,
        autopayAmount,
    ]);
    return result;
};
