var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useNavigate } from 'react-router-dom';
import { EVENT } from '../../analytics/analyticsConsts';
import { ActivityType } from '../../api/apiConstants';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { SCREEN, setScreen } from '../../app/screenStateSlice';
import { store } from '../../app/store';
import { ANALYTICS } from '../../firebase/firebase';
import { formatToSnakeCase } from '../../utils/formatUtils';
import { checkBankAccountBroken } from '../../utils/functionUtils';
import { PAGE } from '../../utils/routeConstant';
import { getActionVisibility } from './activityDetailsHelpers';
import { ImpButton, ImpPad, ImpSpacer, ImpSurface, colors, spacing, } from '@imprint-payments/imprint-ui';
import { setIsFlyoutVisible } from '../../app/flyoutSlice';
import { ActivityStatus } from './activityDetailsConsts';
import React from 'react';
export function ActivityDetailsActions(_a) {
    var activity = _a.activity;
    var language = useAppSelector(function (state) { return state.languageSelector.languageLocalization; });
    var primaryPaymentAccount = useAppSelector(function (state) { return state.homeSelector.primaryPaymentAccount; });
    var productAccount = useAppSelector(function (state) { return state.productAccountSelector; });
    var dispatch = useAppDispatch();
    var navigate = useNavigate();
    var hasFooterButtons = activity.type === ActivityType.payment &&
        [
            ActivityStatus.Scheduled,
            ActivityStatus.Canceled,
            ActivityStatus.Rejected,
        ].includes(activity.status);
    if (!hasFooterButtons) {
        return _jsx(_Fragment, {});
    }
    var _b = getActionVisibility(activity, primaryPaymentAccount, productAccount), showEdit = _b.showEdit, showCancel = _b.showCancel, showNewPayment = _b.showNewPayment, showTryAgain = _b.showTryAgain;
    var handleFooterButtonClick = function (buttonName) {
        var buttonNameFormatted = formatToSnakeCase(buttonName);
        ANALYTICS.logEvent("".concat(EVENT.ACTIVITY_DETAILS, "_").concat(buttonNameFormatted, "_").concat(EVENT.CLICKED), {
            type: activity.type,
            status: activity.status,
            transactionID: activity.transactionUUID,
            productUUID: store.getState().productAccountSelector.productDetails.productUUID,
        });
        if (checkBankAccountBroken(primaryPaymentAccount)) {
            dispatch(setScreen(SCREEN.BANKING));
            navigate(PAGE.BANKING);
        }
        else {
            dispatch(setScreen(SCREEN.PAYMENTS));
            navigate(PAGE.PAYMENTS);
        }
        dispatch(setIsFlyoutVisible(false));
    };
    var renderButton = function (label, action, variant) {
        if (variant === void 0) { variant = 'secondary'; }
        return (_jsx(ImpButton, __assign({ size: "fill", buttonStyle: 'branded', variant: variant, onClick: action }, { children: label })));
    };
    var buttonConfigs = [
        { show: showEdit, label: language.editPayment, variant: 'primary' },
        { show: showCancel, label: language.cancelPayment },
        { show: showNewPayment, label: language.makeNewPayment },
        { show: showTryAgain, label: language.tryAgain },
    ];
    return (_jsx(ImpSurface, __assign({ elevation: 1, surfaceColor: colors.surface.surfaceLow }, { children: _jsxs(ImpPad, __assign({ padding: "0px ".concat(spacing.l) }, { children: [_jsx(ImpSpacer, { height: spacing.l }), buttonConfigs.map(function (config, index) {
                    return config.show && (_jsxs(React.Fragment, { children: [renderButton(config.label, function () { return handleFooterButtonClick(config.label); }, config.variant), _jsx(ImpSpacer, { height: spacing.m })] }, index));
                }), _jsx(ImpSpacer, { height: spacing.xl })] })) })));
}
