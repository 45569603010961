var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { CardActionReason, replaceCard, } from '../../api/CardMenu/cardRequestHelper';
import { ConfirmationScreen } from './ConfirmationScreen';
import { ImpButton, ImpContainer, ImpFlex, ImpFlexItem, ImpPad, ImpSpacer, ImpText, } from '@imprint-payments/imprint-ui';
import { AuthUsersPage, setAuthUsersPage } from '../../app/authUsersSlice';
import { displayTemporarySnackbar } from '../../utils/functionUtils';
import { FORM_BUTTON_WIDTH } from '../../assets/consts/const';
import { LIST_STYLE, ListSection } from '../../components/list/List';
import { ANALYTICS } from '../../firebase/firebase';
import { EVENT } from '../../analytics/analyticsConsts';
export function AuthReplaceCard() {
    var dispatch = useAppDispatch();
    var _a = useState(false), isConfirmingAddress = _a[0], setIsConfirmingAddress = _a[1];
    var _b = useState(false), isAddressConfirmed = _b[0], setIsAddressConfirmed = _b[1];
    var _c = useState(false), isSubmitting = _c[0], setIsSubmitting = _c[1];
    var _d = useState(CardActionReason.NONE), replacementReason = _d[0], setReplacementReason = _d[1];
    var language = useAppSelector(function (state) { return state.languageSelector.languageLocalization; });
    var authUsersState = useAppSelector(function (state) { return state.authUsersSelector; });
    if (isAddressConfirmed) {
        return (_jsx(ConfirmationScreen, { title: language.authNewCardOnWay, subtitle: language.authAllowAFewDays, actionButton: _jsx(ImpButton, __assign({ size: "fill", buttonStyle: "branded", variant: "primary", onClick: function () {
                    dispatch(setAuthUsersPage(AuthUsersPage.DEFAULT));
                } }, { children: language.done })) }));
    }
    // TODO: handle something went wrong
    var handleConfirmAddressClick = function () {
        setIsSubmitting(true);
        var cardUUID = authUsersState.selectedAuthorizedUser.cardUUID;
        var reason = replacementReason;
        var onSuccess = function () {
            ANALYTICS.logEvent(EVENT.AUTH_USER_CARD_REPLACEMENT_REQUESTED);
            setIsAddressConfirmed(true);
            setIsSubmitting(false);
        };
        var onError = function () {
            setIsSubmitting(false);
            displayTemporarySnackbar(language.somethingWentWrong, dispatch);
        };
        dispatch(replaceCard({ cardUUID: cardUUID, reason: reason, onSuccess: onSuccess, onError: onError }));
    };
    if (isConfirmingAddress) {
        return (_jsx(ImpContainer, __assign({ textAlign: "center" }, { children: _jsxs(ImpFlex, __assign({ direction: "column", align: "center" }, { children: [_jsx(ImpText, __assign({ typography: "headline2", mobileTypography: "headline3" }, { children: language.authConfirmAddressTitle })), _jsx(ImpSpacer, { height: "xl" }), _jsx(ImpText, __assign({ typography: "body2", color: "#5E5D5C" }, { children: language.authConfirmAddressSubtitle })), _jsx(ImpSpacer, { height: "xl" }), _jsx(ConfirmAddressDisplay, { address: authUsersState.selectedAuthorizedUser.address }), _jsx(ImpSpacer, { height: "xl" }), _jsx(ImpContainer, __assign({ width: "min(100%, 700px)" }, { children: _jsxs(ImpFlex, __assign({ direction: "row", align: "center", justify: "center", gap: "l", fluid: true }, { children: [_jsx(ImpFlexItem, __assign({ flex: "1 1 0" }, { children: _jsx(ImpContainer, __assign({ width: "min(100%, 356px)" }, { children: _jsx(ImpButton, __assign({ variant: "text", onClick: function () {
                                                setIsConfirmingAddress(false);
                                                setReplacementReason(CardActionReason.NONE);
                                            }, size: "fill", state: isSubmitting ? 'disabled' : 'enabled' }, { children: language.goBackBtn })) })) })), _jsx(ImpFlexItem, __assign({ flex: "1 1 0" }, { children: _jsx(ImpContainer, __assign({ width: "min(100%, 356px)" }, { children: _jsx(ImpButton, __assign({ onClick: handleConfirmAddressClick, buttonStyle: "branded", size: "fill", state: isSubmitting ? 'submitting' : 'enabled' }, { children: language.confirmBtn })) })) }))] })) }))] })) })));
    }
    var getReplaceCardActionButtonState = function () {
        if (replacementReason === CardActionReason.NONE) {
            return 'disabled';
        }
        return 'enabled';
    };
    return (_jsx(ImpContainer, __assign({ textAlign: "center" }, { children: _jsxs(ImpFlex, __assign({ direction: "column", align: "center", fluid: true }, { children: [_jsx(ImpText, __assign({ typography: "headline2", mobileTypography: "headline3" }, { children: language.authReplaceCardTitle })), _jsx(ImpSpacer, { height: "2xl" }), _jsx(ListSection, { style: LIST_STYLE.SELECT, defaultSelection: -1, items: [
                        {
                            title: language.authReplaceReason1,
                            handleClick: function () { return setReplacementReason(CardActionReason.LOST); },
                        },
                        {
                            title: language.authReplaceReason2,
                            handleClick: function () { return setReplacementReason(CardActionReason.STOLEN); },
                        },
                        {
                            title: language.authReplaceReason3,
                            handleClick: function () { return setReplacementReason(CardActionReason.DAMAGED); },
                        },
                    ] }), _jsx(ImpSpacer, { height: "l" }), _jsx(ImpText, __assign({ typography: "body3" }, { children: language.authReplaceCardNote })), _jsx(ImpSpacer, { height: "2xl" }), _jsx(ImpContainer, __assign({ width: FORM_BUTTON_WIDTH }, { children: _jsx(ImpButton, __assign({ size: "fill", variant: "primary", buttonStyle: "branded", onClick: function () {
                            setIsConfirmingAddress(true);
                        }, state: getReplaceCardActionButtonState() }, { children: language.continueBtn })) }))] })) })));
}
function ConfirmAddressDisplay(_a) {
    var address = _a.address;
    return (_jsx(ImpContainer, __assign({ width: '100%', borderRadius: "12px", backgroundColor: "white" }, { children: _jsx(ImpPad, __assign({ padding: "0px 24px" }, { children: _jsxs(ImpText, __assign({ typography: "body2" }, { children: [address.streetLine1, address.streetLine2 && (_jsxs(_Fragment, { children: [_jsx("br", {}), address.streetLine2] })), _jsx("br", {}), address.city, ", ", address.state, " ", address.zipCode] })) })) })));
}
