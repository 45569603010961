export var analyticsMock = {
    logEvent: function () { },
    setCurrentScreen: function () { },
    setuserId: function () { },
};
export var mockFirebase = function () {
    jest.mock('@firebase/remote-config', function () { return ({
        getRemoteConfig: jest.fn().mockImplementation(function () { return ({
            settings: { minimumFetchIntervalMillis: 3600000 },
        }); }),
        fetchAndActivate: jest.fn(),
    }); });
    jest.mock('firebase/analytics', function () {
        return { logEvent: jest.fn(), isSupported: jest.fn().mockResolvedValue(false) };
    });
};
