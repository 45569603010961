var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ImpContainer, ImpSurface, ImpFlex, ImpSpacer, ImpButton, } from '@imprint-payments/imprint-ui';
import { useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectPrimaryPaymentAccount } from '../../app/homeSelectors';
import { selectLanguage } from '../../app/languageSelectors';
import { PAYMENT_ACCOUNT_STATUS } from '../banking/Banking';
import { SCREEN, setScreen } from '../../app/screenStateSlice';
import { useNavigate } from 'react-router-dom';
import { PAGE } from '../../utils/routeConstant';
import { ANALYTICS } from '../../firebase/firebase';
import { PKG } from '../../analytics/analyticsConsts';
import { selectTotalSpend, } from '../../app/productAccountSelectors';
import { useScheduledPayments } from '../payments/UpcomingPayments';
var useCardBalanceActionState = function () {
    var primaryPaymentAccount = useAppSelector(selectPrimaryPaymentAccount);
    var totalSpend = useAppSelector(selectTotalSpend);
    var language = useAppSelector(selectLanguage);
    var dispatch = useAppDispatch();
    var navigate = useNavigate();
    var upcomingPaymentsList = useScheduledPayments();
    var toReturn = useMemo(function () {
        switch (primaryPaymentAccount === null || primaryPaymentAccount === void 0 ? void 0 : primaryPaymentAccount.status) {
            case PAYMENT_ACCOUNT_STATUS.APPROVED:
                return {
                    state: (totalSpend === null || totalSpend === void 0 ? void 0 : totalSpend.valueInMinUnit) > 0 ? 'enabled' : 'disabled',
                    onClick: function () {
                        ANALYTICS.logEvent('card_balance_make_payment_clicked');
                        dispatch(setScreen(SCREEN.ONE_TIME_PAYMENT));
                        navigate(PAGE.ONE_TIME_PAYMENT);
                    },
                    children: language.makeAPayment,
                };
            case PAYMENT_ACCOUNT_STATUS.APPLY: {
                return {
                    state: 'enabled',
                    onClick: function () {
                        ANALYTICS.logEvent('card_balance_connect_a_bank_clicked');
                        dispatch(setScreen(SCREEN.BANKING));
                        navigate(PAGE.BANKING);
                    },
                    children: language.connectABank,
                };
            }
            case PAYMENT_ACCOUNT_STATUS.REPAIR: {
                return {
                    state: 'enabled',
                    onClick: function () {
                        ANALYTICS.logEvent('card_balance_fix_account_clicked');
                        dispatch(setScreen(SCREEN.BANKING));
                        navigate(PAGE.BANKING);
                    },
                    children: language.fixAccount,
                };
            }
            case PAYMENT_ACCOUNT_STATUS.REVIEWING_AUTO:
            case PAYMENT_ACCOUNT_STATUS.REVIEWING_MANUAL:
                return {
                    state: 'disabled',
                    onClick: function () { },
                    children: language.bankLinkingInReview,
                };
            default:
                ANALYTICS.funcError(PKG, 'useCardBalanceActionState', "unexpected primary payment acct status ".concat(primaryPaymentAccount === null || primaryPaymentAccount === void 0 ? void 0 : primaryPaymentAccount.status));
                return {
                    state: 'disabled',
                    onClick: function () { },
                    children: language.somethingWentWrong,
                };
        }
    }, [primaryPaymentAccount]);
    return toReturn;
};
export function CardBalanceAction() {
    var cardBalanceState = useCardBalanceActionState();
    return (_jsx(ImpContainer, __assign({ width: "100%" }, { children: _jsx(ImpSurface, __assign({ hasBorderRadius: true }, { children: _jsx(ImpFlex, __assign({ fluid: true, justify: "center" }, { children: _jsxs(ImpContainer, __assign({ width: "min(356px, 100% - 32px)" }, { children: [_jsx(ImpSpacer, { height: "xl" }), _jsx(ImpButton, __assign({}, cardBalanceState, { size: "fill", buttonStyle: "branded" })), _jsx(ImpSpacer, { height: "xl" })] })) })) })) })));
}
