var _a;
import { createSlice } from '@reduxjs/toolkit';
import { getInitLoadProductAccountResponse, } from '../api/loadProductAccount/loadProductAccount';
import { getEmptyPaymentDetails } from '../types/PaymentDetails';
var initialState = (getInitLoadProductAccountResponse === null || getInitLoadProductAccountResponse === void 0 ? void 0 : getInitLoadProductAccountResponse()) || null;
var productAccountSlice = createSlice({
    name: 'productAccount',
    initialState: initialState,
    reducers: {
        setDueDay: function (state, action) {
            if (!state.paymentDetails)
                state.paymentDetails = getEmptyPaymentDetails();
            state.paymentDetails.dueDay = action.payload;
        },
        setAccountCode: function (state, action) {
            state.accountCode = action.payload;
        },
        setAutopay: function (state, action) {
            state.autopay = action.payload;
        },
        setAutoPayDetails: function (state, action) {
            state.autoPayDetails = action.payload;
        },
        setCardBalanceV2: function (state, action) {
            state.cardBalanceV2 = action.payload;
        },
        setPaymentDetails: function (state, action) {
            state.paymentDetails = action.payload;
        },
        setProductDetails: function (state, action) {
            state.productDetails = action.payload;
        },
        setPhysicalCard: function (state, action) {
            state.physicalCard = action.payload;
        },
        setVirtualCard: function (state, action) {
            state.virtualCard = action.payload;
        },
        setRewards: function (state, action) {
            state.rewards = action.payload;
        },
        setAuthUserDetails: function (state, action) {
            state.authUserDetails = action.payload;
        },
        setActions: function (state, action) {
            state.actions = action.payload;
        },
        setAccount: function (state, action) {
            state.account = action.payload;
        },
        setWidgets: function (state, action) {
            state.widgets = action.payload;
        },
        setShippingWidget: function (state, action) {
            state.shippingWidget = action.payload;
        },
        resetProductAccount: function () { return initialState; },
    },
});
export var setDueDay = (_a = productAccountSlice.actions, _a.setDueDay), setProductDetails = _a.setProductDetails, setAccountCode = _a.setAccountCode, setAutopay = _a.setAutopay, setAutoPayDetails = _a.setAutoPayDetails, setCardBalanceV2 = _a.setCardBalanceV2, setPaymentDetails = _a.setPaymentDetails, setPhysicalCard = _a.setPhysicalCard, setVirtualCard = _a.setVirtualCard, setRewards = _a.setRewards, setAuthUserDetails = _a.setAuthUserDetails, setActions = _a.setActions, setAccount = _a.setAccount, setWidgets = _a.setWidgets, resetProductAccount = _a.resetProductAccount, setShippingWidget = _a.setShippingWidget;
export default productAccountSlice.reducer;
