import { colors } from '@imprint-payments/imprint-ui';
export var getBalanceRingColor = function (balancePercent) {
    var normalizedBalancePercent = balancePercent / 100;
    var errorThreshold = 0.7;
    var warningThreshold = 0.3;
    return normalizedBalancePercent > errorThreshold
        ? colors.content.onSurfaceError
        : normalizedBalancePercent > warningThreshold
            ? colors.content.onSurfaceWarning
            : colors.content.onSurfaceSuccess;
};
export var generateBalanceRings = function (cardBalanceV2) {
    var balancePercent = Math.min(100, cardBalanceV2.spendPercentage.valueInMinUnit);
    var pendingPercent = cardBalanceV2
        .pendingSpendPercentage
        ? cardBalanceV2.pendingSpendPercentage.valueInMinUnit
        : balancePercent;
    return [
        {
            color: getBalanceRingColor(balancePercent),
            percentComplete: balancePercent,
        },
        {
            color: colors.surface.surfaceDisabled,
            percentComplete: pendingPercent,
        },
    ].sort(function (a, b) {
        // sort by percentComplete descending
        // if tie, put the one with color second
        if (a.percentComplete === b.percentComplete) {
            return a.color === colors.surface.surfaceDisabled ? -1 : 1;
        }
        return b.percentComplete - a.percentComplete;
    });
};
