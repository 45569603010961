var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { ImpButton, ImpContainer, ImpFlex, ImpInput, ImpSpacer, ImpText, colors, } from '@imprint-payments/imprint-ui';
import { useEffect, useState } from 'react';
import { formatDob } from '../../utils/formatUtils';
import { ValidationResponse, validateBirthDate, } from '../../utils/validateUtils';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { ForgotPinScreenState, setForgotPinScreenState, } from '../../app/forgotPinSlice';
import { FORM_BUTTON_WIDTH } from '../../assets/consts/const';
import { forgotPinDob } from '../../api/forgotPin/forgotPinDob.api';
export function VerifyDobPage() {
    var _a = useState(''), birthday = _a[0], setBirthday = _a[1];
    var _b = useState(ValidationResponse.empty), birthdayError = _b[0], setBirthdayError = _b[1];
    var _c = useState('disabled'), buttonState = _c[0], setButtonState = _c[1];
    var language = useAppSelector(function (state) { return state.languageSelector.languageLocalization; });
    var forgotPinState = useAppSelector(function (state) { return state.forgotPinSlice; });
    var dispatch = useAppDispatch();
    useEffect(function () {
        if (birthday.length !== 0)
            dispatch(setForgotPinScreenState(ForgotPinScreenState.VALID));
        if (birthday.length < 10)
            setBirthdayError(ValidationResponse.empty);
        if (birthday.length === 10) {
            setBirthdayError(validateBirthDate(birthday));
        }
    }, [birthday]);
    function handleContinueClick() {
        if (birthdayError === ValidationResponse.valid) {
            dispatch(setForgotPinScreenState(ForgotPinScreenState.LOADING));
            forgotPinDob(birthday);
        }
    }
    useEffect(function () {
        if (forgotPinState.screenState === ForgotPinScreenState.LOADING) {
            setButtonState('submitting');
        }
        else if (birthdayError === ValidationResponse.valid) {
            setButtonState('enabled');
        }
        else {
            setButtonState('disabled');
        }
    }, [forgotPinState.screenState, birthdayError]);
    return (_jsx(ImpContainer, { children: _jsxs(ImpFlex, __assign({ direction: "column", align: "center", fluid: true }, { children: [_jsx(ImpText, __assign({ typography: "headline2", mobileTypography: "headline3" }, { children: language.loginDobHeader })), _jsx(ImpSpacer, { height: 'xl' }), _jsx(ImpContainer, __assign({ width: "100%" }, { children: _jsx(ImpInput, { label: language.loginDobField, value: birthday, showError: ![ValidationResponse.valid, ValidationResponse.empty].includes(birthdayError) ||
                            forgotPinState.screenState === ForgotPinScreenState.INCORRECT_DOB, errorMessage: '', inputStyle: "centered", onChange: function (e) {
                            setBirthday(formatDob(e.target.value));
                        }, onBlur: function () {
                            setBirthdayError(validateBirthDate(birthday));
                        }, maxLength: 10, onEnter: handleContinueClick, isDisabled: buttonState === 'submitting' }) })), forgotPinState.screenState === ForgotPinScreenState.INCORRECT_DOB && (_jsxs(_Fragment, { children: [_jsx(ImpSpacer, { height: 'l' }), _jsx(ImpText, __assign({ typography: "body1", color: colors.content.onBackgroundError }, { children: language.invalidServiceDob }))] })), _jsx(ImpSpacer, { height: 'xl' }), _jsx(ImpContainer, __assign({ width: FORM_BUTTON_WIDTH }, { children: _jsx(ImpButton, __assign({ onClick: handleContinueClick, state: buttonState, size: "fill" }, { children: language.continueBtn })) }))] })) }));
}
