var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ImpBreadcrumbs, ImpContainer, ImpFlex, ImpSpacer } from '@imprint-payments/imprint-ui';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { loadHome } from '../../api/loadHome.api';
import { updateAddress } from '../../api/updateAddress/updateAddress';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { RewardsRedemptionPage, setRewardsRedemptionPage } from '../../app/rewardsRedemptionSlice';
import { FORM_INPUT_WIDTH } from '../../assets/consts/const';
import HeaderServicing from '../../components/HeaderServicing/HeaderServicing';
import { displayTemporarySnackbar, navigateToAccountPage } from '../../utils/functionUtils';
import AddAddressPage from '../authorizedUsers/AddAddressPage';
import VerifyPinPage from '../authorizedUsers/VerifyPinPage';
export function RedeemUpdateAddressPage() {
    var dispatch = useAppDispatch();
    var navigate = useNavigate();
    var _a = useState(false), isSubmittingAddress = _a[0], setIsSubmittingAddress = _a[1];
    var language = useAppSelector(function (state) { return state.languageSelector.languageLocalization; });
    var _b = useState('verify'), updateState = _b[0], setUpdateState = _b[1];
    var onSuccess = function () {
        setIsSubmittingAddress(false);
        displayTemporarySnackbar(language.addressUpdated, dispatch);
        dispatch(setRewardsRedemptionPage(RewardsRedemptionPage.ConfigureRedemption));
        dispatch(loadHome());
    };
    var onError = function () {
        setIsSubmittingAddress(false);
        displayTemporarySnackbar(language.somethingWentWrong, dispatch);
    };
    var updateAddressBreadcrumbs = function () { return (_jsx(ImpBreadcrumbs, { breadcrumbs: [language.updateYourAddressTitle], onBackClick: function () {
            return dispatch(setRewardsRedemptionPage(RewardsRedemptionPage.ConfigureRedemption));
        }, onCancelClick: function () { return navigateToAccountPage(dispatch, navigate); } })); };
    switch (updateState) {
        case 'verify': {
            return (_jsxs(ImpContainer, { children: [_jsx(HeaderServicing, {}), updateAddressBreadcrumbs(), _jsx(ImpSpacer, { height: "2xl" }), _jsx(VerifyPinPage, { handleSuccess: function () { return setUpdateState('update'); } })] }));
        }
        case 'update': {
            return (_jsxs(ImpContainer, { children: [_jsx(HeaderServicing, {}), updateAddressBreadcrumbs(), _jsx(ImpSpacer, { height: "2xl" }), _jsx(ImpFlex, __assign({ direction: "column", align: "center", fluid: true }, { children: _jsx(ImpContainer, __assign({ width: FORM_INPUT_WIDTH }, { children: _jsx(AddAddressPage, { autofillConsumerAddress: true, title: language.updateYourAddressTitle, isSubmitting: isSubmittingAddress, handleContinueClick: function (address) {
                                    setIsSubmittingAddress(true);
                                    updateAddress({ address: address, onSuccess: onSuccess, onError: onError });
                                } }) })) }))] }));
        }
    }
}
