var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ImpActionPage, ImpButton, ImpInput, ImpSpacer, ImpText, colors, } from '@imprint-payments/imprint-ui';
import { useEffect, useState } from 'react';
import { EVENT } from '../../analytics/analyticsConsts';
import { generateUpdateEmailOtp } from '../../api/PersonalInfo/UpdateEmail/generateUpdateEmailOtp';
import { selectEmail, selectNewEmail } from '../../app/accountOnboardingSelectors';
import { setNewEmail } from '../../app/accountOnboardingSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectLanguage } from '../../app/languageSelectors';
import { selectMerchantData } from '../../app/merchantDataSelectors';
import { SCREEN, setScreen } from '../../app/screenStateSlice';
import { HEADER_SERVICING_HEIGHT } from '../../components/HeaderServicing/HeaderServicing';
import { ANALYTICS } from '../../firebase/firebase';
import { displayTemporarySnackbar } from '../../utils/functionUtils';
import { ValidationResponse, getShowError, getValidationString, handleValueChange, validateEmail, } from '../../utils/validateUtils';
export function ConfirmEmailPage() {
    var dispatch = useAppDispatch();
    var language = useAppSelector(selectLanguage);
    var merchantData = useAppSelector(selectMerchantData);
    var originalEmail = useAppSelector(selectEmail);
    var savedNewEmail = useAppSelector(selectNewEmail);
    var _a = useState(savedNewEmail || originalEmail), email = _a[0], setEmail = _a[1];
    var _b = useState(ValidationResponse.empty), emailError = _b[0], setEmailError = _b[1];
    var _c = useState(false), isSubmitting = _c[0], setIsSubmitting = _c[1];
    useEffect(function () {
        ANALYTICS.logEvent(EVENT.CONFIRM_EMAIL_VIEWED, {
            product_uuid: merchantData.productUUID,
        });
    }, []);
    useEffect(function () {
        handleValueChange(validateEmail(email), setEmailError);
    }, [email]);
    var handleConfirmClick = function () {
        ANALYTICS.logEvent(EVENT.CONFIRM_EMAIL_CLICKED, {
            product_uuid: merchantData.productUUID,
        });
        if (email === originalEmail) {
            dispatch(setScreen(SCREEN.GO_PAPERLESS));
        }
        else {
            setIsSubmitting(true);
            dispatch(generateUpdateEmailOtp(email, function () {
                setIsSubmitting(false);
                dispatch(setNewEmail(email));
                dispatch(setScreen(SCREEN.APP_OTPSCREEN));
            }, function () {
                setIsSubmitting(false);
                displayTemporarySnackbar(language.somethingWentWrong, dispatch);
            }));
        }
    };
    var getConfirmButtonState = function () {
        return isSubmitting
            ? 'submitting'
            : [ValidationResponse.valid, ValidationResponse.emailPotentialTypo].includes(emailError)
                ? 'enabled'
                : 'disabled';
    };
    return (_jsxs(ImpActionPage, __assign({ footer: _jsx(ImpButton, __assign({ buttonStyle: 'branded', state: getConfirmButtonState(), onClick: handleConfirmClick }, { children: language.confirmBtn })), offset: HEADER_SERVICING_HEIGHT }, { children: [_jsx(ImpSpacer, { height: "2xl" }), _jsx(ImpText, __assign({ typography: "headline3" }, { children: language.confirmYourEmailAddress })), _jsx(ImpSpacer, { height: "xs" }), _jsx(ImpText, __assign({ typography: "body2", color: colors.content.onSurfaceVariant }, { children: language.confirmEmailSubtitle })), _jsx(ImpSpacer, { height: "l" }), _jsx(ImpInput, { label: language.emailAddress, value: email, showError: getShowError(emailError), errorMessage: getValidationString(emailError, language), showWarning: emailError === ValidationResponse.emailPotentialTypo, onChange: function (e) { return setEmail(e.target.value); }, onBlur: function () {
                    setEmailError(validateEmail(email));
                    ANALYTICS.logEvent(EVENT.CONFIRM_EMAIL_EDITED, {
                        product_uuid: merchantData.productUUID,
                    });
                } }), _jsx(ImpSpacer, { height: "xl" })] })));
}
