import { PKG } from '../analytics/analyticsConsts';
import { API_CONFIG } from '../api/apiConfig';
import { ResponseCode } from '../api/http/httpConsts';
import { ANALYTICS } from '../firebase/firebase';
export function emptyApiResponse() {
    return {
        data: {
            body: {},
        },
        status: ResponseCode.HTTP_ERROR,
        message: '',
    };
}
// TODO: add tests
export function isEmpty(obj) {
    return (obj.status === ResponseCode.NO_CONTENT &&
        obj.message === '' &&
        !Object(obj.data.body).keys);
}
// TODO: test thoroughly
export function handleApiError(apiName, obj) {
    ANALYTICS.funcError(PKG, apiName, JSON.stringify({
        status: obj.status,
        message: obj.message,
        url: API_CONFIG[apiName].baseUrl,
    }));
}
// takes a response code, a mapping of response codes to handlers, and a generic error handler
// handlers must be able to take store, response, and optional params
export function getHandler(responseStatus, handlers, genericError) {
    return function (store, response, params) {
        (handlers[responseStatus] || genericError)(store, response, params);
    };
}
