var _a;
import { createSlice } from '@reduxjs/toolkit';
export var SnackbarContext;
(function (SnackbarContext) {
    SnackbarContext["DismissWithActivity"] = "DismissWithActivity";
    SnackbarContext["Default"] = "Default";
})(SnackbarContext || (SnackbarContext = {}));
var initialState = {
    snackbarVisible: false,
    snackbarText: '',
    context: SnackbarContext.Default,
    snackbarType: 'none',
};
export var TEMP_SNACKBAR_DURATION = 3000;
var SnackbarSlice = createSlice({
    name: 'snackbar',
    initialState: initialState,
    reducers: {
        displaySnackbar: function (state, action) {
            state.snackbarText = action.payload.snackbarText;
            state.context = action.payload.context;
            state.snackbarVisible = true;
            state.snackbarType = action.payload.snackbarType || 'none';
        },
        dismissSnackbar: function () { return initialState; },
    },
});
export var displaySnackbar = (_a = SnackbarSlice.actions, _a.displaySnackbar), dismissSnackbar = _a.dismissSnackbar;
export default SnackbarSlice.reducer;
