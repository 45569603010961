var _a;
import { createSlice } from '@reduxjs/toolkit';
var initialState = {
    confirmLater: false,
    confirmed: false,
    cardDesignSelection: false,
};
var acceptScreenSlice = createSlice({
    name: 'applicationScreen',
    initialState: initialState,
    reducers: {
        setConfirmAddressLater: function (state, action) {
            state.confirmLater = action.payload;
        },
        setConfirm: function (state, action) {
            state.confirmed = action.payload;
        },
        setCardDesignSelection: function (state, action) {
            state.cardDesignSelection = action.payload;
        },
    },
});
export var setConfirmAddressLater = (_a = acceptScreenSlice.actions, _a.setConfirmAddressLater), setConfirm = _a.setConfirm, setCardDesignSelection = _a.setCardDesignSelection;
export default acceptScreenSlice.reducer;
