var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ImpActionPage, ImpButton, ImpButtonDock, ImpSpacer, ImpText, colors, } from '@imprint-payments/imprint-ui';
import { useEffect, useState } from 'react';
import { EVENT } from '../../analytics/analyticsConsts';
import { submitAccountUpdate } from '../../api/api';
import { selectIsPaperless } from '../../app/accountOnboardingSelectors';
import { selectConsumerInfo } from '../../app/consumerInfoSelectors';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectLanguage } from '../../app/languageSelectors';
import { selectMerchantData } from '../../app/merchantDataSelectors';
import { SCREEN, setScreen } from '../../app/screenStateSlice';
import { STATEMENT_MEDIUM } from '../../app/statementsSlice';
import { HEADER_SERVICING_HEIGHT } from '../../components/HeaderServicing/HeaderServicing';
import { ANALYTICS } from '../../firebase/firebase';
import { displayTemporarySnackbar } from '../../utils/functionUtils';
export function PaperlessPreferencePage() {
    var dispatch = useAppDispatch();
    var language = useAppSelector(selectLanguage);
    var merchantData = useAppSelector(selectMerchantData);
    var isPaperless = useAppSelector(selectIsPaperless);
    var consumerInfo = useAppSelector(selectConsumerInfo);
    var _a = useState(false), isSubmitting = _a[0], setIsSubmitting = _a[1];
    useEffect(function () {
        ANALYTICS.logEvent(isPaperless ? EVENT.PAPERLESS_ENABLED_VIEWED : EVENT.GO_PAPERLESS_VIEWED, {
            product_uuid: merchantData.productUUID,
        });
    }, []);
    var handleBackClick = function () {
        dispatch(setScreen(SCREEN.CONFIRM_EMAIL));
    };
    function updateStatementMedium(statementMedium) {
        setIsSubmitting(true);
        dispatch(submitAccountUpdate({
            consumerUUID: consumerInfo.consumerUUID,
            statementPreferences: {
                statementMedium: statementMedium,
            },
        }, function () {
            setIsSubmitting(false);
            dispatch(setScreen(SCREEN.WELCOME));
        }, function () {
            // If api failed, we should still navigate the user to home
            setIsSubmitting(false);
            dispatch(setScreen(SCREEN.WELCOME));
            displayTemporarySnackbar(language.somethingWentWrong, dispatch);
        }));
    }
    var handlePrimaryClick = function () {
        ANALYTICS.logEvent(isPaperless ? EVENT.PAPERLESS_ENABLED_OKAY_CLICKED : EVENT.GO_PAPERLESS_CONFIRM_CLICKED, {
            product_uuid: merchantData.productUUID,
        });
        updateStatementMedium(STATEMENT_MEDIUM.paperless);
    };
    var handleSecondaryClick = function () {
        ANALYTICS.logEvent(EVENT.GO_PAPERLESS_NO_THANKS_CLICKED, {
            product_uuid: merchantData.productUUID,
        });
        updateStatementMedium(STATEMENT_MEDIUM.both);
    };
    var getButtonState = function () { return (isSubmitting ? 'submitting' : 'enabled'); };
    var _b = !isPaperless
        ? {
            title: language.goPaperless,
            subtitle: language.goPaperlessDetails1 + ' ' + language.goPaperlessDetails2,
            primaryBtnText: language.goPaperless,
            secondaryBtnText: language.iWantStatementsMailed,
        }
        : {
            title: language.paperlessEnabled,
            subtitle: language.goPaperlessDetails2,
            primaryBtnText: language.okay,
            secondaryBtnText: undefined,
        }, title = _b.title, subtitle = _b.subtitle, primaryBtnText = _b.primaryBtnText, secondaryBtnText = _b.secondaryBtnText;
    return (_jsxs(ImpActionPage, __assign({ footer: _jsxs(ImpButtonDock, { children: [_jsx(ImpButton, __assign({ buttonStyle: 'branded', state: getButtonState(), onClick: handlePrimaryClick }, { children: primaryBtnText })), secondaryBtnText && (_jsx(ImpButton, __assign({ variant: "secondary", buttonStyle: 'branded', state: getButtonState(), onClick: handleSecondaryClick }, { children: secondaryBtnText })))] }), onBackClick: handleBackClick, offset: HEADER_SERVICING_HEIGHT }, { children: [_jsx(ImpSpacer, { height: "2xl" }), _jsx(ImpText, __assign({ typography: "headline3" }, { children: title })), _jsx(ImpSpacer, { height: "xs" }), _jsx(ImpText, __assign({ typography: "body2", color: colors.content.onSurfaceVariant }, { children: subtitle })), _jsx(ImpSpacer, { height: "xl" })] })));
}
