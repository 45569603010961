var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { ImpOfferPage, ImpSpacer } from '@imprint-payments/imprint-ui';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { EVENT } from '../../analytics/analyticsConsts';
import { getCardDesignOptions } from '../../api/api';
import { ACTION_TYPE } from '../../api/apiConstants';
import { setCardDesignSelection } from '../../app/acceptScreenSlice';
import { selectActiveCreditCards, selectHome, selectSelectedProductAccount, selectSelectedProductAccountUUID, } from '../../app/homeSelectors';
import { ACCOUNT_STATUS, setSelectedProductAccount } from '../../app/homeSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectMerchantData } from '../../app/merchantDataSelectors';
import { ANALYTICS } from '../../firebase/firebase';
import { clearHomePageState, navigateToAccountPage } from '../../utils/functionUtils';
import { PRODUCTUUID } from '../../utils/productUUIDConstants';
import { PAGE } from '../../utils/routeConstant';
import Confetti from '../Confetti/Confetti';
import { useOfferPage } from './OfferHeaderUtils';
var useConfetti = function () {
    var productUUID = useAppSelector(selectSelectedProductAccountUUID);
    switch (productUUID) {
        case PRODUCTUUID.HEB:
        case PRODUCTUUID.CM:
            return _jsx(Confetti, {});
        default:
            return _jsx(_Fragment, {});
    }
};
export default function OfferHeader() {
    var consumerInfo = useAppSelector(function (state) { return state.consumerInfoSelector; });
    var activeCardList = useAppSelector(selectActiveCreditCards);
    var consumerData = useAppSelector(function (state) { return state.consumerDataSelector; });
    var selectedProductAccount = useAppSelector(selectSelectedProductAccount);
    var confetti = useConfetti();
    var home = useAppSelector(selectHome);
    var merchantData = useAppSelector(selectMerchantData);
    var dispatch = useAppDispatch();
    var navigate = useNavigate();
    useEffect(function () {
        window.scrollTo(0, 0);
        //  if single-card offer case, allow user to stay on page
        if (consumerInfo.actions &&
            consumerInfo.actions[0].type === ACTION_TYPE.SHOW_OFFER_SCREEN &&
            activeCardList.length === 0) {
            return;
        }
        // if not single card offer case and the selected product account is not offer pending, redirect to account page
        if ((consumerData === null || consumerData === void 0 ? void 0 : consumerData.consumerUUID) !== '' &&
            (selectedProductAccount === null || selectedProductAccount === void 0 ? void 0 : selectedProductAccount.productAccountStatus) !== ACCOUNT_STATUS.OFFER_PENDING) {
            navigateToAccountPage(dispatch, navigate);
        }
    }, []);
    useEffect(function () {
        var _a;
        if (((_a = home.application.cardOffer.cardDesignOptions) === null || _a === void 0 ? void 0 : _a.length) > 1) {
            for (var _i = 0, _b = home.application.cardOffer.cardDesignOptions; _i < _b.length; _i++) {
                var cardDesignOption = _b[_i];
                var productUUID = merchantData.productUUID;
                dispatch(getCardDesignOptions(cardDesignOption.cardDesignID, productUUID));
            }
            dispatch(setCardDesignSelection(true));
        }
    }, [home.application, home.productAccounts]);
    function backClick() {
        if (activeCardList.length === 0) {
            clearHomePageState(dispatch);
        }
        else {
            // Do not clear the page state if the user has existing cards
            dispatch(setSelectedProductAccount(activeCardList[0]));
        }
        navigate(PAGE.HOME);
    }
    useEffect(function () {
        window.history.pushState('fake-route', document.title, window.location.href);
        addEventListener('popstate', backClick);
        return function () {
            removeEventListener('popstate', backClick);
            if (window.history.state === 'fake-route') {
                window.history.back();
            }
        };
    }, []);
    useEffect(function () {
        ANALYTICS.logEvent(EVENT.OFFER_VIEWED);
    }, []);
    var offerPageData = useOfferPage();
    return (_jsxs(_Fragment, { children: [confetti, _jsx(ImpSpacer, { height: "2xl", mobileHeight: "xl" }), _jsx(ImpOfferPage, __assign({}, offerPageData))] }));
}
