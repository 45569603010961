var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Icons, ImpBreadcrumbs, ImpButton, ImpContainer, ImpFlex, ImpFlexItem, ImpIcon, ImpInlineLink, ImpList, ImpSpacer, ImpSpinner, ImpText, colors, } from '@imprint-payments/imprint-ui';
import { useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import { useNavigate } from 'react-router-dom';
import { EVENT } from '../../analytics/analyticsConsts';
import { loadScheduledPayments, submitCancelPayment, submitUpdatePayment } from '../../api/api';
import { ImprintConstants } from '../../api/apiConstants';
import { loadHome } from '../../api/loadHome.api';
import { MODAL_ENUM, setShowModal } from '../../app/applicationScreenSlice';
import { selectPaymentsEnhancementsFlag } from '../../app/featureFlagSelectors';
import { SUCCESS_TYPE, setStartedBankLinking, setSuccessInformation } from '../../app/homeSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { PaymentPageStage, resetPaymentsScreen, setPaymentsPage, setSelectedPayment, } from '../../app/paymentsPageSlice';
import { selectPaymentDetails } from '../../app/productAccountSelectors';
import { SCREEN, setPreviousScreen, setScreen } from '../../app/screenStateSlice';
import Button, { ButtonColorScheme } from '../../components/button/Button';
import CancelPage from '../../components/CancelPage/CancelPage';
import HeaderServicing from '../../components/HeaderServicing/HeaderServicing';
import { LIST_STYLE, ListSection } from '../../components/list/List';
import ModalDefinitionText from '../../components/ModalDefinitionText/ModalDefinitionText';
import PaymentDefinitionModal from '../../components/popup/PaymentDefinitionModal';
import { ANALYTICS } from '../../firebase/firebase';
import { convertAmountToString, currencyToNumber, formatDollarStringToString, getNextAutoPayDate, months, } from '../../utils/formatUtils';
import { generatePayByCheckUrl, getLanguageFromCookie, navigateToAccountPage, } from '../../utils/functionUtils';
import { LanguageCode } from '../../utils/languageConstants';
import { PAGE } from '../../utils/routeConstant';
import { PaymentAccountStatus } from '../dashboard/Dashboard';
import '../style/PaymentCalendar.css';
import styles from './Payments.module.css';
import { getPaymentAmountOptions } from './paymentsUtils';
import { UpcomingPayments } from './UpcomingPayments';
export default function Payments() {
    var _a, _b, _c, _d, _e;
    var language = useAppSelector(function (state) { return state.languageSelector.languageLocalization; });
    var selectedLanguage = useAppSelector(function (state) { return state.languageSelector.languageCode; });
    var paymentDetails = useAppSelector(selectPaymentDetails);
    var paymentsEnhancementsEnabled = useAppSelector(selectPaymentsEnhancementsFlag);
    var dispatch = useAppDispatch();
    var navigate = useNavigate();
    var appScreenState = useAppSelector(function (state) { return state.applicationScreenSelector; });
    var productAccount = useAppSelector(function (state) { return state.productAccountSelector; });
    var paymentAccount = useAppSelector(function (state) { return state.homeSelector.primaryPaymentAccount; });
    var paymentsPageState = useAppSelector(function (state) { return state.paymentsPageSelector; });
    var languageState = useAppSelector(function (state) { return state.languageSelector; });
    var merchantData = useAppSelector(function (state) { return state.merchantDataSelector; });
    var consumerInfo = useAppSelector(function (state) { return state.consumerInfoSelector; });
    var homeSelector = useAppSelector(function (state) { return state.homeSelector; });
    var selectedProductAccountCreditLineUUID = (_b = (_a = homeSelector.selectedProductAccount) === null || _a === void 0 ? void 0 : _a.creditLineUUID) !== null && _b !== void 0 ? _b : '';
    // Return to home if invalid data is passed in.
    if (selectedProductAccountCreditLineUUID === '') {
        navigate(PAGE.HOME);
    }
    var paymentRequirements = homeSelector.paymentRequirements[selectedProductAccountCreditLineUUID];
    var _f = useState(convertAmountToString(paymentRequirements === null || paymentRequirements === void 0 ? void 0 : paymentRequirements.statement)), selectedRescheduleValue = _f[0], setSelectedRescheduleValue = _f[1];
    var primaryPaymentAccount = useAppSelector(function (state) { return state.homeSelector.primaryPaymentAccount; });
    var todayDate = new Date();
    var minScheduleDate = new Date();
    minScheduleDate.setDate(todayDate.getDate() + 1);
    var maxScheduleDate = new Date(0);
    maxScheduleDate.setUTCSeconds(paymentRequirements === null || paymentRequirements === void 0 ? void 0 : paymentRequirements.maxScheduleDateEpochSec);
    var editTimeDeadline = new Date(0);
    editTimeDeadline.setUTCSeconds((_c = paymentsPageState.selectedPayment) === null || _c === void 0 ? void 0 : _c.editTimeDeadlineToEpochSec);
    var selectedPaymentPastEditDeadline = todayDate > editTimeDeadline;
    var _g = useState(false), isPaymentValid = _g[0], setIsPaymentValid = _g[1];
    // We will set payment date by the date on calendar once the confirmation button is clicked.
    var _h = useState(new Date()), rescheduledPaymentDate = _h[0], setRescheduledPaymentDate = _h[1];
    var paymentDateConfirmationSubtitleFormatted = rescheduledPaymentDate.toLocaleDateString(getLanguageFromCookie(), {
        month: 'numeric',
        day: 'numeric',
    });
    var _j = useState(minScheduleDate), calendarDate = _j[0], setCalendarDate = _j[1];
    var paymentDateCalendarSubheaderFormatted = calendarDate.toLocaleDateString(getLanguageFromCookie(), { month: 'short', day: 'numeric' });
    var _k = useState(true), isLoading = _k[0], setIsLoading = _k[1];
    var _l = useState(false), inCancelConfirmation = _l[0], setInCancelConfirmation = _l[1];
    var _m = useState(''), paymentMethodName = _m[0], setPaymentMethodName = _m[1];
    useEffect(function () {
        dispatch(loadHome());
    }, []);
    // Retrieve all the productAccountUUID for current product account
    useEffect(function () {
        var _a;
        dispatch(loadScheduledPayments([(_a = homeSelector.selectedProductAccount) === null || _a === void 0 ? void 0 : _a.productAccountUUID]));
        return function () {
            dispatch(resetPaymentsScreen());
        };
    }, []);
    var scheduledPayments = useAppSelector(function (state) { return state.activitySelector.scheduledPayments; });
    var autoPayEnabled = !(((_d = productAccount.autopay) === null || _d === void 0 ? void 0 : _d.paymentPlan) === null ||
        ((_e = productAccount.autopay) === null || _e === void 0 ? void 0 : _e.paymentPlan) === undefined);
    // Payment option will jump to link bank ccount page if bank account needs repair.
    var connectBankNeeded = primaryPaymentAccount.status === PaymentAccountStatus.Apply;
    var fixAccountNeeded = primaryPaymentAccount.status === PaymentAccountStatus.Repair ||
        primaryPaymentAccount.status === PaymentAccountStatus.ReviewManual ||
        primaryPaymentAccount.status === PaymentAccountStatus.ReviewAuto;
    var getItemDescription = function (isScheduledPayments, scheduledPaymentValue) {
        if (connectBankNeeded) {
            return language.connectABank;
        }
        else if (fixAccountNeeded) {
            return language.fixAccount;
        }
        else if (isScheduledPayments) {
            return scheduledPaymentValue;
        }
        else {
            return null;
        }
    };
    // We will disable the "schedule a payment" option if the user have no balance to pay, or needs to connect a bank.
    var disableScheduleAPayment = connectBankNeeded ||
        !paymentRequirements ||
        ((paymentRequirements === null || paymentRequirements === void 0 ? void 0 : paymentRequirements.current.valueInMinUnit) <= 0 &&
            (paymentRequirements === null || paymentRequirements === void 0 ? void 0 : paymentRequirements.statement.valueInMinUnit) <= 0 &&
            (paymentRequirements === null || paymentRequirements === void 0 ? void 0 : paymentRequirements.minimum.valueInMinUnit) <= 0);
    var paymentOptionList = [
        {
            title: language.autoPay,
            subtitle: autoPayEnabled
                ? language.autoPayDueDashboard + getNextAutoPayDate(productAccount)
                : language.autoPaySubtitle,
            leadingIcon: _jsx(ImpIcon, { iconSrc: Icons.arrowsClockwise, size: "24px" }),
            trailingTitle: getItemDescription(),
            onClick: function () {
                if (fixAccountNeeded) {
                    dispatch(setStartedBankLinking(true));
                    dispatch(setScreen(SCREEN.BANKING));
                    navigate(PAGE.BANKING);
                    ANALYTICS.logEvent(EVENT.PAYMENTS_CONNECT_BANK_ACCOUNT_CLICKED);
                }
                else {
                    // Save previous screen state for auto-pay to navigate back.
                    dispatch(setPreviousScreen(SCREEN.PAYMENTS));
                    dispatch(setScreen(SCREEN.AUTOPAY));
                    navigate(PAGE.AUTOPAY);
                    ANALYTICS.logEvent(EVENT.SETUP_AUTOPAY_CLICKED);
                }
            },
            trailingContent: _jsx(ImpIcon, { iconSrc: Icons.chevronRight }),
        },
        {
            title: language.scheduleAPayment,
            subtitle: language.scheduleAPaymentSubtitle,
            leadingIcon: _jsx(ImpIcon, { iconSrc: Icons.calendar, size: "24px" }),
            trailingTitle: getItemDescription(),
            isDisabled: disableScheduleAPayment,
            onClick: function () {
                if (fixAccountNeeded) {
                    dispatch(setStartedBankLinking(true));
                    dispatch(setScreen(SCREEN.BANKING));
                    navigate(PAGE.BANKING);
                    ANALYTICS.logEvent(EVENT.PAYMENTS_CONNECT_BANK_ACCOUNT_CLICKED);
                }
                else {
                    dispatch(setPreviousScreen(SCREEN.PAYMENTS));
                    dispatch(setScreen(SCREEN.ONE_TIME_PAYMENT));
                    navigate(PAGE.ONE_TIME_PAYMENT);
                    ANALYTICS.logEvent(EVENT.SCHEDULE_A_PAYMENT_CLICKED);
                }
            },
            trailingContent: _jsx(ImpIcon, { iconSrc: Icons.chevronRight }),
        },
        {
            title: language.payByCheck,
            subtitle: language.payByCheckSubtitle,
            trailingContent: _jsx(ImpIcon, { iconSrc: Icons.chevronRight }),
            leadingIcon: _jsx(ImpIcon, { iconSrc: Icons.mail }),
            onClick: function () {
                var link = generatePayByCheckUrl(merchantData.merchantName, languageState.languageCode);
                window.open(link, '_blank');
            },
        },
    ];
    var getScheduledPaymentList = function () {
        var scheduledPaymentList = [];
        if (scheduledPayments.length > 0) {
            scheduledPayments.forEach(function (scheduledPayment) {
                scheduledPaymentList.push({
                    title: language.scheduledPayment,
                    subtitle: scheduledPayment.dateTime,
                    trailingTitle: getItemDescription(true, scheduledPayment.value),
                    onClick: function () {
                        if (fixAccountNeeded) {
                            dispatch(setStartedBankLinking(true));
                            dispatch(setScreen(SCREEN.BANKING));
                            navigate(PAGE.BANKING);
                            ANALYTICS.logEvent(EVENT.PAYMENTS_CONNECT_BANK_ACCOUNT_CLICKED);
                        }
                        else {
                            dispatch(setPaymentsPage(PaymentPageStage.PaymentDetails));
                            dispatch(setSelectedPayment(scheduledPayment));
                            ANALYTICS.logEvent(EVENT.MODIFY_SCHEDULED_PAYMENT_CLICKED);
                        }
                    },
                    trailingContent: _jsx(ImpIcon, { iconSrc: Icons.chevronRight }),
                });
            });
        }
        else {
            scheduledPaymentList.push({
                title: language.noScheduledPayments,
            });
        }
        return scheduledPaymentList;
    };
    // Jump to success page if update or cancel succeeded.
    useEffect(function () {
        if (homeSelector.successType !== SUCCESS_TYPE.default) {
            if (homeSelector.successType === SUCCESS_TYPE.paymentCancel) {
                dispatch(setSuccessInformation({
                    amount: paymentsPageState.selectedPayment.value,
                    date: paymentsPageState.selectedPayment.dateTime,
                }));
            }
            else if (homeSelector.successType === SUCCESS_TYPE.paymentPayNow ||
                homeSelector.successType === SUCCESS_TYPE.paymentSchedule) {
                dispatch(setSuccessInformation({
                    amount: selectedRescheduleValue,
                    date: paymentDateConfirmationSubtitleFormatted,
                }));
            }
            dispatch(setScreen(SCREEN.SUCCESS));
            navigate(PAGE.SUCCESS);
        }
    }, [homeSelector.successType]);
    useEffect(function () {
        if (!selectedRescheduleValue) {
            setSelectedRescheduleValue(convertAmountToString(paymentRequirements === null || paymentRequirements === void 0 ? void 0 : paymentRequirements.statement));
        }
    });
    useEffect(function () {
        var selectedValueNum = currencyToNumber(selectedRescheduleValue);
        var currentBalanceNum = currencyToNumber(convertAmountToString(paymentRequirements === null || paymentRequirements === void 0 ? void 0 : paymentRequirements.current));
        var MINIMUM_PAYMENT = 0.01;
        if (selectedValueNum >= MINIMUM_PAYMENT && selectedValueNum <= currentBalanceNum) {
            setIsPaymentValid(true);
        }
        else {
            setIsPaymentValid(false);
        }
    }, [selectedRescheduleValue, homeSelector]);
    useEffect(function () {
        // checks if payment requirements values are available
        if (paymentAccount.status === '' || !language) {
            setIsLoading(true);
        }
        else {
            setIsLoading(false);
        }
    }, [paymentAccount.status, language]);
    var paymentOptions = getPaymentAmountOptions(language, paymentRequirements, setPaymentMethodName);
    var paymentDetailsOrConfirmation = [
        {
            title: language.paymentAmount,
            subtitle: paymentsPageState.page === PaymentPageStage.PaymentDetails
                ? paymentsPageState.selectedPayment.value
                : selectedRescheduleValue,
        },
        {
            title: language.paymentDate,
            subtitle: paymentsPageState.page === PaymentPageStage.PaymentDetails
                ? paymentsPageState.selectedPayment.dateTime
                : paymentDateConfirmationSubtitleFormatted,
        },
        {
            title: language.paymentMethod,
            subtitle: language.accountEnding + primaryPaymentAccount.accountNumberLast4,
        },
    ];
    var getContent = function () {
        var _a, _b, _c, _d;
        var content = {
            headerValue: '',
            subheaderValue: '',
            definition: '',
            appNotice1: '',
            appNotice2: '',
            leftBtnValue: '',
            rightBtnValue: '',
        };
        var selectedPaymentSubheader = language.paymentScheduledFor + ' ' + paymentsPageState.selectedPayment.dateTime + '.';
        var nextPaymentSubheader = '';
        if (productAccount.paymentDetails) {
            nextPaymentSubheader =
                language.nextPaymentDue +
                    ' ' +
                    months(selectedLanguage)[((_b = (_a = productAccount === null || productAccount === void 0 ? void 0 : productAccount.paymentDetails) === null || _a === void 0 ? void 0 : _a.dueDay) === null || _b === void 0 ? void 0 : _b.month) - 1] +
                    ' ' +
                    ((_d = (_c = productAccount === null || productAccount === void 0 ? void 0 : productAccount.paymentDetails) === null || _c === void 0 ? void 0 : _c.dueDay) === null || _d === void 0 ? void 0 : _d.day) +
                    '.';
        }
        switch (paymentsPageState.page) {
            case PaymentPageStage.PaymentDetails:
                content = {
                    headerValue: paymentsPageState.selectedPayment.value,
                    subheaderValue: selectedPaymentSubheader,
                    definition: '',
                    appNotice1: selectedPaymentPastEditDeadline ? language.unableEditPaymentPastDeadline : '',
                    appNotice2: '',
                    leftBtnValue: language.cancelPayment,
                    rightBtnValue: language.editPayment,
                };
                break;
            case PaymentPageStage.PaymentInEdit:
                content = {
                    headerValue: selectedRescheduleValue,
                    subheaderValue: nextPaymentSubheader,
                    definition: '',
                    appNotice1: '',
                    appNotice2: '',
                    leftBtnValue: language.payLater,
                    rightBtnValue: language.payNow,
                };
                break;
            case PaymentPageStage.PaymentSelected:
                content = {
                    headerValue: selectedRescheduleValue,
                    subheaderValue: nextPaymentSubheader,
                    definition: '',
                    appNotice1: language.disclosureAgree1,
                    appNotice2: language.disclosureAgree2,
                    leftBtnValue: language.editPayment,
                    rightBtnValue: language.confirmBtn,
                };
                break;
            case PaymentPageStage.InCalendarDateSelection:
                content = {
                    headerValue: selectedRescheduleValue,
                    subheaderValue: nextPaymentSubheader,
                    definition: '',
                    appNotice1: '',
                    appNotice2: '',
                    leftBtnValue: '',
                    rightBtnValue: '',
                };
                break;
            case PaymentPageStage.PaymentOverview:
            default:
        }
        return content;
    };
    // We send valid scheduled date in the request for scheduled payment, and send null scheduled date for paying now.
    var sendScheduledPaymentForReschedule = !(rescheduledPaymentDate.getMonth() == todayDate.getMonth() &&
        rescheduledPaymentDate.getDate() == todayDate.getDate());
    var scheduledDateSecondsSinceEpoch = Math.round(rescheduledPaymentDate.getTime() / 1000);
    // cancelConfirmBtnClick will handle the cancel confirmation button click
    var cancelConfirmBtnClick = function () {
        // "Cancel payment" action triggered.
        ANALYTICS.logEvent(EVENT.SCHEDULED_PAYMENT_EDIT_CANCEL_PAYMENT_CLICKED, {
            payment_amount: paymentMethodName,
            payment_value: selectedRescheduleValue,
        });
        dispatch(submitCancelPayment(paymentsPageState.selectedPayment.transactionUUID));
    };
    // leftBtnClick will handle the "Cancel Payment", "Pay later", and "Edit Payment" function call.
    var leftBtnClick = function () {
        switch (paymentsPageState.page) {
            case PaymentPageStage.PaymentDetails:
                // enter cancel confirmation UX
                setInCancelConfirmation(true);
                break;
            case PaymentPageStage.PaymentInEdit:
                // "Pay later" action triggered.
                ANALYTICS.logEvent(EVENT.SCHEDULED_PAYMENT_EDIT_PAY_LATER_CLICKED);
                dispatch(setPaymentsPage(PaymentPageStage.InCalendarDateSelection));
                break;
            case PaymentPageStage.PaymentSelected:
                // "Edit payment" action triggered.
                ANALYTICS.logEvent(EVENT.SCHEDULED_PAYMENT_EDIT_EDIT_PAYMENT_CLICKED);
                dispatch(setPaymentsPage(PaymentPageStage.PaymentInEdit));
                break;
            // Left button should not appear in the overview and calendar stage.
            case PaymentPageStage.PaymentOverview:
            case PaymentPageStage.InCalendarDateSelection:
            default:
        }
    };
    // rightBtnClick will handle the "Edit payment", "Pay now", and "Confirm" function call.
    var rightBtnClick = function () {
        switch (paymentsPageState.page) {
            case PaymentPageStage.PaymentDetails:
                // "Edit payment" action triggered.
                ANALYTICS.logEvent(EVENT.SCHEDULED_PAYMENT_EDIT_EDIT_PAYMENT_CLICKED);
                dispatch(setPaymentsPage(PaymentPageStage.PaymentInEdit));
                break;
            case PaymentPageStage.PaymentInEdit:
                // "Pay now" action triggered.
                ANALYTICS.logEvent(EVENT.SCHEDULED_PAYMENT_EDIT_PAY_NOW_CLICKED, {
                    payment_amount: paymentMethodName,
                    payment_value: selectedRescheduleValue,
                });
                dispatch(setPaymentsPage(PaymentPageStage.PaymentSelected));
                break;
            case PaymentPageStage.PaymentSelected:
                // "Confirm" action triggered.
                ANALYTICS.logEvent(EVENT.SCHEDULED_PAYMENT_EDIT_CONFIRM_CLICKED, {
                    payment_amount: paymentMethodName,
                    payment_value: selectedRescheduleValue,
                });
                dispatch(submitUpdatePayment({
                    consumerUUID: consumerInfo.consumerUUID,
                    transactionUUID: paymentsPageState.selectedPayment.transactionUUID,
                    amount: parseFloat(formatDollarStringToString(selectedRescheduleValue)),
                    currency: 'USD',
                    creditLineUUID: selectedProductAccountCreditLineUUID,
                    scheduledDateEpochSec: sendScheduledPaymentForReschedule
                        ? scheduledDateSecondsSinceEpoch
                        : null,
                }));
                break;
            // Left button should not appear in the overview and calendar stage.
            case PaymentPageStage.PaymentOverview:
            case PaymentPageStage.InCalendarDateSelection:
            default:
        }
    };
    var disableButtons = paymentsPageState.page === PaymentPageStage.PaymentDetails
        ? selectedPaymentPastEditDeadline
        : !isPaymentValid;
    // Get the button state for ImpButton
    var getButtonState = function () {
        if (disableButtons) {
            return 'disabled';
        }
        return appScreenState.loading ? 'submitting' : 'enabled';
    };
    var getBreadcrumbsTitle = function () {
        switch (paymentsPageState.page) {
            case PaymentPageStage.PaymentDetails:
                return [language.breadcrumbScheduledPayment];
            case PaymentPageStage.PaymentInEdit:
                return [language.breadcrumbPayments, language.breadcrumbScheduleAPayment];
            case PaymentPageStage.PaymentSelected:
                return [language.breadcrumbPayments, language.breadcrumbPaymentConfirmation];
            case PaymentPageStage.InCalendarDateSelection:
                return [language.breadcrumbPayments, language.breadcrumbScheduleAPayment];
            case PaymentPageStage.PaymentOverview:
            default:
                return [language.breadcrumbPayments];
        }
    };
    function handleBackClick() {
        setInCancelConfirmation(false);
        switch (paymentsPageState.page) {
            case PaymentPageStage.PaymentDetails:
                dispatch(setPaymentsPage(PaymentPageStage.PaymentOverview));
                break;
            case PaymentPageStage.PaymentInEdit:
                dispatch(setPaymentsPage(PaymentPageStage.PaymentDetails));
                break;
            case PaymentPageStage.InCalendarDateSelection:
            case PaymentPageStage.PaymentSelected:
                dispatch(setPaymentsPage(PaymentPageStage.PaymentInEdit));
                break;
            case PaymentPageStage.PaymentOverview:
            default:
                navigateToAccountPage(dispatch, navigate);
                break;
        }
    }
    if (isLoading) {
        return (_jsxs("div", { children: [_jsx(HeaderServicing, {}), _jsx(ImpBreadcrumbs, { breadcrumbs: [language.breadcrumbPayments, language.breadcrumbOneTimePayment], onBackClick: function () {
                        handleBackClick();
                    }, onCancelClick: function () { return navigateToAccountPage(dispatch, navigate); } }), _jsx(ImpSpacer, { height: "2xl" }), _jsx(ImpContainer, __assign({ width: "100%" }, { children: _jsx(ImpSpinner, {}) }))] }));
    }
    return (_jsxs(_Fragment, { children: [_jsx(HeaderServicing, {}), _jsx(ImpBreadcrumbs, { breadcrumbs: getBreadcrumbsTitle(), onBackClick: function () { return handleBackClick(); }, onCancelClick: function () { return navigateToAccountPage(dispatch, navigate); } }), _jsx("div", __assign({ className: styles.container }, { children: _jsx("div", __assign({ className: styles.body }, { children: paymentsPageState.page === PaymentPageStage.PaymentOverview ? (
                    // Show the payment options and scheduled payments in the payment overview stage.
                    _jsxs(_Fragment, { children: [_jsx(ImpSpacer, { height: "2xl", mobileHeight: "xl" }), _jsxs(ImpFlex, __assign({ direction: "column", gap: "l", mobileGap: "m", fluid: true }, { children: [_jsx(ImpContainer, __assign({ width: "100%" }, { children: _jsx(ImpList, { title: language.paymentOptions, items: paymentOptionList, useSurface: true }) })), paymentDetails &&
                                        (paymentsEnhancementsEnabled ? (_jsx(UpcomingPayments, {})) : (_jsx(ImpContainer, __assign({ width: "100%" }, { children: _jsx(ImpList, { title: language.scheduledPayments, items: getScheduledPaymentList(), useSurface: true }) }))))] }))] })) : (
                    // Show the edit payment with edit options in other stages.
                    _jsxs(_Fragment, { children: [appScreenState.showModal === MODAL_ENUM.showPaymentDefinitionModal && (_jsx(PaymentDefinitionModal, {})), inCancelConfirmation ? (_jsx(CancelPage, { header: language.cancelConfirmationHeader, subheader: language.cancelConfirmationSubheader, goBackBtnValue: language.cancelGoBackBtn, confirmBtnValue: language.cancelConfirmCancelBtn, isLoading: appScreenState.loading, goBackClick: function () {
                                    setInCancelConfirmation(false);
                                }, confirmClick: function () {
                                    cancelConfirmBtnClick();
                                } })) : (_jsx(ImpContainer, __assign({ width: 'var(--max-width-desktop)' }, { children: _jsxs(ImpFlex, __assign({ direction: "column", align: "center" }, { children: [_jsx(ImpSpacer, { height: '2xl' }), _jsx(ImpText, __assign({ typography: "headline1" }, { children: getContent().headerValue })), _jsx(ImpSpacer, { height: 'l' }), _jsx(ImpText, __assign({ typography: "body2", mobileTypography: "body3", color: colors.content.onBackgroundVariant }, { children: getContent().subheaderValue })), _jsx(ImpSpacer, { height: 'xl' }), paymentsPageState.page === PaymentPageStage.InCalendarDateSelection ? (_jsxs("div", __assign({ className: styles.paymentDateSelectionContainer }, { children: [_jsx(Calendar, { onChange: setCalendarDate, locale: getLanguageFromCookie(), minDate: minScheduleDate, maxDate: maxScheduleDate, defaultValue: minScheduleDate }), _jsxs("div", __assign({ className: styles.paymentDateSelectionSubheader }, { children: [language.scheduledPaymentSubheader, _jsx("b", { children: paymentDateCalendarSubheaderFormatted })] })), _jsx(Button, { value: language.continueBtn, colorScheme: ButtonColorScheme.fillImprint, handleClick: function () {
                                                        dispatch(setPaymentsPage(PaymentPageStage.PaymentSelected));
                                                        setRescheduledPaymentDate(calendarDate);
                                                        ANALYTICS.logEvent(EVENT.SCHEDULED_PAYMENT_EDIT_DATE_SELECTION_CLICKED);
                                                    } })] }))) : (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: paymentsPageState.page === PaymentPageStage.PaymentInEdit
                                                        ? styles.visible
                                                        : styles.hidden }, { children: [_jsx(ListSection, { style: LIST_STYLE.SELECT, items: paymentOptions, defaultSelection: 1, onItemSelect: function (value) {
                                                                if (paymentsPageState.page === PaymentPageStage.PaymentInEdit)
                                                                    setSelectedRescheduleValue(value);
                                                            } }), _jsx(ModalDefinitionText, { text: language.paymentDefinition, onClick: function () {
                                                                dispatch(setShowModal(MODAL_ENUM.showPaymentDefinitionModal));
                                                            } })] })), _jsx("div", __assign({ className: paymentsPageState.page === PaymentPageStage.PaymentDetails ||
                                                        paymentsPageState.page === PaymentPageStage.PaymentSelected
                                                        ? styles.visible
                                                        : styles.hidden }, { children: _jsx(ListSection, { style: LIST_STYLE.DEFAULT, items: paymentDetailsOrConfirmation, noPointerCursor: true }) })), _jsxs("p", __assign({ className: styles.appNotice }, { children: [getContent().appNotice1, paymentsPageState.page === PaymentPageStage.PaymentSelected && (_jsx(ImpInlineLink, __assign({ color: 'var(--black)', url: language.selected === LanguageCode.English
                                                                ? ImprintConstants.PaymentConsent
                                                                : ImprintConstants.PaymentConsentES, onClick: function () {
                                                                ANALYTICS.logEvent(EVENT.ONE_TIME_PAYMENT_LINK_CLICKED);
                                                            } }, { children: language.consentAuthorization }))), getContent().appNotice2] })), _jsxs(ImpFlex, __assign({ direction: "row", mobileDirection: "column", gap: "xl", align: "center", mobileAlign: "stretch", fluid: true }, { children: [_jsx(ImpFlexItem, __assign({ flex: '1 1 0' }, { children: _jsx(ImpButton, __assign({ size: "fill", variant: "secondary", buttonStyle: "imprint", onClick: leftBtnClick, state: getButtonState() }, { children: getContent().leftBtnValue })) })), _jsx(ImpFlexItem, __assign({ flex: '1 1 0' }, { children: _jsx(ImpButton, __assign({ size: "fill", variant: "primary", buttonStyle: "imprint", onClick: rightBtnClick, state: getButtonState() }, { children: getContent().rightBtnValue })) }))] }))] }))] })) })))] })) })) }))] }));
}
