var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ImpActionPage, ImpButton, ImpButtonDock, ImpContainer, ImpFlex, ImpInput, ImpPad, ImpSpacer, ImpText, colors, } from '@imprint-payments/imprint-ui';
import { useEffect, useState } from 'react';
import { EVENT } from '../../analytics/analyticsConsts';
import { confirmPhone } from '../../api/findAccount/confirmPhone.api';
import { selectHasPIN, selectPhone } from '../../app/accountOnboardingSelectors';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectLanguage } from '../../app/languageSelectors';
import { selectMerchantData } from '../../app/merchantDataSelectors';
import { SCREEN, setScreen } from '../../app/screenStateSlice';
import { FORM_INPUT_WIDTH, PHONE_NUMBER_MAX_LENGTH, imprintSupportPhone, } from '../../assets/consts/const';
import { HEADER_SERVICING_HEIGHT } from '../../components/HeaderServicing/HeaderServicing';
import Terms from '../../components/terms/Terms';
import { ANALYTICS } from '../../firebase/firebase';
import { formatPhoneNumber } from '../../utils/formatUtils';
import { PRODUCTUUID } from '../../utils/productUUIDConstants';
import { ValidationResponse, getShowError, getValidationString, handleValueChange, validatePhoneNumber, } from '../../utils/validateUtils';
var getScreenContent = function (isConversion, hasPhone, language) {
    return !isConversion
        ? {
            title: language.findAccountConfirmPhoneTitle,
            subtitle: language.findAccountConfirmPhoneSubtitle,
            smsConsentTop: undefined,
            smsConsentBottom: language.phoneNumberConfirmInstruction,
            buttonText: language.confirmBtn,
        }
        : {
            title: !hasPhone ? language.enterYourPhoneNumber : language.confirmYourPhoneNumber,
            subtitle: language.convertedConfirmPhoneSubtitle,
            smsConsentTop: language.phoneNumberInstruction,
            smsConsentBottom: undefined,
            buttonText: language.continueBtn,
        };
};
export function ConfirmPhonePage() {
    var dispatch = useAppDispatch();
    var language = useAppSelector(selectLanguage);
    var merchantData = useAppSelector(selectMerchantData);
    var originalPhone = useAppSelector(selectPhone);
    var hasPIN = useAppSelector(selectHasPIN);
    var _a = useState(formatPhoneNumber(originalPhone)), mobilePhone = _a[0], setMobilePhone = _a[1];
    var _b = useState(ValidationResponse.empty), mobilePhoneError = _b[0], setMobilePhoneError = _b[1];
    var _c = useState(false), isSubmitting = _c[0], setIsSubmitting = _c[1];
    var isConversion = merchantData.productUUID === PRODUCTUUID.BB && !hasPIN;
    useEffect(function () {
        ANALYTICS.logEvent(EVENT.ACCOUNT_FOUND_PHONE_VIEWED, {
            product_uuid: merchantData.productUUID,
            context: originalPhone ? 'confirm_phone' : 'enter_phone',
        });
    }, []);
    useEffect(function () {
        handleValueChange(validatePhoneNumber(mobilePhone), setMobilePhoneError);
        if (mobilePhone.length === PHONE_NUMBER_MAX_LENGTH) {
            handleFilledOut();
        }
    }, [mobilePhone]);
    useEffect(function () {
        if (mobilePhoneError === ValidationResponse.invalidPhoneNumber) {
            ANALYTICS.logEvent(EVENT.ACCOUNT_FOUND_PHONE_INVALID_VIEWED, {
                product_uuid: merchantData.productUUID,
            });
            return;
        }
    }, [mobilePhoneError]);
    var handleBackClick = function () {
        dispatch(setScreen(SCREEN.FIND_ACCOUNT));
    };
    var handleFilledOut = function () {
        setMobilePhoneError(validatePhoneNumber(mobilePhone));
        ANALYTICS.logEvent(originalPhone ? EVENT.ACCOUNT_FOUND_PHONE_EDITED : EVENT.ACCOUNT_FOUND_PHONE_FILLED_OUT, {
            product_uuid: merchantData.productUUID,
        });
    };
    var handleContinueClick = function () {
        ANALYTICS.logEvent(EVENT.ACCOUNT_FOUND_PHONE_CONTINUE_CLICKED, {
            product_uuid: merchantData.productUUID,
            context: originalPhone ? 'confirm_phone' : 'enter_phone',
        });
        setIsSubmitting(true);
        confirmPhone({
            phone: mobilePhone.replace(/\D/g, ''),
            sendVoice: false,
            onFail: function () {
                setIsSubmitting(false);
                setMobilePhoneError(ValidationResponse.invalidPhoneNumberOccupied);
                ANALYTICS.logEvent(EVENT.ACCOUNT_FOUND_PHONE_ERROR_VIEWED, {
                    product_uuid: merchantData.productUUID,
                });
            },
            onSuccess: function () {
                setIsSubmitting(false);
            },
        });
    };
    var handleCallSupport = function () {
        window.location.href = "tel:+1".concat(imprintSupportPhone);
    };
    var getContinueButtonState = function () {
        return isSubmitting
            ? 'submitting'
            : mobilePhoneError === ValidationResponse.valid
                ? 'enabled'
                : 'disabled';
    };
    var content = getScreenContent(isConversion, originalPhone, language);
    return (_jsxs(ImpActionPage, __assign({ footer: _jsxs(_Fragment, { children: [_jsxs(ImpContainer, __assign({ textAlign: "left" }, { children: [isConversion && (_jsx(ImpPad, __assign({ padding: "0 24px", mobilePadding: "0" }, { children: _jsx(Terms, { isConversion: true }) }))), content.smsConsentBottom && (_jsx(ImpText, __assign({ typography: 'body3', color: colors.content.onBackgroundVariant }, { children: content.smsConsentBottom })))] })), _jsx(ImpSpacer, { height: "l" }), _jsxs(ImpButtonDock, __assign({ alwaysColumn: true }, { children: [_jsx(ImpButton, __assign({ buttonStyle: isConversion ? 'branded' : 'imprint', state: getContinueButtonState(), onClick: handleContinueClick }, { children: content.buttonText })), mobilePhoneError === ValidationResponse.invalidPhoneNumberOccupied && (_jsxs(ImpButton, __assign({ variant: "secondary", buttonStyle: isConversion ? 'branded' : 'imprint', onClick: handleCallSupport }, { children: [language.contact, " ", formatPhoneNumber(imprintSupportPhone)] })))] }))] }), onBackClick: handleBackClick, offset: HEADER_SERVICING_HEIGHT }, { children: [_jsx(ImpSpacer, { height: "2xl" }), _jsx(ImpText, __assign({ typography: "headline2" }, { children: content.title })), _jsx(ImpSpacer, { height: "xs" }), _jsx(ImpText, __assign({ typography: "body2", color: colors.content.onSurfaceVariant }, { children: content.subtitle })), _jsx(ImpSpacer, { height: "l" }), _jsx(ImpInput, { label: language.phoneNumber, value: mobilePhone, maxLength: 16, onChange: function (e) {
                    var phoneNumber = formatPhoneNumber(e.target.value);
                    setMobilePhone(phoneNumber);
                }, onBlur: function () { return setMobilePhoneError(validatePhoneNumber(mobilePhone)); }, showError: getShowError(mobilePhoneError), errorMessage: getValidationString(mobilePhoneError, language), isDisabled: isSubmitting, numeric: true, type: "tel" }), _jsx(ImpSpacer, { height: "xs" }), content.smsConsentTop && !getShowError(mobilePhoneError) && (_jsx(ImpContainer, __assign({ textAlign: "left", width: FORM_INPUT_WIDTH }, { children: _jsx(ImpFlex, __assign({ direction: "column", align: "left" }, { children: _jsx(ImpPad, __assign({ paddingLeft: "m", paddingRight: "m" }, { children: _jsx(ImpText, __assign({ typography: "body3", color: colors.content.onSurfaceVariant }, { children: content.smsConsentTop })) })) })) }))), _jsx(ImpSpacer, { height: "2xl" })] })));
}
