import { differenceInCalendarDays, isBefore, startOfDay } from 'date-fns';
var conversionDate = new Date('2024-07-15T00:00:00-04:00');
function convertedHomeWelcomeShownKey() {
    return isPostConversion()
        ? 'imprint:bbPostConversionWelcomeShown'
        : 'imprint:bbPreConversionWelcomeShown';
}
export var getDaysToConversion = function (language) {
    var now = new Date();
    var today = startOfDay(now);
    // differenceInCalendarDays round down partial day, need +1
    var days = differenceInCalendarDays(conversionDate, today) + 1;
    return "".concat(days).concat(days !== 1 ? language.days : language.daySingular);
};
export var isPostConversion = function () {
    var now = new Date();
    return !isBefore(now, conversionDate);
};
export var needShowConvertedHomeWelcome = function () {
    return localStorage.getItem(convertedHomeWelcomeShownKey()) !== 'true';
};
export var setConvertedHomeWelcomeShown = function () {
    return localStorage.setItem(convertedHomeWelcomeShownKey(), 'true');
};
