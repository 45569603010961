var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { BreakpointsPx, colors, ImpCard, ImpContainer, ImpImage, ImpSpacer, ImpText, } from '@imprint-payments/imprint-ui';
import { useEffect, useMemo, useState } from 'react';
import { useAppSelector } from '../../app/hooks';
import { selectLanguage } from '../../app/languageSelectors';
import loginImage from '../../assets/images/login-image.png';
import styles from './LoginPage.module.css';
export function LoginText() {
    var language = useAppSelector(selectLanguage);
    return (_jsxs(_Fragment, { children: [_jsx(ImpText, __assign({ typography: "body1", color: colors.content.onSurfaceVariant }, { children: language.welcomeToImprint })), _jsx(ImpSpacer, { height: "s" }), _jsx("div", __assign({ className: styles.adjustableText }, { children: _jsx(ImpText, __assign({ typography: 'displaySerif3', color: colors.content.onSurface }, { children: language.logInToManageCard })) }))] }));
}
export function LoginImage(_a) {
    var isBranded = _a.isBranded;
    var merchantData = useAppSelector(function (state) { return state.merchantDataSelector; });
    return isBranded ? (_jsx("div", __assign({ className: styles.cardImg }, { children: _jsx(ImpCard, { src: merchantData.cardImg, width: "100%" }) }))) : (_jsx(ImpContainer, __assign({ width: "66%" }, { children: _jsx(ImpImage, { src: loginImage, alt: 'Imprint', width: "100%" }) })));
}
var TABLET_BREAKPOINT = 793;
export var useScreenWidth = function () {
    var _a = useState(window.innerWidth), width = _a[0], setWidth = _a[1];
    var screenSizeStatus = useMemo(function () {
        if (width < BreakpointsPx.Mobile) {
            return 'mobile';
        }
        else if (width < TABLET_BREAKPOINT) {
            return 'tablet';
        }
        else {
            return 'desktop';
        }
    }, [width]);
    useEffect(function () {
        var handleResize = function () { return setWidth(window.innerWidth); };
        window.addEventListener('resize', handleResize);
        return function () { return window.removeEventListener('resize', handleResize); };
    }, []);
    return screenSizeStatus;
};
