import { getEmptyAddress } from './Address';
import { CardStatus } from './CardStatus';
export var getEmptyAuthorizedUser = function () {
    return {
        authorizedUserUUID: '',
        authorizedUserAccountUUID: '',
        firstName: '',
        lastName: '',
        address: getEmptyAddress(),
        addressDaysUntilEditable: 0,
        cardUUID: '',
        last4: '',
        cardStatus: CardStatus.INACTIVE,
    };
};
