var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { colors, ImpContainer, ImpFlex, ImpSpacer, ImpText } from '@imprint-payments/imprint-ui';
import { EVENT } from '../../analytics/analyticsConsts';
import { useAppSelector } from '../../app/hooks';
import { ScreenStateContext } from '../../app/screenStateSlice';
import { TEXT_WIDTH } from '../../assets/consts/const';
import { ANALYTICS } from '../../firebase/firebase';
export function LoginFailed(_a) {
    var failedOtp = _a.failedOtp;
    var language = useAppSelector(function (state) { return state.languageSelector.languageLocalization; });
    var screenState = useAppSelector(function (state) { return state.screenStateSelector; });
    var homeState = useAppSelector(function (state) { return state.homeSelector; });
    var featureFlags = useAppSelector(function (state) { return state.featureFlagSelector.featureFlags; });
    var isPinLockOutEnabled = featureFlags.pinLockOutEnabled === 'true';
    var body = language.maxRetriesBody;
    var header = language.loginOtpFailedHeader;
    if (isPinLockOutEnabled && homeState.homeFlow.pinLockOutMessage) {
        body = homeState.homeFlow.pinLockOutMessage;
    }
    if (screenState.screenContext == ScreenStateContext.FailedCardDetailsPIN) {
        ANALYTICS.logEvent(EVENT.CARD_DETAILS_PIN_FAILED_VIEWED);
        header = language.couldntVerifyPin;
        body = language.accountHasBeenLocked;
    }
    else if (failedOtp) {
        body = language.loginOtpFailedBody;
    }
    return (_jsx(ImpContainer, __assign({ textAlign: "center", width: TEXT_WIDTH }, { children: _jsxs(ImpFlex, __assign({ direction: "column", align: "center", fluid: true }, { children: [_jsx(ImpSpacer, { height: "2xl" }), _jsx(ImpText, __assign({ typography: "headline2" }, { children: header })), _jsx(ImpSpacer, { height: "m" }), _jsx(ImpText, __assign({ typography: "body2", color: colors.content.onSurfaceVariant }, { children: body }))] })) })));
}
