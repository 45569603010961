import { ACCOUNT_STATUS, ProductType } from './homeSlice';
export var selectHome = function (state) { return state.homeSelector; };
export var selectPrimaryPaymentAccount = function (state) {
    return state.homeSelector.primaryPaymentAccount;
};
export var selectSelectedProductUUID = function (state) { var _a; return (_a = state.homeSelector.selectedProductAccount) === null || _a === void 0 ? void 0 : _a.productUUID; };
export var selectSelectedProductAccountUUID = function (state) { var _a; return (_a = state.homeSelector.selectedProductAccount) === null || _a === void 0 ? void 0 : _a.productAccountUUID; };
export var selectSelectedMerchantName = function (state) { var _a; return (_a = state.homeSelector.selectedProductAccount) === null || _a === void 0 ? void 0 : _a.merchantName; };
export var selectActiveCreditCards = function (state) {
    return state.homeSelector.productAccounts.filter(function (productAccount) {
        return productAccount.productType !== ProductType.SpendCard &&
            productAccount.productAccountStatus === ACCOUNT_STATUS.ACTIVE;
    });
};
export var selectProductAccounts = function (state) { return state.homeSelector.productAccounts; };
export var selectSelectedProductAccount = function (state) {
    return state.homeSelector.selectedProductAccount;
};
export var selectExpiresAt = function (state) {
    return state.homeSelector.application.decision.expiresAt;
};
export var selectUserInformation = function (state) { return state.homeSelector.userInformation; };
export var selectHomeFlow = function (state) { return state.homeSelector.homeFlow; };
export var selectConsumerDocumentType = function (state) { return state.homeSelector.consumerDocumentType; };
