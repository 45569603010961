var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Icons, ImpBackdrop, ImpButton, ImpContainer, ImpIcon, ImpList, ImpSpacer, ImpSurface, ImpText, ImpTooltip, useModal, } from '@imprint-payments/imprint-ui';
import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { EVENT } from '../../analytics/analyticsConsts';
import { selectAccountLink } from '../../app/accountLinkSelector';
import { selectUserInformation } from '../../app/homeSelectors';
import { setUserInformation } from '../../app/homeSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectLanguage } from '../../app/languageSelectors';
import { SCREEN, setScreen } from '../../app/screenStateSlice';
import { useScreenWidth } from '../../components/loginPage/loginPageUtils';
import { ANALYTICS } from '../../firebase/firebase';
import { PAGE } from '../../utils/routeConstant';
export function ConnectBankButton(_a) {
    var displayToolTip = _a.displayToolTip;
    var language = useAppSelector(selectLanguage);
    var dispatch = useAppDispatch();
    var navigate = useNavigate();
    var userInfo = useAppSelector(selectUserInformation);
    var firstTimeUser = userInfo.firstTimeUser;
    var accountLinkInfo = useAppSelector(selectAccountLink);
    var screenStatus = useScreenWidth();
    var _b = useModal(false), isOpen = _b[0], isClosing = _b[1], openBackdrop = _b[2], closeBackdrop = _b[3];
    var overlayOff = function () {
        closeBackdrop();
        dispatch(setUserInformation(__assign(__assign({}, userInfo), { firstTimeUser: false })));
    };
    useEffect(function () {
        if (firstTimeUser && !accountLinkInfo.showAccountLinkModal && displayToolTip) {
            openBackdrop();
            ANALYTICS.logEvent(EVENT.CONNECT_A_BANK_ACCOUNT_TOOLTIP_VIEWED);
        }
        if (accountLinkInfo.showAccountLinkModal || !displayToolTip) {
            closeBackdrop();
        }
    }, [firstTimeUser, accountLinkInfo.showAccountLinkModal]);
    var handleConnectBank = function () {
        ANALYTICS.logEvent(EVENT.HOME_CONNECT_BANK_ACCOUNT_CLICKED);
        overlayOff();
        dispatch(setScreen(SCREEN.BANKING));
        navigate(PAGE.BANKING);
    };
    var ConnectButton = function () { return (_jsx(ImpButton, __assign({ variant: "secondary", size: "hug", buttonStyle: "branded", onClick: handleConnectBank }, { children: language.connectBankButtonText }))); };
    var mobileProps = {
        onClick: handleConnectBank,
        trailingContent: _jsx(ImpIcon, { iconSrc: Icons.chevronRight }),
    };
    var nonMobileProps = useMemo(function () {
        return {
            onClick: undefined,
            trailingContent: (_jsx(WithTooltip, __assign({ isOpen: isOpen && displayToolTip, isClosing: isClosing, crossAxisOffset: -60 }, { children: _jsx(ConnectButton, {}) }))),
        };
    }, [isOpen, isClosing]);
    var props = screenStatus === 'mobile' ? mobileProps : nonMobileProps;
    return (_jsxs(_Fragment, { children: [_jsx(ImpBackdrop, { onClick: overlayOff, isOpen: isOpen, isClosing: isClosing }), _jsx(ImpContainer, __assign({ width: "100%" }, { children: _jsx(ImpSurface, __assign({ hasBorderRadius: true }, { children: _jsx(ImpContainer, __assign({ width: "100%" }, { children: _jsx(ConnectRow, { props: props, tooltipProps: {
                                isOpen: isOpen,
                                isClosing: isClosing,
                            } }) })) })) }))] }));
}
function WithTooltip(_a) {
    var children = _a.children, isOpen = _a.isOpen, isClosing = _a.isClosing, crossAxisOffset = _a.crossAxisOffset;
    var language = useAppSelector(selectLanguage);
    return (_jsx(ImpTooltip, __assign({ position: "top", crossAxisOffset: crossAxisOffset, width: "290px", contentWidth: "inherit", isVisible: isOpen && !isClosing, content: _jsxs(ImpContainer, { children: [_jsx(ImpText, __assign({ typography: "headline3", color: "white" }, { children: language.connectABankAccount })), _jsx(ImpSpacer, { height: "xs" }), _jsx(ImpText, __assign({ typography: "body2", color: "white" }, { children: language.connectBankEDU }))] }), arrow: true, displayOnContentHover: false, mainAxisOffset: 5 }, { children: _jsx(ImpContainer, __assign({ width: "100%", zIndex: isOpen || isClosing ? 15 : 1 }, { children: children })) })));
}
function ConnectRow(_a) {
    var props = _a.props, tooltipProps = _a.tooltipProps;
    var language = useAppSelector(selectLanguage);
    return (_jsx(TooltipWrapperWhenMobile, __assign({ isOpen: tooltipProps.isOpen, isClosing: tooltipProps.isClosing }, { children: _jsx(ImpList, { items: [
                __assign({ title: language.connectBankButtonTitle, subtitle: language.connectBankButtonSubtitle }, props),
            ] }) })));
}
function TooltipWrapperWhenMobile(_a) {
    var children = _a.children, isOpen = _a.isOpen, isClosing = _a.isClosing;
    var screenStatus = useScreenWidth();
    return screenStatus === 'mobile' ? (_jsx(WithTooltip, __assign({ isOpen: isOpen, isClosing: isClosing, crossAxisOffset: 0 }, { children: children }))) : (_jsx(_Fragment, { children: children }));
}
