var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { ImpContainer, ImpList, ImpSpacer } from '@imprint-payments/imprint-ui';
import { useEffect, useMemo, useState } from 'react';
import { EVENT } from '../../analytics/analyticsConsts';
import { loadProductAccount } from '../../api/loadProductAccount/loadProductAccount';
import { activatePhysicalCard } from '../../api/physicalCardActions/activatePhysicalCard.api';
import { deactivatePhysicalCard } from '../../api/physicalCardActions/deactivatePhysicalCard.api';
import { updateAuthUser } from '../../api/updateAuthUser/updateAuthUser';
import { selectSelectedAuthorizedUser } from '../../app/authUserSelectors';
import { AuthUsersPage, resetAuthUsers, setAuthUsersPage, setSelectedAuthorizedUser, } from '../../app/authUsersSlice';
import { selectDisplayAuthUserCardDelayTHY } from '../../app/featureFlagSelectors';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { FORM_INPUT_WIDTH } from '../../assets/consts/const';
import { ANALYTICS } from '../../firebase/firebase';
import { displayTemporarySnackbar } from '../../utils/functionUtils';
import AddAddressPage from './AddAddressPage';
import { AuthActivateCard } from './AuthActivateCard';
import { AuthDeactivateUser } from './AuthDeactivateUser';
import { AuthReplaceCard } from './AuthReplaceCard';
import { AuthUserDeactivated } from './AuthUserDeactivated';
import { generateUserOptions } from './authUsersHelpers';
import { CardNotAvailableNote } from './CardNotAvailableNote';
import VerifyPinPage from './VerifyPinPage';
export function AuthorizedUserInfo() {
    var _a = useState(false), isLoadingLockPhysicalCard = _a[0], setIsLoadingLockPhysicalCard = _a[1];
    var authUsersState = useAppSelector(function (state) { return state.authUsersSelector; });
    var _b = useState(false), isAddressSubmitting = _b[0], setIsAddressSubmitting = _b[1];
    var language = useAppSelector(function (state) { return state.languageSelector.languageLocalization; });
    var dispatch = useAppDispatch();
    var homeSelector = useAppSelector(function (state) { return state.homeSelector; });
    var selectedAuthorizedUser = useAppSelector(selectSelectedAuthorizedUser);
    var displayAuthUserCardDelayTHY = useAppSelector(selectDisplayAuthUserCardDelayTHY);
    useEffect(function () {
        var _a;
        loadProductAccount({
            productAccountUUID: ((_a = homeSelector.selectedProductAccount) === null || _a === void 0 ? void 0 : _a.productAccountUUID) || '',
        });
        return function () {
            dispatch(resetAuthUsers());
        };
    }, []);
    var handleLockPhysicalCardClick = function () {
        setIsLoadingLockPhysicalCard(true);
        if (selectedAuthorizedUser.cardStatus === 'ACTIVE') {
            var cardUUID = selectedAuthorizedUser.cardUUID;
            deactivatePhysicalCard({
                cardUUID: cardUUID,
                onSuccess: function () {
                    ANALYTICS.logEvent(EVENT.AUTH_USER_CARD_LOCKED);
                    setIsLoadingLockPhysicalCard(false);
                    displayTemporarySnackbar(language.physicalCardLocked, dispatch);
                    loadProductAccount({
                        productAccountUUID: homeSelector.selectedProductAccount.productAccountUUID,
                    });
                },
                onError: function () {
                    setIsLoadingLockPhysicalCard(false);
                },
            });
        }
        else {
            activatePhysicalCard({
                cardUUID: selectedAuthorizedUser.cardUUID,
                last4: String(selectedAuthorizedUser.last4),
                onSuccess: function () {
                    ANALYTICS.logEvent(EVENT.AUTH_USER_CARD_UNLOCKED);
                    displayTemporarySnackbar(language.physicalCardUnlocked, dispatch);
                    loadProductAccount({
                        productAccountUUID: homeSelector.selectedProductAccount.productAccountUUID,
                    });
                },
                onError: function () {
                    displayTemporarySnackbar(language.somethingWentWrong, dispatch);
                },
            });
            setIsLoadingLockPhysicalCard(false);
        }
    };
    var handleActivatePhysicalCardClick = function () {
        ANALYTICS.logEvent(EVENT.AUTH_USER_ACTIVATE_CARD_STARTED);
        dispatch(setAuthUsersPage(AuthUsersPage.ACTIVATE_PHYSICAL_CARD));
    };
    var userOptions = useMemo(function () {
        return generateUserOptions(dispatch, authUsersState, language, handleActivatePhysicalCardClick, handleLockPhysicalCardClick);
    }, [authUsersState]);
    if (authUsersState.page === AuthUsersPage.VERIFY_PIN_ADDRESS) {
        return (_jsx(VerifyPinPage, { handleSuccess: function () {
                dispatch(setAuthUsersPage(AuthUsersPage.UPDATE_ADDRESS));
            }, handleGoBack: function () {
                dispatch(setAuthUsersPage(AuthUsersPage.DEFAULT));
            } }));
    }
    var handleAddressContinueClick = function (address) {
        setIsAddressSubmitting(true);
        var authorizedUserUUID = selectedAuthorizedUser.authorizedUserUUID;
        updateAuthUser({
            authorizedUserUUID: authorizedUserUUID,
            address: address,
            onSuccess: function () {
                ANALYTICS.logEvent(EVENT.AUTH_USER_ADDRESS_UPDATED);
                setIsAddressSubmitting(false);
                dispatch(setAuthUsersPage(AuthUsersPage.DEFAULT));
                displayTemporarySnackbar(language.addressUpdated, dispatch);
                loadProductAccount({
                    productAccountUUID: homeSelector.selectedProductAccount.productAccountUUID,
                });
                dispatch(setSelectedAuthorizedUser(__assign(__assign({}, selectedAuthorizedUser), { address: address })));
            },
        });
    };
    switch (authUsersState.page) {
        case AuthUsersPage.ACTIVATE_PHYSICAL_CARD:
            return _jsx(AuthActivateCard, {});
        case AuthUsersPage.REPLACE_CARD:
            return _jsx(AuthReplaceCard, {});
        case AuthUsersPage.DEACTIVATE_USER:
            return _jsx(AuthDeactivateUser, {});
        case AuthUsersPage.USER_DEACTIVATED:
            return _jsx(AuthUserDeactivated, {});
        case AuthUsersPage.UPDATE_ADDRESS:
            return (_jsx(ImpContainer, __assign({ width: FORM_INPUT_WIDTH }, { children: _jsx(AddAddressPage, { continueButtonStyle: "branded", title: language.authUpdateAddressTitle, isSubmitting: isAddressSubmitting, handleContinueClick: handleAddressContinueClick }) })));
        default:
            return (_jsxs(ImpContainer, __assign({ width: '100%' }, { children: [!isCardAvailable(selectedAuthorizedUser === null || selectedAuthorizedUser === void 0 ? void 0 : selectedAuthorizedUser.cardUUID) && displayAuthUserCardDelayTHY && (_jsxs(_Fragment, { children: [_jsx(CardNotAvailableNote, {}), _jsx(ImpSpacer, { height: "16px" })] })), _jsx(ImpList, { useSurface: true, items: userOptions })] })));
    }
}
function isCardAvailable(cardUUID) {
    return cardUUID !== '';
}
