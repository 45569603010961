var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Icons } from '@imprint-payments/imprint-ui';
import { RewardRedemptionType } from '../../types/RewardRedemptionType';
import { compareDisplayAmounts, createDisplayAmount, formatDisplayAmount, } from '@imprint-payments/imprint-lib';
import { store } from '../../app/store';
import { useEffect, useState } from 'react';
import { setRedeemVal, setRedemptionType, } from '../../app/rewardsRedemptionSlice';
import { PaymentAccountStatus } from '../dashboard/Dashboard';
import { displayTemporarySnackbar } from '../../utils/functionUtils';
export var getRewardIconSrc = function (type) {
    switch (type) {
        case RewardRedemptionType.BankWithdrawal:
            return Icons.bank;
        case RewardRedemptionType.MailCheck:
            return Icons.mail;
        case RewardRedemptionType.StatementCredit:
        default:
            return Icons.lightning;
    }
};
export var getRewardTitle = function (type, language) {
    switch (type) {
        case RewardRedemptionType.BankWithdrawal:
            return language.bankWithdrawal;
        case RewardRedemptionType.MailCheck:
            return language.mailCheck;
        case RewardRedemptionType.StatementCredit:
            return language.statementCredit;
        default:
            return '';
    }
};
export var getRewardSubtitle = function (option, displayCardBalance, language) {
    var _a;
    var toReturn = "{min}".concat(language.min, " \u2022 {delay}").concat(language.days);
    if (!option)
        return '';
    if ((option === null || option === void 0 ? void 0 : option.redemptionType) === RewardRedemptionType.StatementCredit &&
        displayCardBalance) {
        var cardBalance = (_a = store.getState().productAccountSelector) === null || _a === void 0 ? void 0 : _a.cardBalanceV2.totalSpend;
        if (cardBalance) {
            return "".concat(language.cardBalanceLabel).concat(formatDisplayAmount(cardBalance));
        }
    }
    if ((option === null || option === void 0 ? void 0 : option.minimumAmount) && (option === null || option === void 0 ? void 0 : option.daysDelay)) {
        toReturn = toReturn.replace('{min}', formatDisplayAmount(option === null || option === void 0 ? void 0 : option.minimumAmount));
        toReturn = toReturn.replace('{delay}', option === null || option === void 0 ? void 0 : option.daysDelay);
        return toReturn;
    }
    else {
        return "".concat(language.noMin, " \u2022 ").concat(language.instant);
    }
};
export var isOptionValid = function (minAmount, maxAmount, redeemVal) {
    var cleanedMinAmount = parseFloat(formatDisplayAmount(minAmount).replace(/[$,]/g, ''));
    var cleanedMaxAmount = parseFloat(formatDisplayAmount(maxAmount).replace(/[$,]/g, ''));
    var cleanedRedeemVal = parseFloat(redeemVal.replace(/[$,]/g, ''));
    return (cleanedMinAmount <= cleanedRedeemVal &&
        cleanedMaxAmount >= cleanedRedeemVal &&
        cleanedRedeemVal > 0);
};
export var useRedemptionSelection = function (initialConditions, dispatch, redemptionOptions, merchantData, productAccount, rewardsRedemptionState) {
    var _a = useState(initialConditions), conditions = _a[0], setConditions = _a[1];
    var _b = useState(rewardsRedemptionState.redemptionType || RewardRedemptionType.Empty), selectedIndex = _b[0], setSelectedIndex = _b[1];
    var handleRedemptionSelection = function (type) {
        setSelectedIndex(type);
        dispatch(setRedemptionType(type));
    };
    var updateConditions = function () {
        var updatedConditions = __assign({}, conditions);
        var wasPreviouslySelected = false;
        redemptionOptions === null || redemptionOptions === void 0 ? void 0 : redemptionOptions.forEach(function (option) {
            var type = option.redemptionType, minimumAmount = option.minimumAmount;
            if (!type)
                return;
            var minAmount = minimumAmount || createDisplayAmount({ valueInMinUnit: 0 });
            var maxAmount = getMaxAmount(type, productAccount);
            var isValid = isOptionValid(minAmount, maxAmount, rewardsRedemptionState.redeemVal);
            updatedConditions[type] = getConditionState(isValid, type, selectedIndex);
            if (updatedConditions[type] === 'disabled' && type === selectedIndex) {
                wasPreviouslySelected = true;
            }
        });
        if (wasPreviouslySelected)
            handleRedemptionSelection(RewardRedemptionType.Empty);
        setConditions(updatedConditions);
    };
    var minDisplayAmount = function (a, b) {
        var comparison = compareDisplayAmounts(a, b);
        return comparison === -1 ? a : b;
    };
    var getMaxAmount = function (type, productAccount) {
        return type === RewardRedemptionType.StatementCredit
            ? minDisplayAmount(productAccount.cardBalanceV2.totalSpend, productAccount.rewards.rewardBalances.balances.available.amount)
            : productAccount.rewards.rewardBalances.balances.available.amount;
    };
    var getConditionState = function (isValid, type, selectedIndex) {
        return isValid
            ? type === selectedIndex
                ? 'selected'
                : 'enabled'
            : 'disabled';
    };
    useEffect(updateConditions, [
        rewardsRedemptionState.redeemVal,
        redemptionOptions,
        selectedIndex,
    ]);
    return [conditions, handleRedemptionSelection];
};
export var getActionButtonState = function (paymentAccount, productAccount, rewardsRedemptionState) {
    // handle action for bank withdrawal
    if (paymentAccount.status !== PaymentAccountStatus.Approved &&
        rewardsRedemptionState.redemptionType ===
            RewardRedemptionType.BankWithdrawal) {
        return 'enabled';
    }
    var invalidStates = [
        // no redemption method selected
        rewardsRedemptionState.redemptionType === RewardRedemptionType.Empty,
        // amount === 0
        parseFloat(rewardsRedemptionState.redeemVal.replace(/[$,]/g, '')) === 0,
        // amount > available balance
        parseFloat(rewardsRedemptionState.redeemVal.replace(/[$,]/g, '')) >
            parseFloat(formatDisplayAmount(productAccount.rewards.rewardBalances.balances.available.amount).replace(/[$,]/g, '')),
    ];
    if (invalidStates.some(function (check) { return check; })) {
        return 'disabled';
    }
    return 'enabled';
};
export var getActionButtonText = function (paymentAccount, rewardsRedemptionState, language) {
    if (paymentAccount.status !== PaymentAccountStatus.Approved &&
        rewardsRedemptionState.redemptionType ===
            RewardRedemptionType.BankWithdrawal) {
        return language.linkBankLabel;
    }
    return language.continueBtn;
};
export var getDisabledClick = function (option, dispatch, rewardsRedemptionState, productAccount, language) {
    if (!option)
        return;
    var type = option.redemptionType;
    var min = formatDisplayAmount(option.minimumAmount || createDisplayAmount({ valueInMinUnit: 0 }));
    var parsedCardBalance = parseFloat(formatDisplayAmount(productAccount.cardBalanceV2.totalSpend).replace(/[$,]/g, ''));
    var parsedRedemptionVal = parseFloat(rewardsRedemptionState.redeemVal.replace(/[$,]/g, ''));
    var parsedAvailableBalance = parseFloat(formatDisplayAmount(productAccount.rewards.rewardBalances.balances.available.amount ||
        createDisplayAmount({ valueInMinUnit: 0 })).replace(/[$,]/g, ''));
    var toShow = language.redeemMinRewards.replace('{min}', min);
    if (type === RewardRedemptionType.StatementCredit) {
        if (parsedRedemptionVal === 0) {
            return function () {
                displayTemporarySnackbar(language.noRedemptionValPopup, dispatch);
            };
        }
        else if (parsedCardBalance <= 0) {
            return function () {
                displayTemporarySnackbar(language.noCardBalance, dispatch);
            };
        }
        else if (parsedRedemptionVal > parsedCardBalance) {
            return function () {
                displayTemporarySnackbar(language.redeemUpToBalance.replace('{bal}', formatDisplayAmount(productAccount.cardBalanceV2.totalSpend)), dispatch);
            };
        }
    }
    else if (parsedRedemptionVal > parsedAvailableBalance) {
        return function () {
            var _a;
            displayTemporarySnackbar(language.redeemMaxRewards.replace('{max}', formatDisplayAmount((_a = store.getState()) === null || _a === void 0 ? void 0 : _a.productAccountSelector.rewards.rewardBalances.balances.available.amount)), dispatch);
        };
    }
    else if (type === RewardRedemptionType.BankWithdrawal) {
        return function () {
            displayTemporarySnackbar(toShow.replace('{action}', language.withdrawToBank), dispatch);
        };
    }
    else if (type === RewardRedemptionType.MailCheck) {
        return function () {
            return displayTemporarySnackbar(toShow.replace('{action}', language.mailACheck), dispatch);
        };
    }
    return function () { return displayTemporarySnackbar(language.somethingWentWrong, dispatch); };
};
export var getOptions = function (redemptionOptions, conditions, dispatch, rewardsRedemptionState, handleRedemptionSelection, redeemVal, productAccount, language) {
    return ((redemptionOptions === null || redemptionOptions === void 0 ? void 0 : redemptionOptions.map(function (option) {
        var type = option.redemptionType;
        var condition = type ? conditions[type] : 'disabled';
        var totalSpend = productAccount.cardBalanceV2.totalSpend;
        var entered = createDisplayAmount({
            displayValue: redeemVal.replace(/[$,]/g, ''),
        });
        var displayCardBalance = compareDisplayAmounts(totalSpend, entered) === -1;
        return {
            iconSrc: getRewardIconSrc(type),
            title: getRewardTitle(type, language),
            subtitle: getRewardSubtitle(option, displayCardBalance, language),
            state: condition,
            disabledOnClick: getDisabledClick(option, dispatch, rewardsRedemptionState, productAccount, language),
            onClick: function () { return handleRedemptionSelection(type); },
        };
    })) || []);
};
export var selectFirstAvailable = function (redemptionOptions, productAccount, handleRedemptionSelection, dispatch) {
    var formattedAvailableBalance = formatDisplayAmount(productAccount.rewards.rewardBalances.balances.available.amount);
    for (var _i = 0, _a = redemptionOptions || []; _i < _a.length; _i++) {
        var option = _a[_i];
        var type = option.redemptionType, minimumAmount = option.minimumAmount;
        if (!type)
            return;
        var minAmount = minimumAmount || createDisplayAmount({ valueInMinUnit: 0 });
        if (type !== RewardRedemptionType.StatementCredit &&
            compareDisplayAmounts(minAmount, productAccount.rewards.rewardBalances.balances.available.amount) !== 1) {
            handleRedemptionSelection(type);
            dispatch(setRedeemVal(formattedAvailableBalance));
            return;
        }
    }
};
export var initialSetup = function (productAccount, dispatch, handleRedemptionSelection, redemptionOptions) {
    var formattedAvailableBalance = formatDisplayAmount(productAccount.rewards.rewardBalances.balances.available.amount);
    var formattedCardBalance = formatDisplayAmount(productAccount.cardBalanceV2.totalSpend);
    var parsedCardBalance = parseFloat(formattedCardBalance.replace(/[$,]/g, ''));
    var parsedAvailableBalance = parseFloat(formattedAvailableBalance.replace(/[$,]/g, ''));
    var hasCardBalance = parsedCardBalance > 0;
    if (hasCardBalance) {
        // if they have a card balance, opt to pay that off with the minimum of
        //  card balance and available balance (can't pay off more than you have)
        if (parsedCardBalance > parsedAvailableBalance) {
            dispatch(setRedeemVal(formattedAvailableBalance));
        }
        else {
            dispatch(setRedeemVal(formattedCardBalance));
        }
        handleRedemptionSelection(RewardRedemptionType.StatementCredit);
        return;
    }
    else {
        dispatch(setRedeemVal(formattedAvailableBalance));
        selectFirstAvailable(redemptionOptions, productAccount, handleRedemptionSelection, dispatch);
    }
};
export var getCannotRedeem = function (cardBalance, availableBalance, redemptionOptions) {
    var parsedCardBalance = parseFloat(formatDisplayAmount(cardBalance).replace(/[$,]/g, ''));
    var canPayCardBalance = parsedCardBalance > 0;
    var otherRedemptionPossible = redemptionOptions === null || redemptionOptions === void 0 ? void 0 : redemptionOptions.some(function (option) {
        return option.redemptionType !== RewardRedemptionType.StatementCredit &&
            option.redemptionType !== RewardRedemptionType.Empty &&
            compareDisplayAmounts(option.minimumAmount || createDisplayAmount({ valueInMinUnit: 0 }), availableBalance) !== 1;
    });
    return !(canPayCardBalance || otherRedemptionPossible);
};
