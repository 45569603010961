var _a;
import { createSlice } from '@reduxjs/toolkit';
var lastActiveTimeKey = 'imprint:lastActiveTime';
export var initialState = {
    applicationUUID: '',
    token: null,
    requestID: '',
};
export var authSlice = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {
        setAuth: function (state, action) {
            localStorage.setItem(lastActiveTimeKey, Date.now().toString());
            state.applicationUUID = action.payload.applicationUUID;
            state.token = action.payload.token;
            state.requestID = action.payload.requestID;
        },
        verifyPinSuccessAuth: function (state, action) {
            localStorage.setItem(lastActiveTimeKey, Date.now().toString());
            state.applicationUUID = action.payload.applicationUUID;
            state.token = action.payload.token;
            state.requestID = action.payload.requestID;
        },
        setAuthRequestId: function (state, action) {
            state.requestID = action.payload;
        },
        setAuthApplicationUUID: function (state, action) {
            state.applicationUUID = action.payload;
        },
        setAuthToken: function (state, action) {
            localStorage.setItem(lastActiveTimeKey, Date.now().toString());
            state.token = action.payload;
        },
        clearAuth: function () { return initialState; },
    },
});
export var setAuth = (_a = authSlice.actions, _a.setAuth), verifyPinSuccessAuth = _a.verifyPinSuccessAuth, setAuthRequestId = _a.setAuthRequestId, setAuthToken = _a.setAuthToken, clearAuth = _a.clearAuth;
export default authSlice.reducer;
