var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a;
import { store } from '../../app/store';
import { PAYMENT_STATUS, ActivityType } from '../apiConstants';
import { httpRequest } from '../http/client';
import { API_CONFIG, RequestName } from '../apiConfig';
import { getHandler, handleApiError } from '../../types/Api';
import { ResponseCode } from '../http/httpConsts';
export var ACTIVITY_FEED_MAX = 20;
export var ACTIVITY_FEED_MAX_DOWNLOAD_CSV = 80;
var ACTIVITY_FEED_TYPES_AND_STATUSES = Array({
    type: ActivityType.auth,
    statuses: [PAYMENT_STATUS.pending],
}, {
    type: ActivityType.transaction,
    statuses: [PAYMENT_STATUS.confirmed],
}, {
    type: ActivityType.dispute,
    statuses: [
        PAYMENT_STATUS.confirmed,
        PAYMENT_STATUS.pending,
    ],
}, {
    type: ActivityType.payment,
    statuses: [
        PAYMENT_STATUS.scheduled,
        PAYMENT_STATUS.pending,
        PAYMENT_STATUS.confirmed,
        PAYMENT_STATUS.rejected,
        PAYMENT_STATUS.canceled,
        PAYMENT_STATUS.submitted,
    ],
}, {
    type: ActivityType.refund,
    statuses: [PAYMENT_STATUS.confirmed],
}, {
    type: ActivityType.reward,
    statuses: [PAYMENT_STATUS.pending, PAYMENT_STATUS.confirmed],
}, {
    type: ActivityType.rewardWithdrawalACH,
    statuses: [
        PAYMENT_STATUS.pending,
        PAYMENT_STATUS.confirmed,
        PAYMENT_STATUS.rejected,
        PAYMENT_STATUS.submitted,
    ],
}, {
    type: ActivityType.rewardWithdrawalCheck,
    statuses: [
        PAYMENT_STATUS.scheduled,
        PAYMENT_STATUS.pending,
        PAYMENT_STATUS.confirmed,
        PAYMENT_STATUS.rejected,
        PAYMENT_STATUS.canceled,
        PAYMENT_STATUS.submitted,
    ],
}, {
    type: ActivityType.fee,
    statuses: [PAYMENT_STATUS.confirmed],
}, {
    type: ActivityType.interest,
    statuses: [PAYMENT_STATUS.confirmed],
}, {
    type: ActivityType.credit,
    statuses: [PAYMENT_STATUS.confirmed],
}, {
    type: ActivityType.paymentCheck,
    statuses: [PAYMENT_STATUS.confirmed, PAYMENT_STATUS.rejected],
});
var ONE_TIME_REWARD_TYPES_AND_STATUSES = Array({
    type: ActivityType.oneTime,
    statuses: [PAYMENT_STATUS.confirmed],
}, {
    type: ActivityType.signup,
    statuses: [PAYMENT_STATUS.confirmed],
}, {
    type: ActivityType.offer,
    statuses: [PAYMENT_STATUS.confirmed],
});
var handleOk = function (store, response, params) {
    var res = response.data.body;
    params.onSuccess(res);
};
var handleGeneric = function (store, response, params) {
    var res = response.data.body;
    params.onError();
    handleApiError(RequestName.LOAD_ACTIVITY, res);
};
var handlers = (_a = {},
    _a[ResponseCode.OK] = handleOk,
    _a);
export var loadActivity = function (_a) {
    var productAccountUUID = _a.productAccountUUID, nextToken = _a.nextToken, filterOption = _a.filterOption, _b = _a.limit, limit = _b === void 0 ? ACTIVITY_FEED_MAX : _b, onError = _a.onError, onSuccess = _a.onSuccess;
    return __awaiter(void 0, void 0, void 0, function () {
        var body, req, apiResponse, handler;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    body = {
                        filters: {
                            productAccountUUIDs: [productAccountUUID],
                            transactionTypesAndStatuses: getTransactionTypesAndStatuses(filterOption),
                            accrualTypesAndStatuses: getAccrualTypesAndStatuses(filterOption),
                        },
                        pagination: {
                            limit: limit,
                            lastID: '',
                            nextToken: nextToken === '' ? null : nextToken,
                        },
                    };
                    req = {
                        config: API_CONFIG.LOAD_ACTIVITY,
                        body: body,
                    };
                    return [4 /*yield*/, httpRequest(req)];
                case 1:
                    apiResponse = _c.sent();
                    handler = getHandler(apiResponse.status, handlers, handleGeneric);
                    handler(store, apiResponse, { onError: onError, onSuccess: onSuccess });
                    return [2 /*return*/, apiResponse.data.body];
            }
        });
    });
};
var getTransactionTypesAndStatuses = function (filterOption) {
    switch (filterOption) {
        case 'Payments':
            return Array({
                type: ActivityType.payment,
                statuses: [
                    PAYMENT_STATUS.scheduled,
                    PAYMENT_STATUS.pending,
                    PAYMENT_STATUS.confirmed,
                    PAYMENT_STATUS.rejected,
                    PAYMENT_STATUS.canceled,
                    PAYMENT_STATUS.submitted,
                ],
            }, {
                type: ActivityType.paymentCheck,
                statuses: [PAYMENT_STATUS.confirmed, PAYMENT_STATUS.rejected],
            });
        case 'Purchases':
            return Array({
                type: ActivityType.transaction,
                statuses: [PAYMENT_STATUS.confirmed],
            }, {
                type: ActivityType.auth,
                statuses: [PAYMENT_STATUS.pending],
            });
        case 'Redemptions':
            return Array({
                type: ActivityType.reward,
                statuses: [PAYMENT_STATUS.pending, PAYMENT_STATUS.confirmed],
            }, {
                type: ActivityType.rewardWithdrawalACH,
                statuses: [
                    PAYMENT_STATUS.pending,
                    PAYMENT_STATUS.confirmed,
                    PAYMENT_STATUS.rejected,
                    PAYMENT_STATUS.submitted,
                ],
            }, {
                type: ActivityType.rewardWithdrawalCheck,
                statuses: [
                    PAYMENT_STATUS.scheduled,
                    PAYMENT_STATUS.pending,
                    PAYMENT_STATUS.confirmed,
                    PAYMENT_STATUS.rejected,
                    PAYMENT_STATUS.canceled,
                    PAYMENT_STATUS.submitted,
                ],
            });
        case 'Offers':
            return [];
        case 'Refunds':
            return Array({
                type: ActivityType.refund,
                statuses: [PAYMENT_STATUS.confirmed],
            }, {
                type: ActivityType.credit,
                statuses: [PAYMENT_STATUS.confirmed],
            });
        case 'Disputes':
            return Array({
                type: ActivityType.dispute,
                statuses: [
                    PAYMENT_STATUS.confirmed,
                    PAYMENT_STATUS.pending,
                ],
            });
        default:
            return ACTIVITY_FEED_TYPES_AND_STATUSES;
    }
};
var getAccrualTypesAndStatuses = function (filterOption) {
    switch (filterOption) {
        case 'Offers':
        case 'All':
            return ONE_TIME_REWARD_TYPES_AND_STATUSES;
        case 'Payments':
        case 'Purchases':
        case 'Redemptions':
        case 'Refunds':
        case 'Disputes':
        default:
            return [];
    }
};
