var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { colors, ImpButton, ImpContainer, ImpFlex, ImpOtpField, ImpSpacer, ImpText, } from '@imprint-payments/imprint-ui';
import { useEffect, useState } from 'react';
import { EVENT } from '../../analytics/analyticsConsts';
import { initiateForgotPin } from '../../api/forgotPin/initiateForgotPin.api';
import { PINContext, verifyPin } from '../../api/verifyPin.api';
import { ForgotPinScreenState, setForgotPinScreenState } from '../../app/forgotPinSlice';
import { setHomeFlow } from '../../app/homeSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { TEMP_SNACKBAR_DURATION } from '../../app/snackbarSlice';
import { TEXT_WIDTH } from '../../assets/consts/const';
import { ANALYTICS } from '../../firebase/firebase';
import { displayTemporarySnackbar } from '../../utils/functionUtils';
import { ValidationResponse } from '../../utils/validateUtils';
import Spinner from '../spinner/Spinner';
export default function PinEntry() {
    var _a = useState(''), input = _a[0], setInput = _a[1];
    var _b = useState(false), clearInput = _b[0], setClearInput = _b[1];
    var dispatch = useAppDispatch();
    var appScreenState = useAppSelector(function (state) { return state.applicationScreenSelector; });
    var homeState = useAppSelector(function (state) { return state.homeSelector; });
    var language = useAppSelector(function (state) { return state.languageSelector.languageLocalization; });
    var showError = ![ValidationResponse.empty, ValidationResponse.valid].includes(homeState.homeFlow.pinError);
    useEffect(function () {
        ANALYTICS.logEvent(EVENT.ENTER_PIN_VIEWED);
        clearError();
    }, []);
    function handleChange(s) {
        setClearInput(false);
        if (input)
            clearError();
        setInput(s);
    }
    var handleInputFull = function (s) {
        ANALYTICS.logEvent(EVENT.ENTER_PIN_SUBMITTED);
        checkPinWithBE(s);
    };
    function checkPinWithBE(p) {
        dispatch(verifyPin(p, PINContext.Login, function () { }, // onSuccess
        function () { }, // onIncorrectEntry - 401(101: x attempts left; 201: locked)
        function () { return showServerError(); }, // onError - Server errors like 500
        function () { return showServerError(); } // onRejection - not a real use case in logiin flow
        ));
    }
    function showServerError() {
        ANALYTICS.logEvent(EVENT.PIN_SERVER_ERROR);
        setTimeout(function () {
            setClearInput(true);
        }, TEMP_SNACKBAR_DURATION);
        displayTemporarySnackbar(language.somethingWentWrong, dispatch);
    }
    function handleForgotPinClick() {
        ANALYTICS.logEvent(EVENT.LOGIN_FORGOT_PIN_CLICKED);
        initiateForgotPin();
        dispatch(setForgotPinScreenState(ForgotPinScreenState.LOADING));
        clearError();
    }
    function clearError() {
        dispatch(setHomeFlow(__assign(__assign({}, homeState.homeFlow), { pinError: ValidationResponse.empty })));
    }
    var ForgotPinPrompt = function () {
        var isResetStyle = showError;
        return (_jsx(ImpContainer, { children: _jsx(ImpFlex, __assign({ direction: "row", justify: "center", fluid: true }, { children: _jsx(ImpButton, __assign({ variant: isResetStyle ? 'primary' : 'text', buttonStyle: "imprint", onClick: handleForgotPinClick }, { children: isResetStyle ? language.resetPin : language.forgotPin })) })) }));
    };
    return (_jsxs(ImpContainer, __assign({ textAlign: "center", width: TEXT_WIDTH }, { children: [_jsxs(ImpFlex, __assign({ direction: "column", align: "center", fluid: true }, { children: [_jsx(ImpSpacer, { height: "2xl" }), _jsx(ImpText, __assign({ typography: "headline2" }, { children: language.enterYourPin })), _jsx(ImpSpacer, { height: "xl" })] })), _jsx(ImpContainer, __assign({ height: "56px" }, { children: _jsx(ImpOtpField, { autoFocus: true, numInputs: 4, onChange: handleChange, onFull: handleInputFull, clearWithError: showError, clear: clearInput, mask: true, autoComplete: "one-time-code" }) })), _jsx(ImpSpacer, { height: "xl" }), showError && (_jsxs(_Fragment, { children: [_jsx(ImpText, __assign({ color: colors.content.onSurfaceError }, { children: homeState.homeFlow.pinLockOutMessage })), _jsx(ImpSpacer, { height: "s" }), _jsx(ImpText, { children: language.acctLockoutParagraph }), _jsx(ImpSpacer, { height: "xl" })] })), _jsx(ForgotPinPrompt, {}), appScreenState.loading && _jsx(Spinner, {})] })));
}
