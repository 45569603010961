// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".hLrEfCuTObwhdKmIC7_P {\n  display: flex;\n  height: 100vh;\n  flex-direction: column;\n\n  align-items: center;\n}\n\n.RM7duGRpUkApSnnDlFWn {\n  display: flex;\n  flex-direction: column;\n  text-align: center;\n  flex: 1;\n  max-width: min(454px, 100vw - 32px);\n\n  margin-top: 234px;\n}\n\n.RM7duGRpUkApSnnDlFWn > * {\n  margin: 0;\n}\n\n.kCLNvuvuWD6c2fcnv_yf {\n  font-size: 36px;\n  font-weight: 670;\n  line-height: 40px;\n  letter-spacing: -.01em;\n}\n\n.UiudwpaH1UAPcWkoDsSI {\n  margin-top: 16px;\n  font-size: 14px;\n  line-height: 24px;\n  color: var(--grayscale-neutralGray600);\n}\n\n.S6z9Vnl4l9GFcR60XEJZ {\n  margin-top: 40px;\n}\n\n.RM7duGRpUkApSnnDlFWn a {\n  text-decoration: none;\n  color: var(--textColor);\n}", "",{"version":3,"sources":["webpack://./src/pages/error/Error.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,aAAa;EACb,sBAAsB;;EAEtB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,OAAO;EACP,mCAAmC;;EAEnC,iBAAiB;AACnB;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,sBAAsB;AACxB;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,sCAAsC;AACxC;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,qBAAqB;EACrB,uBAAuB;AACzB","sourcesContent":[".container {\n  display: flex;\n  height: 100vh;\n  flex-direction: column;\n\n  align-items: center;\n}\n\n.body {\n  display: flex;\n  flex-direction: column;\n  text-align: center;\n  flex: 1;\n  max-width: min(454px, 100vw - 32px);\n\n  margin-top: 234px;\n}\n\n.body > * {\n  margin: 0;\n}\n\n.title {\n  font-size: 36px;\n  font-weight: 670;\n  line-height: 40px;\n  letter-spacing: -.01em;\n}\n\n.subtitle {\n  margin-top: 16px;\n  font-size: 14px;\n  line-height: 24px;\n  color: var(--grayscale-neutralGray600);\n}\n\n.buttonContainer {\n  margin-top: 40px;\n}\n\n.body a {\n  text-decoration: none;\n  color: var(--textColor);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "hLrEfCuTObwhdKmIC7_P",
	"body": "RM7duGRpUkApSnnDlFWn",
	"title": "kCLNvuvuWD6c2fcnv_yf",
	"subtitle": "UiudwpaH1UAPcWkoDsSI",
	"buttonContainer": "S6z9Vnl4l9GFcR60XEJZ"
};
module.exports = ___CSS_LOADER_EXPORT___;
