var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { ImpCallout, ImpContainer, ImpText, colors } from '@imprint-payments/imprint-ui';
import { EVENT } from '../../analytics/analyticsConsts';
import { useAppSelector } from '../../app/hooks';
import { selectLanguage } from '../../app/languageSelectors';
import { usePageEvent } from '../../hooks/usePageEvent';
export function CardNotAvailableNote() {
    var language = useAppSelector(selectLanguage);
    usePageEvent(EVENT.AUTH_USER_CARD_NOT_AVAILABLE_NOTE_VIEWED);
    return (_jsx(ImpCallout, __assign({ variant: "info" }, { children: _jsx(ImpContainer, __assign({ width: "100%" }, { children: _jsx(ImpText, __assign({ color: colors.content.onSurfaceInfo }, { children: language.authUserCardNotAvailable })) })) })));
}
