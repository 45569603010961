var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ImpActionPage, ImpSpacer } from '@imprint-payments/imprint-ui';
import { useEffect } from 'react';
import { ForgotPinScreen, setForgotPinScreen } from '../../app/forgotPinSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { SCREEN } from '../../app/screenStateSlice';
import { HEADER_SERVICING_HEIGHT } from '../../components/HeaderServicing/HeaderServicing';
import { LoginCreatePin } from '../../components/pinEntry/LoginCreatePin';
import { FindAccountErrorPage } from '../FindYourAccount/FindAccountErrorPage';
import { FindYourAccountPage } from '../FindYourAccount/FindYourAccountPage';
import { ErrorPage } from './ErrorPage';
import SuccessPage from './SuccessPage';
import { VerifyDobPage } from './VerifyDobPage';
export default function ForgotPinFlowV2() {
    var forgotPinState = useAppSelector(function (state) { return state.forgotPinSlice; });
    var dispatch = useAppDispatch();
    useEffect(function () {
        switch (forgotPinState.nextStep) {
            case 'verifyDOB':
                dispatch(setForgotPinScreen(ForgotPinScreen.VERIFY_DOB));
                break;
            case 'verifyPII':
                dispatch(setForgotPinScreen(ForgotPinScreen.VERIFY_PII));
                break;
            case 'setPIN':
                dispatch(setForgotPinScreen(ForgotPinScreen.SET_PIN));
                break;
            default:
                dispatch(setForgotPinScreen(ForgotPinScreen.SOMETHING_WENT_WRONG));
        }
    }, [forgotPinState.nextStep]);
    var content;
    switch (forgotPinState.screen) {
        case ForgotPinScreen.VERIFY_PII:
            return _jsx(FindYourAccountPage, { isForgotPin: true });
        case SCREEN.FIND_ACCOUNT_ERROR:
            return _jsx(FindAccountErrorPage, {});
        case ForgotPinScreen.VERIFY_DOB:
            content = _jsx(VerifyDobPage, {});
            break;
        case ForgotPinScreen.SET_PIN:
            content = _jsx(LoginCreatePin, { resetPin: true });
            break;
        case ForgotPinScreen.SUCCESS:
            content = _jsx(SuccessPage, {});
            break;
        default:
            content = _jsx(ErrorPage, {});
            break;
    }
    return (_jsxs(ImpActionPage, __assign({ offset: HEADER_SERVICING_HEIGHT }, { children: [_jsx(ImpSpacer, { height: "2xl" }), content] })));
}
