var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useReducer, useState } from 'react';
import { colors, Icons, ImpBreadcrumbs, ImpContainer, ImpDivider, ImpFlex, ImpIcon, ImpImage, ImpList, ImpModal, ImpPad, ImpSpacer, ImpText, useModal } from "@imprint-payments/imprint-ui";
import HeaderServicing from "../../components/HeaderServicing/HeaderServicing";
import { navigateToAccountPage } from "../../utils/functionUtils";
import { useNavigate } from "react-router-dom";
import { store } from "../../app/store";
import { useAppSelector } from "../../app/hooks";
import AccountLinkModal from "../../components/AccountLinkModal/AccountLinkModal";
import { selectAccountLink } from "../../app/accountLinkSelector";
import { getAlternateLogoIcon } from "../../api/api";
import { setShowAccountLinkModal } from "../../app/accountLinkSlice";
import { selectSelectedProductAccountUUID } from "../../app/homeSelectors";
import { ANALYTICS } from "../../firebase/firebase";
import { EVENT } from "../../analytics/analyticsConsts";
var UpdateLinkedAccount = function () {
    var _a, _b;
    var navigate = useNavigate();
    var dispatch = store.dispatch;
    var language = useAppSelector(function (state) { return state.languageSelector.languageLocalization; });
    var merchantData = useAppSelector(function (state) { return state.merchantDataSelector; });
    var consumerData = useAppSelector(function (state) { return state.consumerInfoSelector; });
    var selectedProductAccountUUID = useAppSelector(selectSelectedProductAccountUUID);
    var accountLinkInfo = useAppSelector(selectAccountLink);
    var _c = useState(''), logoSrc = _c[0], setLogoSrc = _c[1];
    var _d = useState(true), loadingImg = _d[0], setLoadingImg = _d[1];
    var _e = useReducer(function (x) { return x + 1; }, 0), forceUpdate = _e[1];
    var _f = useModal(false), isAccountLinkModalOpen = _f[0], isAccountLinkModalClosing = _f[1], openAccountLinkModal = _f[2], closeAccountLinkModal = _f[3];
    useEffect(function () {
        var fetchSrc = function () { return __awaiter(void 0, void 0, void 0, function () {
            var src;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, getAlternateLogoIcon(merchantData.productUUID, true)];
                    case 1:
                        src = _a.sent();
                        setLoadingImg(false);
                        setLogoSrc(src || '');
                        return [2 /*return*/];
                }
            });
        }); };
        fetchSrc().catch(function (e) {
            console.log(e);
        });
    }, [selectedProductAccountUUID]);
    useEffect(function () {
        if (accountLinkInfo.showAccountLinkModal) {
            openAccountLinkModal();
        }
        else if (isAccountLinkModalOpen) {
            closeAccountLinkModal();
        }
    }, [accountLinkInfo.showAccountLinkModal]);
    var cancel = function () {
        navigateToAccountPage(dispatch, navigate);
    };
    var closeAccountLinkModalHandler = function () {
        closeAccountLinkModal();
        dispatch(setShowAccountLinkModal(false));
    };
    var openModal = function () {
        ANALYTICS.logEvent("".concat(EVENT.MERCHANT_NUMBER_UPDATE_CLICKED), {
            product_uuid: merchantData.productUUID,
            consumer_uuid: consumerData.consumerUUID,
        });
        openAccountLinkModal();
    };
    return (_jsxs(ImpContainer, { children: [_jsx(HeaderServicing, {}), _jsx(ImpBreadcrumbs, { breadcrumbs: [(_a = language[merchantData.productUUID]) === null || _a === void 0 ? void 0 : _a.accountLinking.membershipName], onBackClick: cancel, onCancelClick: cancel }), _jsx(ImpSpacer, { height: "2xl" }), _jsxs(ImpFlex, __assign({ direction: "column", align: "center", justify: "center", fluid: true }, { children: [_jsxs(ImpContainer, __assign({ backgroundColor: "white", borderRadius: "12px", width: "min(748px, 100% - 32px)", textAlign: "center" }, { children: [_jsx(ImpPad, __assign({ padding: "60px" }, { children: _jsx(ImpFlex, __assign({ align: "center", justify: "center", fluid: true }, { children: _jsx(ImpImage, { src: logoSrc, alt: "product-icon", width: "min(250px, 100%)", borderRadius: "50%" }) })) })), _jsx(ImpDivider, { color: colors.border.borderVariant }), _jsx(ImpPad, __assign({ padding: "40px" }, { children: _jsxs(ImpFlex, __assign({ direction: "column", align: "center", fluid: true }, { children: [_jsx(ImpContainer, { children: _jsx(ImpText, __assign({ typography: "headline2" }, { children: accountLinkInfo.membershipNumber })) }), _jsx(ImpSpacer, { height: "m" }), _jsx(ImpText, __assign({ typography: "body1", color: colors.content.onBackgroundVariant }, { children: (_b = language[merchantData.productUUID]) === null || _b === void 0 ? void 0 : _b.accountLinking.updateLinkedAccountSubheading }))] })) }))] })), _jsx(ImpSpacer, { height: "m" }), _jsx(ImpContainer, __assign({ width: "min(748px, 100% - 32px)", height: "400px" }, { children: _jsx(ImpList, { useSurface: true, items: [
                                {
                                    title: language.useDifferentAccount,
                                    leadingIcon: _jsx(ImpIcon, { iconSrc: Icons.arrowsClockwise, size: "24px" }),
                                    trailingContent: _jsx(ImpIcon, { iconSrc: Icons.chevronRight }),
                                    onClick: openModal,
                                },
                            ] }) }))] })), _jsx(ImpModal, __assign({ isOpen: isAccountLinkModalOpen, isClosing: isAccountLinkModalClosing, closeModal: closeAccountLinkModalHandler, respectContentWidth: true, respectContentHeight: true, headerImg: merchantData.accountLinkModalLogo, preventClose: false }, { children: _jsx(AccountLinkModal, { reloadDashboard: forceUpdate, closeModal: closeAccountLinkModalHandler }) }))] }));
};
export default UpdateLinkedAccount;
