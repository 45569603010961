export var KEY = {
    BACKSPACE: 8,
    DEL: 46,
    LEFT: 37,
    RIGHT: 39,
    TAB: 9,
};
export var KEYTYPE = {
    ENTER: 'ENTER',
    ARROW_RIGHT: 'ARROWRIGHT',
    ARROW_LEFT: 'ARROWLEFT',
    TAB: 'TAB',
};
