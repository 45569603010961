var _a;
import { createSlice } from '@reduxjs/toolkit';
import { getEmptyAmount } from '../utils/formatUtils';
import { ValidationResponse } from '../utils/validateUtils';
export var DOCUMENT_TYPE;
(function (DOCUMENT_TYPE) {
    DOCUMENT_TYPE["UNSPECIFIED"] = "UNSPECIFIED";
    DOCUMENT_TYPE["CITI_HISTORY_STATEMENT"] = "CITI_HISTORY_STATEMENT";
    DOCUMENT_TYPE["HISTORY_CHA"] = "HISTORY_CHA";
})(DOCUMENT_TYPE || (DOCUMENT_TYPE = {}));
export var ProductType;
(function (ProductType) {
    ProductType["CreditCard"] = "CREDITCARD";
    ProductType["SpendCard"] = "SPENDCARD";
})(ProductType || (ProductType = {}));
export var ACCOUNT_STATUS;
(function (ACCOUNT_STATUS) {
    ACCOUNT_STATUS["OFFER_PENDING"] = "OFFER_PENDING";
    ACCOUNT_STATUS["FROZEN"] = "FROZEN";
    ACCOUNT_STATUS["MANUAL_REVIEW"] = "MANUAL_REVIEW";
    ACCOUNT_STATUS["ACTIVE"] = "ACTIVE";
    ACCOUNT_STATUS["INACTIVE"] = "INACTIVE";
    ACCOUNT_STATUS["CANCEL"] = "CANCEL";
})(ACCOUNT_STATUS || (ACCOUNT_STATUS = {}));
// This interface is to record the information for any successful request.
// Including Submit payment, cancel payment, schedule payment.
export var SUCCESS_TYPE;
(function (SUCCESS_TYPE) {
    SUCCESS_TYPE["default"] = "default";
    SUCCESS_TYPE["paymentCancel"] = "paymentCancel";
    SUCCESS_TYPE["paymentPayNow"] = "paymentPayNow";
    SUCCESS_TYPE["paymentSchedule"] = "paymentSchedule";
    SUCCESS_TYPE["autoPaySchedule"] = "autoPaySchedule";
    SUCCESS_TYPE["autoPayCancel"] = "autoPayCancel";
    SUCCESS_TYPE["statementSetting"] = "statementSetting";
    // Personal Info Update
    SUCCESS_TYPE["emailUpdate"] = "emailUpdate";
    SUCCESS_TYPE["phoneUpdate"] = "phoneUpdate";
    SUCCESS_TYPE["addressUpdate"] = "addressUpdate";
    SUCCESS_TYPE["incomeUpdate"] = "incomeUpdate";
    // Security Pin
    SUCCESS_TYPE["securityPin"] = "securityPin";
    // Card Menu
    SUCCESS_TYPE["cardRequested"] = "cardRequested";
    SUCCESS_TYPE["cardReplaced"] = "cardReplaced";
})(SUCCESS_TYPE || (SUCCESS_TYPE = {}));
export var HomeScreen;
(function (HomeScreen) {
    HomeScreen[HomeScreen["Home"] = 0] = "Home";
    HomeScreen[HomeScreen["CardDetails"] = 1] = "CardDetails";
})(HomeScreen || (HomeScreen = {}));
var initialState = {
    userInformation: {
        firstName: '',
        lastName: '',
        memberSince: '',
        phone: '',
        email: '',
        firstTimeUser: false,
        address: {
            streetLine1: '',
            streetLine2: '',
            city: '',
            state: '',
            country: '',
            zipCode: '',
        },
        addressDaysUntilEditable: undefined,
    },
    homeFlow: {
        loginError: false,
        dobError: false,
        pinError: ValidationResponse.empty,
        pinLockOutMessage: '',
        pinRemainingAttempts: 0,
        failedPIN: false,
        redirectedFirstTime: false,
    },
    paymentRequirements: {
        '': {
            minimum: getEmptyAmount(),
            statement: getEmptyAmount(),
            current: getEmptyAmount(),
            interest: getEmptyAmount(),
            maxScheduleDateEpochSec: 0,
        },
    },
    primaryPaymentAccount: {
        paymentAccountUUID: '',
        status: '',
        name: '',
        accountNumberLast4: '',
        reason: '',
        accountType: '',
    },
    productAccounts: [
        {
            productAccountUUID: '',
            productUUID: '',
            productURL: '',
            creditLineUUID: '',
            cardholderAgreementURL: '',
            merchantName: '',
            productType: ProductType.CreditCard,
            productAccountStatus: ACCOUNT_STATUS.INACTIVE,
            consumerDocuments: [],
        },
    ],
    application: {
        applicationUUID: '',
        decision: {
            status: '',
            expiresAt: '',
            remainingDays: '',
            message: '',
        },
        cardOffer: {
            apr: getEmptyAmount(),
            cardDesignOptions: [],
            cardholderAgreementURL: '',
            fees: {
                annualFee: getEmptyAmount(),
                internationalFee: getEmptyAmount(),
                lateFee: getEmptyAmount(),
                overlimitFee: getEmptyAmount(),
                returnedPaymentFee: getEmptyAmount(),
            },
            limit: getEmptyAmount(),
        },
    },
    hasCreditCard: false,
    hasSpendCard: false,
    startedBankLinking: false,
    successType: SUCCESS_TYPE.default,
    successInformation: {
        amount: '',
        date: '',
    },
    displayFindMoreBanner: true,
    homeScreen: HomeScreen.Home,
    loadingNewCard: false,
    showWallet: false,
    linkToken: '',
    showAccountStatus: false,
    displayedSpendReview: false,
};
var homeSlice = createSlice({
    name: 'home',
    initialState: initialState,
    reducers: {
        setUserInformation: function (state, action) {
            state.userInformation = action.payload;
        },
        setHomeFlow: function (state, action) {
            state.homeFlow = action.payload;
        },
        setPaymentRequirements: function (state, action) {
            state.paymentRequirements = action.payload;
        },
        setPrimaryPaymentAccount: function (state, action) {
            state.primaryPaymentAccount = action.payload;
        },
        setProductAccounts: function (state, action) {
            state.productAccounts = action.payload;
        },
        setHasCreditCard: function (state, action) {
            state.hasCreditCard = action.payload;
        },
        setHasSpendCard: function (state, action) {
            state.hasSpendCard = action.payload;
        },
        setStartedBankLinking: function (state, action) {
            state.startedBankLinking = action.payload;
        },
        setSuccessType: function (state, action) {
            state.successType = action.payload;
        },
        setSuccessInformation: function (state, action) {
            state.successInformation = action.payload;
        },
        resetHome: function () { return initialState; },
        setApplication: function (state, action) {
            state.application = action.payload;
        },
        setSelectedProductAccount: function (state, action) {
            if (action.payload) {
                localStorage.setItem('imprint:productUUID', action.payload.productUUID);
            }
            state.selectedProductAccount = action.payload;
        },
        setDisplayFindMoreBanner: function (state, action) {
            state.displayFindMoreBanner = action.payload;
        },
        setHomeScreen: function (state, action) {
            state.homeScreen = action.payload;
        },
        setLoadingNewCard: function (state, action) {
            state.loadingNewCard = action.payload;
        },
        setShowWallet: function (state, action) {
            state.showWallet = action.payload;
        },
        setLinkToken: function (state, action) {
            state.linkToken = action.payload;
        },
        setShowAccountStatus: function (state, action) {
            state.showAccountStatus = action.payload;
        },
        setAccountStatusProductUUID: function (state, action) {
            state.accountStatusProductUUID = action.payload;
        },
        setDisplayedSpendReview: function (state, action) {
            state.displayedSpendReview = action.payload;
        },
        setRevealCardNumber: function (state, action) {
            state.revealCardNumber = action.payload;
        },
        setConsumerDocumentType: function (state, action) {
            state.consumerDocumentType = action.payload;
        },
    },
});
export var setUserInformation = (_a = homeSlice.actions, _a.setUserInformation), setHomeFlow = _a.setHomeFlow, setPaymentRequirements = _a.setPaymentRequirements, setPrimaryPaymentAccount = _a.setPrimaryPaymentAccount, setProductAccounts = _a.setProductAccounts, setHasCreditCard = _a.setHasCreditCard, setHasSpendCard = _a.setHasSpendCard, setStartedBankLinking = _a.setStartedBankLinking, setSuccessType = _a.setSuccessType, setSuccessInformation = _a.setSuccessInformation, resetHome = _a.resetHome, setApplication = _a.setApplication, setSelectedProductAccount = _a.setSelectedProductAccount, setDisplayFindMoreBanner = _a.setDisplayFindMoreBanner, setHomeScreen = _a.setHomeScreen, setLoadingNewCard = _a.setLoadingNewCard, setShowWallet = _a.setShowWallet, setLinkToken = _a.setLinkToken, setShowAccountStatus = _a.setShowAccountStatus, setAccountStatusProductUUID = _a.setAccountStatusProductUUID, setDisplayedSpendReview = _a.setDisplayedSpendReview, setRevealCardNumber = _a.setRevealCardNumber, setConsumerDocumentType = _a.setConsumerDocumentType;
export default homeSlice.reducer;
