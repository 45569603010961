import { useStatsigClient, useStatsigUser } from '@statsig/react-bindings';
export var Gates;
(function (Gates) {
    Gates["addToWallet"] = "add_to_wallet_web";
    Gates["displayCardLift"] = "cardlift";
    Gates["cloOfferConfigs"] = "clo_offer_configs";
})(Gates || (Gates = {}));
// DO NOT USE IN PRODUCTION. wrapper for statsig checkGate with console logs
export function useCheckGateDebug() {
    var checkGate = useStatsigClient().checkGate;
    var user = useStatsigUser().user;
    return function (gate) {
        var gateResult = checkGate(gate);
        console.log("result ".concat(gateResult, " with user: "), user);
        return gateResult;
    };
}
export var Configs;
(function (Configs) {
    Configs["cloOfferConfigs"] = "clo_offer_configs";
})(Configs || (Configs = {}));
