var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ImpContainer, ImpList, ImpSurface, ImpListHeader, ImpPad, ImpFlex, ImpText, colors, } from '@imprint-payments/imprint-ui';
import { useEffect, useState } from 'react';
import { getStatements } from '../../api/api';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { selectLanguage } from '../../app/languageSelectors';
import { selectStatementsList } from '../../app/statementsSelectors';
export function Statements() {
    var formattedStatements = useAppSelector(selectStatementsList);
    var productAccount = useAppSelector(function (state) { return state.productAccountSelector; });
    var language = useAppSelector(selectLanguage);
    var dispatch = useAppDispatch();
    var _a = useState(true), isLoadingStatements = _a[0], setIsLoadingStatements = _a[1];
    useEffect(function () {
        dispatch(getStatements()).then(function () {
            setIsLoadingStatements(false);
        });
    }, []);
    return (_jsx(ImpContainer, __assign({ width: "100%" }, { children: (formattedStatements === null || formattedStatements === void 0 ? void 0 : formattedStatements.length) > 0 || isLoadingStatements ? (_jsx(ImpList, { title: language.statements, subtitle: language.accountNumber + productAccount.accountCode, useSurface: true, items: formattedStatements, isLoading: isLoadingStatements, hideSquareLoader: true })) : (_jsxs(ImpSurface, __assign({ hasBorderRadius: true }, { children: [_jsx(ImpListHeader, { title: language.statements }), _jsx(ImpPad, __assign({ fluid: true, padding: "32px" }, { children: _jsx(ImpFlex, __assign({ fluid: true, justify: "center" }, { children: _jsx(ImpText, __assign({ typography: "body2", color: colors.content.onSurfaceVariant }, { children: language.noStatements })) })) }))] }))) })));
}
