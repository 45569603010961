import { jsx as _jsx } from "react/jsx-runtime";
import { Icons, ImpIcon, colors } from '@imprint-payments/imprint-ui';
import { PartnerMembershipStatus } from '../../types/RewardActionAssets';
export function getAccountLinkWidgetContent(status, language) {
    switch (status) {
        case PartnerMembershipStatus.UNLINKED: {
            return {
                title: language.accountLinkWidgetHeader,
                subtitle: language.accountLinkWidgetSubline,
                icon: _jsx(ImpIcon, { iconSrc: Icons.lock, stroke: colors.content.onSurfaceWarning }),
            };
        }
        case PartnerMembershipStatus.PENDING: {
            return {
                title: language.accountLinkInProgress,
                subtitle: language.accountLinkPendingSubline,
                icon: _jsx(ImpIcon, { iconSrc: Icons.spinner, stroke: colors.content.onSurface }),
            };
        }
        case PartnerMembershipStatus.CLOSED:
        case PartnerMembershipStatus.INVALID: {
            return {
                title: language.unableToLinkAccount,
                subtitle: language.unableToLinkAccountSubtitle,
                icon: _jsx(ImpIcon, { iconSrc: Icons.warningCircle, stroke: colors.content.onSurfaceError }),
            };
        }
        case PartnerMembershipStatus.PENDING_CONFIRMATION_ACTIVE:
        case PartnerMembershipStatus.PENDING_CONFIRMATION_MERGED: {
            return {
                title: language.finishLinkingAccount,
                subtitle: language.finishLinkingAccountSubtitle,
                icon: _jsx(ImpIcon, { iconSrc: Icons.link, stroke: colors.content.onSurfaceSuccess }),
            };
        }
        default: {
            return null;
        }
    }
}
