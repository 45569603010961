var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ImpActionPage, ImpButton, ImpSpacer, ImpText, colors } from '@imprint-payments/imprint-ui';
import { useEffect } from 'react';
import { EVENT } from '../../analytics/analyticsConsts';
import { checkIdvStatus } from '../../api/findAccount/checkIdvStatus.api';
import { selectIdvLink } from '../../app/accountOnboardingSelectors';
import { useAppSelector } from '../../app/hooks';
import { selectLanguage } from '../../app/languageSelectors';
import { selectMerchantData } from '../../app/merchantDataSelectors';
import { HEADER_SERVICING_HEIGHT } from '../../components/HeaderServicing/HeaderServicing';
import { ANALYTICS } from '../../firebase/firebase';
export function FindAccountIDVPage() {
    var language = useAppSelector(selectLanguage);
    var merchantData = useAppSelector(selectMerchantData);
    var idvLink = useAppSelector(selectIdvLink);
    var POLL_INTERVAL_MS = 1000; // poll every 1s
    useEffect(function () {
        ANALYTICS.logEvent(EVENT.UPDATE_PHONE_IDV_VIEWED, {
            product_uuid: merchantData.productUUID,
        });
    }, []);
    useEffect(function () {
        pollIdvStatus();
        var interval = setInterval(pollIdvStatus, POLL_INTERVAL_MS);
        return function () { return clearInterval(interval); };
    }, []);
    var pollIdvStatus = function () {
        // Only poll when user is on this page
        if (document.visibilityState === 'visible') {
            checkIdvStatus();
        }
    };
    var onIdvLinkClick = function () {
        ANALYTICS.logEvent(EVENT.UPDATE_PHONE_IDV_CONTINUE_CLICKED, {
            product_uuid: merchantData.productUUID,
        });
        window.open(idvLink);
    };
    return (_jsxs(ImpActionPage, __assign({ footer: _jsx(ImpButton, __assign({ buttonStyle: "branded", onClick: onIdvLinkClick }, { children: language.continueWithVerification })), offset: HEADER_SERVICING_HEIGHT }, { children: [_jsx(ImpSpacer, { height: "2xl" }), _jsx(ImpText, __assign({ typography: "headline2" }, { children: language.verifyYourIdentity })), _jsx(ImpSpacer, { height: "xs" }), _jsx(ImpText, __assign({ typography: "body2", color: colors.content.onSurfaceVariant }, { children: language.verifyYourIdentitySubtitle })), _jsx(ImpSpacer, { height: "xl" })] })));
}
