import { getEmptyAmount } from './Amount';
export var getEmptyBalances = function () {
    return {
        totalBalance: getEmptyAmount(),
        balanceIssue: '',
        accountLimitHardCap: false,
        totalAccountLimit: getEmptyAmount(),
        totalCashBalance: getEmptyAmount(),
        totalPendingTransactions: getEmptyAmount(),
        totalSpendingPower: getEmptyAmount(),
        totalWithdrawPower: getEmptyAmount(),
    };
};
