// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".U3054Csljp6hR2fpwCFR {\n  display: flex;\n  flex-direction: column;\n  text-align: center;\n  margin-left: auto;\n  margin-right: auto;\n  max-width: min(454px, 100vw - 32px);\n}\n\n.TralDBrwdfhcyhbsV8R2 {\n  margin-top: 16px;\n  font-size: 14px;\n  line-height: 24px;\n  color: var(--grayscale-neutralGray600);\n}\n\n.fKZX6r85klEg132aIbAV {\n  font-size: 36px;\n  line-height: 40px;\n  letter-spacing: -0.01em;\n  font-weight: 670;\n}\n\n.s1VLGqNfNijecfQfG2XL {\n    margin: 40px auto;\n  width: min(100vw - 48px, 356px);\n}\n\n@media (max-width: 600px) {\n  .U3054Csljp6hR2fpwCFR {\n    max-width: calc(100vw - 32px);\n  }\n\n  .P3YsDtCCrt5Cg6I_HkCZ {\n    font-size: 24px;\n    line-height: 32px;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/pages/cardMenu/ActivatePhysicalCardSuccess.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,iBAAiB;EACjB,kBAAkB;EAClB,mCAAmC;AACrC;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,sCAAsC;AACxC;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,uBAAuB;EACvB,gBAAgB;AAClB;;AAEA;IACI,iBAAiB;EACnB,+BAA+B;AACjC;;AAEA;EACE;IACE,6BAA6B;EAC/B;;EAEA;IACE,eAAe;IACf,iBAAiB;EACnB;AACF","sourcesContent":[".body {\n  display: flex;\n  flex-direction: column;\n  text-align: center;\n  margin-left: auto;\n  margin-right: auto;\n  max-width: min(454px, 100vw - 32px);\n}\n\n.subtitle {\n  margin-top: 16px;\n  font-size: 14px;\n  line-height: 24px;\n  color: var(--grayscale-neutralGray600);\n}\n\n.title {\n  font-size: 36px;\n  line-height: 40px;\n  letter-spacing: -0.01em;\n  font-weight: 670;\n}\n\n.buttonContainer {\n    margin: 40px auto;\n  width: min(100vw - 48px, 356px);\n}\n\n@media (max-width: 600px) {\n  .body {\n    max-width: calc(100vw - 32px);\n  }\n\n  .header {\n    font-size: 24px;\n    line-height: 32px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": "U3054Csljp6hR2fpwCFR",
	"subtitle": "TralDBrwdfhcyhbsV8R2",
	"title": "fKZX6r85klEg132aIbAV",
	"buttonContainer": "s1VLGqNfNijecfQfG2XL",
	"header": "P3YsDtCCrt5Cg6I_HkCZ"
};
module.exports = ___CSS_LOADER_EXPORT___;
