var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Icons, ImpActionPage, ImpButton, ImpCard, ImpContainer, ImpFlex, ImpIcon, ImpPad, ImpSpacer, ImpText, borderRadius, colors, } from '@imprint-payments/imprint-ui';
import { useEffect } from 'react';
import { EVENT } from '../../analytics/analyticsConsts';
import { getCardFront, getColor } from '../../api/api';
import { selectFirstName } from '../../app/accountOnboardingSelectors';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectLanguage } from '../../app/languageSelectors';
import { selectMerchantData } from '../../app/merchantDataSelectors';
import { SCREEN, setScreen } from '../../app/screenStateSlice';
import { FORM_BUTTON_WIDTH } from '../../assets/consts/const';
import { HEADER_SERVICING_HEIGHT } from '../../components/HeaderServicing/HeaderServicing';
import { ANALYTICS } from '../../firebase/firebase';
export function ConvertedAccountFoundPage() {
    var dispatch = useAppDispatch();
    var language = useAppSelector(selectLanguage);
    var merchantData = useAppSelector(selectMerchantData);
    var firstName = useAppSelector(selectFirstName);
    useEffect(function () {
        if (!merchantData.productUUID)
            return;
        dispatch(getCardFront(merchantData.productUUID));
        dispatch(getColor(merchantData.productUUID));
        ANALYTICS.logEvent(EVENT.ACCOUNT_FOUND_VIEWED, {
            product_uuid: merchantData.productUUID,
        });
    }, []);
    var handleBackClick = function () {
        dispatch(setScreen(SCREEN.FIND_ACCOUNT));
    };
    var handleContinueClick = function () {
        ANALYTICS.logEvent(EVENT.ACCOUNT_FOUND_CONTINUE_CLICKED, {
            product_uuid: merchantData.productUUID,
        });
        dispatch(setScreen(SCREEN.CONFIRM_PHONE));
    };
    return (_jsxs(ImpActionPage, __assign({ footer: _jsx(ImpButton, __assign({ buttonStyle: "branded", onClick: handleContinueClick }, { children: language.continueBtn })), onBackClick: handleBackClick, offset: HEADER_SERVICING_HEIGHT }, { children: [_jsxs(ImpFlex, __assign({ direction: "column", align: "center", gap: "l", fluid: true }, { children: [_jsx(ImpSpacer, { height: "s" }), _jsx("div", __assign({ style: { position: 'relative', zIndex: 1 } }, { children: _jsx(ImpCard, { src: merchantData.cardImg, width: FORM_BUTTON_WIDTH }) })), _jsx(ImpSpacer, { height: "s" }), _jsx(ImpContainer, __assign({ backgroundColor: colors.background.backgroundSecondary, borderRadius: borderRadius.s, border: "1px solid ".concat(colors.border.borderVariant) }, { children: _jsx(ImpPad, __assign({ paddingTop: "m", paddingBottom: "m", paddingLeft: "l", paddingRight: "l" }, { children: _jsxs(ImpFlex, __assign({ direction: "row", gap: "s", align: "center" }, { children: [_jsx(ImpIcon, { iconSrc: Icons.userCirclePlus, stroke: colors.content.onSurfaceSuccess }), _jsx(ImpText, __assign({ typography: "body2" }, { children: language.weFoundYourAccount }))] })) })) })), _jsx(ImpText, __assign({ typography: "display3" }, { children: language.welcome + ', ' + firstName })), _jsx(ImpText, __assign({ typography: "body1", color: colors.content.onSurfaceVariant }, { children: language.convertedAccountMoreSteps }))] })), _jsx(ImpSpacer, { height: "xl" })] })));
}
