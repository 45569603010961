import { jsx as _jsx } from "react/jsx-runtime";
import { navigateToAccountPage } from '../../utils/functionUtils';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { RewardsRedemptionPage, resetRewardsRedemption, } from '../../app/rewardsRedemptionSlice';
import { ConfigureRedemption } from './ConfigureRedemptionPage';
import { ConfirmRedemptionPage } from './ConfirmRedemptionPage';
import { RedeemUpdateAddressPage } from './RedeemUpdateAddressPage';
import { RedeemUpdateBankPage } from './RedeemUpdateBankPage';
import { PRODUCTUUID } from '../../utils/productUUIDConstants';
export function RedeemRewardsPage() {
    var rewardsRedemptionState = useAppSelector(function (state) { return state.rewardsRedemptionSelector; });
    var dispatch = useAppDispatch();
    var navigate = useNavigate();
    var selectedProductAccount = useAppSelector(function (state) { return state.homeSelector.selectedProductAccount; });
    var v1ValidMerchants = [PRODUCTUUID.HEB, PRODUCTUUID.CM];
    useEffect(function () {
        if (!selectedProductAccount ||
            !v1ValidMerchants.includes(selectedProductAccount === null || selectedProductAccount === void 0 ? void 0 : selectedProductAccount.productUUID))
            navigateToAccountPage(dispatch, navigate);
        return function () {
            dispatch(resetRewardsRedemption());
        };
    }, []);
    switch (rewardsRedemptionState.page) {
        case RewardsRedemptionPage.ConfirmRedemption: {
            return _jsx(ConfirmRedemptionPage, {});
        }
        case RewardsRedemptionPage.UpdateAddress:
            return _jsx(RedeemUpdateAddressPage, {});
        case RewardsRedemptionPage.UpdateBank:
            return _jsx(RedeemUpdateBankPage, {});
        default:
        case RewardsRedemptionPage.ConfigureRedemption: {
            return _jsx(ConfigureRedemption, {});
        }
    }
}
