var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { SCREEN, setScreen } from '../../app/screenStateSlice';
import HeaderServicing from '../../components/HeaderServicing/HeaderServicing';
import { PAGE } from '../../utils/routeConstant';
import { ImpBreadcrumbs, ImpButton, ImpContainer, ImpFlex, ImpSpacer, } from '@imprint-payments/imprint-ui';
import { getAuthApplicationEvaluation } from '../../api/getAuthApplicationEvaluation/getAuthApplicationEvaluation';
import { loadHome } from '../../api/loadHome.api';
import { AuthUsersPage, resetAuthUsers, setAuthUserAddress, setAuthUsersPage, } from '../../app/authUsersSlice';
import { selectDisplayAuthUserCardDelayTHY } from '../../app/featureFlagSelectors';
import { SUCCESS_TYPE } from '../../app/homeSlice';
import { selectLanguage } from '../../app/languageSelectors';
import { selectProductUUID } from '../../app/merchantDataSelectors';
import { Decision } from '../../types/Decision';
import { DisplayState } from '../../types/DisplayState';
import { navigateToAccountPage } from '../../utils/functionUtils';
import { PRODUCTUUID } from '../../utils/productUUIDConstants';
import AddAddressPage from './AddAddressPage';
import AddInformationPage from './AddInformationPage';
import AdditionalDetailsPage from './AdditionalDetailsPage';
import { CardNotAvailableNote } from './CardNotAvailableNote';
import { ConfirmationScreen } from './ConfirmationScreen';
import CreateAuthUserPage from './CreateAuthUserPage';
import { RejectedPage } from './RejectedPage';
import VerifyIdentityPage from './VerifyIdentityPage';
import VerifyPinPage from './VerifyPinPage';
function CardMailedPage() {
    var language = useAppSelector(selectLanguage);
    var authUserState = useAppSelector(function (state) { return state.authUsersSelector; });
    var productUUID = useAppSelector(selectProductUUID);
    var displayAuthUserCardDelayTHY = useAppSelector(selectDisplayAuthUserCardDelayTHY);
    var navigate = useNavigate();
    var displayCardNotAvailable = productUUID === PRODUCTUUID.TURKISH && displayAuthUserCardDelayTHY;
    return (_jsxs(ImpContainer, __assign({ width: "min(100% - 32px, 748px)" }, { children: [displayCardNotAvailable && (_jsxs(_Fragment, { children: [_jsx(CardNotAvailableNote, {}), _jsx(ImpSpacer, { height: "16px" })] })), _jsx(ConfirmationScreen, { title: language.authApplicationSuccess, subtitle: displayCardNotAvailable
                    ? language.authApplicationSuccessSubtitleCardNotAvailable
                    : language.authApplicationSuccessSubtitleCardMailed.replace('%name%', authUserState.consumer.name.firstName), actionButton: _jsx(ImpButton, __assign({ buttonStyle: "branded", variant: "primary", onClick: function () {
                        navigate(PAGE.AUTHORIZED_USERS_PAGE);
                    }, size: "fill" }, { children: language.done })) })] })));
}
export default function AddAuthorizedUserPage() {
    var dispatch = useAppDispatch();
    var navigate = useNavigate();
    var language = useAppSelector(function (state) { return state.languageSelector.languageLocalization; });
    var homeSelector = useAppSelector(function (state) { return state.homeSelector; });
    var authUserPage = useAppSelector(function (state) { return state.authUsersSelector.page; });
    var authUsersState = useAppSelector(function (state) { return state.authUsersSelector; });
    var featureFlags = useAppSelector(function (state) { return state.featureFlagSelector.featureFlags; });
    if (featureFlags.authUsers !== 'true') {
        navigateToAccountPage(dispatch, navigate);
        return null;
    }
    useEffect(function () {
        dispatch(loadHome());
        return function () {
            dispatch(resetAuthUsers());
        };
    }, []);
    useEffect(function () {
        var _a = authUsersState.application, status = _a.status, displayState = _a.displayState;
        if (!status || !displayState)
            return;
        if (status === Decision.APPROVED) {
            dispatch(setAuthUsersPage(AuthUsersPage.CARD_MAILED));
        }
        else if (status === Decision.REJECTED) {
            dispatch(setAuthUsersPage(AuthUsersPage.REJECTED));
        }
        else if (displayState === DisplayState.DisplayStateNewApplicationWithFullSSN) {
            dispatch(setAuthUsersPage(AuthUsersPage.ADDITIONAL_DETAILS));
        }
        else if (displayState === DisplayState.DisplayStateGetApplicationEvaluation) {
            var interval_1 = setInterval(function () {
                getAuthApplicationEvaluation();
            }, 2000);
            return function () { return clearInterval(interval_1); };
        }
    }, [authUsersState.application.displayState, authUsersState.application.status]);
    useEffect(function () {
        if (homeSelector.successType !== SUCCESS_TYPE.default) {
            dispatch(setScreen(SCREEN.SUCCESS));
            navigate(PAGE.SUCCESS);
        }
    }, [homeSelector.successType]);
    var getPageFromStage = function () {
        switch (authUserPage) {
            case AuthUsersPage.VERIFY_PIN:
                return (_jsx(VerifyPinPage, { handleSuccess: function () { return dispatch(setAuthUsersPage(AuthUsersPage.ADD_INFORMATION)); }, handleGoBack: function () { return dispatch(setAuthUsersPage(AuthUsersPage.CREATE_AUTH_USER)); } }));
            case AuthUsersPage.ADD_INFORMATION:
                return _jsx(AddInformationPage, {});
            case AuthUsersPage.ADD_ADDRESS:
                return (_jsx(AddAddressPage, { autofillConsumerAddress: true, title: language.addTheirAddressTitle, isSubmitting: false, continueButtonStyle: "branded", handleContinueClick: function (address) {
                        dispatch(setAuthUserAddress(address));
                        dispatch(setAuthUsersPage(AuthUsersPage.VERIFY_IDENTITY));
                    } }));
            case AuthUsersPage.VERIFY_IDENTITY:
                return _jsx(VerifyIdentityPage, {});
            case AuthUsersPage.ADDITIONAL_DETAILS:
                return _jsx(AdditionalDetailsPage, {});
            case AuthUsersPage.CARD_MAILED:
                return _jsx(CardMailedPage, {});
            case AuthUsersPage.REJECTED:
                return _jsx(RejectedPage, {});
            case AuthUsersPage.CREATE_AUTH_USER:
            default:
                return _jsx(CreateAuthUserPage, {});
        }
    };
    var getBreadcrumbsTitle = function () {
        switch (authUserPage) {
            case AuthUsersPage.VERIFY_PIN:
                return [language.verifyYourPin];
            case AuthUsersPage.ADD_INFORMATION:
                return [language.step1of3];
            case AuthUsersPage.CREATE_AUTH_USER:
            default:
                return [language.authorizedUsers];
        }
    };
    function handleBackClick() {
        switch (authUserPage) {
            case AuthUsersPage.VERIFY_PIN:
                dispatch(setAuthUsersPage(AuthUsersPage.CREATE_AUTH_USER));
                break;
            case AuthUsersPage.ADD_INFORMATION:
            case AuthUsersPage.CONFIRM_PIN:
                dispatch(setAuthUsersPage(AuthUsersPage.CREATE_AUTH_USER));
                break;
            case AuthUsersPage.ADD_ADDRESS:
                dispatch(setAuthUsersPage(AuthUsersPage.ADD_INFORMATION));
                break;
            case AuthUsersPage.VERIFY_IDENTITY:
                dispatch(setAuthUsersPage(AuthUsersPage.ADD_ADDRESS));
                break;
            case AuthUsersPage.DEFAULT:
            case AuthUsersPage.CREATE_AUTH_USER:
                navigate(PAGE.AUTHORIZED_USERS_PAGE);
                break;
            default:
                navigateToAccountPage(dispatch, navigate);
                break;
        }
    }
    return (_jsxs(ImpContainer, { children: [_jsx(HeaderServicing, {}), _jsx(ImpBreadcrumbs, { breadcrumbs: getBreadcrumbsTitle(), onBackClick: [
                    AuthUsersPage.ADDITIONAL_DETAILS,
                    AuthUsersPage.REJECTED,
                    AuthUsersPage.CARD_MAILED,
                ].includes(authUserPage)
                    ? undefined
                    : handleBackClick, onCancelClick: function () { return navigateToAccountPage(dispatch, navigate); } }), _jsxs(ImpFlex, __assign({ direction: "column", align: "center" }, { children: [_jsx(ImpSpacer, { height: "2xl" }), getPageFromStage()] }))] }));
}
