var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ImpSpinner } from '@imprint-payments/imprint-ui';
import { useEffect } from 'react';
import { loadHome } from '../../api/loadHome.api';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { SCREEN } from '../../app/screenStateSlice';
import Header from '../../components/header/Header';
import OfferHeader from '../../components/offerheader/OfferHeader';
import useNextPage from '../../hooks/nextPage';
import styles from './OfferPage.module.css';
export default function OfferPage() {
    var dispatch = useAppDispatch();
    var nextPage = useNextPage();
    var screenState = useAppSelector(function (state) { return state.screenStateSelector; });
    var merchantData = useAppSelector(function (state) { return state.merchantDataSelector; });
    var consumerData = useAppSelector(function (state) { return state.consumerInfoSelector; });
    useEffect(function () {
        dispatch(loadHome());
    }, []);
    useEffect(function () {
        if (screenState.screen === SCREEN.ACCOUNT) {
            nextPage();
        }
    }, [screenState]);
    var getOfferBody = function () {
        var _a, _b;
        if ((_b = (_a = consumerData.cardOffer) === null || _a === void 0 ? void 0 : _a.fees) === null || _b === void 0 ? void 0 : _b.annualFee) {
            return (_jsx("div", __assign({ className: styles.offerWrapper }, { children: _jsx(OfferHeader, {}) })));
        }
        else {
            return _jsx(ImpSpinner, {});
        }
    };
    return (_jsxs("div", __assign({ className: styles.container }, { children: [_jsx(Header, { merchantName: merchantData.rewardDetails.signupCardName, brandImg: merchantData.logoIcon }), getOfferBody()] })));
}
