import { convertAmountToString } from '@imprint-payments/imprint-lib';
import { paymentMaxInputLength } from '../../assets/consts/const';
import { ITEM_STYLE } from '../../components/list/List';
// Note: with the current configuration of paymentRequirements, we could get a
//  null value in here (since paymentRequirements is any). Also, we should not
//  be ablet to reach the page that uses this function without having paymentRequirements.
export var getPaymentAmountOptions = function (language, paymentRequirements, setPaymentMethodName) { return [
    {
        title: language.currentBalance,
        subtitle: convertAmountToString(paymentRequirements === null || paymentRequirements === void 0 ? void 0 : paymentRequirements.current),
        handleClick: function () {
            setPaymentMethodName('current_balance');
        },
    },
    {
        title: language.statementBalance,
        subtitle: convertAmountToString(paymentRequirements === null || paymentRequirements === void 0 ? void 0 : paymentRequirements.statement),
        handleClick: function () {
            setPaymentMethodName('statement_balance');
        },
    },
    {
        title: language.minPayment,
        subtitle: convertAmountToString(paymentRequirements === null || paymentRequirements === void 0 ? void 0 : paymentRequirements.minimum),
        handleClick: function () {
            setPaymentMethodName('minimum_payment');
        },
    },
    {
        title: language.customAmount,
        subtitle: '$0.00',
        style: ITEM_STYLE.CUSTOM_AMOUNT,
        maxCustomAmount: convertAmountToString(paymentRequirements === null || paymentRequirements === void 0 ? void 0 : paymentRequirements.current),
        maxInputLength: paymentMaxInputLength,
        handleClick: function () {
            setPaymentMethodName('custom_amount');
        },
    },
]; };
