import { Icons } from '@imprint-payments/imprint-ui';
import { PKG } from '../../analytics/analyticsConsts';
import { ANALYTICS } from '../../firebase/firebase';
import { ShippingWidgetState } from '../../types/ShippingWidget';
export var getPhysicalCardWidgetConfig = function (language) {
    return [
        {
            label: language.physicalCardState1,
            labelIcon: Icons.mail,
        },
        {
            label: language.physicalCardState2,
            labelIcon: Icons.truck,
        },
        {
            label: language.physicalCardState3,
            labelIcon: Icons.star,
        },
    ];
};
export var isShippingWidgetVisible = function (state, status, last4) {
    switch (state) {
        case ShippingWidgetState.Init:
            if (!last4 && !status) {
                return true;
            }
            break;
        case ShippingWidgetState.Ordered:
            if (status === 'INACTIVE') {
                return true;
            }
            break;
        case ShippingWidgetState.Shipped:
            if (status === 'INACTIVE' && !last4) {
                return true;
            }
            break;
        case ShippingWidgetState.NotReceived:
            if (status === 'INACTIVE' && !last4) {
                return true;
            }
            break;
    }
    ANALYTICS.funcError(PKG, 'showPhysicalCardWidget', "unexpected state received. state: ".concat(state, ", status: ").concat(status, ", last4: ").concat(last4));
    return false;
};
export var epochToMMDD = function (epoch) {
    var date = new Date(epoch * 1000);
    return "".concat(date.getMonth() + 1, "/").concat(date.getDate());
};
