var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { BreakpointsPx, Icons, ImpButton, ImpContainer, ImpFlex, ImpFlexItem, ImpHidden, ImpIcon, ImpPad, ImpSpacer, ImpText, colors, } from '@imprint-payments/imprint-ui';
import { useNavigate } from 'react-router-dom';
import { PAGE } from '../../utils/routeConstant';
import { usePaymentWidgetConfig } from './PaymentWidgetUtils';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectLanguage } from '../../app/languageSelectors';
import { setStartedBankLinking } from '../../app/homeSlice';
import { SCREEN, setPreviousScreen, setScreen, } from '../../app/screenStateSlice';
import { selectPrimaryPaymentAccount } from '../../app/homeSelectors';
import { useEffect, useState } from 'react';
import { setNavigateAfterBankLinking } from '../../app/paymentsPageSlice';
import { PaymentAccountStatus } from '../../pages/dashboard/Dashboard';
export function PaymentWidget() {
    var navigate = useNavigate();
    var dispatch = useAppDispatch();
    var language = useAppSelector(selectLanguage);
    var primaryPaymentAccount = useAppSelector(selectPrimaryPaymentAccount);
    var _a = useState(false), isPayNowButtonDisplayed = _a[0], setIsPayNowButtonDisplayed = _a[1];
    var _b = usePaymentWidgetConfig(), indicatorColor = _b.indicatorColor, title = _b.title, subtitle = _b.subtitle, payNowVariant = _b.payNowVariant;
    useEffect(function () {
        setIsPayNowButtonDisplayed(payNowVariant !== undefined);
    }, [payNowVariant]);
    var handleContainerClick = function () {
        navigate(PAGE.PAYMENTS);
    };
    var handlePayNowClick = function () {
        if (!(primaryPaymentAccount.status === PaymentAccountStatus.Approved)) {
            dispatch(setStartedBankLinking(true));
            dispatch(setScreen(SCREEN.BANKING));
            dispatch(setNavigateAfterBankLinking(true));
            navigate(PAGE.BANKING);
        }
        else {
            dispatch(setPreviousScreen(SCREEN.PAYMENTS));
            dispatch(setScreen(SCREEN.ONE_TIME_PAYMENT));
            navigate(PAGE.ONE_TIME_PAYMENT);
        }
    };
    return (_jsx(ImpContainer, __assign({ onClick: isPayNowButtonDisplayed ? undefined : handleContainerClick }, { children: _jsx(ImpPad, __assign({ padding: "16px 24px", fluid: true }, { children: _jsxs(ImpFlex, __assign({ direction: "row", align: "center", justify: "space-between", fluid: true, mobileDirection: isPayNowButtonDisplayed ? 'column' : undefined, mobileAlign: isPayNowButtonDisplayed ? 'flex-start' : undefined }, { children: [_jsx(ImpFlexItem, __assign({ flexGrow: 1 }, { children: _jsxs(ImpFlex, __assign({ direction: "row", gap: "l", align: "center" }, { children: [_jsx(ImpContainer, { height: "8px", width: "8px", backgroundColor: indicatorColor, borderRadius: "4px", dataTestId: "widget-indicator" }), _jsxs(ImpFlex, __assign({ direction: "column", align: "flex-start" }, { children: [_jsx(ImpText, __assign({ typography: "label1", dataTestId: "widget-title" }, { children: title })), _jsx(ImpSpacer, { height: "xs" }), _jsx(ImpText, __assign({ typography: "body2", color: colors.content.onSurfaceVariant, dataTestId: "widget-subtitle" }, { children: subtitle }))] }))] })) })), isPayNowButtonDisplayed ? (_jsx(ImpFlexItem, __assign({ flexGrow: 0, alignSelf: "normal" }, { children: _jsx(ImpPad, __assign({ mobilePadding: "12px 0 0" }, { children: _jsxs(ImpContainer, __assign({ width: "100%" }, { children: [_jsx(ImpHidden, __assign({ hideBelow: BreakpointsPx.Mobile }, { children: _jsx(ImpButton, __assign({ size: "hug", buttonStyle: "branded", variant: payNowVariant, onClick: handlePayNowClick }, { children: language.payNow })) })), _jsx(ImpHidden, __assign({ hideAbove: BreakpointsPx.Mobile }, { children: _jsx(ImpButton, __assign({ size: "fill", buttonStyle: "branded", variant: payNowVariant, onClick: handlePayNowClick }, { children: language.payNow })) }))] })) })) }))) : (_jsx(ImpIcon, { stroke: colors.content.onSurfaceVariant, iconSrc: Icons.chevronRight }))] })) })) })));
}
