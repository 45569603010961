var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Icons, ImpButton, ImpContainer, ImpFlex, ImpIcon, ImpInlineLink, ImpInput, ImpSpacer, ImpText, colors, } from '@imprint-payments/imprint-ui';
import { useEffect, useState } from 'react';
import { EVENT } from '../../analytics/analyticsConsts';
import { ImprintConstants } from '../../api/apiConstants';
import { initAuthApplication } from '../../api/initAuthApplication/initAuthApplication';
import { setAuthUserSsn4 } from '../../app/authUsersSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { FORM_INPUT_WIDTH, TEXT_WIDTH } from '../../assets/consts/const';
import { ANALYTICS } from '../../firebase/firebase';
import { LanguageCode } from '../../utils/languageConstants';
import { MerchantInfos, PRODUCTUUID_TO_MERCHANT } from '../../utils/productUUIDConstants';
import { ValidationResponse, getShowError, getValidationString, handleValueChange, validateLastFourSSN, } from '../../utils/validateUtils';
export default function VerifyIdentityPage() {
    var _a;
    var dispatch = useAppDispatch();
    var _b = useState(false), isSubmitting = _b[0], setIsSubmitting = _b[1];
    var homeState = useAppSelector(function (state) { return state.homeSelector; });
    var _c = useState(''), ssn4 = _c[0], setSsn4 = _c[1];
    var _d = useState(ValidationResponse.empty), ssn4Error = _d[0], setSsn4Error = _d[1];
    var languageCode = useAppSelector(function (state) { return state.languageSelector.languageCode; });
    var language = useAppSelector(function (state) { return state.languageSelector.languageLocalization; });
    var productUUID = ((_a = homeState.selectedProductAccount) === null || _a === void 0 ? void 0 : _a.productUUID) || '';
    useEffect(function () {
        handleValueChange(validateLastFourSSN(ssn4), setSsn4Error);
    }, [ssn4]);
    var getButtonState = function () {
        if (isSubmitting) {
            return 'submitting';
        }
        else if (ssn4Error === ValidationResponse.valid) {
            return 'enabled';
        }
        return 'disabled';
    };
    var handleContinueClick = function () {
        ANALYTICS.logEvent(EVENT.AUTH_USER_APPLICATION_SUBMITTED);
        dispatch(setAuthUserSsn4({ ssn4: ssn4 }));
        setIsSubmitting(true);
        initAuthApplication();
    };
    var getPrivacyPolicy = function () {
        return languageCode === LanguageCode.English
            ? ImprintConstants.ImprintPrivacy
            : ImprintConstants.ImprintPrivacyES;
    };
    var getEsignLink = function () {
        return languageCode === LanguageCode.English
            ? ImprintConstants.ImprintEsign
            : ImprintConstants.ImprintEsignES;
    };
    var getCardholderAgreement = function () {
        var _a;
        return ((_a = homeState.selectedProductAccount) === null || _a === void 0 ? void 0 : _a.cardholderAgreementURL) || '';
    };
    var getCardholderInfoSharingNotice = function () {
        return MerchantInfos[PRODUCTUUID_TO_MERCHANT[productUUID]].infoSharing[languageCode];
    };
    return (_jsxs(ImpContainer, __assign({ textAlign: "center", width: TEXT_WIDTH }, { children: [_jsxs(ImpFlex, __assign({ fluid: true, direction: "column", align: "center" }, { children: [_jsx(ImpText, __assign({ typography: "headline2", mobileTypography: "headline3" }, { children: language.authSsnEntryTitle })), _jsx(ImpSpacer, { height: 'm' }), _jsx(ImpText, __assign({ typography: "body2", color: colors.content.onBackgroundVariant }, { children: language.authSsnDisclaimer })), _jsx(ImpSpacer, { height: 'xl' }), _jsx(ImpFlex, __assign({ fluid: true, direction: "column", align: "center", gap: "l" }, { children: _jsx(ImpContainer, __assign({ width: FORM_INPUT_WIDTH }, { children: _jsx(ImpInput, { label: language.ssnLast4Label, showError: getShowError(ssn4Error), errorMessage: getValidationString(ssn4Error, language), isDisabled: isSubmitting, value: ssn4, onChange: function (e) {
                                    setSsn4(e.target.value);
                                }, onBlur: function () {
                                    setSsn4Error(validateLastFourSSN(ssn4));
                                }, maxLength: 4, numeric: true, trailingIcon: _jsx(ImpIcon, { iconSrc: Icons.lock, stroke: colors.content.onBackgroundVariant }) }) })) }))] })), _jsx(ImpSpacer, { height: 'xl' }), _jsxs(ImpText, __assign({ typography: 'body3', color: colors.content.onBackgroundVariant }, { children: [language.clickingContinue1, _jsx(ImpInlineLink, __assign({ color: 'var(--merchant-color)', url: getCardholderAgreement() }, { children: language.creditCardholderAgreement })), ', ', _jsx(ImpInlineLink, __assign({ color: 'var(--merchant-color)', url: getPrivacyPolicy() }, { children: language.privacyPolicy })), ', ', _jsx(ImpInlineLink, __assign({ color: 'var(--merchant-color)', url: getEsignLink() }, { children: language.eSignPolicy })), language.clickingContinue3, _jsx(ImpInlineLink, __assign({ color: 'var(--merchant-color)', url: getCardholderInfoSharingNotice() }, { children: language.cardholderInfoSharingNotice })), language.clickingContinue2] })), _jsx(ImpSpacer, { height: 'xl' }), _jsx(ImpFlex, __assign({ fluid: true, direction: "row", justify: "center" }, { children: _jsx(ImpButton, __assign({ size: "m", variant: "primary", buttonStyle: "branded", state: getButtonState(), onClick: handleContinueClick }, { children: language.continueBtn })) }))] })));
}
