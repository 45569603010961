var _a, _b;
export var LanguageCode;
(function (LanguageCode) {
    LanguageCode["English"] = "en-US";
    LanguageCode["Spanish"] = "es";
})(LanguageCode || (LanguageCode = {}));
;
export var LANGUAGE_TO_NUM = (_a = {},
    _a[LanguageCode.English] = 0,
    _a[LanguageCode.Spanish] = 1,
    _a);
export var LANGUAGE_CODE_TO_TEXT = (_b = {},
    _b[LanguageCode.English] = 'English',
    _b[LanguageCode.Spanish] = 'Español',
    _b);
export var LANGUAGE_HEADER = 'Accept-Language';
