// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HUpQLB7KvN8w__oWNBUQ,\n.LvB5f7SApngSeTBJsoLf {\n  width: 100vw;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}", "",{"version":3,"sources":["webpack://./src/pages/cardMenu/ActivatePhysicalCardPage.module.css"],"names":[],"mappings":"AAAA;;EAEE,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB","sourcesContent":[".container,\n.body {\n  width: 100vw;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "HUpQLB7KvN8w__oWNBUQ",
	"body": "LvB5f7SApngSeTBJsoLf"
};
module.exports = ___CSS_LOADER_EXPORT___;
