var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import { jsx as _jsx } from "react/jsx-runtime";
import googleImageEn from '../../assets/images/addToGoogleWallet_en.svg';
import googleImageEs from '../../assets/images/addToGoogleWallet_es.svg';
import { ImpImage } from '@imprint-payments/imprint-ui';
import { LanguageCode } from '../../utils/languageConstants';
import { launchGooglePayWebApp } from '../../api/addToWallet/googlePayIntegration';
import { useAppSelector } from '../../app/hooks';
import { PRODUCTUUID } from '../../utils/productUUIDConstants';
var productUUIDToPiaid = (_a = {},
    _a[PRODUCTUUID.EB] = 'MARQETA_PUSH_PROV_IMPRINT_EB_1',
    _a[PRODUCTUUID.BB] = 'MARQETA_PUSH_PROV_IMPRINT_BB_1',
    _a[PRODUCTUUID.TURKISH] = 'MARQETA_PUSH_PROV_IMPRINT_TURKISH_1',
    _a[PRODUCTUUID.HEB] = 'MARQETA_PUSH_PROV_IMPRINT_HEB_1',
    _a[PRODUCTUUID.BB] = 'MARQETA_PUSH_PROV_IMPRINT_CM_1',
    _a);
export default function AddToGoogleWalletButton(_a) {
    var cardUUID = _a.cardUUID, languageCode = _a.languageCode, onClick = _a.onClick, onSuccess = _a.onSuccess, onError = _a.onError;
    var merchantData = useAppSelector(function (state) { return state.merchantDataSelector; });
    var integratorId = productUUIDToPiaid[merchantData.productUUID];
    return integratorId ? (_jsx("button", __assign({ id: "google-wallet-button", onClick: function () {
            onClick();
            launchGooglePayWebApp(cardUUID, integratorId, onSuccess, onError);
        } }, { children: _jsx(ImpImage, { src: languageCode === LanguageCode.English ? googleImageEn : googleImageEs, alt: 'Add to Google Wallet', height: "44px" }) }))) : null;
}
