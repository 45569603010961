var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ImpContainer, ImpFlex, ImpOtpField, ImpSpacer, ImpSpinner, ImpText, colors, } from '@imprint-payments/imprint-ui';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useState } from 'react';
import { ANALYTICS } from '../../firebase/firebase';
import { displayTemporarySnackbar } from '../../utils/functionUtils';
import { EVENT } from '../../analytics/analyticsConsts';
import { verifyUpdateEmailOtp } from '../../api/PersonalInfo/UpdateEmail/verifyUpdateEmailOtp';
import styles from './PersonalInfo.module.css';
export function VerifyEmailPage(_a) {
    var onSuccess = _a.onSuccess;
    var language = useAppSelector(function (state) { return state.languageSelector.languageLocalization; });
    var _b = useState(false), incorrectDigitsError = _b[0], setIncorrectDigitsError = _b[1];
    var _c = useState(false), otpRequestFailed = _c[0], setOtpRequestFailed = _c[1];
    var _d = useState(''), otpInput = _d[0], setOtpInput = _d[1];
    var _e = useState(false), isLoading = _e[0], setIsLoading = _e[1];
    var dispatch = useAppDispatch();
    var handleEmailOtpChange = function (value) {
        setIncorrectDigitsError(false);
        setOtpRequestFailed(false);
        setOtpInput(value);
    };
    var handleSuccess = function () {
        onSuccess();
    };
    var handleEmailOtpIncorrectResponse = function () {
        setIncorrectDigitsError(true);
        setIsLoading(false);
    };
    var handleEmailOtpError = function () {
        setOtpRequestFailed(true);
        setIsLoading(false);
    };
    var handleRejection = function () {
        setIsLoading(false);
        displayTemporarySnackbar(language.somethingWentWrong, dispatch);
    };
    var handleEmailOtpFull = function () {
        ANALYTICS.logEvent(EVENT.PERSONAL_INFO_EMAIL_OTP_SUBMITTED);
        setIsLoading(true);
        dispatch(verifyUpdateEmailOtp(otpInput, handleSuccess, handleEmailOtpIncorrectResponse, handleEmailOtpError, handleRejection));
    };
    return (_jsx(ImpContainer, __assign({ width: "min(552px, 100%)" }, { children: _jsxs(ImpFlex, __assign({ direction: "column", align: "center", fluid: true }, { children: [_jsx(ImpText, __assign({ typography: "headline2" }, { children: language.verifyEmailHeader })), _jsx(ImpSpacer, { height: 'm' }), _jsx(ImpText, __assign({ typography: "body2", color: colors.content.onBackgroundVariant }, { children: language.verifyEmailSubheader })), _jsx(ImpSpacer, { height: 'xl' }), _jsx(ImpOtpField, { numInputs: 6, onChange: handleEmailOtpChange, onFull: handleEmailOtpFull, clearWithError: incorrectDigitsError || otpRequestFailed }), _jsxs("div", __assign({ className: styles.messageContainer }, { children: [incorrectDigitsError && (_jsx("div", __assign({ className: styles.message }, { children: language.otpError2 }))), otpRequestFailed && (_jsx("div", __assign({ className: styles.message }, { children: language.somethingWentWrong })))] })), isLoading && _jsx(ImpSpinner, {})] })) })));
}
