var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
export var CustomBullet = function (_a) {
    var stroke = _a.stroke;
    return (_jsx("div", __assign({ style: { height: '24px', width: '24px', marginBottom: 'auto' } }, { children: _jsx("svg", __assign({ style: { height: '24px', width: '24px' }, fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("path", { d: "M12 16.5C14.4853 16.5 16.5 14.4853 16.5 12C16.5 9.51472 14.4853 7.5 12 7.5C9.51472 7.5 7.5 9.51472 7.5 12C7.5 14.4853 9.51472 16.5 12 16.5Z", stroke: stroke, strokeWidth: "2", strokeMiterlimit: "10" }) })) })));
};
