import { EVENT } from '../analytics/analyticsConsts';
import { SetBackendLanguage } from '../api/setLanguage';
import { setLanguage, setLanguageChangedBeforeLogin, setShowDisclaimer, } from '../app/languageSlice';
import { SCREEN, setScreen } from '../app/screenStateSlice';
import { store } from '../app/store';
import localizationEN from '../assets/languages/en.json';
import localizationES from '../assets/languages/es.json';
import { invalidSession } from '../components/ProtectedRoute/protectedRouteUtils';
import { ANALYTICS } from '../firebase/firebase';
import { navigateToLogin, setSessionLanguage } from './functionUtils';
import { LanguageCode } from './languageConstants';
var languageSelectionPage = 'language_selection_page';
var languageConfirmationModalSettings = 'language_confirmation_modal_settings';
var languageConfirmationModalLogin = 'language_confirmation_modal_login';
export var codeToLocalization = function (languageCode) {
    switch (languageCode) {
        case LanguageCode.Spanish:
            return {
                code: LanguageCode.Spanish,
                localization: localizationES,
            };
        case LanguageCode.English:
        default:
            return {
                code: LanguageCode.English,
                localization: localizationEN,
            };
    }
};
export var handleLanguageSelect = function (languageCode, page) {
    if (languageCode === store.getState().languageSelector.languageCode)
        return;
    switch (languageCode) {
        case LanguageCode.Spanish:
            store.dispatch(setScreen(SCREEN.LANGUAGE_DISCLAIMER));
            store.dispatch(setShowDisclaimer(true));
            if (page === languageSelectionPage) {
                ANALYTICS.logEvent(EVENT.LANGUAGE_CONFIRMATION_MODAL_VIEWED, {
                    page: languageConfirmationModalSettings,
                    language: languageCode,
                });
            }
            else {
                ANALYTICS.logEvent(EVENT.LANGUAGE_CONFIRMATION_MODAL_VIEWED, {
                    page: languageConfirmationModalLogin,
                    language: languageCode,
                });
            }
            break;
        case LanguageCode.English:
        default:
            handleLanguageChangeConfirmed(LanguageCode.English, page);
            ANALYTICS.logEvent(EVENT.LANGUAGE_SELECTION_CLICKED, {
                page: page,
                language: languageCode,
            });
    }
};
export var handleLanguageChangeConfirmed = function (language, page) {
    if (language === store.getState().languageSelector.languageCode)
        return;
    var auth = store.getState().authSlice;
    switch (language) {
        case LanguageCode.Spanish:
            ANALYTICS.logEvent(EVENT.LANGUAGE_CONTINUE_CLICKED, {
                language: language,
                page: page,
            });
            store.dispatch(setLanguage(codeToLocalization(LanguageCode.Spanish)));
            setSessionLanguage(LanguageCode.Spanish);
            store.dispatch(setShowDisclaimer(false));
            if (invalidSession(auth)) {
                store.dispatch(setLanguageChangedBeforeLogin(true));
                store.dispatch(navigateToLogin);
                return;
            }
            else {
                store.dispatch(SetBackendLanguage);
            }
            break;
        case LanguageCode.English:
        default:
            store.dispatch(setLanguage(codeToLocalization(LanguageCode.English)));
            setSessionLanguage(LanguageCode.English);
            if (invalidSession(auth)) {
                store.dispatch(setLanguageChangedBeforeLogin(true));
                store.dispatch(navigateToLogin);
                return;
            }
            else {
                store.dispatch(SetBackendLanguage);
            }
    }
};
