var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useAppSelector } from '../../app/hooks';
import { ImpButton, ImpContainer, ImpFlex, ImpModal, ImpPad, ImpSpacer, ImpText, colors, } from '@imprint-payments/imprint-ui';
import { ANALYTICS } from '../../firebase/firebase';
import { EVENT } from '../../analytics/analyticsConsts';
export function RequestCardErrorModal(_a) {
    var isOpen = _a.isOpen, isClosing = _a.isClosing, closeModal = _a.closeModal;
    var language = useAppSelector(function (state) { return state.languageSelector.languageLocalization; });
    return (_jsx(ImpModal, __assign({ closeModal: closeModal, isOpen: isOpen, isClosing: isClosing }, { children: _jsx(ImpContainer, __assign({ width: "100%", maxHeight: "80vh" }, { children: _jsxs(ImpFlex, __assign({ direction: "column", fluid: true }, { children: [_jsx(ImpPad, __assign({ padding: "0px 16px" }, { children: _jsx(ImpText, __assign({ typography: "headline1" }, { children: language.requestCardErrorTitle })) })), _jsx(ImpSpacer, { height: "xl" }), _jsx(ImpPad, __assign({ padding: "0px 16px" }, { children: _jsxs(ImpText, __assign({ typography: "body1", color: colors.content.onBackgroundVariant }, { children: [language.requestCardErrorSubtitle, _jsx(ImpButton, __assign({ variant: "inline", onClick: function () {
                                        ANALYTICS.logEvent(EVENT.REQUEST_CARD_ERROR_SUPPORT_EMAIL);
                                        window.open('mailto:' + language.imprintSupportEmail);
                                    } }, { children: language.imprintSupportEmail })), "."] })) })), _jsx(ImpContainer, __assign({ width: "100%" }, { children: _jsx(ImpPad, __assign({ padding: "36px 16px" }, { children: _jsx(ImpButton, __assign({ variant: "primary", size: "fill", onClick: function () {
                                    ANALYTICS.logEvent(EVENT.REQUEST_CARD_ERROR_SUPPORT_EMAIL);
                                    window.open('mailto:' + language.imprintSupportEmail);
                                    closeModal();
                                } }, { children: language.contactSupportBtn })) })) }))] })) })) })));
}
