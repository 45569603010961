var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Icons, ImpBreadcrumbs, ImpButton, ImpContainer, ImpFlex, ImpFlexItem, ImpIcon, ImpList, ImpPad, ImpSpacer, ImpText, colors, } from '@imprint-payments/imprint-ui';
import { useEffect, useState } from 'react';
import { usePlaidLink } from 'react-plaid-link';
import { useNavigate } from 'react-router-dom';
import { EVENT } from '../../analytics/analyticsConsts';
import { addPaymentAccount, cancelPaymentAccount, submitCreateLinkToken } from '../../api/api';
import { loadHome } from '../../api/loadHome.api';
import { ACCOUNT_STATUS, ProductType, setStartedBankLinking } from '../../app/homeSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import LinkBank from '../../components/linkBank/LinkBank';
import Spinner from '../../components/spinner/Spinner';
import { ANALYTICS } from '../../firebase/firebase';
import { formatPaymentAccount } from '../../utils/formatUtils';
import { navigateToAccountPage, userHasBankAccount } from '../../utils/functionUtils';
export var PAYMENT_ACCOUNT_STATUS;
(function (PAYMENT_ACCOUNT_STATUS) {
    PAYMENT_ACCOUNT_STATUS["APPLY"] = "apply";
    PAYMENT_ACCOUNT_STATUS["APPROVED"] = "approved";
    PAYMENT_ACCOUNT_STATUS["REVIEWING_MANUAL"] = "reviewing_manual";
    PAYMENT_ACCOUNT_STATUS["REVIEWING_AUTO"] = "reviewing_auto";
    PAYMENT_ACCOUNT_STATUS["REPAIR"] = "repair";
})(PAYMENT_ACCOUNT_STATUS || (PAYMENT_ACCOUNT_STATUS = {}));
export var BANK_LINKING_STATUS;
(function (BANK_LINKING_STATUS) {
    BANK_LINKING_STATUS["NONE"] = "none";
    BANK_LINKING_STATUS["BANK_LINKED"] = "bank_linked";
    BANK_LINKING_STATUS["IN_REVIEW"] = "in_review";
    BANK_LINKING_STATUS["REPAIR"] = "repair";
    BANK_LINKING_STATUS["LOADING"] = "loading";
})(BANK_LINKING_STATUS || (BANK_LINKING_STATUS = {}));
export default function Banking(_a) {
    var onSuccess = _a.onSuccess, onExitFlow = _a.onExitFlow;
    var language = useAppSelector(function (state) { return state.languageSelector.languageLocalization; });
    var dispatch = useAppDispatch();
    var navigate = useNavigate();
    var consumerInfo = useAppSelector(function (state) { return state.consumerInfoSelector; });
    var homeSelector = useAppSelector(function (state) { return state.homeSelector; });
    var appScreenState = useAppSelector(function (state) { return state.applicationScreenSelector; });
    var paymentAccount = useAppSelector(function (state) { return state.homeSelector.primaryPaymentAccount; });
    var _b = useState(false), startedLinking = _b[0], setStartedLinking = _b[1];
    var _c = useState(BANK_LINKING_STATUS.LOADING), bankLinkingStatus = _c[0], setBankLinkingStatus = _c[1];
    var _d = useState(false), inLinkBankMethodSwitch = _d[0], setInLinkBankMethodSwitch = _d[1];
    var _e = useState(false), inCancelConfirmation = _e[0], setInCancelConfirmation = _e[1];
    var hasNoCreditCard = (homeSelector === null || homeSelector === void 0 ? void 0 : homeSelector.productAccounts.filter(function (productAccount) {
        return productAccount.productType !== ProductType.SpendCard &&
            ![ACCOUNT_STATUS.FROZEN, ACCOUNT_STATUS.MANUAL_REVIEW].includes(productAccount.productAccountStatus);
    }).length) === 0;
    var hasSpendCard = homeSelector.hasSpendCard && hasNoCreditCard;
    var config = {
        onSuccess: function (public_token, metadata) {
            var _a, _b, _c;
            if (metadata.accounts.length > 0) {
                dispatch(addPaymentAccount({
                    cancelCurrent: userHasBankAccount(paymentAccount.status),
                    paymentAccount: {
                        institutionID: (_a = metadata.institution) === null || _a === void 0 ? void 0 : _a.institution_id,
                        institutionName: (_b = metadata.institution) === null || _b === void 0 ? void 0 : _b.name,
                        accountID: metadata.accounts[0].id,
                        accountName: metadata.accounts[0].name,
                        mask: metadata.accounts[0].mask,
                        accountType: metadata.accounts[0].subtype,
                        accountNumber: '',
                        routingNumber: '',
                    },
                    paymentAccountType: 'plaid',
                    token: public_token,
                    verificationStatus: (_c = metadata.accounts[0].verification_status) !== null && _c !== void 0 ? _c : 'automatically_verified',
                }));
                setStartedLinking(false);
                setStartedBankLinking(false);
                setInLinkBankMethodSwitch(false);
                setInCancelConfirmation(false);
            }
        },
        token: homeSelector.linkToken,
        // required for OAuth; if not using OAuth, set to null or omit:
        // receivedRedirectUri: window.location.href,
    };
    var _f = usePlaidLink(config), open = _f.open, exit = _f.exit, ready = _f.ready;
    useEffect(function () {
        if (paymentAccount.status !== '') {
            switch (paymentAccount.status) {
                case PAYMENT_ACCOUNT_STATUS.APPLY: {
                    setBankLinkingStatus(BANK_LINKING_STATUS.NONE);
                    break;
                }
                case PAYMENT_ACCOUNT_STATUS.APPROVED: {
                    setBankLinkingStatus(BANK_LINKING_STATUS.BANK_LINKED);
                    break;
                }
                case PAYMENT_ACCOUNT_STATUS.REVIEWING_MANUAL: {
                    setBankLinkingStatus(BANK_LINKING_STATUS.IN_REVIEW);
                    break;
                }
                case PAYMENT_ACCOUNT_STATUS.REVIEWING_AUTO: {
                    setBankLinkingStatus(BANK_LINKING_STATUS.IN_REVIEW);
                    break;
                }
                case PAYMENT_ACCOUNT_STATUS.REPAIR: {
                    setBankLinkingStatus(BANK_LINKING_STATUS.REPAIR);
                    break;
                }
            }
        }
    }, [paymentAccount.status]);
    useEffect(function () {
        dispatch(loadHome()).then(function () {
            // workaround until loadHome refactor
            if (consumerInfo.consumerUUID === '')
                return;
            dispatch(submitCreateLinkToken({
                consumerUUID: consumerInfo.consumerUUID,
            }));
        });
    }, []);
    var deleteClick = function () {
        dispatch(cancelPaymentAccount());
    };
    var fixAccountButton = function () { return (_jsx(ImpContainer, __assign({ width: "min(367px, 100%)" }, { children: _jsx(ImpPad, __assign({ padding: "16px", fluid: true }, { children: _jsx(ImpButton, __assign({ state: !ready ? 'disabled' : 'enabled', onClick: function () {
                    ANALYTICS.logEvent(EVENT.BANK_ACCOUNT_FIX_ACCOUNT_CLICKED);
                    open();
                }, size: "fill" }, { children: language.fixAccount })) })) }))); };
    var linkingOptions = function () { return (_jsx(ImpContainer, { children: _jsx(ImpFlex, __assign({ fluid: true, direction: "row", justify: "center" }, { children: _jsxs(ImpFlex, __assign({ direction: "row", mobileDirection: "column", gap: "xl", align: "center", mobileAlign: "stretch", fluid: true }, { children: [!homeSelector.hasSpendCard && (_jsx(ImpFlexItem, __assign({ flex: '1 1 0' }, { children: _jsx(ImpButton, __assign({ onClick: function () {
                                ANALYTICS.logEvent(EVENT.LINK_BANK_ACCOUNT_LINK_ACCOUNT_CLICKED);
                                setStartedLinking(true);
                            }, state: appScreenState.loading ? 'submitting' : 'enabled', variant: 'secondary', size: "fill" }, { children: language.linkBankBtnNew })) }))), _jsx(ImpFlexItem, __assign({ flex: '1 1 0' }, { children: _jsx(ImpButton, __assign({ disabled: !ready, onClick: function () {
                                ANALYTICS.logEvent(EVENT.LINK_BANK_ACCOUNT_LINK_PLAID_ACCOUNT_CLICKED);
                                open();
                            }, state: appScreenState.loading ? 'submitting' : 'enabled', size: "fill" }, { children: language.linkBankBtnPlaid })) }))] })) })) })); };
    var bankActionButtons = function () { return (_jsxs(_Fragment, { children: [_jsx(ImpList, { useSurface: true, items: [
                    {
                        title: language.useDifferentAccount,
                        leadingIcon: _jsx(ImpIcon, { iconSrc: Icons.arrowsClockwise, size: "24px" }),
                        onClick: function () {
                            ANALYTICS.logEvent(EVENT.BANK_ACCOUNT_LINKED_USE_DIFFERENT_ACCOUNT_CLICKED);
                            setInLinkBankMethodSwitch(true);
                        },
                    },
                ] }), _jsx(ImpList, { useSurface: true, items: [
                    {
                        title: language.deleteAccountBtn,
                        destructive: true,
                        leadingIcon: (_jsx(ImpIcon, { iconSrc: Icons.trash, stroke: colors.content.onBackgroundError, size: "24px" })),
                        onClick: function () {
                            ANALYTICS.logEvent(EVENT.BANK_ACCOUNT_LINKED_DELETE_ACCOUNT_CLICKED);
                            setInCancelConfirmation(true);
                        },
                    },
                ] })] })); };
    var showBankActions = bankLinkingStatus === BANK_LINKING_STATUS.BANK_LINKED ||
        bankLinkingStatus === BANK_LINKING_STATUS.REPAIR;
    var showFixAcct = bankLinkingStatus === BANK_LINKING_STATUS.REPAIR;
    var showLinkingOptions = bankLinkingStatus === BANK_LINKING_STATUS.NONE;
    var actionUI = function (_a) {
        var title = _a.title, subtitle = _a.subtitle, button1 = _a.button1, button2 = _a.button2;
        return (_jsxs(_Fragment, { children: [_jsx(ImpBreadcrumbs, { breadcrumbs: [language.banking], onBackClick: function () { return setInLinkBankMethodSwitch(false); }, onCancelClick: function () { return navigateToAccountPage(dispatch, navigate); } }), _jsx(ImpSpacer, { height: '5xl' }), _jsx(ImpFlex, __assign({ direction: "row", justify: "center" }, { children: _jsxs(ImpContainer, __assign({ width: 'min(748px, 100% - 32px)' }, { children: [_jsx(ImpSpacer, { height: "2xl" }), _jsx(ImpFlex, __assign({ direction: "column", align: "center" }, { children: _jsxs(ImpContainer, __assign({ textAlign: "center" }, { children: [_jsx(ImpText, __assign({ typography: "headline2" }, { children: title })), _jsx(ImpSpacer, { height: "l" }), _jsx(ImpText, __assign({ typography: "body2", color: colors.content.onBackgroundVariant }, { children: subtitle })), _jsx(ImpSpacer, { height: "3xl" }), _jsxs(ImpFlex, __assign({ direction: "row", mobileDirection: "column", gap: "xl", align: "center", mobileAlign: "stretch", fluid: true }, { children: [_jsx(ImpFlexItem, __assign({ flex: '1 1 0' }, { children: button1 })), _jsx(ImpFlexItem, __assign({ flex: '1 1 0' }, { children: button2 }))] }))] })) }))] })) }))] }));
    };
    var linkBankMethodSwitchUI = actionUI({
        title: language.linkDifferentAccount,
        subtitle: language.linkDifferentAccountSubheader,
        button1: (_jsx(ImpButton, __assign({ onClick: function () {
                dispatch(cancelPaymentAccount());
                setStartedLinking(true);
                setInLinkBankMethodSwitch(false);
            }, state: appScreenState.loading ? 'submitting' : 'enabled', variant: 'secondary', size: "fill" }, { children: language.linkBankBtnNew }))),
        button2: (_jsx(ImpButton, __assign({ disabled: !ready, onClick: function () {
                dispatch(cancelPaymentAccount());
                setInLinkBankMethodSwitch(false);
                open();
            }, state: appScreenState.loading ? 'submitting' : ready ? 'enabled' : 'disabled', size: "fill" }, { children: language.linkBankBtnPlaid }))),
    });
    var cancelConfirmUI = actionUI({
        title: language.cancelConfirmationHeader,
        subtitle: language.deleteAccountConfirmationSubheader,
        button1: (_jsx(ImpButton, __assign({ onClick: function () {
                deleteClick();
                setInCancelConfirmation(false);
            }, size: "fill" }, { children: language.deleteAccountConfirmYesBtn }))),
        button2: (_jsx(ImpButton, __assign({ onClick: function () {
                setInCancelConfirmation(false);
            }, variant: "secondary", size: "fill" }, { children: language.deleteAccountConfirmNoBtn }))),
    });
    var bankingPageUI = function () {
        if (paymentAccount.status === '')
            return _jsx(Spinner, {});
        if (inCancelConfirmation)
            return cancelConfirmUI;
        if (inLinkBankMethodSwitch)
            return linkBankMethodSwitchUI;
        return (_jsxs(_Fragment, { children: [_jsx(LinkBank, { bankLinkingStatus: bankLinkingStatus, hasSpendCard: hasSpendCard, header: formatPaymentAccount(paymentAccount, language), startBankLinking: startedLinking, setStartedLinking: setStartedLinking, onSuccess: onSuccess, onExitFlow: onExitFlow, bottomContent: showFixAcct && _jsx(_Fragment, { children: fixAccountButton() }) }), _jsx(ImpSpacer, { height: "xl" }), !startedLinking && (_jsx(ImpFlex, __assign({ fluid: true, justify: "center", direction: "row" }, { children: _jsx(ImpContainer, __assign({ width: "min(748px, 100% - 32px)" }, { children: _jsxs(ImpFlex, __assign({ fluid: true, direction: "column", gap: "s", align: "stretch" }, { children: [showLinkingOptions && linkingOptions(), showBankActions && bankActionButtons()] })) })) })))] }));
    };
    return bankingPageUI();
}
