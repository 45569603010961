import { useStatsigUser } from '@statsig/react-bindings';
import { useEffect } from 'react';
import { selectSelectedProductAccountUUID } from '../app/homeSelectors';
import { useAppSelector } from '../app/hooks';
import { selectProductUUID } from '../app/merchantDataSelectors';
// updates statsig users with useEffects
export function useStatsigUserUpdaters() {
    var updateUserAsync = useStatsigUser().updateUserAsync;
    var productUUID = useAppSelector(selectProductUUID);
    var selectedProductAccountUUID = useAppSelector(selectSelectedProductAccountUUID);
    useEffect(function () {
        productUUID &&
            selectedProductAccountUUID &&
            updateUserAsync(function (user) {
                var _a;
                user.userID = selectedProductAccountUUID;
                var impAttributes = (_a = user.custom) !== null && _a !== void 0 ? _a : {};
                impAttributes.productUUID = productUUID;
                // TODO: this should probably be web
                impAttributes.platform = 'mobile';
                user.custom = impAttributes;
                return user;
            });
    }, [selectedProductAccountUUID, productUUID]);
}
