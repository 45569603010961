var _a;
import { createSlice } from '@reduxjs/toolkit';
import { ValidationResponse, validateName, validateEmail, validatePhoneNumber, validateFullSSN, validateLastFourSSN, validateAddress, validateCity, validateZip, validateState, validateBirthDate, validateAccountNum, validateRoutingNum, verifyAccountAndRoutingError, } from '../utils/validateUtils';
var initialState = {
    fnError: ValidationResponse.empty,
    lnError: ValidationResponse.empty,
    emailError: ValidationResponse.empty,
    hhEmailError: ValidationResponse.empty,
    subscribeEmailError: ValidationResponse.empty,
    birthDateError: ValidationResponse.empty,
    phoneNumberError: ValidationResponse.empty,
    lastFourError: ValidationResponse.empty,
    fullSSNError: ValidationResponse.invalidSSN,
    address1Error: ValidationResponse.empty,
    address2Error: ValidationResponse.empty,
    cityError: ValidationResponse.empty,
    zipError: ValidationResponse.empty,
    stateError: ValidationResponse.empty,
    accountNumError: ValidationResponse.empty,
    routingNumError: ValidationResponse.empty,
    accountAndRoutingError: ValidationResponse.empty,
    serviceLoginError: ValidationResponse.empty,
};
export var validatorSlice = createSlice({
    name: 'validator',
    initialState: initialState,
    reducers: {
        checkFN: function (state, action) {
            state.fnError = validateName(action.payload, true);
        },
        checkLN: function (state, action) {
            state.lnError = validateName(action.payload, false);
        },
        checkEmail: function (state, action) {
            var email = action.payload[0];
            var isSubscribeEmail = action.payload[1];
            var currentEmail = action.payload[2];
            if (currentEmail !== undefined && email === currentEmail) {
                state.emailError = ValidationResponse.invalidEmailSameAsCurrent;
            }
            else if (isSubscribeEmail) {
                state.subscribeEmailError = validateEmail(email);
            }
            else {
                state.emailError = validateEmail(email);
            }
        },
        checkBirthDate: function (state, action) {
            state.birthDateError = validateBirthDate(action.payload);
        },
        checkPhoneNumber: function (state, action) {
            state.phoneNumberError = validatePhoneNumber(action.payload);
        },
        checkSSNLastFour: function (state, action) {
            state.lastFourError = validateLastFourSSN(action.payload);
        },
        checkFullSSN: function (state, action) {
            var _a;
            var ssn = action.payload[0];
            var last4 = (_a = action.payload[1]) !== null && _a !== void 0 ? _a : '';
            state.fullSSNError =
                last4 !== '' ? validateFullSSN(ssn, last4) : validateFullSSN(ssn);
        },
        checkAddress1: function (state, action) {
            state.address1Error = validateAddress(action.payload);
        },
        checkAddress2: function (state, action) {
            state.address2Error = validateAddress(action.payload, true);
        },
        checkCity: function (state, action) {
            state.cityError = validateCity(action.payload);
        },
        checkZip: function (state, action) {
            state.zipError = validateZip(action.payload);
        },
        checkState: function (state, action) {
            state.stateError = validateState(action.payload);
        },
        checkAccountNum: function (state, action) {
            state.accountNumError = validateAccountNum(action.payload);
        },
        checkRoutingNum: function (state, action) {
            state.routingNumError = validateRoutingNum(action.payload);
        },
        setAccountAndRoutingError: function (state, action) {
            state.accountAndRoutingError = verifyAccountAndRoutingError(action.payload);
        },
        resetInputValidation: function (state) {
            state.fnError = initialState.fnError;
            state.lnError = initialState.lnError;
            state.emailError = initialState.emailError;
            state.birthDateError = initialState.birthDateError;
            state.phoneNumberError = initialState.phoneNumberError;
            state.lastFourError = initialState.lastFourError;
            state.fullSSNError = initialState.fullSSNError;
            state.address1Error = initialState.address1Error;
            state.address2Error = initialState.address2Error;
            state.cityError = initialState.cityError;
            state.zipError = initialState.zipError;
            state.stateError = initialState.stateError;
        },
        resetPopupValidation: function (state) {
            state.subscribeEmailError = initialState.subscribeEmailError;
        },
        resetAccountAndRouting: function (state) {
            state.routingNumError = initialState.routingNumError;
            state.accountNumError = initialState.accountNumError;
        },
    },
});
export var checkFN = (_a = validatorSlice.actions, _a.checkFN), checkLN = _a.checkLN, checkEmail = _a.checkEmail, checkBirthDate = _a.checkBirthDate, checkPhoneNumber = _a.checkPhoneNumber, checkSSNLastFour = _a.checkSSNLastFour, checkFullSSN = _a.checkFullSSN, checkAddress1 = _a.checkAddress1, checkAddress2 = _a.checkAddress2, checkCity = _a.checkCity, checkZip = _a.checkZip, checkState = _a.checkState, resetInputValidation = _a.resetInputValidation, resetPopupValidation = _a.resetPopupValidation, checkAccountNum = _a.checkAccountNum, checkRoutingNum = _a.checkRoutingNum, setAccountAndRoutingError = _a.setAccountAndRoutingError, resetAccountAndRouting = _a.resetAccountAndRouting;
export default validatorSlice.reducer;
