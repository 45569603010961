var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export var CustomArrowLeft = function (_a) {
    var stroke = _a.stroke;
    return (_jsx("div", __assign({ style: { height: '24px', width: '24px' } }, { children: _jsxs("svg", __assign({ style: { height: '24px', width: '24px' }, fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [_jsx("path", { d: "M20.25 12H3.75", stroke: stroke, strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M10.5 5.25L3.75 12L10.5 18.75", stroke: stroke, strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" })] })) })));
};
