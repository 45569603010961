import Accept from '../pages/accept/AcceptApplicaton';
import AccessMore from '../pages/accessMore/AccessMore';
import AccountSetupFlow from '../pages/accountSetup/AccountSetupFlow';
import AccountStatusPage from '../pages/accountStatusPage/AccountStatusPage';
import AddAuthorizedUserPage from '../pages/authorizedUsers/AddAuthorizedUserPage';
import { AuthorizedUsersPage } from '../pages/authorizedUsers/AuthorizedUsersPage';
import AutoPay from '../pages/autoPay/AutoPay';
import { BankingPage } from '../pages/banking/BankingPage';
import { CardBalanceDetailsPage } from '../pages/CardBalanceDetailsPage/CardBalanceDetailsPage';
import ActivatePhysicalCardPage from '../pages/cardMenu/ActivatePhysicalCardPage';
import { GetNewCardNumber } from '../pages/cardMenu/GetNewCardNumber';
import { GetPhysicalCard } from '../pages/cardMenu/GetPhysicalCard';
import { ReplaceCard } from '../pages/cardMenu/ReplaceCard';
import ChangeLanguage from '../pages/changeLanguage/ChangeLanguage';
import ConsumerDocuments from '../pages/consumerDocuments/ConsumerDocuments';
import Dashboard from '../pages/dashboard/Dashboard';
import Documents from '../pages/documents/Documents';
import DownloadApp from '../pages/downloadApp/DownloadApp';
import EDUAppWeb from '../pages/EDUAppWeb/EDUAppWeb';
import OfferPage from '../pages/offerPage/OfferPage';
import OneTimePayment from '../pages/oneTimePayment/OneTimePayment';
import Payments from '../pages/payments/Payments';
import PersonalInfo from '../pages/PersonalInfo/PersonalInfo';
import { RedeemRewardsPage } from '../pages/redeemRewards/RedeemRewardsPage';
import Security from '../pages/security/Security';
import StatementSettings from '../pages/StatementSettings/StatementSettings';
import Success from '../pages/success/Success';
import { PAGE } from '../utils/routeConstant';
import UpdateLinkedAccount from "../pages/UpdateLinkedAccount/UpdateLinkedAccount";
// a protected route requires the user to be authenticated
export var protectedRoutes = [
    { path: PAGE.ACCEPT, component: Accept },
    { path: PAGE.ACCESS_MORE, component: AccessMore },
    { path: PAGE.ACCOUNT, component: Dashboard },
    { path: PAGE.ACCOUNT_SETUP, component: AccountSetupFlow },
    { path: PAGE.ACCOUNT_STATUS, component: AccountStatusPage },
    { path: PAGE.ACTIVATE_PHYSICAL_CARD, component: ActivatePhysicalCardPage },
    { path: PAGE.ADD_AUTHORIZED_USER, component: AddAuthorizedUserPage },
    { path: PAGE.AUTHORIZED_USERS_PAGE, component: AuthorizedUsersPage },
    { path: PAGE.AUTOPAY, component: AutoPay },
    { path: PAGE.BANKING, component: BankingPage },
    { path: PAGE.CARD_BALANCE, component: CardBalanceDetailsPage },
    { path: PAGE.CHANGE_LANGUAGE, component: ChangeLanguage },
    { path: PAGE.CONSUMER_DOCUMENTS, component: ConsumerDocuments },
    { path: PAGE.DOCUMENTS, component: Documents },
    { path: PAGE.DOWNLOAD_APP, component: DownloadApp },
    { path: PAGE.EDU_APP_WEB, component: EDUAppWeb },
    { path: PAGE.GET_NEW_CARD_NUMBER, component: GetNewCardNumber },
    { path: PAGE.GET_PHYSICAL_CARD, component: GetPhysicalCard },
    { path: PAGE.OFFER, component: OfferPage },
    { path: PAGE.ONE_TIME_PAYMENT, component: OneTimePayment },
    { path: PAGE.PAYMENTS, component: Payments },
    { path: PAGE.PERSONAL_INFO, component: PersonalInfo },
    { path: PAGE.REPLACE_CARD, component: ReplaceCard },
    { path: PAGE.REWARDS, component: RedeemRewardsPage },
    { path: PAGE.UPDATE_LINKED_ACCOUNT, component: UpdateLinkedAccount },
    { path: PAGE.SECURITY, component: Security },
    { path: PAGE.STATEMENT_SETTINGS, component: StatementSettings },
    { path: PAGE.SUCCESS, component: Success },
];
