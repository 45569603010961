export var constructDate = function (date) {
    return new Date(date.year, date.month - 1, date.day);
};
export var formatDateToShortMonthDay = function (date, languageCode) {
    var options = {
        month: 'short',
        day: 'numeric',
    };
    return constructDate(date).toLocaleDateString(languageCode, options);
};
export var formatTagMonDD = function (startDate, endDate, languageCode) {
    var options = {
        month: 'numeric',
        day: 'numeric',
    };
    var startMonth = constructDate(startDate).toLocaleDateString(languageCode, options);
    var endMonth = constructDate(endDate).toLocaleDateString(languageCode, options);
    return "".concat(startMonth, " - ").concat(endMonth);
};
/**
 * Formats an epoch timestamp to a month and day string. If a timezone is provided,
 * the date will be formatted in that timezone. Otherwise, the date will be formatted in
 * the user's local timezone.
 */
export var formatEpochToMonthDD = function (epoch, languageCode, timeZone) {
    if (languageCode === void 0) { languageCode = 'en-US'; }
    var date = new Date(epoch * 1000);
    var options = { month: 'long', day: 'numeric' };
    if (timeZone) {
        options.timeZone = timeZone;
    }
    else {
        options.timeZone = 'America/New_York';
    }
    return date.toLocaleDateString(languageCode, options);
};
