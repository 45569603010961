var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useAppSelector } from '../../app/hooks';
import styles from './Dashboard.module.css';
import { ImprintConstants } from '../../api/apiConstants';
import downloadAppQRCodeGeneric from '../../assets/images/downloadAppQRCode_generic.svg';
export function DownloadAppQRImage() {
    var language = useAppSelector(function (state) { return state.languageSelector.languageLocalization; });
    function handleDownloadAppClick() {
        window.open('http://' + ImprintConstants.DownloadLinkDisplay);
    }
    return (_jsx("div", __assign({ className: styles.leading }, { children: _jsxs("div", __assign({ className: styles.qrInfoContainer }, { children: [_jsx("div", __assign({ className: styles.qrContainer }, { children: _jsx("img", { className: styles.qr, src: downloadAppQRCodeGeneric, tabIndex: 0, alt: "download app qr code" }) })), _jsxs("p", __assign({ className: styles.downloadSubline, tabIndex: 0 }, { children: [language.downloadSubline, ' ', _jsx("span", __assign({ className: styles.downloadLink, onClick: handleDownloadAppClick }, { children: ImprintConstants.DownloadLinkDisplay })), ' ', language.downloadSubline2] }))] })) })));
}
