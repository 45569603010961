import { ENV, setDeviceID } from '../../app/apiScreenSlice';
import { RequestType } from './httpConsts';
import { requestAssetUrlProd, requestAssetUrlStaging, } from '../../assets/consts/const';
// Get the base URL from the store
export var getBaseUrl = function (store, type) {
    switch (type) {
        case RequestType.EXTERNAL:
            return store.getState().apiScreenScreenSelector.env === ENV.PROD
                ? requestAssetUrlProd
                : requestAssetUrlStaging;
        case RequestType.INTERNAL:
        default:
            return store.getState().apiScreenScreenSelector.baseURL;
    }
};
// Get the device ID or generate one if need be
export function getDeviceID(state, dispatch) {
    var storedDeviceID = state.apiScreenScreenSelector.deviceID;
    if (storedDeviceID !== '' && storedDeviceID !== undefined) {
        return storedDeviceID;
    }
    // UUID created using current date (for uniqueness) + Math.random() (for more uniqueness)
    var newUUID = Date.now().toString(36) + Math.random().toString(36).substring(2);
    dispatch(setDeviceID(newUUID));
    return newUUID;
}
