var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useAppSelector } from '../../app/hooks';
import imprintLogo from '../../assets/icons/logo-imprint.svg';
import { PRODUCTUUID } from '../../utils/productUUIDConstants';
import styles from './Header.module.css';
export default function Header(_a) {
    var merchantName = _a.merchantName, brandImg = _a.brandImg;
    var merchantData = useAppSelector(function (state) { return state.merchantDataSelector; });
    var language = useAppSelector(function (state) { return state.languageSelector.languageLocalization; });
    function getImgDimensions(id) {
        if (id === PRODUCTUUID.WG) {
            return styles.westgateImg;
        }
        return styles.hhImg;
    }
    return (_jsx("div", __assign({ className: styles.header }, { children: _jsxs("div", __assign({ className: styles.headerMain }, { children: [_jsx("div", __assign({ className: styles.brandLogoContainer }, { children: _jsx("img", { draggable: "false", alt: "brand", className: getImgDimensions(merchantData.productUUID), src: brandImg }) })), _jsxs("h1", __assign({ className: styles.headerTitle }, { children: [merchantName, " ", language === null || language === void 0 ? void 0 : language.application] })), _jsx("div", __assign({ className: styles.imprintLogoContainer }, { children: _jsx("img", { draggable: "false", alt: "logo", className: styles.imprintLogo, src: imprintLogo }) }))] })) })));
}
