import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ImpContainer } from '@imprint-payments/imprint-ui';
import HeaderServicing from '../../components/HeaderServicing/HeaderServicing';
import Banking from './Banking';
import { selectNavigateAfterBankLinking } from '../../app/paymentsPageSelectors';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useEffect } from 'react';
import { setNavigateAfterBankLinking } from '../../app/paymentsPageSlice';
import { SCREEN, setPreviousScreen, setScreen, } from '../../app/screenStateSlice';
import { useNavigate } from 'react-router-dom';
import { PAGE } from '../../utils/routeConstant';
export function BankingPage() {
    var dispatch = useAppDispatch();
    var navigate = useNavigate();
    var shouldNavigateToPaymentsPage = useAppSelector(selectNavigateAfterBankLinking);
    var navigateToPaymentsPage = function () {
        dispatch(setNavigateAfterBankLinking(false));
        dispatch(setPreviousScreen(SCREEN.PAYMENTS));
        dispatch(setScreen(SCREEN.ONE_TIME_PAYMENT));
        navigate(PAGE.ONE_TIME_PAYMENT);
    };
    useEffect(function () {
        return function () { return dispatch(setNavigateAfterBankLinking(false)); };
    }, []);
    return (_jsxs(ImpContainer, { children: [_jsx(HeaderServicing, {}), _jsx(ImpContainer, { children: _jsx(Banking, { onSuccess: shouldNavigateToPaymentsPage ? navigateToPaymentsPage : undefined }) })] }));
}
