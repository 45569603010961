var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useDispatch } from 'react-redux';
import { EVENT } from '../../analytics/analyticsConsts';
import { useAppSelector } from '../../app/hooks';
import { setShowDisclaimer } from '../../app/languageSlice';
import { SCREEN, setScreen } from '../../app/screenStateSlice';
import { ANALYTICS } from '../../firebase/firebase';
import { LanguageCode } from '../../utils/languageConstants';
import { handleLanguageChangeConfirmed } from '../../utils/languagelocale';
import HeaderServicing, { HEADER_SERVICING_TYPE } from '../HeaderServicing/HeaderServicing';
import Button, { ButtonColorScheme } from '../button/Button';
import styles from './LanguageDisclaimer.module.css';
export default function LanguageDisclaimer(_a) {
    var page = _a.page, onCancel = _a.onCancel;
    var language = useAppSelector(function (state) { return state.languageSelector.languageLocalization; });
    var languageState = useAppSelector(function (state) { return state.languageSelector; });
    var dispatch = useDispatch();
    return (_jsxs("div", __assign({ className: styles.container }, { children: [_jsx(HeaderServicing, { type: HEADER_SERVICING_TYPE.GO_BACK, onGoBack: onCancel }), _jsxs("div", __assign({ className: styles.body }, { children: [_jsx("h1", __assign({ className: styles.header }, { children: language.changeLanguageHeader })), _jsx("p", __assign({ className: styles.bodyText }, { children: language.changeLanguageBody })), _jsxs("div", __assign({ className: styles.btnGroup }, { children: [_jsx(Button, { value: language.changeLanguageButton, handleClick: function () {
                                    handleLanguageChangeConfirmed(LanguageCode.Spanish, page);
                                }, colorScheme: ButtonColorScheme.fillImprint }), _jsx(Button, { value: language.cancelBtn, handleClick: onCancel ||
                                    (function () {
                                        ANALYTICS.logEvent(EVENT.LANGUAGE_CANCEL_CLICKED, {
                                            page: 'language_confirmation_modal_login',
                                        });
                                        dispatch(setScreen(SCREEN.LOGIN));
                                        dispatch(setShowDisclaimer(false));
                                    }), colorScheme: ButtonColorScheme.ghostImprint })] }))] }))] })));
}
