var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styles from './ActivatePhysicalCardSuccess.module.css';
import { navigateToAccountPage } from '../../utils/functionUtils';
import { useAppSelector } from '../../app/hooks';
import { ANALYTICS } from '../../firebase/firebase';
import { EVENT } from '../../analytics/analyticsConsts';
import { useEffect } from 'react';
import { ImpButton } from '@imprint-payments/imprint-ui';
import { selectMerchantData } from '../../app/merchantDataSelectors';
import { PRODUCTUUID } from '../../utils/productUUIDConstants';
import { isPostConversion } from '../../utils/conversionUtils';
export function ActivatePhysicalCardSuccess() {
    var navigate = useNavigate();
    var dispatch = useDispatch();
    var language = useAppSelector(function (state) { return state.languageSelector.languageLocalization; });
    // change subtitle if product is Brooks Brothers
    var merchantData = useAppSelector(selectMerchantData);
    // TODO: remove this logic after BB launch
    var subtitle = merchantData.productUUID === PRODUCTUUID.BB && !isPostConversion()
        ? language.useYourCardImmediatelyBB
        : language.useYourCardImmediately;
    useEffect(function () {
        ANALYTICS.logEvent(EVENT.ACTIVATE_PHYSICAL_CARD_SUCCESS_VIEWED);
    }, []);
    return (_jsxs("div", __assign({ className: styles.body }, { children: [_jsx("div", __assign({ className: styles.title }, { children: language.yourCardIsReady })), _jsx("div", __assign({ className: styles.subtitle }, { children: subtitle })), _jsx("div", __assign({ className: styles.buttonContainer }, { children: _jsx(ImpButton, __assign({ size: "fill", variant: "primary", buttonStyle: "branded", onClick: function () {
                        ANALYTICS.logEvent(EVENT.CARD_ACTIVATION_SUCCESS_DONE_CLICKED);
                        navigateToAccountPage(dispatch, navigate);
                    } }, { children: language.done })) }))] })));
}
