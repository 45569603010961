var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { ImpButton, ImpContainer, ImpFlex, ImpList, ImpListHeader, ImpPad, ImpSpacer, ImpSurface, ImpText, colors, } from '@imprint-payments/imprint-ui';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectLanguage, selectLanguageCode, } from '../../app/languageSelectors';
import { selectAutopay, selectUpcomingPaymentNoAutopayWidget, } from '../../app/productAccountSelectors';
import { useMemo } from 'react';
import { PaymentWidgetPaymentType, getUpcomingPaymentsList, parseStringToDisplayAmount, } from './UpcomingPaymentsUtils';
import { useNavigate } from 'react-router-dom';
import { SCREEN, setScreen } from '../../app/screenStateSlice';
import { PAGE } from '../../utils/routeConstant';
import { selectPrimaryPaymentAccount } from '../../app/homeSelectors';
import { PaymentAccountStatus } from '../dashboard/Dashboard';
import { PaymentPageStage, setPaymentsPage, setSelectedPayment, } from '../../app/paymentsPageSlice';
import { formatDisplayAmount } from '@imprint-payments/imprint-lib';
import { epochToMMDD } from '../dashboard/physicalCardWidgetHelpers';
import { generatePayByCheckUrl } from '../../utils/functionUtils';
export var useScheduledPayments = function () {
    var upcomingPaymentNoAutopayWidget = useAppSelector(selectUpcomingPaymentNoAutopayWidget);
    var primaryPaymentAccount = useAppSelector(selectPrimaryPaymentAccount);
    var autopay = useAppSelector(selectAutopay);
    var language = useAppSelector(selectLanguage);
    var languageCode = useAppSelector(selectLanguageCode);
    var dispatch = useAppDispatch();
    var navigate = useNavigate();
    var toReturn = [];
    toReturn = useMemo(function () {
        var upcomingPaymentsList = getUpcomingPaymentsList(upcomingPaymentNoAutopayWidget, autopay, language, languageCode);
        var listOfPayments = [];
        upcomingPaymentsList.map(function (payment) {
            var onClick = function () {
                if (primaryPaymentAccount.status !==
                    PaymentAccountStatus.Approved) {
                    dispatch(setScreen(SCREEN.BANKING));
                    navigate(PAGE.BANKING);
                }
                else {
                    if (payment.paymentType === PaymentWidgetPaymentType.Autopay) {
                        dispatch(setScreen(SCREEN.AUTOPAY));
                        navigate(PAGE.AUTOPAY);
                    }
                    else {
                        var _a = payment.params, transactionUUID = _a.transactionUUID, paymentAmount = _a.paymentAmount, scheduleDate = _a.scheduleDate, lastDateToModify = _a.lastDateToModify;
                        var selectedPayment = {
                            transactionUUID: transactionUUID,
                            value: formatDisplayAmount(parseStringToDisplayAmount(paymentAmount)),
                            dateTime: epochToMMDD(parseInt(scheduleDate)),
                            editTimeDeadlineToEpochSec: parseInt(lastDateToModify),
                        };
                        dispatch(setSelectedPayment(selectedPayment));
                        dispatch(setPaymentsPage(PaymentPageStage.PaymentDetails));
                        dispatch(setScreen(SCREEN.PAYMENTS));
                        navigate(PAGE.PAYMENTS);
                    }
                }
            };
            listOfPayments.push(__assign(__assign({}, payment), { onClick: onClick }));
        });
        return listOfPayments;
    }, [
        autopay,
        upcomingPaymentNoAutopayWidget,
        primaryPaymentAccount,
        language,
    ]);
    return toReturn;
};
export function UpcomingPayments() {
    var language = useAppSelector(selectLanguage);
    var merchantData = useAppSelector(function (state) { return state.merchantDataSelector; });
    var upcomingPaymentsList = useScheduledPayments();
    var languageState = useAppSelector(function (state) { return state.languageSelector; });
    var productAccount = useAppSelector(function (state) { return state.productAccountSelector; });
    return (_jsx(ImpContainer, __assign({ width: "100%" }, { children: _jsxs(ImpSurface, __assign({ hasBorderRadius: true }, { children: [_jsx(ImpListHeader, { title: language.upcomingPayments, subtitle: language.accountNumber + productAccount.accountCode }), (upcomingPaymentsList === null || upcomingPaymentsList === void 0 ? void 0 : upcomingPaymentsList.length) > 0 ? (_jsxs(_Fragment, { children: [_jsx(ImpPad, __assign({ padding: "16px 16px 0px" }, { children: _jsx(ImpText, __assign({ color: colors.content.onSurfaceVariant, typography: "body3" }, { children: language.upcomingPaymentsSubtitle })) })), _jsx(ImpList, { items: upcomingPaymentsList })] })) : (_jsx(ImpPad, __assign({ fluid: true, padding: "32px" }, { children: _jsx(ImpContainer, __assign({ textAlign: "center" }, { children: _jsxs(ImpFlex, __assign({ justify: "center", align: "center", direction: "column" }, { children: [_jsx(ImpText, __assign({ typography: "body1", color: colors.content.onSurfaceVariant }, { children: language.noUpcomingPaymentsLabel })), _jsx(ImpSpacer, { height: "m" }), _jsx(ImpText, __assign({ typography: "body2", color: colors.content.onSurfaceVariant }, { children: language.learnMoreWaysToPayLabel })), _jsx(ImpSpacer, { height: "xl" }), _jsx(ImpButton, __assign({ onClick: function () {
                                        var link = generatePayByCheckUrl(merchantData.merchantName, languageState.languageCode);
                                        window.open(link, '_blank');
                                    }, variant: "text", buttonStyle: "branded", size: "hug", linkOut: true }, { children: language.moreWaysToPayLabel }))] })) })) })))] })) })));
}
