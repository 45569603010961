var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Icons, ImpIcon, ImpToggle } from '@imprint-payments/imprint-ui';
import { AuthUsersPage, setAuthUsersPage, setSelectedAuthorizedUser, } from '../../app/authUsersSlice';
import { store } from '../../app/store';
import { getAddressText } from '../../types/Address';
import { DisplayState } from '../../types/DisplayState';
import { PAGE } from '../../utils/routeConstant';
export var userHasActivatedPhysicalCard = function (last4) {
    return last4 !== '' && last4 !== undefined;
};
export var getAuthUserSubtitle = function (authUser) {
    var language = store.getState().languageSelector.languageLocalization;
    if (authUser.displayState === DisplayState.DisplayStateNewApplicationWithFullSSN) {
        return language.incompleteApplicationSubtitle;
    }
    else if (authUser.displayState === DisplayState.DisplayStateGetApplicationEvaluation) {
        return language.pendingApplicationSubtitle;
    }
    else if (userHasActivatedPhysicalCard(authUser.last4)) {
        return "(\u2022\u2022\u2022\u2022 ".concat(authUser.last4, ")");
    }
    return language.cardNotActivated;
};
export var getAuthUserOnClick = function (authUser, setPopupInfo, onIncomplete, onPending) {
    if (authUser.displayState === DisplayState.DisplayStateNewApplicationWithFullSSN) {
        return function () {
            setPopupInfo({
                authApplicationUUID: authUser.authApplicationUUID || 'unset',
                name: {
                    firstName: authUser.firstName,
                    lastName: authUser.lastName,
                },
            });
            onIncomplete();
        };
    }
    else if (authUser.displayState === 'GetApplicationEvaluation') {
        return function () {
            onPending();
        };
    }
    return function () {
        store.dispatch(setSelectedAuthorizedUser(authUser));
    };
};
export var generateUserOptions = function (dispatch, authUsersState, language, handleActivatePhysicalCardClick, handleLockPhysicalCardClick) {
    var options = [
        {
            title: language.personalInfoName,
            subtitle: authUsersState.selectedAuthorizedUser.firstName +
                ' ' +
                authUsersState.selectedAuthorizedUser.lastName,
            leadingIcon: _jsx(ImpIcon, { iconSrc: Icons.user, stroke: "var(--merchant-color)" }),
        },
        {
            title: language.personalInfoAddress,
            subtitle: getAddressText(authUsersState.selectedAuthorizedUser.address),
            leadingIcon: _jsx(ImpIcon, { iconSrc: Icons.house, stroke: "var(--merchant-color)" }),
            onClick: function () {
                dispatch(setAuthUsersPage(AuthUsersPage.VERIFY_PIN_ADDRESS));
            },
            trailingContent: _jsx(ImpIcon, { iconSrc: Icons.chevronRight }),
        },
        {
            title: language.replaceCard,
            leadingIcon: _jsx(ImpIcon, { iconSrc: Icons.repeat, stroke: "var(--merchant-color)" }),
            onClick: function () {
                dispatch(setAuthUsersPage(AuthUsersPage.REPLACE_CARD));
            },
            trailingContent: _jsx(ImpIcon, { iconSrc: Icons.chevronRight }),
            isDisabled: authUsersState.selectedAuthorizedUser.cardUUID === '',
        },
        {
            title: language.deactivateUser,
            leadingIcon: _jsx(ImpIcon, { iconSrc: Icons.userMinus, stroke: "var(--merchant-color)" }),
            onClick: function () {
                dispatch(setAuthUsersPage(AuthUsersPage.DEACTIVATE_USER));
            },
            trailingContent: _jsx(ImpIcon, { iconSrc: Icons.chevronRight }),
        },
    ];
    if (!userHasActivatedPhysicalCard(authUsersState.selectedAuthorizedUser.last4)) {
        options.splice(2, 0, {
            title: language.activatePhysicalCard,
            leadingIcon: _jsx(ImpIcon, { iconSrc: Icons.card, stroke: "var(--merchant-color)" }),
            onClick: handleActivatePhysicalCardClick,
            trailingContent: _jsx(ImpIcon, { iconSrc: Icons.chevronRight }),
            isDisabled: authUsersState.selectedAuthorizedUser.cardUUID === '',
        });
    }
    else {
        options.splice(2, 0, {
            title: language.lockPhysicalCard,
            leadingIcon: _jsx(ImpIcon, { iconSrc: Icons.lock, stroke: "var(--merchant-color)" }),
            trailingContent: (_jsx(ImpToggle, { checked: authUsersState.selectedAuthorizedUser.cardStatus === 'INACTIVE' })),
            onClick: handleLockPhysicalCardClick,
            isDisabled: authUsersState.selectedAuthorizedUser.cardUUID === '',
        });
    }
    return options;
};
export var generateUsersList = function (productAccount, dispatch, navigate, language, setPopupInfo, openModal, openModalIncompleteApp) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    var usersInfo = __spreadArray([], (((_a = productAccount.authUserDetails) === null || _a === void 0 ? void 0 : _a.authorizedUsers) || []), true).sort(function (a, b) {
        var firstNameComparison = a.firstName.localeCompare(b.firstName);
        if (firstNameComparison === 0) {
            return a.authorizedUserUUID.localeCompare(b.authorizedUserUUID);
        }
        return firstNameComparison;
    })
        .map(function (authUser) {
        return {
            title: authUser.firstName + ' ' + authUser.lastName,
            subtitle: getAuthUserSubtitle(authUser),
            onClick: function () {
                dispatch(setSelectedAuthorizedUser(authUser));
            },
        };
    });
    if ((_c = (_b = productAccount.authUserDetails) === null || _b === void 0 ? void 0 : _b.pendingAuthorizedUsers) === null || _c === void 0 ? void 0 : _c.length) {
        // push each pending auth user
        productAccount.authUserDetails.pendingAuthorizedUsers.forEach(function (authUser) {
            usersInfo.push({
                title: authUser.firstName + ' ' + authUser.lastName,
                subtitle: getAuthUserSubtitle(authUser),
                onClick: getAuthUserOnClick(authUser, setPopupInfo, openModalIncompleteApp, openModal),
            });
        });
    }
    var numAuthUsers = ((_e = (_d = productAccount.authUserDetails) === null || _d === void 0 ? void 0 : _d.authorizedUsers) === null || _e === void 0 ? void 0 : _e.length) || 0;
    var numPendingAuthUsers = ((_g = (_f = productAccount.authUserDetails) === null || _f === void 0 ? void 0 : _f.pendingAuthorizedUsers) === null || _g === void 0 ? void 0 : _g.length) || 0;
    var maxNumAuthUsers = ((_h = productAccount.authUserDetails) === null || _h === void 0 ? void 0 : _h.limitOfAuthorizedUsers) || 0;
    if (numAuthUsers + numPendingAuthUsers < maxNumAuthUsers) {
        usersInfo.push({
            title: language.addAuthorizedUser,
            onClick: function () {
                if (numPendingAuthUsers === 0) {
                    navigate(PAGE.ADD_AUTHORIZED_USER);
                }
            },
            isDisabled: numPendingAuthUsers > 0,
        });
    }
    return usersInfo;
};
