var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useNavigate } from 'react-router-dom';
import { navigateToAccountPage } from '../../utils/functionUtils';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { ANALYTICS } from '../../firebase/firebase';
import { EVENT } from '../../analytics/analyticsConsts';
import { useEffect } from 'react';
import HeaderServicing from '../../components/HeaderServicing/HeaderServicing';
import { SUCCESS_TYPE } from '../../app/homeSlice';
import { PAGE } from '../../utils/routeConstant';
import { SCREEN, setScreen } from '../../app/screenStateSlice';
import { CardAddressConfirmation } from '../../components/CardDetails/ConfirmAddress';
import { CardActionReason } from '../../api/CardMenu/cardRequestHelper';
import { ImpBreadcrumbs, ImpContainer, ImpFlex, ImpSpacer } from '@imprint-payments/imprint-ui';
export function GetPhysicalCard() {
    var navigate = useNavigate();
    var dispatch = useAppDispatch();
    var homeSelector = useAppSelector(function (state) { return state.homeSelector; });
    var productAccount = useAppSelector(function (state) { return state.productAccountSelector; });
    var showGetPhysicalCard = !productAccount.physicalCard &&
        productAccount.productDetails.physicalCardOptionAvailable;
    useEffect(function () {
        // return to home page if the user has a shipped card
        if (!showGetPhysicalCard) {
            navigateToAccountPage(dispatch, navigate);
        }
        ANALYTICS.logEvent(EVENT.GET_PHYSICAL_CARD_VIEWED);
    }, []);
    useEffect(function () {
        if (homeSelector.successType !== SUCCESS_TYPE.default) {
            dispatch(setScreen(SCREEN.SUCCESS));
            navigate(PAGE.SUCCESS);
        }
    }, [homeSelector.successType]);
    return (_jsxs(_Fragment, { children: [_jsx(HeaderServicing, {}), _jsx(ImpBreadcrumbs, { breadcrumbs: [], onBackClick: function () {
                    navigateToAccountPage(dispatch, navigate);
                }, onCancelClick: function () { return navigateToAccountPage(dispatch, navigate); } }), _jsx(ImpSpacer, { height: '2xl' }), _jsx(ImpFlex, __assign({ direction: "column", align: "center", fluid: true }, { children: _jsx(ImpContainer, __assign({ width: "min(748px, 100vw - 32px)" }, { children: _jsx(CardAddressConfirmation, { reason: CardActionReason.USER_REQUESTED, successType: SUCCESS_TYPE.cardRequested }) })) }))] }));
}
