var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ImpBanner, ImpBreadcrumbs, ImpContainer, ImpFlex, ImpImage, ImpSpacer, ImpText, MaxWidth, borderRadius, colors, } from '@imprint-payments/imprint-ui';
import { useNavigate } from 'react-router-dom';
import { EVENT } from '../../analytics/analyticsConsts';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectLanguage } from '../../app/languageSelectors';
import { selectMerchantData } from '../../app/merchantDataSelectors';
import { SCREEN, setScreen } from '../../app/screenStateSlice';
import { CONTAINER_WIDTH } from '../../assets/consts/const';
import HeaderServicing, { HEADER_SERVICING_TYPE, } from '../../components/HeaderServicing/HeaderServicing';
import LoginPage from '../../components/loginPage/LoginPage';
import { ANALYTICS } from '../../firebase/firebase';
import { clearServiceLoginState } from '../../utils/functionUtils';
import { PAGE } from '../../utils/routeConstant';
import { MaintenanceIndicator } from './MaintenanceIndicator';
var CONVERSION_BANNER_COLOR = '#001F37';
export function LoginPageRender(_a) {
    var screenStatus = _a.screenStatus, showBanner = _a.showBanner;
    var isMobile = screenStatus === 'mobile';
    var isTablet = screenStatus === 'tablet';
    return (_jsxs(_Fragment, { children: [!isMobile && _jsx(HeaderServicing, { type: HEADER_SERVICING_TYPE.LOGIN }), _jsx(ImpSpacer, { height: "2xl", mobileHeight: "0px" }), _jsx(ImpFlex, __assign({ fluid: true, direction: "column", align: "center" }, { children: _jsxs(ImpContainer, __assign({ width: "min(".concat(isTablet ? MaxWidth.Button : '800px', ", 100% - 48px)"), mobileWidth: "100%" }, { children: [showBanner ? _jsx(ConversionBanner, { isMobile: isMobile }) : null, _jsx(ImpSpacer, { height: "l" }), _jsx(MaintenanceIndicator, {}), _jsx(LoginPage, {})] })) }))] }));
}
export function ConversionBanner(_a) {
    var isMobile = _a.isMobile;
    var merchantData = useAppSelector(selectMerchantData);
    var language = useAppSelector(selectLanguage);
    var navigate = useNavigate();
    var handleConversionBannerClick = function () {
        ANALYTICS.logEvent(EVENT.CONVERSION_BANNER_CLICKED, {
            product_uuid: merchantData.productUUID,
        });
        navigate(PAGE.FIND_USER_ACCOUNT);
    };
    return (_jsxs(ImpBanner, __assign({ actionType: "navigate", borderRadius: isMobile ? undefined : borderRadius.m, onBannerClick: handleConversionBannerClick, color: CONVERSION_BANNER_COLOR }, { children: [_jsx(ImpImage, { src: merchantData.logoTile, alt: "logo", width: "44px", height: "44px", borderRadius: borderRadius.s }), _jsx(ImpText, __assign({ typography: "body1", color: colors.content.onBackgroundInverse }, { children: language.loginConversionBanner }))] })));
}
export function NonLoginPage() {
    var featureFlags = useAppSelector(function (state) { return state.featureFlagSelector.featureFlags; });
    var isPinLockOutEnabled = featureFlags.pinLockOutEnabled === 'true';
    var failedPIN = useAppSelector(function (state) { return state.homeSelector.homeFlow.failedPIN; });
    var dispatch = useAppDispatch();
    var navigate = useNavigate();
    var handleCancelClick = function () {
        clearServiceLoginState(dispatch);
        dispatch(setScreen(SCREEN.LOGIN));
        navigate(PAGE.LOGIN);
    };
    return (_jsxs(_Fragment, { children: [_jsx(HeaderServicing, { type: HEADER_SERVICING_TYPE.LOGIN }), isPinLockOutEnabled && failedPIN && (_jsx(ImpBreadcrumbs, { breadcrumbs: [''], onCancelClick: handleCancelClick })), _jsx(ImpFlex, __assign({ direction: "row", fluid: true, justify: "center" }, { children: _jsx(ImpContainer, __assign({ width: CONTAINER_WIDTH }, { children: _jsx(LoginPage, {}) })) }))] }));
}
