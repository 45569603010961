export var PrimaryAction;
(function (PrimaryAction) {
    PrimaryAction["MENU"] = "MENU";
    PrimaryAction["RETROACTIVE"] = "RETROACTIVE";
    PrimaryAction["CASHBACK"] = "CASHBACK";
    PrimaryAction["PARTNER_MEMBERSHIP"] = "PARTNER_MEMBERSHIP";
})(PrimaryAction || (PrimaryAction = {}));
export var InfoAction;
(function (InfoAction) {
    InfoAction["REWARDS_DETAILS"] = "REWARDS_DETAILS";
})(InfoAction || (InfoAction = {}));
export var PartnerMembershipStatus;
(function (PartnerMembershipStatus) {
    PartnerMembershipStatus["ACTIVE"] = "ACTIVE";
    PartnerMembershipStatus["UNLINKED"] = "UNLINKED";
    PartnerMembershipStatus["PENDING"] = "PENDING";
    PartnerMembershipStatus["PENDING_CONFIRMATION_ACTIVE"] = "PENDING_CONFIRMATION_ACTIVE";
    PartnerMembershipStatus["PENDING_CONFIRMATION_MERGED"] = "PENDING_CONFIRMATION_MERGED";
    PartnerMembershipStatus["INVALID"] = "INVALID";
    PartnerMembershipStatus["CLOSED"] = "CLOSED";
})(PartnerMembershipStatus || (PartnerMembershipStatus = {}));
