var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { EVENT } from '../../analytics/analyticsConsts';
import { getOfferDetails } from '../../api/api';
import { loadHome } from '../../api/loadHome.api';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import HeaderServicing, { HEADER_SERVICING_TYPE, } from '../../components/HeaderServicing/HeaderServicing';
import Spinner from '../../components/spinner/Spinner';
import { ANALYTICS } from '../../firebase/firebase';
import { DownloadAppQRImage } from '../dashboard/DownloadAppQRImage';
import styles from './DownloadApp.module.css';
export default function DownloadApp() {
    var dispatch = useAppDispatch();
    var navigate = useNavigate();
    var language = useAppSelector(function (state) { return state.languageSelector.languageLocalization; });
    var consumerInfo = useAppSelector(function (state) { return state.consumerInfoSelector; });
    var homeSelector = useAppSelector(function (state) { return state.homeSelector; });
    var _a = useState(true), isLoading = _a[0], setIsLoading = _a[1];
    useEffect(function () {
        if (consumerInfo.notAcceptedOffer) {
            dispatch(getOfferDetails({
                applicationUUID: consumerInfo.actions[0].params.applicationUUID,
            }));
        }
    }, [consumerInfo.actions]);
    useEffect(function () {
        dispatch(loadHome()).then(function () {
            if (consumerInfo.notAcceptedOffer) {
                ANALYTICS.logEvent(EVENT.DOWNLOAD_APP_ACCEPT_OFFER_VIEWED);
            }
            else {
                ANALYTICS.logEvent(EVENT.DOWNLOAD_APP_SPEND_CARD_VIEWED);
            }
        });
    }, []);
    useEffect(function () {
        if (language && consumerInfo.notAcceptedOffer === false) {
            setIsLoading(false);
        }
    }, [language, consumerInfo.notAcceptedOffer]);
    if (isLoading) {
        return (_jsxs("div", __assign({ className: styles.downloadAppContainer }, { children: [_jsx(HeaderServicing, {}), _jsx("div", __assign({ className: styles.downloadAppBody }, { children: _jsx(Spinner, {}) }))] })));
    }
    return (_jsxs("div", __assign({ className: styles.downloadAppContainer }, { children: [_jsx(HeaderServicing, { type: HEADER_SERVICING_TYPE.LOGOUT }), _jsx("div", __assign({ className: styles.downloadAppBody }, { children: _jsxs("div", __assign({ className: styles.splitMiddle }, { children: [_jsx(DownloadAppQRImage, {}), _jsxs("div", __assign({ className: styles.trailing }, { children: [_jsx("h1", __assign({ className: styles.title }, { children: consumerInfo.notAcceptedOffer
                                        ? language.notAcceptedOfferHeader
                                        : language.downloadAppHeader })), _jsx("p", __assign({ className: styles.bodyText }, { children: consumerInfo.notAcceptedOffer
                                        ? language.notAcceptedOfferBody + homeSelector.application.decision.expiresAt + '.'
                                        : language.downloadAppBody }))] }))] })) }))] })));
}
