var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ImpButton, ImpContainer, ImpFlex, ImpPad, ImpText, } from '@imprint-payments/imprint-ui';
import { useAppSelector } from '../../../app/hooks';
import { useEffect } from 'react';
import { ANALYTICS } from '../../../firebase/firebase';
import { EVENT } from '../../../analytics/analyticsConsts';
export function IncompleteApplicationModal(_a) {
    var closeModal = _a.closeModal, onPrimaryClick = _a.onPrimaryClick, onSecondaryClick = _a.onSecondaryClick, isLoading = _a.isLoading;
    var language = useAppSelector(function (state) { return state.languageSelector.languageLocalization; });
    useEffect(function () {
        ANALYTICS.logEvent(EVENT.AUTH_USER_INCOMPLETE_VIEWED);
    }, []);
    return (_jsx(ImpPad, __assign({ fluid: true, padding: "0px 32px 32px" }, { children: _jsxs(ImpFlex, __assign({ fluid: true, direction: "column", align: "center", justify: "space-between", gap: "3xl" }, { children: [_jsx(ImpText, __assign({ typography: "headline3" }, { children: language.authPendingAppTitle })), _jsx(ImpContainer, __assign({ width: "min(100%, 356px)", height: "100%" }, { children: _jsxs(ImpFlex, __assign({ fluid: true, direction: "column", align: "center", gap: "m" }, { children: [_jsx(ImpButton, __assign({ size: "fill", onClick: onPrimaryClick, state: isLoading ? 'disabled' : 'enabled' }, { children: language.authIncompleteAppContinue })), _jsx(ImpButton, __assign({ size: "fill", variant: "text", onClick: onSecondaryClick, state: isLoading ? 'submitting' : 'enabled' }, { children: language.authIncompleteAppDiscard }))] })) }))] })) })));
}
