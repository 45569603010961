import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { ImpConfirmAddressPage, ImpSpacer } from '@imprint-payments/imprint-ui';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { EVENT } from '../../analytics/analyticsConsts';
import { requestNewPhysicalCard } from '../../api/CardMenu/cardRequestHelper';
import { loadHomeAcceptedOffer } from '../../api/api';
import { setConfirmAddressLater } from '../../app/acceptScreenSlice';
import { selectAuth } from '../../app/authSelectors';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { ANALYTICS } from '../../firebase/firebase';
import CardDesignSelectionFlow from '../../pages/cardDesign/CardDesign';
import { navigateToAccountPage } from '../../utils/functionUtils';
import { PAGE } from '../../utils/routeConstant';
import Modal from '../modal/Modal';
export default function CardLater() {
    var dispatch = useAppDispatch();
    var navigate = useNavigate();
    var language = useAppSelector(function (state) { return state.languageSelector.languageLocalization; });
    var acceptScreenState = useAppSelector(function (state) { return state.acceptScreenSelector; });
    var apiScreenState = useAppSelector(function (state) { return state.apiScreenScreenSelector; });
    var homeSelector = useAppSelector(function (state) { return state.homeSelector; });
    var consumerInfo = useAppSelector(function (state) { return state.consumerInfoSelector; });
    var merchantData = useAppSelector(function (state) { return state.merchantDataSelector; });
    var auth = useAppSelector(selectAuth);
    var applicationStatus = useAppSelector(function (state) { return state.applicationStatusSelector; });
    // navigate back to home page if the user forcifully land on this page
    useEffect(function () {
        if (consumerInfo.consumerUUID !== '' && merchantData.productUUID === '') {
            navigateToAccountPage(dispatch, navigate);
        }
    }, []);
    var handleConfirmAddress = function () {
        ANALYTICS.logEvent(EVENT.CONFIRM_ADDRESS_CONFIRM_CLICKED);
        dispatch(requestNewPhysicalCard({
            token: auth.token,
            productAccountUUID: applicationStatus.productAccountUUID,
            deviceID: apiScreenState.deviceID,
        })).then(function () {
            dispatch(loadHomeAcceptedOffer());
            navigate(PAGE.HOME);
        });
    };
    var handleNotNow = function () {
        ANALYTICS.logEvent(EVENT.CONFIRM_ADDRESS_NOT_NOW_CLICKED);
        dispatch(setConfirmAddressLater(true));
    };
    var handleSoundsGood = function () {
        ANALYTICS.logEvent(EVENT.CONFIRM_ADDRESS_LATER_SOUNDS_GOOD_CLICKED);
        dispatch(setConfirmAddressLater(false));
        dispatch(loadHomeAcceptedOffer());
        navigate(PAGE.HOME);
    };
    function getAddress() {
        var address = homeSelector.userInformation.address;
        return {
            line1: address.streetLine1,
            line2: address.streetLine2,
            line3: "".concat(address.city, ", ").concat(address.state, " ").concat(address.zipCode),
        };
    }
    return (_jsx(_Fragment, { children: acceptScreenState.confirmLater ? (_jsx(Modal, { header: language.cardLater, body1: language.requestCardLater, buttonName1: language.soundsGood, pillBtn1: true, button1Click: handleSoundsGood })) : acceptScreenState.cardDesignSelection ? (_jsx(CardDesignSelectionFlow, {})) : (_jsxs(_Fragment, { children: [_jsx(ImpSpacer, { height: "2xl", mobileHeight: "xl" }), _jsx(ImpConfirmAddressPage, { pageState: 'enabled', displayImage: merchantData.cardOfferImg, title: language.acceptedTitle, subtitle: language.shipToNewAddress, displayText: getAddress(), primaryActionButtonInfo: {
                        text: language.confirmAddressBtn,
                        onClick: handleConfirmAddress,
                    }, secondaryActionButtonInfo: {
                        text: language.notNowBtn,
                        onClick: handleNotNow,
                    } })] })) }));
}
