export var getEmptyProductDetails = function () {
    return ({
        stripePublicKey: '',
        merchantName: '',
        cardName: '',
        productUUID: '',
        activatedAccount: false,
        physicalCardOptionAvailable: false,
        merchantURL: '',
    });
};
