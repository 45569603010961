var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ImpBreadcrumbs, ImpButton, ImpFlex, ImpFlexItem, ImpSpacer, ImpText, colors, } from '@imprint-payments/imprint-ui';
import { useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import { useNavigate } from 'react-router-dom';
import { EVENT } from '../../analytics/analyticsConsts';
import { ImprintConstants } from '../../api/apiConstants';
import { loadHome } from '../../api/loadHome.api';
import { submitPayment } from '../../api/payment/submitPayment';
import { MODAL_ENUM, setShowModal } from '../../app/applicationScreenSlice';
import { selectPaymentsEnhancementsFlag } from '../../app/featureFlagSelectors';
import { SUCCESS_TYPE, setStartedBankLinking, setSuccessInformation, setSuccessType, } from '../../app/homeSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { SCREEN, setScreen } from '../../app/screenStateSlice';
import { paymentMaxInputLength } from '../../assets/consts/const';
import Button, { ButtonColorScheme } from '../../components/button/Button';
import HeaderServicing from '../../components/HeaderServicing/HeaderServicing';
import { ITEM_STYLE, LIST_STYLE, ListSection } from '../../components/list/List';
import ModalDefinitionText from '../../components/ModalDefinitionText/ModalDefinitionText';
import PaymentDefinitionModal from '../../components/popup/PaymentDefinitionModal';
import Spinner from '../../components/spinner/Spinner';
import { ANALYTICS } from '../../firebase/firebase';
import { convertAmountToNumber, convertAmountToString, currencyToNumber, formatDollarStringToString, } from '../../utils/formatUtils';
import { checkBankAccountBroken, displayTemporarySnackbar, getLanguageFromCookie, getNextPaymentDueDate, navigateToAccountPage, } from '../../utils/functionUtils';
import { LanguageCode } from '../../utils/languageConstants';
import { PAGE } from '../../utils/routeConstant';
import { AreYouSureContent } from '../payments/AreYouSurePage';
import { useScheduledPayments } from '../payments/UpcomingPayments';
import '../style/PaymentCalendar.css';
import styles from './OneTimePayment.module.css';
function NoPaymentDuePage() {
    var navigate = useNavigate();
    var dispatch = useAppDispatch();
    var language = useAppSelector(function (state) { return state.languageSelector.languageLocalization; });
    useEffect(function () {
        ANALYTICS.logEvent(EVENT.NO_PAYMENT_DUE_VIEWED);
    }, []);
    return (_jsxs("div", __assign({ className: styles.noPaymentDueContainer }, { children: [_jsx("div", __assign({ className: styles.noPaymentDueHeader }, { children: language.noPaymentDue })), _jsxs("div", __assign({ className: styles.noPaymentDueSubheader }, { children: [language.noPaymentDueSubheader1, _jsx("br", {}), language.noPaymentDueSubheader2] })), _jsx("div", __assign({ className: styles.buttonContainer }, { children: _jsx(Button, { value: language.backToHome, colorScheme: ButtonColorScheme.fillBranded, handleClick: function () {
                        navigateToAccountPage(dispatch, navigate);
                        ANALYTICS.logEvent(EVENT.NO_PAYMENT_DUE_BACK_TO_HOME_CLICKED);
                    } }) }))] })));
}
export default function OneTimePayment() {
    var _a, _b;
    var language = useAppSelector(function (state) { return state.languageSelector.languageLocalization; });
    var selectedLanguage = useAppSelector(function (state) { return state.languageSelector.languageCode; });
    var navigate = useNavigate();
    var dispatch = useAppDispatch();
    var appScreenState = useAppSelector(function (state) { return state.applicationScreenSelector; });
    var screenState = useAppSelector(function (state) { return state.screenStateSelector; });
    var productAccount = useAppSelector(function (state) { return state.productAccountSelector; });
    var homeSelector = useAppSelector(function (state) { return state.homeSelector; });
    var selectedProductAccountCreditLineUUID = (_b = (_a = homeSelector.selectedProductAccount) === null || _a === void 0 ? void 0 : _a.creditLineUUID) !== null && _b !== void 0 ? _b : '';
    // Return to home if invalid data is passed in.
    if (selectedProductAccountCreditLineUUID === '') {
        navigate(PAGE.HOME);
    }
    var paymentRequirements = homeSelector.paymentRequirements[selectedProductAccountCreditLineUUID];
    var primaryPaymentAccount = useAppSelector(function (state) { return state.homeSelector.primaryPaymentAccount; });
    var consumerInfo = useAppSelector(function (state) { return state.consumerInfoSelector; });
    var _c = useState(convertAmountToString(paymentRequirements.statement)), selectedValue = _c[0], setSelectedValue = _c[1];
    var nextPaymentDueDate = getNextPaymentDueDate(productAccount, selectedLanguage);
    var todayDate = new Date();
    var minScheduleDate = new Date();
    minScheduleDate.setDate(todayDate.getDate() + 1);
    var maxScheduleDate = new Date(0);
    maxScheduleDate.setUTCSeconds(paymentRequirements.maxScheduleDateEpochSec);
    var _d = useState(false), paymentSelected = _d[0], setPaymentSelected = _d[1];
    var _e = useState(false), isPaymentValid = _e[0], setIsPaymentValid = _e[1];
    var scheduledPaymentsList = useScheduledPayments();
    // We will set payment date by the date on calendar once the confirmation button is clicked.
    var _f = useState(new Date()), paymentDate = _f[0], setPaymentDate = _f[1];
    var paymentDateConfirmationSubtitleFormatted = paymentDate.toLocaleDateString(getLanguageFromCookie(), {
        month: 'numeric',
        day: 'numeric',
    });
    var sendScheduledPayment = !(paymentDate.getMonth() == todayDate.getMonth() && paymentDate.getDate() == todayDate.getDate());
    var _g = useState(false), inPaymentDateSelection = _g[0], setInPaymentDateSelection = _g[1];
    var _h = useState(minScheduleDate), calendarDate = _h[0], setCalendarDate = _h[1];
    var paymentDateCalendarSubheaderFormatted = calendarDate.toLocaleDateString(getLanguageFromCookie(), { month: 'short', day: 'numeric' });
    var _j = useState(true), isLoading = _j[0], setIsLoading = _j[1];
    var _k = useState(false), isSubmitting = _k[0], setIsSubmitting = _k[1];
    var _l = useState(''), paymentMethodName = _l[0], setPaymentMethodName = _l[1];
    var paymentsEnhancementsEnabled = useAppSelector(selectPaymentsEnhancementsFlag);
    var _m = useState(false), duplicatePaymentsConfirmation = _m[0], setDuplicatePaymentsConfirmation = _m[1];
    useEffect(function () {
        if (scheduledPaymentsList.length > 0) {
            setDuplicatePaymentsConfirmation(true);
        }
    }, [scheduledPaymentsList]);
    useEffect(function () {
        if (checkBankAccountBroken(primaryPaymentAccount)) {
            dispatch(setStartedBankLinking(true));
            dispatch(setScreen(SCREEN.BANKING));
            navigate(PAGE.BANKING);
        }
    }, [primaryPaymentAccount.status]);
    var getContent = function () {
        var content = {
            headerValue: selectedValue,
            definition: '',
            appNotice1: '',
            appNotice2: '',
            leftBtnValue: language.payLater,
            rightBtnValue: language.payNow,
        };
        if (!inPaymentDateSelection && paymentSelected) {
            content = {
                headerValue: language.submitYourPayment,
                definition: '',
                appNotice1: language.disclosureAgree1,
                appNotice2: language.disclosureAgree2,
                leftBtnValue: language.editPayment,
                rightBtnValue: language.submitPayment,
            };
        }
        return content;
    };
    // leftBtnClick will handle the "Pay later" and "Edit payment" function call.
    var leftBtnClick = function () {
        if (paymentSelected) {
            // activate "Edit payment" action
            setPaymentSelected(false);
        }
        else {
            // activate "Pay later" action
            setInPaymentDateSelection(true);
            ANALYTICS.logEvent(EVENT.ONE_TIME_PAYMENT_PAY_LATER_CLICKED);
            // Scheduled payment's minimum date is the next day.
            setCalendarDate(minScheduleDate);
            setPaymentSelected(true);
        }
    };
    var onSuccess = function (response) {
        setIsSubmitting(false);
        if (response.editTimeToEpochSec) {
            dispatch(setSuccessType(sendScheduledPayment ? SUCCESS_TYPE.paymentSchedule : SUCCESS_TYPE.paymentPayNow));
        }
    };
    var onError = function () {
        setIsSubmitting(false);
        displayTemporarySnackbar(language.somethingWentWrong, dispatch);
    };
    // rightBtnClick will handle the "Pay now" and "Confirm" function call.
    var rightBtnClick = function () {
        var paymentValue = formatDollarStringToString(selectedValue);
        if (paymentSelected) {
            // activate "Confirm" action
            setIsSubmitting(true);
            ANALYTICS.logEvent(EVENT.ONE_TIME_PAYMENT_CONFIRM_CLICKED, {
                payment_amount: paymentMethodName,
                payment_value: paymentValue,
            });
            submitPayment({
                amount: parseFloat(paymentValue),
                creditLineUUID: selectedProductAccountCreditLineUUID,
                currency: 'USD',
                // We send valid scheduled date in the request for scheduled payment,
                // and send null scheduled date for paying now.
                scheduledDateEpochSec: sendScheduledPayment
                    ? Math.round(paymentDate.getTime() / 1000)
                    : null,
                paymentAccountUUID: primaryPaymentAccount.paymentAccountUUID,
                productType: 'CREDITCARD',
                type: 'payment',
                onSuccess: onSuccess,
                onError: onError,
            });
        }
        else {
            // activate "Pay now" action
            ANALYTICS.logEvent(EVENT.ONE_TIME_PAYMENT_PAY_NOW_CLICKED, {
                payment_amount: paymentMethodName,
                payment_value: paymentValue,
            });
            // Pay now will reset the payment date to today.
            setPaymentDate(new Date());
            setPaymentSelected(true);
        }
    };
    useEffect(function () {
        dispatch(loadHome());
    }, []);
    useEffect(function () {
        if (paymentSelected) {
            ANALYTICS.logEvent(EVENT.ONE_TIME_PAYMENT_CONFIRMATION_VIEWED);
        }
        else {
            ANALYTICS.logEvent(EVENT.ONE_TIME_PAYMENT_VIEWED);
        }
        // Redirect to download app page if user only has spend cards
        if (consumerInfo.hasSpendCardOnly || consumerInfo.notAcceptedOffer) {
            dispatch(setScreen(SCREEN.DOWNLOAD_APP));
            navigate(PAGE.DOWNLOAD_APP);
        }
    }, [paymentSelected]);
    useEffect(function () {
        if (homeSelector.successType !== SUCCESS_TYPE.default) {
            dispatch(setSuccessInformation({
                amount: selectedValue,
                date: paymentDateConfirmationSubtitleFormatted,
            }));
            dispatch(setScreen(SCREEN.SUCCESS));
            navigate(PAGE.SUCCESS);
        }
    }, [homeSelector.successType]);
    useEffect(function () {
        if (!selectedValue) {
            setSelectedValue(convertAmountToString(paymentRequirements.statement));
        }
    });
    useEffect(function () {
        var selectedValue_num = currencyToNumber(selectedValue);
        var currentBalance_num = currencyToNumber(convertAmountToString(paymentRequirements.current));
        var MINIMUM_PAYMENT = 0.01;
        if (selectedValue_num >= MINIMUM_PAYMENT && selectedValue_num <= currentBalance_num) {
            setIsPaymentValid(true);
        }
        else {
            setIsPaymentValid(false);
        }
    }, [selectedValue, homeSelector]);
    useEffect(function () {
        // checks if payment requirements values are available
        if (!paymentRequirements.current ||
            paymentRequirements.current.displaySymbol === '' ||
            !language) {
            setIsLoading(true);
        }
        else {
            setIsLoading(false);
        }
    }, [paymentRequirements.current, language]);
    if (isLoading) {
        return (_jsxs("div", { children: [_jsx(HeaderServicing, {}), _jsx(ImpBreadcrumbs, { breadcrumbs: [language.breadcrumbPayments, language.breadcrumbOneTimePayment], onBackClick: function () { return handleBackClick(); }, onCancelClick: function () { return navigateToAccountPage(dispatch, navigate); } }), _jsx(ImpSpacer, { height: 'xl' }), _jsx(Spinner, {})] }));
    }
    var getLessThanMinimumWarning = function () {
        var selectedValueNumber = parseFloat(formatDollarStringToString(selectedValue));
        var minimumValueNumber = parseFloat(paymentRequirements.minimum.displayValue);
        if (paymentRequirements.minimum.valueInMinUnit <= 0) {
            return _jsx(_Fragment, {});
        }
        else if (selectedValueNumber < minimumValueNumber) {
            var header1 = String(language.lessThanMinimumDueOTPSubheader1)
                .replace('[amount]', convertAmountToString(paymentRequirements.interest))
                .replace('[date]', nextPaymentDueDate);
            var header2 = String(language.lessThanMinimumDueOTPSubheader2)
                .replace('[amount]', convertAmountToString(paymentRequirements.minimum))
                .replace('[date]', nextPaymentDueDate);
            return (_jsxs(_Fragment, { children: [_jsx("p", __assign({ className: styles.nextPaymentDue }, { children: header1 })), _jsx("p", __assign({ className: styles.nextPaymentDue }, { children: header2 }))] }));
        }
    };
    var paymentOptions = [
        {
            title: language.currentBalance,
            subtitle: convertAmountToString(paymentRequirements.current),
            handleClick: function () {
                setPaymentMethodName('current_balance');
            },
        },
        {
            title: language.statementBalance,
            subtitle: convertAmountToString(paymentRequirements.statement),
            handleClick: function () {
                setPaymentMethodName('statement_balance');
            },
        },
        {
            title: language.minPayment,
            subtitle: convertAmountToString(paymentRequirements.minimum),
            handleClick: function () {
                setPaymentMethodName('minimum_payment');
            },
        },
        {
            title: language.customAmount,
            subtitle: '$0.00',
            style: ITEM_STYLE.CUSTOM_AMOUNT,
            maxCustomAmount: convertAmountToString(paymentRequirements.current),
            maxInputLength: paymentMaxInputLength,
            handleClick: function () {
                setPaymentMethodName('custom_amount');
            },
        },
    ];
    var paymentConfirmation = [
        {
            title: language.paymentAmount,
            subtitle: selectedValue,
        },
        {
            title: language.paymentDate,
            subtitle: paymentDateConfirmationSubtitleFormatted,
        },
        {
            title: language.paymentMethod,
            subtitle: language.accountEnding + primaryPaymentAccount.accountNumberLast4,
        },
    ];
    // Get the button state for ImpButton
    var getButtonState = function () {
        if (!isPaymentValid) {
            return 'disabled';
        }
        return isSubmitting ? 'submitting' : 'enabled';
    };
    function handleBackClick() {
        if (inPaymentDateSelection) {
            setInPaymentDateSelection(false);
        }
        if (paymentSelected) {
            setPaymentSelected(false);
        }
        else if (screenState.previousScreen === SCREEN.PAYMENTS) {
            // navigate to payments if coming from payments page
            dispatch(setScreen(SCREEN.PAYMENTS));
            navigate(PAGE.PAYMENTS);
        }
        else {
            navigateToAccountPage(dispatch, navigate);
        }
    }
    var getBreadcrumbSubItem = function () {
        if (inPaymentDateSelection) {
            return language.breadcrumbPayLater;
        }
        if (paymentSelected) {
            return language.breadcrumbPaymentConfirmation;
        }
        else {
            return language.breadcrumbOneTimePayment;
        }
    };
    if (duplicatePaymentsConfirmation && paymentsEnhancementsEnabled) {
        return (_jsxs(_Fragment, { children: [_jsx(HeaderServicing, {}), _jsx(ImpBreadcrumbs, { breadcrumbs: [language.breadcrumbPayments, getBreadcrumbSubItem()], onBackClick: handleBackClick, onCancelClick: function () { return navigateToAccountPage(dispatch, navigate); } }), _jsx(ImpSpacer, { height: '2xl', mobileHeight: "xl" }), _jsx(AreYouSureContent, { onPrimaryClick: function () {
                        setDuplicatePaymentsConfirmation(false);
                    }, onSecondaryClick: handleBackClick }), _jsx(ImpSpacer, { height: '2xl', mobileHeight: "xl" })] }));
    }
    return (_jsxs(_Fragment, { children: [_jsx(HeaderServicing, {}), _jsx(ImpBreadcrumbs, { breadcrumbs: [language.breadcrumbPayments, getBreadcrumbSubItem()], onBackClick: handleBackClick, onCancelClick: function () { return navigateToAccountPage(dispatch, navigate); } }), convertAmountToNumber(paymentRequirements.current) <= 0 ? (_jsx(NoPaymentDuePage, {})) : (_jsxs("div", __assign({ className: styles.container }, { children: [appScreenState.showModal === MODAL_ENUM.showPaymentDefinitionModal && (_jsx(PaymentDefinitionModal, {})), _jsxs("div", __assign({ className: styles.body }, { children: [_jsx(ImpSpacer, { height: '2xl' }), _jsx(ImpText, __assign({ typography: "headline1" }, { children: getContent().headerValue })), _jsx(ImpSpacer, { height: 'm' }), _jsxs(ImpText, __assign({ typography: "body2", mobileTypography: "body3", color: colors.content.onBackgroundVariant }, { children: [language.nextPaymentDue, " ", nextPaymentDueDate, "."] })), _jsx(ImpSpacer, { height: '2xl' }), getLessThanMinimumWarning(), inPaymentDateSelection ? (_jsxs("div", __assign({ className: styles.paymentDateSelectionContainer }, { children: [_jsx(Calendar, { onChange: setCalendarDate, locale: getLanguageFromCookie(), minDate: minScheduleDate, maxDate: maxScheduleDate }), _jsx(ImpSpacer, { height: '2xl' }), _jsxs(ImpText, __assign({ typography: "body2", mobileTypography: "body3", color: colors.content.onBackgroundVariant }, { children: [language.scheduledPaymentSubheader, paymentDateCalendarSubheaderFormatted] })), _jsx(ImpSpacer, { height: 'm' }), _jsx(ImpFlex, __assign({ direction: "column", align: "center" }, { children: _jsx(ImpButton, __assign({ onClick: function () {
                                                setInPaymentDateSelection(false);
                                                setPaymentDate(calendarDate);
                                                ANALYTICS.logEvent(EVENT.PAYMENT_DATE_SELECTION_CLICKED);
                                            }, state: 'enabled', variant: 'primary', size: "m" }, { children: language.continueBtn })) }))] }))) : (_jsxs("div", __assign({ className: styles.container }, { children: [_jsxs("div", __assign({ className: paymentSelected ? styles.hidden : styles.visible }, { children: [_jsx(ListSection, { style: LIST_STYLE.SELECT, items: paymentOptions, defaultSelection: 1, onItemSelect: function (value) {
                                                    if (!paymentSelected)
                                                        setSelectedValue(value);
                                                } }), _jsx(ModalDefinitionText, { text: language.paymentDefinition, onClick: function () {
                                                    dispatch(setShowModal(MODAL_ENUM.showPaymentDefinitionModal));
                                                } })] })), _jsx("div", __assign({ className: paymentSelected ? styles.visible : styles.hidden }, { children: _jsx(ListSection, { style: LIST_STYLE.DEFAULT, items: paymentConfirmation, noPointerCursor: true }) })), _jsxs("p", __assign({ className: styles.appNotice }, { children: [getContent().appNotice1, paymentSelected && (_jsx("a", __assign({ className: "".concat(styles.body, " ").concat(styles.authLink), href: language.selected === LanguageCode.English
                                                    ? ImprintConstants.PaymentConsent
                                                    : ImprintConstants.PaymentConsentES, rel: "noopener", target: "_blank", onClick: function () {
                                                    ANALYTICS.logEvent(EVENT.ONE_TIME_PAYMENT_LINK_CLICKED);
                                                } }, { children: language.consentAuthorization }))), getContent().appNotice2] })), _jsxs(ImpFlex, __assign({ direction: "row", mobileDirection: "column", gap: "xl", align: "center", mobileAlign: "stretch", fluid: true }, { children: [_jsx(ImpFlexItem, __assign({ flex: '1 1 0' }, { children: _jsx(ImpButton, __assign({ size: "fill", variant: "secondary", buttonStyle: "imprint", onClick: leftBtnClick }, { children: getContent().leftBtnValue })) })), _jsx(ImpFlexItem, __assign({ flex: '1 1 0' }, { children: _jsx(ImpButton, __assign({ size: "fill", variant: "primary", buttonStyle: "imprint", onClick: rightBtnClick, state: getButtonState() }, { children: getContent().rightBtnValue })) }))] }))] })))] }))] })))] }));
}
