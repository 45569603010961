var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { formatDisplayAmount } from '@imprint-payments/imprint-lib';
import { ImpButton, ImpText, ImpTooltip } from '@imprint-payments/imprint-ui';
import { useNavigate } from 'react-router-dom';
import { EVENT } from '../../analytics/analyticsConsts';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectLanguage } from '../../app/languageSelectors';
import { selectPartnerMembershipStatus, selectPrimaryAction, selectProductAccount, selectRedemptionTypes, selectRewards, selectRewardsAvailableBalanceAmount, selectSelectedProductUUID, } from '../../app/productAccountSelectors';
import { SCREEN, setScreen } from '../../app/screenStateSlice';
import { ANALYTICS } from '../../firebase/firebase';
import { PartnerMembershipStatus, PrimaryAction } from '../../types/RewardActionAssets';
import { PRODUCTUUID } from '../../utils/productUUIDConstants';
import { PAGE } from '../../utils/routeConstant';
export var RewardsRedemptionButton = function () {
    var _a;
    var dispatch = useAppDispatch();
    var navigate = useNavigate();
    var v1ValidMerchants = [PRODUCTUUID.HEB, PRODUCTUUID.CM, PRODUCTUUID.TURKISH, PRODUCTUUID.BB];
    var language = useAppSelector(selectLanguage);
    var selectedProductUUID = useAppSelector(selectSelectedProductUUID);
    var productAccount = useAppSelector(selectProductAccount);
    var canRedeemRewards = v1ValidMerchants.includes((_a = productAccount === null || productAccount === void 0 ? void 0 : productAccount.productDetails) === null || _a === void 0 ? void 0 : _a.productUUID);
    var rewardsAvailableBalance = useAppSelector(selectRewardsAvailableBalanceAmount);
    var rewards = useAppSelector(selectRewards);
    var primaryAction = useAppSelector(selectPrimaryAction);
    var partnerMembershipStatus = useAppSelector(selectPartnerMembershipStatus);
    var redemptionTypes = useAppSelector(selectRedemptionTypes);
    var hasPostiveBalance = (rewardsAvailableBalance === null || rewardsAvailableBalance === void 0 ? void 0 : rewardsAvailableBalance.valueInMinUnit) > 0;
    var isValidCashbackAction = primaryAction === PrimaryAction.CASHBACK && (redemptionTypes === null || redemptionTypes === void 0 ? void 0 : redemptionTypes.length) > 0 && hasPostiveBalance;
    var isMenuAction = primaryAction === PrimaryAction.MENU;
    var isPartnerMembershipAccountLinked = primaryAction === PrimaryAction.PARTNER_MEMBERSHIP &&
        partnerMembershipStatus === PartnerMembershipStatus.ACTIVE;
    var linkOut = isMenuAction || isPartnerMembershipAccountLinked;
    var getRewardsButtonState = function () {
        return isValidCashbackAction || linkOut ? 'enabled' : 'disabled';
    };
    var handleUseRewardsButtonClick = function () {
        ANALYTICS.logEvent(EVENT.HOME_REDEEM_CLICKED, {
            card_balance: formatDisplayAmount(productAccount),
            available_balance: formatDisplayAmount(rewardsAvailableBalance),
        });
        switch (linkOut) {
            case true: {
                window.open(productAccount.productDetails.merchantURL, '_blank');
                break;
            }
            default: {
                dispatch(setScreen(SCREEN.REWARDS));
                navigate(PAGE.REWARDS);
                break;
            }
        }
        return;
    };
    if (canRedeemRewards) {
        return (_jsx(ImpButton, __assign({ state: getRewardsButtonState(), onClick: handleUseRewardsButtonClick, buttonStyle: "branded", size: "hug", linkOut: linkOut }, { children: rewards.displayActionTitle })));
    }
    else {
        return (_jsx(ImpTooltip, __assign({ arrow: true, crossAxisOffset: -50, mainAxisOffset: 6, width: "210px", content: _jsxs(_Fragment, { children: [_jsx(ImpText, __assign({ color: 'white', typography: "label3" }, { children: language.rewardsComingSoonTitle })), _jsx(ImpText, __assign({ color: 'white', typography: "body3" }, { children: language.rewardsComingSoonSubtitle }))] }) }, { children: _jsx(ImpButton, __assign({ state: "disabled", buttonStyle: "branded", size: "hug", linkOut: linkOut }, { children: rewards.displayActionTitle })) })));
    }
};
