var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { ImpButton, ImpContainer, ImpFlex, ImpFlexItem, ImpInput, ImpSelect, ImpSpacer, ImpText, colors, } from '@imprint-payments/imprint-ui';
import { AuthUsersPage, setAuthUserInformation, setAuthUsersPage } from '../../app/authUsersSlice';
import { FORM_INPUT_WIDTH } from '../../assets/consts/const';
import { formatDateValue, formatDob, formatPhoneNumber } from '../../utils/formatUtils';
import { ValidationResponse, getShowError, getValidationString, handleValueChange, validateAuthBirthDate, validateEmail, validateName, validatePhoneNumber, } from '../../utils/validateUtils';
export default function AddInformationPage() {
    var dispatch = useAppDispatch();
    var _a = useState(''), firstName = _a[0], setFirstName = _a[1];
    var _b = useState(ValidationResponse.empty), firstNameError = _b[0], setFirstNameError = _b[1];
    var _c = useState(''), lastName = _c[0], setLastName = _c[1];
    var _d = useState(ValidationResponse.empty), lastNameError = _d[0], setLastNameError = _d[1];
    var _e = useState(''), email = _e[0], setEmail = _e[1];
    var _f = useState(ValidationResponse.empty), emailError = _f[0], setEmailError = _f[1];
    var _g = useState(''), birthMonth = _g[0], setBirthMonth = _g[1];
    var _h = useState(''), birthDay = _h[0], setBirthDay = _h[1];
    var _j = useState(''), birthYear = _j[0], setBirthYear = _j[1];
    var _k = useState(''), fullDob = _k[0], setFullDob = _k[1];
    var _l = useState(ValidationResponse.empty), fullDobError = _l[0], setFullDobError = _l[1];
    var _m = useState(''), mobilePhone = _m[0], setMobilePhone = _m[1];
    var _o = useState(ValidationResponse.empty), mobilePhoneError = _o[0], setMobilePhoneError = _o[1];
    var _p = useState(''), relationship = _p[0], setRelationship = _p[1];
    var _q = useState(ValidationResponse.empty), relationshipError = _q[0], setRelationshipError = _q[1];
    var language = useAppSelector(function (state) { return state.languageSelector.languageLocalization; });
    var authUserState = useAppSelector(function (state) { return state.authUsersSelector; });
    var relationshipToCardholder = [
        {
            value: 'SPOUSE',
            label: language.relationshipArray[0],
        },
        {
            value: 'DOMESTIC_PARTNER',
            label: language.relationshipArray[1],
        },
        {
            value: 'CHILD',
            label: language.relationshipArray[2],
        },
        {
            value: 'OTHER_FAMILY_MEMBER',
            label: language.relationshipArray[3],
        },
        {
            value: 'FRIEND',
            label: language.relationshipArray[4],
        },
    ];
    useEffect(function () {
        setFirstName(authUserState.consumer.name.firstName);
        setLastName(authUserState.consumer.name.lastName);
        setEmail(authUserState.consumer.email);
        if (authUserState.consumer.dob.day !== 0) {
            setBirthMonth(formatDateValue(authUserState.consumer.dob.month.toString()));
            setBirthDay(formatDateValue(authUserState.consumer.dob.day.toString()));
            setBirthYear(authUserState.consumer.dob.year.toString());
        }
        setMobilePhone(formatPhoneNumber(authUserState.consumer.phone));
        if (authUserState.consumer.relationshipToPrimaryUser !== '') {
            setRelationship(authUserState.consumer.relationshipToPrimaryUser);
            setRelationshipError(ValidationResponse.valid);
        }
    }, []);
    useEffect(function () {
        handleValueChange(validateName(firstName, true), setFirstNameError);
    }, [firstName]);
    useEffect(function () {
        handleValueChange(validateName(lastName, false), setLastNameError);
    }, [lastName]);
    useEffect(function () {
        handleValueChange(validateEmail(email), setEmailError);
    }, [email]);
    useEffect(function () {
        handleValueChange(validatePhoneNumber(mobilePhone), setMobilePhoneError);
    }, [mobilePhone]);
    useEffect(function () {
        setFullDob(formatDob("".concat(birthMonth, "/").concat(birthDay, "/").concat(birthYear)));
    }, [birthDay, birthMonth, birthYear]);
    useEffect(function () {
        if (fullDob.length < 10) {
            setFullDobError(ValidationResponse.empty);
            return;
        }
        handleValueChange(validateAuthBirthDate(fullDob), setFullDobError);
    }, [fullDob]);
    function handleContinueClick() {
        dispatch(setAuthUserInformation({
            name: {
                firstName: firstName,
                lastName: lastName,
            },
            email: email,
            dob: {
                month: Number(birthMonth),
                day: Number(birthDay),
                year: Number(birthYear),
            },
            phone: mobilePhone,
            relationshipToPrimaryUser: relationship,
        }));
        dispatch(setAuthUsersPage(AuthUsersPage.ADD_ADDRESS));
    }
    function getContinueButtonState() {
        if (firstNameError === ValidationResponse.valid &&
            lastNameError === ValidationResponse.valid &&
            [
                ValidationResponse.valid,
                ValidationResponse.emailPotentialTypo,
            ].includes(emailError) &&
            fullDobError === ValidationResponse.valid &&
            mobilePhoneError === ValidationResponse.valid &&
            relationshipError === ValidationResponse.valid) {
            return 'enabled';
        }
        else {
            return 'disabled';
        }
    }
    return (_jsx(ImpContainer, __assign({ width: FORM_INPUT_WIDTH }, { children: _jsxs(ImpFlex, __assign({ fluid: true, direction: "column", align: "center" }, { children: [_jsx(ImpText, __assign({ typography: "headline2", mobileTypography: "headline3" }, { children: language.addTheirInformation })), _jsx(ImpSpacer, { height: 'xl' }), _jsxs(ImpFlex, __assign({ fluid: true, direction: "column", align: "flex-start", gap: "l" }, { children: [_jsx(ImpContainer, __assign({ width: "100%" }, { children: _jsx(ImpInput, { label: language.firstName, showError: getShowError(firstNameError), errorMessage: getValidationString(firstNameError, language), value: firstName, onChange: function (e) {
                                    setFirstName(e.target.value);
                                }, onBlur: function () {
                                    setFirstNameError(validateName(firstName, true));
                                } }) })), _jsx(ImpContainer, __assign({ width: "100%" }, { children: _jsx(ImpInput, { label: language.lastName, showError: getShowError(lastNameError), errorMessage: getValidationString(lastNameError, language), value: lastName, onChange: function (e) { return setLastName(e.target.value); }, onBlur: function () {
                                    setLastNameError(validateName(lastName, false));
                                } }) })), _jsx(ImpContainer, __assign({ width: "100%" }, { children: _jsx(ImpInput, { label: language.email, showError: getShowError(emailError), errorMessage: getValidationString(emailError, language), showWarning: emailError === ValidationResponse.emailPotentialTypo, value: email, onChange: function (e) { return setEmail(e.target.value); }, onBlur: function () {
                                    setEmailError(validateEmail(email));
                                } }) })), _jsxs(ImpContainer, __assign({ width: "100%" }, { children: [_jsx(ImpText, __assign({ typography: "body3", color: "#5E5D5C" }, { children: language.birthDay })), _jsx(ImpSpacer, { height: "xs" }), _jsxs(ImpFlex, __assign({ direction: "row", align: "flex-start", gap: "m", fluid: true }, { children: [_jsx(ImpFlexItem, __assign({ flexGrow: 1 }, { children: _jsx(ImpSelect, { options: language.months.map(function (value, index) {
                                                    return { value: index + 1, label: value };
                                                }), label: language.month, onOptionChange: function (option) {
                                                    setBirthMonth(formatDateValue(option));
                                                }, showError: getShowError(fullDobError), autocomplete: "off", defaultOption: authUserState.consumer.dob.month !== 0
                                                    ? {
                                                        value: language.months[authUserState.consumer.dob.month - 1],
                                                        label: language.months[authUserState.consumer.dob.month - 1],
                                                    }
                                                    : undefined }) })), _jsx(ImpFlexItem, __assign({ flexGrow: 1 }, { children: _jsx(ImpInput, { label: language.day, value: birthDay, showError: getShowError(fullDobError), errorMessage: '', onChange: function (e) {
                                                    setBirthDay(e.target.value);
                                                }, onBlur: function () {
                                                    setBirthDay(formatDateValue(birthDay));
                                                }, maxLength: 2, numeric: true }) })), _jsx(ImpFlexItem, __assign({ flexGrow: 1 }, { children: _jsx(ImpInput, { label: language.year, value: birthYear, showError: getShowError(fullDobError), errorMessage: '', onChange: function (e) {
                                                    setBirthYear(e.target.value);
                                                }, onBlur: function () {
                                                    setFullDobError(validateAuthBirthDate(fullDob));
                                                }, maxLength: 4 }) }))] })), getShowError(fullDobError) && (_jsxs(_Fragment, { children: [_jsx(ImpSpacer, { height: "s" }), _jsx(ImpText, __assign({ typography: "body3", color: colors.content.onBackgroundError }, { children: getValidationString(fullDobError, language) }))] }))] })), _jsx(ImpContainer, __assign({ width: "100%" }, { children: _jsx(ImpInput, { label: language.phoneNumber, showError: getShowError(mobilePhoneError), errorMessage: getValidationString(mobilePhoneError, language), value: mobilePhone, onChange: function (e) {
                                    var pn = formatPhoneNumber(e.target.value);
                                    setMobilePhone(pn);
                                }, onBlur: function () {
                                    setMobilePhoneError(validatePhoneNumber(mobilePhone));
                                }, maxLength: 16, numeric: true, type: "text" }) })), _jsx(ImpContainer, __assign({ width: "100%" }, { children: _jsx(ImpSelect, { options: relationshipToCardholder, label: language.relationshipToPrimaryCardholder, onOptionChange: function (val) {
                                    setRelationship(val);
                                    setRelationshipError(ValidationResponse.valid);
                                }, defaultOption: authUserState.consumer.relationshipToPrimaryUser !== ''
                                    ? {
                                        value: authUserState.consumer.relationshipToPrimaryUser,
                                        label: language.relationshipArray[relationshipToCardholder.findIndex(function (element) {
                                            return element.value ===
                                                authUserState.consumer.relationshipToPrimaryUser;
                                        })],
                                    }
                                    : undefined }) })), _jsx(ImpContainer, __assign({ width: "100%" }, { children: _jsxs(ImpFlex, __assign({ fluid: true, direction: "column", align: "center" }, { children: [_jsx(ImpSpacer, { height: "xl" }), _jsx(ImpButton, __assign({ buttonStyle: "branded", state: getContinueButtonState(), variant: "primary", onClick: handleContinueClick }, { children: language.continueBtn })), _jsx(ImpSpacer, { height: "xl" })] })) }))] }))] })) })));
}
