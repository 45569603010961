import { jsx as _jsx } from "react/jsx-runtime";
import { isAndroid, isIOS } from 'react-device-detect';
import AddToAppleWalletButton from './AddToAppleWalletButton';
import AddToGoogleWalletButton from './AddToGoogleWalletButton';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectLanguage } from '../../app/languageSelectors';
import { PRODUCTUUID } from '../../utils/productUUIDConstants';
import { displaySnackbarOnFocus } from '../../utils/functionUtils';
// enable prd testing for add to wallet. since we dont have EB cardholders, this shouldnt affect customers
export var ADD_TO_WALLET_ENABLED_MERCHANTS = [PRODUCTUUID.EB];
export default function AddToWalletButton(_a) {
    var cardUUID = _a.cardUUID, onClick = _a.onClick, onSuccess = _a.onSuccess;
    var languageCode = useAppSelector(selectLanguage).selected;
    var language = useAppSelector(selectLanguage);
    var dispatch = useAppDispatch();
    var handleSuccess = function () {
        //TODO: [WS] notify imprint BE the card is added
        console.log('Notify Imprint BE the card is added.');
        displaySnackbarOnFocus(language.addToWalletHappy, dispatch, 'success');
        onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess();
    };
    var handleError = function () {
        displaySnackbarOnFocus(language.addToWalletFail, dispatch, 'error');
    };
    if (isIOS) {
        return (_jsx(AddToAppleWalletButton, { cardUUID: cardUUID, languageCode: languageCode, onClick: onClick, onSuccess: handleSuccess, onError: handleError }));
    }
    else if (isAndroid) {
        return (_jsx(AddToGoogleWalletButton, { cardUUID: cardUUID, languageCode: languageCode, onClick: onClick, onSuccess: handleSuccess, onError: handleError }));
    }
    return null;
}
