import { useEffect } from 'react';
import { useAppDispatch } from '../app/hooks';
import { setLanguage } from '../app/languageSlice';
import { getLanguageFromCookie } from './functionUtils';
import { LanguageCode } from './languageConstants';
import { codeToLocalization } from './languagelocale';
export var useLanguage = function () {
    var dispatch = useAppDispatch();
    useEffect(function () {
        var language = LanguageCode.English;
        language = getLanguageFromCookie();
        dispatch(setLanguage(codeToLocalization(language)));
    }, []);
};
