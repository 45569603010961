import { colors } from '@imprint-payments/imprint-ui';
import { useEffect } from 'react';
import { useAppSelector } from '../app/hooks';
import { selectMerchantData } from '../app/merchantDataSelectors';
export var useMerchantAssets = function () {
    var merchantData = useAppSelector(selectMerchantData);
    var root = document.documentElement;
    useEffect(function () {
        root.style.setProperty('--lightColor', merchantData.color.light);
        root.style.setProperty('--darkColor', merchantData.color.dark);
        if (merchantData.color.contrastLight) {
            root.style.setProperty('--contrastLight', merchantData.color.contrastLight);
        }
        else {
            root.style.removeProperty('--contrastLight');
        }
        if (merchantData.color.contrastDark) {
            root.style.setProperty('--contrastDark', merchantData.color.contrastDark);
        }
        else {
            root.style.removeProperty('--contrastDark');
        }
        root.style.setProperty('--merchant-color', merchantData.color.light);
        root.style.setProperty('--background-servicing', colors.surface.surfaceLow);
        root.style.setProperty('--background', colors.surface.surfaceLow);
    }, [merchantData.color.light]);
};
