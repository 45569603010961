var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { ImpCard, ImpContainer } from '@imprint-payments/imprint-ui';
import { useAppSelector } from '../../app/hooks';
import { selectVirtualCardStatus } from '../../app/productAccountSelectors';
import { DISABLED_CARD_STATUSES } from '../../utils/cardConsts';
var VirtualCardStatus;
(function (VirtualCardStatus) {
    VirtualCardStatus["Active"] = "ACTIVE";
})(VirtualCardStatus || (VirtualCardStatus = {}));
export function CardItem(_a) {
    var image = _a.image, subtitle = _a.subtitle, handleClick = _a.handleClick;
    var productAccount = useAppSelector(function (state) { return state.productAccountSelector; });
    var merchantData = useAppSelector(function (state) { return state.merchantDataSelector; });
    var virtualCardStatus = useAppSelector(selectVirtualCardStatus);
    if (DISABLED_CARD_STATUSES.includes(productAccount.account.status) ||
        !productAccount.virtualCard ||
        !(virtualCardStatus === VirtualCardStatus.Active)) {
        return (_jsx(ImpContainer, __assign({ zIndex: 1, width: "343px", mobileWidth: "100%", maxWidth: "343px" }, { children: _jsx(ImpCard, { src: merchantData.cardImgDisabled, shadowOnHover: true, width: "100%" }) })));
    }
    // Disabling until we have a way to differentiate 'inactive' and failed virtual card creation (match mobile behavior)
    // if (!productAccount.virtualCard) {
    //   return (
    //     <ImpContainer>
    //       <ImpTooltip
    //         arrow
    //         content={
    //           <ImpFlex direction="column">
    //             <ImpText typography="label3" color="white">
    //               {language.virtualCardDisabledTooltipTitle}
    //             </ImpText>
    //             <ImpText typography="body3" color="white">
    //               {language.virtualCardDisabledTooltipContent}
    //             </ImpText>
    //           </ImpFlex>
    //         }
    //       >
    //         <ImpCard
    //           src={merchantData.cardImgDisabled}
    //           subtitle={language.virtualCardDisabledSubtitle}
    //           subtitleColor={colors.content.onBackgroundVariant}
    //           shadowOnHover
    //         />
    //       </ImpTooltip>
    //     </ImpContainer>
    //   );
    // }
    return (_jsx(ImpContainer, __assign({ width: "343px", mobileWidth: "100%", maxWidth: "343px", position: "relative" }, { children: _jsx(ImpCard, { src: image, subtitle: subtitle, hoverable: true, shimmer: true, shimmerOnce: true, handleClick: handleClick, subtitleColor: 'var(--merchant-color)', width: "100%" }) })));
}
