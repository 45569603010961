var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a;
import { setShowAccountLinkModal } from '../../app/accountLinkSlice';
import { setLoading } from '../../app/applicationScreenSlice';
import { setSelectedAuthorizedUser } from '../../app/authUsersSlice';
import { setCardTerms, setMerchantName } from '../../app/merchantDataSlice';
import { setAccount, setAccountCode, setActions, setAuthUserDetails, setAutoPayDetails, setAutopay, setCardBalanceV2, setDueDay, setPaymentDetails, setPhysicalCard, setProductDetails, setRewards, setShippingWidget, setVirtualCard, setWidgets, } from '../../app/productAccountSlice';
import { store } from '../../app/store';
import { getEmptyAccount } from '../../types/Account';
import { getHandler, handleApiError } from '../../types/Api';
import { getEmptyBalances } from '../../types/Balances';
import { getEmptyCard } from '../../types/Card';
import { getEmptyCardBalanceV2 } from '../../types/CardBalanceV2';
import { getEmptyCardTerms } from '../../types/CardTerms';
import { getEmptyDate } from '../../types/Date';
import { getEmptyPaymentDetails } from '../../types/PaymentDetails';
import { getEmptyProductAccountRewards, } from '../../types/ProductAccountRewards';
import { getEmptyProductDetails } from '../../types/ProductDetails';
import { PartnerMembershipStatus, PrimaryAction } from '../../types/RewardActionAssets';
import { getEmptyTopSheet } from '../../types/TopSheet';
import { API_CONFIG, RequestName } from '../apiConfig';
import { handleCardDesignSelection } from '../cardDesign/cardDesign';
import { httpRequest } from '../http/client';
import { ResponseCode } from '../http/httpConsts';
export var getInitLoadProductAccountResponse = function () {
    return {
        account: getEmptyAccount(),
        productType: '',
        productDetails: getEmptyProductDetails(),
        physicalCard: getEmptyCard(),
        rewards: getEmptyProductAccountRewards(),
        balances: getEmptyBalances(),
        cardBalanceV2: getEmptyCardBalanceV2(),
        paymentDetails: getEmptyPaymentDetails(),
        offers: [],
        widgets: [],
        actions: [],
        topSheet: getEmptyTopSheet(),
    };
};
export var handleOk = function (store, response, params) { return __awaiter(void 0, void 0, void 0, function () {
    var loadProductAccountResponse, firstTimeUser, relevantAuthUser;
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    return __generator(this, function (_l) {
        loadProductAccountResponse = response.data.body;
        firstTimeUser = store.getState().homeSelector.userInformation.firstTimeUser;
        // update required states
        store.dispatch(setProductDetails(loadProductAccountResponse.productDetails));
        store.dispatch(setMerchantName(loadProductAccountResponse.productDetails.merchantName));
        store.dispatch(setRewards(loadProductAccountResponse.rewards));
        // show account link modal if user is firsttime user and
        // membership is UNLINKED
        store.dispatch(setShowAccountLinkModal(firstTimeUser &&
            ((_a = loadProductAccountResponse.rewards) === null || _a === void 0 ? void 0 : _a.primaryAction) === PrimaryAction.PARTNER_MEMBERSHIP &&
            ((_b = loadProductAccountResponse.rewards) === null || _b === void 0 ? void 0 : _b.partnerMembershipStatus) ===
                PartnerMembershipStatus.UNLINKED));
        store.dispatch(setActions(loadProductAccountResponse.actions));
        // set undefined states
        store.dispatch(setDueDay((_d = (_c = loadProductAccountResponse.paymentDetails) === null || _c === void 0 ? void 0 : _c.dueDay) !== null && _d !== void 0 ? _d : getEmptyDate()));
        store.dispatch(setAccount(loadProductAccountResponse.account));
        store.dispatch(setPhysicalCard(loadProductAccountResponse.physicalCard));
        store.dispatch(setAccountCode(loadProductAccountResponse.accountCode));
        store.dispatch(setAutopay(loadProductAccountResponse.autopay));
        store.dispatch(setAutoPayDetails(loadProductAccountResponse.autoPayDetails));
        store.dispatch(setCardBalanceV2((_e = loadProductAccountResponse.cardBalanceV2) !== null && _e !== void 0 ? _e : getEmptyCardBalanceV2()));
        store.dispatch(setPaymentDetails((_f = loadProductAccountResponse.paymentDetails) !== null && _f !== void 0 ? _f : getEmptyPaymentDetails()));
        store.dispatch(setCardTerms((_g = loadProductAccountResponse.cardTerms) !== null && _g !== void 0 ? _g : getEmptyCardTerms()));
        store.dispatch(setAuthUserDetails(loadProductAccountResponse.authUserDetails));
        store.dispatch(setVirtualCard(loadProductAccountResponse.virtualCard));
        store.dispatch(setWidgets((_h = loadProductAccountResponse.widgets) !== null && _h !== void 0 ? _h : []));
        store.dispatch(setShippingWidget(loadProductAccountResponse.shippingWidget));
        relevantAuthUser = (_k = (_j = loadProductAccountResponse.authUserDetails) === null || _j === void 0 ? void 0 : _j.authorizedUsers) === null || _k === void 0 ? void 0 : _k.find(function (authUser) {
            var _a, _b;
            return authUser.authorizedUserUUID ===
                ((_b = (_a = store.getState().authUsersSelector) === null || _a === void 0 ? void 0 : _a.selectedAuthorizedUser) === null || _b === void 0 ? void 0 : _b.authorizedUserUUID);
        });
        if (relevantAuthUser) {
            store.dispatch(setSelectedAuthorizedUser(relevantAuthUser));
        }
        // handle card design selection
        handleCardDesignSelection(store, loadProductAccountResponse.productDetails.productUUID, params.productAccountUUID);
        return [2 /*return*/];
    });
}); };
var handleGenericError = function (store, response) {
    var res = response.data.body;
    var dispatch = store.dispatch;
    dispatch(setLoading(false));
    handleApiError(RequestName.LOAD_PRODUCT_ACCOUNT, res);
};
var handlers = (_a = {},
    _a[ResponseCode.OK] = handleOk,
    _a);
export var loadProductAccount = function (request, reduxStore) {
    if (reduxStore === void 0) { reduxStore = store; }
    return __awaiter(void 0, void 0, void 0, function () {
        var urlSuffix, req, apiResponse, handler;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setLoading(true);
                    urlSuffix = "/".concat(request.productAccountUUID);
                    req = {
                        config: API_CONFIG.LOAD_PRODUCT_ACCOUNT,
                        urlSuffix: urlSuffix,
                    };
                    return [4 /*yield*/, httpRequest(req)];
                case 1:
                    apiResponse = _a.sent();
                    handler = getHandler(apiResponse.status, handlers, handleGenericError);
                    return [4 /*yield*/, handler(reduxStore, apiResponse, {
                            productAccountUUID: request.productAccountUUID,
                        })];
                case 2:
                    _a.sent();
                    setLoading(false);
                    return [2 /*return*/];
            }
        });
    });
};
