var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { EVENT, PKG } from '../analytics/analyticsConsts';
import { setConfirm } from '../app/acceptScreenSlice';
import { setDeviceID } from '../app/apiScreenSlice';
import { setLoading } from '../app/applicationScreenSlice';
import { setProductAccountUUID } from '../app/applicationStatusSlice';
import { resetActions, setCardOffer, setConsumerUUID, setHasSpendCardOnly, setNotAcceptedOffer, } from '../app/consumerInfoSlice';
import { setApplication, setHomeFlow, setLinkToken, setPrimaryPaymentAccount, setProductAccounts, setSuccessType, setUserInformation, SUCCESS_TYPE, } from '../app/homeSlice';
import { setAccountLinkModalBackgroundImg, setAccountLinkModalLogo, setAppUiImg, setCardBackImg, setCardDesignOptionImages, setCardImg, setCardOfferImg, setColor, setConfirmLaterQR, setDownloadAppBackgroundImg, setLogoIcon, setLogoTile, setProductUUID, setRewardDetails, setWebUiImg, } from '../app/merchantDataSlice';
import { SCREEN, setScreen } from '../app/screenStateSlice';
import { setLastStatementID, updateStatementMedium, updateStatements, } from '../app/statementsSlice';
import { store } from '../app/store';
import { setAccountAndRoutingError } from '../app/validatorSlice';
import { ANALYTICS } from '../firebase/firebase';
import { getNewDeviceID } from '../utils/functionUtils';
import { METHOD, RejectionPDFRequest, Request, requestAssests } from '../utils/http';
import { URL } from './apiConstants';
import { setScheduledPayments } from '../app/activitySlice';
import { setAuth } from '../app/authSlice';
import { ForgotPinScreen, setForgotPinScreen } from '../app/forgotPinSlice';
import { setOtpScreenState } from '../app/otpScreenSlice';
import { setCardBenefitsImage } from '../app/rewardsDetailsSlice';
import { PARAM_SHOW_WPP } from '../assets/consts/const';
import { LanguageCode } from '../utils/languageConstants';
import { getVirtualCard } from './cardDesign/cardDesign';
import { getVirtualCardDisabled } from './cardDesign/getVirtualCardDisabled';
import { getScheduledPayments } from './loadActivity/getScheduledPayments';
import { loadProductAccount } from './loadProductAccount/loadProductAccount';
import { PINContext } from './verifyPin.api';
//TODO: need to split this huge file
var newUUID = getNewDeviceID === null || getNewDeviceID === void 0 ? void 0 : getNewDeviceID();
export var showError = function (dispatch) {
    dispatch(setLoading(false));
    dispatch(setScreen(SCREEN.ERROR));
};
var checkout = function (_a) {
    var phone = _a.phone, productUUID = _a.productUUID;
    return __awaiter(void 0, void 0, void 0, function () {
        var data, error_1;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, Request({
                            url: URL.CHECKOUT,
                            method: METHOD.POST,
                            body: {
                                phone: phone,
                                productUUID: productUUID,
                            },
                        })];
                case 1:
                    data = _b.sent();
                    return [2 /*return*/, [data, null]];
                case 2:
                    error_1 = _b.sent();
                    return [2 /*return*/, [null, error_1]];
                case 3: return [2 /*return*/];
            }
        });
    });
};
export var recordAnalyticsToSnowflake = function (_a) {
    var analyticsRequestData = _a.analyticsRequestData;
    return __awaiter(void 0, void 0, void 0, function () {
        var data, error_2;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, Request({
                            url: URL.ANALYTICS,
                            method: METHOD.POST,
                            body: JSON.parse(analyticsRequestData),
                            keepAlive: true,
                        })];
                case 1:
                    data = _b.sent();
                    return [2 /*return*/, [null, null]];
                case 2:
                    error_2 = _b.sent();
                    return [2 /*return*/, [null, error_2]];
                case 3: return [2 /*return*/];
            }
        });
    });
};
export var login = function (_a) {
    var phone = _a.phone, deviceID = _a.deviceID, _b = _a.resend, resend = _b === void 0 ? false : _b, _c = _a.sendVoice, sendVoice = _c === void 0 ? false : _c;
    return __awaiter(void 0, void 0, void 0, function () {
        var data, error_3;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    _d.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, Request({
                            url: URL.LOGIN,
                            method: METHOD.POST,
                            body: {
                                phone: phone,
                                resend: resend,
                                sendVoice: sendVoice,
                            },
                            deviceID: deviceID,
                        })];
                case 1:
                    data = _d.sent();
                    return [2 /*return*/, [data, null]];
                case 2:
                    error_3 = _d.sent();
                    return [2 /*return*/, [null, error_3]];
                case 3: return [2 /*return*/];
            }
        });
    });
};
export var loginThunk = function (_a) {
    var phone = _a.phone;
    return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
        var auth, _a, loginData, error;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    auth = getState().authSlice;
                    return [4 /*yield*/, login({
                            phone: phone,
                        })];
                case 1:
                    _a = _b.sent(), loginData = _a[0], error = _a[1];
                    if (error || (loginData === null || loginData === void 0 ? void 0 : loginData.message)) {
                        showError(dispatch);
                    }
                    dispatch(setConsumerUUID((loginData === null || loginData === void 0 ? void 0 : loginData.consumerUUID) || ''));
                    dispatch(setAuth(__assign(__assign({}, auth), { requestID: loginData.requestID })));
                    return [2 /*return*/];
            }
        });
    }); };
};
export var serviceLoginThunk = function (_a) {
    var phone = _a.phone, resend = _a.resend, sendVoice = _a.sendVoice;
    return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
        var auth, userInfo, homeFlow, storedPhone, deviceID, _a, loginData, error;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    dispatch(setLoading(true));
                    auth = getState().authSlice;
                    userInfo = getState().homeSelector.userInformation;
                    homeFlow = getState().homeSelector.homeFlow;
                    if (getState().apiScreenScreenSelector.deviceID === '') {
                        dispatch(setDeviceID(getNewDeviceID()));
                    }
                    if (resend) {
                        storedPhone = getState().otpScreenSelector.destination;
                        phone = phone || storedPhone;
                    }
                    deviceID = getState().apiScreenScreenSelector.deviceID;
                    return [4 /*yield*/, login({
                            phone: phone,
                            deviceID: deviceID,
                            resend: resend,
                            sendVoice: sendVoice,
                        })];
                case 1:
                    _a = _b.sent(), loginData = _a[0], error = _a[1];
                    if ((error === null || error === void 0 ? void 0 : error.code) === '201') {
                        dispatch(setLoading(false));
                        dispatch(setHomeFlow(__assign(__assign({}, homeFlow), { pinLockOutMessage: error.message })));
                        dispatch(setScreen(SCREEN.VERIFICATION_FAILED));
                        return [2 /*return*/, error];
                    }
                    if (loginData === null || loginData === void 0 ? void 0 : loginData.nextStep) {
                        dispatch(setLoading(false));
                        dispatch(setUserInformation(__assign(__assign({}, userInfo), { phone: phone })));
                        dispatch(setHomeFlow(__assign(__assign({}, homeFlow), { loginError: false })));
                        dispatch(setConsumerUUID((loginData === null || loginData === void 0 ? void 0 : loginData.consumerUUID) || ''));
                        dispatch(setAuth(__assign(__assign({}, auth), { requestID: loginData.requestID })));
                        dispatch(setHasSpendCardOnly(false));
                        dispatch(setNotAcceptedOffer(false));
                        dispatch(resetActions());
                        dispatch(setUserInformation(__assign(__assign({}, userInfo), { phone: phone })));
                        if (loginData.nextStep == 'verifyOTP') {
                            dispatch(setOtpScreenState({
                                method: 'phone',
                                destination: phone,
                            }));
                            dispatch(setScreen(SCREEN.SERVICE_OTPSCREEN));
                        }
                        if (loginData.nextStep == 'verifyPIN') {
                            // remainingAttempts could be undefined or int
                            if (loginData === null || loginData === void 0 ? void 0 : loginData.remainingAttempts) {
                                dispatch(setHomeFlow(__assign(__assign({}, homeFlow), { pinRemainingAttempts: loginData === null || loginData === void 0 ? void 0 : loginData.remainingAttempts })));
                            }
                            dispatch(setUserInformation(__assign(__assign({}, userInfo), { firstTimeUser: false })));
                            dispatch(setScreen(SCREEN.PIN));
                        }
                        return [2 /*return*/, loginData];
                    }
                    dispatch(setLoading(false));
                    dispatch(setHomeFlow(__assign(__assign({}, homeFlow), { loginError: true })));
                    return [2 /*return*/];
            }
        });
    }); };
};
// TODO: new api config for prompt
export var prompt = function (_a) {
    var productUUID = _a.productUUID, deviceID = _a.deviceID;
    return __awaiter(void 0, void 0, void 0, function () {
        var token, data, error_4;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    token = store.getState().authSlice.token;
                    return [4 /*yield*/, Request({
                            url: "".concat(URL.PROMPT, "?productUUID=").concat(productUUID),
                            method: METHOD.GET,
                            token: token,
                            deviceID: deviceID,
                        })];
                case 1:
                    data = _b.sent();
                    return [2 /*return*/, [data, null]];
                case 2:
                    error_4 = _b.sent();
                    return [2 /*return*/, error_4];
                case 3: return [2 /*return*/];
            }
        });
    });
};
export var verifyOtp = function (_a) {
    var requestId = _a.requestId, code = _a.code, deviceID = _a.deviceID;
    return __awaiter(void 0, void 0, void 0, function () {
        var data, error_5;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, Request({
                            url: URL.VERIFY,
                            method: METHOD.POST,
                            body: {
                                requestId: requestId,
                                code: code,
                            },
                            deviceID: deviceID,
                        })];
                case 1:
                    data = _b.sent();
                    return [2 /*return*/, [data, null]];
                case 2:
                    error_5 = _b.sent();
                    return [2 /*return*/, [null, error_5]];
                case 3: return [2 /*return*/];
            }
        });
    });
};
export var initialSC = function (requestParams) { return __awaiter(void 0, void 0, void 0, function () {
    var data, error_6;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, Request({
                        url: requestParams.applicationUUID
                            ? "".concat(URL.INITIALSC, "/").concat(requestParams.applicationUUID, "/submit")
                            : URL.INITIALSC,
                        method: METHOD.POST,
                        body: requestParams.ssn9 ? { ssn9: requestParams.ssn9 } : requestParams.initialScParams,
                        token: requestParams.token,
                    })];
            case 1:
                data = _a.sent();
                return [2 /*return*/, [data, null]];
            case 2:
                error_6 = _a.sent();
                ANALYTICS.funcError(PKG, 'initialSC', String(error_6));
                return [2 /*return*/, [null, error_6]];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var getCardImgForProductAccounts = function (productAccounts) {
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var newProductAccounts, error_7;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, Promise.all(productAccounts.map(function (productAccount) { return __awaiter(void 0, void 0, void 0, function () {
                            var _a;
                            var _b;
                            return __generator(this, function (_c) {
                                switch (_c.label) {
                                    case 0:
                                        _a = [__assign({}, productAccount)];
                                        _b = {};
                                        return [4 /*yield*/, requestAssests({
                                                url: productAccount.cardDesignID
                                                    ? "/content/".concat(productAccount.productUUID, "/web-assets/card-designs/virtual/").concat(productAccount.cardDesignID, ".svg")
                                                    : "/content/".concat(productAccount.productUUID, "/mobile-assets/light/virtualCard.png"),
                                                image: true,
                                            })];
                                    case 1: return [2 /*return*/, (__assign.apply(void 0, _a.concat([(_b.cardImg = (_c.sent()), _b)])))];
                                }
                            });
                        }); }))];
                case 1:
                    newProductAccounts = _a.sent();
                    dispatch(setProductAccounts(newProductAccounts));
                    return [3 /*break*/, 3];
                case 2:
                    error_7 = _a.sent();
                    ANALYTICS.funcError(PKG, 'getCardImgForProductAccount', String(error_7));
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
};
export var getUiImg = function (productUUID, isApp, isEN) {
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var languageDir, url, data, error_8;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    languageDir = 'es';
                    if (isEN) {
                        languageDir = 'en';
                    }
                    url = "/content/".concat(productUUID, "/web-assets/").concat(languageDir, "/DeviceUI.png");
                    if (isApp === true) {
                        url = "/content/".concat(productUUID, "/web-assets/").concat(languageDir, "/PhoneUI.png");
                    }
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, requestAssests({
                            url: url,
                            image: true,
                        })];
                case 2:
                    data = (_a.sent());
                    if (isApp === true) {
                        dispatch(setAppUiImg(data));
                    }
                    else {
                        dispatch(setWebUiImg(data));
                    }
                    return [3 /*break*/, 4];
                case 3:
                    error_8 = _a.sent();
                    ANALYTICS.funcError(PKG, 'getUiImg', JSON.stringify(error_8));
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
};
export var getCardBenefitsImage = function (productUUID) {
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var data, error_9;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, requestAssests({
                            url: "/content/".concat(productUUID, "/mobile-assets/light/cardBenefits.png"),
                            image: true,
                        })];
                case 1:
                    data = (_a.sent());
                    dispatch(setCardBenefitsImage(data));
                    return [3 /*break*/, 3];
                case 2:
                    error_9 = _a.sent();
                    ANALYTICS.funcError(PKG, 'getCardBenefitsImage', String(error_9));
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
};
export var getCardOfferImg = function (productUUID) {
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var data, error_10;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, requestAssests({
                            url: "/content/".concat(productUUID, "/web-assets/offerImage.png"),
                            image: true,
                        })];
                case 1:
                    data = (_a.sent());
                    dispatch(setCardOfferImg(data));
                    return [3 /*break*/, 3];
                case 2:
                    error_10 = _a.sent();
                    ANALYTICS.funcError(PKG, 'getCardOfferImg', String(error_10));
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
};
export var getCardFront = function (productUUID) {
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var data, error_11;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, requestAssests({
                            url: "/content/".concat(productUUID, "/mobile-assets/light/virtualCard.png"),
                            image: true,
                        })];
                case 1:
                    data = (_a.sent());
                    dispatch(setProductUUID(productUUID));
                    dispatch(setCardImg(data));
                    return [3 /*break*/, 3];
                case 2:
                    error_11 = _a.sent();
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
};
export var getCardBack = function (productUUID) {
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var data, error_12;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, requestAssests({
                            url: "/content/".concat(productUUID, "/web-assets/cardBack.svg"),
                            image: true,
                        })];
                case 1:
                    data = (_a.sent());
                    dispatch(setCardBackImg(data));
                    return [3 /*break*/, 3];
                case 2:
                    error_12 = _a.sent();
                    ANALYTICS.funcError(PKG, 'getCardBackImg', String(error_12));
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
};
export var getAlternateLogoIcon = function (productUUID, large) {
    if (large === void 0) { large = false; }
    return __awaiter(void 0, void 0, void 0, function () {
        var data, error_13;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, requestAssests({
                            url: "/content/".concat(productUUID, "/web-assets/alternateLogoIcon").concat(large ? 'Large' : '', ".png"),
                            image: true,
                        })];
                case 1:
                    data = (_a.sent());
                    return [2 /*return*/, data];
                case 2:
                    error_13 = _a.sent();
                    ANALYTICS.funcError(PKG, 'getAlternateLogo', String(error_13));
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    });
};
export var getChromeLogoIcon = function () { return __awaiter(void 0, void 0, void 0, function () {
    var data, error_14;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, requestAssests({
                        url: "/content/common/web-assets/chrome_icon.png",
                        image: true,
                    })];
            case 1:
                data = (_a.sent());
                return [2 /*return*/, data];
            case 2:
                error_14 = _a.sent();
                ANALYTICS.funcError(PKG, 'getChromeIcon', String(error_14));
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var getRewardDetails = function (productUUID) {
    var language = store.getState().languageSelector.languageCode;
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var data, error_15;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, requestAssests({
                            url: language === LanguageCode.English
                                ? "/content/".concat(productUUID, "/program-details/rewards.json")
                                : "/content/".concat(productUUID, "/program-details/es/rewards.json"),
                        })];
                case 1:
                    data = (_a.sent());
                    dispatch(setRewardDetails(data));
                    return [3 /*break*/, 3];
                case 2:
                    error_15 = _a.sent();
                    ANALYTICS.funcError(PKG, 'getRewardDetails', String(error_15));
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
};
export var getColor = function (productUUID) {
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var data, error_16;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, requestAssests({
                            url: "/content/".concat(productUUID, "/mobile-assets/colors.json"),
                        })];
                case 1:
                    data = (_a.sent());
                    dispatch(setColor(data));
                    return [3 /*break*/, 3];
                case 2:
                    error_16 = _a.sent();
                    ANALYTICS.funcError(PKG, 'getColor', String(error_16));
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
};
export var getLogoIcon = function (productUUID) {
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var data, error_17;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, requestAssests({
                            url: "/content/".concat(productUUID, "/web-assets/logoIcon.svg"),
                            image: true,
                        })];
                case 1:
                    data = (_a.sent());
                    dispatch(setLogoIcon(data));
                    return [3 /*break*/, 3];
                case 2:
                    error_17 = _a.sent();
                    ANALYTICS.funcError(PKG, 'getLogoIcon', String(error_17));
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
};
export var getLogoTile = function (productUUID) {
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var data, error_18;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, requestAssests({
                            url: "/content/".concat(productUUID, "/mobile-assets/light/logoTile.svg"),
                            image: true,
                        })];
                case 1:
                    data = (_a.sent());
                    dispatch(setLogoTile(data));
                    return [3 /*break*/, 3];
                case 2:
                    error_18 = _a.sent();
                    ANALYTICS.funcError(PKG, 'getLogoTile', String(error_18));
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
};
export var getDownloadAppBackgroundImg = function (productUUID) {
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var data, error_19;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, requestAssests({
                            url: "/content/".concat(productUUID, "/web-assets/downloadAppBackground.png"),
                            image: true,
                        })];
                case 1:
                    data = (_a.sent());
                    dispatch(setDownloadAppBackgroundImg(data));
                    return [3 /*break*/, 3];
                case 2:
                    error_19 = _a.sent();
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
};
export var getAccountLinkModalBackgroundImg = function (productUUID) {
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var data, error_20;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, requestAssests({
                            url: "/content/".concat(productUUID, "/web-assets/accountLinkModalBackground.svg"),
                            image: true,
                        })];
                case 1:
                    data = (_a.sent());
                    dispatch(setAccountLinkModalBackgroundImg(data));
                    return [3 /*break*/, 3];
                case 2:
                    error_20 = _a.sent();
                    ANALYTICS.funcError(PKG, 'getAccountLinkModalBackgroundImg', String(error_20));
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
};
export var getAccountLinkModalLogo = function (productUUID) {
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var data, error_21;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, requestAssests({
                            url: "/content/".concat(productUUID, "/web-assets/logoFull.svg"),
                            image: true,
                        })];
                case 1:
                    data = (_a.sent());
                    dispatch(setAccountLinkModalLogo(data));
                    return [3 /*break*/, 3];
                case 2:
                    error_21 = _a.sent();
                    ANALYTICS.funcError(PKG, 'getAccountLinkModalLogo', String(error_21));
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
};
export var sendOtpToUser = function (_a) {
    var phone = _a.phone, productUUID = _a.productUUID;
    return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
        var auth, _a, checkoutError, _b, loginData, loginError;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    dispatch(setLoading(true));
                    auth = getState().authSlice;
                    return [4 /*yield*/, checkout({
                            phone: phone,
                            productUUID: productUUID,
                        })];
                case 1:
                    _a = _c.sent(), checkoutError = _a[1];
                    if (checkoutError) {
                        ANALYTICS.logEvent(EVENT.ERROR_VIEWED, {
                            error_type: checkoutError === null || checkoutError === void 0 ? void 0 : checkoutError.message,
                        });
                        ANALYTICS.funcError(PKG, 'sendOTPToUser > checkout', JSON.stringify(checkoutError));
                        showError(dispatch);
                        return [2 /*return*/, checkoutError];
                    }
                    return [4 /*yield*/, login({
                            phone: phone,
                        })];
                case 2:
                    _b = _c.sent(), loginData = _b[0], loginError = _b[1];
                    if (loginError) {
                        ANALYTICS.logEvent(EVENT.ERROR_VIEWED, {
                            error_type: loginError === null || loginError === void 0 ? void 0 : loginError.message,
                        });
                        ANALYTICS.funcError(PKG, 'sendOTPToUser > login', JSON.stringify(loginError));
                        showError(dispatch);
                        return [2 /*return*/, loginError];
                    }
                    if (!loginData || !loginData.requestID) {
                        showError(dispatch);
                        return [2 /*return*/, null];
                    }
                    dispatch(setAuth(__assign(__assign({}, auth), { requestID: loginData.requestID })));
                    dispatch(setConsumerUUID((loginData === null || loginData === void 0 ? void 0 : loginData.consumerUUID) || ''));
                    dispatch(setLoading(false));
                    return [2 /*return*/];
            }
        });
    }); };
};
export var confirmApi = function (_a) {
    var token = _a.token, applicationUUID = _a.applicationUUID, cardDesignID = _a.cardDesignID, deviceID = _a.deviceID;
    return __awaiter(void 0, void 0, void 0, function () {
        var data, error_22;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, Request({
                            url: "/v2/applications/".concat(applicationUUID, "/confirm"),
                            method: METHOD.POST,
                            body: {
                                cardDesignID: cardDesignID,
                            },
                            token: token,
                            deviceID: deviceID,
                        })];
                case 1:
                    data = _b.sent();
                    return [2 /*return*/, [data, null]];
                case 2:
                    error_22 = _b.sent();
                    return [2 /*return*/, [null, error_22]];
                case 3: return [2 /*return*/];
            }
        });
    });
};
export var confirm = function (_a) {
    var token = _a.token, applicationUUID = _a.applicationUUID, cardDesignID = _a.cardDesignID, deviceID = _a.deviceID;
    return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
        var _a, confirmResponse, confirmError;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    dispatch(setLoading(true));
                    if (getState().apiScreenScreenSelector.deviceID === '') {
                        dispatch(setDeviceID(getNewDeviceID()));
                    }
                    return [4 /*yield*/, confirmApi({
                            token: token,
                            applicationUUID: applicationUUID,
                            cardDesignID: cardDesignID,
                            deviceID: deviceID,
                        })];
                case 1:
                    _a = _b.sent(), confirmResponse = _a[0], confirmError = _a[1];
                    if (confirmError || !confirmResponse) {
                        ANALYTICS.logEvent(EVENT.ERROR_VIEWED, {
                            error_type: confirmError === null || confirmError === void 0 ? void 0 : confirmError.message,
                        });
                        ANALYTICS.funcError(PKG, 'confirm', JSON.stringify(confirmError));
                        showError(dispatch);
                        return [2 /*return*/];
                    }
                    dispatch(setLoading(false));
                    dispatch(setConfirm(true));
                    dispatch(setProductAccountUUID(confirmResponse.productAccountUUID));
                    return [2 /*return*/];
            }
        });
    }); };
};
export var rejectionPDF = function (applicationUUID, token) { return __awaiter(void 0, void 0, void 0, function () {
    var data, error_23;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, RejectionPDFRequest({
                        url: URL.REJECTION_PDF,
                        applicationUUID: applicationUUID,
                        token: token,
                    })];
            case 1:
                data = _a.sent();
                return [2 /*return*/, [data, null]];
            case 2:
                error_23 = _a.sent();
                return [2 /*return*/, [null, error_23]];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var subscribe = function (details, type) { return __awaiter(void 0, void 0, void 0, function () {
    var data, error_24;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, Request({
                        url: URL.SUBSCRIBE,
                        method: METHOD.POST,
                        body: { type: type, details: details },
                    })];
            case 1:
                data = _a.sent();
                return [2 /*return*/, [null, null]];
            case 2:
                error_24 = _a.sent();
                return [2 /*return*/, [null, error_24]];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var verifyDob = function (dob, requestID, deviceID) { return __awaiter(void 0, void 0, void 0, function () {
    var data, error_25;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, Request({
                        url: URL.VERIFY_DOB,
                        method: METHOD.POST,
                        body: {
                            dob: dob,
                            requestID: requestID,
                        },
                        deviceID: deviceID,
                    })];
            case 1:
                data = _a.sent();
                return [2 /*return*/, [data, null]];
            case 2:
                error_25 = _a.sent();
                return [2 /*return*/, [null, error_25]];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var verifyServiceDob = function (dob) {
    return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
        var auth, userInfo, homeFlow, requestID, deviceID, _a, dobResponse, dobError;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    dispatch(setLoading(true));
                    auth = getState().authSlice;
                    userInfo = getState().homeSelector.userInformation;
                    homeFlow = getState().homeSelector.homeFlow;
                    requestID = auth.requestID;
                    if (getState().apiScreenScreenSelector.deviceID === '') {
                        dispatch(setDeviceID(getNewDeviceID()));
                    }
                    deviceID = getState().apiScreenScreenSelector.deviceID;
                    return [4 /*yield*/, verifyDob(dob, requestID, deviceID)];
                case 1:
                    _a = _b.sent(), dobResponse = _a[0], dobError = _a[1];
                    if (dobError || !dobResponse) {
                        if ((dobError === null || dobError === void 0 ? void 0 : dobError.code) === '101') {
                            dispatch(setHomeFlow(__assign(__assign({}, homeFlow), { dobError: true })));
                            dispatch(setLoading(false));
                            return [2 /*return*/, dobError];
                        }
                        else if ((dobError === null || dobError === void 0 ? void 0 : dobError.code) === '201') {
                            dispatch(setScreen(SCREEN.VERIFICATION_FAILED));
                            dispatch(setLoading(false));
                            return [2 /*return*/, dobError];
                        }
                        // misc error
                        else if (dobError) {
                            ANALYTICS.logEvent(EVENT.DOB_FAILED, {
                                error: JSON.stringify(dobError),
                            });
                        }
                        return [2 /*return*/];
                    }
                    if (dobResponse.nextStep == 'setPIN') {
                        dispatch(setLoading(false));
                        dispatch(setUserInformation(__assign(__assign({}, userInfo), { firstTimeUser: true })));
                        dispatch(setAuth(__assign(__assign({}, auth), { requestID: dobResponse.requestID })));
                        dispatch(setScreen(SCREEN.PIN));
                    }
                    return [2 /*return*/];
            }
        });
    }); };
};
export var createPinHelper = function (pin, requestID, deviceID) { return __awaiter(void 0, void 0, void 0, function () {
    var data, error_26;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, Request({
                        url: URL.CREATE_PIN,
                        method: METHOD.POST,
                        body: {
                            pin: pin,
                            requestID: requestID,
                        },
                        deviceID: deviceID,
                    })];
            case 1:
                data = _a.sent();
                return [2 /*return*/, [data, null]];
            case 2:
                error_26 = _a.sent();
                return [2 /*return*/, [null, error_26]];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var resetPinHelper = function (token, consumerUUID, oldPin, newPin, deviceID) { return __awaiter(void 0, void 0, void 0, function () {
    var data, error_27;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, Request({
                        url: URL.RESET_PIN,
                        token: token,
                        method: METHOD.POST,
                        body: {
                            consumerUUID: consumerUUID,
                            oldPin: oldPin,
                            newPin: newPin,
                        },
                        deviceID: deviceID,
                    })];
            case 1:
                data = _a.sent();
                return [2 /*return*/, [data, null]];
            case 2:
                error_27 = _a.sent();
                return [2 /*return*/, [null, error_27]];
            case 3: return [2 /*return*/];
        }
    });
}); };
// TODO: break down into new API structure
export var createPin = function (pin, context, onFail, onSuccess) {
    return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
        var auth, deviceID, homeFlow, token, consumerUUID, _a, resetPinResponse, resetPinError, inStoreOnboarding, requestID, _b, createPinResponse, createPinError;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    auth = getState().authSlice;
                    if (getState().apiScreenScreenSelector.deviceID === '') {
                        dispatch(setDeviceID(getNewDeviceID()));
                    }
                    deviceID = getState().apiScreenScreenSelector.deviceID;
                    homeFlow = getState().homeSelector.homeFlow;
                    if (!homeFlow.redirectedFirstTime) return [3 /*break*/, 2];
                    dispatch(setLoading(true));
                    token = auth.token;
                    consumerUUID = getState().consumerInfoSelector.consumerUUID;
                    return [4 /*yield*/, resetPinHelper(token, consumerUUID, '', pin, deviceID)];
                case 1:
                    _a = _c.sent(), resetPinResponse = _a[0], resetPinError = _a[1];
                    if (resetPinError) {
                        dispatch(setLoading(false));
                        onFail === null || onFail === void 0 ? void 0 : onFail();
                        ANALYTICS.funcError(PKG, 'resetPin, in create PIN', JSON.stringify(resetPinError));
                        return [2 /*return*/, resetPinError];
                    }
                    if (resetPinResponse) {
                        inStoreOnboarding = sessionStorage.getItem(PARAM_SHOW_WPP);
                        dispatch(setLoading(false));
                        dispatch(setScreen(inStoreOnboarding ? SCREEN.ACCOUNT : SCREEN.EDU_APP_WEB));
                        onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess();
                        return [2 /*return*/];
                    }
                    dispatch(setLoading(false));
                    return [3 /*break*/, 4];
                case 2:
                    dispatch(setLoading(true));
                    requestID = auth.requestID;
                    return [4 /*yield*/, createPinHelper(pin, requestID, deviceID)];
                case 3:
                    _b = _c.sent(), createPinResponse = _b[0], createPinError = _b[1];
                    if (createPinError) {
                        dispatch(setLoading(false));
                        onFail === null || onFail === void 0 ? void 0 : onFail();
                        ANALYTICS.funcError(PKG, 'createPin', JSON.stringify(createPinError));
                        return [2 /*return*/, createPinError];
                    }
                    if (createPinResponse) {
                        dispatch(setLoading(false));
                        // if we're resetting pin, ignore the auth token so the user has to return to login
                        if (context === PINContext.ResetPin) {
                            dispatch(setForgotPinScreen(ForgotPinScreen.SUCCESS));
                            return [2 /*return*/];
                        }
                        dispatch(setAuth(__assign(__assign({}, auth), { token: createPinResponse.token })));
                        onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess();
                        return [2 /*return*/];
                    }
                    dispatch(setLoading(false));
                    _c.label = 4;
                case 4: return [2 /*return*/];
            }
        });
    }); };
};
export var resetPin = function (newPin, oldPin, handleError, handleSuccess) {
    return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
        var auth, deviceID, token, consumerUUID, _a, resetPinResponse, resetPinError;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    auth = getState().authSlice;
                    if (getState().apiScreenScreenSelector.deviceID === '') {
                        dispatch(setDeviceID(getNewDeviceID()));
                    }
                    deviceID = getState().apiScreenScreenSelector.deviceID;
                    dispatch(setLoading(true));
                    token = auth.token;
                    consumerUUID = getState().consumerInfoSelector.consumerUUID;
                    return [4 /*yield*/, resetPinHelper(token, consumerUUID, oldPin, newPin, deviceID)];
                case 1:
                    _a = _b.sent(), resetPinResponse = _a[0], resetPinError = _a[1];
                    if (resetPinError) {
                        handleError();
                        ANALYTICS.funcError(PKG, 'resetPin', JSON.stringify(resetPinError));
                        return [2 /*return*/, resetPinError];
                    }
                    dispatch(setLoading(false));
                    handleSuccess();
                    return [2 /*return*/];
            }
        });
    }); };
};
export var verifyPinHelper = function (pin, requestID, deviceID, context, token) { return __awaiter(void 0, void 0, void 0, function () {
    var url, data, error_28;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                url = context === PINContext.Login ? URL.CONSUMER_MFA_PIN : URL.CONSUMER_PIN_VERIFY;
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                return [4 /*yield*/, Request({
                        url: url,
                        method: METHOD.POST,
                        token: token,
                        body: {
                            pin: pin,
                            requestID: requestID,
                        },
                        deviceID: deviceID,
                    })];
            case 2:
                data = _a.sent();
                return [2 /*return*/, [data, null]];
            case 3:
                error_28 = _a.sent();
                return [2 /*return*/, [null, error_28]];
            case 4: return [2 /*return*/];
        }
    });
}); };
export var home = function (token, deviceID) { return __awaiter(void 0, void 0, void 0, function () {
    var e, data, error_29;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!token) {
                    e = new Error('no token');
                    ANALYTICS.funcError(PKG, 'home', String(e));
                    return [2 /*return*/, [null, e]];
                }
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                return [4 /*yield*/, Request({
                        url: URL.HOME_API,
                        token: token,
                        deviceID: deviceID,
                    })];
            case 2:
                data = _a.sent();
                return [2 /*return*/, [data, null]];
            case 3:
                error_29 = _a.sent();
                ANALYTICS.funcError(PKG, 'home', String(error_29));
                return [2 /*return*/, [null, error_29]];
            case 4: return [2 /*return*/];
        }
    });
}); };
export var loadHomeAcceptedOffer = function () {
    return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            dispatch(setNotAcceptedOffer(false));
            dispatch(setLoading(false));
            dispatch(setScreen(SCREEN.ACCOUNT));
            return [2 /*return*/];
        });
    }); };
};
export var getStatements = function () {
    return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
        var auth, lastStatementID, token, selectedProductAccount, deviceID, _a, statementResponse, statementError;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    dispatch(setLoading(true));
                    auth = getState().authSlice;
                    lastStatementID = getState().statementsSelector.lastStatementID;
                    token = auth.token;
                    selectedProductAccount = getState().homeSelector.selectedProductAccount;
                    if (getState().apiScreenScreenSelector.deviceID === '') {
                        dispatch(setDeviceID(getNewDeviceID()));
                    }
                    deviceID = getState().apiScreenScreenSelector.deviceID;
                    return [4 /*yield*/, statement(token, lastStatementID, deviceID, selectedProductAccount === null || selectedProductAccount === void 0 ? void 0 : selectedProductAccount.productAccountUUID)];
                case 1:
                    _a = _b.sent(), statementResponse = _a[0], statementError = _a[1];
                    if (statementError || !statementResponse) {
                        dispatch(setLoading(false));
                        return [2 /*return*/];
                    }
                    dispatch(updateStatements(statementResponse));
                    dispatch(updateStatementMedium(statementResponse.statementMedium));
                    dispatch(setLoading(false));
                    dispatch(setLastStatementID(''));
                    return [2 /*return*/];
            }
        });
    }); };
};
export var statement = function (token, lastStatementID, deviceID, productAccountUUID) { return __awaiter(void 0, void 0, void 0, function () {
    var data, error_30;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, Request({
                        url: "".concat(URL.STATEMENTS, "?limit=10&lastStatementID=").concat(lastStatementID, "&productAccountUUID=").concat(productAccountUUID),
                        token: token,
                        deviceID: deviceID,
                    })];
            case 1:
                data = _a.sent();
                return [2 /*return*/, [data, null]];
            case 2:
                error_30 = _a.sent();
                ANALYTICS.funcError(PKG, 'statement', String(error_30));
                return [2 /*return*/, [null, error_30]];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var submitAccountUpdate = function (body, onSuccess, onFail) {
    return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
        var auth, token, deviceID, _a, accountUpdateResponse, accountUpdateError;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    dispatch(setLoading(true));
                    auth = getState().authSlice;
                    token = auth.token;
                    if (getState().apiScreenScreenSelector.deviceID === '') {
                        dispatch(setDeviceID(getNewDeviceID()));
                    }
                    deviceID = getState().apiScreenScreenSelector.deviceID;
                    return [4 /*yield*/, accountUpdate(token, deviceID, body)];
                case 1:
                    _a = _b.sent(), accountUpdateResponse = _a[0], accountUpdateError = _a[1];
                    if (accountUpdateError || !accountUpdateResponse) {
                        dispatch(setLoading(false));
                        onFail === null || onFail === void 0 ? void 0 : onFail();
                        return [2 /*return*/];
                    }
                    if (body.consumerFactors) {
                        dispatch(setSuccessType(SUCCESS_TYPE.incomeUpdate));
                    }
                    onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess();
                    dispatch(setLoading(false));
                    return [2 /*return*/];
            }
        });
    }); };
};
export var accountUpdate = function (token, deviceID, body) { return __awaiter(void 0, void 0, void 0, function () {
    var data, error_31;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, Request({
                        url: URL.ACCOUNT_PREFERENCE,
                        method: METHOD.PUT,
                        token: token,
                        body: body,
                        deviceID: deviceID,
                    })];
            case 1:
                data = _a.sent();
                return [2 /*return*/, [data, null]];
            case 2:
                error_31 = _a.sent();
                ANALYTICS.funcError(PKG, 'accountUpdate', String(error_31));
                return [2 /*return*/, [null, error_31]];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var addPaymentAccount = function (body) {
    return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
        var auth, token, deviceID, _a, accountsResponse, accountsError;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    dispatch(setLoading(true));
                    auth = getState().authSlice;
                    token = auth.token;
                    if (getState().apiScreenScreenSelector.deviceID === '') {
                        dispatch(setDeviceID(getNewDeviceID()));
                    }
                    deviceID = getState().apiScreenScreenSelector.deviceID;
                    return [4 /*yield*/, accounts(token, deviceID, body)];
                case 1:
                    _a = _b.sent(), accountsResponse = _a[0], accountsError = _a[1];
                    if (accountsError || !accountsResponse) {
                        body.onFail && body.onFail();
                        ANALYTICS.funcError(PKG, 'addPaymentAccount', JSON.stringify(accountsError));
                        dispatch(setLoading(false));
                        dispatch(setAccountAndRoutingError('error'));
                        return [2 /*return*/];
                    }
                    body.onSuccess && body.onSuccess();
                    dispatch(setPrimaryPaymentAccount(accountsResponse.paymentAccount));
                    dispatch(setLoading(false));
                    return [2 /*return*/];
            }
        });
    }); };
};
export var accounts = function (token, deviceID, body) { return __awaiter(void 0, void 0, void 0, function () {
    var data, error_32;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, Request({
                        url: URL.ACCOUNTS,
                        method: METHOD.POST,
                        token: token,
                        body: body,
                        deviceID: deviceID,
                    })];
            case 1:
                data = _a.sent();
                return [2 /*return*/, [data, null]];
            case 2:
                error_32 = _a.sent();
                ANALYTICS.funcError(PKG, 'accounts', String(error_32));
                return [2 /*return*/, [null, error_32]];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var cancelPaymentAccount = function () {
    return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
        var auth, paymentAccountUUID, token, deviceID, _a, cancelAccountsResponse, cancelAccountsError;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    dispatch(setLoading(true));
                    auth = getState().authSlice;
                    paymentAccountUUID = getState().homeSelector.primaryPaymentAccount.paymentAccountUUID;
                    token = auth.token;
                    if (getState().apiScreenScreenSelector.deviceID === '') {
                        dispatch(setDeviceID(getNewDeviceID()));
                    }
                    deviceID = getState().apiScreenScreenSelector.deviceID;
                    return [4 /*yield*/, cancelAccounts(token, paymentAccountUUID, deviceID)];
                case 1:
                    _a = _b.sent(), cancelAccountsResponse = _a[0], cancelAccountsError = _a[1];
                    if (cancelAccountsError) {
                        ANALYTICS.funcError(PKG, 'cancelPaymentAccount', JSON.stringify(cancelAccountsError));
                        dispatch(setLoading(false));
                        return [2 /*return*/];
                    }
                    dispatch(setLoading(false));
                    dispatch(setPrimaryPaymentAccount({
                        paymentAccountUUID: '',
                        status: 'apply',
                        name: '',
                        accountNumberLast4: '',
                        reason: '',
                        accountType: '',
                    }));
                    return [2 /*return*/];
            }
        });
    }); };
};
export var cancelAccounts = function (token, paymentAccountUUID, deviceID) { return __awaiter(void 0, void 0, void 0, function () {
    var data, error_33;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, Request({
                        url: "".concat(URL.ACCOUNTS, "/").concat(paymentAccountUUID).concat(URL.CANCEL),
                        method: METHOD.PUT,
                        token: token,
                        deviceID: deviceID,
                    })];
            case 1:
                data = _a.sent();
                return [2 /*return*/, [data, null]];
            case 2:
                error_33 = _a.sent();
                ANALYTICS.funcError(PKG, 'cancelAccounts', String(error_33));
                return [2 /*return*/, [null, error_33]];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var getOfferDetails = function (_a) {
    var applicationUUID = _a.applicationUUID;
    return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
        var auth, token, deviceID, _a, offerDetailsResponse, offerDetailsError;
        var _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    dispatch(setLoading(true));
                    auth = getState().authSlice;
                    token = auth.token;
                    if (getState().apiScreenScreenSelector.deviceID === '') {
                        dispatch(setDeviceID(getNewDeviceID()));
                    }
                    deviceID = getState().apiScreenScreenSelector.deviceID;
                    return [4 /*yield*/, offer(applicationUUID, token, deviceID)];
                case 1:
                    _a = _c.sent(), offerDetailsResponse = _a[0], offerDetailsError = _a[1];
                    if (offerDetailsResponse) {
                        dispatch(setCardOffer((_b = offerDetailsResponse.application) === null || _b === void 0 ? void 0 : _b.cardOffer));
                    }
                    if (offerDetailsError || !offerDetailsResponse) {
                        if (offerDetailsError) {
                            ANALYTICS.funcError(PKG, 'offerDetailsResponse', JSON.stringify(offerDetailsError));
                        }
                        dispatch(setLoading(false));
                        return [2 /*return*/];
                    }
                    dispatch(setApplication(offerDetailsResponse.application));
                    dispatch(setLoading(false));
                    return [2 /*return*/];
            }
        });
    }); };
};
export var offer = function (applicationUUID, token, deviceID) { return __awaiter(void 0, void 0, void 0, function () {
    var data, error_34;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, Request({
                        url: "".concat(URL.APPS, "/").concat(applicationUUID).concat(URL.OFFER),
                        token: token,
                        deviceID: deviceID,
                    })];
            case 1:
                data = _a.sent();
                return [2 /*return*/, [data, null]];
            case 2:
                error_34 = _a.sent();
                ANALYTICS.funcError(PKG, 'offer', String(error_34));
                return [2 /*return*/, [null, error_34]];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var getConfirmLaterQR = function (productUUID) {
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var data, error_35;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, requestAssests({
                            url: "/content/".concat(productUUID, "/web-assets/confirmLaterQR.svg"),
                            image: true,
                        })];
                case 1:
                    data = (_a.sent());
                    dispatch(setConfirmLaterQR(data));
                    return [3 /*break*/, 3];
                case 2:
                    error_35 = _a.sent();
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
};
export var loadScheduledPayments = function (productAccountUUID) {
    return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
        var auth, token, deviceID, _a, scheduledPaymentsResponse, scheduledPaymentsError, scheduledPayments;
        var _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    dispatch(setLoading(true));
                    auth = getState().authSlice;
                    token = auth.token;
                    if (getState().apiScreenScreenSelector.deviceID === '') {
                        dispatch(setDeviceID(getNewDeviceID()));
                    }
                    deviceID = getState().apiScreenScreenSelector.deviceID;
                    return [4 /*yield*/, getScheduledPayments({
                            productAccountUUID: productAccountUUID,
                            token: token,
                            deviceID: deviceID,
                        })];
                case 1:
                    _a = _c.sent(), scheduledPaymentsResponse = _a[0], scheduledPaymentsError = _a[1];
                    if (scheduledPaymentsError || !scheduledPaymentsResponse) {
                        if (scheduledPaymentsError) {
                            ANALYTICS.logEvent(EVENT.OTP_FAILED, {
                                error: JSON.stringify(scheduledPaymentsError),
                            });
                        }
                        return [2 /*return*/];
                    }
                    scheduledPayments = [];
                    (_b = scheduledPaymentsResponse.activity) === null || _b === void 0 ? void 0 : _b.forEach(function (activityItem) {
                        if (activityItem !== null &&
                            activityItem.editTimeToEpochSec !== null &&
                            activityItem.transactionUUID !== null) {
                            scheduledPayments.push({
                                transactionUUID: activityItem.transactionUUID,
                                value: activityItem.amount.displaySymbol + activityItem.amount.displayValue,
                                dateTime: activityItem.subheader1,
                                editTimeDeadlineToEpochSec: activityItem.editTimeToEpochSec,
                            });
                        }
                    });
                    dispatch(setScheduledPayments(scheduledPayments));
                    dispatch(setLoading(false));
                    return [2 /*return*/];
            }
        });
    }); };
};
export var submitCancelPayment = function (transactionUUID) {
    return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
        var auth, token, deviceID, _a, cancelPaymentResponse, cancelPaymentError;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    dispatch(setLoading(true));
                    auth = getState().authSlice;
                    token = auth.token;
                    if (getState().apiScreenScreenSelector.deviceID === '') {
                        dispatch(setDeviceID(getNewDeviceID()));
                    }
                    deviceID = getState().apiScreenScreenSelector.deviceID;
                    return [4 /*yield*/, cancelPayment(token, deviceID, transactionUUID)];
                case 1:
                    _a = _b.sent(), cancelPaymentResponse = _a[0], cancelPaymentError = _a[1];
                    if (cancelPaymentError || !cancelPaymentResponse) {
                        dispatch(setLoading(false));
                        return [2 /*return*/];
                    }
                    dispatch(setSuccessType(SUCCESS_TYPE.paymentCancel));
                    dispatch(setLoading(false));
                    return [2 /*return*/];
            }
        });
    }); };
};
export var cancelPayment = function (token, deviceID, transactionUUID) { return __awaiter(void 0, void 0, void 0, function () {
    var data, error_36;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, Request({
                        url: "".concat(URL.PAYMENT, "/").concat(transactionUUID),
                        method: METHOD.DELETE,
                        token: token,
                        deviceID: deviceID,
                    })];
            case 1:
                data = _a.sent();
                return [2 /*return*/, [data, null]];
            case 2:
                error_36 = _a.sent();
                ANALYTICS.funcError(PKG, 'cancelPayment', String(error_36));
                return [2 /*return*/, [null, error_36]];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var submitUpdatePayment = function (body) {
    return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
        var auth, token, deviceID, _a, paymentResponse, paymentError;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    dispatch(setLoading(true));
                    auth = getState().authSlice;
                    token = auth.token;
                    if (getState().apiScreenScreenSelector.deviceID === '') {
                        dispatch(setDeviceID(getNewDeviceID()));
                    }
                    deviceID = getState().apiScreenScreenSelector.deviceID;
                    return [4 /*yield*/, updatePayment(token, deviceID, body)];
                case 1:
                    _a = _b.sent(), paymentResponse = _a[0], paymentError = _a[1];
                    if (paymentError || !paymentResponse) {
                        dispatch(setLoading(false));
                        return [2 /*return*/];
                    }
                    if (paymentResponse.editTimeToEpochSec) {
                        // If we do not include payment date, we are requesting for 'Pay now', otherwise for 'Pay later'.
                        dispatch(setSuccessType(body.scheduledDateEpochSec ? SUCCESS_TYPE.paymentSchedule : SUCCESS_TYPE.paymentPayNow));
                    }
                    dispatch(setLoading(false));
                    return [2 /*return*/];
            }
        });
    }); };
};
export var updatePayment = function (token, deviceID, body) { return __awaiter(void 0, void 0, void 0, function () {
    var data, error_37;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, Request({
                        url: URL.PAYMENT,
                        method: METHOD.PUT,
                        token: token,
                        body: body,
                        deviceID: deviceID,
                    })];
            case 1:
                data = _a.sent();
                return [2 /*return*/, [data, null]];
            case 2:
                error_37 = _a.sent();
                ANALYTICS.funcError(PKG, 'payment', String(error_37));
                return [2 /*return*/, [null, error_37]];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var getCardDesignOptions = function (cardDesignID, productUUID) {
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var physicalData, renderData, data, error_38;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 3, , 4]);
                    return [4 /*yield*/, requestAssests({
                            url: "/content/".concat(productUUID, "/web-assets/card-designs/physical/").concat(cardDesignID, ".svg"),
                            image: true,
                        })];
                case 1:
                    physicalData = (_a.sent());
                    return [4 /*yield*/, requestAssests({
                            url: "/content/".concat(productUUID, "/web-assets/card-designs/render/").concat(cardDesignID, ".svg"),
                            image: true,
                        })];
                case 2:
                    renderData = (_a.sent());
                    data = {
                        cardDesignID: cardDesignID,
                        physical: physicalData,
                        render: renderData,
                    };
                    dispatch(setCardDesignOptionImages(data));
                    return [3 /*break*/, 4];
                case 3:
                    error_38 = _a.sent();
                    ANALYTICS.funcError(PKG, 'getCardDesignOptionImages', String(error_38));
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
};
export var consumerLogOut = function () { return __awaiter(void 0, void 0, void 0, function () {
    var token, data, error_39;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                token = store.getState().authSlice.token;
                if (!token)
                    return [2 /*return*/];
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                return [4 /*yield*/, Request({
                        url: "".concat(URL.CONSUMER_LOGOUT),
                        method: METHOD.POST,
                        token: token,
                    })];
            case 2:
                data = _a.sent();
                return [3 /*break*/, 4];
            case 3:
                error_39 = _a.sent();
                return [2 /*return*/, [null, error_39]];
            case 4: return [2 /*return*/];
        }
    });
}); };
export var submitSetupAutoPay = function (body) {
    return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
        var auth, token, deviceID, _a, autoPayResponse, autoPayError;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    dispatch(setLoading(true));
                    auth = getState().authSlice;
                    token = auth.token;
                    if (getState().apiScreenScreenSelector.deviceID === '') {
                        dispatch(setDeviceID(getNewDeviceID()));
                    }
                    deviceID = getState().apiScreenScreenSelector.deviceID;
                    return [4 /*yield*/, setupAutoPay(token, deviceID, body)];
                case 1:
                    _a = _b.sent(), autoPayResponse = _a[0], autoPayError = _a[1];
                    if (autoPayError || !autoPayResponse) {
                        dispatch(setLoading(false));
                        return [2 /*return*/];
                    }
                    if (autoPayResponse.paymentPlan) {
                        dispatch(setSuccessType(SUCCESS_TYPE.autoPaySchedule));
                    }
                    dispatch(setLoading(false));
                    return [2 /*return*/];
            }
        });
    }); };
};
export var setupAutoPay = function (token, deviceID, body) { return __awaiter(void 0, void 0, void 0, function () {
    var data, error_40;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, Request({
                        url: URL.PAYMENT_PLAN,
                        method: METHOD.POST,
                        token: token,
                        body: body,
                        deviceID: deviceID,
                    })];
            case 1:
                data = _a.sent();
                return [2 /*return*/, [data, null]];
            case 2:
                error_40 = _a.sent();
                ANALYTICS.funcError(PKG, 'autopay', String(error_40));
                return [2 /*return*/, [null, error_40]];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var submitCancelAutoPay = function (paymentPlanUUID, request) {
    return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
        var auth, token, deviceID, _a, cancelAutoPayResponse, cancelAutoPayError;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    dispatch(setLoading(true));
                    auth = getState().authSlice;
                    token = auth.token;
                    if (getState().apiScreenScreenSelector.deviceID === '') {
                        dispatch(setDeviceID(getNewDeviceID()));
                    }
                    deviceID = getState().apiScreenScreenSelector.deviceID;
                    return [4 /*yield*/, cancelAutoPay(token, request, deviceID, paymentPlanUUID)];
                case 1:
                    _a = _b.sent(), cancelAutoPayResponse = _a[0], cancelAutoPayError = _a[1];
                    if (cancelAutoPayError || !cancelAutoPayResponse) {
                        dispatch(setLoading(false));
                        return [2 /*return*/];
                    }
                    dispatch(setSuccessType(SUCCESS_TYPE.autoPayCancel));
                    dispatch(setLoading(false));
                    return [2 /*return*/];
            }
        });
    }); };
};
export var cancelAutoPay = function (token, body, deviceID, paymentPlanUUID) { return __awaiter(void 0, void 0, void 0, function () {
    var data, error_41;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, Request({
                        url: "".concat(URL.PAYMENT_PLAN, "/").concat(paymentPlanUUID),
                        method: METHOD.DELETE,
                        token: token,
                        body: body,
                        deviceID: deviceID,
                    })];
            case 1:
                data = _a.sent();
                return [2 /*return*/, [data, null]];
            case 2:
                error_41 = _a.sent();
                ANALYTICS.funcError(PKG, 'cancelAutoPay', String(error_41));
                return [2 /*return*/, [null, error_41]];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var setOfferPageForCard = function (productAccountPendingOffer) {
    return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
        var productUUID, auth;
        return __generator(this, function (_a) {
            productUUID = productAccountPendingOffer.productUUID;
            auth = getState().authSlice;
            if (getState().apiScreenScreenSelector.deviceID === '') {
                dispatch(setDeviceID(getNewDeviceID()));
            }
            loadProductAccount({
                productAccountUUID: productAccountPendingOffer.productAccountUUID,
            }).then(function () { return __awaiter(void 0, void 0, void 0, function () {
                var applicationUUID;
                var _a, _b;
                return __generator(this, function (_c) {
                    switch (_c.label) {
                        case 0:
                            applicationUUID = (_b = (_a = store.getState().productAccountSelector.actions[0]) === null || _a === void 0 ? void 0 : _a.params) === null || _b === void 0 ? void 0 : _b.applicationUUID;
                            if (!applicationUUID || applicationUUID === '') {
                                ANALYTICS.funcError(PKG, 'setOfferPageForCard', 'applicationUUID is empty');
                                return [2 /*return*/];
                            }
                            dispatch(setNotAcceptedOffer(true));
                            dispatch(setProductUUID(productUUID));
                            dispatch(setAuth(__assign(__assign({}, auth), { applicationUUID: applicationUUID })));
                            return [4 /*yield*/, dispatch(getColor(productUUID))];
                        case 1:
                            _c.sent();
                            return [4 /*yield*/, dispatch(getLogoIcon(productUUID))];
                        case 2:
                            _c.sent();
                            return [4 /*yield*/, dispatch(getRewardDetails(productUUID))];
                        case 3:
                            _c.sent();
                            return [4 /*yield*/, dispatch(getCardBenefitsImage(productUUID))];
                        case 4:
                            _c.sent();
                            return [4 /*yield*/, dispatch(getCardOfferImg(productUUID))];
                        case 5:
                            _c.sent();
                            return [4 /*yield*/, dispatch(getConfirmLaterQR(productUUID))];
                        case 6:
                            _c.sent();
                            return [4 /*yield*/, dispatch(getOfferDetails({ applicationUUID: applicationUUID }))];
                        case 7:
                            _c.sent();
                            getVirtualCard({ productUUID: productUUID });
                            getVirtualCardDisabled({ productUUID: productUUID });
                            return [2 /*return*/];
                    }
                });
            }); });
            return [2 /*return*/];
        });
    }); };
};
export var submitCreateLinkToken = function (request) {
    return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
        var auth, token, deviceID, _a, createLinkTokenResponse, createLinkTokenError;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    dispatch(setLoading(true));
                    auth = getState().authSlice;
                    token = auth.token;
                    if (getState().apiScreenScreenSelector.deviceID === '') {
                        dispatch(setDeviceID(getNewDeviceID()));
                    }
                    deviceID = getState().apiScreenScreenSelector.deviceID;
                    return [4 /*yield*/, createLinkToken(token, request, deviceID)];
                case 1:
                    _a = _b.sent(), createLinkTokenResponse = _a[0], createLinkTokenError = _a[1];
                    if (createLinkTokenError || !createLinkTokenResponse) {
                        dispatch(setLoading(false));
                        return [2 /*return*/];
                    }
                    dispatch(setLinkToken(createLinkTokenResponse.linkToken));
                    dispatch(setLoading(false));
                    return [2 /*return*/];
            }
        });
    }); };
};
export var createLinkToken = function (token, body, deviceID) { return __awaiter(void 0, void 0, void 0, function () {
    var data, error_42;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, Request({
                        url: "".concat(URL.LINK_TOKEN),
                        method: METHOD.POST,
                        token: token,
                        body: body,
                        deviceID: deviceID,
                    })];
            case 1:
                data = _a.sent();
                return [2 /*return*/, [data, null]];
            case 2:
                error_42 = _a.sent();
                ANALYTICS.funcError(PKG, 'createLinkToken', String(error_42));
                return [2 /*return*/, [null, error_42]];
            case 3: return [2 /*return*/];
        }
    });
}); };
