var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { BUTTON_WIDTH, ImpButton, ImpContainer, ImpFlex, ImpModal, ImpModalHeader, ImpPad, ImpSpacer, ImpText, } from '@imprint-payments/imprint-ui';
import { useState } from 'react';
import { useAppSelector } from '../../app/hooks';
import { selectLanguage } from '../../app/languageSelectors';
import imo from '../../assets/images/imprint-monthly-offer.svg';
import { EXTERNAL_URLS } from '../../utils/routeConstant';
import { MODAL_BACKGROUND } from '../spend-review';
var HEADER_BORDER_RADIUS = '12px 12px 0px 0px';
export var CLOModal = function (_a) {
    var open = _a.open, dismissedKey = _a.dismissedKey;
    var containerStyle = {
        width: 'min(600px, 100vw - 32px)',
        background: "url(".concat(imo, ")"),
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        overflow: 'hidden',
        borderRadius: HEADER_BORDER_RADIUS,
    };
    var language = useAppSelector(selectLanguage);
    var _b = useState(open), isOpen = _b[0], setIsOpen = _b[1];
    return (_jsx(ImpModal, __assign({ isOpen: isOpen, isClosing: false, respectContentHeight: true }, { children: _jsx(ImpContainer, __assign({ width: "min(600px, 100vw - 32px)", height: "min(540px, 100vh - 48px)", style: {
                overflowX: 'hidden',
            } }, { children: _jsxs(ImpContainer, __assign({ height: "340px", backgroundColor: MODAL_BACKGROUND, borderRadius: HEADER_BORDER_RADIUS, zIndex: 1, textAlign: "center" }, { children: [_jsx("div", __assign({ style: containerStyle }, { children: _jsx(ImpFlex, __assign({ direction: "column", fluid: true }, { children: _jsx(ImpModalHeader, { invertColor: true, closeModal: closeModal }) })) })), _jsx(ImpPad, __assign({ padding: "24px", mobilePadding: "12px 16px" }, { children: _jsxs(ImpFlex, __assign({ direction: "column", align: "center" }, { children: [_jsx(ImpText, __assign({ typography: "headline1" }, { children: language.cloTeaserTitle })), _jsx(ImpSpacer, { height: "s" }), _jsx(ImpText, __assign({ typography: "body1" }, { children: language.cloTeaserSubtitle })), _jsx(ImpSpacer, { height: "xl" }), _jsx(ImpContainer, __assign({ width: BUTTON_WIDTH }, { children: _jsx(ImpButton, __assign({ size: "fill", buttonStyle: "branded", onClick: onClick }, { children: language.cloTeaserCTA })) }))] })) }))] })) })) })));
    function closeModal() {
        localStorage.setItem(dismissedKey, 'true');
        setIsOpen(false);
    }
    function onClick() {
        window.open(EXTERNAL_URLS.imprintDownload);
        localStorage.setItem(dismissedKey, 'true');
        setIsOpen(false);
    }
};
