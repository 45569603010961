var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a;
import { setAccountOnboardingInfo } from '../../app/accountOnboardingSlice';
import { setLoading } from '../../app/applicationScreenSlice';
import { setAuthRequestId } from '../../app/authSlice';
import { setConsumerUUID } from '../../app/consumerInfoSlice';
import { setForgotPinNextStep, setForgotPinRequestID } from '../../app/forgotPinSlice';
import { setProductUUID } from '../../app/merchantDataSlice';
import { STATEMENT_MEDIUM } from '../../app/statementsSlice';
import { store } from '../../app/store';
import { getHandler } from '../../types/Api';
import { PRODUCTUUID } from '../../utils/productUUIDConstants';
import { API_CONFIG } from '../apiConfig';
import { httpRequest } from '../http/client';
import { ResponseCode } from '../http/httpConsts';
var handleOk = function (store, response, params) {
    var accountInfo = response.data.body;
    var dispatch = store.dispatch;
    dispatch(setLoading(false));
    dispatch(setConsumerUUID(accountInfo.consumerUUID));
    dispatch(setProductUUID(accountInfo.productUUID || ''));
    // TODO: use onboarding request ID instead
    dispatch(setAuthRequestId(accountInfo.requestID));
    var showWelcome = accountInfo.showWelcome || (accountInfo.productUUID === PRODUCTUUID.BB && !accountInfo.hasPIN);
    switch (accountInfo.nextStep) {
        case 'setPIN':
            // Forgot PIN, or non-conversion user without PIN
            dispatch(setForgotPinRequestID(accountInfo.requestID));
            dispatch(setForgotPinNextStep(accountInfo.nextStep));
            break;
        case 'setPhone':
            // Conversion user without PIN
            dispatch(setAccountOnboardingInfo({
                requestID: accountInfo.requestID,
                firstName: accountInfo.firstName,
                phone: accountInfo.phone,
                email: accountInfo.email,
                isPaperless: accountInfo.statementPreferences.statementMedium === STATEMENT_MEDIUM.paperless,
                hasPIN: accountInfo.hasPIN,
                showWelcome: showWelcome,
            }));
    }
    params.onSuccess(showWelcome, accountInfo.nextStep);
};
var handleGenericError = function (store, response, params) {
    var res = response.data.body;
    var dispatch = store.dispatch;
    dispatch(setLoading(false));
    params.onFail();
};
var handlers = (_a = {},
    _a[ResponseCode.OK] = handleOk,
    _a);
export var findAccount = function (_a) {
    var lastName = _a.lastName, dob = _a.dob, ssn = _a.ssn, onFail = _a.onFail, onSuccess = _a.onSuccess;
    return __awaiter(void 0, void 0, void 0, function () {
        var dispatch, requestID, request, apiResponse, handler;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    dispatch = store.dispatch;
                    dispatch(setLoading(true));
                    requestID = store.getState().accountOnboardingSelector.verifyPIIRequestID;
                    request = {
                        config: __assign({}, API_CONFIG.FIND_ACCOUNT),
                        body: {
                            lastName: lastName,
                            dob: dob,
                            ssn: ssn,
                            requestID: requestID,
                        },
                    };
                    return [4 /*yield*/, httpRequest(request)];
                case 1:
                    apiResponse = _b.sent();
                    handler = getHandler(apiResponse.status, handlers, handleGenericError);
                    handler(store, apiResponse, { onFail: onFail, onSuccess: onSuccess });
                    return [2 /*return*/];
            }
        });
    });
};
