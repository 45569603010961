import { jsx as _jsx } from "react/jsx-runtime";
import { formatDisplayAmount, } from '@imprint-payments/imprint-lib';
import { Icons, ImpIcon } from '@imprint-payments/imprint-ui';
import { ANALYTICS } from '../../firebase/firebase';
import { PKG } from '../../analytics/analyticsConsts';
import { formatEpochToMonthDD } from '../CardBalanceDetailsPage/dateFormatters';
export var parseStringToDisplayAmount = function (string) {
    var parsedObject = JSON.parse(string);
    return parsedObject;
};
export var parseAutopayType = function (paymentPlan, language) {
    var amountType = paymentPlan.amountType, amount = paymentPlan.amount;
    switch (amountType) {
        case 'CUSTOM':
            return formatDisplayAmount(amount);
        case 'MINIMUM':
            return language.minimum;
        case 'STATEMENT_BALANCE':
            return language.statementBalance;
        default:
            ANALYTICS.funcError(PKG, 'parseAutopayType', "unexpected autopay amountType: ".concat(amountType));
            return undefined;
    }
};
var isValidBankName = function (bankName) {
    return bankName && bankName !== '';
};
export var formatPaymentAccount = function (_a) {
    var language = _a.language, last4 = _a.last4, bankName = _a.bankName;
    return "".concat(isValidBankName(bankName) ? bankName : language.account, " (\u2022\u2022\u2022\u2022 ").concat(last4, ")");
};
export var PaymentWidgetPaymentType;
(function (PaymentWidgetPaymentType) {
    PaymentWidgetPaymentType["ScheduledPayment"] = "scheduledPayment";
    PaymentWidgetPaymentType["Autopay"] = "autopay";
})(PaymentWidgetPaymentType || (PaymentWidgetPaymentType = {}));
var parseScheduledPaymentWidget = function (widget, language, languageCode) {
    var _a, _b, _c, _d, _e;
    return {
        title: formatEpochToMonthDD(parseInt((_a = widget.params) === null || _a === void 0 ? void 0 : _a.scheduleDate), languageCode),
        subtitle: "".concat(formatPaymentAccount({
            last4: (_b = widget.params) === null || _b === void 0 ? void 0 : _b.mask,
            language: language,
            bankName: (_c = widget.params) === null || _c === void 0 ? void 0 : _c.institutionName,
        })),
        trailingTitle: "".concat(formatDisplayAmount(parseStringToDisplayAmount((_d = widget.params) === null || _d === void 0 ? void 0 : _d.paymentAmount))),
        trailingSubtitle: language.oneTime,
        trailingContent: _jsx(ImpIcon, { iconSrc: Icons.chevronRight }),
        paymentType: PaymentWidgetPaymentType.ScheduledPayment,
        params: widget.params,
        epoch: parseInt((_e = widget.params) === null || _e === void 0 ? void 0 : _e.scheduleDate),
    };
};
var parseAutopay = function (autopay, language, languageCode) {
    var _a, _b, _c, _d;
    return {
        title: formatEpochToMonthDD((_a = autopay.paymentPlan) === null || _a === void 0 ? void 0 : _a.nextPaymentEpochSec, languageCode),
        subtitle: "".concat(formatPaymentAccount({
            language: language,
            last4: (_b = autopay.paymentPlan) === null || _b === void 0 ? void 0 : _b.paymentAccount.accountNumberLast4,
            bankName: (_c = autopay.paymentPlan) === null || _c === void 0 ? void 0 : _c.paymentAccount.name,
        })),
        trailingTitle: parseAutopayType(autopay === null || autopay === void 0 ? void 0 : autopay.paymentPlan, language),
        trailingSubtitle: language.autoPay,
        trailingContent: _jsx(ImpIcon, { iconSrc: Icons.chevronRight }),
        paymentType: PaymentWidgetPaymentType.Autopay,
        epoch: (_d = autopay.paymentPlan) === null || _d === void 0 ? void 0 : _d.nextPaymentEpochSec,
    };
};
export var getUpcomingPaymentsList = function (upcomingPaymentNoAutopayWidget, autopay, language, languageCode) {
    var _a;
    if (languageCode === void 0) { languageCode = 'en-US'; }
    var toReturn = [];
    upcomingPaymentNoAutopayWidget === null || upcomingPaymentNoAutopayWidget === void 0 ? void 0 : upcomingPaymentNoAutopayWidget.map(function (widget) {
        toReturn.push(parseScheduledPaymentWidget(widget, language, languageCode));
    });
    var nextAutopayEpoch = (_a = autopay === null || autopay === void 0 ? void 0 : autopay.paymentPlan) === null || _a === void 0 ? void 0 : _a.nextPaymentEpochSec;
    if (nextAutopayEpoch) {
        toReturn.push(parseAutopay(autopay, language, languageCode));
    }
    return toReturn.sort(function (a, b) { return a.epoch - b.epoch; });
};
