var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useAppSelector } from '../../app/hooks';
import styles from './ChartRow.module.css';
import Check from '../../assets/icons/Check.svg';
export default function ChartRow(_a) {
    var body1 = _a.body1, checked = _a.checked, divider = _a.divider;
    var language = useAppSelector(function (state) { return state.languageSelector.languageLocalization; });
    return (_jsxs("div", __assign({ className: styles.row }, { children: [_jsxs("div", __assign({ className: styles.rowContent }, { children: [_jsx("div", __assign({ className: styles.description }, { children: body1 })), _jsx("div", __assign({ className: styles.rowContentTrailing }, { children: checked ? (_jsx("img", { src: Check, className: styles.check })) : (_jsx("div", __assign({ className: styles.comingSoon }, { children: language.comingSoon }))) }))] })), divider && _jsx("div", { className: styles.divider })] })));
}
