var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ActivityIcons, colors, ImpButton, ImpContainer, ImpFlex, ImpImage, ImpModal, ImpPad, ImpText, spacing } from "@imprint-payments/imprint-ui";
import { CardItem } from "../../pages/dashboard/CardItem";
import { ANALYTICS } from "../../firebase/firebase";
import { EVENT } from "../../analytics/analyticsConsts";
import { EXTERNAL_URLS } from "../../utils/routeConstant";
import { useAppSelector } from "../../app/hooks";
import { selectLanguage } from "../../app/languageSelectors";
import { useEffect, useState } from "react";
import { getChromeLogoIcon } from "../../api/api";
var CardLiftModal = function (_a) {
    var cardImg = _a.cardImg, open = _a.open, dismissedKey = _a.dismissedKey;
    var language = useAppSelector(selectLanguage);
    var merchantData = useAppSelector(function (state) { return state.merchantDataSelector; });
    var _b = useState(open), isOpen = _b[0], setIsOpen = _b[1];
    var _c = useState(''), chromeLogo = _c[0], setChromeLogoSrc = _c[1];
    var closeModal = function () {
        ANALYTICS.logEvent(EVENT.CARDLIFT_DOWNLOAD_MODAL_DISMISSED);
        localStorage.setItem(dismissedKey, "true");
        setIsOpen(false);
    };
    var onClick = function () {
        ANALYTICS.logEvent(EVENT.CARDLIFT_DOWNLOAD_MODAL_CLICKED);
        window.open(EXTERNAL_URLS.cardLiftDownload);
        localStorage.setItem(dismissedKey, "true");
        setIsOpen(false);
    };
    useEffect(function () {
        var fetchChromeLogo = function () { return __awaiter(void 0, void 0, void 0, function () {
            var src;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, getChromeLogoIcon()];
                    case 1:
                        src = _a.sent();
                        setChromeLogoSrc(src || '');
                        return [2 /*return*/];
                }
            });
        }); };
        fetchChromeLogo().catch(function (e) { return console.log(e); });
    }, []);
    return (_jsx(ImpModal, __assign({ isOpen: isOpen, isClosing: false, closeModal: closeModal }, { children: _jsx(ImpContainer, __assign({ width: "100%" }, { children: _jsx(ImpPad, __assign({ padding: spacing.xl }, { children: _jsxs(ImpFlex, __assign({ direction: "column", align: "center" }, { children: [_jsxs(ImpFlex, __assign({ direction: "column", align: "center" }, { children: [_jsx(CardItem, { image: cardImg }), _jsx(ImpContainer, __assign({ position: 'relative', backgroundColor: 'white', borderRadius: '100%', height: '70px', width: '70px', style: { top: '-35px', display: 'flex', alignItems: 'center', justifyContent: 'center' } }, { children: _jsx(ImpImage, { src: chromeLogo, alt: "google-chrome-logo", height: '45px' }) }))] })), _jsx(ImpText, __assign({ typography: "headline2" }, { children: "".concat(language.addYourCardToChrome, ",") })), _jsx(ImpText, __assign({ typography: "headline2" }, { children: language.cardLiftCashback })), _jsx(ImpPad, __assign({ padding: spacing.l }, { children: _jsx(ImpText, __assign({ typography: "label1", color: colors.content.onBackgroundVariant }, { children: language.checkoutWithCard.replace('[MERCHANT_NAME]', merchantData.merchantName) })) })), _jsx(ImpPad, __assign({ padding: spacing.l }, { children: _jsx(ImpButton, __assign({ trailingIcon: ActivityIcons.arrow, buttonStyle: "branded", color: "white", onClick: onClick }, { children: language.addCardToChrome })) }))] })) })) })) })));
};
export default CardLiftModal;
