var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { formatDisplayAmount } from '@imprint-payments/imprint-lib';
import { ImpBadgeText, ImpContainer, ImpFlex, ImpPad, ImpSpacer, ImpText, colors, } from '@imprint-payments/imprint-ui';
import { useMemo } from 'react';
import { useAppSelector } from '../../app/hooks';
import { selectLanguage, selectLanguageCode } from '../../app/languageSelectors';
import { selectCardBalanceV2, selectPaymentDetails } from '../../app/productAccountSelectors';
import { formatDateToShortMonthDay, formatTagMonDD } from './dateFormatters';
var useCardBalanceStats = function () {
    var _a = useAppSelector(selectCardBalanceV2), previousStatementSpend = _a.previousStatementSpend, currentStatementSpend = _a.currentStatementSpend;
    var languageCode = useAppSelector(selectLanguageCode);
    var language = useAppSelector(selectLanguage);
    var paymentDetails = useAppSelector(selectPaymentDetails);
    var toReturn = useMemo(function () {
        var _a, _b, _c, _d;
        return {
            statementBalance: {
                eyebrow: (_a = language.statementBalance) === null || _a === void 0 ? void 0 : _a.toUpperCase(),
                title: formatDisplayAmount(previousStatementSpend.amount),
                tag: (previousStatementSpend === null || previousStatementSpend === void 0 ? void 0 : previousStatementSpend.startDate) &&
                    formatTagMonDD(previousStatementSpend.startDate, previousStatementSpend.endDate, languageCode),
            },
            newSpend: {
                eyebrow: (_b = language.newSpend) === null || _b === void 0 ? void 0 : _b.toUpperCase(),
                title: formatDisplayAmount(currentStatementSpend.amount),
                tag: (currentStatementSpend === null || currentStatementSpend === void 0 ? void 0 : currentStatementSpend.startDate) &&
                    formatTagMonDD(currentStatementSpend.startDate, currentStatementSpend.endDate, languageCode),
            },
            minimumDue: {
                eyebrow: (_c = language.minimumDue) === null || _c === void 0 ? void 0 : _c.toUpperCase(),
                title: paymentDetails.hasDue ? formatDisplayAmount(paymentDetails.minDue) : language.no,
            },
            dueDate: {
                eyebrow: (_d = language.dueDate) === null || _d === void 0 ? void 0 : _d.toUpperCase(),
                title: paymentDetails.hasDue
                    ? formatDateToShortMonthDay(paymentDetails.dueDay, languageCode)
                    : language.no,
            },
        };
    }, [previousStatementSpend, languageCode, paymentDetails]);
    return toReturn;
};
export function CardBalanceStats() {
    var _a = useCardBalanceStats(), statementBalance = _a.statementBalance, newSpend = _a.newSpend, minimumDue = _a.minimumDue, dueDate = _a.dueDate;
    return (_jsxs(ImpFlex, __assign({ direction: "column", fluid: true, gap: "8px" }, { children: [_jsxs(ImpFlex, __assign({ gap: "8px", fluid: true, align: "stretch" }, { children: [_jsx(CardBalanceItem, __assign({}, statementBalance)), _jsx(CardBalanceItem, __assign({}, newSpend))] })), _jsxs(ImpFlex, __assign({ gap: "8px", fluid: true, align: "stretch" }, { children: [_jsx(CardBalanceItem, __assign({}, minimumDue)), _jsx(CardBalanceItem, __assign({}, dueDate))] }))] })));
}
function CardBalanceItem(_a) {
    var eyebrow = _a.eyebrow, title = _a.title, tag = _a.tag;
    return (_jsx(ImpContainer, __assign({ width: "100%", borderRadius: "8px", backgroundColor: colors.surface.surfaceHigh }, { children: _jsx(ImpPad, __assign({ padding: "16px", fluid: true }, { children: _jsxs(ImpFlex, __assign({ fluid: true, direction: "column" }, { children: [_jsx(ImpText, __assign({ typography: "overline", color: colors.content.onSurfaceVariant }, { children: eyebrow })), _jsx(ImpSpacer, { height: "xs" }), _jsx(ImpText, __assign({ typography: "headline3" }, { children: title })), tag ? (_jsxs(_Fragment, { children: [_jsx(ImpSpacer, { height: "l" }), _jsx(ImpBadgeText, __assign({ variant: "primary" }, { children: tag }))] })) : (_jsxs(_Fragment, { children: [_jsx(ImpSpacer, { height: "l" }), _jsx(ImpSpacer, { height: "xl" })] }))] })) })) })));
}
