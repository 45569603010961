var _a;
import { createSlice } from '@reduxjs/toolkit';
export var STATEMENT_MEDIUM;
(function (STATEMENT_MEDIUM) {
    STATEMENT_MEDIUM["paperless"] = "PAPERLESS";
    STATEMENT_MEDIUM["both"] = "BOTH";
})(STATEMENT_MEDIUM || (STATEMENT_MEDIUM = {}));
var initialState = {
    statements: [],
    lastStatementID: '',
    statementMedium: '',
};
var statementsSlice = createSlice({
    name: 'statements',
    initialState: initialState,
    reducers: {
        updateStatements: function (state, action) {
            state.statements = action.payload.statements;
        },
        setLastStatementID: function (state, action) {
            state.lastStatementID = action.payload;
        },
        resetStatements: function (state) {
            state.statements = initialState.statements;
            state.lastStatementID = initialState.lastStatementID;
        },
        updateStatementMedium: function (state, action) {
            state.statementMedium = action.payload;
        },
    },
});
export var updateStatements = (_a = statementsSlice.actions, _a.updateStatements), setLastStatementID = _a.setLastStatementID, resetStatements = _a.resetStatements, updateStatementMedium = _a.updateStatementMedium;
export default statementsSlice.reducer;
