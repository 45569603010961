var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ImpBreadcrumbs, ImpSpacer } from '@imprint-payments/imprint-ui';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { EVENT } from '../../analytics/analyticsConsts';
import { loadHome } from '../../api/loadHome.api';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { ActivatePhysicalCardScreen, setPhysicalCardActivationScreen, } from '../../app/physicalCardActivationSlice';
import { shouldShowActivatePhysicalCardOption } from '../../components/CardDetails/CardDetailsPage';
import HeaderServicing from '../../components/HeaderServicing/HeaderServicing';
import Spinner from '../../components/spinner/Spinner';
import { ANALYTICS } from '../../firebase/firebase';
import { navigateToAccountPage } from '../../utils/functionUtils';
import { PAGE } from '../../utils/routeConstant';
import { ActivatePhysicalCardCodeEntry } from './ActivatePhysicalCardCodeEntry';
import styles from './ActivatePhysicalCardPage.module.css';
import { ActivatePhysicalCardSuccess } from './ActivatePhysicalCardSuccess';
export default function ActivatePhysicalCardPage() {
    var navigate = useNavigate();
    var dispatch = useAppDispatch();
    var productAccount = useAppSelector(function (state) { return state.productAccountSelector; });
    var language = useAppSelector(function (state) { return state.languageSelector.languageLocalization; });
    var physicalCardActivationState = useAppSelector(function (state) { return state.physicalCardActivationSelector; });
    var _a = useState(true), isLoading = _a[0], setIsLoading = _a[1];
    useEffect(function () {
        dispatch(loadHome());
        dispatch(setPhysicalCardActivationScreen(ActivatePhysicalCardScreen.EnterCode));
    }, []);
    useEffect(function () {
        if (productAccount.productDetails.cardName !== '') {
            setIsLoading(false);
            if (!shouldShowActivatePhysicalCardOption(productAccount)) {
                navigateToAccountPage(dispatch, navigate);
            }
            else {
                ANALYTICS.logEvent(EVENT.ACTIVATE_PHYSICAL_CARD_LAST4_VIEWED);
            }
        }
    }, [productAccount]);
    return (_jsxs("div", __assign({ className: styles.container }, { children: [_jsx(HeaderServicing, {}), _jsx(ImpBreadcrumbs, { breadcrumbs: [language.activatePhysicalCard], onBackClick: function () {
                    navigate(PAGE.HOME);
                }, onCancelClick: function () { return navigateToAccountPage(dispatch, navigate); } }), _jsx(ImpSpacer, { height: "2xl" }), isLoading ? (_jsx(Spinner, {})) : physicalCardActivationState.screen === ActivatePhysicalCardScreen.EnterCode ? (_jsx(ActivatePhysicalCardCodeEntry, {})) : (_jsx(ActivatePhysicalCardSuccess, {}))] })));
}
