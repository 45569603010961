var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a;
import { setAuthRequestId } from '../../app/authSlice';
import { setOtpScreenState } from '../../app/otpScreenSlice';
import { SCREEN, setScreen } from '../../app/screenStateSlice';
import { store } from '../../app/store';
import { getHandler } from '../../types/Api';
import { API_CONFIG } from '../apiConfig';
import { httpRequest } from '../http/client';
import { ResponseCode } from '../http/httpConsts';
var handleOk = function (store, response) {
    var res = response.data.body;
    var dispatch = store.dispatch;
    switch (res.nextStep) {
        case 'verifyOTP':
        case 'verifySetPhoneOTP':
            dispatch(setAuthRequestId(res.requestID));
            var phone = store.getState().accountOnboardingSelector.phone;
            dispatch(setOtpScreenState({
                method: 'phone',
                destination: phone,
            }));
            dispatch(setScreen(SCREEN.SERVICE_OTPSCREEN));
            break;
        case 'idv':
            break;
        default:
            dispatch(setScreen(SCREEN.IDV_ERROR));
    }
};
var handleGenericError = function (store, response) {
    var dispatch = store.dispatch;
    dispatch(setScreen(SCREEN.IDV_ERROR));
};
var handlers = (_a = {},
    _a[ResponseCode.OK] = handleOk,
    _a);
export var checkIdvStatus = function () { return __awaiter(void 0, void 0, void 0, function () {
    var consumerUUID, requestID, request, apiResponse, handler;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                consumerUUID = store.getState().consumerInfoSelector.consumerUUID;
                requestID = store.getState().authSlice.requestID;
                request = {
                    config: __assign({}, API_CONFIG.IDV_STATUS),
                    body: {
                        consumerUUID: consumerUUID,
                        requestID: requestID,
                    },
                };
                return [4 /*yield*/, httpRequest(request)];
            case 1:
                apiResponse = _a.sent();
                handler = getHandler(apiResponse.status, handlers, handleGenericError);
                handler(store, apiResponse);
                return [2 /*return*/];
        }
    });
}); };
