var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { ImpButton, ImpContainer, ImpPad, ImpText } from '@imprint-payments/imprint-ui';
export default function ModalDefinitionText(_a) {
    var text = _a.text, onClick = _a.onClick;
    return (_jsx(ImpPad, __assign({ padding: "12px 24px" }, { children: _jsx(ImpButton, __assign({ variant: "inline", onClick: onClick }, { children: _jsx(ImpContainer, __assign({ textAlign: "left" }, { children: _jsx(ImpText, __assign({ typography: "body1", underline: true }, { children: text })) })) })) })));
}
