var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ImpFlex, ImpFlexItem, ImpInput, ImpSelect, ImpSpacer, ImpText, colors, } from '@imprint-payments/imprint-ui';
import { formatDateValue } from '../../utils/formatUtils';
import { useEffect, useState } from 'react';
import { EVENT } from '../../analytics/analyticsConsts';
import { useAppSelector } from '../../app/hooks';
import { selectMerchantData } from '../../app/merchantDataSelectors';
import { ANALYTICS } from '../../firebase/firebase';
import { ValidationResponse, getShowError, getValidationString, validateBirthDate, } from '../../utils/validateUtils';
export function DobInput(_a) {
    var isDisabled = _a.isDisabled, onDobChange = _a.onDobChange;
    var language = useAppSelector(function (state) { return state.languageSelector.languageLocalization; });
    var merchantData = useAppSelector(selectMerchantData);
    var _b = useState(''), month = _b[0], setMonth = _b[1];
    var _c = useState(''), day = _c[0], setDay = _c[1];
    var _d = useState(''), year = _d[0], setYear = _d[1];
    var _e = useState(ValidationResponse.empty), fullDobError = _e[0], setFullDobError = _e[1];
    var _f = useState(''), fullDob = _f[0], setFullDob = _f[1];
    useEffect(function () {
        // Update fulldob only if it exists or is filled out
        var inputDob = "".concat(month, "/").concat(day, "/").concat(year);
        if (fullDob || inputDob.length === 10) {
            setFullDob(inputDob);
        }
    }, [day, month, year]);
    useEffect(function () {
        if (fullDob) {
            var dobError = validateBirthDate(fullDob);
            setFullDobError(dobError);
            var isValid = !getShowError(dobError);
            onDobChange({ day: day, month: month, year: year, isValid: isValid });
        }
    }, [fullDob]);
    return (_jsxs("div", { children: [_jsxs(ImpFlex, __assign({ direction: "row", align: "flex-start", gap: "s", fluid: true }, { children: [_jsx(ImpFlexItem, __assign({ flexGrow: 1 }, { children: _jsx(ImpSelect, { options: language.months.map(function (value, index) {
                                return { value: index + 1, label: value };
                            }), label: language.month, isDisabled: isDisabled, onOptionChange: function (option) {
                                setMonth(formatDateValue(option));
                                ANALYTICS.logEvent(EVENT.FIND_ACCOUNT_DOB_MONTH_FILLED_OUT, {
                                    product_uuid: merchantData.productUUID,
                                });
                            }, showError: getShowError(fullDobError) }) })), _jsx(ImpFlexItem, __assign({ flexGrow: 1 }, { children: _jsx(ImpInput, { label: language.day, isDisabled: isDisabled, showError: getShowError(fullDobError), onChange: function (e) { return setDay(formatDateValue(e.target.value)); }, onBlur: function () {
                                return ANALYTICS.logEvent(EVENT.FIND_ACCOUNT_DOB_DAY_FILLED_OUT, {
                                    product_uuid: merchantData.productUUID,
                                });
                            }, maxLength: 2, numeric: true }) })), _jsx(ImpFlexItem, __assign({ flexGrow: 1 }, { children: _jsx(ImpInput, { label: language.year, isDisabled: isDisabled, showError: getShowError(fullDobError), onChange: function (e) { return setYear(e.target.value); }, onBlur: function () {
                                return ANALYTICS.logEvent(EVENT.FIND_ACCOUNT_DOB_YEAR_FILLED_OUT, {
                                    product_uuid: merchantData.productUUID,
                                });
                            }, maxLength: 4, numeric: true }) }))] })), getShowError(fullDobError) && (_jsxs(_Fragment, { children: [_jsx(ImpSpacer, { height: "s" }), _jsx(ImpText, __assign({ typography: "body3", color: colors.content.onBackgroundError }, { children: getValidationString(fullDobError, language) }))] }))] }));
}
