var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Icons, ImpBreadcrumbs, ImpButton, ImpContainer, ImpFlex, ImpIcon, ImpList, ImpModal, ImpSpacer, ImpText, colors, useModal, } from '@imprint-payments/imprint-ui';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { loadHome } from '../../api/loadHome.api';
import { selectToken } from '../../app/authSelectors';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectLanguageCode } from '../../app/languageSelectors';
import english from '../../assets/languages/en.json';
import HeaderServicing, { HEADER_SERVICING_TYPE, } from '../../components/HeaderServicing/HeaderServicing';
import { navigateToAccountPage } from '../../utils/functionUtils';
import { LANGUAGE_CODE_TO_TEXT, LanguageCode } from '../../utils/languageConstants';
import { handleLanguageChangeConfirmed, handleLanguageSelect } from '../../utils/languagelocale';
export default function ChangeLanguage() {
    var navigate = useNavigate();
    var _a = useState(false), isLoading = _a[0], setIsLoading = _a[1];
    var token = useAppSelector(selectToken);
    var handleBack = function () {
        window.history.back();
    };
    var dispatch = useAppDispatch();
    var language = useAppSelector(function (state) { return state.languageSelector.languageLocalization; });
    var languageCode = useAppSelector(selectLanguageCode);
    var languageState = useAppSelector(function (state) { return state.languageSelector; });
    var langCode = languageState.languageCode;
    var _b = useModal(false), isOpen = _b[0], isClosing = _b[1], open = _b[2], close = _b[3];
    useEffect(function () {
        if (token) {
            dispatch(loadHome());
        }
    }, [token, languageCode]);
    var getLanguageItems = function () {
        return Array.from(Object.values(LanguageCode).map(function (code) {
            return {
                title: LANGUAGE_CODE_TO_TEXT[code],
                trailingContent: (_jsx(ImpIcon, { iconSrc: code === languageState.languageCode ? Icons.radioSelected : Icons.radio })),
                onClick: function () {
                    if (code === languageState.languageCode) {
                        return;
                    }
                    if (code === LanguageCode.Spanish) {
                        open();
                    }
                    else {
                        handleLanguageSelect(code, 'language_selection_page');
                    }
                },
            };
        }));
    };
    var handleConfirmChangeLanguage = function () {
        close();
        handleLanguageChangeConfirmed(LanguageCode.Spanish, 'language_selection_page');
    };
    var handleCancelChangeLanguage = function () {
        close();
    };
    return (_jsxs(_Fragment, { children: [_jsxs(ImpModal, __assign({ isOpen: isOpen, isClosing: isClosing, closeModal: close }, { children: [_jsx(ImpFlex, __assign({ fluid: true, justify: "center" }, { children: _jsxs(ImpContainer, __assign({ width: "min(100% - 32px, 100%)", height: "100%" }, { children: [_jsx(ImpText, __assign({ typography: "headline2" }, { children: english.changeLanguageHeader })), _jsx(ImpSpacer, { height: "s" }), _jsx(ImpText, __assign({ typography: "body2", color: colors.content.onSurfaceVariant }, { children: english.changeLanguageBody })), _jsx(ImpSpacer, { height: "xl" }), _jsx(ImpFlex, __assign({ fluid: true, justify: "center" }, { children: _jsx(ImpContainer, __assign({ width: "min(100%, 356px)", height: "100%" }, { children: _jsxs(ImpFlex, __assign({ fluid: true, direction: "column", align: "center", gap: "m" }, { children: [_jsx(ImpButton, __assign({ size: "fill", onClick: handleConfirmChangeLanguage, state: isLoading ? 'disabled' : 'enabled' }, { children: english.changeLanguageButton })), _jsx(ImpButton, __assign({ size: "fill", variant: "text", onClick: handleCancelChangeLanguage, state: isLoading ? 'submitting' : 'enabled' }, { children: language.cancelBtn }))] })) })) }))] })) })), _jsx(ImpSpacer, { height: "xl" })] })), _jsx(HeaderServicing, { type: HEADER_SERVICING_TYPE.DEFAULT }), _jsx(ImpBreadcrumbs, { breadcrumbs: [language.languageText], onBackClick: handleBack, onCancelClick: function () { return navigateToAccountPage(dispatch, navigate); } }), _jsx(ImpSpacer, { height: "2xl", mobileHeight: "xl" }), _jsx(ImpFlex, __assign({ fluid: true, justify: "center" }, { children: _jsx(ImpContainer, __assign({ width: "min(100% - 32px, 600px)" }, { children: _jsx(ImpList, { items: getLanguageItems(), useSurface: true }) })) }))] }));
}
