var _a;
import { createSlice } from '@reduxjs/toolkit';
export var getEmptyAmount = function () {
    return {
        displaySymbol: '',
        displayValue: '',
        displayUnit: '',
        exponent: 0,
        negative: false,
        valueInMinUnit: 0,
    };
};
var initialState = {
    merchantName: '',
    cardTerms: {
        apr: '',
        creditLimit: {
            displaySymbol: '',
            displayValue: '',
            exponent: 0,
            valueInMinUnit: 0,
            negative: false,
            displayUnit: '',
        },
        lateFee: '',
        returnedFee: '',
        annualFee: '',
        internationalFee: '',
    },
    productUUID: '',
    cardImg: '',
    cardImgDisabled: '',
    appUiImg: '',
    webUiImg: '',
    cardOfferImg: '',
    rewardDetails: {
        merchantName: '',
        cardName: '',
        termCTA: '',
        rewardsType: '',
        signupBonus: {
            amount: '',
            amountSubtext: '',
            title: '',
            items: { text: '' },
        },
        rewards: [
            { amount: '', amountSubtext: '', title: '', items: { text: '' } },
            { amount: '', amountSubtext: '', title: '', items: { text: '' } },
        ],
        rewardsBonus: [],
        rewardsNote: '',
    },
    color: { dark: '', light: '' },
    logoIcon: '',
    logoTile: '',
    downloadAppBackgroundImg: '',
    confirmLaterQR: '',
    cardDesignOptions: [],
    cardDesignOptionsImages: {},
    cardBackImg: '',
    accountLinkModalBackgroundImg: '',
    accountLinkModalLogo: '',
};
var merchantDataSlice = createSlice({
    name: 'merchantData',
    initialState: initialState,
    reducers: {
        setProductUUID: function (state, action) {
            state.productUUID = action.payload;
        },
        setCardImg: function (state, action) {
            state.cardImg = action.payload;
        },
        setCardImgDisabled: function (state, action) {
            state.cardImgDisabled = action.payload;
        },
        setAppUiImg: function (state, action) {
            state.appUiImg = action.payload;
        },
        setWebUiImg: function (state, action) {
            state.webUiImg = action.payload;
        },
        setCardOfferImg: function (state, action) {
            state.cardOfferImg = action.payload;
        },
        setRewardDetails: function (state, action) {
            state.rewardDetails = action.payload;
        },
        setColor: function (state, action) {
            state.color = action.payload;
        },
        setLogoIcon: function (state, action) {
            state.logoIcon = action.payload;
        },
        setLogoTile: function (state, action) {
            state.logoTile = action.payload;
        },
        setMerchantName: function (state, action) {
            state.merchantName = action.payload;
        },
        setCardTerms: function (state, action) {
            state.cardTerms = action.payload;
        },
        setDownloadAppBackgroundImg: function (state, action) {
            state.downloadAppBackgroundImg = action.payload;
        },
        resetMerchantData: function () { return initialState; },
        setConfirmLaterQR: function (state, action) {
            state.confirmLaterQR = action.payload;
        },
        setCardDesignOptionImages: function (state, action) {
            var _a = action.payload, cardDesignID = _a.cardDesignID, physical = _a.physical, render = _a.render;
            state.cardDesignOptionsImages[cardDesignID] = {
                cardDesignID: cardDesignID,
                physical: physical,
                render: render,
            };
        },
        setCardBackImg: function (state, action) {
            state.cardBackImg = action.payload;
        },
        setAccountLinkModalBackgroundImg: function (state, action) {
            state.accountLinkModalBackgroundImg = action.payload;
        },
        setAccountLinkModalLogo: function (state, action) {
            state.accountLinkModalLogo = action.payload;
        },
    },
});
export var setProductUUID = (_a = merchantDataSlice.actions, _a.setProductUUID), setCardImg = _a.setCardImg, setCardImgDisabled = _a.setCardImgDisabled, setAppUiImg = _a.setAppUiImg, setWebUiImg = _a.setWebUiImg, setCardOfferImg = _a.setCardOfferImg, setRewardDetails = _a.setRewardDetails, setColor = _a.setColor, setLogoIcon = _a.setLogoIcon, setLogoTile = _a.setLogoTile, setMerchantName = _a.setMerchantName, setCardTerms = _a.setCardTerms, setDownloadAppBackgroundImg = _a.setDownloadAppBackgroundImg, resetMerchantData = _a.resetMerchantData, setConfirmLaterQR = _a.setConfirmLaterQR, setCardDesignOptionImages = _a.setCardDesignOptionImages, setCardBackImg = _a.setCardBackImg, setAccountLinkModalBackgroundImg = _a.setAccountLinkModalBackgroundImg, setAccountLinkModalLogo = _a.setAccountLinkModalLogo;
export default merchantDataSlice.reducer;
