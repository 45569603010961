import { URL } from './apiConstants';
import { DEFAULT_DELAY, Method, RequestType, RETRY_CONFIG } from './http/httpConsts';
export var RequestName;
(function (RequestName) {
    RequestName["ACCOUNT"] = "ACCOUNT";
    RequestName["ACTIVATE_PHYSICAL_CARD"] = "ACTIVATE_PHYSICAL_CARD";
    RequestName["CONFIRM_PHONE"] = "CONFIRM_PHONE";
    RequestName["DEACTIVATE_AUTH_USER"] = "DEACTIVATE_AUTH_USER";
    RequestName["DEACTIVATE_PHYSICAL_CARD"] = "DEACTIVATE_PHYSICAL_CARD";
    RequestName["EXPIRE_AUTH_APPLICATION"] = "EXPIRE_AUTH_APPLICATION";
    RequestName["FIND_ACCOUNT"] = "FIND_ACCOUNT";
    RequestName["FORGOT_PIN_DOB"] = "FORGOT_PIN_DOB";
    RequestName["GET_AUTH_APPLICATION_EVALUATION"] = "GET_AUTH_APPLICATION_EVALUATION";
    RequestName["GET_VIRTUAL_CARD"] = "GET_VIRTUAL_CARD";
    RequestName["IDV_STATUS"] = "IDV_STATUS";
    RequestName["IS_CONVERTED_USER"] = "IS_CONVERTED_USER";
    RequestName["GET_VIRTUAL_CARD_DISABLED"] = "GET_VIRTUAL_CARD_DISABLED";
    RequestName["INITIATE_AUTH_APPLICATION"] = "INITIATE_AUTH_APPLICATION";
    RequestName["INITIATE_FORGOT_PIN"] = "INITIATE_FORGOT_PIN";
    RequestName["LOAD_ACTIVITY"] = "LOAD_ACTIVITY";
    RequestName["LOAD_PRODUCT_ACCOUNT"] = "LOAD_PRODUCT_ACCOUNT";
    RequestName["PROMPT"] = "PROMPT";
    RequestName["REWARDS_REDEEM"] = "REWARDS_REDEEM";
    RequestName["SUBMIT_AUTH_APPLICATION"] = "SUBMIT_AUTH_APPLICATION";
    RequestName["UPDATE_PHONE"] = "UPDATE_PHONE";
    RequestName["UPDATE_PHONE_OTP"] = "UPDATE_PHONE_OTP";
    RequestName["UPDATE_ADDRESS"] = "UPDATE_ADDRESS";
    RequestName["UPDATE_AUTH_USER"] = "UPDATE_AUTH_USER";
    RequestName["GET_REWARD_DETAILS"] = "GET_REWARD_DETAILS";
    RequestName["SUBMIT_PAYMENT"] = "SUBMIT_PAYMENT";
    RequestName["LINK_ACCOUNT"] = "LINK_ACCOUNT";
    RequestName["LINK_ACCOUNT_V2"] = "LINK_ACCOUNT_V2";
    RequestName["GET_MEMBERSHIP"] = "GET_MEMBERSHIP";
    RequestName["UPDATE_MEMBERSHIP"] = "UPDATE_MEMBERSHIP";
    RequestName["REWARDS_DETAILS"] = "REWARDS_DETAILS";
    RequestName["WPP_APPLE"] = "WPP_APPLE";
    RequestName["WPP_GOOGLE"] = "WPP_GOOGLE";
})(RequestName || (RequestName = {}));
export var API_CONFIG = {
    LOAD_PRODUCT_ACCOUNT: {
        baseUrl: URL.LOAD_PRODUCT_ACCOUNT,
        method: Method.GET,
        retryCount: RETRY_CONFIG[Method.GET],
        retryDelay: DEFAULT_DELAY,
        type: RequestType.INTERNAL,
        keepAlive: false,
    },
    GET_VIRTUAL_CARD: {
        method: Method.GET,
        retryCount: RETRY_CONFIG[Method.GET],
        retryDelay: DEFAULT_DELAY,
        type: RequestType.EXTERNAL,
        keepAlive: false,
        image: true,
    },
    GET_VIRTUAL_CARD_DISABLED: {
        method: Method.GET,
        retryCount: RETRY_CONFIG[Method.GET],
        retryDelay: DEFAULT_DELAY,
        type: RequestType.EXTERNAL,
        keepAlive: false,
        image: true,
    },
    INITIATE_FORGOT_PIN: {
        baseUrl: URL.PIN_FORGOT,
        method: Method.POST,
        retryCount: RETRY_CONFIG[Method.POST],
        retryDelay: DEFAULT_DELAY,
        type: RequestType.INTERNAL,
        keepAlive: false,
    },
    IDV_STATUS: {
        baseUrl: URL.IDV_STATUS,
        method: Method.POST,
        retryCount: RETRY_CONFIG[Method.POST],
        retryDelay: DEFAULT_DELAY,
        type: RequestType.INTERNAL,
        keepAlive: false,
    },
    CONFIRM_PHONE: {
        baseUrl: URL.CONFIRM_PHONE,
        method: Method.POST,
        retryCount: RETRY_CONFIG[Method.POST],
        retryDelay: DEFAULT_DELAY,
        type: RequestType.INTERNAL,
        keepAlive: false,
    },
    FIND_ACCOUNT: {
        baseUrl: URL.FIND_ACCOUNT,
        method: Method.POST,
        retryCount: RETRY_CONFIG[Method.POST],
        retryDelay: DEFAULT_DELAY,
        type: RequestType.INTERNAL,
        keepAlive: false,
    },
    FORGOT_PIN_DOB: {
        baseUrl: URL.VERIFY_DOB,
        method: Method.POST,
        retryCount: RETRY_CONFIG[Method.POST],
        retryDelay: DEFAULT_DELAY,
        type: RequestType.INTERNAL,
        keepAlive: false,
    },
    INITIATE_AUTH_APPLICATION: {
        baseUrl: URL.AUTH_APPLICATION,
        method: Method.POST,
        retryCount: RETRY_CONFIG[Method.POST],
        retryDelay: DEFAULT_DELAY,
        type: RequestType.INTERNAL,
        keepAlive: false,
    },
    SUBMIT_AUTH_APPLICATION: {
        baseUrl: URL.AUTH_APPLICATION,
        method: Method.POST,
        retryCount: RETRY_CONFIG[Method.POST],
        retryDelay: DEFAULT_DELAY,
        type: RequestType.INTERNAL,
        keepAlive: false,
    },
    GET_AUTH_APPLICATION_EVALUATION: {
        baseUrl: URL.AUTH_APPLICATION,
        method: Method.GET,
        retryCount: RETRY_CONFIG[Method.GET],
        retryDelay: DEFAULT_DELAY,
        type: RequestType.INTERNAL,
        keepAlive: false,
    },
    UPDATE_AUTH_USER: {
        baseUrl: URL.AUTHORIZED_USER,
        method: Method.PUT,
        retryCount: RETRY_CONFIG[Method.PUT],
        retryDelay: DEFAULT_DELAY,
        type: RequestType.INTERNAL,
        keepAlive: false,
    },
    DEACTIVATE_AUTH_USER: {
        baseUrl: URL.AUTHORIZED_ACCOUNTS,
        method: Method.PUT,
        retryCount: RETRY_CONFIG[Method.PUT],
        retryDelay: DEFAULT_DELAY,
        type: RequestType.INTERNAL,
        keepAlive: false,
    },
    DEACTIVATE_PHYSICAL_CARD: {
        baseUrl: URL.CARD_ENDPOINT,
        method: Method.PUT,
        retryCount: RETRY_CONFIG[Method.PUT],
        retryDelay: DEFAULT_DELAY,
        type: RequestType.INTERNAL,
        keepAlive: false,
    },
    ACTIVATE_PHYSICAL_CARD: {
        baseUrl: URL.CARD_ENDPOINT,
        method: Method.PUT,
        retryCount: RETRY_CONFIG[Method.PUT],
        retryDelay: DEFAULT_DELAY,
        type: RequestType.INTERNAL,
        keepAlive: false,
    },
    PROMPT: {
        baseUrl: URL.PROMPT,
        method: Method.GET,
        retryCount: RETRY_CONFIG[Method.GET],
        retryDelay: DEFAULT_DELAY,
        type: RequestType.INTERNAL,
        keepAlive: false,
    },
    UPDATE_PHONE: {
        baseUrl: URL.ACCOUNT_PHONE,
        method: Method.POST,
        retryCount: RETRY_CONFIG[Method.POST],
        retryDelay: DEFAULT_DELAY,
        type: RequestType.INTERNAL,
        keepAlive: false,
    },
    UPDATE_PHONE_OTP: {
        baseUrl: URL.ACCOUNT_PHONE_OTP,
        method: Method.POST,
        retryCount: RETRY_CONFIG[Method.POST],
        retryDelay: DEFAULT_DELAY,
        type: RequestType.INTERNAL,
        keepAlive: false,
    },
    UPDATE_ADDRESS: {
        baseUrl: URL.UPDATE_ADDRESS,
        method: Method.POST,
        retryCount: RETRY_CONFIG[Method.POST],
        retryDelay: DEFAULT_DELAY,
        type: RequestType.INTERNAL,
        keepAlive: false,
    },
    ACCOUNT: {
        baseUrl: URL.ACCOUNT_PREFERENCE,
        method: Method.PUT,
        retryCount: RETRY_CONFIG[Method.PUT],
        retryDelay: DEFAULT_DELAY,
        type: RequestType.INTERNAL,
        keepAlive: false,
    },
    REWARDS_REDEEM: {
        baseUrl: URL.REWARDS_REDEEM,
        method: Method.POST,
        retryCount: RETRY_CONFIG[Method.POST],
        retryDelay: DEFAULT_DELAY,
        type: RequestType.INTERNAL,
        keepAlive: false,
    },
    LOAD_ACTIVITY: {
        baseUrl: URL.LOAD_ACTIVITY,
        method: Method.POST,
        retryCount: RETRY_CONFIG[Method.POST],
        retryDelay: DEFAULT_DELAY,
        type: RequestType.INTERNAL,
        keepAlive: false,
    },
    EXPIRE_AUTH_APPLICATION: {
        baseUrl: URL.AUTH_APPLICATION,
        method: Method.POST,
        retryCount: RETRY_CONFIG[Method.POST],
        retryDelay: DEFAULT_DELAY,
        type: RequestType.INTERNAL,
        keepAlive: false,
    },
    GET_REWARD_DETAILS: {
        baseUrl: URL.REWARDS_DETAILS,
        method: Method.GET,
        retryCount: RETRY_CONFIG[Method.GET],
        retryDelay: DEFAULT_DELAY,
        type: RequestType.INTERNAL,
        keepAlive: false,
    },
    SUBMIT_PAYMENT: {
        baseUrl: URL.PAYMENT,
        method: Method.POST,
        retryCount: RETRY_CONFIG[Method.POST],
        retryDelay: DEFAULT_DELAY,
        type: RequestType.INTERNAL,
        keepAlive: false,
    },
    LINK_ACCOUNT: {
        baseUrl: URL.LINK_ACCOUNT,
        method: Method.POST,
        retryCount: RETRY_CONFIG[Method.POST],
        retryDelay: DEFAULT_DELAY,
        type: RequestType.INTERNAL,
        keepAlive: false,
    },
    LINK_ACCOUNT_V2: {
        baseUrl: URL.LINK_ACCOUNT_V2,
        method: Method.POST,
        retryCount: RETRY_CONFIG[Method.POST],
        retryDelay: DEFAULT_DELAY,
        type: RequestType.INTERNAL,
        keepAlive: false,
    },
    GET_MEMBERSHIP: {
        baseUrl: URL.GET_MEMBERSHIP,
        method: Method.GET,
        retryCount: RETRY_CONFIG[Method.GET],
        retryDelay: DEFAULT_DELAY,
        type: RequestType.INTERNAL,
        keepAlive: false,
    },
    UPDATE_MEMBERSHIP: {
        baseUrl: URL.UPDATE_MEMBERSHIP,
        method: Method.PUT,
        retryCount: RETRY_CONFIG[Method.PUT],
        retryDelay: DEFAULT_DELAY,
        type: RequestType.INTERNAL,
        keepAlive: false,
    },
    REWARDS_DETAILS: {
        baseUrl: URL.PACT_REWARD_DETAILS,
        method: Method.GET,
        retryCount: RETRY_CONFIG[Method.POST],
        retryDelay: DEFAULT_DELAY,
        type: RequestType.INTERNAL,
        keepAlive: false,
    },
    WPP_APPLE: {
        baseUrl: URL.CARD_ENDPOINT,
        method: Method.POST,
        retryCount: RETRY_CONFIG[Method.POST],
        retryDelay: DEFAULT_DELAY,
        type: RequestType.INTERNAL,
        keepAlive: false,
    },
    WPP_GOOGLE: {
        baseUrl: URL.CARD_ENDPOINT,
        method: Method.POST,
        retryCount: RETRY_CONFIG[Method.POST],
        retryDelay: DEFAULT_DELAY,
        type: RequestType.INTERNAL,
        keepAlive: false,
    },
    IS_CONVERTED_USER: {
        baseUrl: URL.IS_CONVERTED_USER,
        method: Method.GET,
        retryCount: RETRY_CONFIG[Method.GET],
        retryDelay: DEFAULT_DELAY,
        type: RequestType.INTERNAL,
        keepAlive: false,
    },
};
