var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ImpButton, ImpContainer, ImpFlex, ImpInput, ImpSelect, ImpSpacer, ImpText, } from '@imprint-payments/imprint-ui';
import { useEffect, useState } from 'react';
import { EVENT } from '../../analytics/analyticsConsts';
import { updateAccount } from '../../api/updateAccount/updateAccount';
import { MODAL_ENUM, setShowModal } from '../../app/applicationScreenSlice';
import { SUCCESS_TYPE, setSuccessType } from '../../app/homeSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { FORM_BUTTON_WIDTH, FORM_INPUT_WIDTH } from '../../assets/consts/const';
import IncomeHousingModal from '../../components/popup/IncomeHousingModal';
import { ANALYTICS } from '../../firebase/firebase';
import { displayTemporarySnackbar } from '../../utils/functionUtils';
import { ValidationResponse } from '../../utils/validateUtils';
import { formatUSD, toUSDKeyDown, useFormInput } from './useFormInput';
var IncomeType;
(function (IncomeType) {
    IncomeType["Employment"] = "Employment";
    IncomeType["SelfEmployment"] = "SelfEmployment";
    IncomeType["Retirement"] = "Retirement";
    IncomeType["Unemployment"] = "Unemployment";
    IncomeType["GovtAssistance"] = "GovtAssistance";
    IncomeType["OtherIncome"] = "OtherIncome";
})(IncomeType || (IncomeType = {}));
var incomeTypes = [
    IncomeType.Employment,
    IncomeType.SelfEmployment,
    IncomeType.Retirement,
    IncomeType.Unemployment,
    IncomeType.GovtAssistance,
    IncomeType.OtherIncome,
];
var HousingType;
(function (HousingType) {
    HousingType["Rent"] = "Rent";
    HousingType["Own"] = "Own";
    HousingType["Other"] = "Other";
})(HousingType || (HousingType = {}));
var housingTypes = [HousingType.Rent, HousingType.Own, HousingType.Other];
export var validateAnnualIncome = function (i) {
    if (i.length === 0 || i === '0' || i === '$' || i === '$0') {
        return ValidationResponse.invalidIncome;
    }
    return ValidationResponse.valid;
};
export var validateHousing = function (h) {
    if (h.length === 0 || h === '$') {
        return ValidationResponse.invalidHousing;
    }
    return ValidationResponse.valid;
};
export function EditIncomePage() {
    var language = useAppSelector(function (state) { return state.languageSelector.languageLocalization; });
    var appScreenState = useAppSelector(function (state) { return state.applicationScreenSelector; });
    var consumerInfo = useAppSelector(function (state) { return state.consumerInfoSelector; });
    var _a = useState(''), incomeSource = _a[0], setIncomeSource = _a[1];
    var _b = useFormInput({
        validator: validateHousing,
        formatter: formatUSD,
        cleanFormattedValue: function (val) { return val.replace(/[^0-9]/g, ''); },
        onKeyDown: toUSDKeyDown,
        maxLength: 7,
        // accounts for formatting
        maxLengthBuffer: 3,
    }), housingCost = _b[0], isHousingCostValid = _b[1], housingCostInputProps = _b[2];
    var _c = useFormInput({
        validator: validateAnnualIncome,
        formatter: formatUSD,
        cleanFormattedValue: function (val) { return val.replace(/[^0-9]/g, ''); },
        onKeyDown: toUSDKeyDown,
        maxLength: 7,
        maxLengthBuffer: 3,
    }), income = _c[0], isIncomeValid = _c[1], incomeInputProps = _c[2];
    var _d = useState(''), housingType = _d[0], setHousingType = _d[1];
    var dispatch = useAppDispatch();
    var _e = useState('disabled'), continueBtnState = _e[0], setContinueBtnState = _e[1];
    var _f = useState(false), isSubmitting = _f[0], setIsSubmitting = _f[1];
    useEffect(function () {
        if (incomeSource === '' || !isHousingCostValid || housingType === '' || !isIncomeValid) {
            setContinueBtnState('disabled');
        }
        else if (isSubmitting) {
            setContinueBtnState('submitting');
        }
        else {
            setContinueBtnState('enabled');
        }
    }, [incomeSource, isHousingCostValid, housingType, isIncomeValid, isSubmitting]);
    var handleSubmitClick = function () {
        setIsSubmitting(true);
        var onSuccess = function () {
            dispatch(setSuccessType(SUCCESS_TYPE.incomeUpdate));
            setIsSubmitting(false);
        };
        var onError = function () {
            displayTemporarySnackbar(language.somethingWentWrong, dispatch);
            setIsSubmitting(false);
        };
        updateAccount({
            consumerUUID: consumerInfo.consumerUUID,
            consumerFactors: {
                income: income,
                incomeType: incomeSource,
                housingType: housingType,
                housingCost: housingCost,
            },
            onSuccess: onSuccess,
            onError: onError,
        });
    };
    return (_jsxs(_Fragment, { children: [appScreenState.showModal === MODAL_ENUM.showIncomeHousingModal && _jsx(IncomeHousingModal, {}), _jsx(ImpText, __assign({ typography: "headline2" }, { children: language.editIncomeHeader })), _jsx(ImpSpacer, { height: 'xl' }), _jsx(ImpContainer, __assign({ width: FORM_INPUT_WIDTH }, { children: _jsxs(ImpFlex, __assign({ direction: "column", align: "normal", gap: "m", fluid: true }, { children: [_jsx(ImpSelect, { label: language.incomeSource, options: language.incomeSourceOption.map(function (val, index) {
                                return { value: incomeTypes[index], label: val };
                            }), onOptionChange: function (val) {
                                ANALYTICS.logEvent(EVENT.PERSONAL_INFO_INCOME_HOUSING_EDIT, {
                                    field: 'primary_income_source',
                                });
                                setIncomeSource(val);
                            }, isDisabled: isSubmitting }), _jsx(ImpInput, __assign({}, incomeInputProps, { label: language.annualIncome, isDisabled: isSubmitting, errorMessage: language.invalidIncome })), _jsx(ImpSelect, { label: language.housingType, options: language.housingTypeOption.map(function (val, index) {
                                return { value: housingTypes[index], label: val };
                            }), onOptionChange: function (val) {
                                ANALYTICS.logEvent(EVENT.PERSONAL_INFO_INCOME_HOUSING_EDIT, {
                                    field: 'housing_type',
                                });
                                setHousingType(val);
                            }, isDisabled: isSubmitting }), _jsx(ImpInput, __assign({}, housingCostInputProps, { label: language.monthlyHousingCost, isDisabled: isSubmitting, errorMessage: language.invalidHousing })), _jsx(ImpText, __assign({ typography: "body2" }, { children: _jsx(ImpButton, __assign({ variant: "inline", onClick: function () {
                                    dispatch(setShowModal(MODAL_ENUM.showIncomeHousingModal));
                                } }, { children: language.learnMore })) }))] })) })), _jsx(ImpSpacer, { height: 'xl' }), _jsx(ImpContainer, __assign({ width: FORM_BUTTON_WIDTH }, { children: _jsx(ImpButton, __assign({ size: "fill", buttonStyle: "imprint", state: continueBtnState, onClick: handleSubmitClick }, { children: language.save })) }))] }));
}
