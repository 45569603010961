var _a;
import { createSlice } from '@reduxjs/toolkit';
export var PaymentPageStage;
(function (PaymentPageStage) {
    PaymentPageStage["PaymentOverview"] = "overview";
    PaymentPageStage["PaymentDetails"] = "details";
    PaymentPageStage["PaymentInEdit"] = "edit";
    PaymentPageStage["InCalendarDateSelection"] = "calendar";
    PaymentPageStage["PaymentSelected"] = "selected";
})(PaymentPageStage || (PaymentPageStage = {}));
var initialState = {
    page: PaymentPageStage.PaymentOverview,
    selectedPayment: null,
    navigateAfterBankLinking: false,
};
var paymentsPageSlice = createSlice({
    name: 'paymentsScreen',
    initialState: initialState,
    reducers: {
        setPaymentsPage: function (state, action) {
            state.page = action.payload;
        },
        setSelectedPayment: function (state, action) {
            state.selectedPayment = action.payload;
        },
        setNavigateAfterBankLinking: function (state, action) {
            state.navigateAfterBankLinking = action.payload;
        },
        resetPaymentsScreen: function (state) { return initialState; },
    },
});
export var setPaymentsPage = (_a = paymentsPageSlice.actions, _a.setPaymentsPage), setSelectedPayment = _a.setSelectedPayment, resetPaymentsScreen = _a.resetPaymentsScreen, setNavigateAfterBankLinking = _a.setNavigateAfterBankLinking;
export default paymentsPageSlice.reducer;
