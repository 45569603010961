import { useSnackbar } from '@imprint-payments/imprint-ui';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { clearServiceLoginState } from '../../utils/functionUtils';
import { addListeners, removeListeners, throttle, useSessionTimer } from './protectedRouteUtils';
// 5 minute inactive timeout
var TIMEOUT_M = 5;
// 2 minute warning threshold
var WARNING_THRESHOLD_M = 2;
export var LAST_ACTIVE_TIME_KEY = 'imprint:lastActiveTime';
export var TIMEOUT_MS = 1000 * 60 * TIMEOUT_M;
export var WARNING_THRESHOLD_MS = 1000 * 60 * WARNING_THRESHOLD_M;
var getTimeElapsed = function (lastTimestamp) { return Date.now() - lastTimestamp; };
export function SessionManager() {
    var _a = useSnackbar(), displayPersistentSnackbar = _a.displayPersistentSnackbar, updateSnackbar = _a.updateSnackbar, clearSnackbar = _a.clearSnackbar;
    var dispatch = useDispatch();
    var _b = useSessionTimer(), setTimeElapsedMS = _b.setTimeElapsedMS, isWithinWarningThreshold = _b.isWithinWarningThreshold, isPastTimeout = _b.isPastTimeout, warningText = _b.warningText, timeoutText = _b.timeoutText;
    var _c = useState(null), warningSnackbarId = _c[0], setWarningSnackbarId = _c[1];
    var handleWarning = useCallback(function () {
        var text = warningText;
        if (!warningSnackbarId) {
            var id = displayPersistentSnackbar({ text: text });
            setWarningSnackbarId(id);
        }
        else {
            updateSnackbar(warningSnackbarId, { text: text });
        }
    }, [warningSnackbarId, displayPersistentSnackbar, updateSnackbar, warningText]);
    var handleInactivity = useCallback(function () {
        if (warningSnackbarId) {
            clearSnackbar(warningSnackbarId);
            setWarningSnackbarId(null);
        }
        clearServiceLoginState(dispatch);
        displayPersistentSnackbar({ text: timeoutText });
    }, [
        dispatch,
        displayPersistentSnackbar,
        timeoutText,
        clearSnackbar,
        setWarningSnackbarId,
        warningSnackbarId,
    ]);
    var updateActivity = useCallback(function () {
        var now = Date.now();
        localStorage.setItem(LAST_ACTIVE_TIME_KEY, now.toString());
        setTimeElapsedMS(0);
        if (warningSnackbarId) {
            clearSnackbar(warningSnackbarId);
            setWarningSnackbarId(null);
        }
    }, [setTimeElapsedMS, clearSnackbar, setWarningSnackbarId]);
    useEffect(function () {
        var throttledUpdateActivity = throttle(updateActivity, 1000);
        var interval = setInterval(function () {
            var lastActiveStored = localStorage.getItem(LAST_ACTIVE_TIME_KEY);
            if (!lastActiveStored) {
                return;
            }
            var timeElapsed = getTimeElapsed(parseInt(lastActiveStored, 10));
            setTimeElapsedMS(timeElapsed);
            if (isPastTimeout) {
                handleInactivity();
            }
            else if (isWithinWarningThreshold) {
                handleWarning();
            }
        }, 1000);
        addListeners(throttledUpdateActivity);
        return function () {
            clearInterval(interval);
            removeListeners(throttledUpdateActivity);
        };
    }, [updateActivity, handleInactivity, handleWarning, setTimeElapsedMS, warningSnackbarId]);
    return null;
}
