var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { EVENT } from '../../analytics/analyticsConsts';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { ActivatePhysicalCardScreen, setPhysicalCardActivationScreen, } from '../../app/physicalCardActivationSlice';
import { ANALYTICS } from '../../firebase/firebase';
import { activatePhysicalCard } from '../../api/physicalCardActions/activatePhysicalCard.api';
import { ImpContainer, ImpFlex, ImpOtpField, ImpSpacer, ImpText } from '@imprint-payments/imprint-ui';
import { displayTemporarySnackbar } from '../../utils/functionUtils';
export function ActivatePhysicalCardCodeEntry() {
    var dispatch = useAppDispatch();
    var physicalCardInfo = useAppSelector(function (state) { return state.productAccountSelector.physicalCard; });
    var language = useAppSelector(function (state) { return state.languageSelector.languageLocalization; });
    var _a = useState(false), clearWithError = _a[0], setClearWithError = _a[1];
    function handleChange() {
        setClearWithError(false);
    }
    return (_jsx(ImpContainer, __assign({ textAlign: "center" }, { children: _jsxs(ImpFlex, __assign({ direction: "column", align: "center" }, { children: [_jsx(ImpText, __assign({ typography: "headline2", mobileTypography: "headline3" }, { children: language.activateYourCard })), _jsx(ImpSpacer, { height: "l" }), _jsx(ImpText, __assign({ typography: "body2" }, { children: language.activateYourCardSubtitle })), _jsx(ImpSpacer, { height: "xl" }), _jsx(ImpContainer, __assign({ width: "min(393px, 100vw - 32px)" }, { children: _jsx(ImpOtpField, { numInputs: 4, onChange: handleChange, clearWithError: clearWithError, onFull: function (last4) {
                            ANALYTICS.logEvent(EVENT.ACTIVATE_BUTTON_CLICKED);
                            var cardUUID = physicalCardInfo === null || physicalCardInfo === void 0 ? void 0 : physicalCardInfo.cardUUID;
                            if (!cardUUID) {
                                displayTemporarySnackbar(language.somethingWentWrong, dispatch);
                                ANALYTICS.logEvent(EVENT.CARD_ACTIVATION_FAILED);
                            }
                            else {
                                activatePhysicalCard({
                                    cardUUID: cardUUID,
                                    last4: last4,
                                    onSuccess: function () {
                                        dispatch(setPhysicalCardActivationScreen(ActivatePhysicalCardScreen.Success));
                                    },
                                    onError: function () {
                                        setClearWithError(true);
                                        ANALYTICS.logEvent(EVENT.CARD_ACTIVATION_FAILED);
                                    },
                                });
                            }
                        } }) }))] })) })));
}
