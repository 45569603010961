var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ActivityIcons, colors, Icons, ImpIcon } from '@imprint-payments/imprint-ui';
import { useEffect, useRef, useState } from 'react';
import { useAppSelector } from '../../app/hooks';
import { currencyToNumber, inputToUSD } from '../../utils/formatUtils';
import { KEYTYPE } from '../../utils/keyConstants';
import InputField from '../inputfield/InputField';
import styles from './List.module.css';
export var LIST_STYLE;
(function (LIST_STYLE) {
    LIST_STYLE[LIST_STYLE["DEFAULT"] = 0] = "DEFAULT";
    LIST_STYLE[LIST_STYLE["CHEVRON_RIGHT"] = 1] = "CHEVRON_RIGHT";
    LIST_STYLE[LIST_STYLE["SELECT"] = 2] = "SELECT";
    LIST_STYLE[LIST_STYLE["PROFILE"] = 3] = "PROFILE";
    LIST_STYLE[LIST_STYLE["CHECK"] = 4] = "CHECK";
    LIST_STYLE[LIST_STYLE["CHECK_TOGGLE"] = 5] = "CHECK_TOGGLE";
    LIST_STYLE[LIST_STYLE["ACTIVITY"] = 6] = "ACTIVITY";
    LIST_STYLE[LIST_STYLE["ACTIVITY_ITEM"] = 7] = "ACTIVITY_ITEM";
})(LIST_STYLE || (LIST_STYLE = {}));
/*
Reusable list component
- style: LIST_STYLE, determines default style for list items (default/profile => no right icon, chevronRight => chevron right, select => radio button)
- items: ListItemProps[], array of list item props (that will populate list)
- header: string, optional header for list section
- defaultSelection: number, optional default selection index for list section (for select style)
- swapTitleSubtitle: boolean, optional swap of title and subtitle location
- onItemSelect: (value: string) => void, optional callback (for select style)
- noPointerCursor: boolean, optional removal of pointer cursor for list items
*/
function ListHeader(_a) {
    var header = _a.header, style = _a.style, noBorder = _a.noBorder;
    if (style === LIST_STYLE.ACTIVITY_ITEM && header) {
        return (_jsx("div", __assign({ className: "".concat(styles.activityHeader, " ").concat(noBorder ? styles.noBorder : "") }, { children: header })));
    }
    return _jsx("div", __assign({ className: styles.header }, { children: header }));
}
export function ListSection(_a) {
    var style = _a.style, header = _a.header, items = _a.items, defaultSelection = _a.defaultSelection, swapTitleSubtitle = _a.swapTitleSubtitle, onItemSelect = _a.onItemSelect, noPointerCursor = _a.noPointerCursor, outerElementRef = _a.outerElementRef, _b = _a.viewMoreEnabled, viewMoreEnabled = _b === void 0 ? false : _b, _c = _a.noBorder, noBorder = _c === void 0 ? false : _c;
    var _d = useState(defaultSelection || 0), selectedIndex = _d[0], setSelectedIndex = _d[1];
    useEffect(function () {
        if (style === LIST_STYLE.SELECT &&
            defaultSelection &&
            onItemSelect &&
            items &&
            items.length > 0) {
            onItemSelect(items[defaultSelection].subtitle || '');
        }
    }, []);
    function getDefaultItemStyle() {
        switch (style) {
            case LIST_STYLE.SELECT:
                return ITEM_STYLE.RADIO_BUTTON;
            case LIST_STYLE.CHEVRON_RIGHT:
                return ITEM_STYLE.CHEVRON_RIGHT;
            case LIST_STYLE.PROFILE:
                return ITEM_STYLE.PROFILE;
            case LIST_STYLE.ACTIVITY:
                return ITEM_STYLE.ACTIVITY;
            default:
                return ITEM_STYLE.DEFAULT;
        }
    }
    var LONG_SEPARATOR = [LIST_STYLE.SELECT, LIST_STYLE.PROFILE];
    var separatorStyle = LONG_SEPARATOR.includes(style)
        ? styles.separatorTrailing
        : styles.separator;
    var listContentStyle = "".concat(styles.content, " ").concat(style == LIST_STYLE.ACTIVITY_ITEM && styles.adjustedBorder, " ").concat(noBorder && styles.noBorder);
    return (_jsxs("div", __assign({ className: styles.listSection }, { children: [header && items && items.length > 0 && (_jsx(ListHeader, { header: header, style: style, noBorder: noBorder })), items && items.length > 0 && (_jsx("div", __assign({ className: listContentStyle }, { children: items.map(function (item, i) {
                    var l = items.length;
                    var isSelected = selectedIndex === i;
                    var itemStyle = item.style || getDefaultItemStyle();
                    function handleItemClick(val) {
                        setSelectedIndex(i);
                        onItemSelect && val && onItemSelect(val);
                        item.handleClick && item.handleClick(val);
                    }
                    return (_jsxs("div", __assign({ className: styles.itemContainer }, { children: [_jsx(ListItem, { style: itemStyle, title: item.title, subtitle: item.subtitle, rightDescription: item.rightDescription, handleClick: function (val) { return handleItemClick(val); }, selected: isSelected, maxCustomAmount: item.maxCustomAmount, maxInputLength: item.maxInputLength, customMinPaymentNotice: item.customMinPaymentNotice, leadingIcon: item.leadingIcon, leadingIconComponent: item.leadingIconComponent, warning: item.warning, allowedDates: item.allowedDates, dueDate: item.dueDate, disabled: item.disabled, swapTitleSubtitle: swapTitleSubtitle, trailingComponent: item.trailingComponent, lastItem: item.lastItem, outerElementRef: outerElementRef, noPointerCursor: noPointerCursor || item.noPointerCursor, expandable: item.expandable, trailingTitle: item.trailingTitle, trailingSubtitle: item.trailingSubtitle, trailingSubtitleHighlight: item.trailingSubtitleHighlight, titleTopBottomMargin: item.titleTopBottomMargin, heading: item.heading, centerButton: item.centerButton, badge: item.badge }), (i < l - 1 || viewMoreEnabled) && (_jsx("div", { className: separatorStyle }))] }), i));
                }) })))] })));
}
export var ITEM_STYLE;
(function (ITEM_STYLE) {
    ITEM_STYLE[ITEM_STYLE["DEFAULT"] = 0] = "DEFAULT";
    ITEM_STYLE[ITEM_STYLE["RADIO_BUTTON"] = 1] = "RADIO_BUTTON";
    ITEM_STYLE[ITEM_STYLE["CHEVRON_RIGHT"] = 2] = "CHEVRON_RIGHT";
    ITEM_STYLE[ITEM_STYLE["CUSTOM_AMOUNT"] = 3] = "CUSTOM_AMOUNT";
    ITEM_STYLE[ITEM_STYLE["PROFILE"] = 4] = "PROFILE";
    ITEM_STYLE[ITEM_STYLE["CHECK"] = 5] = "CHECK";
    ITEM_STYLE[ITEM_STYLE["CALENDAR"] = 6] = "CALENDAR";
    ITEM_STYLE[ITEM_STYLE["TOGGLE"] = 7] = "TOGGLE";
    ITEM_STYLE[ITEM_STYLE["ACTIVITY"] = 8] = "ACTIVITY";
    ITEM_STYLE[ITEM_STYLE["TITLE_STYLE"] = 9] = "TITLE_STYLE";
    ITEM_STYLE[ITEM_STYLE["EMPTY_ACTIVITY"] = 10] = "EMPTY_ACTIVITY";
    ITEM_STYLE[ITEM_STYLE["NAVIGATE"] = 11] = "NAVIGATE";
})(ITEM_STYLE || (ITEM_STYLE = {}));
/*
Reusable list item component (populates a list)
- heading: string, a list item as a chart heading
- title: string, title of list item
- subtitle: string, optional subtitle of list item
- rightDescription: string, optional right description of list item
- style: ITEM_STYLE, optional style for list item
- handleClick: (val?: string) => void, optional callback for list item
- selected: boolean, optional selected state for list item (for RADIO_BUTTON style)
- maxCustomAmount: string, optional maximum custom currency amount for list item (for CUSTOM_AMOUNT style)
- maxInputLength: number, optional maximum length for list input (for CUSTOM_AMOUNT style)
- leadingIcon: string, optional path to image that will fill the front part of the item
- leadingIconComponent: React.ReactNode, optional component that will fill the front part of the item (use this with custom icons)
- customMinPaymentNotice: string, optional notice for asking user to cover minimum payment (for CUSTOM_AMOUNT style)
- warning: boolean, optional application of warning style to list item
- dueDate: number, optional due date (for CALENDAR style)
- allowedDates: number[], optional array of allowed dates (for CALENDAR style)
- disabled: boolean, optional disabled state for list item
- swapTitleSubtitle: boolean, optional swap of title and subtitle location
- trailingComponent: React.ReactNode, optional component that will fill the back part of the item
- noPointerCursor: boolean, optional removal of pointer cursor for list item
- titleTopBottomMargin: boolean, optional addition of top and bottom padding onto header text
*/
export function ListItem(_a) {
    var title = _a.title, heading = _a.heading, subtitle = _a.subtitle, rightDescription = _a.rightDescription, style = _a.style, leadingIcon = _a.leadingIcon, leadingIconComponent = _a.leadingIconComponent, handleClick = _a.handleClick, selected = _a.selected, maxCustomAmount = _a.maxCustomAmount, maxInputLength = _a.maxInputLength, customMinPaymentNotice = _a.customMinPaymentNotice, warning = _a.warning, dueDate = _a.dueDate, allowedDates = _a.allowedDates, disabled = _a.disabled, swapTitleSubtitle = _a.swapTitleSubtitle, trailingComponent = _a.trailingComponent, noPointerCursor = _a.noPointerCursor, lastItem = _a.lastItem, expandable = _a.expandable, outerElementRef = _a.outerElementRef, trailingTitle = _a.trailingTitle, trailingSubtitle = _a.trailingSubtitle, trailingSubtitleHighlight = _a.trailingSubtitleHighlight, titleTopBottomMargin = _a.titleTopBottomMargin, centerButton = _a.centerButton, badge = _a.badge;
    var trailingIcon;
    var _b = useState(''), input = _b[0], setInput = _b[1];
    var _c = useState(''), inputSubtitle = _c[0], setInputSubtitle = _c[1];
    var language = useAppSelector(function (state) { return state.languageSelector.languageLocalization; });
    // maximum input box can contain is $999,999,999.99
    var CUSTOM_INPUT_MAX_DIGITS = 16;
    var innerElementRef = useRef(null);
    switch (style) {
        case ITEM_STYLE.RADIO_BUTTON:
        case ITEM_STYLE.CUSTOM_AMOUNT:
        case ITEM_STYLE.CALENDAR:
            trailingIcon = selected ? Icons.radioSelected : Icons.radio;
            break;
        case ITEM_STYLE.CHEVRON_RIGHT:
            trailingIcon = Icons.chevronRight;
            break;
        case ITEM_STYLE.ACTIVITY:
            trailingIcon = expandable ? Icons.chevronRight : 'placeholder';
            break;
        case ITEM_STYLE.CHECK:
            trailingIcon = selected ? Icons.checkmark : 'none';
            break;
        case ITEM_STYLE.NAVIGATE:
            trailingIcon = ActivityIcons.arrow;
            break;
        case ITEM_STYLE.PROFILE:
        default:
            trailingIcon = 'none';
    }
    var itemStyle = "".concat(styles.item, " \n    ").concat(style === ITEM_STYLE.PROFILE && styles.itemLarge, " \n    ").concat(disabled && styles.disabled, "\n    ").concat(noPointerCursor && styles.noPointerCursor);
    var titleStyle = "".concat(styles.title, " ").concat(warning && styles.warningTitle, " ").concat(style === ITEM_STYLE.EMPTY_ACTIVITY && styles.emptyActivity);
    var nameAndIconStyle = style === ITEM_STYLE.EMPTY_ACTIVITY
        ? styles.nameAndIconCentered
        : styles.nameAndIcon;
    function handleItemClick() {
        // If a button is disabled, it will not respond to any clicks.
        if (disabled) {
            return;
        }
        if (style === ITEM_STYLE.CUSTOM_AMOUNT) {
            handleClick && handleClick(input || language.zeroAmount);
        }
        else if (style === ITEM_STYLE.RADIO_BUTTON) {
            handleClick && handleClick(subtitle);
        }
        else {
            handleClick && handleClick();
        }
    }
    useEffect(function () {
        if (style !== ITEM_STYLE.CALENDAR) {
            if (!maxCustomAmount) {
                setInputSubtitle(customMinPaymentNotice !== null && customMinPaymentNotice !== void 0 ? customMinPaymentNotice : language.minPaymentNotice);
            }
            else {
                var max = currencyToNumber(maxCustomAmount);
                var payment = currencyToNumber(input);
                if (payment > max) {
                    setInputSubtitle(language.customAmountOver);
                }
                else {
                    setInputSubtitle(customMinPaymentNotice !== null && customMinPaymentNotice !== void 0 ? customMinPaymentNotice : language.minPaymentNotice);
                }
            }
        }
    }, [input]);
    function getLeadingIcon() {
        if (leadingIcon) {
            if (badge) {
                return (_jsxs("div", __assign({ className: styles.leadingIconAndBadge }, { children: [_jsx("img", { className: style === ITEM_STYLE.ACTIVITY
                                ? styles.leadingIconActivity
                                : styles.leadingIcon, src: leadingIcon, alt: 'leading icon', tabIndex: 0 }), _jsx("img", { className: styles.badge, src: badge, alt: 'badge', tabIndex: 0 })] })));
            }
            return (_jsx("img", { className: style === ITEM_STYLE.ACTIVITY
                    ? styles.leadingIconActivity
                    : styles.leadingIcon, src: leadingIcon, alt: 'leading icon', tabIndex: 0 }));
        }
        else if (leadingIconComponent) {
            return leadingIconComponent;
        }
        else {
            return null;
        }
    }
    var getCalendarDays = function () {
        var days = [];
        var _loop_1 = function (i) {
            days.push(_jsxs("button", __assign({ className: styles.calendarButton, disabled: allowedDates === undefined ? false : !allowedDates.includes(i), onClick: function () {
                    handleClick && handleClick(i.toString());
                } }, { children: [_jsx("div", __assign({ className: styles.calendarButtonDate }, { children: i })), _jsx("div", __assign({ className: "".concat(styles.calendarButtonDue, " ").concat(dueDate === i ? styles.visible : styles.hidden) }, { children: language.calendarDue }))] }), "CalendarDay" + i));
        };
        for (var i = 1; i <= 31; i++) {
            _loop_1(i);
        }
        return days;
    };
    var getTitleAndSubtitle = function () {
        if (swapTitleSubtitle) {
            return (_jsxs(_Fragment, { children: [subtitle && (_jsx("div", __assign({ className: styles.subtitle, tabIndex: 0 }, { children: input || subtitle }))), _jsx("p", __assign({ className: titleStyle, tabIndex: 0, ref: innerElementRef }, { children: title }))] }));
        }
        else {
            return (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: "".concat(titleStyle, " ").concat(titleTopBottomMargin ? styles.titleMargin : ''), tabIndex: 0, ref: innerElementRef }, { children: title })), subtitle && (_jsx("div", __assign({ className: styles.subtitle, tabIndex: 0 }, { children: input || subtitle })))] }));
        }
    };
    var getTrailingTitleAndSubtitle = function (alwaysSubtitle) {
        return (_jsxs("div", __assign({ className: styles.trailingGroup }, { children: [_jsx("div", __assign({ className: styles.title, tabIndex: 0, ref: innerElementRef }, { children: trailingTitle })), trailingSubtitle ||
                    (trailingSubtitleHighlight && (_jsxs("div", __assign({ className: styles.subtitle, tabIndex: 0 }, { children: [trailingSubtitle, ' ', trailingSubtitleHighlight && (_jsx("span", __assign({ className: styles.trailingSubtitleHighlight }, { children: trailingSubtitleHighlight })))] })))), !trailingSubtitle && !trailingSubtitleHighlight && alwaysSubtitle && (_jsx("div", __assign({ className: styles.subtitle, tabIndex: 0 }, { children: "\u00A0" })))] })));
    };
    function getTrailingIcon(trailingIcon) {
        if (trailingIcon === 'none') {
            return;
        }
        if (trailingIcon === 'placeholder') {
            return _jsx("div", __assign({ className: styles.trailingIconSpacer }, { children: "\u00A0" }));
        }
        return (_jsx(ImpIcon, { size: "20px", iconSrc: trailingIcon, stroke: colors.content.onSurfaceVariant }));
    }
    return (_jsxs("div", __assign({ className: itemStyle, onClick: handleItemClick, onKeyDown: function (e) {
            if (e.key.toUpperCase() === KEYTYPE.ENTER) {
                handleItemClick();
            }
        } }, { children: [_jsxs("div", __assign({ className: styles.itemTextIcon }, { children: [_jsxs("div", __assign({ className: nameAndIconStyle }, { children: [heading && _jsx("div", __assign({ className: styles.heading }, { children: heading })), getLeadingIcon(), _jsx("div", __assign({ className: styles.name }, { children: getTitleAndSubtitle() }))] })), rightDescription && (_jsx("div", __assign({ className: styles.rightDescription }, { children: rightDescription }))), _jsx("div", __assign({ className: styles.name }, { children: getTrailingTitleAndSubtitle() })), getTrailingIcon(trailingIcon), trailingComponent && trailingComponent] })), (selected || input) && style === ITEM_STYLE.CUSTOM_AMOUNT && (_jsx("div", __assign({ className: styles.customValContainer }, { children: _jsxs("div", __assign({ className: styles.customValInputContainer }, { children: [_jsx(InputField, { placeholder: language.zeroAmount, value: input, fieldId: "customAmount", callback: function (value) {
                                if (value.length < CUSTOM_INPUT_MAX_DIGITS) {
                                    var formattedInput = inputToUSD(value);
                                    setInput(formattedInput || '');
                                    handleClick &&
                                        handleClick(formattedInput || language.zeroAmount);
                                }
                            }, length: maxInputLength, noLabel: true, large: true }), _jsx("p", __assign({ className: styles.inputSubtitle, tabIndex: 0 }, { children: inputSubtitle }))] })) }))), (selected || input) && style === ITEM_STYLE.CALENDAR && (_jsx("div", __assign({ className: styles.customValContainer }, { children: _jsxs("div", __assign({ className: styles.customDateInputContainer }, { children: [_jsx("div", __assign({ className: styles.calendarContainer }, { children: getCalendarDays() })), _jsx("p", __assign({ className: styles.inputSubtitle, tabIndex: 0 }, { children: inputSubtitle }))] })) }))), centerButton && (_jsx("div", __assign({ className: styles.centerButtonContainer }, { children: centerButton })))] })));
}
