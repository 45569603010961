var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ImpButton, ImpContainer, ImpFlex, ImpPad, ImpSpacer, ImpText, colors, } from '@imprint-payments/imprint-ui';
import { useAppSelector } from '../../app/hooks';
import { useNavigate } from 'react-router-dom';
import { selectLanguage } from '../../app/languageSelectors';
import { PAGE } from '../../utils/routeConstant';
import { useEffect } from 'react';
import { ANALYTICS } from '../../firebase/firebase';
import { selectPhysicalCardUUID } from '../../app/productAccountSelectors';
export function NeverReceivedCardModal(_a) {
    var closeModal = _a.closeModal;
    var language = useAppSelector(selectLanguage);
    var navigate = useNavigate();
    var physicalCardUUID = useAppSelector(selectPhysicalCardUUID);
    var neverReceivedCardAction = function () {
        ANALYTICS.logEvent('card_not_received_confirm_clicked', {
            card_uuid: physicalCardUUID,
        });
        navigate(PAGE.REPLACE_CARD);
    };
    useEffect(function () {
        ANALYTICS.logEvent('card_not_received_modal_viewed');
    }, []);
    return (_jsx(ImpContainer, __assign({ width: "min(600px, 100vw - 32px)", height: "100%" }, { children: _jsx(ImpPad, __assign({ padding: "0px 32px 32px", fluid: true }, { children: _jsxs(ImpFlex, __assign({ fluid: true, direction: "column", align: "center", justify: "space-between" }, { children: [_jsxs(ImpFlex, __assign({ direction: "column", fluid: true, gap: "s" }, { children: [_jsx(ImpText, __assign({ typography: "headline3" }, { children: language.neverReceivedCardTitle })), _jsx(ImpText, __assign({ typography: "body2", color: colors.content.onSurfaceVariant }, { children: language.neverReceivedCardDesc }))] })), _jsx(ImpSpacer, { height: "2xl" }), _jsx(ImpContainer, __assign({ width: "100%" }, { children: _jsxs(ImpFlex, __assign({ direction: "column", align: "center", gap: "l", fluid: true }, { children: [_jsx(ImpButton, __assign({ onClick: neverReceivedCardAction, variant: "primary", buttonStyle: "branded", size: "fill" }, { children: language.neverReceivedCardAction })), _jsx(ImpButton, __assign({ onClick: closeModal, variant: "text" }, { children: language.nevermindBtn }))] })) }))] })) })) })));
}
