var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Icons, ImpButton, ImpContainer, ImpDivider, ImpFlex, ImpIcon, ImpInput, ImpPad, ImpSpacer, ImpSpinner, ImpText, ImpTooltip, colors, spacing, useSnackbar, } from '@imprint-payments/imprint-ui';
import { useEffect, useRef, useState } from 'react';
import { EVENT } from '../../analytics/analyticsConsts';
import { getMembership } from '../../api/linkAccount/getMembership';
import { linkAccountV2 } from '../../api/linkAccount/linkAccountV2';
import { updateMembership } from '../../api/linkAccount/updateMembership';
import { selectAccountLink } from '../../app/accountLinkSelector';
import { AccountLinkStatus, resetAccountLinkSlice, setInvalidMembershipNumber, setStatus, setMembershipNumber as setMembershipNumberInStore } from '../../app/accountLinkSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectLanguage } from '../../app/languageSelectors';
import { ANALYTICS } from '../../firebase/firebase';
import { MembershipContext, MembershipStatus } from '../../types/MembershipAssets';
import { PartnerMembershipStatus } from '../../types/RewardActionAssets';
import { NOT_FOUND, PartnerMembershipInfos, usePartnerLanguage, } from '../../utils/partnerMembershipConstants';
import { PARTNER_TYPE, PRODUCTUUID } from '../../utils/productUUIDConstants';
import { TURKISH_MEMBERSHIP_NUMBER_LENGTH, TURKISH_MEMBERSHIP_NUMBER_PREFIX, } from '../../utils/turkishConstants';
import { ValidationResponse, getShowError, getValidationString, handleValueChange, inputWarning, validateEmail, } from '../../utils/validateUtils';
export default function AccountLinkModal(_a) {
    var _b;
    var reloadDashboard = _a.reloadDashboard, closeModal = _a.closeModal;
    var dispatch = useAppDispatch();
    var language = useAppSelector(selectLanguage);
    var accountLinkInfo = useAppSelector(selectAccountLink);
    var merchantData = useAppSelector(function (state) { return state.merchantDataSelector; });
    var replaceMerchantContent = usePartnerLanguage(merchantData.productUUID);
    var appScreenState = useAppSelector(function (state) { return state.applicationScreenSelector; });
    var languageCode = useAppSelector(function (state) { return state.languageSelector.languageCode; });
    var consumerData = useAppSelector(function (state) { return state.consumerInfoSelector; });
    var productAccountUUID = useAppSelector(function (state) { var _a; return (_a = state.homeSelector.selectedProductAccount) === null || _a === void 0 ? void 0 : _a.productAccountUUID; });
    var productAccount = useAppSelector(function (state) { return state.productAccountSelector; });
    var membershipStatus = (_b = productAccount.rewards) === null || _b === void 0 ? void 0 : _b.partnerMembershipStatus;
    var _c = useState(''), membershipNumber = _c[0], setMembershipNumber = _c[1];
    var _d = useState(''), membershipFirstName = _d[0], setMembershipFirstName = _d[1];
    var _e = useState(''), membershipLastName = _e[0], setMembershipLastName = _e[1];
    var _f = useState(false), showMembershipToolTip = _f[0], setShowMembershipToolTip = _f[1];
    var _g = useState(false), showAccountError = _g[0], setShowAccountError = _g[1];
    var _h = useState(ValidationResponse.empty), formatValidation = _h[0], setFormatValidation = _h[1];
    var _j = useState(membershipStatus === PartnerMembershipStatus.PENDING_CONFIRMATION_ACTIVE ||
        membershipStatus === PartnerMembershipStatus.PENDING_CONFIRMATION_MERGED), isLoading = _j[0], setIsLoading = _j[1];
    var shouldReloadDashboard = useRef(false);
    var tooltipContentRef = useRef(null);
    var displayTemporarySnackbar = useSnackbar().displayTemporarySnackbar;
    useEffect(function () {
        return function () {
            dispatch(setInvalidMembershipNumber(false));
            shouldReloadDashboard.current && reloadDashboard();
        };
    }, []);
    useEffect(function () {
        var handleOutsideClick = function (event) {
            if (tooltipContentRef.current && !tooltipContentRef.current.contains(event.target)) {
                setShowMembershipToolTip(false);
            }
        };
        document.addEventListener('click', handleOutsideClick);
        return function () {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [tooltipContentRef]);
    useEffect(function () {
        switch (accountLinkInfo.status) {
            case AccountLinkStatus.LINK_ACCOUNT: {
                ANALYTICS.logEvent("".concat(EVENT.MERCHANT_NUMBER_CONFIRMED_VIEWED), {
                    product_uuid: merchantData.productUUID,
                    consumer_uuid: consumerData.consumerUUID,
                    context: MembershipContext.ACTIVE,
                });
                break;
            }
            case AccountLinkStatus.MERGED_ACCOUNT: {
                ANALYTICS.logEvent("".concat(EVENT.MERCHANT_NUMBER_CONFIRMED_VIEWED), {
                    product_uuid: merchantData.productUUID,
                    consumer_uuid: consumerData.consumerUUID,
                    context: MembershipContext.MERGED,
                });
                break;
            }
            case AccountLinkStatus.CLOSED_ACCOUNT: {
                ANALYTICS.logEvent("".concat(EVENT.MERCHANT_NUMBER_CLOSED_VIEWED), {
                    product_uuid: merchantData.productUUID,
                    consumer_uuid: consumerData.consumerUUID,
                });
                break;
            }
            case AccountLinkStatus.ERROR: {
                ANALYTICS.logEvent("".concat(EVENT.MERCHANT_NUMBER_ISSUES_VIEWED), {
                    product_uuid: merchantData.productUUID,
                    consumer_uuid: consumerData.consumerUUID,
                });
                break;
            }
            case AccountLinkStatus.PENDING: {
                ANALYTICS.logEvent("".concat(EVENT.PARTNER_ACCT_RETRY_VIEWED), {
                    product_uuid: merchantData.productUUID,
                    consumer_uuid: consumerData.consumerUUID,
                });
                break;
            }
            default: {
                break;
            }
        }
    }, [accountLinkInfo.status]);
    useEffect(function () {
        if (accountLinkInfo.invalidMembershipNumber) {
            ANALYTICS.logEvent("".concat(EVENT.MERCHANT_NUMBER_INVALID_VIEWED), {
                product_uuid: merchantData.productUUID,
                consumer_uuid: consumerData.consumerUUID,
                error_type: NOT_FOUND,
            });
        }
    }, [accountLinkInfo.invalidMembershipNumber]);
    useEffect(function () {
        setShowAccountError(accountLinkInfo.invalidMembershipNumber);
    }, [accountLinkInfo.invalidMembershipNumber]);
    useEffect(function () {
        if (membershipStatus === PartnerMembershipStatus.PENDING_CONFIRMATION_ACTIVE) {
            dispatch(setStatus(AccountLinkStatus.LINK_ACCOUNT));
        }
        else if (membershipStatus === PartnerMembershipStatus.PENDING_CONFIRMATION_MERGED) {
            dispatch(setStatus(AccountLinkStatus.MERGED_ACCOUNT));
        }
        else if (membershipStatus === PartnerMembershipStatus.UNLINKED ||
            PartnerMembershipStatus.CLOSED ||
            PartnerMembershipStatus.INVALID) {
            dispatch(setStatus(AccountLinkStatus.FIND_ACCOUNT));
        }
    }, []);
    useEffect(function () {
        if (!isLoading)
            return;
        getMembership({
            productAccountUUID: productAccountUUID,
            partnerType: PARTNER_TYPE[merchantData.productUUID],
            onSuccess: function (res) {
                setMembershipFirstName(res.firstName);
                setMembershipLastName(res.lastName);
                setMembershipNumber(res.membershipSourceID);
                setIsLoading(false);
            },
            onError: onError,
        });
    }, [isLoading]);
    var getHeader = function () {
        switch (accountLinkInfo.status) {
            case AccountLinkStatus.FIND_ACCOUNT: {
                return replaceMerchantContent(language.findAccountHeader);
            }
            case AccountLinkStatus.LINK_ACCOUNT: {
                return replaceMerchantContent(language.linkAccountHeader);
            }
            case AccountLinkStatus.MERGED_ACCOUNT: {
                return replaceMerchantContent(language.mergedAccountHeader);
            }
            case AccountLinkStatus.CLOSED_ACCOUNT: {
                return replaceMerchantContent(language.closedAccountHeader);
            }
            case AccountLinkStatus.ERROR: {
                return replaceMerchantContent(language.accountLinkErrorHeader);
            }
            case AccountLinkStatus.PENDING: {
                return replaceMerchantContent(language.accountLinkInProgress);
            }
        }
    };
    var renderModalHeader = function () {
        return (_jsxs(ImpContainer, __assign({ width: "100%" }, { children: [_jsx(ImpFlex, __assign({ direction: "row", justify: "center" }, { children: _jsx(ImpContainer, __assign({ width: "min(368px, 100% - 32px)", textAlign: "center" }, { children: _jsx(ImpText, __assign({ typography: "headline3" }, { children: getHeader() })) })) })), _jsx(ImpSpacer, { height: "m" })] })));
    };
    var getSubline = function () {
        switch (accountLinkInfo.status) {
            case AccountLinkStatus.FIND_ACCOUNT: {
                if (membershipStatus === PartnerMembershipStatus.INVALID ||
                    membershipStatus === PartnerMembershipStatus.CLOSED)
                    return replaceMerchantContent(language.findAccountSublineErr);
                return replaceMerchantContent(language.findAccountSubline);
            }
            case AccountLinkStatus.LINK_ACCOUNT: {
                return replaceMerchantContent(language.linkAccountSubline);
            }
            case AccountLinkStatus.MERGED_ACCOUNT: {
                return replaceMerchantContent(language.mergedAccountSubline);
            }
            case AccountLinkStatus.CLOSED_ACCOUNT: {
                return replaceMerchantContent(language.closedAccountSubline);
            }
            case AccountLinkStatus.ERROR: {
                return replaceMerchantContent(language.accountLinkErrorSubline);
            }
            case AccountLinkStatus.PENDING: {
                return replaceMerchantContent(language.accountLinkPendingSubline);
            }
        }
    };
    var renderModalSubline = function () {
        return (_jsxs(ImpContainer, __assign({ width: "100%" }, { children: [_jsx(ImpFlex, __assign({ direction: "row", justify: "center" }, { children: _jsx(ImpContainer, __assign({ width: "min(368px, 100% - 32px)", textAlign: "center" }, { children: _jsx(ImpText, __assign({ typography: "body2", color: colors.content.onBackgroundVariant }, { children: getSubline() })) })) })), _jsx(ImpSpacer, { height: "xl" })] })));
    };
    var findMembershipHandler = function () {
        window.open(PartnerMembershipInfos[merchantData.productUUID].partnerMembershipURL[languageCode], '_blank', 'noopener');
    };
    var findAccountTrailingIcon = function (title, content, stroke) {
        var viewportWidthInPixels = window.innerWidth || document.documentElement.clientWidth;
        return (_jsx(ImpContainer, __assign({ cursor: showMembershipToolTip ? 'pointer' : 'default', containerRef: tooltipContentRef }, { children: _jsx(ImpTooltip, __assign({ crossAxisOffset: Math.max(-160, -viewportWidthInPixels + 248), position: "bottom", width: "min(368px, 100vw - 64px)", isVisible: showMembershipToolTip, content: _jsxs(ImpContainer, { children: [_jsx(ImpSpacer, { height: "s" }), _jsx(ImpText, __assign({ typography: "label2", color: "white" }, { children: title })), _jsx(ImpSpacer, { height: "xs" }), _jsx(ImpText, __assign({ typography: "body3", color: "white" }, { children: content })), _jsx(ImpSpacer, { height: "s" })] }), arrow: true, arrowOffset: -10, displayOnContentHover: false, mainAxisOffset: 8 }, { children: _jsx(ImpIcon, { iconSrc: Icons.info, ariaLabel: "Info Icon", width: "24px", stroke: stroke, strokeWidth: "2px", onClick: function () {
                        if (!showMembershipToolTip) {
                            ANALYTICS.logEvent("".concat(EVENT.MERCHANT_NUMBER_TOOLTIP_CLICKED), {
                                product_uuid: merchantData.productUUID,
                                consumer_uuid: consumerData.consumerUUID,
                            });
                        }
                        setShowMembershipToolTip(!showMembershipToolTip);
                    } }) })) })));
    };
    var findAccountBody = function () {
        return (_jsx(ImpContainer, __assign({ width: "100%" }, { children: _jsx(ImpFlex, __assign({ direction: "row", justify: "center" }, { children: _jsx(ImpContainer, __assign({ width: "min(368px, 100% - 32px)", textAlign: "center" }, { children: findAccountInputField() })) })) })));
    };
    var findAccountInputField = function () {
        var _a;
        var _b, _c;
        var resetShowAccountErr = function () {
            setShowAccountError(false);
            dispatch(setInvalidMembershipNumber(false));
        };
        var turkishInputField = (_jsx(ImpInput, { label: (_b = language[merchantData.productUUID]) === null || _b === void 0 ? void 0 : _b.accountLinking.membershipIdPrompt, numeric: true, prefix: TURKISH_MEMBERSHIP_NUMBER_PREFIX, onFocus: function () {
                ANALYTICS.logEvent("".concat(EVENT.MERCHANT_NUMBER_CLICKED), {
                    product_uuid: merchantData.productUUID,
                    consumer_uuid: consumerData.consumerUUID,
                });
            }, onChange: function (e) {
                resetShowAccountErr();
                setMembershipNumber(TURKISH_MEMBERSHIP_NUMBER_PREFIX + e.target.value);
            }, beforeChange: function (e) {
                var val = e.target.value;
                val = val
                    .replace(/[^0-9]/g, '')
                    .slice(0, TURKISH_MEMBERSHIP_NUMBER_LENGTH - TURKISH_MEMBERSHIP_NUMBER_PREFIX.length);
                e.target.value = TURKISH_MEMBERSHIP_NUMBER_PREFIX + val;
            }, errorMessage: language.enterValidMembershipNumber, showError: showAccountError, trailingIconWithColor: function (stroke) {
                var _a;
                return findAccountTrailingIcon((_a = language[merchantData.productUUID]) === null || _a === void 0 ? void 0 : _a.accountLinking.membershipIdPrompt, _jsxs(_Fragment, { children: [replaceMerchantContent(language.findMembershipBody1), _jsx(ImpButton, __assign({ variant: "inline", buttonStyle: "color", color: "white", onClick: findMembershipHandler }, { children: merchantData.merchantName })), replaceMerchantContent(language.findMembershipBody2)] }), stroke);
            }, isDisabled: appScreenState.loading }));
        var eddieBauerInputField = (_jsx(ImpInput, { label: (_c = language[merchantData.productUUID]) === null || _c === void 0 ? void 0 : _c.accountLinking.membershipIdPrompt, onFocus: function () {
                ANALYTICS.logEvent("".concat(EVENT.MERCHANT_NUMBER_CLICKED), {
                    product_uuid: merchantData.productUUID,
                    consumer_uuid: consumerData.consumerUUID,
                });
            }, value: membershipNumber, onChange: function (e) {
                resetShowAccountErr();
                setMembershipNumber(e.target.value);
                handleValueChange(validateEmail(e.target.value), setFormatValidation);
            }, onBlur: function () {
                setFormatValidation(validateEmail(membershipNumber));
            }, showWarning: inputWarning.includes(formatValidation), errorMessage: showAccountError
                ? language.eddieBauerAccountErr
                : getValidationString(formatValidation, language), showError: showAccountError || getShowError(formatValidation), trailingIconWithColor: function (stroke) {
                return findAccountTrailingIcon(language.emailAddress, language.eddieBauerTooltip, stroke);
            }, isDisabled: appScreenState.loading }));
        var map = (_a = {},
            _a[PRODUCTUUID.TURKISH] = turkishInputField,
            _a[PRODUCTUUID.EB] = eddieBauerInputField,
            _a);
        return map[merchantData.productUUID];
    };
    var nameInfoBody = function (firstName, lastName) {
        return (_jsx(ImpContainer, __assign({ width: "100%" }, { children: _jsx(ImpFlex, __assign({ direction: "row", justify: "center" }, { children: _jsx(ImpContainer, __assign({ width: "min(368px, 100% - 32px)", textAlign: "center" }, { children: _jsxs(ImpFlex, __assign({ direction: "row", fluid: true }, { children: [_jsx(ImpContainer, __assign({ width: "50%" }, { children: _jsxs(ImpFlex, __assign({ direction: "column", fluid: true }, { children: [_jsx(ImpText, __assign({ typography: "body3" }, { children: replaceMerchantContent(language.accountLinkFirstName) })), _jsx(ImpText, __assign({ typography: "body2" }, { children: firstName }))] })) })), _jsx(ImpContainer, __assign({ width: "50%" }, { children: _jsxs(ImpFlex, __assign({ direction: "column", fluid: true }, { children: [_jsx(ImpText, __assign({ typography: "body3" }, { children: replaceMerchantContent(language.accountLinkLastName) })), _jsx(ImpText, __assign({ typography: "body2" }, { children: lastName }))] })) }))] })) })) })) })));
    };
    var AccountInfoBody = function (membershipNumber, firstName, lastName) {
        var _a;
        return (_jsx(ImpContainer, __assign({ width: "100%" }, { children: _jsxs(ImpFlex, __assign({ direction: "column", justify: "center" }, { children: [_jsx(ImpContainer, __assign({ width: "100%", textAlign: "center" }, { children: _jsx(ImpFlex, __assign({ direction: "row", justify: "center" }, { children: _jsx(ImpContainer, __assign({ width: "min(368px, 100% - 32px)", textAlign: "center" }, { children: _jsxs(ImpFlex, __assign({ direction: "column", justify: "center" }, { children: [_jsx(ImpText, __assign({ typography: "body3" }, { children: (_a = language[merchantData.productUUID]) === null || _a === void 0 ? void 0 : _a.accountLinking.membershipIdPrompt })), _jsx(ImpText, __assign({ typography: "body2" }, { children: membershipNumber }))] })) })) })) })), _jsx(ImpSpacer, { height: "xl" }), _jsx(ImpContainer, __assign({ width: "100%", textAlign: "center" }, { children: _jsx(ImpFlex, __assign({ direction: "row", justify: "center" }, { children: _jsx(ImpDivider, { width: "min(368px, 100% - 32px)", color: colors.border.borderVariant }) })) })), _jsx(ImpSpacer, { height: "xl" }), nameInfoBody(firstName, lastName)] })) })));
    };
    var renderModalBody = function () {
        switch (accountLinkInfo.status) {
            case AccountLinkStatus.FIND_ACCOUNT: {
                return findAccountBody();
            }
            case AccountLinkStatus.LINK_ACCOUNT:
            case AccountLinkStatus.MERGED_ACCOUNT: {
                return AccountInfoBody(membershipNumber, membershipFirstName, membershipLastName);
            }
            // empty for closed account account and error
            default: {
                return _jsx(_Fragment, {});
            }
        }
    };
    var button1Copy = function () {
        return accountLinkInfo.status === AccountLinkStatus.FIND_ACCOUNT
            ? replaceMerchantContent(language.findAccount)
            : replaceMerchantContent(language.linkAccount);
    };
    var validateInput = function () {
        var _a;
        var _b;
        var MAX_MEMBERSHIP_NUMBER_LENGTH = 100;
        var turkish = function (input) { return input.length === TURKISH_MEMBERSHIP_NUMBER_LENGTH; };
        var eddieBauer = function (input) { return !getShowError(validateEmail(input)); };
        var minValidation = function (input) { return input.length < MAX_MEMBERSHIP_NUMBER_LENGTH; };
        var validators = (_a = {},
            _a[PRODUCTUUID.TURKISH] = turkish,
            _a[PRODUCTUUID.EB] = eddieBauer,
            _a);
        var validate = (_b = validators[merchantData.productUUID]) !== null && _b !== void 0 ? _b : minValidation;
        return validate(membershipNumber);
    };
    var isFindAccountButtonDisabled = function () {
        return (accountLinkInfo.status === AccountLinkStatus.FIND_ACCOUNT &&
            (!validateInput() || showAccountError));
    };
    var button1State = function () {
        if (appScreenState.loading) {
            return 'submitting';
        }
        if (isFindAccountButtonDisabled()) {
            return 'disabled';
        }
        else {
            return 'enabled';
        }
    };
    var button2State = function () {
        if (appScreenState.loading) {
            return 'disabled';
        }
        else {
            return 'enabled';
        }
    };
    var onFindAccountSuccess = function (response) {
        shouldReloadDashboard.current = true;
        if (response.status) {
            switch (response.status) {
                case MembershipStatus.CLOSED: {
                    dispatch(setStatus(AccountLinkStatus.CLOSED_ACCOUNT));
                    break;
                }
                case MembershipStatus.PENDING_CONFIRMATION_ACTIVE: {
                    setMembershipFirstName(response.firstName);
                    setMembershipLastName(response.lastName);
                    setMembershipNumber(response.membershipSourceID);
                    dispatch(setStatus(AccountLinkStatus.LINK_ACCOUNT));
                    break;
                }
                case MembershipStatus.PENDING_CONFIRMATION_MERGED: {
                    // first, last name and membershipSourceID are requrired in response
                    // if linkMembership is False and membership.status = ACTIVE
                    setMembershipFirstName(response.firstName);
                    setMembershipLastName(response.lastName);
                    setMembershipNumber(response.membershipSourceID);
                    dispatch(setStatus(AccountLinkStatus.MERGED_ACCOUNT));
                    break;
                }
                default: {
                    break;
                }
            }
        }
        return;
    };
    var onUpdateAccountSuccess = function (accountLinked) {
        return function () {
            dispatch(resetAccountLinkSlice());
            shouldReloadDashboard.current = true;
            if (accountLinked) {
                displayTemporarySnackbar({ text: replaceMerchantContent(language.accountLinked) });
                dispatch(setMembershipNumberInStore(membershipNumber));
            }
            closeModal();
        };
    };
    var onError = function () {
        dispatch(setStatus(AccountLinkStatus.ERROR));
        setIsLoading(false);
        return;
    };
    var handleButton1Click = function () {
        switch (accountLinkInfo.status) {
            case AccountLinkStatus.FIND_ACCOUNT: {
                linkAccountV2({
                    productUUID: merchantData.productUUID,
                    membershipSourceID: membershipNumber,
                    onSuccess: onFindAccountSuccess,
                    onError: onError,
                    onErrorWithStatusUpdate: function () { return (shouldReloadDashboard.current = true); },
                });
                ANALYTICS.logEvent("".concat(EVENT.MERCHANT_NUMBER_FIND_ACCOUNT_CLICKED), {
                    product_uuid: merchantData.productUUID,
                    consumer_uuid: consumerData.consumerUUID,
                });
                break;
            }
            case AccountLinkStatus.LINK_ACCOUNT:
            case AccountLinkStatus.MERGED_ACCOUNT: {
                updateMembership({
                    productAccountUUID: productAccountUUID,
                    status: PartnerMembershipStatus.ACTIVE,
                    onSuccess: onUpdateAccountSuccess(true),
                    onError: onError,
                });
                ANALYTICS.logEvent("".concat(EVENT.MERCHANT_NUMBER_LINK_ACCOUNT_CLICKED), {
                    product_uuid: merchantData.productUUID,
                    consumer_uuid: consumerData.consumerUUID,
                    merchant_num: membershipNumber,
                    context: MembershipContext.ACTIVE,
                });
                break;
            }
            default: {
                break;
            }
        }
    };
    var button2Copy = function () {
        switch (accountLinkInfo.status) {
            case AccountLinkStatus.FIND_ACCOUNT: {
                return replaceMerchantContent(language.signUpForMilesSmiles);
            }
            case AccountLinkStatus.LINK_ACCOUNT:
            case AccountLinkStatus.MERGED_ACCOUNT: {
                return replaceMerchantContent(language.notThisAccount);
            }
        }
    };
    var clearMembership = function () {
        setMembershipNumber('');
        setMembershipFirstName('');
        setMembershipLastName('');
    };
    var handleButton2Click = function () {
        switch (accountLinkInfo.status) {
            case AccountLinkStatus.FIND_ACCOUNT: {
                ANALYTICS.logEvent("".concat(EVENT.MERCHANT_NUMBER_SIGN_UP_CLICKED), {
                    product_uuid: merchantData.productUUID,
                    consumer_uuid: consumerData.consumerUUID,
                });
                window.open(PartnerMembershipInfos[merchantData.productUUID].partnerMembershipSignUpURL[languageCode], '_blank', 'noopener');
                break;
            }
            case AccountLinkStatus.LINK_ACCOUNT:
            case AccountLinkStatus.MERGED_ACCOUNT: {
                // switch to find account view
                clearMembership();
                updateMembership({
                    productAccountUUID: productAccountUUID,
                    status: PartnerMembershipStatus.UNLINKED,
                    onSuccess: onUpdateAccountSuccess(false),
                    onError: onError,
                });
                ANALYTICS.logEvent("".concat(EVENT.MERCHANT_NUMBER_DO_NOT_LINK_CLICKED), {
                    product_uuid: merchantData.productUUID,
                    consumer_uuid: consumerData.consumerUUID,
                    merchant_number: membershipNumber,
                    context: accountLinkInfo.status === AccountLinkStatus.LINK_ACCOUNT
                        ? MembershipContext.ACTIVE
                        : MembershipContext.MERGED,
                });
                break;
            }
            default: {
                break;
            }
        }
        return;
    };
    var handleContactSupport = function () {
        ANALYTICS.logEvent("".concat(EVENT.MERCHANT_CONTACT_SUPPORT_CLICKED), {
            product_uuid: merchantData.productUUID,
            consumer_uuid: consumerData.consumerUUID,
        });
        window.open(PartnerMembershipInfos[merchantData.productUUID].partnerContactSupportURL[languageCode], '_blank', 'noopener');
    };
    var renderBottomDock = function () {
        switch (accountLinkInfo.status) {
            case AccountLinkStatus.FIND_ACCOUNT:
            case AccountLinkStatus.LINK_ACCOUNT:
            case AccountLinkStatus.MERGED_ACCOUNT: {
                return (_jsxs(ImpContainer, __assign({ width: "100%", height: "136px", textAlign: "center" }, { children: [_jsx(ImpDivider, { color: colors.border.borderVariant }), _jsx(ImpSpacer, { height: "l" }), _jsx(ImpFlex, __assign({ direction: "row", justify: "center" }, { children: _jsx(ImpContainer, __assign({ width: "min(368px, 100% - 32px)", textAlign: "center" }, { children: _jsxs(ImpFlex, __assign({ direction: "column", fluid: true }, { children: [_jsx(ImpButton, __assign({ onClick: handleButton1Click, variant: 'primary', size: "fill", buttonStyle: "branded", state: button1State() }, { children: button1Copy() })), _jsx(ImpSpacer, { height: "s" }), _jsx(ImpPad, __assign({ fluid: true, padding: spacing.m }, { children: _jsx(ImpButton, __assign({ onClick: handleButton2Click, variant: 'text', size: "fill", buttonStyle: "branded", state: button2State() }, { children: button2Copy() })) }))] })) })) }))] })));
            }
            case AccountLinkStatus.CLOSED_ACCOUNT: {
                return (_jsx(ImpContainer, __assign({ width: "100%", height: "80px", textAlign: "center" }, { children: _jsx(ImpFlex, __assign({ direction: "row", justify: "center" }, { children: _jsx(ImpContainer, __assign({ width: "min(368px, 100% - 32px)", textAlign: "center" }, { children: _jsx(ImpButton, __assign({ onClick: handleContactSupport, variant: 'secondary', size: "fill", buttonStyle: "branded" }, { children: replaceMerchantContent(language.membershipSupportButton) })) })) })) })));
            }
            default: {
                return _jsx(_Fragment, {});
            }
        }
    };
    var backgroundStyle = {
        backgroundImage: "url(".concat(merchantData.accountLinkModalBackgroundImg, ")"),
        backgroundSize: '100%',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center 100px',
    };
    return (_jsx(ImpContainer, __assign({ width: "min(400px, 100vw - 32px)", height: "544px", maxHeight: "70vh", textAlign: "center" }, { children: _jsx(ImpFlex, __assign({ direction: "column", fluid: true, justify: "space-between" }, { children: isLoading ? (_jsx(ImpSpinner, {})) : (_jsxs(_Fragment, { children: [_jsx("div", __assign({ style: backgroundStyle }, { children: _jsxs(ImpContainer, __assign({ width: "min(400px, 100vw - 32px)", height: "100%", textAlign: "center" }, { children: [_jsx(ImpDivider, { color: colors.border.borderVariant }), _jsx(ImpSpacer, { height: "xl" }), renderModalHeader(), renderModalSubline(), renderModalBody(), _jsx(ImpSpacer, { height: "m" })] })) })), renderBottomDock()] })) })) })));
}
