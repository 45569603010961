var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styles from './Snackbar.module.css';
import { colors, Icons, ImpContainer, ImpFlex, ImpIcon, ImpPad, ImpText, } from '@imprint-payments/imprint-ui';
export default function Snackbar(_a) {
    var visible = _a.visible, text = _a.text, _b = _a.type, type = _b === void 0 ? 'none' : _b;
    var TextContent = function () { return (_jsx(ImpText, __assign({ typography: "body2", color: colors.content.onBackgroundInverse }, { children: text }))); };
    if (type === 'success' || type === 'error') {
        var icon = {
            success: Icons.circleWavyCheck,
            error: Icons.warningCircle,
        }[type];
        var iconColor = {
            success: colors.content.onBackgroundSuccess,
            error: colors.content.onBackgroundError,
        }[type];
        return (_jsx("div", __assign({ className: visible ? styles.snackbarOpen : styles.snackbar }, { children: _jsx(ImpPad, __assign({ padding: "16px" }, { children: _jsxs(ImpFlex, __assign({ direction: "row", align: "center", gap: "l" }, { children: [_jsx(ImpContainer, { children: _jsx(ImpIcon, { iconSrc: icon, stroke: iconColor }) }), _jsx(TextContent, {})] })) })) })));
    }
    return (_jsx("div", __assign({ className: visible ? styles.snackbarOpen : styles.snackbar }, { children: _jsx(ImpPad, __assign({ padding: "16px" }, { children: _jsx(TextContent, {}) })) })));
}
