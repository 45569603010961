var _a;
import { createDisplayAmount } from '@imprint-payments/imprint-lib';
import { createSlice } from '@reduxjs/toolkit';
var initialState = {
    lifetimeBalance: createDisplayAmount({ valueInMinUnit: 0 }),
    tierDetails: null,
    availableDate: null,
    availableBalance: createDisplayAmount({ valueInMinUnit: 0 }),
    pendingBalance: createDisplayAmount({ valueInMinUnit: 0 }),
    cardBenefitsImage: '',
};
var rewardsDetailsSlice = createSlice({
    name: 'rewardsDetails',
    initialState: initialState,
    reducers: {
        setRewardDetails: function (state, action) {
            var _a = action.payload, lifetimeBalance = _a.lifetimeBalance, tierDetails = _a.tierDetails, availableBalance = _a.availableBalance, pendingBalance = _a.pendingBalance, availableDate = _a.availableDate;
            state.lifetimeBalance = lifetimeBalance;
            state.tierDetails = tierDetails;
            state.availableBalance = availableBalance;
            state.availableDate = availableDate;
            state.pendingBalance = pendingBalance;
        },
        setCardBenefitsImage: function (state, action) {
            state.cardBenefitsImage = action.payload;
        },
    },
});
export var setRewardDetails = (_a = rewardsDetailsSlice.actions, _a.setRewardDetails), setCardBenefitsImage = _a.setCardBenefitsImage;
export default rewardsDetailsSlice.reducer;
