var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { getLogoTile } from '../../api/api';
import { selectBrooksBrothersLoginBannerFlag, selectFindAccountFlag, } from '../../app/featureFlagSelectors';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { SCREEN } from '../../app/screenStateSlice';
import LanguageDisclaimer from '../../components/LanguageDisclaimer/LanguageDisclaimer';
import { useScreenWidth } from '../../components/loginPage/loginPageUtils';
import { getLoginMerchant, PRODUCTUUID } from '../../utils/productUUIDConstants';
import styles from './Login.module.css';
import { LoginPageRender, NonLoginPage } from './loginUtils';
export default function Login() {
    var dispatch = useAppDispatch();
    var showingLanguageSelectDisclaimer = useAppSelector(function (state) { return state.languageSelector.showDisclaimer; });
    var screenState = useAppSelector(function (state) { return state.screenStateSelector; });
    var screenStatus = useScreenWidth();
    var isFindAccountEnabled = useAppSelector(selectFindAccountFlag);
    var isBrooksBrothersLoginBannerEnabled = useAppSelector(selectBrooksBrothersLoginBannerFlag);
    var showConversionBanner = (function () {
        var productUUID = getLoginMerchant(window.location.pathname);
        return (isFindAccountEnabled && isBrooksBrothersLoginBannerEnabled && productUUID === PRODUCTUUID.BB);
    })();
    useEffect(function () {
        if (showConversionBanner) {
            var productUUID = PRODUCTUUID.BB;
            dispatch(getLogoTile(productUUID));
        }
    }, [showConversionBanner, screenState]);
    return (_jsx("div", __assign({ className: styles.container }, { children: showingLanguageSelectDisclaimer ? (_jsx(LanguageDisclaimer, { page: 'phone_entry_page' })) : screenState.screen === SCREEN.LOGIN ? (_jsx(LoginPageRender, { screenStatus: screenStatus, showBanner: showConversionBanner })) : (_jsx(NonLoginPage, {})) })));
}
