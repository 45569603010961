var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useEffect, useState } from 'react';
import { setDeviceID } from '../../app/apiScreenSlice';
import { selectToken } from '../../app/authSelectors';
import { setAuthToken } from '../../app/authSlice';
import { selectHomeFlow, selectUserInformation } from '../../app/homeSelectors';
import { setHomeFlow, setUserInformation } from '../../app/homeSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { SCREEN, setScreen } from '../../app/screenStateSlice';
import { COOKIEKEY } from '../../utils/cookieConstants';
import { getCookieValueFor, removeCookieFor } from '../../utils/functionUtils';
import { setConsumerUUID } from '../../app/consumerInfoSlice';
import { setProductUUID } from '../../app/merchantDataSlice';
export var useRootRedirect = function () {
    var token = useAppSelector(selectToken);
    var homeFlow = useAppSelector(selectHomeFlow);
    var userInformation = useAppSelector(selectUserInformation);
    var firstTimeUser = userInformation.firstTimeUser;
    var dispatch = useAppDispatch();
    var _a = useState(false), cookiesHandled = _a[0], setCookiesHandled = _a[1];
    useEffect(function () {
        if (!token) {
            var _a = getCookies(), tokenCookie = _a.tokenCookie, deviceIDCookie = _a.deviceIDCookie, isMultiCard = _a.isMultiCard, productUUID = _a.productUUID, consumerUUID = _a.consumerUUID;
            if (tokenCookie && deviceIDCookie) {
                dispatch(setAuthToken(tokenCookie));
                dispatch(setDeviceID(deviceIDCookie));
                dispatch(setConsumerUUID(consumerUUID));
                dispatch(setProductUUID(productUUID));
                // this just determines whether the user needs to set up their pin - if it's multi-card, we assume they already have
                if (isMultiCard !== 'true') {
                    dispatch(setHomeFlow(__assign(__assign({}, homeFlow), { redirectedFirstTime: true })));
                    dispatch(setUserInformation(__assign(__assign({}, userInformation), { firstTimeUser: true })));
                }
                removeCookies();
                setCookiesHandled(true);
            }
        }
    }, [token, dispatch]);
    if (!token) {
        var _b = getCookies(), tokenCookie = _b.tokenCookie, deviceIDCookie = _b.deviceIDCookie;
        if (!tokenCookie || !deviceIDCookie) {
            return 'login';
        }
        if (!cookiesHandled) {
            return 'loading';
        }
        return 'account';
    }
    if (firstTimeUser) {
        dispatch(setScreen(SCREEN.PIN));
        return 'pin';
    }
    return 'account';
};
var getCookies = function () {
    return {
        tokenCookie: getCookieValueFor(COOKIEKEY.JWT),
        deviceIDCookie: getCookieValueFor(COOKIEKEY.DEVICEID),
        sessionIDCookie: getCookieValueFor(COOKIEKEY.SESSIONID),
        isMultiCard: getCookieValueFor(COOKIEKEY.ISMULTICARD),
        productUUID: getCookieValueFor(COOKIEKEY.PRODUCTUUID),
        consumerUUID: getCookieValueFor(COOKIEKEY.CONSUMERUUID),
    };
};
var removeCookies = function () {
    removeCookieFor(COOKIEKEY.JWT);
    removeCookieFor(COOKIEKEY.DEVICEID);
    removeCookieFor(COOKIEKEY.SESSIONID);
    removeCookieFor(COOKIEKEY.ISMULTICARD);
    removeCookieFor(COOKIEKEY.PRODUCTUUID);
    removeCookieFor(COOKIEKEY.CONSUMERUUID);
};
