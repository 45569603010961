var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Icons, ImpButton, ImpContainer, ImpFlex, ImpIcon, ImpInput, ImpSpacer, ImpText, colors, } from '@imprint-payments/imprint-ui';
import { useEffect, useState } from 'react';
import { EVENT } from '../../analytics/analyticsConsts';
import { submitAuthApplication } from '../../api/submitAuthApplication/submitAuthApplication';
import { setAuthUserFullSsn } from '../../app/authUsersSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { FORM_INPUT_WIDTH, TEXT_WIDTH } from '../../assets/consts/const';
import { ANALYTICS } from '../../firebase/firebase';
import { formatFullSSN } from '../../utils/formatUtils';
import { ValidationResponse, getShowError, getValidationString, handleValueChange, validateFullSSN, } from '../../utils/validateUtils';
export default function AdditionalDetailsPage() {
    var dispatch = useAppDispatch();
    var _a = useState(''), fullSsn = _a[0], setFullSsn = _a[1];
    var _b = useState(''), displayFullSsn = _b[0], setDisplayFullSsn = _b[1];
    var _c = useState(false), isSubmitting = _c[0], setIsSubmitting = _c[1];
    var _d = useState(ValidationResponse.empty), fullSsnError = _d[0], setFullSsnError = _d[1];
    var language = useAppSelector(function (state) { return state.languageSelector.languageLocalization; });
    var authUserState = useAppSelector(function (state) { return state.authUsersSelector; });
    var formatMaskedSsn = function (ssn) {
        var filtered = ssn.replace(/[^\d]/g, '');
        if (filtered.length <= 3) {
            return 'X'.repeat(ssn.length);
        }
        if (filtered.length === 4) {
            return 'XXX-X';
        }
        if (filtered.length === 5) {
            return 'XXX-XX';
        }
        return 'XXX-XX-' + filtered.substring(5);
    };
    var handleFullSsnChange = function (e) {
        if (e.nativeEvent.inputType === 'deleteContentBackward') {
            var filteredFullSSN = fullSsn.replace(/[^\d]/g, '');
            var filteredVal = e.target.value.replace(/[^X\d]/g, '');
            var diff = filteredFullSSN.length - filteredVal.length;
            var newSsn_1 = filteredFullSSN.substring(0, filteredFullSSN.length - diff);
            setFullSsn(formatFullSSN(newSsn_1));
            setDisplayFullSsn(formatMaskedSsn(newSsn_1));
            return;
        }
        var inputValue = e.target.value;
        var lastChar = inputValue[inputValue.length - 1];
        var newSsn = lastChar.match(/[0-9]/) ? fullSsn + lastChar : fullSsn;
        setFullSsn(formatFullSSN(newSsn));
        setDisplayFullSsn(formatMaskedSsn(newSsn));
    };
    useEffect(function () {
        handleValueChange(validateFullSSN(fullSsn, authUserState.consumer.ssn4), setFullSsnError);
    }, [fullSsn]);
    function getButtonState() {
        if (isSubmitting) {
            return 'submitting';
        }
        else if (fullSsnError === ValidationResponse.valid) {
            return 'enabled';
        }
        return 'disabled';
    }
    function handleContinueClick() {
        ANALYTICS.logEvent(EVENT.AUTH_USER_APPLICATION_SUBMITTED);
        var ssn = fullSsn;
        dispatch(setAuthUserFullSsn({ ssn: ssn }));
        setIsSubmitting(true);
        submitAuthApplication();
    }
    return (_jsxs(ImpContainer, __assign({ textAlign: "center", width: TEXT_WIDTH }, { children: [_jsxs(ImpFlex, __assign({ fluid: true, direction: "column", align: "center" }, { children: [_jsx(ImpText, __assign({ typography: "headline2", mobileTypography: "headline3" }, { children: language.authFullSsnEntryTitle })), _jsx(ImpSpacer, { height: 'm' }), _jsx(ImpText, __assign({ typography: "body2", color: colors.content.onBackgroundVariant }, { children: language.authFullSsnEntrySubtitle })), _jsx(ImpSpacer, { height: 'xl' }), _jsx(ImpFlex, __assign({ fluid: true, direction: "column", align: "center", gap: "l" }, { children: _jsx(ImpContainer, __assign({ width: FORM_INPUT_WIDTH }, { children: _jsx(ImpInput, { label: language.fullSsn, showError: getShowError(fullSsnError), errorMessage: getValidationString(fullSsnError, language), value: displayFullSsn, isDisabled: isSubmitting, onChange: handleFullSsnChange, onBlur: function () {
                                    setFullSsnError(validateFullSSN(fullSsn, authUserState.consumer.ssn4));
                                }, maxLength: 11, type: "text", trailingIcon: _jsx(ImpIcon, { iconSrc: Icons.lock, stroke: colors.content.onBackgroundVariant }) }) })) }))] })), _jsx(ImpSpacer, { height: 'xl' }), _jsx(ImpFlex, __assign({ fluid: true, direction: "row", justify: "center" }, { children: _jsx(ImpButton, __assign({ size: "m", variant: "primary", buttonStyle: "branded", state: getButtonState(), onClick: handleContinueClick }, { children: language.continueBtn })) }))] })));
}
