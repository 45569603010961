var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import BulletPoint from '../../components/BulletPoint/BulletPoint';
import { DownloadAppQRImage } from './DownloadAppQRImage';
import styles from './Dashboard.module.css';
import AppIcon from '../../assets/icons/AppIcon.svg';
import { useAppSelector } from '../../app/hooks';
import { ImprintConstants } from '../../api/apiConstants';
import DownloadAppAppStore from '../../assets/icons/DownloadAppAppStore.svg';
import DownloadAppGooglePlay from '../../assets/icons/DownloadAppGooglePlay.svg';
export function DownloadAppValueProps() {
    var language = useAppSelector(function (state) { return state.languageSelector.languageLocalization; });
    var merchantData = useAppSelector(function (state) { return state.merchantDataSelector; });
    return (_jsxs("div", __assign({ className: styles.splitMiddle }, { children: [_jsx(QRLogoComponent, {}), _jsxs("div", __assign({ className: styles.trailing }, { children: [_jsx("h4", __assign({ className: styles.qrTitle, tabIndex: 0 }, { children: language.downloadPrompt })), _jsxs("div", __assign({ className: styles.bullets }, { children: [_jsx(BulletPoint, { text: language.downloadValueProp3, stroke: merchantData.color.light }), _jsx(BulletPoint, { text: language.downloadValueProp4, stroke: merchantData.color.light })] }))] }))] })));
}
export function QRLogoComponent() {
    var language = useAppSelector(function (state) { return state.languageSelector.languageLocalization; });
    function handleDownloadAppClick() {
        window.open('http://' + ImprintConstants.DownloadLinkDisplay);
    }
    return (_jsxs("div", { children: [_jsxs("div", __assign({ className: styles.downloadAppMobileContainer }, { children: [_jsx("img", { src: AppIcon, className: styles.appIcon, tabIndex: 0, alt: "download app qr code clickable", onClick: handleDownloadAppClick }), _jsxs("p", __assign({ className: styles.downloadSublineMobile, tabIndex: 0 }, { children: [language.downloadSublineMobile, ' ', _jsx("span", __assign({ className: styles.downloadLink, onClick: handleDownloadAppClick, tabIndex: 0 }, { children: ImprintConstants.DownloadLinkDisplay })), ' ', language.downloadSubline2] })), _jsxs("div", __assign({ className: styles.downloadAppButtonsContainer }, { children: [_jsx("img", { src: DownloadAppAppStore, onClick: handleDownloadAppClick }), _jsx("img", { src: DownloadAppGooglePlay, onClick: handleDownloadAppClick })] }))] })), _jsx("div", __assign({ className: styles.downloadAppQRImageContainer }, { children: _jsx(DownloadAppQRImage, {}) }))] }));
}
