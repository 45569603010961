var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useNavigate } from 'react-router-dom';
import { SCREEN, setScreen } from '../../app/screenStateSlice';
import HeaderServicing from '../../components/HeaderServicing/HeaderServicing';
import { PAGE } from '../../utils/routeConstant';
import { colors, ImpBreadcrumbs, ImpButton, ImpContainer, ImpFlex, ImpSpacer, ImpText } from '@imprint-payments/imprint-ui';
import { EVENT } from '../../analytics/analyticsConsts';
import { submitAccountUpdate } from '../../api/api';
import { SUCCESS_TYPE, setSuccessInformation, setSuccessType } from '../../app/homeSlice';
import { STATEMENT_MEDIUM } from '../../app/statementsSlice';
import { LIST_STYLE, ListSection } from '../../components/list/List';
import { ANALYTICS } from '../../firebase/firebase';
import { navigateToAccountPage } from '../../utils/functionUtils';
export default function StatementSettings() {
    var dispatch = useAppDispatch();
    var navigate = useNavigate();
    var appScreenState = useAppSelector(function (state) { return state.applicationScreenSelector; });
    var language = useAppSelector(function (state) { return state.languageSelector.languageLocalization; });
    var consumerInfo = useAppSelector(function (state) { return state.consumerInfoSelector; });
    var homeSelector = useAppSelector(function (state) { return state.homeSelector; });
    var _a = useState('disabled'), primaryButtonState = _a[0], setPrimaryButtonState = _a[1];
    // Get the current statement status.
    var initialSetting = useAppSelector(function (state) { return state.statementsSelector.statementMedium; });
    var paperlessStatementDisabled = initialSetting === undefined
        || initialSetting === '';
    if (paperlessStatementDisabled) {
        navigate(PAGE.HOME);
    }
    var _b = useState(initialSetting !== null && initialSetting !== void 0 ? initialSetting : STATEMENT_MEDIUM.both), selectedSetting = _b[0], setSelectedSetting = _b[1];
    var defaultSelectionInSettingList = initialSetting === STATEMENT_MEDIUM.paperless ? 0 : 1;
    var getStatementSetting = function () {
        var paperlessSetting = [
            {
                title: language.goPaperless,
                handleClick: function () {
                    setSelectedSetting(STATEMENT_MEDIUM.paperless);
                    ANALYTICS.logEvent(EVENT.STATEMENT_SETTINGS_PAPAERLESS_CLICKED);
                },
            },
            {
                title: language.paperStatementsTitle,
                subtitle: language.paperStatementsSubtitle,
                handleClick: function () {
                    setSelectedSetting(STATEMENT_MEDIUM.both);
                    ANALYTICS.logEvent(EVENT.STATEMENT_SETTINGS_PAPER_CLICKED);
                },
            },
        ];
        return paperlessSetting;
    };
    useEffect(function () {
        if (appScreenState.loading) {
            setPrimaryButtonState('submitting');
        }
        else {
            // Does not allow user to submit the same setting request.
            setPrimaryButtonState(selectedSetting === initialSetting ? 'disabled' : 'enabled');
        }
    }, [appScreenState.loading, selectedSetting]);
    var saveBtnClick = function () {
        dispatch(submitAccountUpdate({
            consumerUUID: consumerInfo.consumerUUID,
            statementPreferences: {
                statementMedium: selectedSetting,
            },
        }, function () {
            dispatch(setSuccessType(SUCCESS_TYPE.statementSetting));
        }));
    };
    useEffect(function () {
        if (homeSelector.successType !== SUCCESS_TYPE.default) {
            var statementMediumType = selectedSetting === STATEMENT_MEDIUM.paperless
                ? STATEMENT_MEDIUM.paperless
                : STATEMENT_MEDIUM.both;
            dispatch(setSuccessInformation({
                amount: '',
                date: '',
                statementMedium: statementMediumType
            }));
            dispatch(setScreen(SCREEN.SUCCESS));
            navigate(PAGE.SUCCESS);
        }
    }, [homeSelector.successType]);
    return (_jsxs(ImpContainer, { children: [_jsx(HeaderServicing, {}), _jsx(ImpBreadcrumbs, { breadcrumbs: [language.documents], onBackClick: function () { return navigateToAccountPage(dispatch, navigate); }, onCancelClick: function () { return navigateToAccountPage(dispatch, navigate); } }), _jsx(ImpFlex, __assign({ fluid: true, justify: "center" }, { children: _jsx(ImpContainer, __assign({ width: "min(748px, 100vw - 32px)" }, { children: _jsxs(ImpFlex, __assign({ direction: "column", align: "center" }, { children: [_jsx(ImpSpacer, { height: "2xl" }), _jsx(ImpText, __assign({ typography: "headline2" }, { children: language.statements })), _jsx(ImpSpacer, { height: "xl" }), _jsx(ListSection, { style: LIST_STYLE.SELECT, items: getStatementSetting(), defaultSelection: defaultSelectionInSettingList }), _jsx(ImpSpacer, { height: "l" }), _jsx(ImpContainer, __assign({ textAlign: "center" }, { children: _jsx(ImpText, __assign({ color: colors.content.onSurfaceVariant }, { children: language.paperlessDisclaimer })) })), _jsx(ImpSpacer, { height: "xl" }), _jsx(ImpButton, __assign({ size: "m", variant: "primary", onClick: saveBtnClick, state: primaryButtonState }, { children: language.save }))] })) })) }))] }));
}
