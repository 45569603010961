var _a;
import { createSlice } from '@reduxjs/toolkit';
var initialState = {
    status: '',
    showResult: false,
    expiresAt: '',
    productAccountUUID: '',
};
var applicationStatusSlice = createSlice({
    name: 'applicationStatus',
    initialState: initialState,
    reducers: {
        setAppStatus: function (state, action) {
            state.status = action.payload;
        },
        setShowResult: function (state, action) {
            state.showResult = action.payload === null ? true : action.payload;
        },
        setExpiresAt: function (state, action) {
            state.expiresAt = action.payload;
        },
        setProductAccountUUID: function (state, action) {
            state.productAccountUUID = action.payload;
        },
    },
});
export var setAppStatus = (_a = applicationStatusSlice.actions, _a.setAppStatus), setShowResult = _a.setShowResult, setExpiresAt = _a.setExpiresAt, setProductAccountUUID = _a.setProductAccountUUID;
export default applicationStatusSlice.reducer;
