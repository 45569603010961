var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { SCREEN, setScreen } from '../../app/screenStateSlice';
import HeaderServicing from '../../components/HeaderServicing/HeaderServicing';
import { PAGE } from '../../utils/routeConstant';
import { ValidationResponse, validatePin } from '@imprint-payments/imprint-lib';
import { ImpBreadcrumbs, ImpContainer, ImpFlex, ImpOtpField, ImpSpacer, ImpSpinner, ImpText, colors, } from '@imprint-payments/imprint-ui';
import { EVENT } from '../../analytics/analyticsConsts';
import { resetPin } from '../../api/api';
import { loadHome } from '../../api/loadHome.api';
import { SUCCESS_TYPE, setSuccessType } from '../../app/homeSlice';
import { LIST_STYLE, ListSection } from '../../components/list/List';
import { ANALYTICS } from '../../firebase/firebase';
import { displayTemporarySnackbar, navigateToAccountPage } from '../../utils/functionUtils';
import VerifyPinPage from '../authorizedUsers/VerifyPinPage';
var SecurityPageStage;
(function (SecurityPageStage) {
    SecurityPageStage["Overview"] = "overview";
    SecurityPageStage["VerifyPin"] = "verifyPin";
    SecurityPageStage["CreatePin"] = "createPin";
    SecurityPageStage["ConfirmPin"] = "confirmPin";
})(SecurityPageStage || (SecurityPageStage = {}));
export default function Security() {
    var dispatch = useAppDispatch();
    var navigate = useNavigate();
    var language = useAppSelector(function (state) { return state.languageSelector.languageLocalization; });
    var homeSelector = useAppSelector(function (state) { return state.homeSelector; });
    var _a = useState(SecurityPageStage.Overview), securityPageStage = _a[0], setSecurityPageStage = _a[1];
    var _b = useState(false), createClearWithError = _b[0], setCreateClearWithError = _b[1];
    var _c = useState(''), createInput = _c[0], setCreateInput = _c[1];
    var _d = useState(''), oldPin = _d[0], setOldPin = _d[1];
    var _e = useState(false), notStrongPinError = _e[0], setNotStrongPinError = _e[1];
    var isLoadingConfirm = useState(false)[0];
    var _f = useState(false), incorrectDigitsErrorConfirm = _f[0], setIncorrectDigitsErrorConfirm = _f[1];
    var _g = useState(false), requestFailedConfirm = _g[0], setRequestFailedConfirm = _g[1];
    useEffect(function () {
        dispatch(loadHome());
        ANALYTICS.logEvent(EVENT.SECURITY_VIEWED);
        return function () {
            dispatch(setSuccessType(SUCCESS_TYPE.default));
        };
    }, []);
    useEffect(function () {
        setIncorrectDigitsErrorConfirm(false);
        setRequestFailedConfirm(false);
        if (securityPageStage !== SecurityPageStage.CreatePin) {
            setNotStrongPinError(false);
        }
    }, [securityPageStage]);
    useEffect(function () {
        if (homeSelector.successType !== SUCCESS_TYPE.default) {
            dispatch(setScreen(SCREEN.SUCCESS));
            navigate(PAGE.SUCCESS);
        }
    }, [homeSelector.successType]);
    var overviewPage = function () {
        var getSecurityItems = function () {
            return [
                {
                    title: language.resetPin,
                    handleClick: function () {
                        setSecurityPageStage(SecurityPageStage.VerifyPin);
                        ANALYTICS.logEvent(EVENT.SECURITY_RESET_PIN_CLICKED);
                    },
                },
            ];
        };
        return (_jsx(ImpContainer, __assign({ width: "min(748px, 100% - 32px)" }, { children: _jsx(ListSection, { style: LIST_STYLE.CHEVRON_RIGHT, items: getSecurityItems() }) })));
    };
    var createPinPage = function () {
        var handleChange = function (s) {
            if (s !== '') {
                setCreateClearWithError(false);
                setNotStrongPinError(false);
            }
        };
        var handleFull = function (s) {
            var validation = validatePin(s);
            if (validation === ValidationResponse.INVALID_PIN) {
                setNotStrongPinError(true);
                setCreateClearWithError(true);
            }
            else {
                ANALYTICS.logEvent(EVENT.SECURITY_PIN_NEW_ENTRY_CREATED);
                setCreateInput(s);
                setSecurityPageStage(SecurityPageStage.ConfirmPin);
            }
        };
        return (_jsx(ImpContainer, __assign({ width: "min(393px, 100% - 32px)" }, { children: _jsxs(ImpFlex, __assign({ align: "center", direction: "column", fluid: true }, { children: [_jsx(ImpText, __assign({ typography: "headline2" }, { children: language.createANewPIN })), _jsx(ImpSpacer, { height: "xl" }), _jsx(ImpContainer, __assign({ width: "min(393px, 100%)" }, { children: _jsx(ImpOtpField, { numInputs: 4, onChange: handleChange, onFull: handleFull, clearWithError: createClearWithError, mask: true }) })), _jsx(ImpSpacer, { height: "xl" }), _jsx(ImpContainer, __assign({ textAlign: "left" }, { children: _jsx(ImpText, __assign({ typography: "body3", color: notStrongPinError
                                ? colors.content.onBackgroundError
                                : colors.content.onBackgroundVariant }, { children: language.createANewPINMessage })) }))] })) })));
    };
    var confirmPinPage = function () {
        var handleChange = function (s) {
            if (s !== '') {
                setIncorrectDigitsErrorConfirm(false);
                setRequestFailedConfirm(false);
            }
        };
        var handleError = function () {
            setRequestFailedConfirm(true);
        };
        var handleSuccess = function () {
            displayTemporarySnackbar(language.infoUpdateHeader, dispatch);
            setSecurityPageStage(SecurityPageStage.Overview);
        };
        var handleFull = function (s) {
            if (s !== createInput) {
                setIncorrectDigitsErrorConfirm(true);
            }
            else {
                ANALYTICS.logEvent(EVENT.SECURITY_PIN_NEW_ENTRY_CONFIRMED);
                dispatch(resetPin(s, oldPin, handleError, handleSuccess));
            }
        };
        var errorText = function () {
            if (incorrectDigitsErrorConfirm)
                return language.makeSurePinsMatch;
            return language.somethingWentWrong;
        };
        return (_jsxs(ImpContainer, __assign({ width: "min(393px, 100% - 32px)", textAlign: "center" }, { children: [_jsx(ImpContainer, __assign({ textAlign: "center" }, { children: _jsxs(ImpFlex, __assign({ fluid: true, direction: "column", align: "center" }, { children: [_jsx(ImpText, __assign({ typography: "headline2" }, { children: language.confirmYourNewPin })), _jsx(ImpSpacer, { height: "xl" }), _jsx(ImpOtpField, { numInputs: 4, onFull: handleFull, onChange: handleChange, clearWithError: incorrectDigitsErrorConfirm || requestFailedConfirm, mask: true })] })) })), _jsx(ImpContainer, { children: _jsx(ImpFlex, __assign({ fluid: true, direction: "row", justify: "center" }, { children: requestFailedConfirm ||
                            (incorrectDigitsErrorConfirm && (_jsxs(ImpFlex, __assign({ direction: "column" }, { children: [_jsx(ImpSpacer, { height: "l" }), _jsx(ImpText, __assign({ typography: "body2", color: colors.content.onBackgroundError }, { children: errorText() }))] })))) })) }), isLoadingConfirm && _jsx(ImpSpinner, {})] })));
    };
    var getPageFromStage = function () {
        switch (securityPageStage) {
            case SecurityPageStage.VerifyPin:
                return (_jsx(VerifyPinPage, { handleSuccess: function () {
                        setSecurityPageStage(SecurityPageStage.CreatePin);
                    }, setPin: function (pin) { return setOldPin(pin); } }));
            case SecurityPageStage.CreatePin:
                return createPinPage();
            case SecurityPageStage.ConfirmPin:
                return confirmPinPage();
            case SecurityPageStage.Overview:
            default:
                return overviewPage();
        }
    };
    var getBreadcrumbsTitle = function () {
        switch (securityPageStage) {
            case SecurityPageStage.VerifyPin:
                return [language.verifyYourPin];
            case SecurityPageStage.CreatePin:
            case SecurityPageStage.ConfirmPin:
                return [language.resetPin];
            case SecurityPageStage.Overview:
            default:
                return [language.security];
        }
    };
    var handleBackClick = function () {
        switch (securityPageStage) {
            case SecurityPageStage.VerifyPin:
            case SecurityPageStage.CreatePin:
            case SecurityPageStage.ConfirmPin:
                setSecurityPageStage(SecurityPageStage.Overview);
                break;
            case SecurityPageStage.Overview:
            default:
                navigateToAccountPage(dispatch, navigate);
                break;
        }
    };
    return (_jsx(ImpContainer, { children: _jsxs(ImpFlex, __assign({ fluid: true, direction: "column", align: "center" }, { children: [_jsx(HeaderServicing, {}), _jsx(ImpBreadcrumbs, { breadcrumbs: getBreadcrumbsTitle(), onBackClick: handleBackClick, onCancelClick: function () { return navigateToAccountPage(dispatch, navigate); } }), _jsx(ImpSpacer, { height: "2xl" }), getPageFromStage()] })) }));
}
