var _a;
import { createSlice } from '@reduxjs/toolkit';
import { LanguageCode } from '../utils/languageConstants';
var initialState = {
    languageLocalization: null,
    languageCode: LanguageCode.English,
    showDisclaimer: false,
    languageChangedBeforeLogin: false,
};
export var languageSlice = createSlice({
    name: 'languageSelector',
    initialState: initialState,
    reducers: {
        setLanguage: function (state, action) {
            state.languageLocalization = action.payload.localization;
            state.languageCode = action.payload.code || LanguageCode.English;
        },
        setShowDisclaimer: function (state, action) {
            state.showDisclaimer = action.payload;
        },
        setLanguageChangedBeforeLogin: function (state, action) {
            state.languageChangedBeforeLogin = action.payload;
        },
    },
});
// Action creators are generated for each case reducer function
export var setLanguage = (_a = languageSlice.actions, _a.setLanguage), setShowDisclaimer = _a.setShowDisclaimer, setLanguageChangedBeforeLogin = _a.setLanguageChangedBeforeLogin;
export default languageSlice.reducer;
