export var getEmptyAddress = function () {
    return {
        streetLine1: '',
        streetLine2: '',
        city: '',
        state: '',
        country: '',
        zipCode: '',
    };
};
export var getAddressText = function (address) {
    if (!address) {
        return '';
    }
    return (address.streetLine1 +
        ', ' +
        (address.streetLine2 === '' ? '' : address.streetLine2 + ', ') +
        address.city +
        ', ' +
        address.state +
        ' ' +
        address.zipCode);
};
