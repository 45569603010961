export var getEmptyDate = function () {
    return {
        day: 0,
        month: 0,
        year: 0,
    };
};
// constructs a typescript date object from a Date object
export var constructDate = function (date) {
    return new Date(date.year, date.month - 1, date.day);
};
// short month and day (e.g. Jan 1)
export var formatDateMMMDD = function (date, languageCode) {
    var options = {
        month: 'short',
        day: 'numeric',
    };
    return constructDate(date).toLocaleDateString(languageCode, options);
};
// month and day (e.g. 01/01)
export var formatDateMMDD = function (date, languageCode) {
    var options = {
        month: '2-digit',
        day: '2-digit',
    };
    return constructDate(date).toLocaleDateString(languageCode, options);
};
// month and day with no leading zeros (e.g. 1/1)
export var formatDateMD = function (date, languageCode) {
    var options = {
        month: 'numeric',
        day: 'numeric',
    };
    return constructDate(date).toLocaleDateString(languageCode, options);
};
