var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { formatDisplayAmount } from '@imprint-payments/imprint-lib';
import { ImpBulletList, ImpInlineLink, ImpSpacer, ImpText, colors, } from '@imprint-payments/imprint-ui';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { EVENT } from '../../analytics/analyticsConsts';
import { confirm } from '../../api/api';
import { URL } from '../../api/apiConstants';
import { loadProductAccount } from '../../api/loadProductAccount/loadProductAccount';
import { selectAuth } from '../../app/authSelectors';
import { selectActiveCreditCards, selectExpiresAt } from '../../app/homeSelectors';
import { setLoadingNewCard, setSelectedProductAccount } from '../../app/homeSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectLanguage, selectLanguageCode } from '../../app/languageSelectors';
import { selectMerchantData } from '../../app/merchantDataSelectors';
import { SCREEN, setScreen } from '../../app/screenStateSlice';
import { ANALYTICS } from '../../firebase/firebase';
import { displayNumberNone } from '../../utils/formatUtils';
import { clearServiceLoginState } from '../../utils/functionUtils';
import { MerchantInfos, PRODUCTUUID_TO_MERCHANT } from '../../utils/productUUIDConstants';
import { PAGE } from '../../utils/routeConstant';
var getOfferUUIDs = function () {
    var _a;
    var OFFER_CONFIG_UUIDS = 'offerConfigUUIDs';
    if (sessionStorage && sessionStorage.getItem(OFFER_CONFIG_UUIDS) !== '') {
        return (_a = sessionStorage
            .getItem(OFFER_CONFIG_UUIDS)) === null || _a === void 0 ? void 0 : _a.split(',').map(function (item) { return item.trim(); });
    }
    return [];
};
var formatRewardsBonus = function (rewardsBonus) {
    var amount = rewardsBonus.amount, amountSubtext = rewardsBonus.amountSubtext;
    return "".concat(amount).concat(amountSubtext ? " ".concat(amountSubtext) : '');
};
var getRewardsTableData = function (merchantData) {
    var offerData = [];
    if (merchantData.rewardDetails.rewardsBonus) {
        var offerUUIDsSet = new Set(getOfferUUIDs());
        for (var i = 0; i < merchantData.rewardDetails.rewardsBonus.length; i++) {
            var rewardsBonus = merchantData.rewardDetails.rewardsBonus[i];
            if (rewardsBonus.offerConfigUUIDs) {
                for (var j = 0; j < rewardsBonus.offerConfigUUIDs.length; j++) {
                    var offerUUID = rewardsBonus.offerConfigUUIDs[j];
                    if ((offerUUIDsSet.size === 0 && offerUUID === 'default') ||
                        offerUUIDsSet.has(offerUUID)) {
                        offerData.push({
                            title: rewardsBonus.title,
                            subtitle: formatRewardsBonus(rewardsBonus),
                            content: (_jsx("div", { dangerouslySetInnerHTML: { __html: rewardsBonus.items.text }, style: {
                                    color: colors.content.onSurfaceVariant,
                                } })),
                        });
                        break;
                    }
                }
            }
            else {
                offerData.push({
                    title: rewardsBonus.title,
                    subtitle: formatRewardsBonus(rewardsBonus),
                    content: (_jsx("div", { dangerouslySetInnerHTML: { __html: rewardsBonus.items.text }, style: {
                            color: colors.content.onSurfaceVariant,
                        } })),
                });
            }
        }
    }
    return offerData;
};
var getFeeTableData = function (consumerInfo, language) {
    var _a, _b, _c, _d;
    return [
        {
            title: language.annualFee,
            trailingSubtitle: displayNumberNone(consumerInfo.cardOffer.fees.annualFee, language),
        },
        {
            title: language.overlimitFee,
            trailingSubtitle: displayNumberNone(consumerInfo.cardOffer.fees.overlimitFee, language),
        },
        {
            title: language.internationalFee,
            trailingSubtitle: displayNumberNone(consumerInfo.cardOffer.fees.internationalFee, language),
        },
        {
            title: language.lateFee,
            trailingSubtitle: "".concat(language.upto, " ").concat(displayNumberNone((_b = (_a = consumerInfo.cardOffer.fees) === null || _a === void 0 ? void 0 : _a.lateFee) === null || _b === void 0 ? void 0 : _b.additionalTime, language)),
        },
        {
            title: language.returnedFee,
            trailingSubtitle: "".concat(language.upto, " ").concat(displayNumberNone((_d = (_c = consumerInfo.cardOffer.fees) === null || _c === void 0 ? void 0 : _c.returnedPaymentFee) === null || _d === void 0 ? void 0 : _d.additionalTime, language)),
        },
    ];
};
var getDisclosureLinks = function (productUUID, languageCode) {
    var _a;
    return {
        rewardsTermsAndConditions: (_a = MerchantInfos[PRODUCTUUID_TO_MERCHANT[productUUID]]) === null || _a === void 0 ? void 0 : _a.rewardTerms[languageCode],
    };
};
export var useOfferPage = function () {
    var _a;
    var language = useAppSelector(selectLanguage);
    var merchantData = useAppSelector(selectMerchantData);
    var consumerInfo = useAppSelector(function (state) { return state.consumerInfoSelector; });
    var acceptScreenState = useAppSelector(function (state) { return state.acceptScreenSelector; });
    var activeCardList = useAppSelector(selectActiveCreditCards);
    var expiresAt = useAppSelector(selectExpiresAt);
    var languageCode = useAppSelector(selectLanguageCode);
    var apiScreenState = useAppSelector(function (state) { return state.apiScreenScreenSelector; });
    var dispatch = useAppDispatch();
    var auth = useAppSelector(selectAuth);
    var navigate = useNavigate();
    var _b = useState('enabled'), pageState = _b[0], setPageState = _b[1];
    var rewardsTermsAndConditions = getDisclosureLinks(merchantData.productUUID, languageCode).rewardsTermsAndConditions;
    var handleLeaveOfferClick = function () {
        // We do not clear the current state if the user is coming from the multicard selection.
        if (activeCardList.length >= 1) {
            // set the card to be the first valid card
            dispatch(setLoadingNewCard(true));
            dispatch(setSelectedProductAccount(activeCardList[0]));
            loadProductAccount({
                productAccountUUID: activeCardList[0].productAccountUUID,
            }).then(function () {
                dispatch(setLoadingNewCard(false));
            });
        }
        else {
            // if the user isn't multicard, leave offer should log them out
            clearServiceLoginState(dispatch);
        }
        navigate(PAGE.HOME);
    };
    var handlePrimaryAction = function () {
        ANALYTICS.logEvent(EVENT.OFFER_PAGE_CONTINUE_CLICKED);
        if (!acceptScreenState.cardDesignSelection) {
            // accept offer
            setPageState('submitting');
            ANALYTICS.logEvent(EVENT.OFFER_PAGE_ACCEPT_OFFER_CLICKED);
            dispatch(confirm({
                token: auth.token,
                applicationUUID: auth.applicationUUID,
                deviceID: apiScreenState.deviceID,
            })).then(function () {
                setPageState('enabled');
                dispatch(setScreen(SCREEN.ACCEPT));
                navigate(URL.ACCEPT);
            });
        }
        else {
            dispatch(setScreen(SCREEN.ACCEPT));
            navigate(URL.ACCEPT);
        }
    };
    var offerPageProps = {
        display: {
            title: "".concat(language.offerIntro, " ").concat(merchantData === null || merchantData === void 0 ? void 0 : merchantData.rewardDetails.cardName),
            displayImage: merchantData === null || merchantData === void 0 ? void 0 : merchantData.cardOfferImg,
            pageState: pageState,
        },
        info: {
            creditLimit: {
                title: formatDisplayAmount(consumerInfo.cardOffer.limit),
                subtitle: language.creditLimit,
            },
            secondHeaderItem: {
                title: formatDisplayAmount(consumerInfo.cardOffer.apr, true),
                subtitle: language.apr,
                showIcon: true,
            },
            rewardsList: {
                title: language.yourRewards,
                items: getRewardsTableData(merchantData),
            },
            feesList: {
                title: language.fees + '<sup>1</sup>',
                items: getFeeTableData(consumerInfo, language),
            },
            feesDisclosure: (_jsxs(_Fragment, { children: [_jsx(ImpSpacer, { height: "m" }), _jsx(ImpBulletList, { items: [
                            _jsxs(ImpText, __assign({ typography: "body3", color: colors.content.onSurfaceVariant }, { children: [language.offerDisclosure1, _jsx(ImpInlineLink, __assign({ url: rewardsTermsAndConditions, color: "var(--merchant-color)" }, { children: language.offerDisclosure2 })), language.offerDisclosure3, _jsx(ImpInlineLink, __assign({ url: consumerInfo.cardOffer.cardholderAgreementURL, color: "var(--merchant-color)" }, { children: language.offerDisclosure4 })), language.offerDisclosure5] })),
                        ], type: "numeric", bulletStyle: {
                            color: colors.content.onSurfaceVariant,
                            typography: 'body3',
                        } })] })),
            // deprecated
            rates: {
                text: '',
                onClick: function () { },
            },
            actionDisclaimer: language.affectCreditScoreStr,
        },
        modals: {
            edu: {
                creditLimitTitle: language.creditLimit,
                creditLimitSubtitle: language.creditLimitDefinition,
                aprTitle: language.apr,
                aprSubtitle: language.aprDefinition,
            },
            rejectionModal: {
                title: language.rejectOfferTitle,
                subtitle: (_a = language.rejectOfferSubtitle) === null || _a === void 0 ? void 0 : _a.replace('${1}', expiresAt),
                primaryButton: {
                    text: language.rejectOfferPrimaryButtonText,
                    // onclick is managed by the modal within imprint-ui
                    onClick: function () { },
                },
                secondaryButton: {
                    text: language.rejectOfferSecondaryButtonText,
                    onClick: handleLeaveOfferClick,
                },
            },
        },
        actions: {
            // if merchant has card design selection, wording and action change
            primaryButton: {
                text: (acceptScreenState === null || acceptScreenState === void 0 ? void 0 : acceptScreenState.cardDesignSelection)
                    ? language.acceptAndContinue
                    : language.acceptOfferBtn,
                onClick: handlePrimaryAction,
            },
            secondaryButton: {
                text: language.noThanksBtn,
                // secondary action is handled in modal
                onClick: function () { },
            },
        },
    };
    return offerPageProps;
};
