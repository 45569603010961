var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ImpBreadcrumbs, ImpButton, ImpContainer, ImpFlex, ImpFlexItem, ImpSpacer, ImpText, colors, } from '@imprint-payments/imprint-ui';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { EVENT } from '../../analytics/analyticsConsts';
import { submitCancelAutoPay, submitSetupAutoPay } from '../../api/api';
import { ImprintConstants } from '../../api/apiConstants';
import { loadHome } from '../../api/loadHome.api';
import { MODAL_ENUM, setShowModal } from '../../app/applicationScreenSlice';
import { SUCCESS_TYPE, setStartedBankLinking, setSuccessInformation } from '../../app/homeSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectSelectedProductUUID } from '../../app/productAccountSelectors';
import { SCREEN, setScreen } from '../../app/screenStateSlice';
import { autoPayMaxDatePerMonth, autoPayOnDueDateNumber, autoPayValidMaxDate, autoPayValidMinDate, paymentMaxInputLength, } from '../../assets/consts/const';
import CancelPage from '../../components/CancelPage/CancelPage';
import HeaderServicing from '../../components/HeaderServicing/HeaderServicing';
import { ITEM_STYLE, LIST_STYLE, ListSection } from '../../components/list/List';
import ModalDefinitionText from '../../components/ModalDefinitionText/ModalDefinitionText';
import PaymentDefinitionAutoPayModal from '../../components/popup/PaymentDefinitionAutoPayModal';
import Spinner from '../../components/spinner/Spinner';
import { ANALYTICS } from '../../firebase/firebase';
import { addSuffixToDate, convertAmountToString, currencyToNumber, formatDollarStringToString, months, } from '../../utils/formatUtils';
import { checkBankAccountBroken, getLanguageFromCookie, navigateToAccountPage, } from '../../utils/functionUtils';
import { LanguageCode } from '../../utils/languageConstants';
import { PAGE } from '../../utils/routeConstant';
import styles from './AutoPay.module.css';
// AutopayPageStage will keep track of the autopay UX logic flow.
var AutopayPageStage;
(function (AutopayPageStage) {
    AutopayPageStage["AutopayOverview"] = "overview";
    AutopayPageStage["AutopayAmount"] = "amount";
    AutopayPageStage["AutopayDate"] = "date";
    AutopayPageStage["AutopayConfirm"] = "confirm";
})(AutopayPageStage || (AutopayPageStage = {}));
var AutoPayAmountOption;
(function (AutoPayAmountOption) {
    AutoPayAmountOption["PayWhatYouOwe"] = "STATEMENT_BALANCE";
    AutoPayAmountOption["PayMinimum"] = "MINIMUM";
    AutoPayAmountOption["PayCustomAmount"] = "CUSTOM";
})(AutoPayAmountOption || (AutoPayAmountOption = {}));
var AutoPayDateOption;
(function (AutoPayDateOption) {
    AutoPayDateOption["OnDueDate"] = "On Due Date";
    AutoPayDateOption["CustomDate"] = "Custom Date";
})(AutoPayDateOption || (AutoPayDateOption = {}));
export default function AutoPay() {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    var language = useAppSelector(function (state) { return state.languageSelector.languageLocalization; });
    var languageCode = useAppSelector(function (state) { return state.languageSelector.languageCode; });
    var navigate = useNavigate();
    var dispatch = useAppDispatch();
    var appScreenState = useAppSelector(function (state) { return state.applicationScreenSelector; });
    var screenState = useAppSelector(function (state) { return state.screenStateSelector; });
    var productAccount = useAppSelector(function (state) { return state.productAccountSelector; });
    var productUUID = useAppSelector(selectSelectedProductUUID);
    var homeSelector = useAppSelector(function (state) { return state.homeSelector; });
    var selectedProductAccountCreditLineUUID = (_b = (_a = homeSelector.selectedProductAccount) === null || _a === void 0 ? void 0 : _a.creditLineUUID) !== null && _b !== void 0 ? _b : '';
    // Return to home if invalid data is passed in.
    if (selectedProductAccountCreditLineUUID === '') {
        navigate(PAGE.HOME);
    }
    var primaryPaymentAccount = useAppSelector(function (state) { return state.homeSelector.primaryPaymentAccount; });
    var consumerInfo = useAppSelector(function (state) { return state.consumerInfoSelector; });
    var _j = useState('$0.00'), selectedCustomValue = _j[0], setSelectedCustomValue = _j[1];
    var selectedCustomValue_num = currencyToNumber(selectedCustomValue);
    var MINIMUM_PAYMENT = 0.01;
    var _k = useState(AutoPayAmountOption.PayWhatYouOwe), amountOption = _k[0], setAmountOption = _k[1];
    var paymentOptionSelected = amountOption === AutoPayAmountOption.PayCustomAmount
        ? selectedCustomValue_num >= MINIMUM_PAYMENT
        : true;
    var _l = useState(AutoPayDateOption.OnDueDate), dateOption = _l[0], setDateOption = _l[1];
    var _m = useState(0), customDate = _m[0], setCustomDate = _m[1];
    var getAutoPayDueDateSubtitle = function () {
        var _a, _b, _c, _d;
        switch (dateOption) {
            case AutoPayDateOption.OnDueDate:
                if (productAccount === null || productAccount === void 0 ? void 0 : productAccount.paymentDetails)
                    return (language.autoPayDue +
                        months(languageCode)[((_b = (_a = productAccount === null || productAccount === void 0 ? void 0 : productAccount.paymentDetails) === null || _a === void 0 ? void 0 : _a.dueDay) === null || _b === void 0 ? void 0 : _b.month) - 1] +
                        ' ' +
                        ((_d = (_c = productAccount === null || productAccount === void 0 ? void 0 : productAccount.paymentDetails) === null || _c === void 0 ? void 0 : _c.dueDay) === null || _d === void 0 ? void 0 : _d.day));
                return '';
            case AutoPayDateOption.CustomDate:
                var proposedDate = new Date();
                if (proposedDate.getDate() >= customDate) {
                    // schedule for next month's date
                    proposedDate.setMonth(proposedDate.getMonth() + 1);
                }
                proposedDate.setDate(customDate);
                return (language.autoPayDue +
                    proposedDate.toLocaleDateString(getLanguageFromCookie(), {
                        month: 'short',
                        day: 'numeric',
                    }));
        }
    };
    // Fetch the current auto-pay plan, and skip overview stage if auto-pay is disabled.
    var currentAutoPayDetails = productAccount.autopay;
    var currentAutoPayEnabled = ((_d = (_c = productAccount.autopay) === null || _c === void 0 ? void 0 : _c.paymentPlan) === null || _d === void 0 ? void 0 : _d.paymentPlanUUID) !== undefined &&
        ((_f = (_e = productAccount.autopay) === null || _e === void 0 ? void 0 : _e.paymentPlan) === null || _f === void 0 ? void 0 : _f.paymentPlanUUID) !== null;
    // Set the UI stage, which include amount, date, and confirm.
    var _o = useState(currentAutoPayEnabled ? AutopayPageStage.AutopayOverview : AutopayPageStage.AutopayAmount), autopayPageStage = _o[0], setAutopayPageStage = _o[1];
    var _p = useState(true), isLoading = _p[0], setIsLoading = _p[1];
    var _q = useState(false), inCancelConfirmation = _q[0], setInCancelConfirmation = _q[1];
    useEffect(function () {
        if (checkBankAccountBroken(primaryPaymentAccount)) {
            dispatch(setStartedBankLinking(true));
            dispatch(setScreen(SCREEN.BANKING));
            navigate(PAGE.BANKING);
        }
    }, [primaryPaymentAccount.status]);
    var getContent = function () {
        var content = {
            headerValue: '',
            definition: '',
            appNotice1: '',
            appNotice2: '',
            btnValue: '',
        };
        switch (autopayPageStage) {
            case AutopayPageStage.AutopayOverview:
                content = {
                    headerValue: language.autoPayConfirmHeader,
                    definition: '',
                    appNotice1: '',
                    appNotice2: '',
                    btnValue: language.autoPayEdit,
                };
                break;
            case AutopayPageStage.AutopayAmount:
                content = {
                    headerValue: language.autoPayAmountHeader,
                    definition: '',
                    appNotice1: '',
                    appNotice2: '',
                    btnValue: language.continueBtn,
                };
                break;
            case AutopayPageStage.AutopayDate:
                content = {
                    headerValue: language.autoPayDateHeader,
                    definition: '',
                    appNotice1: '',
                    appNotice2: '',
                    btnValue: language.continueBtn,
                };
                break;
            case AutopayPageStage.AutopayConfirm:
                content = {
                    headerValue: language.autoPayConfirmHeader,
                    definition: '',
                    appNotice1: language.disclosureAgree1,
                    appNotice2: language.disclosureAgree2,
                    btnValue: language.submitPayment,
                };
                break;
            default:
        }
        return content;
    };
    var getBtnDisabled = function () {
        switch (autopayPageStage) {
            case AutopayPageStage.AutopayAmount:
                return !paymentOptionSelected;
            case AutopayPageStage.AutopayDate:
                return (dateOption === AutoPayDateOption.CustomDate &&
                    !(customDate >= autoPayValidMinDate && customDate <= autoPayValidMaxDate));
            case AutopayPageStage.AutopayOverview:
            case AutopayPageStage.AutopayConfirm:
            default:
                return false;
        }
    };
    // cancelConfirmBtnClick will handle the cancel confirmation button click
    var cancelConfirmBtnClick = function () {
        var _a, _b, _c;
        // "Cancel auto-pay" action triggered.
        ANALYTICS.logEvent(EVENT.AUTOPAY_CANCEL_AUTOPAY_CLICKED, {
            auto_pay_type: (_a = currentAutoPayDetails === null || currentAutoPayDetails === void 0 ? void 0 : currentAutoPayDetails.paymentPlan) === null || _a === void 0 ? void 0 : _a.amountType,
            auto_pay_date: (_b = currentAutoPayDetails === null || currentAutoPayDetails === void 0 ? void 0 : currentAutoPayDetails.paymentPlan) === null || _b === void 0 ? void 0 : _b.selectedDueDate,
        });
        dispatch(submitCancelAutoPay((_c = currentAutoPayDetails === null || currentAutoPayDetails === void 0 ? void 0 : currentAutoPayDetails.paymentPlan) === null || _c === void 0 ? void 0 : _c.paymentPlanUUID, {
            consumerUUID: consumerInfo.consumerUUID,
        }));
    };
    var btnClick = function () {
        var _a, _b;
        switch (autopayPageStage) {
            case AutopayPageStage.AutopayOverview:
                ANALYTICS.logEvent(EVENT.AUTOPAY_EDIT_CLICKED);
                setAutopayPageStage(AutopayPageStage.AutopayAmount);
                break;
            case AutopayPageStage.AutopayAmount:
                setAutopayPageStage(AutopayPageStage.AutopayDate);
                break;
            case AutopayPageStage.AutopayDate:
                setAutopayPageStage(AutopayPageStage.AutopayConfirm);
                break;
            case AutopayPageStage.AutopayConfirm:
                // Schedule the autopay
                var getAmount = function () {
                    switch (amountOption) {
                        case AutoPayAmountOption.PayWhatYouOwe:
                        case AutoPayAmountOption.PayMinimum:
                            return '';
                        case AutoPayAmountOption.PayCustomAmount:
                        default:
                            return formatDollarStringToString(selectedCustomValue);
                    }
                };
                var getSelectedDueDate = function () {
                    switch (dateOption) {
                        case AutoPayDateOption.OnDueDate:
                            return 100;
                        case AutoPayDateOption.CustomDate:
                        default:
                            return customDate;
                    }
                };
                dispatch(submitSetupAutoPay({
                    consumerUUID: consumerInfo.consumerUUID,
                    creditLineUUID: selectedProductAccountCreditLineUUID,
                    isCreate: !currentAutoPayEnabled,
                    paymentAccountUUID: primaryPaymentAccount.paymentAccountUUID,
                    // include paymentPlanUUID When we are updating an existing payment plan
                    paymentPlanUUID: (_b = (_a = currentAutoPayDetails === null || currentAutoPayDetails === void 0 ? void 0 : currentAutoPayDetails.paymentPlan) === null || _a === void 0 ? void 0 : _a.paymentPlanUUID) !== null && _b !== void 0 ? _b : undefined,
                    amountType: amountOption,
                    amount: parseFloat(getAmount()),
                    currency: 'USD',
                    selectedDueDate: getSelectedDueDate(),
                }));
                break;
            default:
        }
    };
    useEffect(function () {
        dispatch(loadHome());
    }, []);
    useEffect(function () {
        switch (autopayPageStage) {
            case AutopayPageStage.AutopayAmount:
                ANALYTICS.logEvent(EVENT.AUTOPAY_OVERVIEW_VIEWED);
                break;
            case AutopayPageStage.AutopayAmount:
                ANALYTICS.logEvent(EVENT.AUTOPAY_AMOUNT_VIEWED);
                break;
            case AutopayPageStage.AutopayDate:
                ANALYTICS.logEvent(EVENT.AUTOPAY_DATE_VIEWED);
                break;
            case AutopayPageStage.AutopayConfirm:
                ANALYTICS.logEvent(EVENT.AUTOPAY_CONFIRMATION_VIEWED);
                break;
            default:
        }
        // Redirect to download app page if user only has spend cards
        if (consumerInfo.hasSpendCardOnly || consumerInfo.notAcceptedOffer) {
            dispatch(setScreen(SCREEN.DOWNLOAD_APP));
            navigate(PAGE.DOWNLOAD_APP);
        }
    }, [autopayPageStage]);
    var getPaymentAmountInSuccess = function () {
        switch (amountOption) {
            case AutoPayAmountOption.PayWhatYouOwe:
                return language.autoPayPayWhatYouOweInSuccess;
            case AutoPayAmountOption.PayMinimum:
                return language.autoPayPayMinimumInSuccess;
            case AutoPayAmountOption.PayCustomAmount:
            default:
                return selectedCustomValue;
        }
    };
    var getPaymentDateInSuccess = function () {
        switch (dateOption) {
            case AutoPayDateOption.OnDueDate:
                return '';
            case AutoPayDateOption.CustomDate:
            default:
                return addSuffixToDate(customDate);
        }
    };
    useEffect(function () {
        if (homeSelector.successType !== SUCCESS_TYPE.default) {
            dispatch(setSuccessInformation({
                amount: getPaymentAmountInSuccess(),
                date: getPaymentDateInSuccess(),
            }));
            dispatch(setScreen(SCREEN.SUCCESS));
            navigate(PAGE.SUCCESS);
        }
    }, [homeSelector.successType]);
    useEffect(function () {
        if (!language) {
            setIsLoading(true);
        }
        else {
            setIsLoading(false);
        }
    }, [language]);
    if (isLoading) {
        return (_jsxs("div", { children: [_jsx(HeaderServicing, {}), _jsx(ImpBreadcrumbs, { breadcrumbs: [language.breadcrumbPayments, language.breadcrumbAutopay], onBackClick: function () { return handleBackClick(); }, onCancelClick: function () { return navigateToAccountPage(dispatch, navigate); } }), _jsx(ImpSpacer, { height: 'xl' }), _jsx(ImpContainer, { children: _jsx(Spinner, {}) })] }));
    }
    var amountOptions = [
        {
            title: language.autoPayPayWhatYouOwe,
            subtitle: language.autoPayPayWhatYouOweSubheader,
            handleClick: function () {
                setAmountOption(AutoPayAmountOption.PayWhatYouOwe);
            },
        },
        {
            title: language.autoPayPayMinimum,
            subtitle: language.autoPayPayMinimumSubheader,
            handleClick: function () {
                setAmountOption(AutoPayAmountOption.PayMinimum);
            },
        },
        {
            title: language.customAmount,
            subtitle: '$0.00',
            style: ITEM_STYLE.CUSTOM_AMOUNT,
            customMinPaymentNotice: language.minPaymentNoticeAutoPay,
            maxInputLength: paymentMaxInputLength,
            handleClick: function (value) {
                if (value) {
                    setSelectedCustomValue(value);
                }
                setAmountOption(AutoPayAmountOption.PayCustomAmount);
            },
        },
    ];
    var getCustomDateSubtitle = function () {
        if (dateOption === AutoPayDateOption.OnDueDate) {
            return language.autoPayPickADate;
        }
        else if (customDate >= autoPayMaxDatePerMonth) {
            return (language.autoPayCustomDateSubtitle2 +
                addSuffixToDate(customDate) +
                language.autoPayCustomDateSubtitle3);
        }
        else if (customDate >= 1) {
            return (language.autoPayCustomDateSubtitle2 +
                addSuffixToDate(customDate) +
                language.autoPayCustomDateSubtitle4);
        }
        else {
            return language.autoPayCustomDateSubtitle1;
        }
    };
    var paymentDateOptions = [
        {
            title: language.autoPayOnDueDate,
            subtitle: addSuffixToDate((_h = (_g = productAccount === null || productAccount === void 0 ? void 0 : productAccount.paymentDetails) === null || _g === void 0 ? void 0 : _g.dueDay) === null || _h === void 0 ? void 0 : _h.day) +
                language.autoPayOnDueDateSubtitle,
            handleClick: function () {
                setDateOption(AutoPayDateOption.OnDueDate);
            },
        },
        {
            title: language.autoPayCustomDate,
            subtitle: getCustomDateSubtitle(),
            style: ITEM_STYLE.CALENDAR,
            dueDate: currentAutoPayDetails === null || currentAutoPayDetails === void 0 ? void 0 : currentAutoPayDetails.statementDueDay,
            allowedDates: currentAutoPayDetails === null || currentAutoPayDetails === void 0 ? void 0 : currentAutoPayDetails.paymentDayOptions,
            handleClick: function () {
                setDateOption(AutoPayDateOption.CustomDate);
            },
        },
    ];
    var getPaymentAmountSubtitle = function () {
        var _a;
        if (autopayPageStage === AutopayPageStage.AutopayOverview) {
            // viewing an existing payment plan
            if (currentAutoPayDetails === null || currentAutoPayDetails === void 0 ? void 0 : currentAutoPayDetails.paymentPlan) {
                switch (currentAutoPayDetails.paymentPlan.amountType) {
                    case AutoPayAmountOption.PayWhatYouOwe:
                        return language.autoPayPayWhatYouOwe;
                    case AutoPayAmountOption.PayMinimum:
                        return language.autoPayPayMinimum;
                    case AutoPayAmountOption.PayCustomAmount:
                        return convertAmountToString((_a = currentAutoPayDetails.paymentPlan) === null || _a === void 0 ? void 0 : _a.amount);
                    default:
                }
            }
            return '';
        }
        switch (amountOption) {
            case AutoPayAmountOption.PayWhatYouOwe:
                return language.autoPayPayWhatYouOwe;
            case AutoPayAmountOption.PayMinimum:
                return language.autoPayPayMinimum;
            case AutoPayAmountOption.PayCustomAmount:
            default:
                return selectedCustomValue;
        }
    };
    var getPaymentDateSubtitle = function () {
        var _a, _b, _c, _d;
        // Show the current auto-pay's information
        if (autopayPageStage === AutopayPageStage.AutopayOverview) {
            // pay on due date
            if (((_a = currentAutoPayDetails === null || currentAutoPayDetails === void 0 ? void 0 : currentAutoPayDetails.paymentPlan) === null || _a === void 0 ? void 0 : _a.selectedDueDate) === autoPayOnDueDateNumber) {
                return (language.autoPayOnConfirmDateSubtitle +
                    addSuffixToDate(currentAutoPayDetails.statementDueDay) +
                    ', ' +
                    language.autoPayDueDate);
            }
            return (language.autoPayOnConfirmDateSubtitle +
                addSuffixToDate((_b = currentAutoPayDetails === null || currentAutoPayDetails === void 0 ? void 0 : currentAutoPayDetails.paymentPlan) === null || _b === void 0 ? void 0 : _b.selectedDueDate));
        }
        // Show the proposed auto-pay's information
        switch (dateOption) {
            case AutoPayDateOption.OnDueDate:
                return (language.autoPayOnConfirmDateSubtitle +
                    addSuffixToDate((_d = (_c = productAccount === null || productAccount === void 0 ? void 0 : productAccount.paymentDetails) === null || _c === void 0 ? void 0 : _c.dueDay) === null || _d === void 0 ? void 0 : _d.day) +
                    ', ' +
                    language.autoPayDueDate);
            case AutoPayDateOption.CustomDate:
            default:
                return language.autoPayOnConfirmDateSubtitle + addSuffixToDate(customDate);
        }
    };
    var paymentConfirmation = [
        {
            title: language.paymentAmount,
            subtitle: getPaymentAmountSubtitle(),
        },
        {
            title: language.paymentDate,
            subtitle: getPaymentDateSubtitle(),
        },
        {
            title: language.paymentMethod,
            subtitle: language.accountEnding + primaryPaymentAccount.accountNumberLast4,
        },
    ];
    function navigateToPrevScreen() {
        // navigate to payments if coming from payments page
        if (screenState.previousScreen === SCREEN.PAYMENTS) {
            dispatch(setScreen(SCREEN.PAYMENTS));
            navigate(PAGE.PAYMENTS);
        }
        else {
            navigateToAccountPage(dispatch, navigate);
        }
    }
    function handleBackClick() {
        if (inCancelConfirmation) {
            setInCancelConfirmation(false);
            return;
        }
        switch (autopayPageStage) {
            case AutopayPageStage.AutopayDate:
                setAutopayPageStage(AutopayPageStage.AutopayAmount);
                break;
            case AutopayPageStage.AutopayConfirm:
                setAutopayPageStage(AutopayPageStage.AutopayDate);
                break;
            case AutopayPageStage.AutopayAmount:
                // Go to home if no existing auto-pay plan.
                if (currentAutoPayEnabled) {
                    setAutopayPageStage(AutopayPageStage.AutopayOverview);
                }
                else {
                    navigateToPrevScreen();
                }
                break;
            case AutopayPageStage.AutopayOverview:
            default:
                navigateToPrevScreen();
                break;
        }
    }
    return (_jsxs(_Fragment, { children: [_jsx(HeaderServicing, {}), _jsx(ImpBreadcrumbs, { breadcrumbs: [language.breadcrumbPayments, language.breadcrumbAutopay], onBackClick: function () { return handleBackClick(); }, onCancelClick: function () { return navigateToAccountPage(dispatch, navigate); } }), _jsxs("div", __assign({ className: styles.container }, { children: [appScreenState.showModal === MODAL_ENUM.showPaymentDefinitionAutoPayModal && (_jsx(PaymentDefinitionAutoPayModal, {})), inCancelConfirmation ? (_jsx(CancelPage, { header: language.cancelConfirmationHeader, subheader: language.cancelConfirmationSubheader, goBackBtnValue: language.cancelGoBackBtn, confirmBtnValue: language.cancelConfirmCancelBtn, isLoading: appScreenState.loading, goBackClick: function () {
                            setInCancelConfirmation(false);
                        }, confirmClick: function () {
                            cancelConfirmBtnClick();
                        } })) : (_jsx(ImpContainer, __assign({ width: 'var(--max-width-desktop)', textAlign: "center" }, { children: _jsxs(ImpFlex, __assign({ direction: "column", align: "center" }, { children: [_jsx(ImpSpacer, { height: '2xl' }), _jsx(ImpText, __assign({ typography: "headline2" }, { children: getContent().headerValue })), autopayPageStage === AutopayPageStage.AutopayConfirm && (_jsxs(_Fragment, { children: [_jsx(ImpSpacer, { height: 'l' }), _jsx(ImpContainer, __assign({ textAlign: "center" }, { children: _jsx(ImpText, __assign({ typography: "body2", mobileTypography: "body3", color: colors.content.onBackgroundVariant }, { children: getAutoPayDueDateSubtitle() })) }))] })), _jsx(ImpSpacer, { height: 'xl' }), _jsx(ImpContainer, __assign({ width: 'var(--max-width-desktop)' }, { children: _jsxs(ImpFlex, __assign({ direction: "column", align: "center" }, { children: [_jsxs("div", __assign({ className: autopayPageStage === AutopayPageStage.AutopayAmount
                                                    ? styles.visible
                                                    : styles.hidden }, { children: [_jsx(ListSection, { style: LIST_STYLE.SELECT, items: amountOptions, defaultSelection: 0 }), _jsx(ModalDefinitionText, { text: language.paymentDefinition, onClick: function () {
                                                            dispatch(setShowModal(MODAL_ENUM.showPaymentDefinitionAutoPayModal));
                                                        } })] })), _jsx("div", __assign({ className: autopayPageStage === AutopayPageStage.AutopayDate
                                                    ? styles.visible
                                                    : styles.hidden }, { children: _jsx(ListSection, { style: LIST_STYLE.SELECT, items: paymentDateOptions, defaultSelection: 0, onItemSelect: function (value) {
                                                        dateOption === AutoPayDateOption.CustomDate && setCustomDate(Number(value));
                                                    } }) })), _jsx("div", __assign({ className: autopayPageStage === AutopayPageStage.AutopayConfirm ||
                                                    autopayPageStage === AutopayPageStage.AutopayOverview
                                                    ? styles.visible
                                                    : styles.hidden }, { children: _jsx(ListSection, { style: LIST_STYLE.DEFAULT, items: paymentConfirmation, noPointerCursor: true }) })), _jsxs("p", __assign({ className: styles.appNotice, tabIndex: 0 }, { children: [getContent().appNotice1, autopayPageStage === AutopayPageStage.AutopayConfirm && (_jsx("a", __assign({ className: styles.authLink, href: language.selected === LanguageCode.English
                                                            ? ImprintConstants.PaymentConsent
                                                            : ImprintConstants.PaymentConsentES, rel: "noopener", target: "_blank", onClick: function () {
                                                            ANALYTICS.logEvent(EVENT.ONE_TIME_PAYMENT_LINK_CLICKED);
                                                        } }, { children: language.consentAuthorization }))), getContent().appNotice2] })), _jsx(ImpContainer, __assign({ textAlign: "center" }, { children: _jsxs(ImpFlex, __assign({ direction: "row", mobileDirection: "column", gap: "xl", align: "center", mobileAlign: "stretch", fluid: true }, { children: [autopayPageStage === AutopayPageStage.AutopayOverview && (_jsx(ImpFlexItem, __assign({ flex: '1 1 0' }, { children: _jsx(ImpButton, __assign({ disabled: getBtnDisabled(), onClick: function () {
                                                                    setInCancelConfirmation(true);
                                                                }, state: appScreenState.loading ? 'submitting' : 'enabled', variant: 'secondary', size: "m" }, { children: language.cancelAutoPayBtn })) }))), _jsx(ImpFlexItem, __assign({ flex: '1 1 0' }, { children: _jsx(ImpButton, __assign({ disabled: getBtnDisabled(), onClick: btnClick, state: appScreenState.loading ? 'submitting' : 'enabled', variant: 'primary', size: "m" }, { children: getContent().btnValue })) }))] })) }))] })) }))] })) })))] }))] }));
}
