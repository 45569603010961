var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { formatDisplayAmount } from '@imprint-payments/imprint-lib';
import { ActivityIcons, borderRadius, colors, ImpContainer, ImpFlex, ImpModal, ImpPad, ImpSpacer, spacing, useModal, } from '@imprint-payments/imprint-ui';
import { useStatsigClient } from '@statsig/react-bindings';
import { useEffect, useMemo, useState } from 'react';
import { isAndroid, isIOS } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import { EVENT } from '../../analytics/analyticsConsts';
import { ACTION_TYPE } from '../../api/apiConstants';
import { loadHome } from '../../api/loadHome.api';
import { loadProductAccount } from '../../api/loadProductAccount/loadProductAccount';
import { selectAccountLink } from '../../app/accountLinkSelector';
import { setShowAccountLinkModal } from '../../app/accountLinkSlice';
import { selectEnableRewardsFlag } from '../../app/featureFlagSelectors';
import { ACCOUNT_STATUS, HomeScreen, ProductType, setDisplayFindMoreBanner, setHomeScreen, setRevealCardNumber, } from '../../app/homeSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectLanguage } from '../../app/languageSelectors';
import { resetMerchantData } from '../../app/merchantDataSlice';
import { selectAutopayWidget, selectConnectABankWidget, selectPhysicalCard, selectPhysicalCardWidget, selectVirtualCardIssuer, selectVirtualCardStatus, selectVirtualCardUUID, } from '../../app/productAccountSelectors';
import { SCREEN, setScreen } from '../../app/screenStateSlice';
import { PARAM_IN_STORE, PARAM_SHOW_WPP } from '../../assets/consts/const';
import AccountLinkModal from '../../components/AccountLinkModal/AccountLinkModal';
import { CLOModal } from '../../components/CLOModal/CLOModal';
import { CardDetailsPage } from '../../components/CardDetails/CardDetailsPage';
import CardLiftModal from '../../components/CardLiftModal/CardLiftModal';
import HeaderServicing from '../../components/HeaderServicing/HeaderServicing';
import ActivityFeed from '../../components/activityFeed/ActivityFeed';
import AddToWalletButton from '../../components/addToWalletButton/AddToWalletButton';
import { ANALYTICS } from '../../firebase/firebase';
import { Configs, Gates } from '../../statsig/gates';
import { CardStatus } from '../../types/CardStatus';
import { ShippingWidgetState } from '../../types/ShippingWidget';
import { CARD_ISSUER } from '../../utils/cardConsts';
import { EXTERNAL_URLS, PAGE } from '../../utils/routeConstant';
import { CardItem } from './CardItem';
import { ConnectBankButton } from './ConnectBankButton';
import styles from './Dashboard.module.css';
import { HeaderBanner } from './HeaderBanner';
import { HomeCardBalanceSection } from './HomeCardBalanceSection';
import { HomeLoader } from './HomeLoader';
import { HomeRewardsSection } from './HomeRewardsSection';
import { PhysicalCardWidget } from './PhysicalCardWidget';
import { SetUpPaymentsWidget } from './SetUpPaymentsWidget';
import UseCardToday from './UseCardToday';
import { isShippingWidgetVisible } from './physicalCardWidgetHelpers';
export var PaymentAccountStatus;
(function (PaymentAccountStatus) {
    PaymentAccountStatus["Approved"] = "approved";
    PaymentAccountStatus["Apply"] = "apply";
    PaymentAccountStatus["ReviewManual"] = "reviewing_manual";
    PaymentAccountStatus["ReviewAuto"] = "reviewing_auto";
    PaymentAccountStatus["Repair"] = "repair";
})(PaymentAccountStatus || (PaymentAccountStatus = {}));
export default function Dashboard() {
    var dispatch = useAppDispatch();
    var navigate = useNavigate();
    var canConnectABank = useAppSelector(selectConnectABankWidget);
    var canCreateAutopay = useAppSelector(selectAutopayWidget);
    var language = useAppSelector(selectLanguage);
    var homeSelector = useAppSelector(function (state) { return state.homeSelector; });
    var productAccounts = useAppSelector(function (state) { return state.homeSelector.productAccounts; });
    var selectedProductAccount = useAppSelector(function (state) { return state.homeSelector.selectedProductAccount; });
    var merchantData = useAppSelector(function (state) { return state.merchantDataSelector; });
    var consumerData = useAppSelector(function (state) { return state.consumerInfoSelector; });
    var physicalCardWidget = useAppSelector(selectPhysicalCardWidget);
    var physicalCard = useAppSelector(selectPhysicalCard);
    var virtualCardUUID = useAppSelector(selectVirtualCardUUID);
    var virtualCardIssuer = useAppSelector(selectVirtualCardIssuer);
    var virtualCardStatus = useAppSelector(selectVirtualCardStatus);
    var productAccount = useAppSelector(function (state) { return state.productAccountSelector; });
    var featureFlags = useAppSelector(function (state) { return state.featureFlagSelector.featureFlags; });
    var accountLinkEnabled = featureFlags.accountLinkEnabled === 'true';
    var _a = useStatsigClient(), checkGate = _a.checkGate, client = _a.client;
    var appScreenState = useAppSelector(function (state) { return state.applicationScreenSelector; });
    var screenState = useAppSelector(function (state) { return state.screenStateSelector; });
    var cardImg = useAppSelector(function (state) { var _a; return ((_a = state.homeSelector.selectedProductAccount) === null || _a === void 0 ? void 0 : _a.cardImg) || state.merchantDataSelector.cardImg; });
    var consumerInfo = useAppSelector(function (state) { return state.consumerInfoSelector; });
    var accountLinkInfo = useAppSelector(selectAccountLink);
    var _b = useState(true), isLoading = _b[0], setIsLoading = _b[1];
    var _c = useState(false), isRefreshing = _c[0], setIsRefreshing = _c[1]; // refreshing data via load product
    var _d = useState(false), showHeadBanner = _d[0], setShowHeadBanner = _d[1];
    var _e = useState(false), isScrollDisabled = _e[0], setIsScrollDisabled = _e[1];
    // const { isOpen } = useModal(true);
    var toggleScroll = function () {
        setIsScrollDisabled(!isScrollDisabled);
    };
    var cloModalDismissedKey = "CLO_MODAL_DISMISSED_".concat(consumerData.consumerUUID);
    var dynamicConfig = client.getDynamicConfig(Configs.cloOfferConfigs);
    var maxOffersActivatable = dynamicConfig.get('maxOffersActivatable', -1);
    var hasDismissedCLO = localStorage.getItem(cloModalDismissedKey) === 'true';
    var cloModalOpen = showCLOModal(maxOffersActivatable, hasDismissedCLO);
    var cardLiftModalDismissedKey = "CARDLIFT_MODAL_DISMISSED_".concat(consumerData.consumerUUID);
    var cardLiftModalOpen = checkGate(Gates.displayCardLift) &&
        virtualCardStatus === CardStatus.ACTIVE &&
        !localStorage.getItem(cardLiftModalDismissedKey) &&
        !showCLOModal(maxOffersActivatable, hasDismissedCLO);
    // remove card balance component if a product account is canceled, pending
    // or has undefined balance
    var showCardBalanceSection = (productAccount === null || productAccount === void 0 ? void 0 : productAccount.account.status) !== 'canceled' &&
        (productAccount === null || productAccount === void 0 ? void 0 : productAccount.account.status) !== 'pending' &&
        formatDisplayAmount(productAccount.cardBalanceV2.totalSpend).replace(' ', '') !== '';
    var isEnableRewardsFlagOn = useAppSelector(selectEnableRewardsFlag);
    var showRewards = featureFlags.rewardRedemptionEnabled === 'true';
    // Use to determine if we need to directly navigate to offer page
    var activeCardList = productAccounts.filter(function (productAccount) {
        return productAccount.productType !== ProductType.SpendCard &&
            productAccount.productAccountStatus === ACCOUNT_STATUS.ACTIVE;
    });
    var _f = useModal(false), isAccountLinkModalOpen = _f[0], isAccountLinkModalClosing = _f[1], openAccountLinkModal = _f[2], closeAccountLinkModal = _f[3];
    var _g = useState(sessionStorage.getItem(PARAM_SHOW_WPP) === 'true'), showUseCardToday = _g[0], setShowUseCardToday = _g[1];
    useEffect(function () {
        if (accountLinkInfo.showAccountLinkModal) {
            ANALYTICS.logEvent("".concat(EVENT.MERCHANT_NUMBER_LINK_ACCOUNT_VIEWED), {
                product_uuid: merchantData.productUUID,
                consumer_uuid: consumerData.consumerUUID,
            });
            openAccountLinkModal();
        }
        else if (isAccountLinkModalOpen) {
            closeAccountLinkModal();
        }
    }, [accountLinkInfo.showAccountLinkModal]);
    useEffect(function () {
        if (sessionStorage.getItem(PARAM_IN_STORE) === 'true') {
            sessionStorage.setItem(PARAM_IN_STORE, '');
            dispatch(setRevealCardNumber(true));
            dispatch(setHomeScreen(HomeScreen.CardDetails));
        }
    }, []);
    useEffect(function () {
        if (screenState.screen === SCREEN.CONFIRM_EMAIL) {
            navigate(PAGE.ACCOUNT_SETUP);
        }
    }, [screenState.screen]);
    useEffect(function () {
        setIsLoading(true);
        dispatch(loadHome()).then(function () {
            // Redirect user to accept offer page if there is only 1 active offer on account
            if (consumerInfo.actions &&
                consumerInfo.actions[0].type === ACTION_TYPE.SHOW_OFFER_SCREEN &&
                activeCardList.length === 0) {
                navigate(PAGE.OFFER);
            }
            else {
                ANALYTICS.logEvent(EVENT.HOME_VIEWED);
                if (selectedProductAccount) {
                    loadProductAccount({
                        productAccountUUID: selectedProductAccount.productAccountUUID,
                    });
                }
            }
        });
    }, [homeSelector.selectedProductAccount, consumerInfo.actions]);
    useEffect(function () {
        history.pushState(null, '', window.location.href);
        window.onpopstate = function (event) {
            history.go(1);
        };
        dispatch(resetMerchantData());
    }, []);
    useEffect(function () {
        if (isRefreshing)
            return;
        if (!merchantData.cardImg || !consumerInfo || !language) {
            setIsLoading(true);
        }
        else {
            setIsLoading(false);
        }
    }, [language, merchantData, consumerInfo, isRefreshing]);
    useEffect(function () {
        if (homeSelector.displayFindMoreBanner) {
            setShowHeadBanner(true);
        }
    }, [homeSelector.displayFindMoreBanner]);
    function showCLOModal(maxOffersAvailable, hasDismissed) {
        return maxOffersAvailable > 0 && !hasDismissed;
    }
    var reloadProduct = function () {
        setIsLoading(true);
        setIsRefreshing(true);
        loadProductAccount({
            productAccountUUID: selectedProductAccount.productAccountUUID,
        }).finally(function () { return setIsRefreshing(false); });
    };
    var headBannerOff = function () {
        ANALYTICS.logEvent(EVENT.FIND_OUT_MORE_BANNER_DISMISSED);
        dispatch(setDisplayFindMoreBanner(false));
        setShowHeadBanner(false);
        return;
    };
    var handleFindMore = function () {
        ANALYTICS.logEvent(EVENT.FIND_OUT_MORE_BANNER_CLICKED);
        dispatch(setScreen(SCREEN.ACCESS_MORE));
        navigate(PAGE.ACCESS_MORE);
        return;
    };
    var closeAccountLinkModalHandler = function () {
        closeAccountLinkModal();
        dispatch(setShowAccountLinkModal(false));
    };
    var showPhysicalCardWidget = useMemo(function () {
        var state = physicalCardWidget === null || physicalCardWidget === void 0 ? void 0 : physicalCardWidget.state;
        var flagEnabled = (featureFlags === null || featureFlags === void 0 ? void 0 : featureFlags.physical_card_tracking_widget_enabled) === 'true';
        var enumValues = Object.values(ShippingWidgetState);
        if (!flagEnabled || !state || !enumValues.includes(state)) {
            return false;
        }
        if (!physicalCard) {
            return state === ShippingWidgetState.Init;
        }
        var status = physicalCard.status, last4 = physicalCard.last4;
        return isShippingWidgetVisible(state, status, last4);
    }, [physicalCard, physicalCardWidget]);
    var headerBannerContent = function () {
        var props = {
            primaryAction: handleFindMore,
            primaryActionText: language.downloadAppBanner,
            closeHeaderBanner: headBannerOff,
            headerText: language.cantFindWhatLookingFor,
        };
        if (checkGate(Gates.displayCardLift) && virtualCardStatus === CardStatus.ACTIVE) {
            props.primaryAction = function () {
                ANALYTICS.logEvent(EVENT.CARDLIFT_DOWNLOAD_BANNER_CLICKED);
                window.open(EXTERNAL_URLS.cardLiftDownload);
            };
            props.primaryActionText = language.addCardToChrome;
            props.headerText = language.headerBannerCashback;
            props.primaryActionIcon = ActivityIcons.arrow;
        }
        return props;
    };
    var headerBanner = showHeadBanner && _jsx(HeaderBanner, __assign({}, headerBannerContent()));
    var cardDetails = homeSelector.homeScreen === HomeScreen.CardDetails && (_jsx(CardDetailsPage, { onGoBackClick: function () {
            ANALYTICS.logEvent(EVENT.CARD_MENU_GO_BACK_CLICKED);
            dispatch(setHomeScreen(HomeScreen.Home));
        } }));
    var HomeWidget = function () {
        var canConnectBank = useAppSelector(selectConnectABankWidget);
        var canCreateAutopay = useAppSelector(selectAutopayWidget);
        if (canConnectBank) {
            return _jsx(ConnectBankButton, { displayToolTip: !cardLiftModalOpen && !cloModalOpen });
        }
        if (canCreateAutopay) {
            return _jsx(SetUpPaymentsWidget, {});
        }
        return null;
    };
    if (showUseCardToday && !isLoading) {
        return _jsx(UseCardToday, { closeUseCardToday: function () { return setShowUseCardToday(false); } });
    }
    return isLoading ? (_jsxs(ImpContainer, __assign({ width: "100vw" }, { children: [_jsx(HeaderServicing, {}), _jsx(ImpFlex, __assign({ direction: "row", justify: "center" }, { children: _jsx(HomeLoader, {}) }))] }))) : (_jsxs(ImpContainer, __assign({ width: "100%" }, { children: [_jsx(HeaderServicing, { toggleScroll: toggleScroll }), headerBanner, cardDetails, homeSelector.homeScreen === HomeScreen.Home && (_jsxs(_Fragment, { children: [_jsx(ImpSpacer, { height: "xl", mobileHeight: "xl" }), _jsx(CLOModal, { open: cloModalOpen, dismissedKey: cloModalDismissedKey }), _jsx(CardLiftModal, { cardImg: cardImg, open: cardLiftModalOpen, dismissedKey: cardLiftModalDismissedKey }), accountLinkEnabled && (_jsx(ImpModal, __assign({ isOpen: isAccountLinkModalOpen, isClosing: isAccountLinkModalClosing, closeModal: closeAccountLinkModalHandler, respectContentWidth: true, respectContentHeight: true, headerImg: merchantData.accountLinkModalLogo, preventClose: appScreenState.loading }, { children: _jsx(AccountLinkModal, { reloadDashboard: reloadProduct, closeModal: closeAccountLinkModalHandler }) }))), _jsx(ImpFlex, __assign({ direction: "row", justify: "center", fluid: true }, { children: _jsx(ImpContainer, __assign({ width: "min(100% - 32px, 1140px)" }, { children: _jsxs(ImpFlex, __assign({ direction: "column", fluid: true, gap: spacing.xl, mobileGap: 's' }, { children: [_jsxs("div", __assign({ className: styles.homeTopRowContainer }, { children: [_jsx(ImpContainer, __assign({ dataTestId: "card-container", position: "relative", width: "100%" }, { children: _jsx(ImpFlex, __assign({ fluid: true, direction: "column", align: "left", mobileAlign: "center" }, { children: _jsx(CardItem, { image: cardImg, subtitle: language.showCardDetails, handleClick: function () {
                                                            ANALYTICS.logEvent(EVENT.CARD_MENU_CLICKED);
                                                            dispatch(setHomeScreen(HomeScreen.CardDetails));
                                                        } }) })) })), (isIOS || isAndroid) &&
                                                checkGate(Gates.addToWallet) &&
                                                virtualCardIssuer === CARD_ISSUER.MARQETA && (_jsx(ImpContainer, __assign({ width: "100%", borderRadius: borderRadius.m, backgroundColor: colors.surface.surfaceHigh }, { children: _jsx(ImpPad, __assign({ paddingTop: "s", paddingBottom: "s", fluid: true }, { children: _jsx(AddToWalletButton, { cardUUID: virtualCardUUID, onClick: function () {
                                                            //TODO: [WS] Analytics
                                                            console.log('Add to wallet clicked');
                                                        }, onSuccess: function () {
                                                            //TODO: [WS] Analytics if needed
                                                            console.log('Your card is added to wallet!');
                                                        } }) })) }))), _jsxs(ImpFlex, __assign({ direction: "column", fluid: true, gap: "8px" }, { children: [showPhysicalCardWidget && _jsx(PhysicalCardWidget, {}), (canConnectABank || canCreateAutopay) && _jsx(HomeWidget, {})] }))] })), _jsxs("div", __assign({ className: styles.splitRow }, { children: [showCardBalanceSection && _jsx(HomeCardBalanceSection, {}), showRewards && _jsx(HomeRewardsSection, {})] })), _jsx(ActivityFeed, {})] })) })) })), _jsx(ImpSpacer, { height: "3xl", mobileHeight: "xl" })] }))] })));
}
