// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FrYOEWyJ7NgyueQxNpC_ {\n  width: 24px;\n  height: 24px;\n}\n\n.lOqmc_V8mKaIxfxKmtfx {\n  display: flex;\n  flex-direction: row;\n  align-items: top;\n}", "",{"version":3,"sources":["webpack://./src/components/BulletPoint/BulletPoint.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,gBAAgB;AAClB","sourcesContent":[".bulletPoint {\n  width: 24px;\n  height: 24px;\n}\n\n.divPoint {\n  display: flex;\n  flex-direction: row;\n  align-items: top;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bulletPoint": "FrYOEWyJ7NgyueQxNpC_",
	"divPoint": "lOqmc_V8mKaIxfxKmtfx"
};
module.exports = ___CSS_LOADER_EXPORT___;
