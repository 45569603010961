// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OGWg4wli2v3TAOs29Z75 {\n  height: 100dvh;\n  display: flex;\n  flex-direction: column;\n}\n\n.VoqpgBoF5azd9ClVA0FA {\n  max-width: min(454px, 100% - 32px);\n  margin-left: auto;\n  margin-right: auto;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/login/Login.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,kCAAkC;EAClC,iBAAiB;EACjB,kBAAkB;AACpB","sourcesContent":[".container {\n  height: 100dvh;\n  display: flex;\n  flex-direction: column;\n}\n\n.body {\n  max-width: min(454px, 100% - 32px);\n  margin-left: auto;\n  margin-right: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "OGWg4wli2v3TAOs29Z75",
	"body": "VoqpgBoF5azd9ClVA0FA"
};
module.exports = ___CSS_LOADER_EXPORT___;
