var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { ImpButton, ImpContainer, ImpFlex, ImpSpacer, ImpText, } from '@imprint-payments/imprint-ui';
import { navigateToLogin } from '../../utils/functionUtils';
import { useNavigate } from 'react-router-dom';
import { FORM_BUTTON_WIDTH } from '../../assets/consts/const';
import { useEffect } from 'react';
import { ANALYTICS } from '../../firebase/firebase';
import { EVENT } from '../../analytics/analyticsConsts';
export default function SuccessPage() {
    var dispatch = useAppDispatch();
    var navigate = useNavigate();
    var language = useAppSelector(function (state) { return state.languageSelector.languageLocalization; });
    function handlePrimaryButtonClick() {
        ANALYTICS.logEvent(EVENT.RESET_PIN_CONFIRMED_CLICKED, {
            context: 'forgot_pin',
        });
        navigateToLogin(dispatch, navigate);
    }
    useEffect(function () {
        ANALYTICS.logEvent(EVENT.RESET_PIN_CONFIRMED_VIEWED, {
            context: 'forgot_pin',
        });
    }, []);
    return (_jsx(ImpContainer, __assign({ textAlign: 'center' }, { children: _jsxs(ImpFlex, __assign({ direction: "column", align: "center", fluid: true }, { children: [_jsx(ImpText, __assign({ typography: "headline2", mobileTypography: "headline3" }, { children: language.forgotPinSuccessTitle })), _jsx(ImpSpacer, { height: 'm' }), _jsx(ImpText, __assign({ typography: "body1", mobileTypography: "body2" }, { children: language.forgotPinSuccessSubtitle })), _jsx(ImpSpacer, { height: 'xl' }), _jsx(ImpContainer, __assign({ width: FORM_BUTTON_WIDTH }, { children: _jsx(ImpButton, __assign({ onClick: handlePrimaryButtonClick, state: 'enabled', size: "fill" }, { children: language.LogIn })) }))] })) })));
}
