var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ImpButton, ImpContainer, ImpFlex, ImpImage, ImpPad, ImpSpacer, ImpText, } from '@imprint-payments/imprint-ui';
import { useEffect } from 'react';
import { useAppSelector } from '../../app/hooks';
import { selectLanguage } from '../../app/languageSelectors';
import imprintLogo from '../../assets/icons/logo-imprint.svg';
import { ANALYTICS } from '../../firebase/firebase';
export function PageNotFound() {
    var language = useAppSelector(selectLanguage);
    useEffect(function () {
        ANALYTICS.logEvent('page_not_found_viewed', {
            url: window.location.href,
        });
    }, []);
    return (_jsx(ImpFlex, __assign({ fluid: true, justify: "center" }, { children: _jsx(ImpContainer, __assign({ width: "min(1200px, 100%)" }, { children: _jsx(ImpPad, __assign({ fluid: true, padding: "56px", mobilePadding: "24px" }, { children: _jsxs(ImpFlex, __assign({ direction: "column" }, { children: [_jsx(ImpImage, { src: imprintLogo, alt: "logo", width: "120px" }), _jsx(ImpSpacer, { height: "60px" }), _jsx(ImpText, __assign({ typography: "display3" }, { children: language.pageNotFoundTitle })), _jsx(ImpSpacer, { height: "12px" }), _jsx(ImpText, __assign({ typography: "body1" }, { children: language.pageNotFoundSubtitle })), _jsx(ImpSpacer, { height: "24px" }), _jsx(ImpButton, __assign({ buttonStyle: "imprint", onClick: function () {
                                ANALYTICS.logEvent('page_not_found_cta_clicked', {
                                    url: window.location.href,
                                });
                                window.location.href = '/';
                            }, size: "hug" }, { children: language.pageNotFoundCTA }))] })) })) })) })));
}
