export function selectPaymentsEnhancementsFlag(state) {
    return state.featureFlagSelector.featureFlags.payments_enhancements_enabled === 'true';
}
export function selectSpendReviewFlag(state) {
    return state.featureFlagSelector.featureFlags.spend_review_enabled === 'true';
}
export function selectRewardsDetailsFlag(state) {
    return state.featureFlagSelector.featureFlags.reward_details_update_enabled === 'true';
}
export function selectBrooksBrothersLoginBannerFlag(state) {
    return state.featureFlagSelector.featureFlags.showBrooksBrothersLoginBanner === 'true';
}
export function selectFindAccountFlag(state) {
    return state.featureFlagSelector.featureFlags.findAccountEnabled === 'true';
}
export function selectPaymentsEnabled(state) {
    return state.featureFlagSelector.featureFlags.paymentsEnabled === 'true';
}
export function selectVoiceOTPEnabledEnabledFlag(state) {
    return state.featureFlagSelector.featureFlags.voiceOTPEnabled === 'true';
}
export function selectDownloadCsvActivityFeedFlag(state) {
    return state.featureFlagSelector.featureFlags.downloadCsvActivityFeed === 'true';
}
export function selectEnableRewardsFlag(state) {
    return state.featureFlagSelector.featureFlags.enableRewards === 'true';
}
export function selectDisplayAuthUserCardDelayTHY(state) {
    return state.featureFlagSelector.featureFlags.displayAuthUserCardDelayTHY === 'true';
}
export function selectConsumerDocumentsFlag(state) {
    return state.featureFlagSelector.featureFlags.consumerDocumentsEnabled === 'true';
}
export function selectShowDownloadCsvNewIconFlag(state) {
    return state.featureFlagSelector.featureFlags.showDownloadCsvNewIcon === 'true';
}
export function selectMaintenanceWindowFeatureFlags(state) {
    return {
        isMaintenanceIndicatorEnabled: state.featureFlagSelector.featureFlags.isMaintenanceIndicatorEnabled,
        maintenanceWindowStartEpochMS: parseInt(state.featureFlagSelector.featureFlags.maintenanceWindowStartEpochMS),
        maintenanceWindowEndEpochMS: parseInt(state.featureFlagSelector.featureFlags.maintenanceWindowEndEpochMS),
    };
}
