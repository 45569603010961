var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Icons, ImpButton, ImpContainer, ImpIcon, ImpList, ImpSurface, } from '@imprint-payments/imprint-ui';
import { useNavigate } from 'react-router-dom';
import { EVENT } from '../../analytics/analyticsConsts';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectLanguage } from '../../app/languageSelectors';
import { SCREEN, setScreen } from '../../app/screenStateSlice';
import { useScreenWidth } from '../../components/loginPage/loginPageUtils';
import { ANALYTICS } from '../../firebase/firebase';
import { PAGE } from '../../utils/routeConstant';
export function SetUpPaymentsWidget() {
    var language = useAppSelector(selectLanguage);
    var screenStatus = useScreenWidth();
    var dispatch = useAppDispatch();
    var navigate = useNavigate();
    var handleSetUpPayments = function () {
        ANALYTICS.logEvent(EVENT.HOME_SET_UP_PAYMENTS_WIDGET_CLICKED);
        dispatch(setScreen(SCREEN.PAYMENTS));
        navigate(PAGE.PAYMENTS);
    };
    var mobileProps = {
        onClick: handleSetUpPayments,
        trailingContent: _jsx(ImpIcon, { iconSrc: Icons.chevronRight }),
    };
    function PaymentsButton() {
        return (_jsx(ImpButton, __assign({ variant: "secondary", size: "hug", buttonStyle: "branded", onClick: handleSetUpPayments }, { children: language.setUpAutopayWidgetText })));
    }
    var nonMobileProps = {
        onClick: undefined,
        trailingContent: _jsx(PaymentsButton, {}),
    };
    var props = screenStatus === 'mobile' ? mobileProps : nonMobileProps;
    return (_jsx(ImpContainer, __assign({ width: "100%" }, { children: _jsx(ImpSurface, __assign({ hasBorderRadius: true }, { children: _jsx(ImpContainer, __assign({ width: "100%" }, { children: _jsx(ImpList, { items: [
                        __assign({ title: language.setUpAutopayWidgetTitle, subtitle: language.setUpAutopayWidgetSubtitle }, props),
                    ] }) })) })) })));
}
