var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import styles from './Error.module.css';
import { useAppSelector } from '../../app/hooks';
import { ANALYTICS } from '../../firebase/firebase';
import { EVENT } from '../../analytics/analyticsConsts';
import HeaderServicing, { HEADER_SERVICING_TYPE, } from '../../components/HeaderServicing/HeaderServicing';
import Button, { ButtonColorScheme } from '../../components/button/Button';
import { useNavigate } from 'react-router-dom';
import { PAGE } from '../../utils/routeConstant';
import { clearServiceLoginState } from '../../utils/functionUtils';
import { useDispatch } from 'react-redux';
export default function Error() {
    var navigate = useNavigate();
    var dispatch = useDispatch();
    useEffect(function () {
        ANALYTICS.logEvent(EVENT.ERROR_VIEWED);
    }, []);
    var language = useAppSelector(function (state) { return state.languageSelector.languageLocalization; });
    return (_jsxs("div", __assign({ className: styles.container }, { children: [_jsx(HeaderServicing, { type: HEADER_SERVICING_TYPE.CENTER }), _jsxs("div", __assign({ className: styles.body }, { children: [_jsx("h1", __assign({ className: styles.title }, { children: language.experiencingSomeIssues })), _jsxs("p", __assign({ className: styles.subtitle }, { children: [language.experiencingSomeIssuesSubtitle, ' ', _jsxs("span", { children: [_jsx("a", __assign({ href: 'mailto:support@imprint.co' }, { children: "support@imprint.co" })), "."] })] })), _jsx("div", __assign({ className: styles.buttonContainer }, { children: _jsx(Button, { value: language.tryAgain, handleClick: function () {
                                clearServiceLoginState(dispatch);
                                navigate(PAGE.LOGIN);
                            }, colorScheme: ButtonColorScheme.fillImprint }) }))] }))] })));
}
