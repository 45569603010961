var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ImpButton, ImpContainer, ImpFlex, ImpImage, ImpSpacer, ImpText, colors, } from '@imprint-payments/imprint-ui';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { EVENT } from '../../analytics/analyticsConsts';
import { SUCCESS_TYPE, setSuccessInformation, setSuccessType } from '../../app/homeSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { SCREEN, setScreen } from '../../app/screenStateSlice';
import { STATEMENT_MEDIUM } from '../../app/statementsSlice';
import { FORM_BUTTON_WIDTH } from '../../assets/consts/const';
import Calendar from '../../assets/icons/Calendar.svg';
import CancelPayment from '../../assets/icons/CancelPayment.svg';
import CheckCircle from '../../assets/icons/CheckCircle.svg';
import HeaderServicing from '../../components/HeaderServicing/HeaderServicing';
import { ANALYTICS } from '../../firebase/firebase';
import { navigateToAccountPage } from '../../utils/functionUtils';
import { PAGE } from '../../utils/routeConstant';
export default function Success() {
    var language = useAppSelector(function (state) { return state.languageSelector.languageLocalization; });
    var navigate = useNavigate();
    var dispatch = useAppDispatch();
    var homeSelector = useAppSelector(function (state) { return state.homeSelector; });
    var successInformation = homeSelector.successInformation;
    var successType = homeSelector.successType;
    var personalInfoUpdateSuccess = [
        SUCCESS_TYPE.emailUpdate,
        SUCCESS_TYPE.addressUpdate,
        SUCCESS_TYPE.incomeUpdate,
    ];
    var brandedDoneButtonSuccess = [
        SUCCESS_TYPE.cardReplaced,
        SUCCESS_TYPE.cardRequested,
    ];
    // If user use url to trigger this page, we should redirect to home page.
    useEffect(function () {
        if (successType === SUCCESS_TYPE.default) {
            navigateToAccountPage(dispatch, navigate);
        }
        return function () {
            // Reset the success state to default.
            dispatch(setSuccessInformation({
                date: '',
                amount: '',
            }));
            dispatch(setSuccessType(SUCCESS_TYPE.default));
        };
    }, []);
    var getHeaderValue = function () {
        switch (successType) {
            case SUCCESS_TYPE.paymentCancel:
                return language.paymentCanceled;
            case SUCCESS_TYPE.paymentSchedule:
                return language.paymentScheduled;
            case SUCCESS_TYPE.autoPaySchedule:
                return language.autoPaySetup;
            case SUCCESS_TYPE.autoPayCancel:
                return language.autoPayCancel;
            case SUCCESS_TYPE.statementSetting:
                return language.paperlessSettingSavedHeader;
            case SUCCESS_TYPE.emailUpdate:
                return language.emailUpdateHeader;
            case SUCCESS_TYPE.phoneUpdate:
                return language.editPhoneSuccess;
            case SUCCESS_TYPE.addressUpdate:
                return language.addressUpdateHeader;
            case SUCCESS_TYPE.incomeUpdate:
            case SUCCESS_TYPE.securityPin:
                return language.infoUpdateHeader;
            case SUCCESS_TYPE.cardRequested:
                return language.cardRequestedHeader;
            case SUCCESS_TYPE.cardReplaced:
                return language.cardReplacedHeader;
            case SUCCESS_TYPE.paymentPayNow:
            default:
                return language.paymentSubmitted;
        }
    };
    var getSubheaderValue = function () {
        var amountReplaceKeyword = '[amount]';
        var dateReplaceKeyword = '[date]';
        var firstNameReplaceKeyword = '[First Name]';
        switch (successType) {
            case SUCCESS_TYPE.paymentCancel:
                return String(language.paymentCanceledSubheader)
                    .replace(amountReplaceKeyword, successInformation.amount)
                    .replace(dateReplaceKeyword, successInformation.date);
            case SUCCESS_TYPE.paymentSchedule:
                return String(language.paymentScheduledSubheader)
                    .replace(amountReplaceKeyword, successInformation.amount)
                    .replace(dateReplaceKeyword, successInformation.date);
            case SUCCESS_TYPE.autoPaySchedule:
                if (successInformation.date === '') {
                    // user set auto-pay on due date
                    return String(language.autoPaySetupOnDueDateSubheader).replace(amountReplaceKeyword, successInformation.amount);
                }
                return String(language.autoPaySetupSubheader)
                    .replace(amountReplaceKeyword, successInformation.amount)
                    .replace(dateReplaceKeyword, successInformation.date);
            case SUCCESS_TYPE.autoPayCancel:
                return language.autoPayCancelSubheader;
            case SUCCESS_TYPE.statementSetting:
                return successInformation.statementMedium === STATEMENT_MEDIUM.paperless
                    ? language.paperlessSettingSavedSubheader
                    : '';
            case SUCCESS_TYPE.cardRequested:
                return language.cardRequestedSubheader;
            case SUCCESS_TYPE.cardReplaced:
                return String(language.cardReplacedSubheader).replace(firstNameReplaceKeyword, homeSelector.userInformation.firstName);
            case SUCCESS_TYPE.paymentPayNow:
                return language.paymentSubmittedSubheader;
            default:
                return '';
        }
    };
    var getSuccessImg = function () {
        switch (successType) {
            case SUCCESS_TYPE.autoPayCancel:
            case SUCCESS_TYPE.paymentCancel:
                return CancelPayment;
            case SUCCESS_TYPE.paymentSchedule:
                return Calendar;
            case SUCCESS_TYPE.paymentPayNow:
            case SUCCESS_TYPE.autoPaySchedule:
            case SUCCESS_TYPE.statementSetting:
            default:
                return CheckCircle;
        }
    };
    return (_jsxs(_Fragment, { children: [_jsx(HeaderServicing, {}), _jsx(ImpFlex, __assign({ direction: "column", align: "center", fluid: true }, { children: _jsxs(ImpContainer, __assign({ width: "min(552px, 100%)", textAlign: "center" }, { children: [_jsx(ImpSpacer, { height: '5xl' }), _jsx(ImpSpacer, { height: '5xl' }), _jsx(ImpSpacer, { height: 'xl' }), _jsxs(ImpFlex, __assign({ direction: "column", justify: "center", align: "center" }, { children: [_jsx(ImpImage, { src: getSuccessImg(), alt: 'successimg', height: '45px', width: '45px' }), _jsx(ImpSpacer, { height: 'xl' }), _jsx(ImpText, __assign({ typography: "headline1" }, { children: getHeaderValue() })), _jsx(ImpSpacer, { height: 'm' }), _jsx(ImpText, __assign({ typography: "body2", mobileTypography: "body3", color: colors.content.onBackgroundVariant }, { children: getSubheaderValue() })), _jsx(ImpSpacer, { height: '2xl' }), _jsx(ImpContainer, __assign({ width: FORM_BUTTON_WIDTH }, { children: _jsx(ImpButton, __assign({ size: "fill", variant: "primary", buttonStyle: brandedDoneButtonSuccess.includes(successType)
                                            ? 'branded'
                                            : 'imprint', onClick: function () {
                                            if (personalInfoUpdateSuccess.includes(successType)) {
                                                dispatch(setScreen(SCREEN.PERSONAL_INFO));
                                                navigate(PAGE.PERSONAL_INFO);
                                                ANALYTICS.logEvent(EVENT.SUCCESS_BACK_TO_PERSONAL_INFO_CLICKED);
                                            }
                                            else {
                                                navigateToAccountPage(dispatch, navigate);
                                                ANALYTICS.logEvent(EVENT.SUCCESS_BACK_TO_HOME_CLICKED);
                                            }
                                        } }, { children: language.done })) }))] }))] })) }))] }));
}
