var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ImpContainer, ImpFlex, ImpSpacer, ImpText, ImpButton, } from '@imprint-payments/imprint-ui';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { navigateToAccountPage } from '../../utils/functionUtils';
import { useNavigate } from 'react-router-dom';
import { ANALYTICS } from '../../firebase/firebase';
import { EVENT } from '../../analytics/analyticsConsts';
import { useEffect } from 'react';
export function RejectedPage() {
    var dispatch = useAppDispatch();
    var navigate = useNavigate();
    var language = useAppSelector(function (state) { return state.languageSelector.languageLocalization; });
    function handlePrimaryButtonClick() {
        navigateToAccountPage(dispatch, navigate);
    }
    useEffect(function () {
        ANALYTICS.logEvent(EVENT.AUTH_USER_DECISION_REJECTED);
    }, []);
    return (_jsx(ImpContainer, __assign({ textAlign: "center" }, { children: _jsxs(ImpFlex, __assign({ fluid: true, direction: "column", align: "center" }, { children: [_jsx(ImpText, __assign({ typography: "headline2", mobileTypography: "headline3" }, { children: language.authRejectedTitle })), _jsx(ImpSpacer, { height: 'm' }), _jsx(ImpText, __assign({ typography: "body2" }, { children: language.authRejectedSubtitle })), _jsx(ImpSpacer, { height: 'xl' }), _jsx(ImpFlex, __assign({ direction: "row", align: "center" }, { children: _jsx(ImpButton, __assign({ buttonStyle: "branded", variant: "primary", onClick: handlePrimaryButtonClick }, { children: language.done })) }))] })) })));
}
