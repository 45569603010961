var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { RESPONSE_CODE } from '../api/apiConstants';
import { ENV } from '../app/apiScreenSlice';
import { store } from '../app/store';
import { requestAssetUrlProd, requestAssetUrlStaging } from '../assets/consts/const';
import { getLanguageFromCookie } from './functionUtils';
import { getSignature } from './headers';
var GENERIC_HEADER = {
    'x-imprint-platform': 'web',
};
export var METHOD;
(function (METHOD) {
    METHOD["DELETE"] = "delete";
    METHOD["GET"] = "get";
    METHOD["POST"] = "post";
    METHOD["PUT"] = "put";
})(METHOD || (METHOD = {}));
export var Request = function (_a) {
    var url = _a.url, _b = _a.method, method = _b === void 0 ? METHOD.GET : _b, token = _a.token, body = _a.body, deviceID = _a.deviceID, _c = _a.keepAlive, keepAlive = _c === void 0 ? false : _c;
    return __awaiter(void 0, void 0, void 0, function () {
        var tokenHeader, parse_url, signature, signatureHeader, languageHeader, deviceIDHeader;
        return __generator(this, function (_d) {
            tokenHeader = token == null ? undefined : { 'x-imprint-access-token': "".concat(token) };
            parse_url = url.split('?')[0];
            signature = getSignature({
                path: parse_url,
                state: store.getState(),
                body: body,
            });
            signatureHeader = signature === ''
                ? ''
                : {
                    'x-imprint-signature': "".concat(signature),
                };
            languageHeader = {
                'Accept-Language': getLanguageFromCookie(),
            };
            deviceID = deviceID !== null && deviceID !== void 0 ? deviceID : store.getState().apiScreenScreenSelector.deviceID;
            deviceIDHeader = deviceID === ''
                ? ''
                : {
                    'x-imprint-device-id': "".concat(deviceID),
                };
            return [2 /*return*/, new Promise(function (resolve, reject) {
                    fetch("".concat(getBaseUrl()).concat(url), {
                        headers: __assign(__assign(__assign(__assign(__assign({}, tokenHeader), signatureHeader), deviceIDHeader), GENERIC_HEADER), languageHeader),
                        method: method,
                        keepalive: keepAlive,
                        body: JSON.stringify(body),
                    })
                        .then(function (res) { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    if (!!res.ok) return [3 /*break*/, 2];
                                    return [4 /*yield*/, res.json()];
                                case 1: throw _a.sent();
                                case 2: return [2 /*return*/, res.status !== RESPONSE_CODE.NO_CONTENT ? res.json() : res];
                            }
                        });
                    }); })
                        .then(function (data) { return resolve(data); })
                        .catch(function (e) {
                        return reject(e);
                    });
                })];
        });
    });
};
export var requestAssests = function (_a) {
    var url = _a.url, _b = _a.image, image = _b === void 0 ? false : _b;
    var requestUrl = store.getState().apiScreenScreenSelector.env === ENV.PROD
        ? requestAssetUrlProd + url
        : requestAssetUrlStaging + url;
    return new Promise(function (resolve, reject) {
        fetch(requestUrl)
            .then(function (res) { return __awaiter(void 0, void 0, void 0, function () {
            var imageBlob, imageObjectURL;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!!res.ok) return [3 /*break*/, 2];
                        return [4 /*yield*/, res];
                    case 1: throw _a.sent();
                    case 2:
                        if (!image) return [3 /*break*/, 4];
                        return [4 /*yield*/, res.blob()];
                    case 3:
                        imageBlob = _a.sent();
                        imageObjectURL = URL.createObjectURL(imageBlob);
                        return [2 /*return*/, imageObjectURL];
                    case 4: return [2 /*return*/, res.json()];
                }
            });
        }); })
            .then(function (data) { return resolve(data); })
            .catch(function (e) {
            return reject(e);
        });
    });
};
export var ApplicationEligibilityRequest = function (_a) {
    var url = _a.url, _b = _a.method, method = _b === void 0 ? METHOD.GET : _b, token = _a.token, productUUID = _a.productUUID, email = _a.email;
    return __awaiter(void 0, void 0, void 0, function () {
        var tokenHeader, signature, signatureHeader;
        return __generator(this, function (_c) {
            tokenHeader = token == null ? undefined : { 'x-imprint-access-token': "".concat(token) };
            signature = getSignature({
                path: "".concat(url, "/").concat(productUUID, "/eligibility"),
                state: store.getState(),
            });
            signatureHeader = signature === ''
                ? undefined
                : {
                    'x-imprint-signature': "".concat(signature),
                };
            return [2 /*return*/, new Promise(function (resolve, reject) {
                    fetch("".concat(getBaseUrl()).concat(url, "/").concat(productUUID, "/eligibility?email=").concat(email), {
                        headers: __assign(__assign(__assign({}, tokenHeader), signatureHeader), GENERIC_HEADER),
                        method: method,
                    })
                        .then(function (res) { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    if (!!res.ok) return [3 /*break*/, 2];
                                    return [4 /*yield*/, res.json()];
                                case 1: throw _a.sent();
                                case 2: return [2 /*return*/, res.json()];
                            }
                        });
                    }); })
                        .then(function (data) {
                        resolve(data);
                    })
                        .catch(function (e) {
                        return reject(e);
                    });
                })];
        });
    });
};
export var RejectionPDFRequest = function (_a) {
    var url = _a.url, _b = _a.method, method = _b === void 0 ? METHOD.GET : _b, token = _a.token, applicationUUID = _a.applicationUUID;
    return __awaiter(void 0, void 0, void 0, function () {
        var tokenHeader, signature, signatureHeader;
        return __generator(this, function (_c) {
            tokenHeader = token == null ? undefined : { 'x-imprint-access-token': "".concat(token) };
            signature = getSignature({
                path: "".concat(url, "/").concat(applicationUUID, "/reasons"),
                state: store.getState(),
            });
            signatureHeader = signature === ''
                ? undefined
                : {
                    'x-imprint-signature': "".concat(signature),
                };
            return [2 /*return*/, new Promise(function (resolve, reject) {
                    fetch("".concat(getBaseUrl()).concat(url, "/").concat(applicationUUID, "/reasons"), {
                        headers: __assign(__assign(__assign({}, tokenHeader), signatureHeader), GENERIC_HEADER),
                        method: method,
                    })
                        .then(function (res) { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    if (!!res.ok) return [3 /*break*/, 2];
                                    return [4 /*yield*/, res.json()];
                                case 1: throw _a.sent();
                                case 2: return [2 /*return*/, res.json()];
                            }
                        });
                    }); })
                        .then(function (data) {
                        resolve(data);
                    })
                        .catch(function (e) {
                        return reject(e);
                    });
                })];
        });
    });
};
function getBaseUrl() {
    return store.getState().apiScreenScreenSelector.baseURL;
}
