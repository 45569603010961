var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ImpContainer, ImpFlex, ImpSpacer, ImpText } from '@imprint-payments/imprint-ui';
export function ConfirmationScreen(_a) {
    var title = _a.title, subtitle = _a.subtitle, actionButton = _a.actionButton, cancelButton = _a.cancelButton;
    return (_jsx(ImpContainer, __assign({ textAlign: "center" }, { children: _jsxs(ImpFlex, __assign({ direction: "column", align: "center", justify: "center" }, { children: [_jsx(ImpText, __assign({ typography: "headline2", mobileTypography: "headline3" }, { children: title })), _jsx(ImpSpacer, { height: "m" }), _jsx(ImpText, __assign({ typography: "body2" }, { children: subtitle })), _jsx(ImpSpacer, { height: "2xl" }), _jsx(ImpContainer, __assign({ width: "min(356px, 100%)" }, { children: _jsxs(ImpFlex, __assign({ direction: "column", justify: "center", gap: "m" }, { children: [actionButton, cancelButton] })) }))] })) })));
}
