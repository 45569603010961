var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import styles from './InputField.module.css';
import lock from '../../assets/images/lock.png';
import { SEND_FULL_ADDRESS } from '../../assets/events/eventConst';
import { formatFullSSN } from '../../utils/formatUtils';
import { ValidationResponse, getShowError } from '../../utils/validateUtils';
import arrowDown from '../../assets/images/arrow-down.png';
import arrowUp from '../../assets/images/arrow-up.png';
import hideIconSvg from '../../assets/icons/hideIcon.svg';
import unhideIconSvg from '../../assets/icons/unhideIcon.svg';
import { useAppSelector } from '../../app/hooks';
import { checkAutocompleteDelayMs } from '../../assets/consts/const';
export default function InputField(_a) {
    var placeholder = _a.placeholder, value = _a.value, length = _a.length, center = _a.center, fieldId = _a.fieldId, centerError = _a.centerError, errorMsg = _a.errorMsg, overrideDisplayError = _a.overrideDisplayError, _b = _a.defaultErr, defaultErr = _b === void 0 ? false : _b, computedErr = _a.computedErr, disabled = _a.disabled, options = _a.options, customAriaLabel = _a.customAriaLabel, noEdit = _a.noEdit, noLabel = _a.noLabel, branded = _a.branded, medium = _a.medium, large = _a.large, onEnterPressed = _a.onEnterPressed, callback = _a.callback, validator = _a.validator, analyticsEvent = _a.analyticsEvent, errCallback = _a.errCallback;
    var language = useAppSelector(function (state) { return state.languageSelector.languageLocalization; });
    var consumerInfo = useAppSelector(function (state) { return state.consumerInfoSelector; });
    var _c = useState(false), isFocus = _c[0], setIsFocus = _c[1];
    var _d = useState(false), showErr = _d[0], setShowErr = _d[1];
    var _e = useState(false), showOptions = _e[0], setShowOptions = _e[1];
    var _f = useState(''), ssn = _f[0], setSsn = _f[1];
    var _g = useState(''), ssnUndo = _g[0], setSsnUndo = _g[1];
    var _h = useState(styles.input), classInputBox = _h[0], setClassInputBox = _h[1];
    var _j = useState(styles.input), classInput = _j[0], setClassInput = _j[1];
    var _k = useState(styles.label), classLabel = _k[0], setClassLabel = _k[1];
    var _l = useState(options), optionsList = _l[0], setOptionsList = _l[1];
    var _m = useState(''), selectedOption = _m[0], setSelectedOption = _m[1];
    var _o = useState(''), errMsg = _o[0], setErrMsg = _o[1];
    var _p = useState(false), analyticsSent = _p[0], setAnalyticsSent = _p[1];
    var _q = useState(true), hideInput = _q[0], setHideInput = _q[1];
    var _r = useState(undefined), autocompleteTimeout = _r[0], setAutocompleteTimeout = _r[1];
    var validateWhileTyping = [
        ValidationResponse.invalidEmailLong,
        ValidationResponse.invalidCityLong,
        ValidationResponse.invalidState,
        ValidationResponse.invalidAddressLong,
        ValidationResponse.invalidNameLong,
        ValidationResponse.invalidName,
        ValidationResponse.shortFullSSN,
        ValidationResponse.invalidSSNMatchLastFour,
        ValidationResponse.invalidHhEmail,
        ValidationResponse.invalidAccountNum,
        ValidationResponse.invalidRoutingNum,
    ];
    var noError = [ValidationResponse.valid, ValidationResponse.empty];
    var filterOptions = function (data, value) {
        return data.filter(function (cur) {
            var matched = true;
            for (var i = 0; i < value.length; i++) {
                if (cur.value[i] !== value[i]) {
                    matched = false;
                }
            }
            return matched;
        });
    };
    var selectOption = function (e, item) {
        e.stopPropagation();
        validator && validator(item.value);
        callback(item.value);
        setShowOptions(false);
    };
    var handleOptionKeyDown = function (e, item, index) {
        var _a, _b, _c;
        var keyCode = e.code;
        if (keyCode == 'Tab' && index == options.length - 1) {
            e.preventDefault();
            ((_a = document.getElementById('listContainer')) === null || _a === void 0 ? void 0 : _a.firstChild).focus();
            return;
        }
        if (keyCode == 'ArrowUp') {
            e.preventDefault();
            if (index == 0) {
                (_b = document.getElementById(fieldId)) === null || _b === void 0 ? void 0 : _b.focus();
                return;
            }
            e.currentTarget.previousSibling &&
                e.currentTarget.previousSibling.focus();
        }
        else if (keyCode == 'ArrowDown') {
            e.preventDefault();
            if (index == options.length - 1) {
                ((_c = document.getElementById('listContainer')) === null || _c === void 0 ? void 0 : _c.firstChild).focus();
            }
            e.currentTarget.nextSibling &&
                e.currentTarget.nextSibling.focus();
        }
        else if (keyCode == 'Enter') {
            selectOption(e, item);
            setSelectedOption(options[index].value);
        }
    };
    useEffect(function () {
        if (fieldId == 'HHInputField') {
            if (consumerInfo.emailVerificationFailed) {
                setShowErr(true);
                setErrMsg(language.invalidHhEmail);
            }
            else {
                setShowErr(false);
            }
        }
        else {
            setErrMsg(errorMsg);
        }
    }, [fieldId, consumerInfo.emailVerificationFailed, errorMsg]);
    useEffect(function () {
        // if input is not valid, do not send analytics
        if (validator && !(computedErr === ValidationResponse.valid)) {
            return;
        }
        // if analytics have not already been sent and value is filled (and valid), send analytics
        if (!analyticsSent && value !== '') {
            analyticsEvent && analyticsEvent();
            setAnalyticsSent(true);
        }
    }, [value, computedErr, focus]);
    useEffect(function () {
        if (validator && computedErr) {
            // show all errors if we're not in focus
            if (!isFocus) {
                setShowErr(getShowError(computedErr));
            }
        }
    }, [isFocus, computedErr]);
    useEffect(function () {
        if (overrideDisplayError != null) {
            setShowErr(overrideDisplayError);
        }
    }, [overrideDisplayError]);
    useEffect(function () {
        var handleClick = function (event) {
            if (!isFocus &&
                event.target.id !== 'list' &&
                event.target.id !== 'option') {
                setShowOptions(false);
            }
        };
        if (showOptions) {
            window.addEventListener('click', handleClick);
        }
        return function () {
            if (showOptions) {
                window.removeEventListener('click', handleClick);
            }
        };
    });
    useEffect(function () {
        if (validator && computedErr) {
            // if "validateWhileTyping" error occurs, show the error
            if (validateWhileTyping.includes(computedErr)) {
                setShowErr(true);
            }
            else {
                setShowErr(false);
            }
        }
        if (options && !noEdit) {
            setOptionsList(filterOptions(options, value));
        }
    }, [value]);
    useEffect(function () {
        setShowErr(defaultErr);
    }, []);
    useEffect(function () {
        var labelStyling = [];
        if (isFocus || value !== '') {
            if (noLabel) {
                labelStyling.push(styles.noLabel);
            }
            else {
                labelStyling.push(styles.smallLabel);
            }
        }
        if ((overrideDisplayError || validator) && showErr) {
            labelStyling.push(styles.errorLabel);
        }
        if (large) {
            labelStyling.push(styles.largeLabel);
        }
        else if (medium) {
            labelStyling.push(styles.mediumLabel);
        }
        setClassLabel(labelStyling.join(' '));
    }, [showErr, isFocus, noLabel, center, value, computedErr]);
    useEffect(function () {
        var inputStyling = [];
        var inputBoxStyling = [styles.input];
        if (center) {
            inputBoxStyling.push(styles.centerInputBox);
            inputStyling.push(styles.centerInput);
        }
        if (branded) {
            inputStyling.push(styles.brandedInput);
        }
        if (overrideDisplayError || (validator && computedErr)) {
            if (disabled) {
                inputBoxStyling.push(styles.disabled);
            }
            else if (showErr) {
                inputBoxStyling.push(styles.errorInputBox);
            }
            if (showErr) {
                inputStyling.push(styles.errorInput);
            }
        }
        if (noLabel) {
            inputStyling.push(styles.noLabelInput);
        }
        if (large) {
            inputStyling.push(styles.largeInput);
        }
        else if (medium) {
            inputStyling.push(styles.mediumInput);
        }
        setClassInputBox(inputBoxStyling.join(' '));
        setClassInput(inputStyling.join(' '));
    }, [showErr, computedErr, center]);
    // Check for autocomplete if previous input is more than checkAutocompleteDelay miliseconds.
    var invokeCheckAutocomplete = function () {
        if (fieldId === 'searchAddress') {
            clearTimeout(autocompleteTimeout);
            setAutocompleteTimeout(setTimeout(checkAutocomplete, checkAutocompleteDelayMs));
        }
    };
    var checkAutocomplete = function () {
        if (fieldId === 'searchAddress') {
            var autocompleteAddress_1 = {
                address1: '',
                city: '',
                state: '',
                postal_code: '',
            };
            var field = document.getElementById('searchAddress');
            // @ts-ignore-next-line
            var autocomplete_1 = new google.maps.places.Autocomplete(field, {
                componentRestrictions: { country: ['us'] },
                fields: ['address_components', 'geometry'],
                types: ['address'],
            });
            autocomplete_1.addListener('place_changed', function () {
                var components = autocomplete_1.getPlace().address_components;
                components.forEach(function (c) {
                    if (c.types[0] === 'street_number') {
                        autocompleteAddress_1.address1 = c.long_name;
                    }
                    else if (c.types[0] === 'route') {
                        if (autocompleteAddress_1.address1) {
                            autocompleteAddress_1.address1 += " ".concat(c.short_name);
                        }
                        else {
                            autocompleteAddress_1.address1 = c.short_name;
                        }
                    }
                    else if (c.types[0] === 'sublocality_level_1' ||
                        c.types[0] === 'locality') {
                        autocompleteAddress_1.city = c.long_name;
                    }
                    else if (c.types[0] === 'administrative_area_level_1') {
                        autocompleteAddress_1.state = c.short_name;
                    }
                    else if (c.types[0] === 'postal_code') {
                        autocompleteAddress_1.postal_code = c.short_name;
                    }
                });
                var customEvent = new CustomEvent(SEND_FULL_ADDRESS, {
                    detail: autocompleteAddress_1,
                    bubbles: true,
                    composed: true,
                });
                document.dispatchEvent(customEvent);
            });
        }
    };
    var mask = function () {
        var masked = [];
        for (var i = 0; i < ssn.length; i++) {
            if (i <= 6) {
                masked.push(ssn[i].replace(/[0-9]/g, 'X'));
            }
            if (i > 6) {
                masked.push(ssn[i]);
            }
        }
        setSsn(masked.join(''));
    };
    var unmask = function () {
        setSsn(ssnUndo);
    };
    return (_jsxs("div", __assign({ className: styles.contentContainer }, { children: [_jsxs("div", __assign({ className: classInputBox }, { children: [_jsx("label", __assign({ htmlFor: fieldId, className: classLabel }, { children: placeholder })), _jsx("input", { autoComplete: "off", "aria-label": customAriaLabel, type: hideInput &&
                            (fieldId === 'linkBankAccount' || fieldId === 'linkBankRouting')
                            ? 'password'
                            : '', placeholder: "", id: fieldId, tabIndex: 0, className: classInput, readOnly: noEdit, onFocus: function () {
                            setIsFocus(true);
                            fieldId === 'fullSSN' ? unmask() : '';
                            if (options) {
                                setShowOptions(true);
                            }
                        }, onBlur: function (e) {
                            setIsFocus(false);
                            fieldId === 'fullSSN' ? mask() : '';
                            validator && validator(e.target.value);
                        }, maxLength: length, onChange: function (e) {
                            invokeCheckAutocomplete();
                            setSsn(formatFullSSN(e.target.value));
                            setSsnUndo(formatFullSSN(e.target.value));
                            callback(e.target.value);
                            validator && validator(e.target.value);
                        }, onKeyDown: function (e) {
                            var _a, _b;
                            var keyCode = e.code;
                            if (options) {
                                if (keyCode == 'Tab') {
                                    (_a = document.getElementById('listContainer')) === null || _a === void 0 ? void 0 : _a.focus();
                                }
                                else if (keyCode == 'ArrowDown') {
                                    e.preventDefault();
                                    (_b = document.getElementById('list')) === null || _b === void 0 ? void 0 : _b.focus();
                                }
                            }
                            else {
                                if (keyCode == 'Enter' && onEnterPressed) {
                                    onEnterPressed();
                                }
                            }
                        }, value: fieldId === 'fullSSN' ? ssn : value }), (fieldId === 'ssn' || fieldId === 'fullSSN') && _jsx("img", { src: lock }), (fieldId === 'linkBankAccount' || fieldId === 'linkBankRouting') && (_jsx("img", { className: styles.hideIcon, src: hideInput ? unhideIconSvg : hideIconSvg, onClick: function () {
                            setHideInput(!hideInput);
                        } })), options && (_jsx("img", { className: styles.arrow, src: showOptions ? arrowUp : arrowDown }))] })), showErr && errMsg && (_jsx("div", __assign({ className: centerError
                    ? "".concat(styles.centerErrorMsg, " ").concat(styles.errorMsg)
                    : styles.errorMsg, tabIndex: 0 }, { children: errMsg }))), showOptions && optionsList.length > 0 && (_jsx("div", __assign({ id: "listContainer", tabIndex: 1, className: styles.content, onClick: function () {
                    validator && validator(selectedOption);
                    callback(selectedOption);
                    setShowOptions(false);
                }, onKeyDown: function (e) {
                    var keyCode = e.code;
                    if (keyCode == 'Enter') {
                        validator && validator(selectedOption);
                        callback(selectedOption);
                        setShowOptions(false);
                    }
                    if (keyCode == 'ArrowDown') {
                        e.preventDefault();
                    }
                } }, { children: optionsList.map(function (item, index) {
                    return (_jsx("div", __assign({ className: styles.option, id: "list", tabIndex: 2, onClick: function (e) {
                            selectOption(e, item);
                        }, onMouseOver: function () {
                            setSelectedOption(options[index].value);
                        }, onKeyDown: function (e) {
                            handleOptionKeyDown(e, item, index);
                        } }, { children: item.label }), item.label));
                }) })))] })));
}
