export var BATCH_SIZE = 1;
export var COUNT1 = 1;
export var ERROR = 'errors';
export var DEFAULT_SAMPLE_RATE = 1.0;
export var tagKeyWhere = 'where:';
export var tagKeyPkgName = 'pkg:';
export var tagKeyFuncName = 'func:';
export var tagKeyReason = 'reason:';
export var PKG = 'WEB_SERVICING';
/*
Event Logging
*/
export var EVENT;
(function (EVENT) {
    // Login
    EVENT["ENTER_PHONE_NUMBER_VIEWED"] = "enter_phone_number_viewed";
    EVENT["ENTER_PHONE_NUMBER_FILLED_OUT"] = "enter_phone_number_filled_out";
    EVENT["ENTER_PHONE_NUMBER_LOG_IN_CLICKED"] = "enter_phone_number_log_in_clicked";
    EVENT["ENTER_PHONE_NUMBER_NOT_RECOGNIZED_VIEWED"] = "enter_phone_number_not_recognized_viewed";
    EVENT["CONVERSION_BANNER_CLICKED"] = "conversion_banner_clicked";
    EVENT["ENTER_PHONE_FIND_ACCOUNT_CLICKED"] = "enter_phone_find_account_clicked";
    EVENT["LOGIN_OTP_VIEWED"] = "login_otp_viewed";
    EVENT["LOGIN_OTP_SUBMITTED"] = "login_otp_submitted";
    EVENT["LOGIN_OTP_SEND_CODE_AGAIN_CLICKED"] = "login_otp_send_code_again_clicked";
    EVENT["LOGIN_OTP_SEND_VOICE_CODE_CLICKED"] = "login_otp_send_voice_code_clicked";
    EVENT["LOGIN_OTP_SEND_VOICE_CODE_AGAIN_CLICKED"] = "login_otp_send_voice_code_again_clicked";
    EVENT["LOGIN_OTP_GO_BACK_CLICKED"] = "login_otp_go_back_clicked";
    EVENT["LOGIN_OTP_TRY_AGAIN_VIEWED"] = "login_otp_try_again_viewed";
    EVENT["LOGIN_OTP_TRY_AGAIN_SUBMITTED"] = "login_otp_try_again_submitted";
    EVENT["LOGIN_OTP_TRY_AGAIN_SEND_CODE_AGAIN_CLICKED"] = "login_otp_try_again_send_code_again_clicked";
    EVENT["LOGIN_OTP_TRY_AGAIN_GO_BACK_CLICKED"] = "login_otp_try_again_go_back_clicked";
    EVENT["LOGIN_FORGOT_PIN_CLICKED"] = "login_forgot_pin_clicked";
    EVENT["VERIFY_DOB_VIEWED"] = "verify_dob_viewed";
    EVENT["VERIFY_DOB_FILLED_OUT"] = "verify_dob_filled_out";
    EVENT["VERIFY_DOB_CONTINUE_CLICKED"] = "verify_dob_continue_clicked";
    EVENT["VERIFY_DOB_TRY_AGAIN_VIEWED"] = "verify_dob_try_again_viewed";
    EVENT["VERIFY_DOB_TRY_AGAIN_CONTINUE_CLICKED"] = "verify_dob_try_again_continue_clicked";
    EVENT["DOB_FAILED"] = "dob_failed";
    EVENT["CREATE_PIN_VIEWED"] = "create_pin_viewed";
    EVENT["CREATE_PIN_SUBMITTED"] = "create_pin_submitted";
    EVENT["CREATE_PIN_INVALID_PIN_VIEWED"] = "create_pin_invalid_pin_viewed";
    EVENT["CREATE_PIN_ERROR_CRITERIA"] = "create_pin_error_criteria";
    EVENT["CREATE_PIN_ERROR_MATCH"] = "create_pin_error_match";
    EVENT["RESET_PIN_CONFIRMED_VIEWED"] = "reset_pin_confirmed_viewed";
    EVENT["RESET_PIN_CONFIRMED_CLICKED"] = "reset_pin_confirmed_clicked";
    EVENT["CONFIRM_PIN_VIEWED"] = "confirm_pin_viewed";
    EVENT["CONFIRM_PIN_SUBMITTED"] = "confirm_pin_submitted";
    EVENT["CONFIRM_PIN_RESET_PIN_CLICKED"] = "confirm_pin_reset_pin_clicked";
    EVENT["CONFIRM_PIN_TRY_AGAIN_VIEWED"] = "confirm_pin_try_again_viewed";
    EVENT["CONFIRM_PIN_TRY_AGAIN_RESET_PIN_CLICKED"] = "confirm_pin_try_again_reset_pin_clicked";
    EVENT["ENTER_PIN_VIEWED"] = "enter_pin_viewed";
    EVENT["ENTER_PIN_SUBMITTED"] = "enter_pin_submitted";
    EVENT["PIN_INCORRECT_VIEWED"] = "pin_incorrect_viewed";
    EVENT["PIN_LOCKED_VIEWED"] = "pin_locked_viewed";
    EVENT["PIN_SERVER_ERROR"] = "pin_server_error";
    EVENT["DOWNLOAD_APP_SPEND_CARD_VIEWED"] = "download_app_spend_card_viewed";
    EVENT["DOWNLOAD_APP_ACCEPT_OFFER_VIEWED"] = "download_app_accept_offer_viewed";
    EVENT["HOME_VIEWED"] = "home_viewed";
    EVENT["MERCHANT_PAGE_PAY_BUTTON_CLICKED"] = "merchant_page_pay_button_clicked";
    EVENT["HEADER_IMPRINT_LOGO_CLICKED"] = "header_imprint_logo_clicked";
    EVENT["HEADER_SIGNOUT_CLICKED"] = "header_signout_clicked";
    EVENT["HEADER_CARD_DETAILS_CLICKED"] = "header_card_details_clicked";
    EVENT["BREADCRUMBS_BACK_CLICKED"] = "breadcrumbs_back_clicked";
    EVENT["BREADCRUMBS_HOME_CLICKED"] = "breadcrumbs_home_clicked";
    // Find account
    EVENT["FIND_ACCOUNT_VIEWED"] = "find_account_viewed";
    EVENT["FIND_ACCOUNT_NAME_FILLED_OUT"] = "find_account_name_filled_out";
    EVENT["FIND_ACCOUNT_DOB_MONTH_FILLED_OUT"] = "find_account_dob_month_filled_out";
    EVENT["FIND_ACCOUNT_DOB_DAY_FILLED_OUT"] = "find_account_dob_day_filled_out";
    EVENT["FIND_ACCOUNT_DOB_YEAR_FILLED_OUT"] = "find_account_dob_year_filled_out";
    EVENT["FIND_ACCOUNT_SSN_FILLED_OUT"] = "find_account_ssn_filled_out";
    EVENT["FIND_ACCOUNT_BACK_CLICKED"] = "find_account_back_clicked";
    EVENT["FIND_ACCOUNT_CONTINUE_CLICKED"] = "find_account_continue_clicked";
    EVENT["FIND_ACCOUNT_ERROR_VIEWED"] = "find_account_error_viewed";
    EVENT["FIND_ACCOUNT_ERROR_OKAY_CLICKED"] = "find_account_error_okay_clicked";
    EVENT["FIND_ACCOUNT_ERROR_SUPPORT_CLICKED"] = "find_account_error_support_clicked";
    EVENT["ACCOUNT_FOUND_VIEWED"] = "account_found_viewed";
    EVENT["ACCOUNT_FOUND_CONTINUE_CLICKED"] = "account_found_continue_clicked";
    EVENT["ACCOUNT_FOUND_PHONE_VIEWED"] = "account_found_phone_viewed";
    EVENT["ACCOUNT_FOUND_PHONE_FILLED_OUT"] = "account_found_phone_filled_out";
    EVENT["ACCOUNT_FOUND_PHONE_EDITED"] = "account_found_phone_edited";
    EVENT["ACCOUNT_FOUND_PHONE_INVALID_VIEWED"] = "account_found_phone_invalid_viewed";
    EVENT["ACCOUNT_FOUND_PHONE_CONTINUE_CLICKED"] = "account_found_phone_continue_clicked";
    EVENT["ACCOUNT_FOUND_PHONE_ERROR_VIEWED"] = "account_found_phone_error_viewed";
    EVENT["UPDATE_PHONE_IDV_VIEWED"] = "update_phone_idv_viewed";
    EVENT["UPDATE_PHONE_IDV_CONTINUE_CLICKED"] = "update_phone_idv_continue_clicked";
    EVENT["UPDATE_PHONE_IDV_ERROR_VIEWED"] = "update_phone_idv_error_viewed";
    EVENT["UPDATE_PHONE_IDV_ERROR_SUPPORT_CLICKED"] = "update_phone_idv_error_support_clicked";
    // Onboarding
    EVENT["CONFIRM_EMAIL_VIEWED"] = "confirm_email_viewed";
    EVENT["CONFIRM_EMAIL_EDITED"] = "confirm_email_edited";
    EVENT["CONFIRM_EMAIL_CLICKED"] = "confirm_email_clicked";
    EVENT["CONFIRM_EMAIL_OTP_VIEWED"] = "confirm_email_otp_viewed";
    EVENT["CONFIRM_EMAIL_OTP_SUBMITTED"] = "confirm_email_otp_submitted";
    EVENT["CONFIRM_EMAIL_OTP_TRY_AGAIN_VIEWED"] = "confirm_email_otp_try_again_viewed";
    EVENT["CONFIRM_EMAIL_OTP_RESEND_CLICKED"] = "confirm_email_otp_resend_clicked";
    EVENT["GO_PAPERLESS_VIEWED"] = "go_paperless_viewed";
    EVENT["GO_PAPERLESS_CONFIRM_CLICKED"] = "go_paperless_confirm_clicked";
    EVENT["GO_PAPERLESS_NO_THANKS_CLICKED"] = "go_paperless_no_thanks_clicked";
    EVENT["PAPERLESS_ENABLED_VIEWED"] = "paperless_enabled_viewed";
    EVENT["PAPERLESS_ENABLED_OKAY_CLICKED"] = "paperless_enabled_okay_clicked";
    // Conversion
    EVENT["PRECONVERSION_WELCOME_VIEWED"] = "preconversion_welcome_viewed";
    EVENT["PRECONVERSION_WELCOME_CLICKED"] = "preconversion_welcome_clicked";
    EVENT["POSTCONVERSION_WELCOME_VIEWED"] = "postconversion_welcome_viewed";
    EVENT["POSTCONVERSION_WELCOME_CLICKED"] = "postconversion_welcome_clicked";
    // User Menu
    EVENT["USER_MENU_VIEWED"] = "user_menu_viewed";
    EVENT["USER_MENU_BANKING_CLICKED"] = "user_menu_banking_clicked";
    EVENT["USER_MENU_DOCUMENTS_CLICKED"] = "user_menu_documents_clicked";
    EVENT["USER_MENU_PAYMENTS_CLICKED"] = "user_menu_payments_clicked";
    EVENT["USER_MENU_PERSONAL_INFO_CLICKED"] = "user_menu_personal_info_clicked";
    EVENT["USER_MENU_SECURITY_CLICKED"] = "user_menu_security_clicked";
    EVENT["USER_MENU_SUPPORT_CLICKED"] = "user_menu_support_clicked";
    EVENT["USER_MENU_SIGN_OUT_CLICKED"] = "user_menu_sign_out_clicked";
    // Bank Linking
    EVENT["LINK_BANK_ACCOUNT_VIEWED"] = "link_bank_account_viewed";
    EVENT["LINK_BANK_ACCOUNT_LINK_ACCOUNT_CLICKED"] = "link_bank_account_link_account_clicked";
    EVENT["LINK_BANK_ACCOUNT_LINK_PLAID_ACCOUNT_CLICKED"] = "link_bank_account_link_plaid_account_clicked";
    EVENT["MANAGE_BANK_ACCOUNT_SPEND_CARD_VIEWED"] = "manage_bank_account_spend_card_viewed";
    EVENT["ENTER_BANK_INFO_VIEWED"] = "enter_bank_info_viewed";
    EVENT["ENTER_BANK_INFO_FILLED_OUT"] = "enter_bank_info_filled_out";
    EVENT["ENTER_BANK_INFO_CONTINUE_CLICKED"] = "enter_bank_info_continue_clicked";
    EVENT["ENTER_BANK_INFO_UNABLE_TO_VERIFY_VIEWED"] = "enter_bank_info_unable_to_verify_viewed";
    EVENT["CONFIRM_BANK_INFO_VIEWED"] = "confirm_bank_info_viewed";
    EVENT["CONFIRM_BANK_INFO_FILLED_OUT"] = "confirm_bank_info_filled_out";
    EVENT["CONFIRM_BANK_INFO_CONTINUE_CLICKED"] = "confirm_bank_info_continue_clicked";
    EVENT["ENTER_BANK_INFO_NO_MATCH_VIEWED"] = "enter_bank_info_no_match_viewed";
    EVENT["BANK_ACCOUNT_LINKED_VIEWED"] = "bank_account_linked_viewed";
    EVENT["BANK_ACCOUNT_LINKED_USE_DIFFERENT_ACCOUNT_CLICKED"] = "bank_account_linked_use_different_account_clicked";
    EVENT["BANK_ACCOUNT_LINKED_DELETE_ACCOUNT_CLICKED"] = "bank_account_delete_account_clicked";
    EVENT["BANK_ACCOUNT_IN_REVIEW_VIEWED"] = "bank_account_in_review_viewed";
    EVENT["BANK_ACCOUNT_REPAIR_VIEWED"] = "bank_account_repair_viewed";
    EVENT["BANK_ACCOUNT_FIX_ACCOUNT_CLICKED"] = "bank_account_fix_account_clicked";
    EVENT["HOME_CONNECT_BANK_ACCOUNT_CLICKED"] = "home_connect_bank_account_clicked";
    EVENT["PAYMENTS_CONNECT_BANK_ACCOUNT_CLICKED"] = "payments_connect_bank_account_clicked";
    // One Time Payment
    EVENT["ONE_TIME_PAYMENT_VIEWED"] = "one_time_payment_viewed";
    EVENT["ONE_TIME_PAYMENT_PAY_LATER_CLICKED"] = "one_time_payment_pay_later_clicked";
    EVENT["ONE_TIME_PAYMENT_PAY_NOW_CLICKED"] = "one_time_payment_pay_now_clicked";
    EVENT["ONE_TIME_PAYMENT_CONFIRMATION_VIEWED"] = "one_time_payment_confirmation_viewed";
    EVENT["ONE_TIME_PAYMENT_CHANGE_AMOUNT_CLICKED"] = "one_time_payment_change_amount_clicked";
    EVENT["ONE_TIME_PAYMENT_CONFIRM_CLICKED"] = "one_time_payment_confirm_clicked";
    EVENT["ONE_TIME_PAYMENT_LINK_CLICKED"] = "one_time_payment_link_clicked";
    EVENT["ONE_TIME_PAYMENT_SUCCESS_VIEWED"] = "one_time_payment_success_viewed";
    EVENT["NO_PAYMENT_DUE_VIEWED"] = "no_payment_due_viewed";
    EVENT["NO_PAYMENT_DUE_BACK_TO_HOME_CLICKED"] = "no_payment_due_back_to_home_clicked";
    EVENT["PAYMENT_DATE_SELECTION_CLICKED"] = "payment_date_selection_clicked";
    EVENT["PAYMENT_DEFINITION_VIEWED"] = "payment_definition_viewed";
    EVENT["DASHBOARD_ONE_TIME_PAYMENT_CLICKED"] = "dashboard_one_time_payment_clicked";
    // Scheduled Payment
    EVENT["MODIFY_SCHEDULED_PAYMENT_CLICKED"] = "modify_scheduled_payment_clicked";
    EVENT["SCHEDULE_A_PAYMENT_CLICKED"] = "schedule_a_payment_clicked";
    // Scheduled Payment Modification
    EVENT["SCHEDULED_PAYMENT_EDIT_EDIT_PAYMENT_CLICKED"] = "scheduled_payment_edit_edit_payment_clicked";
    EVENT["SCHEDULED_PAYMENT_EDIT_CANCEL_PAYMENT_CLICKED"] = "scheduled_payment_edit_cancel_payment_clicked";
    EVENT["SCHEDULED_PAYMENT_EDIT_CONFIRM_CLICKED"] = "scheduled_payment_edit_confirm_clicked";
    EVENT["SCHEDULED_PAYMENT_EDIT_PAY_NOW_CLICKED"] = "scheduled_payment_edit_pay_now_clicked";
    EVENT["SCHEDULED_PAYMENT_EDIT_PAY_LATER_CLICKED"] = "scheduled_payment_edit_pay_later_clicked";
    EVENT["SCHEDULED_PAYMENT_EDIT_DATE_SELECTION_CLICKED"] = "scheduled_payment_edit_payment_date_selection_clicked";
    EVENT["PAYMENT_UPDATE_SUCCESS_VIEWED"] = "payment_update_success_viewed";
    EVENT["PAYMENT_CANCEL_SUCCESS_VIEWED"] = "payment_cancel_success_viewed";
    // Success
    EVENT["SUCCESS_BACK_TO_HOME_CLICKED"] = "success_back_to_home_clicked";
    EVENT["SUCCESS_BACK_TO_PERSONAL_INFO_CLICKED"] = "success_back_to_personal_info_clicked";
    // Auto-Pay
    EVENT["SETUP_AUTOPAY_CLICKED"] = "setup_autopay_clicked";
    EVENT["AUTOPAY_EDIT_CLICKED"] = "autopay_edit_clicked";
    EVENT["AUTOPAY_CANCEL_AUTOPAY_CLICKED"] = "autopay_edit_cancel_autopay_clicked";
    EVENT["AUTOPAY_OVERVIEW_VIEWED"] = "autopay_overview_viewed";
    EVENT["AUTOPAY_AMOUNT_VIEWED"] = "autopay_amount_viewed";
    EVENT["AUTOPAY_DATE_VIEWED"] = "autopay_date_viewed";
    EVENT["AUTOPAY_CONFIRMATION_VIEWED"] = "autopay_confirmation_viewed";
    EVENT["AUTOPAY_SETUP_SUCCESS_VIEWED"] = "autopay_setup_success_viewed";
    EVENT["PAYMENT_DEFINITION_AUTO_PAY_VIEWED"] = "payment_definition_auto_pay_viewed";
    EVENT["DASHBOARD_AUTOPAY_CLICKED"] = "dashboard_autopay_clicked";
    EVENT["DASHBOARD_AUTOPAY_EDIT_CLICKED"] = "dashboard_autopay_edit_clicked";
    // Documents
    EVENT["DOCUMENTS_VIEWED"] = "documents_viewed";
    EVENT["DOCUMENTS_DOCUMENT_CLICKED"] = "documents_document_clicked";
    EVENT["DOCUMENTS_PAPERLESS_CLICKED"] = "documents_paperless_clicked";
    EVENT["CONSUMER_DOCUMENTS_VIEWED"] = "consumer_documents_viewed";
    EVENT["CONSUMER_DOCUMENT_CLICKED"] = "consumer_document_clicked";
    // Timeout
    EVENT["SESSION_EXPIRED_WARNING_VIEWED"] = "session_expired_warning_viewed";
    EVENT["SESSION_EXPIRED_VIEWED"] = "session_expired_viewed";
    EVENT["ISSUE_WITH_APPLICATION_VIEWED"] = "issue_with_application_viewed";
    EVENT["ISSUE_WITH_APPLICATION_GO_BACK_CLICKED"] = "issue_with_application_go_back_clicked";
    EVENT["ERROR_VIEWED"] = "error_viewed";
    EVENT["OTP_FAILED"] = "otp_failed";
    EVENT["LOGIN_FAILED_VIEWED"] = "login_failed_viewed";
    // EDU
    EVENT["FIRST_LOGIN_WEB_EDUCATION_PAGE_VIEWED"] = "first_login_web_education_page_viewed";
    EVENT["GET_THE_APP_CLICKED"] = "get_the_app_clicked";
    EVENT["CONTINUE_ON_WEB_CLICKED"] = "continue_on_web_clicked";
    EVENT["FIND_OUT_MORE_BANNER_CLICKED"] = "find_out_more_banner_clicked";
    EVENT["FIND_OUT_MORE_BANNER_DISMISSED"] = "find_out_more_banner_dismissed";
    EVENT["MORE_APP_INFO_PAGE_VIEWED"] = "more_app_info_page_viewed";
    EVENT["CONNECT_A_BANK_ACCOUNT_TOOLTIP_VIEWED"] = "connect_a_bank_account_tooltip_viewed";
    // Offer Page
    EVENT["OFFER_VIEWED"] = "offer_viewed";
    EVENT["OFFER_CONTINUE_CLICKED"] = "offer_continue_clicked";
    EVENT["OFFER_NO_THANKS_CLICKED"] = "offer_no_thanks_clicked";
    EVENT["OFFER_LINK_CLICKED"] = "offer_link_clicked";
    EVENT["OFFER_INFO_EDU_VIEWED"] = "offer_info_edu_viewed";
    // Offer App
    EVENT["OFFER_APP_DOWNLOAD_VIEWED"] = "offer_app_download_viewed";
    EVENT["OFFER_APP_DOWNLOAD_CONTACT_CLICKED"] = "offer_app_download_contact_clicked";
    // Card Design Selection
    EVENT["CARD_ART_SELECTION_PAGE_VIEWED"] = "card_art_selection_page_viewed";
    EVENT["CARD_ART_SELECTION_CHANGED"] = "card_art_selection_changed";
    EVENT["CARD_ART_SELECTION_CONFIRMED"] = "card_art_selection_confirmed";
    // Accepting Offer
    EVENT["OFFER_PAGE_ACCEPT_OFFER_CLICKED"] = "offer_page_accept_offer_clicked";
    EVENT["OFFER_PAGE_CONTINUE_CLICKED"] = "offer_page_continue_clicked";
    EVENT["CONFIRM_ADDRESS_VIEWED"] = "confirm_address_viewed";
    EVENT["CONFIRM_ADDRESS_LATER_VIEWED"] = "confirm_address_later_viewed";
    EVENT["CONFIRM_ADDRESS_CONFIRM_CLICKED"] = "confirm_address_confirm_clicked";
    EVENT["CONFIRM_ADDRESS_NOT_NOW_CLICKED"] = "confirm_address_not_now_clicked";
    EVENT["CONFIRM_ADDRESS_LATER_SOUNDS_GOOD_CLICKED"] = "confirm_address_later_sounds_good_clicked";
    // Card Menu
    EVENT["CARD_MENU_CLICKED"] = "card_menu_clicked";
    EVENT["CARD_MENU_VIEWED"] = "card_menu_viewed";
    EVENT["CARD_MENU_GO_BACK_CLICKED"] = "card_menu_go_back_clicked";
    EVENT["ACTIVATE_PHYSICAL_CARD_CLICKED"] = "activate_physical_card_clicked";
    EVENT["ACTIVATE_PHYSICAL_CARD_LAST4_VIEWED"] = "activate_physical_card_last4_viewed";
    EVENT["ACTIVATE_PHYSICAL_CARD_SUCCESS_VIEWED"] = "activate_physical_card_success_viewed";
    EVENT["ACTIVATE_BUTTON_CLICKED"] = "activate_button_clicked";
    EVENT["CARD_ACTIVATION_FAILED"] = "card_activation_failed";
    EVENT["CARD_ACTIVATION_SUCCESS_DONE_CLICKED"] = "card_activation_success_done_clicked";
    EVENT["CARD_DETAILS_PIN_FAILED_VIEWED"] = "card_details_pin_failed_viewed";
    EVENT["GET_NEW_CARD_NUMBER_CLICKED"] = "get_new_card_number_clicked";
    EVENT["GET_NEW_CARD_NUMBER_VIEWED"] = "get_new_card_number_viewed";
    EVENT["GET_NEW_CARD_NUMBER_CANCEL_CLICKED"] = "get_new_card_number_cancel_clicked";
    EVENT["GET_NEW_CARD_NUMBER_CONFIRM_CLICKED"] = "get_new_card_number_confirm_clicked";
    EVENT["GET_PHYSICAL_CARD_VIEWED"] = "get_physical_card_viewed";
    EVENT["REPLACE_CARD_VIEWED"] = "replace_card_viewed";
    EVENT["REPLACE_CARD_CONFIRM_CLICKED"] = "replace_card_confirm_clicked";
    EVENT["REPLACE_CARD_OPTION_MISSING_CLICKED"] = "replace_card_option_missing_clicked";
    EVENT["REPLACE_CARD_OPTION_STOLEN_CLICKED"] = "replace_card_option_stolen_clicked";
    EVENT["REPLACE_CARD_OPTION_DAMAGED_CLICKED"] = "replace_card_option_damaged_clicked";
    EVENT["REPLACE_CARD_CHANGE_ADDRESS_CLICKED"] = "replace_card_change_address_clicked";
    EVENT["REQUEST_CARD_ERROR_VIEWED"] = "request_card_error_viewed";
    EVENT["REQUEST_CARD_ERROR_SUPPORT_EMAIL"] = "request_card_error_support_email";
    // Card details
    EVENT["CARD_DETAILS_SHOW_CARD_NUMBER_CLICKED"] = "card_details_show_card_number_clicked";
    EVENT["CARD_DETAILS_HIDE_CARD_NUMBER_CLICKED"] = "card_details_hide_card_number_clicked";
    EVENT["CARD_DETAILS_PIN_ENTRY_VIEWED"] = "card_details_pin_entry_viewed";
    EVENT["CARD_DETAILS_PIN_ENTRY_SUBMITTED"] = "card_details_pin_entry_submitted";
    EVENT["CARD_DETAILS_PIN_ENTRY_GO_BACK_CLICKED"] = "card_details_pin_entry_go_back_clicked";
    EVENT["CARD_DETAILS_CARD_NUMBER_VIEWED"] = "card_details_card_number_viewed";
    EVENT["CARD_DETAILS_TIMEOUT_TRIGGERED"] = "card_details_timeout_triggered";
    EVENT["CARD_DETAILS_COPY_CLICKED"] = "card_details_copy_clicked";
    // LOCALIZATION
    EVENT["LANGUAGE_MENU_TAB_CLICKED"] = "language_menu_tab_clicked";
    EVENT["LANGUAGE_SELECTION_CLICKED"] = "language_selection_clicked";
    EVENT["LANGUAGE_CONFIRMATION_MODAL_VIEWED"] = "language_confirmation_modal_viewed";
    EVENT["LANGUAGE_CONTINUE_CLICKED"] = "language_continue_clicked";
    EVENT["LANGUAGE_CANCEL_CLICKED"] = "language_cancel_clicked";
    EVENT["LANGUAGE_SELECTION_PAGE_VIEWED"] = "language_selection_page_viewed";
    // Statement Setting
    EVENT["STATEMENT_SETTINGS_PAPAERLESS_CLICKED"] = "statement_settings_papaerless_clicked";
    EVENT["STATEMENT_SETTINGS_PAPER_CLICKED"] = "statement_settings_paper_clicked";
    EVENT["STATEMENT_SETTINGS_SUCCESS_BACK_TO_HOME_CLICKED"] = "statement_settings_success_back_to_home_clicked";
    // Multi-card
    EVENT["MULTI_CARD_MENU_VIEWED"] = "multi_card_menu_viewed";
    EVENT["MULTI_CARD_MENU_CARD_SELECTED"] = "multi_card_menu_card_selected";
    EVENT["MULTI_CARD_MENU_BAD_CARD_VIEWED"] = "multi_card_menu_bad_card_viewed";
    // Card Balance
    EVENT["CARD_BALANCE_CLICKED"] = "card_balance_clicked";
    EVENT["CARD_BALANCE_VIEWED"] = "card_balance_viewed";
    EVENT["CARD_BALANCE_PAY_BUTTON_CLICKED"] = "card_balance_pay_button_clicked";
    EVENT["CARD_BALANCE_STATEMENT_ITEM_CLICKED"] = "card_balance_statement_item_clicked";
    EVENT["MINIMUM_AUTOPAY_MODAL_VIEWED"] = "minimum_autopay_modal_viewed";
    EVENT["MINIMUM_AUTOPAY_MODAL_PAY_CLICKED"] = "minimum_autopay_modal_pay_clicked";
    EVENT["ADJUSTED_AUTOPAY_MODAL_VIEWED"] = "adjusted_autopay_modal_viewed";
    // Personal Info
    EVENT["PERSONAL_INFO_NAME_CHANGE_EMAIL_CLICKED"] = "personal_info_name_change_email_clicked";
    EVENT["PERSONAL_INFO_ADDRESS_EDIT"] = "personal_info_address_edit";
    EVENT["PERSONAL_INFO_INCOME_HOUSING_EDIT"] = "personal_info_income_housing_edit";
    EVENT["PERSONAL_INFO_INCOME_HOUSING_MODAL_VIEWED"] = "personal_info_income_housing_modal_viewed";
    EVENT["PERSONAL_INFO_PIN_ENTRY_SUBMITTED"] = "personal_info_pin_entry_submitted";
    EVENT["PERSONAL_INFO_PIN_SUCCESS"] = "personal_info_pin_success";
    EVENT["PERSONAL_INFO_PIN_CANCEL"] = "personal_info_pin_cancel";
    EVENT["PERSONAL_INFO_EMAIL_OTP_SUBMITTED"] = "personal_info_email_otp_submitted";
    EVENT["PERSONAL_INFO_EMAIL_OTP_SUCCESS"] = "personal_info_email_otp_success";
    EVENT["PERSONAL_INFO_EMAIL_EDIT_CONTINUE_CLICKED"] = "personal_info_email_edit_continue_clicked";
    EVENT["PERSONAL_INFO_PHONE_EDIT_CONTINUE_CLICKED"] = "personal_info_phone_edit_continue_clicked";
    EVENT["EDIT_PHONE_OTP_VIEWED"] = "edit_phone_otp_viewed";
    EVENT["EDIT_PHONE_OTP_SUBMITTED"] = "edit_phone_otp_submitted";
    EVENT["EDIT_PHONE_OTP_TRY_AGAIN_VIEWED"] = "edit_phone_otp_try_again_viewed";
    EVENT["EDIT_PHONE_OTP_RESEND_CLICKED"] = "edit_phone_otp_resend_clicked";
    EVENT["EDIT_PHONE_OTP_CALL_CLICKED"] = "edit_phone_otp_call_clicked";
    EVENT["EDIT_PHONE_OTP_CALL_AGAIN_CLICKED"] = "edit_phone_otp_call_again_clicked";
    EVENT["PERSONAL_INFO_PHONE_OTP_SUCCESS"] = "personal_info_phone_otp_success";
    // Security
    EVENT["SECURITY_VIEWED"] = "security_viewed";
    EVENT["SECURITY_RESET_PIN_CLICKED"] = "security_reset_pin_clicked";
    EVENT["SECURITY_PIN_CANCEL"] = "security_pin_cancel";
    EVENT["SECURITY_PIN_ENTRY_VERIFIED"] = "security_pin_entry_verified";
    EVENT["SECURITY_PIN_NEW_ENTRY_CREATED"] = "security_pin_new_entry_created";
    EVENT["SECURITY_PIN_NEW_ENTRY_CONFIRMED"] = "security_pin_new_entry_confirmed";
    // Transaction Feed
    EVENT["CLICKED"] = "clicked";
    EVENT["ACTIVITY_DETAILS"] = "activity_details";
    EVENT["ACTIVITY_DETAIL_VIEWED"] = "activity_detail_viewed";
    EVENT["ACTIVITY_FILTER_CLICKED"] = "activity_filter_clicked";
    EVENT["ACTIVITY_FILTER_PAYMENTS_CLICKED"] = "activity_filter_payments_clicked";
    EVENT["ACTIVITY_FILTER_PURCHASES_CLICKED"] = "activity_filter_purchases_clicked";
    EVENT["ACTIVITY_FILTER_REDEMPTIONS_CLICKED"] = "activity_filter_redemptions_clicked";
    EVENT["ACTIVITY_FILTER_OFFERS_CLICKED"] = "activity_filter_offers_clicked";
    EVENT["ACTIVITY_FILTER_REFUNDS_CLICKED"] = "activity_filter_refunds_clicked";
    EVENT["ACTIVITY_FILTER_DISPUTES_CLICKED"] = "activity_filter_disputes_clicked";
    EVENT["ACTIVITY_FILTER_ALL_CLICKED"] = "activity_filter_all_clicked";
    EVENT["DOWNLOAD_CSV_CLICKED"] = "download_csv_clicked";
    // Authorized User
    EVENT["AUTH_USER_APPLICATION_STARTED"] = "auth_user_application_started";
    EVENT["AUTH_USER_APPLICATION_SUBMITTED"] = "auth_user_application_submitted";
    EVENT["AUTH_USER_DECISION_REJECTED"] = "auth_user_decision_rejected";
    EVENT["AUTH_USER_ACTIVATE_CARD_STARTED"] = "auth_user_activate_card_started";
    EVENT["AUTH_USER_ACTIVATE_CARD_COMPLETED"] = "auth_user_activate_card_completed";
    EVENT["AUTH_USER_CARD_REPLACEMENT_REQUESTED"] = "auth_user_card_replacement_requested";
    EVENT["AUTH_USER_CARD_LOCKED"] = "auth_user_card_locked";
    EVENT["AUTH_USER_CARD_UNLOCKED"] = "auth_user_card_unlocked";
    EVENT["AUTH_USER_CARD_DEACTIVATED"] = "auth_user_card_deactivated";
    EVENT["AUTH_USER_ADDRESS_UPDATED"] = "auth_user_address_updated";
    EVENT["AUTH_USER_MANAGE_USERS_CLICKED"] = "auth_user_manage_users_clicked";
    EVENT["AUTH_USER_DISCARD_APP_CLICKED"] = "auth_user_discard_app_clicked";
    EVENT["AUTH_USER_CONTINUE_APP_CLICKED"] = "auth_user_continue_app_clicked";
    EVENT["AUTH_USER_INCOMPLETE_VIEWED"] = "auth_user_incomplete_viewed";
    EVENT["AUTH_USER_PENDING_VIEWED"] = "auth_user_pending_viewed";
    EVENT["AUTH_USER_CARD_NOT_AVAILABLE_NOTE_VIEWED"] = "auth_user_card_not_available_note_viewed";
    // Returning User
    EVENT["SERVICING_DECISION_DENIED_VIEWED"] = "servicing_decision_denied_viewed";
    EVENT["SERVICING_DECISION_EXPIRED_VIEWED"] = "servicing_decision_expired_viewed";
    EVENT["SERVICING_DECISION_IN_REVIEW_VIEWED"] = "servicing_decision_in_review_viewed";
    EVENT["SERVICING_DECISION_FROZEN_VIEWED"] = "servicing_decision_frozen_viewed";
    EVENT["SERVICING_DECISION_ELIGIBLE_VIEWED"] = "servicing_decision_eligible_viewed";
    EVENT["SERVICING_DECISION_REAPPLY_VIEWED"] = "servicing_decision_reapply_viewed";
    EVENT["SERVICING_DECISION_PREAPPROVED_VIEWED"] = "servicing_decision_preapproved_viewed";
    EVENT["SERVICING_DECISION_DENIED_ACTION_CLICKED"] = "servicing_decision_denied_action_clicked";
    EVENT["SERVICING_DECISION_FROZEN_ACTION_CLICKED"] = "servicing_decision_frozen_action_clicked";
    EVENT["SERVICING_DECISION_ELIGIBLE_ACTION_CLICKED"] = "servicing_decision_eligible_action_clicked";
    EVENT["SERVICING_DECISION_REAPPLY_ACTION_CLICKED"] = "servicing_decision_reapply_action_clicked";
    EVENT["SERVICING_DECISION_EXPIRED_OFFER_ACTION_CLICKED"] = "servicing_decision_expired_offer_action_clicked";
    EVENT["WALLET_CARD_CLICKED"] = "wallet_card_clicked";
    EVENT["REMEMBER_ME_CHECKED"] = "remember_me_checked";
    EVENT["REMEMBER_ME_UNCHECKED"] = "remember_me_unchecked";
    // Rewards
    EVENT["REWARDS_CONFIGURE_VIEWED"] = "rewards_configure_viewed";
    EVENT["REWARDS_CONFIRM_VIEWED"] = "rewards_confirm_viewed";
    EVENT["REWARDS_CONFIRM_ACTION_BUTTON_CLICKED"] = "rewards_confirm_action_button_clicked";
    EVENT["REWARDS_CONFIRM_SECONDARY_BUTTON_CLICKED"] = "rewards_confirm_secondary_button_clicked";
    EVENT["HOME_REDEEM_CLICKED"] = "home_redeem_clicked";
    EVENT["REWARDS_DISABLED_BUTTON_CLICKED"] = "rewards_disabled_button_clicked";
    // Partner Account Linking
    EVENT["MERCHANT_NUMBER_LINK_ACCOUNT_VIEWED"] = "linked_rewards_account_viewed";
    EVENT["MERCHANT_NUMBER_UPDATE_CLICKED"] = "update_linked_rewards_account_clicked";
    EVENT["MERCHANT_NUMBER_VIEW_ACCOUNT_CLICKED"] = "view_linked_rewards_account_clicked";
    EVENT["MERCHANT_NUMBER_CLICKED"] = "link_rewards_account_filled_out";
    EVENT["MERCHANT_NUMBER_SIGN_UP_CLICKED"] = "link_rewards_account_sign_up_clicked";
    EVENT["MERCHANT_NUMBER_FIND_ACCOUNT_CLICKED"] = "link_rewards_account_find_account_clicked";
    EVENT["MERCHANT_NUMBER_TOOLTIP_CLICKED"] = "link_rewards_account_tooltip_clicked";
    EVENT["MERCHANT_NUMBER_CONFIRMED_VIEWED"] = "confirm_rewards_account_viewed";
    EVENT["MERCHANT_NUMBER_LINK_ACCOUNT_CLICKED"] = "confirm_rewards_account_yes_clicked";
    EVENT["MERCHANT_NUMBER_DO_NOT_LINK_CLICKED"] = "confirm_rewards_account_no_clicked";
    EVENT["MERCHANT_NUMBER_INVALID_VIEWED"] = "rewards_account_invalid_viewed";
    EVENT["MERCHANT_NUMBER_CLOSED_VIEWED"] = "rewards_account_closed_viewed";
    EVENT["MERCHANT_NUMBER_ISSUES_VIEWED"] = "rewards_account_issues_viewed";
    EVENT["MERCHANT_CONTACT_SUPPORT_CLICKED"] = "rewards_account_support_clicked";
    EVENT["PARTNER_ACCT_RETRY_VIEWED"] = "rewards_account_searching_viewed";
    EVENT["SUBMIT_FEEDBACK_CLICKED"] = "submit_feedback_clicked";
    EVENT["HOME_SET_UP_PAYMENTS_WIDGET_CLICKED"] = "home_set_up_payments_widget_clicked";
    // CardLift
    EVENT["CARDLIFT_DOWNLOAD_BANNER_CLICKED"] = "cardlift_download_banner_clicked";
    EVENT["CARDLIFT_DOWNLOAD_DETAIL_CLICKED"] = "cardlift_download_detail_clicked";
    EVENT["CARDLIFT_DOWNLOAD_MODAL_CLICKED"] = "cardlift_download_modal_clicked";
    EVENT["CARDLIFT_DOWNLOAD_MODAL_DISMISSED"] = "cardlift_download_modal_dismissed";
    // in store
    EVENT["useTapToPayViewed"] = "instore_use_tap_to_pay_viewed";
    EVENT["useTapToPayClosed"] = "instore_use_tap_to_pay_closed";
    EVENT["downloadAppClicked"] = "instore_download_app_clicked";
    EVENT["viewVCNClicked"] = "instore_view_virtual_card_clicked";
})(EVENT || (EVENT = {}));
