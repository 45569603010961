var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { fetchAndActivate, getRemoteConfig, getString } from 'firebase/remote-config';
import AnalyticsWrapper from '../analytics/analyticsWrapper';
import { ENV, setApiKey } from '../app/apiScreenSlice';
import { setFeatureFlags } from '../app/featureFlagSlice';
import { store } from '../app/store';
import { isLocal } from '../utils/environments';
import { featureFlags } from './featureFlags';
var P_VAL = 'passcode';
var FIREBASE_CONFIG = {
    apiKey: process.env.FIREBASE_KEY_PRD,
    authDomain: 'application-flow-a12d8.firebaseapp.com',
    projectId: 'application-flow-a12d8',
    storageBucket: 'application-flow-a12d8.appspot.com',
    messagingSenderId: '747835896747',
    appId: '1:747835896747:web:30ced306dbbeb8aa1b58ee',
    measurementId: 'G-QFDXQK7FY9',
    mapKey: process.env.FIREBASE_KEY_PRD,
};
var FIREBASE_CONFIG_STG = {
    apiKey: process.env.FIREBASE_KEY_STG,
    authDomain: 'application-flow-stg.firebaseapp.com',
    projectId: 'application-flow-stg',
    storageBucket: 'application-flow-stg.appspot.com',
    messagingSenderId: '664902079568',
    appId: '1:664902079568:web:edd0ebbfe860c5acf32d55',
    measurementId: 'G-89EJLCEYC5',
    mapKey: process.env.FIREBASE_KEY_STG_MAP,
};
// Initialize Firebase
var domain = window.location.hostname.toLowerCase();
var domainParse = domain.split('.');
// PREPROD uses the same config as PROD (with the goal of reflecting the same behavior)
export var config = FIREBASE_CONFIG;
if (domainParse.includes(ENV.STG) ||
    domainParse.includes(ENV.LOCAL) ||
    // domainParse.includes(ENV.INTERNAL_PROD) ||
    isLocal(domain)) {
    config = FIREBASE_CONFIG_STG;
}
var app = initializeApp(config);
export var auth = getAuth(app);
export var ANALYTICS = new AnalyticsWrapper(app);
// Initialize Remote Config and get a reference to the service
var remoteConfig = getRemoteConfig(app);
fetchAndActivate(remoteConfig);
remoteConfig.settings.minimumFetchIntervalMillis = 3600000;
export function initFeatureFlags() {
    var featureFlagObj = {};
    fetchAndActivate(remoteConfig).then(function () {
        featureFlags.map(function (val) {
            featureFlagObj[val] = getString(remoteConfig, val);
        });
        store.dispatch(setFeatureFlags(featureFlagObj));
    });
}
export default function fetchFirebaseApiKey() {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, fetchAndActivate(remoteConfig)];
                case 1:
                    _a.sent();
                    store.dispatch(setApiKey(getString(remoteConfig, P_VAL)));
                    return [2 /*return*/];
            }
        });
    });
}
