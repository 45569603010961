import { colors } from '@imprint-payments/imprint-ui';
import { MaxWidth } from '@imprint-payments/imprint-ui';
export var applicationStatus = {
    noApplication: 'No application found',
    inReview: 'Application still in review',
    notApproved: 'Application not approved',
    haveOffer: 'Offer received',
    alreadySignedUp: 'Already Signed Up',
    creditFrozen: 'Your credit is frozen',
};
export var PLATFORM;
(function (PLATFORM) {
    PLATFORM["mobile"] = "mobile";
    PLATFORM["tablet"] = "tablet";
    PLATFORM["computer"] = "computer";
})(PLATFORM || (PLATFORM = {}));
export var subscribeType = {
    UNSUPPORTED_STATE: 'unsupportedState',
    NO_SMARTPHONE: 'noSmartphone',
};
export var defaultBulletColor = '#1E191A';
export var autoPayMaxDatePerMonth = 28;
export var autoPayOnDueDateNumber = 100;
export var autoPayValidMinDate = 1;
export var autoPayValidMaxDate = 31;
// 8 digit will restrict the input to be under $9999.99
export var paymentMaxInputLength = 8;
// How to Pay by check links for the merchants.
export var payByCheckLinkDict = {
    'Holiday Inn Club Vacations®/en-US': 'https://hicvcreditcard.zendesk.com/hc/en-us/articles/15580744630164-How-do-I-make-a-payment-on-my-Holiday-Inn-Club-VacationsTM-World-Mastercard-',
    'Holiday Inn Club Vacations®/es': 'https://hicvcreditcard.zendesk.com/hc/es/articles/15580744630164--C%C3%B3mo-realize-un-pago-con-mi-Holiday-Inn-Club-VacationsTM-World-Mastercard--How-do-I-make-a-payment-on-my-Holiday-Inn-Club-VacationsTM-World-Mastercard---C%C3%B3mo-realize-un-pago-con-mi-Holiday-Inn-Club-VacationsTM-World-Mastercard--How-do-I-make-a-payment-on-my-Holiday-Inn-Club-VacationsTM-World-Mastercard-',
    'Horizon Hobby/en-US': 'https://horizonhobbysupport.zendesk.com/hc/en-us/articles/8808947522324-How-do-I-make-a-payment-to-my-Horizon-Hobby-card-',
    'Horizon Hobby/es': 'https://horizonhobbysupport.zendesk.com/hc/en-us/articles/8808947522324-How-do-I-make-a-payment-to-my-Horizon-Hobby-card-',
    'H-E-B/en-US': 'https://hebcreditcard.zendesk.com/hc/en-us/articles/9152100632980-How-do-I-make-a-payment-to-my-H-E-B-Visa-Signature-Credit-Card-',
    'H-E-B/es': 'https://hebcreditcard.zendesk.com/hc/es/articles/9152100632980--C%C3%B3mo-realizo-un-pago-a-mi-tarjeta-de-cr%C3%A9dito-H-E-B-Visa-Signature-Credit-Card-',
    'Central Market/en-US': 'https://centralmarketcreditcard.zendesk.com/hc/en-us/articles/13051975143316-How-do-I-make-a-payment-to-my-Central-Market-Visa-Signature-Credit-Card-',
    'Central Market/es': 'https://centralmarketcreditcard.zendesk.com/hc/es/articles/13051975143316--C%C3%B3mo-realizo-un-pago-a-mi-tarjeta-de-cr%C3%A9dito-Central-Market-Visa-C%C3%B3mo-realizo-un-pago-a-mi-tarjeta-de-cr%C3%A9dito-Central-Market-Visa-Signature-Credit-Card-',
    'Westgate Resorts/en-US': 'https://westgatesupport.zendesk.com/hc/en-us/articles/9127123553684-How-do-I-make-a-payment-to-my-World-of-Westgate-Mastercard-',
    'Westgate Resorts/es': 'https://westgatesupport.zendesk.com/hc/en-us/articles/9127123553684-How-do-I-make-a-payment-to-my-World-of-Westgate-Mastercard-',
    'WeWoreWhat/en-US': 'https://imprintpayments.zendesk.com/hc/en-us/articles/4405689399572-How-do-I-make-a-payment-',
    'WeWoreWhat/es': 'https://imprintpayments.zendesk.com/hc/en-us/articles/4405689399572-How-do-I-make-a-payment-',
    'default/en-US': 'https://imprintpayments.zendesk.com/hc/en-us/articles/4405689399572-How-do-I-make-a-payment-',
    'default/es': 'https://imprintpayments.zendesk.com/hc/en-us/articles/4405689399572-How-do-I-make-a-payment-',
};
export var pendingTransactionColorInBalanceRing = colors.surface.surfaceDisabled;
export var checkAutocompleteDelayMs = 1000;
export var requestAssetUrlStaging = 'https://app.stg.imprint.co';
export var requestAssetUrlProd = 'https://app.imprint.co';
// TODO: document in design system
export var FORM_INPUT_WIDTH = "min(".concat(MaxWidth.Input, ", 100vw - 48px)");
export var FORM_BUTTON_WIDTH = "min(".concat(MaxWidth.Button, ", 100vw - 48px)");
export var TEXT_WIDTH = "min(".concat(MaxWidth.Content, ", 100vw - 48px)");
export var CONTAINER_WIDTH = "min(".concat(MaxWidth.Container, ", 100vw)");
export var HTTPS = 'https://';
export var PHONE_NUMBER_MAX_LENGTH = 16;
export var appDownloadDisplayURL = 'app.imprint.co/download';
export var appDownloadURL = 'https://app.imprint.co/download';
export var transUnionLoginURL = 'https://service.transunion.com/dss/login.page';
export var transUnionArticleURL = 'https://www.transunion.com/blog/credit-advice/what-should-i-do-after-i-freeze-my-credit';
export var supportEmail = 'support@imprint.co';
export var imprintSupportPhone = '8884103664';
export var brooksBrothersMicrosite = 'https://www.brooksbrotherscreditcard.com/upgrade';
export var stripeMigrationErrorCode = '340';
// in store consts
// the app should show UseCardToday screen after login
export var PARAM_SHOW_WPP = 'showTapToPay';
// the app should direct user straight to virtual card screen after login
export var PARAM_IN_STORE = 'in_store';
