var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ImpBreadcrumbs, ImpContainer, ImpDivider, ImpFlex, ImpPad, ImpSpacer, ImpText, colors, } from '@imprint-payments/imprint-ui';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { EVENT } from '../../analytics/analyticsConsts';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import bankThumbnail from '../../assets/icons/bankThumbnail.svg';
import linkedBankThumbnail from '../../assets/icons/linkedBankThumbnail.svg';
import linkedBankThumbnailRed from '../../assets/icons/linkedBankThumbnailRed.svg';
import { ANALYTICS } from '../../firebase/firebase';
import { BANK_LINKING_STATUS } from '../../pages/banking/Banking';
import { navigateToAccountPage } from '../../utils/functionUtils';
import styles from './LinkBank.module.css';
import { LinkWithAcctRouting } from './LinkWithAcctRouting';
export default function LinkBank(_a) {
    var header = _a.header, bankLinkingStatus = _a.bankLinkingStatus, hasSpendCard = _a.hasSpendCard, startBankLinking = _a.startBankLinking, bottomContent = _a.bottomContent, setStartedLinking = _a.setStartedLinking, onSuccess = _a.onSuccess, onExitFlow = _a.onExitFlow;
    var language = useAppSelector(function (state) { return state.languageSelector.languageLocalization; });
    var navigate = useNavigate();
    var dispatch = useAppDispatch();
    var _b = useState(''), headerValue = _b[0], setHeaderValue = _b[1];
    var _c = useState(''), bodyValue = _c[0], setBodyValue = _c[1];
    var _d = useState(bankThumbnail), icon = _d[0], setIcon = _d[1];
    useEffect(function () {
        if (bankLinkingStatus === BANK_LINKING_STATUS.LOADING)
            return;
        switch (bankLinkingStatus) {
            case BANK_LINKING_STATUS.BANK_LINKED:
                ANALYTICS.logEvent(EVENT.BANK_ACCOUNT_LINKED_VIEWED);
                setHeaderValue(header);
                setBodyValue(hasSpendCard ? language.linkedBankNoticeWithSpend : language.linkedBankNotice);
                setIcon(linkedBankThumbnail);
                break;
            case BANK_LINKING_STATUS.IN_REVIEW:
                ANALYTICS.logEvent(EVENT.BANK_ACCOUNT_IN_REVIEW_VIEWED);
                setHeaderValue(language.accountReview);
                setBodyValue(hasSpendCard ? language.accountReviewNoticeWithSpend : language.accountReviewNotice);
                setIcon(bankThumbnail);
                break;
            case BANK_LINKING_STATUS.REPAIR:
                ANALYTICS.logEvent(EVENT.BANK_ACCOUNT_REPAIR_VIEWED);
                setHeaderValue(header);
                setBodyValue(hasSpendCard ? language.bankRepairNoticeWithSpend : language.bankRepairNotice);
                setIcon(linkedBankThumbnailRed);
                break;
            default:
                if (hasSpendCard) {
                    ANALYTICS.logEvent(EVENT.MANAGE_BANK_ACCOUNT_SPEND_CARD_VIEWED);
                    setHeaderValue(language.manageBank);
                    setBodyValue(language.manageBankNotice);
                    setIcon(bankThumbnail);
                }
                else {
                    ANALYTICS.logEvent(EVENT.LINK_BANK_ACCOUNT_VIEWED);
                    setHeaderValue(language.linkBankAccount);
                    setBodyValue(language.linkBankAccountNotice);
                    setIcon(bankThumbnail);
                }
        }
    }, [bankLinkingStatus]);
    var handleBackClick = function () {
        if (startBankLinking) {
            setStartedLinking && setStartedLinking(false);
        }
        else if (onExitFlow) {
            onExitFlow();
        }
        else {
            navigateToAccountPage(dispatch, navigate);
        }
    };
    return (_jsxs(_Fragment, { children: [!startBankLinking && (_jsxs(_Fragment, { children: [_jsx(ImpBreadcrumbs, { breadcrumbs: [language.breadcrumbBanking], onBackClick: handleBackClick, onCancelClick: function () { return navigateToAccountPage(dispatch, navigate); } }), _jsxs(ImpFlex, __assign({ fluid: true, direction: "column", align: "center" }, { children: [_jsx(ImpSpacer, { height: "2xl" }), _jsx(ImpContainer, __assign({ width: "min(748px, 100% - 32px)", backgroundColor: "white", borderRadius: "12px" }, { children: _jsxs(ImpFlex, __assign({ direction: "column", align: "center" }, { children: [_jsx("img", { src: icon, alt: "bank thumbnail", className: styles.bankImg, tabIndex: 0 }), _jsx(ImpDivider, { color: colors.border.borderVariant }), _jsx(ImpContainer, __assign({ width: "100%", borderRadius: "inherit", textAlign: "center" }, { children: _jsx(ImpPad, __assign({ padding: "24px" }, { children: _jsxs(ImpFlex, __assign({ fluid: true, direction: "column", align: "center", gap: "l" }, { children: [_jsx(ImpText, __assign({ typography: "headline1" }, { children: headerValue })), _jsx(ImpText, __assign({ typography: "body1", color: colors.content.onBackgroundVariant }, { children: bodyValue })), bottomContent && bottomContent] })) })) }))] })) }))] }))] })), startBankLinking && (_jsx(LinkWithAcctRouting, { startBankLinking: startBankLinking, setStartedLinking: setStartedLinking, onSuccess: onSuccess }))] }));
}
