var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { colors, Icons, ImpContainer, ImpFlex, ImpIcon, ImpList, ImpPad, ImpSurface, ImpText, } from '@imprint-payments/imprint-ui';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { EVENT } from '../../analytics/analyticsConsts';
import { selectPaymentsEnabled } from '../../app/featureFlagSelectors';
import { closeFlyout } from '../../app/flyoutSlice';
import { selectHome } from '../../app/homeSelectors';
import { setStartedBankLinking } from '../../app/homeSlice';
import { useAppSelector } from '../../app/hooks';
import { selectLanguage } from '../../app/languageSelectors';
import { selectSelectedProductUUID } from '../../app/productAccountSelectors';
import { SCREEN, setScreen } from '../../app/screenStateSlice';
import { ANALYTICS } from '../../firebase/firebase';
import { checkBankAccountBroken, clearServiceLoginState, generateSupportUrl, } from '../../utils/functionUtils';
import { PRODUCTUUID } from '../../utils/productUUIDConstants';
import { PAGE } from '../../utils/routeConstant';
export function UserMenu() {
    var profileButtons = useProfileButtons();
    return (_jsx(ImpContainer, __assign({ height: "100%", width: "100%", backgroundColor: colors.surface.surfaceHigh }, { children: _jsxs(ImpContainer, __assign({ position: "relative", width: "100%", height: "100%" }, { children: [_jsx(UserInfo, {}), _jsx(ImpContainer, __assign({ height: "calc(100% - 92px)" }, { children: _jsxs(ImpFlex, __assign({ direction: "column", justify: "space-between", fluid: true }, { children: [_jsx(ImpContainer, __assign({ width: "100%" }, { children: _jsx(ImpList, { items: profileButtons, useSurface: true, noBorderRadius: true, large: true }) })), _jsx(LogoutButton, {})] })) }))] })) })));
}
var useProfileButtons = function () {
    var language = useAppSelector(selectLanguage);
    var dispatch = useDispatch();
    var navigate = useNavigate();
    var selectedProductUUID = useAppSelector(selectSelectedProductUUID);
    var languageState = useAppSelector(function (state) { return state.languageSelector; });
    var homeSelector = useAppSelector(function (state) { return state.homeSelector; });
    var primaryPaymentAccount = useAppSelector(function (state) { return state.homeSelector.primaryPaymentAccount; });
    var FEEDBACK_LINK = 'https://usabi.li/do/7f0f3ad10f59/2eab';
    var isPaymentsEnabled = useAppSelector(selectPaymentsEnabled);
    var showPayments = function () {
        return isPaymentsEnabled || selectedProductUUID !== PRODUCTUUID.BB;
    };
    return __spreadArray(__spreadArray([
        {
            title: language.personalInfo,
            leadingIcon: _jsx(ImpIcon, { iconSrc: Icons.user }),
            onClick: function () {
                ANALYTICS.logEvent(EVENT.USER_MENU_PERSONAL_INFO_CLICKED);
                closeFlyout(dispatch);
                navigate(PAGE.PERSONAL_INFO);
            },
        }
    ], (showPayments()
        ? [
            {
                title: language.payments,
                leadingIcon: _jsx(ImpIcon, { iconSrc: Icons.currencyDollar }),
                onClick: function () {
                    closeFlyout(dispatch);
                    if (checkBankAccountBroken(primaryPaymentAccount)) {
                        ANALYTICS.logEvent(EVENT.HOME_CONNECT_BANK_ACCOUNT_CLICKED);
                        dispatch(setStartedBankLinking(true));
                        dispatch(setScreen(SCREEN.BANKING));
                        navigate(PAGE.BANKING);
                    }
                    else {
                        ANALYTICS.logEvent(EVENT.USER_MENU_PAYMENTS_CLICKED);
                        dispatch(setScreen(SCREEN.PAYMENTS));
                        navigate(PAGE.PAYMENTS);
                    }
                },
            },
        ]
        : []), true), [
        {
            title: language.banking,
            leadingIcon: _jsx(ImpIcon, { iconSrc: Icons.bank }),
            onClick: function () {
                ANALYTICS.logEvent(EVENT.USER_MENU_BANKING_CLICKED);
                closeFlyout(dispatch);
                navigate(PAGE.BANKING);
            },
        },
        {
            title: language.security,
            leadingIcon: _jsx(ImpIcon, { iconSrc: Icons.shield }),
            onClick: function () {
                ANALYTICS.logEvent(EVENT.USER_MENU_SECURITY_CLICKED);
                closeFlyout(dispatch);
                navigate(PAGE.SECURITY);
            },
        },
        {
            title: language.documents,
            leadingIcon: _jsx(ImpIcon, { iconSrc: Icons.fileText }),
            onClick: function () {
                ANALYTICS.logEvent(EVENT.USER_MENU_DOCUMENTS_CLICKED);
                closeFlyout(dispatch);
                navigate(PAGE.DOCUMENTS);
            },
        },
        {
            title: language.languageText,
            leadingIcon: _jsx(ImpIcon, { iconSrc: Icons.globe }),
            onClick: function () {
                closeFlyout(dispatch);
                navigate(PAGE.CHANGE_LANGUAGE);
            },
        },
        {
            title: language.support,
            leadingIcon: _jsx(ImpIcon, { iconSrc: Icons.question }),
            onClick: function () {
                var _a;
                ANALYTICS.logEvent(EVENT.USER_MENU_SUPPORT_CLICKED);
                window.open(generateSupportUrl((_a = homeSelector.selectedProductAccount) === null || _a === void 0 ? void 0 : _a.productUUID, languageState.languageCode), '_blank');
            },
        },
        {
            title: language.sendUsFeedbackTitle,
            subtitle: language.sendUsFeedbackSubtitle,
            leadingIcon: _jsx(ImpIcon, { iconSrc: Icons.chatCircleDots }),
            trailingContent: _jsx(ImpIcon, { iconSrc: Icons.arrowSquareOut }),
            onClick: function () {
                ANALYTICS.logEvent(EVENT.SUBMIT_FEEDBACK_CLICKED);
                window.open(FEEDBACK_LINK, '_blank');
            },
        },
    ], false);
};
function LogoutButton() {
    var dispatch = useDispatch();
    var language = useAppSelector(selectLanguage);
    var handleLogOutClick = function () {
        ANALYTICS.logEvent(EVENT.USER_MENU_SIGN_OUT_CLICKED);
        clearServiceLoginState(dispatch);
    };
    return (_jsx("div", __assign({ style: { position: 'sticky', bottom: '0', width: '100%' } }, { children: _jsx(ImpContainer, __assign({ width: "100%" }, { children: _jsx(ImpSurface, __assign({ elevation: 3, fluid: true }, { children: _jsx(ImpContainer, __assign({ onClick: handleLogOutClick, width: "100%" }, { children: _jsx(ImpPad, __assign({ padding: "32px 0px", fluid: true }, { children: _jsx(ImpFlex, __assign({ fluid: true, justify: "center", align: "center" }, { children: _jsx(ImpText, __assign({ typography: "label1" }, { children: language.logOut })) })) })) })) })) })) })));
}
function UserInfo() {
    var homeSelector = useAppSelector(selectHome);
    return (_jsx(ImpContainer, __assign({ width: "100%" }, { children: _jsx(ImpPad, __assign({ padding: "12px 24px 24px", fluid: true }, { children: _jsx(ImpFlex, __assign({ direction: "row", fluid: true, align: "center", gap: "24px" }, { children: _jsxs(ImpFlex, __assign({ direction: "column" }, { children: [_jsx(ImpText, __assign({ typography: "headline2" }, { children: "".concat(homeSelector.userInformation.firstName, " ").concat(homeSelector.userInformation.lastName.charAt(0), ".") })), _jsx(ImpText, __assign({ typography: "body1" }, { children: homeSelector.userInformation.memberSince }))] })) })) })) })));
}
