var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useNavigate } from 'react-router-dom';
import HeaderServicing from '../../components/HeaderServicing/HeaderServicing';
import { Icons, ImpBreadcrumbs, ImpContainer, ImpFlex, ImpIcon, ImpList, ImpSpacer } from '@imprint-payments/imprint-ui';
import { EVENT } from '../../analytics/analyticsConsts';
import { DOCUMENT_TYPE } from '../../app/homeSlice';
import { ANALYTICS } from '../../firebase/firebase';
import { navigateToAccountPage } from '../../utils/functionUtils';
import { selectLanguage } from '../../app/languageSelectors';
import { selectConsumerDocumentType } from '../../app/homeSelectors';
import { selectConsumerDocumentsFlag } from '../../app/featureFlagSelectors';
export default function ConsumerDocuments() {
    var _a;
    var dispatch = useAppDispatch();
    var navigate = useNavigate();
    var language = useAppSelector(selectLanguage);
    var homeState = useAppSelector(function (state) { return state.homeSelector; });
    var consumerDocumentType = useAppSelector(selectConsumerDocumentType);
    var consumerDocumentsEnabled = useAppSelector(selectConsumerDocumentsFlag);
    var consumerDocuments = (_a = homeState.selectedProductAccount) === null || _a === void 0 ? void 0 : _a.consumerDocuments;
    var documents = consumerDocuments === null || consumerDocuments === void 0 ? void 0 : consumerDocuments.filter(function (document) { return document.documentType === consumerDocumentType; });
    useEffect(function () {
        if (!consumerDocumentsEnabled) {
            navigateToAccountPage(dispatch, navigate);
        }
        // If there is no consumer documents, go back to account page.
        if (!homeState.selectedProductAccount || !consumerDocuments || consumerDocuments.length === 0) {
            navigateToAccountPage(dispatch, navigate);
        }
        ANALYTICS.logEvent(EVENT.CONSUMER_DOCUMENTS_VIEWED);
    }, []);
    var getTitle = function () {
        switch (consumerDocumentType) {
            case DOCUMENT_TYPE.CITI_HISTORY_STATEMENT:
                return language.historicalCitiStatements;
            case DOCUMENT_TYPE.HISTORY_CHA:
                return language.previousCardholderAgreements;
            default:
                // Should never happen if the document type is not supported.
                return null;
        }
    };
    var getDocumentList = function () {
        if (!documents) {
            return [];
        }
        return documents.map(function (document) {
            if (document.documentType === consumerDocumentType) {
                return {
                    title: document.name,
                    onClick: function () {
                        ANALYTICS.logEvent(EVENT.CONSUMER_DOCUMENT_CLICKED, {
                            documentName: document.name,
                            documentType: document.documentType,
                        });
                        window.open(document.url, '_blank');
                    },
                    trailingContent: _jsx(ImpIcon, { iconSrc: Icons.chevronRight }),
                };
            }
            else {
                return [];
            }
        });
    };
    return (_jsxs(ImpContainer, { children: [_jsx(HeaderServicing, {}), _jsx(ImpBreadcrumbs, { breadcrumbs: [language.documents], onBackClick: function () { return navigateToAccountPage(dispatch, navigate); }, onCancelClick: function () { return navigateToAccountPage(dispatch, navigate); } }), _jsx(ImpFlex, __assign({ direction: "column", align: "center" }, { children: _jsx(ImpContainer, __assign({ width: "var(--max-width-desktop)" }, { children: _jsxs(ImpContainer, __assign({ width: "100%" }, { children: [_jsx(ImpSpacer, { height: "2xl", mobileHeight: "xl" }), _jsx(ImpList, { title: getTitle(), useSurface: true, items: getDocumentList() })] })) })) }))] }));
}
