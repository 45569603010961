import { useEffect, useState } from 'react';
import { useAppSelector } from '../../app/hooks';
import { selectLanguage } from '../../app/languageSelectors';
import { ValidationResponse, getValidationString, hideWhileTyping, noError, } from '../../utils/validateUtils';
export var toUSDKeyDown = function (e) {
    // pvent deleting the dollar sign
    if ((e.key === 'Backspace' || e.key === 'Delete') && e.currentTarget.value === '$') {
        e.preventDefault();
        return;
    }
    // prevent doubling up leading zeros
    if (e.currentTarget.value === '$0' && e.key === '0') {
        e.preventDefault();
        return;
    }
    var validKeys = /[0-9]|Backspace|Delete|ArrowLeft|ArrowRight|Home|End|Tab/;
    // Handle Ctrl/Cmd + A, C, X, V for select all, copy, cut, and paste
    var isClipboardOperation = (e.ctrlKey || e.metaKey) && ['a', 'c', 'x', 'v', 'r'].includes(e.key.toLowerCase());
    if (!e.key.match(validKeys) && !isClipboardOperation) {
        e.preventDefault();
    }
};
export var formatUSD = function (s) {
    var numericValue = parseInt(s.replace(/[^0-9]/g, ''));
    if (isNaN(numericValue)) {
        return '$';
    }
    var formatted = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 0,
    }).format(numericValue);
    var formattedWithoutDecimals = formatted.split('.')[0];
    return formattedWithoutDecimals;
};
export var cleanUSD = function (s) {
    return s.replace(/[^0-9.]/g, '');
};
export var useFormInput = function (_a) {
    var validator = _a.validator, formatter = _a.formatter, cleanFormattedValue = _a.cleanFormattedValue, onKeyDown = _a.onKeyDown, defaultValue = _a.defaultValue, maxLength = _a.maxLength, maxLengthBuffer = _a.maxLengthBuffer;
    var language = useAppSelector(selectLanguage);
    var _b = useState(false), showError = _b[0], setShowError = _b[1];
    var _c = useState(''), errorMessage = _c[0], setErrorMessage = _c[1];
    var _d = useState(''), value = _d[0], setValue = _d[1];
    var _e = useState(''), displayValue = _e[0], setDisplayValue = _e[1];
    var _f = useState(ValidationResponse.empty), validationRes = _f[0], setValidationRes = _f[1];
    useEffect(function () {
        if (defaultValue) {
            // run onchange with default value
            var syntheticEvent = {
                target: { value: defaultValue },
            };
            onChange(syntheticEvent);
        }
    }, [defaultValue]);
    var onBlur = function () {
        var validationResponse = validator(value);
        setValidationRes(validationResponse);
        setShowError(!noError.includes(validationResponse));
        setErrorMessage(getValidationString(validationResponse, language));
    };
    var onChange = function (e) {
        var rawValue = e.target.value;
        var clean = cleanFormattedValue ? cleanFormattedValue(rawValue) : rawValue;
        if (maxLength !== undefined && clean.length > maxLength) {
            clean = clean.substring(0, maxLength);
        }
        var validationResponse = validator(clean);
        setValidationRes(validationResponse);
        if (validationResponse !== 'valid') {
            setShowError(!hideWhileTyping.includes(validationResponse));
            setErrorMessage(getValidationString(validationResponse, language));
        }
        else {
            setShowError(false);
            setErrorMessage('');
        }
        var formatted = formatter ? formatter(clean) : clean;
        setValue(clean);
        setDisplayValue(formatted);
    };
    // account for formatting. alternative is passing max length into the formatter
    var maxLengthProp = maxLength ? maxLength + (maxLengthBuffer !== null && maxLengthBuffer !== void 0 ? maxLengthBuffer : 0) : undefined;
    return [
        value,
        validationRes === ValidationResponse.valid,
        {
            onChange: onChange,
            showError: showError,
            errorMessage: errorMessage,
            onBlur: onBlur,
            value: displayValue,
            onKeyDown: onKeyDown,
            maxLength: maxLengthProp,
        },
    ];
};
