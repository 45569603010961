export var MembershipStatus;
(function (MembershipStatus) {
    MembershipStatus["ACTIVE"] = "ACTIVE";
    MembershipStatus["CLOSED"] = "CLOSED";
    MembershipStatus["PENDING_CONFIRMATION_ACTIVE"] = "PENDING_CONFIRMATION_ACTIVE";
    MembershipStatus["PENDING_CONFIRMATION_MERGED"] = "PENDING_CONFIRMATION_MERGED";
    // TODO: TUNG - add more statuses or create a v2
})(MembershipStatus || (MembershipStatus = {}));
export var MembershipContext;
(function (MembershipContext) {
    MembershipContext["ACTIVE"] = "active";
    MembershipContext["MERGED"] = "merged";
})(MembershipContext || (MembershipContext = {}));
