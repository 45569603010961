var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Icons, ImpFlyout } from '@imprint-payments/imprint-ui';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ImprintConstants } from '../../api/apiConstants';
import { selectActivity, selectFlyoutContext } from '../../app/flyoutSelectors';
import { FlyoutContext, closeFlyout } from '../../app/flyoutSlice';
import { useAppSelector } from '../../app/hooks';
import { ActivityDetailsHandler } from '../ActivityDetailsHandler/ActivityDetailsHandler';
import { UserMenu } from '../UserMenu/UserMenu';
import ActivityDetails from '../activityDetails/ActivityDetails';
function FlyoutContent() {
    var featureFlags = useAppSelector(function (state) { return state.featureFlagSelector.featureFlags; });
    var flyoutSlice = useAppSelector(function (state) { return state.flyoutSelector; });
    switch (flyoutSlice.context) {
        case FlyoutContext.Activity:
            if ((featureFlags === null || featureFlags === void 0 ? void 0 : featureFlags.activityDetailsPhase1) === 'true') {
                return _jsx(ActivityDetailsHandler, {});
            }
            else {
                return _jsx(ActivityDetails, { activity: flyoutSlice.activity });
            }
        case FlyoutContext.UserMenu:
            return _jsx(UserMenu, {});
        default:
            return _jsx(_Fragment, {});
    }
}
export default function Flyout() {
    var dispatch = useDispatch();
    var isVisible = useAppSelector(function (state) { return state.flyoutSelector.isVisible; });
    var isClosing = useAppSelector(function (state) { return state.flyoutSelector.isClosing; });
    var activity = useAppSelector(selectActivity);
    var flyoutContext = useAppSelector(selectFlyoutContext);
    var handleHelpClick = function () {
        var _a, _b, _c, _d;
        if ((_b = (_a = activity === null || activity === void 0 ? void 0 : activity.details) === null || _a === void 0 ? void 0 : _a.support) === null || _b === void 0 ? void 0 : _b.url) {
            window.open((_d = (_c = activity === null || activity === void 0 ? void 0 : activity.details) === null || _c === void 0 ? void 0 : _c.support) === null || _d === void 0 ? void 0 : _d.url, '_blank');
        }
        else {
            window.open(ImprintConstants.Help, '_blank');
        }
    };
    useEffect(function () {
        if (isVisible) {
            document.body.style.overflow = 'hidden';
        }
        else {
            document.body.style.overflow = 'unset';
        }
    }, [isVisible]);
    return (_jsx(ImpFlyout, __assign({ isOpen: isVisible, isClosing: isClosing, close: function () { return closeFlyout(dispatch); }, padDefault: false, contentContainerProps: {
            width: 'min(506px, 100%)',
        }, navTrailingIcon: activity && flyoutContext === FlyoutContext.Activity
            ? {
                onClick: handleHelpClick,
                iconSrc: Icons.question,
            }
            : undefined }, { children: _jsx(FlyoutContent, {}) })));
}
