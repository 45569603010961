var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { BreakpointsPx, Icons, ImpButton, ImpContainer, ImpFlex, ImpFlexItem, ImpHidden, ImpIcon, ImpPad, ImpText, colors, } from '@imprint-payments/imprint-ui';
import { useAppSelector } from '../../app/hooks';
import { selectLanguage } from '../../app/languageSelectors';
import HeaderBannerBackground from '../../assets/images/HeaderBannerBackground.svg';
import { REVIEW_HIGHLIGHT } from '.';
export function SpendReviewBanner(_a) {
    var handleFindMore = _a.handleFindMore, closeHeaderBanner = _a.closeHeaderBanner;
    var language = useAppSelector(selectLanguage);
    return (_jsx(ImpContainer, __assign({ backgroundColor: colors.background.backgroundInverse, width: "100%", height: "80px", position: "relative" }, { children: _jsxs(ImpFlex, __assign({ fluid: true, justify: "center", align: "center" }, { children: [_jsx("div", __assign({ style: {
                        width: '100vw',
                        height: '80px',
                        position: 'absolute',
                        top: '0px',
                        zIndex: 1,
                        background: "url(".concat(HeaderBannerBackground, ")"),
                        backgroundSize: 'cover',
                    } }, { children: _jsx(ImpContainer, { width: "100%" }) })), _jsx("div", __assign({ style: {
                        width: 'min(100vw - 32px, 1140px)',
                        height: '100%',
                        position: 'relative',
                        top: '0px',
                        zIndex: 1,
                    } }, { children: _jsx(ImpFlex, __assign({ fluid: true, align: "center", justify: "center" }, { children: _jsx(ImpContainer, __assign({ width: "100%", border: "1px solid ".concat(REVIEW_HIGHLIGHT), borderRadius: "8px", backgroundColor: colors.background.backgroundInverse }, { children: _jsx(ImpPad, __assign({ fluid: true, padding: "8px 24px", mobilePadding: "8px 16px" }, { children: _jsxs(ImpFlex, __assign({ justify: "space-between", align: "center", fluid: true, gap: "12px" }, { children: [_jsx(ImpHidden, __assign({ hideBelow: BreakpointsPx.Mobile }, { children: _jsx(ImpFlexItem, __assign({ flex: "1" }, { children: _jsx(ImpText, __assign({ color: REVIEW_HIGHLIGHT }, { children: language.spendReviewTeaser })) })) })), _jsx(ImpHidden, __assign({ hideAbove: BreakpointsPx.Mobile }, { children: _jsx(ImpButton, __assign({ variant: "inline", onClick: handleFindMore }, { children: _jsx(ImpContainer, __assign({ textAlign: "left" }, { children: _jsx(ImpText, __assign({ typography: "label3", color: REVIEW_HIGHLIGHT, underline: true }, { children: language.spendReviewTeaser })) })) })) })), _jsxs(ImpFlex, __assign({ direction: "row", align: "center", gap: "8px" }, { children: [_jsx(ImpFlexItem, __assign({ flex: "0 0 auto" }, { children: _jsx(ImpHidden, __assign({ hideBelow: BreakpointsPx.Mobile }, { children: _jsx(ImpButton, __assign({ buttonStyle: "color", color: REVIEW_HIGHLIGHT, variant: "secondary", onClick: handleFindMore, size: "hug" }, { children: language.downloadAppBanner })) })) })), _jsx(ImpIcon, { stroke: REVIEW_HIGHLIGHT, iconSrc: Icons.close, onClick: closeHeaderBanner })] }))] })) })) })) })) }))] })) })));
}
