// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wB4h6pWPGLZkAqr8ioFs {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-end;\n}\n\n.z00RZJEuMQNlLkxeAE3w {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  cursor: pointer;\n}\n\n.Vld90Ji5i4e2bAuPVtJP {\n  height: 96px;\n  position: absolute;\n  width: 320px;\n  z-index: 1;\n  box-shadow: 0px 16px 48px rgba(0, 0, 0, 0.12);\n  border-radius: 8px;\n  margin-top: 56px;\n}\n\n.OHNAFXbZ_nhYSFNkgcb5 {\n  margin-right: 16px;\n}\n\n.UmAh5OvBoRzgZATXHwx4 {\n  font-weight: 504;\n  font-size: 18px;\n  line-height: 28px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Dropdown/Dropdown.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,YAAY;EACZ,UAAU;EACV,6CAA6C;EAC7C,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,iBAAiB;AACnB","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-end;\n}\n\n.dropdownButtonContainer {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  cursor: pointer;\n}\n\n.dropdownListContainer {\n  height: 96px;\n  position: absolute;\n  width: 320px;\n  z-index: 1;\n  box-shadow: 0px 16px 48px rgba(0, 0, 0, 0.12);\n  border-radius: 8px;\n  margin-top: 56px;\n}\n\n.icon {\n  margin-right: 16px;\n}\n\n.value {\n  font-weight: 504;\n  font-size: 18px;\n  line-height: 28px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "wB4h6pWPGLZkAqr8ioFs",
	"dropdownButtonContainer": "z00RZJEuMQNlLkxeAE3w",
	"dropdownListContainer": "Vld90Ji5i4e2bAuPVtJP",
	"icon": "OHNAFXbZ_nhYSFNkgcb5",
	"value": "UmAh5OvBoRzgZATXHwx4"
};
module.exports = ___CSS_LOADER_EXPORT___;
