import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useState } from 'react';
import VerifyPinPage from '../authorizedUsers/VerifyPinPage';
import { ImpBreadcrumbs, ImpContainer, ImpSpacer } from '@imprint-payments/imprint-ui';
import HeaderServicing from '../../components/HeaderServicing/HeaderServicing';
import { RewardsRedemptionPage, setRewardsRedemptionPage, } from '../../app/rewardsRedemptionSlice';
import Banking from '../banking/Banking';
import { displayTemporarySnackbar, navigateToAccountPage } from '../../utils/functionUtils';
export function RedeemUpdateBankPage() {
    var dispatch = useAppDispatch();
    var navigate = useNavigate();
    var language = useAppSelector(function (state) { return state.languageSelector.languageLocalization; });
    var _a = useState('verify'), updateState = _a[0], setUpdateState = _a[1];
    switch (updateState) {
        case 'verify': {
            return (_jsxs(ImpContainer, { children: [_jsx(HeaderServicing, {}), _jsx(ImpBreadcrumbs, { breadcrumbs: [language.updateBankTitle], onBackClick: function () {
                            return dispatch(setRewardsRedemptionPage(RewardsRedemptionPage.ConfigureRedemption));
                        }, onCancelClick: function () { return navigateToAccountPage(dispatch, navigate); } }), _jsx(ImpSpacer, { height: "2xl" }), _jsx(VerifyPinPage, { handleSuccess: function () { return setUpdateState('update'); } })] }));
        }
        case 'update': {
            return (_jsxs(ImpContainer, { children: [_jsx(HeaderServicing, {}), _jsx(ImpContainer, { children: _jsx(Banking, { onExitFlow: function () {
                                dispatch(setRewardsRedemptionPage(RewardsRedemptionPage.ConfigureRedemption));
                            }, onSuccess: function () {
                                displayTemporarySnackbar(language.bankUpdateSuccess, dispatch);
                                dispatch(setRewardsRedemptionPage(RewardsRedemptionPage.ConfigureRedemption));
                            } }) })] }));
        }
    }
}
