// WARNING - these flags may resolve to strings, booleans, or numbers. Please
// verify the correct type of the feature flag you're using
export var featureFlags = [
    'authUsers',
    'cardArtSelection',
    'downloadCsvActivityFeed',
    'forgotPinEnabled',
    'multiCardUIEnabled',
    'oneTimeRewardInFeedEnabled',
    'rewardDetailsEnabled',
    'rewardRedemptionEnabled',
    'rewardShop',
    'showCardNumberEnabled',
    'spanish',
    'pinLockOutEnabled',
    'activityDetailsPhase1',
    'physical_card_tracking_widget_enabled',
    'accountLinkEnabled',
    'payments_enhancements_enabled',
    'spend_review_enabled',
    'reward_details_update_enabled',
    'showBrooksBrothersLoginBanner',
    'findAccountEnabled',
    'paymentsEnabled',
    'voiceOTPEnabled',
    'enableRewards',
    'displayAuthUserCardDelayTHY',
    'consumerDocumentsEnabled',
    'showDownloadCsvNewIcon',
    'isMaintenanceIndicatorEnabled',
    'maintenanceWindowEndEpochMS',
    'maintenanceWindowStartEpochMS',
];
