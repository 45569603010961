import { createSlice } from '@reduxjs/toolkit';
var initialState = {
    scheduledPayments: []
};
var activitySlice = createSlice({
    name: 'activity',
    initialState: initialState,
    reducers: {
        setScheduledPayments: function (state, action) {
            state.scheduledPayments = action.payload;
        },
    },
});
export var setScheduledPayments = activitySlice.actions.setScheduledPayments;
export default activitySlice.reducer;
