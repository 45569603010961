var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ImpBadgeText, ImpBreadcrumbs, ImpButton, ImpContainer, ImpDisplayInput, ImpFlex, ImpPad, ImpRewardsOptions, ImpSpacer, ImpText, colors, } from '@imprint-payments/imprint-ui';
import HeaderServicing from '../../components/HeaderServicing/HeaderServicing';
import { inputToUSD } from '../../utils/formatUtils';
import { navigateToAccountPage } from '../../utils/functionUtils';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useNavigate } from 'react-router-dom';
import { RewardRedemptionType } from '../../types/RewardRedemptionType';
import { RewardsRedemptionPage, setRedeemVal, setRewardsRedemptionPage, } from '../../app/rewardsRedemptionSlice';
import { formatDisplayAmount } from '@imprint-payments/imprint-lib';
import { useEffect, useMemo } from 'react';
import { RewardsDestination } from './RewardsDestination';
import styles from './ConfigureRedemptionPage.module.css';
import { PaymentAccountStatus } from '../dashboard/Dashboard';
import { getActionButtonState, getActionButtonText, getCannotRedeem, getOptions, initialSetup, selectFirstAvailable, useRedemptionSelection, } from './rewardRedemptionHelpers';
import { ANALYTICS } from '../../firebase/firebase';
import { EVENT } from '../../analytics/analyticsConsts';
export function ConfigureRedemption() {
    var _a;
    var dispatch = useAppDispatch();
    var navigate = useNavigate();
    var language = useAppSelector(function (state) { return state.languageSelector.languageLocalization; });
    var rewardsRedemptionState = useAppSelector(function (state) { return state.rewardsRedemptionSelector; });
    var productAccount = useAppSelector(function (state) { return state.productAccountSelector; });
    var merchantData = useAppSelector(function (state) { return state.merchantDataSelector; });
    var initialConditions = (_a = {},
        _a[RewardRedemptionType.StatementCredit] = 'enabled',
        _a[RewardRedemptionType.BankWithdrawal] = 'enabled',
        _a[RewardRedemptionType.MailCheck] = 'enabled',
        _a[RewardRedemptionType.Empty] = 'disabled',
        _a);
    var redemptionOptions = productAccount.rewards.redemptionTypes;
    var _b = useRedemptionSelection(initialConditions, dispatch, redemptionOptions, merchantData, productAccount, rewardsRedemptionState), conditions = _b[0], handleRedemptionSelection = _b[1];
    var paymentAccount = useAppSelector(function (state) { return state.homeSelector.primaryPaymentAccount; });
    var homeSelector = useAppSelector(function (state) { return state.homeSelector; });
    useEffect(function () {
        ANALYTICS.logEvent(EVENT.REWARDS_CONFIGURE_VIEWED, {
            card_balance: formatDisplayAmount(productAccount.cardBalanceV2.totalSpend),
            available_balance: formatDisplayAmount(productAccount.rewards.rewardBalances.balances.available.amount),
        });
        if (rewardsRedemptionState.redemptionType === RewardRedemptionType.Empty) {
            initialSetup(productAccount, dispatch, handleRedemptionSelection, redemptionOptions);
        }
    }, []);
    var options = useMemo(function () {
        return getOptions(redemptionOptions, conditions, dispatch, rewardsRedemptionState, handleRedemptionSelection, rewardsRedemptionState.redeemVal, productAccount, language);
    }, [redemptionOptions, conditions, merchantData, rewardsRedemptionState]);
    var actionButtonText = useMemo(function () { return getActionButtonText(paymentAccount, rewardsRedemptionState, language); }, [paymentAccount, rewardsRedemptionState.redemptionType]);
    var actionButtonState = useMemo(function () {
        return getActionButtonState(paymentAccount, productAccount, rewardsRedemptionState);
    }, [paymentAccount, rewardsRedemptionState, productAccount]);
    var cannotRedeem = useMemo(function () {
        return getCannotRedeem(productAccount.cardBalanceV2.totalSpend, productAccount.rewards.rewardBalances.balances.available.amount, redemptionOptions);
    }, [merchantData, productAccount, redemptionOptions]) || false;
    var handlePrimaryButtonClick = function () {
        if (actionButtonState === 'disabled')
            return;
        if (paymentAccount.status !== PaymentAccountStatus.Approved &&
            rewardsRedemptionState.redemptionType ===
                RewardRedemptionType.BankWithdrawal) {
            dispatch(setRewardsRedemptionPage(RewardsRedemptionPage.UpdateBank));
            return;
        }
        dispatch(setRewardsRedemptionPage(RewardsRedemptionPage.ConfirmRedemption));
    };
    var handleRewardsBalanceClick = function () {
        var formattedAvailableBalance = formatDisplayAmount(productAccount.rewards.rewardBalances.balances.available.amount);
        var formattedCardBalance = formatDisplayAmount(productAccount.cardBalanceV2.totalSpend);
        var parsedCardBalance = parseFloat(formattedCardBalance.replace(/[$,]/g, ''));
        var parsedAvailableBalance = parseFloat(formattedAvailableBalance.replace(/[$,]/g, ''));
        dispatch(setRedeemVal(formattedAvailableBalance));
        if (parsedCardBalance > parsedAvailableBalance) {
            handleRedemptionSelection(RewardRedemptionType.StatementCredit);
        }
        else {
            selectFirstAvailable(redemptionOptions, productAccount, handleRedemptionSelection, dispatch);
        }
    };
    return (_jsxs(ImpContainer, { children: [_jsx(HeaderServicing, {}), _jsx(ImpBreadcrumbs, { breadcrumbs: [language.useRewards], onBackClick: function () { return navigateToAccountPage(dispatch, navigate); }, onCancelClick: function () { return navigateToAccountPage(dispatch, navigate); } }), _jsx(ImpSpacer, { height: "2xl" }), _jsx(ImpFlex, __assign({ direction: "column", align: "center", fluid: true }, { children: _jsxs(ImpContainer, __assign({ width: "min(750px, 100% - 32px)", textAlign: "center" }, { children: [_jsx(ImpText, __assign({ typography: "headline2" }, { children: language.rewardRedemptionTitle })), _jsx(ImpSpacer, { height: "2xl", mobileHeight: "xl" }), _jsx(ImpFlex, __assign({ fluid: true, direction: "column", align: "center" }, { children: _jsx(ImpContainer, __assign({ width: "100%", textAlign: "center", backgroundColor: colors.background.backgroundPrimary, borderRadius: "12px" }, { children: _jsxs(ImpPad, __assign({ padding: "48px", mobilePadding: "16px" }, { children: [_jsxs(ImpFlex, __assign({ direction: "column", align: "center", fluid: true }, { children: [_jsx("div", __assign({ className: styles.rewardsButton }, { children: _jsx(ImpButton, __assign({ variant: "wrapper", onClick: handleRewardsBalanceClick }, { children: _jsx(ImpBadgeText, __assign({ variant: "secondary", color: 'var(--merchant-color)' }, { children: "".concat(language.rewardsLabel).concat(formatDisplayAmount(productAccount.rewards.rewardBalances.balances
                                                                .available.amount)) })) })) })), _jsx(ImpPad, __assign({ padding: "40px", mobilePadding: "16px" }, { children: _jsx(ImpDisplayInput, { value: rewardsRedemptionState.redeemVal, onChange: function (e) { return dispatch(setRedeemVal(e.target.value)); }, clearButton: true, formatter: inputToUSD, autofocus: false }) }))] })), _jsx(ImpFlex, __assign({ direction: "column", align: "center" }, { children: _jsx(ImpContainer, __assign({ width: "100%" }, { children: _jsxs(ImpFlex, __assign({ direction: "column", gap: "l", align: "center" }, { children: [_jsx(ImpContainer, __assign({ width: "100%" }, { children: _jsx("div", __assign({ className: styles.rewardsButton }, { children: _jsx(ImpRewardsOptions, { options: options }) })) })), RewardsDestination({
                                                            selectedRedemptionType: rewardsRedemptionState.redemptionType,
                                                            paymentAccount: paymentAccount,
                                                            address: homeSelector.userInformation.address,
                                                            cannotRedeem: cannotRedeem,
                                                        }), _jsx(ImpContainer, __assign({ width: "min(100%, 356px)" }, { children: _jsx(ImpButton, __assign({ state: actionButtonState, onClick: handlePrimaryButtonClick, buttonStyle: "branded", size: "fill" }, { children: actionButtonText })) }))] })) })) }))] })) })) }))] })) })), _jsx(ImpSpacer, { height: "2xl" })] }));
}
