var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2;
import westgateErrorQr from '../assets/images/westgate-dynamic-qr-error.jpeg';
import westgateQr from '../assets/images/westgate-dynamic-qr.jpeg';
import { LanguageCode } from './languageConstants';
import { LOGIN_PAGE } from './routeConstant';
export var PRODUCTUUID = {
    HH: 'PRDT-v1-785c001c-a9b1-4fc2-87d7-2d0b1060e3ed',
    WG: 'PRDT-v1-a0b163f2-21b1-4fcc-8106-660b6c456c71',
    HEB: 'PRDT-v1-e7a1d3ba-da09-45e5-ab1b-f822cd01b3bf',
    CM: 'PRDT-v1-d7a7bcc2-a2cd-41fe-91d2-b6d355b3f68f',
    HICV: 'PRDT-v1-610f2cec-ac8b-4f00-abd2-83dbd74982da',
    TURKISH: 'PRDT-v1-ed952893-358b-4a70-b4bc-24fac624d5aa',
    EB: 'PRDT-v1-13844060-10f9-481b-bbd8-ce554f70422a',
    BB: 'PRDT-v1-028a15e5-acd2-4bfe-959a-19558e2894b6',
    BOOKING: 'PRDT-v1-a0ef33e9-5976-4e56-aed1-2d0d7cf940d5',
    CRATE_BARREL: 'PRDT-v1-30763e2e-7278-43ca-82b0-cbf6cf7351bb',
    RAKUTEN: 'PRDT-v1-611dddad-353d-46c4-8139-3385ed742de6',
    FETCH: 'PRDT-v1-b30b35f0-42cb-4ba2-a1e0-ddd3572e5dc1',
};
export var PARTNER_TYPE = {
    'PRDT-v1-ed952893-358b-4a70-b4bc-24fac624d5aa': 'TURKISH',
    'PRDT-v1-13844060-10f9-481b-bbd8-ce554f70422a': 'MERKLE',
    'PRDT-v1-028a15e5-acd2-4bfe-959a-19558e2894b6': 'EPSILON',
    'PRDT-v1-785c001c-a9b1-4fc2-87d7-2d0b1060e3ed': 'CLUTCH',
    'PRDT-v1-e7a1d3ba-da09-45e5-ab1b-f822cd01b3bf': 'HEB',
};
// TODO: should be part of merchant-assets
export var MerchantInfos = {
    HH: {
        website: 'https://horizonhobby.com',
        infoSharing: (_a = {},
            _a[LanguageCode.English] = 'https://horizonhobby.imprint.co/credit/legal/cardholder-information-sharing-notice/en-us',
            _a[LanguageCode.Spanish] = 'https://horizonhobby.imprint.co/credit/legal/cardholder-information-sharing-notice/es-us',
            _a),
        rewardTerms: (_b = {},
            _b[LanguageCode.English] = 'https://www.imprint.co/legal/horizon-hobby-rewards-terms',
            _b[LanguageCode.Spanish] = 'https://www.imprint.co/es-us/horizon-hobby/rewards-terms',
            _b),
        zendesk: (_c = {},
            _c[LanguageCode.English] = 'https://horizonhobbysupport.zendesk.com/hc/en-us',
            _c[LanguageCode.Spanish] = 'https://horizonhobbysupport.zendesk.com/hc/es-us',
            _c),
        displaySignupLink: 'imprint.co/hh',
        signUpLink: '/horizonhobby/credit',
        supportPhone: '+18884103664',
    },
    HEB: {
        website: 'https://heb.com',
        infoSharing: (_d = {},
            _d[LanguageCode.English] = 'https://heb.imprint.co/credit/legal/cardholder-information-sharing-notice/en-us',
            _d[LanguageCode.Spanish] = 'https://heb.imprint.co/credit/legal/cardholder-information-sharing-notice/es-us',
            _d),
        rewardTerms: (_e = {},
            _e[LanguageCode.English] = 'https://heb.imprint.co/credit/legal/rewards-terms/en-us',
            _e[LanguageCode.Spanish] = 'https://heb.imprint.co/credit/legal/rewards-terms/es-us',
            _e),
        zendesk: (_f = {},
            _f[LanguageCode.English] = 'https://heb.imprint.co/credit/help/en-us',
            _f[LanguageCode.Spanish] = 'https://heb.imprint.co/credit/help/es-us',
            _f),
        displaySignupLink: 'imprint.co/heb',
        signUpLink: '/heb/credit',
        supportPhone: '+18882902383',
    },
    CM: {
        website: 'https://centralmarket.com',
        infoSharing: (_g = {},
            _g[LanguageCode.English] = 'https://centralmarket.imprint.co/credit/legal/cardholder-information-sharing-notice/en-us',
            _g[LanguageCode.Spanish] = 'https://centralmarket.imprint.co/credit/legal/cardholder-information-sharing-notice/es-us',
            _g),
        rewardTerms: (_h = {},
            _h[LanguageCode.English] = 'https://centralmarket.imprint.co/credit/legal/rewards-terms/en-us',
            _h[LanguageCode.Spanish] = 'https://centralmarket.imprint.co/credit/legal/rewards-terms/es-us',
            _h),
        zendesk: (_j = {},
            _j[LanguageCode.English] = 'https://centralmarket.imprint.co/credit/help/en-us',
            _j[LanguageCode.Spanish] = 'https://centralmarket.imprint.co/credit/help/es-us',
            _j),
        displaySignupLink: 'imprint.co/cm',
        signUpLink: '/centralmarket/credit',
        supportPhone: '+18888188104',
    },
    WG: {
        website: 'https://westgateresorts.com',
        infoSharing: (_k = {},
            _k[LanguageCode.English] = 'https://wowmastercard.com/credit/legal/cardholder-information-sharing-notice/en-us',
            _k[LanguageCode.Spanish] = 'https://wowmastercard.com/credit/legal/cardholder-information-sharing-notice/es-us',
            _k),
        rewardTerms: (_l = {},
            _l[LanguageCode.English] = 'https://www.imprint.co/en-us/westgate-resorts/rewards-terms',
            _l[LanguageCode.Spanish] = 'https://www.imprint.co/es-us/westgate-resorts/rewards-terms',
            _l),
        zendesk: (_m = {},
            _m[LanguageCode.English] = 'https://westgatesupport.zendesk.com/hc/en-us',
            _m[LanguageCode.Spanish] = 'https://westgatesupport.zendesk.com/hc/es',
            _m),
        displaySignupLink: 'imprint.co/wow',
        signUpLink: '/westgateresorts/credit',
        supportPhone: '+18886931683',
    },
    HICV: {
        website: 'https://holidayinnclub.com',
        infoSharing: (_o = {},
            _o[LanguageCode.English] = 'https://holidayinnclub.imprint.co/credit/legal/cardholder-information-sharing-notice/en-us',
            _o[LanguageCode.Spanish] = 'https://holidayinnclub.imprint.co/credit/legal/cardholder-information-sharing-notice/es-us',
            _o),
        rewardTerms: (_p = {},
            _p[LanguageCode.English] = 'https://holidayinnclub.imprint.co/credit/legal/rewards-terms/en-us',
            _p[LanguageCode.Spanish] = 'https://holidayinnclub.imprint.co/credit/legal/rewards-terms/es-us',
            _p),
        zendesk: (_q = {},
            _q[LanguageCode.English] = 'https://holidayinnclub.imprint.co/credit/help/en-us',
            _q[LanguageCode.Spanish] = 'https://holidayinnclub.imprint.co/credit/help/es-us',
            _q),
        displaySignupLink: 'imprint.co/hicv',
        signUpLink: '/holidayinnclub/credit',
        supportPhone: '+18888148895',
    },
    TURKISH: {
        website: 'https://turkishairlines.com/',
        // TODO not available now, using mock links, may need to change later
        infoSharing: (_r = {},
            _r[LanguageCode.English] = 'https://turkishairlines.imprint.co/premier/credit/legal/cardholder-information-sharing-notice/en-us',
            _r[LanguageCode.Spanish] = 'https://turkishairlines.imprint.co/premier/credit/legal/cardholder-information-sharing-notice/es-us',
            _r),
        rewardTerms: (_s = {},
            _s[LanguageCode.English] = 'https://turkishairlines.imprint.co/premier/credit/legal/rewards-terms/en-us',
            _s[LanguageCode.Spanish] = 'https://turkishairlines.imprint.co/premier/credit/legal/rewards-terms/es-us',
            _s),
        zendesk: (_t = {},
            _t[LanguageCode.English] = 'https://turkishairlinescredit.zendesk.com/hc/en-us',
            _t[LanguageCode.Spanish] = 'https://turkishairlinescredit.zendesk.com/hc/es-us',
            _t),
        displaySignupLink: 'imprint.co/turkishairlines',
        signUpLink: '/turkishairlines/premier/credit',
        supportPhone: '+18888573891',
    },
    BB: {
        // TODO not available now, using mock links, may need to change later
        website: 'https://www.brooksbrothers.com',
        cardholderAgreement: (_u = {},
            _u[LanguageCode.English] = 'https://brooksbrothers.imprint.co/credit/legal/credit-cardholder-agreement/en-us',
            _u[LanguageCode.Spanish] = 'https://brooksbrothers.imprint.co/credit/legal/credit-cardholder-agreement/es-us',
            _u),
        infoSharing: (_v = {},
            _v[LanguageCode.English] = 'https://brooksbrothers.imprint.co/credit/legal/cardholder-information-sharing-notice/en-us',
            _v[LanguageCode.Spanish] = 'https://brooksbrothers.imprint.co/credit/legal/cardholder-information-sharing-notice/es-us',
            _v),
        rewardTerms: (_w = {},
            _w[LanguageCode.English] = 'https://brooksbrothers.imprint.co/credit/legal/rewards-terms/en-us',
            _w[LanguageCode.Spanish] = 'https://brooksbrothers.imprint.co/credit/legal/rewards-terms/es-us',
            _w),
        zendesk: (_x = {},
            _x[LanguageCode.English] = 'https://brooksbrothers.imprint.co/credit/help/en-us',
            _x[LanguageCode.Spanish] = 'https://brooksbrothers.imprint.co/credit/help/es-us',
            _x),
        displaySignupLink: 'imprint.co/brooksbrothers',
        signUpLink: '/brooksbrothers/credit',
        supportPhone: '+18888938847',
    },
    EB: {
        website: 'https://eddiebauer.imprint.co',
        cardholderAgreement: (_y = {},
            _y[LanguageCode.English] = 'https://eddiebauer.imprint.co/credit/legal/credit-cardholder-agreement/en-us',
            _y[LanguageCode.Spanish] = 'https://eddiebauer.imprint.co/credit/legal/credit-cardholder-agreement/es-us',
            _y),
        infoSharing: (_z = {},
            _z[LanguageCode.English] = 'https://eddiebauer.imprint.co/credit/legal/cardholder-information-sharing-notice/en-us',
            _z[LanguageCode.Spanish] = 'https://eddiebauer.imprint.co/credit/legal/cardholder-information-sharing-notice/es-us',
            _z),
        rewardTerms: (_0 = {},
            _0[LanguageCode.English] = 'https://eddiebauer.imprint.co/credit/legal/rewards-terms/en-us',
            _0[LanguageCode.Spanish] = 'https://eddiebauer.imprint.co/credit/legal/rewards-terms/es-us',
            _0),
        zendesk: (_1 = {},
            _1[LanguageCode.English] = 'https://eddiebauer.imprint.co/credit/help/en-us',
            _1[LanguageCode.Spanish] = 'https://eddiebauer.imprint.co/credit/help/es-us',
            _1),
        displaySignupLink: 'imprint.co/eddiebauer',
        signUpLink: '/eddiebauer/credit',
        supportPhone: '+18888282290',
    },
};
// Allows us to access MerchantInfos by productUUID
// ex.const curr_zendesk = MerchantInfos[PRODUCTUUID_TO_MERCHANT[productUUID]].zendesk[language];
export var PRODUCTUUID_TO_MERCHANT = (_2 = {},
    _2[PRODUCTUUID.HH] = 'HH',
    _2[PRODUCTUUID.HEB] = 'HEB',
    _2[PRODUCTUUID.CM] = 'CM',
    _2[PRODUCTUUID.WG] = 'WG',
    _2[PRODUCTUUID.HICV] = 'HICV',
    _2[PRODUCTUUID.TURKISH] = 'TURKISH',
    _2[PRODUCTUUID.EB] = 'EB',
    _2[PRODUCTUUID.BB] = 'BB',
    _2[PRODUCTUUID.BOOKING] = 'BOOKING',
    _2[PRODUCTUUID.CRATE_BARREL] = 'CRATE_BARREL',
    _2[PRODUCTUUID.RAKUTEN] = 'RAKUTEN',
    _2[PRODUCTUUID.FETCH] = 'FETCH',
    _2);
export var MERCHANT_QR_INFO = [
    {
        productUUID: 'PRDT-v1-785c001c-a9b1-4fc2-87d7-2d0b1060e3ed',
        jpeg: 'https://app.imprint.co/content/qr/horizonhobby_credit_qr.jpeg',
        jpegError: 'https://app.imprint.co/content/qr/horizonhobby_credit_qr.jpeg',
        iosLink: 'https://apps.apple.com/us/app/imprint-app/id1570296831?ppid=b3355b44-2b16-4bf9-aeb1-a3f5d01f7017',
        androidLink: 'https://play.google.com/store/apps/details?id=co.imprint.consumer&listing=horizonhobbycredit',
        lpName: 'horizon_hobby_lp',
    },
    {
        productUUID: 'PRDT-v1-a0b163f2-21b1-4fcc-8106-660b6c456c71',
        jpeg: westgateQr,
        jpegError: westgateErrorQr,
        iosLink: 'https://apps.apple.com/us/app/imprint-app/id1570296831?ppid=5b05ddd9-d7e3-4e9d-95cb-746dc1682eb8',
        androidLink: 'https://play.google.com/store/apps/details?id=co.imprint.consumer&listing=westgate',
        lpName: 'westgate_lp',
    },
];
export function getLoginMerchant(path) {
    var _a;
    var productUUID = (_a = {},
        _a[LOGIN_PAGE.HEB] = PRODUCTUUID.HEB,
        _a[LOGIN_PAGE.CM] = PRODUCTUUID.CM,
        _a[LOGIN_PAGE.HICV] = PRODUCTUUID.HICV,
        _a[LOGIN_PAGE.HH] = PRODUCTUUID.HH,
        _a[LOGIN_PAGE.WG] = PRODUCTUUID.WG,
        _a[LOGIN_PAGE.TURKISH] = PRODUCTUUID.TURKISH,
        _a[LOGIN_PAGE.EB] = PRODUCTUUID.EB,
        _a[LOGIN_PAGE.BB] = PRODUCTUUID.BB,
        _a[LOGIN_PAGE.BOOKING] = PRODUCTUUID.BOOKING,
        _a)[path];
    return productUUID !== null && productUUID !== void 0 ? productUUID : '';
}
