import { jsx as _jsx } from "react/jsx-runtime";
import { EVENT } from '../../analytics/analyticsConsts';
import { editPhone } from '../../api/PersonalInfo/UpdatePhone/editPhone.api';
import { editPhoneOtp } from '../../api/PersonalInfo/UpdatePhone/editPhoneOtp.api';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectLanguage } from '../../app/languageSelectors';
import { selectOtpDestination } from '../../app/otpScreenSelectors';
import { OtpCard } from '../../components/otp/OtpCard';
import { displayTemporarySnackbar } from '../../utils/functionUtils';
var analyticsEvents = {
    viewed: EVENT.EDIT_PHONE_OTP_VIEWED,
    submitted: EVENT.EDIT_PHONE_OTP_SUBMITTED,
    incorrect: EVENT.EDIT_PHONE_OTP_TRY_AGAIN_VIEWED,
    resend: EVENT.EDIT_PHONE_OTP_RESEND_CLICKED,
    callInstead: EVENT.EDIT_PHONE_OTP_CALL_CLICKED,
    callAgain: EVENT.EDIT_PHONE_OTP_CALL_AGAIN_CLICKED,
};
export function EditPhoneOTPPage(_a) {
    var onSuccess = _a.onSuccess, onBack = _a.onBack;
    var dispatch = useAppDispatch();
    var language = useAppSelector(selectLanguage);
    var phone = useAppSelector(selectOtpDestination);
    var submitOtpHandler = function (code) {
        editPhoneOtp({
            code: code,
            onSuccess: onSuccess,
            onDuplicate: function () {
                //TODO: need localized error message
                displayTemporarySnackbar('Duplicate phone entry in the system.', dispatch);
                onBack();
            },
        });
    };
    var resendOtpHandler = function (sendVoice) {
        editPhone({
            phone: phone,
            sendVoice: sendVoice,
            onFail: function () {
                displayTemporarySnackbar(language.somethingWentWrong, dispatch);
            },
        });
    };
    return (_jsx(OtpCard, { onFull: submitOtpHandler, handleResend: resendOtpHandler, events: analyticsEvents }));
}
