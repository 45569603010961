var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { formatDisplayAmount } from '@imprint-payments/imprint-lib';
import { ImpContainer, ImpList } from '@imprint-payments/imprint-ui';
import { useAppSelector } from '../../app/hooks';
import { selectLanguage } from '../../app/languageSelectors';
import { selectCardTerms } from '../../app/merchantDataSelectors';
export function CardBalanceCardTerms() {
    var cardTerms = useAppSelector(selectCardTerms);
    var language = useAppSelector(selectLanguage);
    var termsList = [
        {
            trailingTitle: cardTerms.apr,
            subtitle: language.apr,
        },
        {
            trailingTitle: formatDisplayAmount(cardTerms.creditLimit),
            subtitle: language.creditLimit,
        },
        {
            trailingTitle: cardTerms.lateFee,
            subtitle: language.lateFee,
        },
        {
            trailingTitle: cardTerms.returnedFee,
            subtitle: language.returnedFee,
        },
        {
            trailingTitle: cardTerms.annualFee,
            subtitle: language.annualFee,
        },
        {
            trailingTitle: cardTerms.internationalFee,
            subtitle: language.internationalFee,
        },
    ];
    return (_jsx(ImpContainer, __assign({ width: "100%" }, { children: _jsx(ImpList, { title: language.cardTerms, useSurface: true, items: termsList }) })));
}
