import { KEY } from './keyConstants';
export var getEmptyAmount = function () {
    return {
        displaySymbol: '',
        displayValue: '',
        displayUnit: '',
        exponent: 0,
        negative: false,
        valueInMinUnit: 0,
    };
};
export var months = function (language) {
    return language === 'es'
        ? [
            'enero',
            'febrero',
            'marzo',
            'abril',
            'mayo',
            'junio',
            'julio',
            'agosto',
            'septiembre',
            'octubre',
            'noviembre',
            'diciembre',
        ]
        : [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
        ];
};
export var pascalCase = function (string) {
    return (string.toLowerCase().charAt(0).toUpperCase() + string.toLowerCase().slice(1)).replace('_', ' ');
};
export var formatPaymentAccount = function (paymentAccount, language) {
    return "".concat(paymentAccount.name === '' ? language.account : paymentAccount.name, " (\u2022\u2022\u2022\u2022 ").concat(paymentAccount === null || paymentAccount === void 0 ? void 0 : paymentAccount.accountNumberLast4, ")");
};
export var formatPhoneNumberInput = function (inputText, keyCode, cursor, cursorEnd) {
    var finalStr = inputText;
    if ((keyCode === KEY.DEL || keyCode === KEY.BACKSPACE) &&
        cursor === cursorEnd) {
        if (cursor === 5 || cursor === 6) {
            finalStr = inputText.replace(/[^\d]/g, '');
            finalStr = finalStr.slice(0, 2) + finalStr.slice(3);
        }
        else if (cursor === 10 || cursor === 11 || cursor === 12) {
            finalStr = inputText.replace(/[^\d]/g, '');
            finalStr = finalStr.slice(0, 5) + finalStr.slice(6);
        }
    }
    return finalStr;
};
export var formatPhoneNumber = function (input) {
    if (!input)
        return input;
    var phoneNumber = input.replace(/[^\d]/g, '');
    var phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4)
        return phoneNumber;
    if (phoneNumberLength < 7) {
        return "(".concat(phoneNumber.slice(0, 3), ") ").concat(phoneNumber.slice(3));
    }
    return "(".concat(phoneNumber.slice(0, 3), ") ").concat(phoneNumber.slice(3, 6), " - ").concat(phoneNumber.slice(6, 10));
};
export var formatDob = function (value) {
    var currentDob = value.replace(/[^\d]/g, '');
    if (currentDob.length > 1 &&
        Math.max.apply(Math, currentDob.split('').map(function (val) { return +val; })) == 0) {
        return '';
    }
    var currentDobLength = currentDob.length;
    if (currentDobLength < 3)
        return currentDob;
    if (currentDobLength < 5)
        return "".concat(currentDob.slice(0, 2), "/").concat(currentDob.slice(2, 4));
    return "".concat(currentDob.slice(0, 2), "/").concat(currentDob.slice(2, 4), "/").concat(currentDob.slice(4, 8));
};
export var formatDateValue = function (date) {
    return String(date).padStart(2, '0');
};
export var formatNumberInput = function (input, maxLength) {
    var result = input.match(/\d/g);
    if (result) {
        return result.join('').substring(0, maxLength);
    }
    return '';
};
export var formatFullSSN = function (value) {
    var currentSSN = value.trim().replace(/[^\d]/g, '');
    var currentSSNLength = currentSSN.length;
    if (currentSSNLength < 4)
        return currentSSN;
    if (currentSSNLength < 6)
        return "".concat(currentSSN.slice(0, 3), "-").concat(currentSSN.slice(3, 5));
    return "".concat(currentSSN.slice(0, 3), "-").concat(currentSSN.slice(3, 5), "-").concat(currentSSN.slice(5, 9));
};
export var formatSSN = function (value) {
    return value.trim().replace(/[^\d]/g, '');
};
export var formatZipCode = function (input) {
    var result = input.match(/\d/g);
    if (result) {
        return result.join('').substring(0, 5);
    }
    return '';
};
export var formatState = function (input) {
    // Only accept letter characters
    var result = input.match(/^[A-Za-z]+$/g);
    if (result) {
        return result.join('').substring(0, 5);
    }
    return '';
};
export var inputNotEmpty = function (input) {
    for (var i = 0; i <= input.length; i++) {
        if (input[i] === '') {
            return false;
        }
    }
    return true;
};
export function displayNumberFromBE(val) {
    if (val === undefined) {
        return '--';
    }
    // remove trailing zeros if '.00'
    var toDisplay = val.displayValue;
    if (toDisplay.endsWith('.00')) {
        toDisplay = "".concat(toDisplay.substring(0, toDisplay.length - 3));
    }
    if (val.displaySymbol !== undefined && val.displaySymbol !== '') {
        return "".concat(val.displaySymbol).concat(toDisplay);
    }
    if (val.displayUnit !== undefined && val.displayUnit !== '') {
        return "".concat(toDisplay).concat(val.displayUnit);
    }
    return "".concat(toDisplay);
}
export function displayNumberNone(val, language) {
    if (val === undefined) {
        return '--';
    }
    if (val.valueInMinUnit === 0) {
        return language.none;
    }
    // remove trailing zeros if '.00'
    return displayNumberFromBE(val);
}
export var calculateExpDate = function () {
    var today = new Date();
    today.setDate(today.getDate() + 25);
    var strToday = today.toLocaleString();
    var dateArr = strToday.split('/');
    return "".concat(dateArr[0], "/").concat(dateArr[1]);
};
export var formatCurrency = function (value) {
    var val = value
        .replace('$', '')
        .trim()
        .replace(/[^\d.]/g, '');
    var dollar = val !== '' ? '$' : '';
    var amount = val.slice(0, 0) + dollar + val.slice(0, val.length);
    return amount;
};
export var currencyToNumber = function (value) {
    return Number(value.replace('$', '').replace(/,/g, ''));
};
export var currencyToMinUnit = function (value) {
    return Math.round(Number(value) * Math.pow(10, 2));
};
export var inputToUSD = function (amount, hideDollarSign) {
    var toReturn = amount.trim().replace(/[^\d]/g, '');
    // adds a decimal two digits from the end
    // prettier-ignore
    switch (toReturn.length) {
        case 0:
            toReturn = '0.00';
            break;
        case 1:
            toReturn = "0.0".concat(toReturn);
            break;
        case 2:
            toReturn = "0.".concat(toReturn);
            break;
        default:
            toReturn = "".concat(toReturn.slice(0, toReturn.length - 2), ".").concat(toReturn.slice(toReturn.length - 2, toReturn.length));
            // removes leading zeros if the number is not a decimal
            if (toReturn[0] === '0' && toReturn[1] !== '.') {
                toReturn = toReturn.slice(1, toReturn.length);
            }
            break;
    }
    var parts = toReturn.split('.');
    var whole = parts[0];
    var decimal = parts[1];
    // places a comma for every 3 digits from the right
    if (whole.length > 3) {
        whole = whole.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
    if (!hideDollarSign) {
        toReturn = "$".concat(whole, ".").concat(decimal);
    }
    else {
        toReturn = "".concat(whole, ".").concat(decimal);
    }
    return toReturn;
};
export var convertAmountToString = function (am, removeDecimal, removeUnit) {
    if (removeDecimal === void 0) { removeDecimal = false; }
    if (removeUnit === void 0) { removeUnit = false; }
    if (!am) {
        return '';
    }
    if (removeDecimal) {
        return ((am.negative ? '-' : '') +
            am.displaySymbol +
            am.displayValue.split('.')[0]);
    }
    // handle points
    if (!am.displaySymbol) {
        if (am.displayUnit !== '%') {
            if (removeUnit) {
                return (am.negative ? '-' : '') + am.displayValue;
            }
            return (am.negative ? '-' : '') + am.displayValue + ' ' + am.displayUnit;
        }
        return (am.negative ? '-' : '') + am.displayValue + am.displayUnit;
    }
    // handle dollars
    return (am.negative ? '-' : '') + am.displaySymbol + am.displayValue;
};
export var convertAmountSubtext = function (prefix, amountSubtext) {
    return "".concat(prefix, " ").concat(amountSubtext);
};
export var convertAmountToNumber = function (am) {
    if (!am) {
        return 0;
    }
    return am.valueInMinUnit;
};
export var convertAmountMinUnitToString = function (am) {
    if (!am) {
        return '0';
    }
    return am.valueInMinUnit.toString();
};
export var getTodaysDate = function () {
    return new Date()
        .toLocaleString('en-US')
        .split(',')[0]
        .slice(0, new Date().toLocaleString('en-US').split(',')[0].replace('/', '.')
        .length - 5);
};
export var addSuffixToDate = function (date) {
    if (!date) {
        return '';
    }
    if (date > 3 && date < 21)
        return date + 'th';
    switch (date % 10) {
        case 1:
            return date + 'st';
        case 2:
            return date + 'nd';
        case 3:
            return date + 'rd';
        default:
            return date + 'th';
    }
};
export var formatDollarStringToString = function (amount) {
    amount = amount.replace('$', '');
    amount = amount.replace(',', '');
    return amount;
};
export var convertStatementSpendDisplayToDateRange = function (statementSpendDisplay) {
    if (!statementSpendDisplay ||
        !statementSpendDisplay.startDate ||
        !statementSpendDisplay.endDate) {
        return undefined;
    }
    return (statementSpendDisplay.startDate.month +
        '/' +
        statementSpendDisplay.startDate.day +
        ' - ' +
        statementSpendDisplay.endDate.month +
        '/' +
        statementSpendDisplay.endDate.day);
};
export var snakeToTitleCase = function (s) {
    return s.replace(/_/g, ' ').replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
};
export var formatDollarStringToStringAbsolute = function (amount) {
    amount = amount.replace('$', '');
    amount = amount.replace('-', '');
    amount = amount.replace(',', '');
    return amount;
};
export function epochSecToESTDate(epochSec, language) {
    var targetTimeZone = 'America/New_York';
    var date = new Date(epochSec * 1000);
    var options = {
        timeZone: targetTimeZone,
        timeZoneName: 'short',
    };
    var estDateString = date.toLocaleString('en-US', options);
    var month = estDateString.split(',')[0].split('/')[0];
    var day = estDateString.split(',')[0].split('/')[1];
    var monthString = months(language)[Number(month) - 1];
    return "".concat(monthString, " ").concat(day).trim();
}
export var epochSecToESTTimeString = function (epochSec) {
    var targetTimeZone = 'America/New_York';
    var date = new Date(epochSec * 1000);
    var options = {
        timeZone: targetTimeZone,
        timeZoneName: 'short',
    };
    var estDateString = date.toLocaleString('en-US', options);
    var hour = estDateString.split(',')[1].split(':')[0];
    var minute = estDateString.split(',')[1].split(':')[1];
    var amOrPm = estDateString.split(',')[1].split(' ')[2];
    // clean leading or trailing spaces
    return "".concat(hour, ":").concat(minute, " ").concat(amOrPm, " EST").trim();
};
export var getNextAutoPayDate = function (productAccount, selectedLanguage, monthAsText) {
    var _a;
    var paymentPlan = (_a = productAccount.autoPayDetails) === null || _a === void 0 ? void 0 : _a.date;
    if (!paymentPlan) {
        return '';
    }
    var monthNumber = paymentPlan.month;
    var dayNumber = paymentPlan.month;
    if (selectedLanguage && monthAsText) {
        return months(selectedLanguage)[monthNumber - 1] + ' ' + dayNumber;
    }
    else {
        return paymentPlan.month + '/' + paymentPlan.day;
    }
};
export var formatToSnakeCase = function (s) {
    return s.toLowerCase().replace(/\s+/g, '_');
};
export var capitalize = function (s) {
    return s.charAt(0).toUpperCase() + s.slice(1);
};
