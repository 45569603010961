var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { ListSection, LIST_STYLE } from '../list/List';
import styles from './Dropdown.module.css';
export default function Dropdown(_a) {
    var defaultSelection = _a.defaultSelection, dropdownItems = _a.dropdownItems, hideOnSelect = _a.hideOnSelect, icon = _a.icon, value = _a.value, onClickEvent = _a.onClickEvent;
    var _b = useState(false), isOpen = _b[0], setIsOpen = _b[1];
    var updateOpened = function () {
        setIsOpen(!isOpen);
        if (isOpen) {
            onClickEvent();
        }
    };
    var handleClick = function () {
        if (hideOnSelect) {
            setIsOpen(false);
        }
    };
    return (_jsxs("div", __assign({ className: styles.container }, { children: [_jsxs("div", __assign({ className: styles.dropdownButtonContainer, onClick: updateOpened }, { children: [icon && (_jsx("img", { src: icon, className: styles.icon, tabIndex: 0, alt: "dropdown icon" })), value && (_jsx("div", __assign({ className: styles.value, tabIndex: 0 }, { children: value })))] })), isOpen && (_jsx("div", __assign({ className: styles.dropdownListContainer, onClick: handleClick }, { children: _jsx(ListSection, { style: LIST_STYLE.CHECK, items: dropdownItems, defaultSelection: defaultSelection }) })))] })));
}
