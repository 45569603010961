var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ImpButton, ImpContainer, ImpFlex, ImpSpacer, ImpText, colors, } from '@imprint-payments/imprint-ui';
import { UpcomingPayments } from './UpcomingPayments';
import { useAppSelector } from '../../app/hooks';
import { selectLanguage } from '../../app/languageSelectors';
export function AreYouSureContent(_a) {
    var onPrimaryClick = _a.onPrimaryClick, onSecondaryClick = _a.onSecondaryClick;
    var language = useAppSelector(selectLanguage);
    var handlePrimaryButton = function () {
        onPrimaryClick();
    };
    var handleSecondaryButton = function () {
        onSecondaryClick();
    };
    return (_jsx(_Fragment, { children: _jsx(ImpFlex, __assign({ direction: "row", justify: "center" }, { children: _jsxs(ImpContainer, __assign({ width: "min(640px, 100% - 32px)" }, { children: [_jsxs(ImpContainer, __assign({ textAlign: "center" }, { children: [_jsx(ImpText, __assign({ typography: "headline2" }, { children: language.areYouSureTitle })), _jsx(ImpSpacer, { height: "l" }), _jsx(ImpText, __assign({ color: colors.content.onSurfaceVariant }, { children: language.areYouSureDesc }))] })), _jsx(ImpSpacer, { height: '2xl', mobileHeight: "xl" }), _jsx(UpcomingPayments, {}), _jsx(ImpSpacer, { height: '2xl', mobileHeight: "xl" }), _jsxs(ImpFlex, __assign({ direction: "row", mobileDirection: "column", gap: "8px" }, { children: [_jsx(ImpButton, __assign({ size: "fill", onClick: handleSecondaryButton, buttonStyle: "branded", variant: "secondary" }, { children: language.areYouSureSecondaryButtonLabel })), _jsx(ImpButton, __assign({ size: "fill", onClick: handlePrimaryButton, buttonStyle: "branded" }, { children: language.areYouSurePrimaryButtonLabel }))] }))] })) })) }));
}
