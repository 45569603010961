export var PAGE;
(function (PAGE) {
    PAGE["HOME"] = "/";
    PAGE["OTP"] = "/otp";
    PAGE["OFFER"] = "/offer";
    PAGE["ERROR"] = "/error";
    PAGE["LOGIN"] = "/login";
    PAGE["ONE_TIME_PAYMENT"] = "/one-time-payment";
    PAGE["AUTOPAY"] = "/autopay";
    PAGE["ACCOUNT"] = "/home";
    PAGE["DOCUMENTS"] = "/documents";
    PAGE["BANKING"] = "/banking";
    PAGE["PAYMENTS"] = "/payments";
    PAGE["DOWNLOAD_APP"] = "/downloadapp";
    PAGE["EDU_APP_WEB"] = "/edu-web-app";
    PAGE["CHANGE_LANGUAGE"] = "/change-language";
    PAGE["ACCEPT"] = "/accept";
    PAGE["ACTIVATE_PHYSICAL_CARD"] = "/activate-card";
    PAGE["GET_NEW_CARD_NUMBER"] = "/get-new-card-number";
    PAGE["GET_PHYSICAL_CARD"] = "/get-physical-card";
    PAGE["REPLACE_CARD"] = "/replace-card";
    PAGE["ACCESS_MORE"] = "/access-more";
    PAGE["SUCCESS"] = "/success";
    PAGE["STATEMENT_SETTINGS"] = "/statement-settings";
    PAGE["CONSUMER_DOCUMENTS"] = "/consumer-documents";
    PAGE["CARD_BALANCE"] = "/card-balance";
    PAGE["PERSONAL_INFO"] = "/personal-info";
    PAGE["SECURITY"] = "/security";
    PAGE["ADD_AUTHORIZED_USER"] = "/add-authorized-user";
    PAGE["AUTHORIZED_USERS_PAGE"] = "/authorized-users";
    PAGE["ACCOUNT_STATUS"] = "/account-status";
    PAGE["REWARDS"] = "/redeem";
    PAGE["REWARD_DETAILS"] = "/rewards-details";
    PAGE["UPDATE_LINKED_ACCOUNT"] = "/update-linked-account";
    PAGE["FIND_USER_ACCOUNT"] = "/find-user-account";
    PAGE["ACCOUNT_SETUP"] = "/account-setup";
})(PAGE || (PAGE = {}));
//TODO: [WS] Branded login entry point, path not finalized yet
export var LOGIN_PAGE;
(function (LOGIN_PAGE) {
    LOGIN_PAGE["HEB"] = "/heb";
    LOGIN_PAGE["CM"] = "/centralmarket";
    LOGIN_PAGE["HICV"] = "/holidayinnclub";
    LOGIN_PAGE["HH"] = "/horizonhobby";
    LOGIN_PAGE["WG"] = "/westgateresorts";
    LOGIN_PAGE["TURKISH"] = "/turkishairlines";
    LOGIN_PAGE["EB"] = "/eddiebauer";
    LOGIN_PAGE["BB"] = "/brooksbrothers";
    LOGIN_PAGE["BOOKING"] = "/booking";
})(LOGIN_PAGE || (LOGIN_PAGE = {}));
export var EXTERNAL_URLS;
(function (EXTERNAL_URLS) {
    EXTERNAL_URLS["cardLiftDownload"] = "https://chromewebstore.google.com/detail/heb-credit-for-chrome/hclkdpaahnpfenlibcmjnajjccpkhoak";
    EXTERNAL_URLS["imprintDownload"] = "http://imprint.co/download";
})(EXTERNAL_URLS || (EXTERNAL_URLS = {}));
export function loginPath() {
    var currentPath = window.location.pathname;
    return Object.values(LOGIN_PAGE).includes(currentPath) ? currentPath : PAGE.LOGIN;
}
export function isGenericLogin() {
    return window.location.pathname == PAGE.LOGIN;
}
