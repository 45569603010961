var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useNavigate } from 'react-router-dom';
import styles from './GetNewCardNumber.module.css';
import { displayTemporarySnackbar, navigateToAccountPage, } from '../../utils/functionUtils';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { ANALYTICS } from '../../firebase/firebase';
import { EVENT } from '../../analytics/analyticsConsts';
import { useEffect, useState } from 'react';
import HeaderServicing from '../../components/HeaderServicing/HeaderServicing';
import { HomeScreen, setHomeScreen } from '../../app/homeSlice';
import { ImpBreadcrumbs, ImpButton, ImpContainer, ImpFlex, ImpPad, ImpSpacer, ImpText, colors, useModal } from '@imprint-payments/imprint-ui';
import { RequestCardErrorModal } from '../../components/popup/RequestCardErrorModal';
import { FORM_BUTTON_WIDTH, stripeMigrationErrorCode } from '../../assets/consts/const';
import { requestNewCardNumber } from '../../api/CardMenu/cardRequestHelper';
export function GetNewCardNumber() {
    var navigate = useNavigate();
    var dispatch = useAppDispatch();
    var language = useAppSelector(function (state) { return state.languageSelector.languageLocalization; });
    var _a = useState(false), isLoading = _a[0], setIsLoading = _a[1];
    var _b = useModal(false), isOpen = _b[0], isClosing = _b[1], openModal = _b[2], closeModal = _b[3];
    var virtualCardInfo = useAppSelector(function (state) { return state.productAccountSelector.virtualCard; });
    var cardUUID = virtualCardInfo === null || virtualCardInfo === void 0 ? void 0 : virtualCardInfo.cardUUID;
    useEffect(function () {
        // return to home page if the user has not activated its card
        if (!cardUUID) {
            navigateToAccountPage(dispatch, navigate);
        }
        ANALYTICS.logEvent(EVENT.GET_NEW_CARD_NUMBER_VIEWED);
    }, []);
    function onSuccess() {
        displayTemporarySnackbar(language.cardNumberUpdated, dispatch);
        navigateToAccountPage(dispatch, navigate);
        dispatch(setHomeScreen(HomeScreen.CardDetails));
        setIsLoading(false);
    }
    function onError(code) {
        if (code === stripeMigrationErrorCode) {
            // Enable a modal to notify user about the Stripe migration
            ANALYTICS.logEvent(EVENT.REQUEST_CARD_ERROR_VIEWED);
            openModal();
        }
        else {
            // TODO Need to restore it after the stripe migration
            displayTemporarySnackbar(language.somethingWentWrong, dispatch);
        }
        setIsLoading(false);
    }
    return (_jsxs(_Fragment, { children: [_jsx(HeaderServicing, {}), _jsx(ImpBreadcrumbs, { breadcrumbs: [], onBackClick: function () { return navigateToAccountPage(dispatch, navigate); }, onCancelClick: function () { return navigateToAccountPage(dispatch, navigate); } }), _jsxs("div", __assign({ className: styles.body }, { children: [_jsx(RequestCardErrorModal, { closeModal: closeModal, isOpen: isOpen, isClosing: isClosing }), _jsxs(ImpFlex, __assign({ direction: "column", align: "center" }, { children: [_jsx(ImpText, __assign({ typography: "headline1" }, { children: language.getNewCardNumber })), _jsx(ImpPad, __assign({ padding: "16px 0px" }, { children: _jsx(ImpText, __assign({ typography: "body1", color: colors.content.onBackgroundVariant }, { children: language.getNewCardNumberSubtitle })) }))] })), _jsx(ImpSpacer, { height: 'xl' }), _jsxs(ImpContainer, __assign({ width: FORM_BUTTON_WIDTH }, { children: [_jsx(ImpButton, __assign({ size: "fill", variant: "primary", buttonStyle: "branded", onClick: function () {
                                    setIsLoading(true);
                                    ANALYTICS.logEvent(EVENT.GET_NEW_CARD_NUMBER_CONFIRM_CLICKED);
                                    if (cardUUID) {
                                        dispatch(requestNewCardNumber({
                                            cardUUID: cardUUID,
                                            last4: undefined,
                                            onSuccess: onSuccess,
                                            onError: onError,
                                        }));
                                    }
                                }, state: isLoading ? 'submitting' : 'enabled' }, { children: language.confirmBtn })), _jsx(ImpSpacer, { height: 'xl' }), _jsx(ImpButton, __assign({ size: "fill", variant: "text", buttonStyle: "branded", onClick: function () {
                                    ANALYTICS.logEvent(EVENT.GET_NEW_CARD_NUMBER_CANCEL_CLICKED);
                                    navigateToAccountPage(dispatch, navigate);
                                } }, { children: language.nevermindBtn }))] }))] }))] }));
}
