var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import { createSlice } from '@reduxjs/toolkit';
import { getEmptyMember } from '../types/Member';
export var AccountLinkStatus;
(function (AccountLinkStatus) {
    AccountLinkStatus[AccountLinkStatus["FIND_ACCOUNT"] = 0] = "FIND_ACCOUNT";
    AccountLinkStatus[AccountLinkStatus["LINK_ACCOUNT"] = 1] = "LINK_ACCOUNT";
    AccountLinkStatus[AccountLinkStatus["PENDING"] = 2] = "PENDING";
    AccountLinkStatus[AccountLinkStatus["MERGED_ACCOUNT"] = 3] = "MERGED_ACCOUNT";
    AccountLinkStatus[AccountLinkStatus["CLOSED_ACCOUNT"] = 4] = "CLOSED_ACCOUNT";
    AccountLinkStatus[AccountLinkStatus["ERROR"] = 5] = "ERROR";
})(AccountLinkStatus || (AccountLinkStatus = {}));
var initialState = {
    showAccountLinkModal: false,
    status: AccountLinkStatus.FIND_ACCOUNT,
    originalMember: getEmptyMember(),
    mergedMember: getEmptyMember(),
    invalidMembershipNumber: false,
    snackShownFor: [],
};
var accountLinkSlice = createSlice({
    name: 'accountLink',
    initialState: initialState,
    reducers: {
        setShowAccountLinkModal: function (state, action) {
            state.showAccountLinkModal = action.payload;
        },
        setStatus: function (state, action) {
            state.status = action.payload;
        },
        setOriginalMember: function (state, action) {
            state.originalMember = action.payload;
        },
        setMergedMember: function (state, action) {
            state.mergedMember = action.payload;
        },
        setInvalidMembershipNumber: function (state, action) {
            state.invalidMembershipNumber = action.payload;
        },
        snackShownFor: function (state, action) {
            state.snackShownFor.push(action.payload);
        },
        resetAccountLinkSlice: function (state) {
            state = __assign(__assign({}, initialState), { snackShownFor: state.snackShownFor });
        },
        setMembershipNumber: function (state, action) {
            state.membershipNumber = action.payload;
        }
    },
});
export var setShowAccountLinkModal = (_a = accountLinkSlice.actions, _a.setShowAccountLinkModal), setStatus = _a.setStatus, setOriginalMember = _a.setOriginalMember, setMergedMember = _a.setMergedMember, setInvalidMembershipNumber = _a.setInvalidMembershipNumber, snackShownFor = _a.snackShownFor, resetAccountLinkSlice = _a.resetAccountLinkSlice, setMembershipNumber = _a.setMembershipNumber;
export default accountLinkSlice.reducer;
