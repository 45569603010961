import { getEmptyAmount } from "./Amount";
;
export var getEmptyRewardBalances = function () {
    return ({
        balances: {
            available: {
                title: '',
                amount: getEmptyAmount()
            },
            pending: {
                title: '',
                amount: getEmptyAmount()
            },
            total: {
                title: '',
                amount: getEmptyAmount()
            },
        }
    });
};
