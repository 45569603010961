import Base64 from 'crypto-js/enc-base64';
import hmacSHA256 from 'crypto-js/hmac-sha256';
import { store } from '../app/store';
export function getApiKey(state) {
    var _a, _b;
    return ((_a = state.apiScreenScreenSelector) === null || _a === void 0 ? void 0 : _a.apiKey) || ((_b = store.getState().apiScreenScreenSelector) === null || _b === void 0 ? void 0 : _b.apiKey);
}
// note: path is the url path, not the full url
export function getSignature(_a) {
    var path = _a.path, state = _a.state, body = _a.body;
    var apiKey = getApiKey(state);
    if (apiKey) {
        var hashVal = ":".concat(path);
        if (body && Object.keys(body).length > 0) {
            hashVal = "".concat(JSON.stringify(body), ":").concat(path);
        }
        return Base64.stringify(hmacSHA256(hashVal, apiKey));
    }
    return '';
}
