var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { formatDisplayAmount } from '@imprint-payments/imprint-lib';
import { colors, Icons, ImpButton, ImpContainer, ImpDivider, ImpFlex, ImpIcon, ImpPad, ImpProgressRing, ImpText, } from '@imprint-payments/imprint-ui';
import { useNavigate } from 'react-router-dom';
import { EVENT } from '../../analytics/analyticsConsts';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectLanguage } from '../../app/languageSelectors';
import { selectProductAccount } from '../../app/productAccountSelectors';
import { SCREEN, setScreen } from '../../app/screenStateSlice';
import { PaymentWidget } from '../../components/PaymentWidget';
import { ANALYTICS } from '../../firebase/firebase';
import { PAGE } from '../../utils/routeConstant';
import { generateBalanceRings } from '../cardBalance/CardBalanceUtils';
export function HomeCardBalanceSection() {
    return (_jsxs(ImpContainer, __assign({ backgroundColor: "white", borderRadius: "12px", width: "100%" }, { children: [_jsx(CardBalanceSectionHeader, {}), _jsx(ImpDivider, { color: colors.border.borderVariant }), _jsx(PaymentWidget, {})] })));
}
function CardBalanceSectionHeader() {
    var language = useAppSelector(selectLanguage);
    var productAccount = useAppSelector(selectProductAccount);
    var dispatch = useAppDispatch();
    var navigate = useNavigate();
    var navigateToCardBalancePage = function () {
        ANALYTICS.logEvent(EVENT.CARD_BALANCE_CLICKED);
        dispatch(setScreen(SCREEN.CARD_BALANCE));
        navigate(PAGE.CARD_BALANCE);
    };
    return (_jsx(ImpContainer, __assign({ width: "100%", onClick: navigateToCardBalancePage }, { children: _jsx(ImpFlex, __assign({ direction: "row", justify: "space-between", align: "center", fluid: true }, { children: _jsx(ImpPad, __assign({ padding: "16px 24px", fluid: true }, { children: _jsxs(ImpFlex, __assign({ fluid: true, direction: "row", align: "center", justify: "space-between" }, { children: [_jsxs(ImpContainer, { children: [_jsxs(ImpFlex, __assign({ direction: "column" }, { children: [_jsx(ImpText, __assign({ typography: "body2", color: colors.content.onBackgroundVariant }, { children: language.cardBalance })), _jsx(ImpPad, __assign({ padding: "4px 0px 8px" }, { children: _jsx(ImpText, __assign({ typography: "headline1" }, { children: formatDisplayAmount(productAccount.cardBalanceV2.totalSpend) })) }))] })), _jsx(ImpButton, __assign({ variant: "inline", onClick: navigateToCardBalancePage }, { children: _jsx(ImpContainer, __assign({ textAlign: "left", cursor: "pointer" }, { children: _jsx(ImpText, __assign({ typography: "body2", underline: true }, { children: language.viewBalanceDetails })) })) }))] }), _jsxs(ImpFlex, __assign({ direction: "row", align: "center", gap: "s" }, { children: [_jsx(ImpProgressRing, { small: true, rings: generateBalanceRings(productAccount.cardBalanceV2) }), _jsx(ImpIcon, { iconSrc: Icons.chevronRight, stroke: colors.content.onSurfaceVariant })] }))] })) })) })) })));
}
