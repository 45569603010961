var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import DOMPurify from 'dompurify';
import Cookies from 'universal-cookie';
import { consumerLogOut } from '../api/api';
import { ENV, resetDeviceID } from '../app/apiScreenSlice';
import { requireFullSSN, SECTION, setEligibilityVerified, setLoading, setSection, } from '../app/applicationScreenSlice';
import { setAppStatus } from '../app/applicationStatusSlice';
import { clearAuth } from '../app/authSlice';
import { resetAuthUsers } from '../app/authUsersSlice';
import { resetConsumerInfoSlice } from '../app/consumerInfoSlice';
import { resetFlyoutState } from '../app/flyoutSlice';
import { resetForgotPinSlice } from '../app/forgotPinSlice';
import { HomeScreen, resetHome, setHomeScreen } from '../app/homeSlice';
import { resetMerchantData } from '../app/merchantDataSlice';
import { resetOtpScreenSlice } from '../app/otpScreenSlice';
import { resetProductAccount } from '../app/productAccountSlice';
import { resetScreenState, SCREEN, setScreen } from '../app/screenStateSlice';
import { dismissSnackbar, displaySnackbar, SnackbarContext, TEMP_SNACKBAR_DURATION, } from '../app/snackbarSlice';
import { resetStatements } from '../app/statementsSlice';
import { resetInputValidation } from '../app/validatorSlice';
import { payByCheckLinkDict } from '../assets/consts/const';
import { PaymentAccountStatus } from '../pages/dashboard/Dashboard';
import { COOKIEKEY, DOMAIN } from './cookieConstants';
import { months } from './formatUtils';
import { LANGUAGE_HEADER, LanguageCode } from './languageConstants';
import { MerchantInfos, PRODUCTUUID_TO_MERCHANT } from './productUUIDConstants';
import { PAGE } from './routeConstant';
export var clearHomePageState = function (dispatch) {
    dispatch(setAppStatus(''));
    dispatch(setSection(SECTION.PERSONALINFO));
    dispatch(requireFullSSN(false));
    dispatch(resetConsumerInfoSlice());
    dispatch(resetInputValidation());
    dispatch(setEligibilityVerified(false));
    dispatch(setScreen(SCREEN.APPLICATION));
    return;
};
export var clearServiceLoginState = function (dispatch) {
    consumerLogOut();
    dispatch(clearAuth());
    dispatch(setLoading(false));
    dispatch(resetConsumerInfoSlice());
    dispatch(resetDeviceID());
    dispatch(resetMerchantData());
    dispatch(resetStatements());
    dispatch(resetHome());
    dispatch(resetInputValidation());
    dispatch(resetProductAccount());
    dispatch(resetScreenState());
    dispatch(resetOtpScreenSlice());
    dispatch(resetFlyoutState());
    dispatch(resetAuthUsers());
    dispatch(resetForgotPinSlice());
    return;
};
export function logOutUser(dispatch) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            clearServiceLoginState(dispatch);
            return [2 /*return*/];
        });
    });
}
export var getNewDeviceID = function () {
    return Date.now().toString(36) + Math.random().toString(36).substring(2);
};
export var getCookieValueFor = function (key) {
    // Cookies currently in format: 'key1=value1; key2=value2; ...;'
    var cookies = new Cookies();
    return cookies.get(key);
};
export var saveCookie = function (key, value, domain) {
    // key-value that you want to add will be overwritten if it existed previously or not.
    var cookieMaxAgeSecs = 5 * 60;
    if (domain !== undefined) {
        document.cookie = "".concat(key, "=").concat(value, "; path=/; secure; sameSite=Lax; domain=").concat(domain, "; max-age=").concat(cookieMaxAgeSecs);
    }
    else {
        // for localhost test
        document.cookie = "".concat(key, "=").concat(value, "; path=/; secure; sameSite=Lax; max-age=").concat(cookieMaxAgeSecs);
    }
};
export var sanitize = function (url) {
    // strip special  characters
    return DOMPurify.sanitize(url).replace(/[^a-zA-Z0-9-._~:/?#[@\]!$&'*+,;=]/g, '');
};
export var validateUrlOrReturnEmpty = function (url) {
    // URL validation
    var regex = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
    var isValid = regex.test(url);
    return isValid ? url : '';
};
export var removeCookieFor = function (key) {
    var cookies = new Cookies();
    var domain = window.location.hostname.toLowerCase();
    var domainParse = domain.split('.');
    var path = '/';
    if (domain === ENV.LOCAL) {
        return cookies.remove(key);
    }
    else if (domainParse.includes(ENV.PREPRD)) {
        return cookies.remove(key, { path: path, domain: DOMAIN.PREPRD });
    }
    else if (domainParse.includes(ENV.STG)) {
        return cookies.remove(key, { path: path, domain: DOMAIN.STG });
    }
    else {
        return cookies.remove(key, { path: path, domain: DOMAIN.PROD });
    }
};
export var setSessionUrlParam = function (link) {
    var urlSearchParams = new URLSearchParams(link);
    var params = Object.fromEntries(urlSearchParams.entries());
    if (Object.keys(params).length !== 0) {
        for (var key in params) {
            var clean = sanitize(params[key]);
            sessionStorage.setItem(key, clean);
        }
    }
};
export var setSessionLanguage = function (language) {
    saveCookie(LANGUAGE_HEADER, language);
};
export var getLanguageFromCookie = function () {
    var languageVal = getCookieValueFor(LANGUAGE_HEADER);
    switch (languageVal) {
        case LanguageCode.Spanish:
            return LanguageCode.Spanish;
        case LanguageCode.English:
        default:
            return LanguageCode.English;
    }
};
export var convertAmountToString = function (am) {
    if (!am) {
        return '';
    }
    return am.displaySymbol + am.displayValue;
};
export var displayTemporarySnackbar = function (text, dispatch, type) {
    if (type === void 0) { type = 'none'; }
    dispatch(displaySnackbar({
        snackbarText: text,
        context: SnackbarContext.Default,
        snackbarType: type,
    }));
    setTimeout(function () {
        dispatch(dismissSnackbar());
    }, TEMP_SNACKBAR_DURATION);
};
export var displaySnackbarOnFocus = function (text, dispatch, type) {
    if (type === void 0) { type = 'none'; }
    if (document.hasFocus()) {
        // If the window is already focused, execute immediately
        displayTemporarySnackbar(text, dispatch, type);
    }
    else {
        // Otherwise, wait for the window to gain focus
        window.addEventListener('focus', function () { return displayTemporarySnackbar(text, dispatch, type); });
    }
};
export var navigateToAccountPage = function (dispatch, navigate) {
    dispatch(setScreen(SCREEN.ACCOUNT));
    navigate(PAGE.ACCOUNT);
    dispatch(setHomeScreen(HomeScreen.Home));
};
export var navigateToLogin = function (dispatch, navigate) {
    dispatch(setScreen(SCREEN.LOGIN));
    navigate(PAGE.LOGIN);
};
export var getNextAutoPayAmount = function (productAccount, language) {
    var _a;
    var paymentPlan = (_a = productAccount.autopay) === null || _a === void 0 ? void 0 : _a.paymentPlan;
    if (!paymentPlan) {
        return '';
    }
    switch (paymentPlan.amountType) {
        case 'MINIMUM':
            return language.minPayment;
        case 'STATEMENT_BALANCE':
            return language.statementBalance;
        case 'CURRENT_BALANCE':
            return language.currentBalance;
        case 'CUSTOM':
        default:
            if (!paymentPlan.amount) {
                return '$0.00';
            }
            return paymentPlan.amount.displaySymbol + paymentPlan.amount.displayValue;
    }
};
export var getNextPaymentDueDate = function (productAccount, selectedLanguage) {
    var _a, _b, _c, _d, _e, _f;
    if (((_b = (_a = productAccount === null || productAccount === void 0 ? void 0 : productAccount.paymentDetails) === null || _a === void 0 ? void 0 : _a.dueDay) === null || _b === void 0 ? void 0 : _b.month) === undefined) {
        return '';
    }
    return (months(selectedLanguage)[((_d = (_c = productAccount === null || productAccount === void 0 ? void 0 : productAccount.paymentDetails) === null || _c === void 0 ? void 0 : _c.dueDay) === null || _d === void 0 ? void 0 : _d.month) - 1] +
        ' ' +
        ((_f = (_e = productAccount === null || productAccount === void 0 ? void 0 : productAccount.paymentDetails) === null || _e === void 0 ? void 0 : _e.dueDay) === null || _f === void 0 ? void 0 : _f.day));
};
export var checkBankAccountBroken = function (primaryPaymentAccount) {
    return (primaryPaymentAccount.status === PaymentAccountStatus.Apply ||
        primaryPaymentAccount.status === PaymentAccountStatus.ReviewManual ||
        primaryPaymentAccount.status === PaymentAccountStatus.ReviewAuto ||
        primaryPaymentAccount.status === PaymentAccountStatus.Repair);
};
export var generateSupportUrl = function (productUUID, currentLanguage) {
    if (!productUUID || !PRODUCTUUID_TO_MERCHANT[productUUID]) {
        return currentLanguage === LanguageCode.Spanish
            ? 'https://imprintpayments.zendesk.com/hc/es-us'
            : 'https://imprintpayments.zendesk.com/hc/en-us';
    }
    return MerchantInfos[PRODUCTUUID_TO_MERCHANT[productUUID]].zendesk[currentLanguage];
};
export var generatePayByCheckUrl = function (merchantName, currentLanguage) {
    var payByCheckLinkKey = merchantName + '/' + currentLanguage;
    if (payByCheckLinkKey in payByCheckLinkDict) {
        return payByCheckLinkDict[payByCheckLinkKey];
    }
    var supportLinkKeyDefault = 'default/' + currentLanguage;
    return payByCheckLinkDict[supportLinkKeyDefault];
};
export var getZeroAmount = function (baseAmount) {
    return __assign(__assign({}, baseAmount), { displayValue: '0.00', valueInMinUnit: 0 });
};
export function formatCardNumber(cardNumber) {
    return cardNumber.replace(/(.{4})/g, '$1 ').trim();
}
export function formatDate(month, year) {
    return "".concat(month.padStart(2, '0'), " / ").concat(year.padStart(2, '0'));
}
export function capNumber(num) {
    return Math.min(Math.max(num, 0), 1);
}
export var getAddressText = function (address) {
    if (!address) {
        return '';
    }
    return (address.streetLine1 +
        ', ' +
        (address.streetLine2 === '' ? '' : address.streetLine2 + ', ') +
        address.city +
        ', ' +
        address.state +
        ' ' +
        address.zipCode);
};
export var isDateExpired = function (encodedDateString) {
    var decodedDate = new Date(encodedDateString);
    var now = new Date();
    return now > decodedDate;
};
export var checkAccountOverdue = function (paymentDetails) {
    return paymentDetails.hasDue && paymentDetails.dueDayLabel === 'OverDue';
};
export var handleReApplicationCookie = function (token, deviceID, productUUID, isMultiCard) {
    var domain = window.location.hostname.toLowerCase();
    var domainParse = domain.split('.');
    if (domain === ENV.LOCAL) {
        saveCookie(COOKIEKEY.JWT, token);
        saveCookie(COOKIEKEY.DEVICEID, deviceID);
        saveCookie(COOKIEKEY.REAPPLICATION, 'true');
        saveCookie(COOKIEKEY.PRODUCTUUID, productUUID);
        saveCookie(COOKIEKEY.ISMULTICARD, isMultiCard);
    }
    else if (domainParse.includes(ENV.STG)) {
        saveCookie(COOKIEKEY.JWT, token, DOMAIN.STG);
        saveCookie(COOKIEKEY.DEVICEID, deviceID, DOMAIN.STG);
        saveCookie(COOKIEKEY.REAPPLICATION, 'true', DOMAIN.STG);
        saveCookie(COOKIEKEY.PRODUCTUUID, productUUID, DOMAIN.STG);
        saveCookie(COOKIEKEY.ISMULTICARD, isMultiCard, DOMAIN.STG);
    }
    else {
        saveCookie(COOKIEKEY.JWT, token, DOMAIN.PROD);
        saveCookie(COOKIEKEY.DEVICEID, deviceID, DOMAIN.PROD);
        saveCookie(COOKIEKEY.REAPPLICATION, 'true', DOMAIN.PROD);
        saveCookie(COOKIEKEY.PRODUCTUUID, productUUID, DOMAIN.PROD);
        saveCookie(COOKIEKEY.ISMULTICARD, isMultiCard, DOMAIN.PROD);
    }
};
export var redirectToSignUp = function (productUUID, newTab, phoneNumber) {
    if (newTab === void 0) { newTab = false; }
    var domain = window.location.hostname.toLowerCase();
    var domainParse = domain.split('.');
    var url = '';
    if (domain === ENV.LOCAL) {
        // TODO: for local test: set url as localhost port
        url = 'http://localhost:8080' + MerchantInfos[PRODUCTUUID_TO_MERCHANT[productUUID]].signUpLink;
    }
    else if (domainParse.includes(ENV.STG)) {
        url =
            'https://apply' + DOMAIN.STG + MerchantInfos[PRODUCTUUID_TO_MERCHANT[productUUID]].signUpLink;
    }
    else {
        url =
            'https://apply' +
                DOMAIN.PROD +
                MerchantInfos[PRODUCTUUID_TO_MERCHANT[productUUID]].signUpLink;
    }
    if (phoneNumber) {
        // Add phone number to the parameter if prefill is needed
        var separator = url.includes('?') ? '&' : '?';
        url += "".concat(separator, "phone=").concat(phoneNumber);
    }
    newTab ? window.open(url, '_blank') : window.location.replace(url);
};
export var saveRememberMe = function (_a) {
    var phoneNumber = _a.phoneNumber, deviceID = _a.deviceID;
    saveCookie(COOKIEKEY.PHONENUMBER, phoneNumber);
    saveCookie(COOKIEKEY.DEVICEID, deviceID);
    var oneWeekInMilliseconds = 7 * 24 * 60 * 60 * 1000;
    var expirationDate = new Date(Date.now() + oneWeekInMilliseconds).toUTCString();
    saveCookie(COOKIEKEY.DEVICEIDEXPIRATION, expirationDate);
};
export var getRememberMe = function () {
    var phoneNumber = getCookieValueFor(COOKIEKEY.PHONENUMBER);
    var deviceID = getCookieValueFor(COOKIEKEY.DEVICEID);
    var deviceIDExpiration = getCookieValueFor(COOKIEKEY.DEVICEIDEXPIRATION);
    return {
        phoneNumber: phoneNumber,
        deviceID: deviceID,
        deviceIDExpiration: deviceIDExpiration,
    };
};
export var clearRememberMe = function () {
    removeCookieFor(COOKIEKEY.PHONENUMBER);
    removeCookieFor(COOKIEKEY.DEVICEID);
    removeCookieFor(COOKIEKEY.DEVICEIDEXPIRATION);
};
export var userHasBankAccount = function (acctStatus) {
    return acctStatus !== PaymentAccountStatus.Apply;
};
