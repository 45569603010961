import { PAYMENT_STATUS, ActivityType } from "../../api/apiConstants";
import Refund from '../../assets/icons/Refund.svg';
import Shop from '../../assets/icons/Shop.svg';
import Fee from '../../assets/icons/Fee.svg';
import Interest from '../../assets/icons/Interest.svg';
import Payment from '../../assets/icons/Payment.svg';
import Reward from '../../assets/icons/Reward.svg';
import Credit from '../../assets/icons/Credit.svg';
import timer from '../../assets/icons/timer.svg';
import crossBadge from '../../assets/icons/CrossBadge.svg';
import exclamationBadge from '../../assets/icons/ExclamationBadge.svg';
import cancelBadge from '../../assets/icons/CancelBadge.svg';
// load icon based on activity type and status
export function loadIcon(type, logoIcon) {
    switch (type) {
        case ActivityType.auth:
        case ActivityType.transaction:
        case ActivityType.dispute:
            return Shop;
        case ActivityType.payment:
        case ActivityType.refund:
        case ActivityType.paymentCheck:
            return Payment;
        case ActivityType.reward:
        case ActivityType.rewardWithdrawalACH:
        case ActivityType.rewardWithdrawalCheck:
        case ActivityType.oneTime:
        case ActivityType.offer:
        case ActivityType.signup:
            return Reward;
        case ActivityType.fee:
        case ActivityType.rejectedPaymentFee:
        case ActivityType.latePaymentFee:
            return Fee;
        case ActivityType.interest:
        case ActivityType.interestNegativeAdjustment:
        case ActivityType.interestPositiveAdjustment:
        case ActivityType.interestCharge:
            return Interest;
        case ActivityType.credit:
            return Credit;
        default:
            return loadSecondaryIcon(logoIcon);
    }
}
;
export var ICON_TYPE;
(function (ICON_TYPE) {
    ICON_TYPE["SHOP"] = "SHOP";
    ICON_TYPE["RECEIPT"] = "RECEIPT";
    ICON_TYPE["INTEREST"] = "INTEREST";
    ICON_TYPE["FEE"] = "FEE";
    ICON_TYPE["REFUND"] = "REFUND";
    ICON_TYPE["CREDIT"] = "CREDIT";
    ICON_TYPE["REDEMPTION"] = "REDEMPTION";
})(ICON_TYPE || (ICON_TYPE = {}));
// load secondary icon based on fallback logoIcon
export function loadSecondaryIcon(iconType) {
    switch (iconType) {
        case ICON_TYPE.SHOP:
            return Shop;
        case ICON_TYPE.RECEIPT:
            return Payment;
        case ICON_TYPE.INTEREST:
            return Interest;
        case ICON_TYPE.FEE:
            return Fee;
        case ICON_TYPE.REFUND:
            return Refund;
        case ICON_TYPE.CREDIT:
            return Credit;
        case ICON_TYPE.REDEMPTION:
            return Reward;
        default:
            return null;
    }
}
export function loadBadge(type, status) {
    var BADGE_MAP = new Map([
        [
            ActivityType.auth,
            new Map([
                [PAYMENT_STATUS.rejected, crossBadge],
                [PAYMENT_STATUS.canceled, cancelBadge],
            ]),
        ],
        [
            ActivityType.transaction,
            new Map([
                [PAYMENT_STATUS.rejected, crossBadge],
                [PAYMENT_STATUS.canceled, cancelBadge],
            ]),
        ],
        [
            ActivityType.dispute,
            new Map([
                [PAYMENT_STATUS.pending, timer],
                [PAYMENT_STATUS.rejected, crossBadge],
                [PAYMENT_STATUS.canceled, cancelBadge],
                [PAYMENT_STATUS.confirmed, exclamationBadge],
                [PAYMENT_STATUS.scheduled, exclamationBadge],
            ]),
        ],
        [
            ActivityType.payment,
            new Map([
                [PAYMENT_STATUS.rejected, crossBadge],
                [PAYMENT_STATUS.canceled, cancelBadge],
            ]),
        ],
        [
            ActivityType.refund,
            new Map([
                [PAYMENT_STATUS.rejected, crossBadge],
                [PAYMENT_STATUS.canceled, cancelBadge],
            ]),
        ],
        [
            ActivityType.paymentCheck,
            new Map([
                [PAYMENT_STATUS.rejected, crossBadge],
                [PAYMENT_STATUS.canceled, cancelBadge],
            ]),
        ],
        [
            ActivityType.reward,
            new Map([
                [PAYMENT_STATUS.rejected, crossBadge],
                [PAYMENT_STATUS.canceled, cancelBadge],
            ]),
        ]
    ]);
    var transactionTypeMap = BADGE_MAP.get(type);
    if (transactionTypeMap) {
        return transactionTypeMap.get(status) || '';
    }
    return '';
}
