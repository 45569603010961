var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Icons, ImpContainer, ImpIcon, ImpInput, colors } from '@imprint-payments/imprint-ui';
import { useState } from 'react';
import { EVENT } from '../../analytics/analyticsConsts';
import { useAppSelector } from '../../app/hooks';
import { selectMerchantData } from '../../app/merchantDataSelectors';
import { ANALYTICS } from '../../firebase/firebase';
import { formatFullSSN } from '../../utils/formatUtils';
import { ValidationResponse, getShowError, getValidationString, validateFullSSN, } from '../../utils/validateUtils';
var SSN_MAX_LENGTH = 11;
export function SSNInput(_a) {
    var isDisabled = _a.isDisabled, onSSNChange = _a.onSSNChange;
    var language = useAppSelector(function (state) { return state.languageSelector.languageLocalization; });
    var merchantData = useAppSelector(selectMerchantData);
    var _b = useState(''), fullSsn = _b[0], setFullSsn = _b[1];
    var _c = useState(''), displayFullSsn = _c[0], setDisplayFullSsn = _c[1];
    var _d = useState(ValidationResponse.empty), fullSsnError = _d[0], setFullSsnError = _d[1];
    var formatMaskedSsn = function (ssn) {
        var filtered = ssn.replace(/[^\d]/g, '');
        if (filtered.length <= 3) {
            return 'X'.repeat(ssn.length);
        }
        if (filtered.length === 4) {
            return 'XXX-X';
        }
        if (filtered.length === 5) {
            return 'XXX-XX';
        }
        return 'XXX-XX-' + filtered.substring(5);
    };
    var clearError = function () {
        setFullSsnError(ValidationResponse.empty);
    };
    var handleFullSsnChange = function (e) {
        if (e.nativeEvent.inputType === 'deleteContentBackward') {
            var filteredFullSSN = fullSsn.replace(/[^\d]/g, '');
            var filteredVal = e.target.value.replace(/[^X\d]/g, '');
            var diff = filteredFullSSN.length - filteredVal.length;
            var newSsn_1 = filteredFullSSN.substring(0, filteredFullSSN.length - diff);
            if (newSsn_1.length < SSN_MAX_LENGTH)
                clearError();
            setFullSsn(formatFullSSN(newSsn_1));
            setDisplayFullSsn(formatMaskedSsn(newSsn_1));
            onSSNChange({ ssn: newSsn_1, isValid: false });
            return;
        }
        var inputValue = e.target.value;
        var lastChar = inputValue[inputValue.length - 1];
        var newSsn = lastChar.match(/[0-9]/) ? fullSsn + lastChar : fullSsn;
        setFullSsn(formatFullSSN(newSsn));
        setDisplayFullSsn(formatMaskedSsn(newSsn));
        if (newSsn.length === SSN_MAX_LENGTH) {
            ANALYTICS.logEvent(EVENT.FIND_ACCOUNT_SSN_FILLED_OUT, {
                product_uuid: merchantData.productUUID,
            });
            var ssnError = validateFullSSN(newSsn);
            setFullSsnError(ssnError);
            var isValid = !getShowError(ssnError);
            onSSNChange({ ssn: newSsn, isValid: isValid });
        }
    };
    var handleSsn9KeyDown = function (e) {
        var allowedKeys = ['Tab', 'ArrowLeft', 'ArrowRight', 'Backspace', 'Delete'];
        var isSelect = (e.ctrlKey || e.metaKey) && 'a' === e.key.toLowerCase();
        if (!allowedKeys.includes(e.key) && isNaN(parseInt(e.key)) && !isSelect) {
            e.preventDefault();
        }
    };
    return (_jsx(ImpContainer, __assign({ width: "100%" }, { children: _jsx(ImpInput, { label: language.socialSecurityNumber, isDisabled: isDisabled, showError: getShowError(fullSsnError), errorMessage: getValidationString(fullSsnError, language), value: displayFullSsn, onChange: handleFullSsnChange, maxLength: SSN_MAX_LENGTH, onKeyDown: function (e) { return handleSsn9KeyDown(e); }, type: "tel", trailingIcon: _jsx(ImpIcon, { iconSrc: Icons.lock, stroke: colors.content.onBackgroundVariant }) }) })));
}
