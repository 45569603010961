var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import styles from './Button.module.css';
import { KEYTYPE } from '../../utils/keyConstants';
export var ButtonColorScheme;
(function (ButtonColorScheme) {
    ButtonColorScheme["fillBranded"] = "fillBranded";
    ButtonColorScheme["fillImprint"] = "fillImprint";
    ButtonColorScheme["ghostBranded"] = "ghostBranded";
    ButtonColorScheme["ghostImprint"] = "ghostImprint";
    ButtonColorScheme["emptyBranded"] = "emptyBranded";
    ButtonColorScheme["emptyImprint"] = "emptyImprint";
    ButtonColorScheme["ghostWhite"] = "ghostWhite";
})(ButtonColorScheme || (ButtonColorScheme = {}));
export var ButtonSize;
(function (ButtonSize) {
    ButtonSize["small"] = "small";
    ButtonSize["xsmall"] = "xsmall";
    ButtonSize["default"] = "default";
})(ButtonSize || (ButtonSize = {}));
export default function Button(_a) {
    var value = _a.value, pill = _a.pill, empty = _a.empty, borderless = _a.borderless, icon = _a.icon, disabled = _a.disabled, hideOnMobile = _a.hideOnMobile, loading = _a.loading, handleClick = _a.handleClick, colorScheme = _a.colorScheme, size = _a.size;
    useEffect(function () { }, [icon, disabled]);
    var getClasses = function () {
        var classList = [''];
        if (disabled) {
            colorScheme === ButtonColorScheme.ghostImprint ||
                colorScheme === ButtonColorScheme.ghostWhite ||
                colorScheme === ButtonColorScheme.ghostBranded
                ? classList.push(styles.disabledGhost)
                : classList.push(styles.disabled);
        }
        if (pill) {
            classList.push(styles.pill);
        }
        if (empty) {
            classList.push(styles.empty);
        }
        if (borderless) {
            classList.push(styles.borderless);
        }
        if (hideOnMobile) {
            classList.push(styles.hideOnMobile);
        }
        // prettier-ignore
        if (colorScheme) {
            switch (colorScheme) {
                case ButtonColorScheme.fillBranded:
                    classList.push(styles.fillBranded);
                    break;
                case ButtonColorScheme.fillImprint:
                    classList.push(styles.fillImprint);
                    break;
                case ButtonColorScheme.ghostBranded:
                    classList.push(styles.ghostBranded);
                    break;
                case ButtonColorScheme.ghostImprint:
                    classList.push(styles.ghostImprint);
                    break;
                case ButtonColorScheme.emptyBranded:
                    classList.push(styles.emptyBranded);
                    break;
                case ButtonColorScheme.ghostWhite:
                    classList.push(styles.ghostWhite);
                    break;
                case ButtonColorScheme.emptyImprint:
                default:
                    classList.push(styles.emptyImprint);
                    break;
            }
        }
        if (size) {
            switch (size) {
                case ButtonSize.small:
                    classList.push(styles.small);
                    break;
                case ButtonSize.xsmall:
                    classList.push(styles.xsmall);
                    break;
                case ButtonSize.default:
                default:
                    break;
            }
        }
        return classList.join(' ');
    };
    var isLoadingCompatibleStyle = getClasses().includes(styles.pill) ||
        colorScheme === ButtonColorScheme.fillImprint ||
        colorScheme === ButtonColorScheme.ghostImprint;
    return (_jsxs("div", __assign({ className: styles.svcButtonContainer }, { children: [_jsx("button", __assign({ className: getClasses(), onClick: !disabled && !loading ? handleClick : undefined, onKeyDown: function (e) {
                    if (e.key.toUpperCase() === KEYTYPE.ENTER && !disabled && !loading) {
                        handleClick();
                    }
                }, tabIndex: 0, "aria-label": disabled ? 'disabled' + value : value }, { children: icon !== undefined ? (_jsxs(_Fragment, { children: [_jsx("img", { className: styles.icon, src: icon }), " ", !loading ? value : ''] })) : (_jsx(_Fragment, { children: !loading ? value : '' })) })), isLoadingCompatibleStyle && !disabled && loading && (_jsxs("div", __assign({ className: "".concat(styles.spinner, " ").concat(colorScheme === ButtonColorScheme.ghostImprint
                    ? styles.spinnerReverseColor
                    : styles.spinnerColor) }, { children: [_jsx("div", {}), _jsx("div", {}), _jsx("div", {}), _jsx("div", {}), _jsx("div", {}), _jsx("div", {}), _jsx("div", {}), _jsx("div", {}), _jsx("div", {}), _jsx("div", {}), _jsx("div", {}), _jsx("div", {})] })))] })));
}
