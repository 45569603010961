var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { ImpDivider, ImpSpacer, ImpText, colors } from '@imprint-payments/imprint-ui';
import { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { EVENT } from '../../analytics/analyticsConsts';
import { ActivityType, PAYMENT_STATUS } from '../../api/apiConstants';
import { setIsFlyoutVisible } from '../../app/flyoutSlice';
import { setStartedBankLinking } from '../../app/homeSlice';
import { useAppSelector } from '../../app/hooks';
import { SCREEN, setScreen } from '../../app/screenStateSlice';
import { store } from '../../app/store';
import RewardSmall from '../../assets/icons/RewardSmall.svg';
import { ANALYTICS } from '../../firebase/firebase';
import { convertAmountToString, epochSecToESTDate, epochSecToESTTimeString, formatDollarStringToStringAbsolute, formatToSnakeCase, inputToUSD, snakeToTitleCase, } from '../../utils/formatUtils';
import { checkBankAccountBroken } from '../../utils/functionUtils';
import { PAGE } from '../../utils/routeConstant';
import { loadIcon } from '../activityFeed/activityFeedConsts';
import Button, { ButtonColorScheme } from '../button/Button';
import { ITEM_STYLE, LIST_STYLE, ListSection } from '../list/List';
import styles from './ActivityDetails.module.css';
export var DETAILS_TYPE;
(function (DETAILS_TYPE) {
    DETAILS_TYPE[DETAILS_TYPE["TRANSACTION"] = 0] = "TRANSACTION";
    DETAILS_TYPE[DETAILS_TYPE["PAYMENT"] = 1] = "PAYMENT";
})(DETAILS_TYPE || (DETAILS_TYPE = {}));
var strikeThroughList = [PAYMENT_STATUS.rejected, PAYMENT_STATUS.canceled];
export var CONTENT_POSITIVE = 'CONTENT_POSITIVE';
export default function ActivityDetails(_a) {
    var _b, _c, _d;
    var activity = _a.activity;
    var language = useAppSelector(function (state) { return state.languageSelector.languageLocalization; });
    var dispatch = useDispatch();
    var outerElementRef = useRef(null);
    var isOneTimeReward = activity.type === ActivityType.oneTime ||
        activity.type === ActivityType.offer ||
        activity.type === ActivityType.signup;
    var shouldShowDescription = (activity.type === ActivityType.reward ||
        activity.type === ActivityType.auth ||
        activity.type === ActivityType.transaction ||
        (isOneTimeReward && activity.status === PAYMENT_STATUS.confirmed)) &&
        ((_c = (_b = activity.details) === null || _b === void 0 ? void 0 : _b.description) === null || _c === void 0 ? void 0 : _c.content);
    function getAmountString(activity) {
        var _a, _b, _c;
        var amount = (_c = (_b = (_a = activity.details) === null || _a === void 0 ? void 0 : _a.heading) === null || _b === void 0 ? void 0 : _b.amount) !== null && _c !== void 0 ? _c : activity.amount;
        var isStrikethrough = activity.type === ActivityType.payment && strikeThroughList.includes(activity.status);
        return (_jsxs("div", __assign({ className: styles.amount }, { children: [_jsxs("sup", __assign({ className: styles.superScript }, { children: [amount.negative && '-', amount.displaySymbol] })), _jsx("span", __assign({ className: isStrikethrough ? styles.amtStrikethrough : "" }, { children: amount.displayUnit === 'pts'
                        ? convertAmountToString(amount)
                        : inputToUSD(formatDollarStringToStringAbsolute(convertAmountToString(amount)), true) }))] })));
    }
    function getAmtSubheadings(str1, str2, str3) {
        return (_jsxs("div", __assign({ className: styles.amtSubheadings }, { children: [_jsx("div", __assign({ className: styles.headerString }, { children: str1 })), _jsxs("div", __assign({ className: styles.amtSubheading2 }, { children: [snakeToTitleCase(str2), " ", str3 && _jsxs(_Fragment, { children: ["\u2022 ", snakeToTitleCase(str3)] })] }))] })));
    }
    function getHeadingObject() {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        return (_jsxs("div", __assign({ className: styles.headerContainer }, { children: [_jsxs("div", __assign({ className: styles.header }, { children: [_jsx("img", { className: styles.leadingIconActivity, src: loadIcon(activity.type, (_a = activity.assets) === null || _a === void 0 ? void 0 : _a.iconType) || '', alt: 'leading icon', tabIndex: 0 }), ((_b = activity.details) === null || _b === void 0 ? void 0 : _b.heading) && (_jsxs("div", __assign({ className: styles.amountContent }, { children: [getAmountString(activity), getAmtSubheadings((_d = (_c = activity === null || activity === void 0 ? void 0 : activity.details) === null || _c === void 0 ? void 0 : _c.heading) === null || _d === void 0 ? void 0 : _d.header, (_f = (_e = activity === null || activity === void 0 ? void 0 : activity.details) === null || _e === void 0 ? void 0 : _e.heading) === null || _f === void 0 ? void 0 : _f.subheader1, (_h = (_g = activity === null || activity === void 0 ? void 0 : activity.details) === null || _g === void 0 ? void 0 : _g.heading) === null || _h === void 0 ? void 0 : _h.subheader2)] }))), !((_j = activity.details) === null || _j === void 0 ? void 0 : _j.heading) && activity.type === ActivityType.payment && (_jsxs("div", __assign({ className: styles.amountContent }, { children: [getAmountString(activity), getAmtSubheadings(activity === null || activity === void 0 ? void 0 : activity.header, activity === null || activity === void 0 ? void 0 : activity.subheader1, activity === null || activity === void 0 ? void 0 : activity.subheader2)] })))] })), getHighlightObject()] })));
    }
    function getHighlightObject() {
        var _a, _b, _c, _d, _e, _f;
        // reward should not have highlight section
        if (activity.type === ActivityType.reward || isOneTimeReward) {
            return _jsx(_Fragment, {});
        }
        if ((_a = activity.details) === null || _a === void 0 ? void 0 : _a.highlight) {
            if (((_b = activity.details.highlight) === null || _b === void 0 ? void 0 : _b.contentColor) === CONTENT_POSITIVE) {
                return (_jsxs("div", __assign({ className: styles.highlightPositive }, { children: [_jsx("div", { children: (_c = activity.details.highlight) === null || _c === void 0 ? void 0 : _c.body }), _jsx("div", __assign({ className: styles.rightAlign }, { children: convertAmountToString((_d = activity.details.highlight) === null || _d === void 0 ? void 0 : _d.amount) }))] })));
            }
            else {
                return (_jsxs("div", __assign({ className: styles.highlightNegative }, { children: [_jsx("div", { children: (_e = activity.details.highlight) === null || _e === void 0 ? void 0 : _e.body }), _jsx("div", __assign({ className: styles.rightAlign }, { children: convertAmountToString((_f = activity.details.highlight) === null || _f === void 0 ? void 0 : _f.amount) }))] })));
            }
        }
    }
    var getOtherItems = function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r;
        var items = [];
        if ((_b = (_a = activity === null || activity === void 0 ? void 0 : activity.details) === null || _a === void 0 ? void 0 : _a.other) === null || _b === void 0 ? void 0 : _b.location) {
            items.push({
                title: language.location,
                style: ITEM_STYLE.DEFAULT,
                trailingTitle: (_d = (_c = activity === null || activity === void 0 ? void 0 : activity.details) === null || _c === void 0 ? void 0 : _c.other) === null || _d === void 0 ? void 0 : _d.location,
            });
        }
        if ((_f = (_e = activity === null || activity === void 0 ? void 0 : activity.details) === null || _e === void 0 ? void 0 : _e.other) === null || _f === void 0 ? void 0 : _f.category) {
            items.push({
                title: language.category,
                style: ITEM_STYLE.DEFAULT,
                trailingTitle: snakeToTitleCase((_h = (_g = activity === null || activity === void 0 ? void 0 : activity.details) === null || _g === void 0 ? void 0 : _g.other) === null || _h === void 0 ? void 0 : _h.category),
            });
        }
        if ((_k = (_j = activity.details) === null || _j === void 0 ? void 0 : _j.other) === null || _k === void 0 ? void 0 : _k.spendType) {
            items.push({
                title: language.type,
                style: ITEM_STYLE.DEFAULT,
                trailingTitle: (_m = (_l = activity === null || activity === void 0 ? void 0 : activity.details) === null || _l === void 0 ? void 0 : _l.other) === null || _m === void 0 ? void 0 : _m.spendType,
            });
        }
        if ((_p = (_o = activity === null || activity === void 0 ? void 0 : activity.details) === null || _o === void 0 ? void 0 : _o.other) === null || _p === void 0 ? void 0 : _p.customerName) {
            items.push({
                title: language.cardUser,
                style: ITEM_STYLE.DEFAULT,
                trailingTitle: (_r = (_q = activity === null || activity === void 0 ? void 0 : activity.details) === null || _q === void 0 ? void 0 : _q.other) === null || _r === void 0 ? void 0 : _r.customerName,
            });
        }
        return items;
    };
    function getOtherTable() {
        // do not show other table for one-time-reward
        if (isOneTimeReward) {
            return _jsx(_Fragment, {});
        }
        return (_jsx("div", __assign({ className: styles.listContainer }, { children: _jsx(ListSection, { style: LIST_STYLE.ACTIVITY_ITEM, items: getOtherItems(), header: language.otherInfo, noBorder: true }) })));
    }
    function getPaymentSummaryItems() {
        var _a;
        var items = [];
        if (activity === null || activity === void 0 ? void 0 : activity.amount) {
            items.push({
                title: language.paymentAmount,
                style: ITEM_STYLE.DEFAULT,
                trailingTitle: convertAmountToString(activity === null || activity === void 0 ? void 0 : activity.amount),
            });
        }
        if (activity === null || activity === void 0 ? void 0 : activity.subheader1) {
            items.push({
                title: language.paymentDate,
                style: ITEM_STYLE.DEFAULT,
                trailingTitle: activity === null || activity === void 0 ? void 0 : activity.subheader1,
            });
        }
        if (activity === null || activity === void 0 ? void 0 : activity.paymentAccount) {
            items.push({
                title: language.paymentMethod,
                style: ITEM_STYLE.DEFAULT,
                trailingTitle: "".concat(language.account, " \u2022\u2022\u2022\u2022").concat((_a = activity === null || activity === void 0 ? void 0 : activity.paymentAccount) === null || _a === void 0 ? void 0 : _a.accountNumberLast4),
            });
        }
        return items;
    }
    function getPaymentSummary() {
        return (_jsx("div", __assign({ className: styles.listContainer }, { children: _jsx(ListSection, { style: LIST_STYLE.ACTIVITY_ITEM, items: getPaymentSummaryItems(), header: language.summary, noBorder: true }) })));
    }
    function getDetails() {
        if (!shouldShowDescription) {
            return _jsx(_Fragment, {});
        }
        return (_jsxs(_Fragment, { children: [_jsx(ImpDivider, { color: colors.border.borderVariant }), _jsxs("div", __assign({ className: styles.rewardContainer }, { children: [_jsx("img", { alt: "question mark", src: RewardSmall }), _jsx(ImpSpacer, { height: 's' }), _jsx(ImpText, __assign({ typography: "body2", color: colors.content.onBackgroundVariant }, { children: activity.details.description.content }))] }))] }));
    }
    return (_jsxs("div", __assign({ className: styles.activityDetailsContainer }, { children: [_jsxs("div", __assign({ className: styles.leading }, { children: [getHeadingObject(), (activity === null || activity === void 0 ? void 0 : activity.type) === ActivityType.payment && getPaymentSummary(), ((_d = activity === null || activity === void 0 ? void 0 : activity.details) === null || _d === void 0 ? void 0 : _d.other) && getOtherTable(), getDetails()] })), (activity === null || activity === void 0 ? void 0 : activity.type) == ActivityType.payment && _jsx(ActivityFooter, { activity: activity })] })));
}
function ActivityFooter(_a) {
    var activity = _a.activity;
    var navigate = useNavigate();
    var dispatch = useDispatch();
    var language = useAppSelector(function (state) { return state.languageSelector.languageLocalization; });
    var primaryPaymentAccount = useAppSelector(function (state) { return state.homeSelector.primaryPaymentAccount; });
    function handleFooterButtonClick(buttonName) {
        var buttonNameFormatted = formatToSnakeCase(buttonName);
        ANALYTICS.logEvent("".concat(EVENT.ACTIVITY_DETAILS, "_").concat(buttonNameFormatted, "_").concat(EVENT.CLICKED), {
            type: activity.type,
            status: activity.status,
            transactionID: activity.transactionUUID,
            consumerUUID: store.getState().consumerInfoSelector.consumerUUID,
            productUUID: store.getState().productAccountSelector.productDetails.productUUID,
        });
        dispatch(setIsFlyoutVisible(false));
        if (checkBankAccountBroken(primaryPaymentAccount)) {
            dispatch(setStartedBankLinking(true));
            dispatch(setScreen(SCREEN.BANKING));
            navigate(PAGE.BANKING);
        }
        else {
            dispatch(setScreen(SCREEN.PAYMENTS));
            navigate(PAGE.PAYMENTS);
        }
    }
    return (_jsxs("div", __assign({ className: styles.footerContainer }, { children: [activity.status == PAYMENT_STATUS.scheduled && (_jsxs("div", __assign({ className: styles.footerScheduled }, { children: [(activity === null || activity === void 0 ? void 0 : activity.editTimeToEpochSec) && (_jsx("div", __assign({ className: styles.footerNotice }, { children: language.paymentEditOrCancelDisclosure
                            .replace('{0}', epochSecToESTTimeString(activity === null || activity === void 0 ? void 0 : activity.editTimeToEpochSec))
                            .replace('{1}', epochSecToESTDate(activity === null || activity === void 0 ? void 0 : activity.editTimeToEpochSec, language.selected)) }))), _jsxs("div", __assign({ className: styles.buttonsContainer }, { children: [_jsx(Button, { value: language.editPayment, colorScheme: ButtonColorScheme.fillBranded, handleClick: function () { return handleFooterButtonClick(language.editPayment); } }), _jsx(Button, { value: language.cancelPayment, colorScheme: ButtonColorScheme.ghostBranded, handleClick: function () { return handleFooterButtonClick(language.cancelPayment); } })] }))] }))), activity.status == PAYMENT_STATUS.rejected && (_jsx("div", __assign({ className: styles.footerScheduled }, { children: _jsx("div", __assign({ className: styles.singleButtonContainer }, { children: _jsx(Button, { value: language.editPayment, colorScheme: ButtonColorScheme.fillBranded, handleClick: function () { return handleFooterButtonClick(language.editPayment); } }) })) })))] })));
}
