var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { PKG } from '../../analytics/analyticsConsts';
import { store } from '../../app/store';
import { ANALYTICS } from '../../firebase/firebase';
import { emptyApiResponse } from '../../types/Api';
import { getLanguageFromCookie } from '../../utils/functionUtils';
import { getSignature } from '../../utils/headers';
import { getBaseUrl, getDeviceID } from './helpers';
import { HEADERS, PLATFORM, RequestType, ResponseCode } from './httpConsts';
export var generateHeaders = function (state, req, url) {
    var _a;
    var parse_url = new URL(url);
    var path = parse_url.pathname;
    var getSignatureParams = {
        path: path,
        state: state,
        body: req.body,
    };
    var signature = getSignature(getSignatureParams);
    switch (req.config.type) {
        case RequestType.EXTERNAL:
            return {};
        case RequestType.INTERNAL:
            return _a = {},
                _a[HEADERS.signature] = signature,
                _a[HEADERS.device] = getDeviceID(state, store.dispatch),
                _a[HEADERS.language] = getLanguageFromCookie(),
                _a[HEADERS.token] = store.getState().authSlice.token || '',
                _a[HEADERS.platform] = PLATFORM,
                _a;
        default:
            ANALYTICS.funcError(PKG, 'Invalid request type', 'httpRequest');
            return {};
    }
};
export var httpRequest = function (req) { return __awaiter(void 0, void 0, void 0, function () {
    var retryAttempts, resp, baseUrl, urlSuffix, url, generatedHeaders, res, _a, _b, _c, _d, error_1, code;
    var _e, _f;
    return __generator(this, function (_g) {
        switch (_g.label) {
            case 0:
                if (!req.config.method) {
                    ANALYTICS.funcError(PKG, 'Invalid request method', 'httpRequest');
                    throw new Error('Invalid request method');
                }
                retryAttempts = 0;
                resp = emptyApiResponse();
                baseUrl = req.config.baseUrl || '';
                urlSuffix = req.urlSuffix || '';
                url = "".concat(getBaseUrl(store, req.config.type)).concat(baseUrl).concat(urlSuffix);
                generatedHeaders = generateHeaders(store.getState(), req, url);
                _g.label = 1;
            case 1:
                if (!(retryAttempts < req.config.retryCount)) return [3 /*break*/, 12];
                _g.label = 2;
            case 2:
                _g.trys.push([2, 10, , 11]);
                return [4 /*yield*/, fetch(url, {
                        headers: generatedHeaders,
                        method: req.config.method,
                        keepalive: req.config.keepAlive,
                        body: JSON.stringify(req.body),
                    })];
            case 3:
                res = _g.sent();
                _e = {
                    status: res.status,
                    message: res.statusText
                };
                _f = {
                    headers: res.headers
                };
                if (!req.config.image) return [3 /*break*/, 5];
                _c = (_b = URL).createObjectURL;
                return [4 /*yield*/, res.blob()];
            case 4:
                _a = _c.apply(_b, [_g.sent()]);
                return [3 /*break*/, 9];
            case 5:
                if (!(res.status !== ResponseCode.NO_CONTENT)) return [3 /*break*/, 7];
                return [4 /*yield*/, res.json()];
            case 6:
                _d = _g.sent();
                return [3 /*break*/, 8];
            case 7:
                _d = undefined;
                _g.label = 8;
            case 8:
                _a = _d;
                _g.label = 9;
            case 9: return [2 /*return*/, (_e.data = (_f.body = _a,
                    _f),
                    _e)];
            case 10:
                error_1 = _g.sent();
                retryAttempts++;
                resp = emptyApiResponse();
                return [3 /*break*/, 11];
            case 11: return [3 /*break*/, 1];
            case 12:
                code = resp.status;
                if (code === ResponseCode.HTTP_ERROR) {
                    ANALYTICS.funcError(PKG, 'httpRequest request failed after all retries', req.config.baseUrl || 'no base url');
                }
                return [2 /*return*/, resp];
        }
    });
}); };
