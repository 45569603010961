var _a;
import { createSlice } from '@reduxjs/toolkit';
var initialState = {
    consumerProfile: {
        name: {
            firstName: '',
            lastName: '',
        },
        phone: '',
        email: '',
        address: {
            streetLine1: '',
            streetLine2: '',
            city: '',
            state: '',
            country: '',
            zipCode: '',
        },
        dob: {
            year: 0,
            month: 0,
            day: 0,
        },
    },
    consumerFactor: {
        income: '',
        incomeType: '',
        housingType: '',
        housingCost: '',
    },
    hasSpendCardOnly: false,
    cardOffer: {},
    last4: '',
    emailVerificationFailed: false,
    rewardsInfo: {
        email: '',
    },
    consumerUUID: '',
    actions: [
        {
            type: '',
            params: {
                applicationUUID: '',
                productUUID: '',
            },
        },
    ],
    notAcceptedOffer: false,
    multiCardUser: false,
};
var consumerInfoSlice = createSlice({
    name: 'consumerInfo',
    initialState: initialState,
    reducers: {
        setConsumerProfile: function (state, action) {
            state.consumerProfile = action.payload;
        },
        setConsumerFactor: function (state, action) {
            state.consumerFactor = action.payload;
        },
        setHasSpendCardOnly: function (state, action) {
            state.hasSpendCardOnly = action.payload;
        },
        setCardOffer: function (state, action) {
            state.cardOffer = action.payload;
        },
        setLast4: function (state, action) {
            state.last4 = action.payload;
        },
        resetConsumerInfoSlice: function () { return initialState; },
        setHHEmailVerification: function (state, action) {
            state.emailVerificationFailed = action.payload;
        },
        setRewardsEmail: function (state, action) {
            state.rewardsInfo.email = action.payload;
        },
        setConsumerUUID: function (state, action) {
            state.consumerUUID = action.payload;
        },
        setActions: function (state, action) {
            state.actions = action.payload;
        },
        resetActions: function (state) {
            state.actions = initialState.actions;
        },
        setNotAcceptedOffer: function (state, action) {
            state.notAcceptedOffer = action.payload;
        },
        setMultiCardUser: function (state, action) {
            state.multiCardUser = action.payload;
        },
    },
});
export var setConsumerProfile = (_a = consumerInfoSlice.actions, _a.setConsumerProfile), setConsumerFactor = _a.setConsumerFactor, setHasSpendCardOnly = _a.setHasSpendCardOnly, setCardOffer = _a.setCardOffer, setLast4 = _a.setLast4, setHHEmailVerification = _a.setHHEmailVerification, setRewardsEmail = _a.setRewardsEmail, resetConsumerInfoSlice = _a.resetConsumerInfoSlice, setConsumerUUID = _a.setConsumerUUID, setNotAcceptedOffer = _a.setNotAcceptedOffer, setActions = _a.setActions, resetActions = _a.resetActions, setMultiCardUser = _a.setMultiCardUser;
export default consumerInfoSlice.reducer;
