var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ImpButton, ImpContainer, ImpFlex, ImpOtpField, ImpSpacer, ImpText, colors, } from '@imprint-payments/imprint-ui';
import { useState } from 'react';
import { PINContext, verifyPin } from '../../api/verifyPin.api';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { SCREEN, ScreenStateContext, setScreen, setScreenContext, } from '../../app/screenStateSlice';
import { logOutUser } from '../../utils/functionUtils';
export default function VerifyPinPage(_a) {
    var handleSuccess = _a.handleSuccess, handleGoBack = _a.handleGoBack, setPin = _a.setPin;
    var _b = useState(''), input = _b[0], setInput = _b[1];
    var _c = useState(false), isLoading = _c[0], setIsLoading = _c[1];
    var _d = useState(false), incorrectDigitsError = _d[0], setIncorrectDigitsError = _d[1];
    var _e = useState(false), requestFailed = _e[0], setRequestFailed = _e[1];
    var dispatch = useAppDispatch();
    var language = useAppSelector(function (state) { return state.languageSelector.languageLocalization; });
    var homeState = useAppSelector(function (state) { return state.homeSelector; });
    var featureFlags = useAppSelector(function (state) { return state.featureFlagSelector.featureFlags; });
    var isPinLockOutEnabled = featureFlags.pinLockOutEnabled === 'true';
    function handleChange(s) {
        if (input != '') {
            setRequestFailed(false);
            setIncorrectDigitsError(false);
        }
        setInput(s);
    }
    function handleIncorrectResponse() {
        setIsLoading(false);
        setIncorrectDigitsError(true);
    }
    function handleError() {
        setIsLoading(false);
        setRequestFailed(true);
    }
    function handleRejection() {
        setIsLoading(false);
        logOutUser(dispatch).then(function () {
            dispatch(setScreen(SCREEN.VERIFICATION_FAILED));
            dispatch(setScreenContext(ScreenStateContext.FailedCardDetailsPIN));
        });
    }
    var handleFull = function (s) {
        setPin === null || setPin === void 0 ? void 0 : setPin(s);
        setIsLoading(true);
        dispatch(verifyPin(s, PINContext.AuthorizedUsers, handleSuccess, handleIncorrectResponse, handleError, handleRejection));
    };
    return (_jsxs(ImpContainer, __assign({ textAlign: "center", width: "100%" }, { children: [_jsx(ImpText, __assign({ typography: "headline2" }, { children: language.enterYourPin })), _jsx(ImpSpacer, { height: "xl" }), _jsx(ImpFlex, __assign({ direction: "row", justify: "center" }, { children: _jsx(ImpContainer, __assign({ width: "min(393px, 100% - 32px)" }, { children: _jsx(ImpOtpField, { autoFocus: true, numInputs: 4, onChange: handleChange, onFull: handleFull, clearWithError: incorrectDigitsError || requestFailed, mask: true }) })) })), _jsx(ImpSpacer, { height: "xl" }), incorrectDigitsError && (_jsx(ImpText, __assign({ typography: 'body2', color: colors.content.onBackgroundError }, { children: isPinLockOutEnabled && homeState.homeFlow.pinLockOutMessage
                    ? homeState.homeFlow.pinLockOutMessage
                    : language.incorrectPIN }))), requestFailed && (_jsx(ImpText, __assign({ typography: 'body2', color: colors.content.onBackgroundError }, { children: language.somethingWentWrong }))), handleGoBack && (_jsxs(_Fragment, { children: [_jsx(ImpSpacer, { height: "2xl" }), _jsx(ImpContainer, { children: _jsxs(ImpFlex, __assign({ direction: "column", align: "center", fluid: true }, { children: [_jsx(ImpSpacer, { height: "xl" }), _jsx(ImpButton, __assign({ variant: "text", onClick: handleGoBack }, { children: language.goBackBtn })), _jsx(ImpSpacer, { height: "xl" })] })) })] }))] })));
}
