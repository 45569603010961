import { jsx as _jsx } from "react/jsx-runtime";
/* tslint:disable:no-implicit-any */
import { Icons, ImpIcon } from '@imprint-payments/imprint-ui';
import { createSelector } from '@reduxjs/toolkit';
import { pascalCase } from '../utils/formatUtils';
var months = function (language) {
    return language === 'es'
        ? [
            'ene.',
            'feb.',
            'mar.',
            'abr.',
            'may.',
            'jun.',
            'jul.',
            'ago.',
            'sep.',
            'oct.',
            'nov.',
            'dic.',
        ]
        : ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
};
var selectStatements = function (state) { return state.statementsSelector.statements; };
var selectedLanguage = function (state) { return state.languageSelector.languageCode; };
export var selectFormattedStatements = createSelector([selectStatements, selectedLanguage], function (statements, language) {
    return statements
        .slice()
        .sort(function (a, b) {
        if (a.startDate.year !== b.startDate.year) {
            return b.startDate.year - a.startDate.year;
        }
        if (a.startDate.month !== b.startDate.month) {
            return b.startDate.month - a.startDate.month;
        }
        return b.startDate.day - a.startDate.day;
    })
        .map(function (st) {
        if (st.productType.toLocaleUpperCase() === 'CREDITCARD') {
            return {
                subtitle: "".concat(pascalCase(months(language)[st.startDate.month - 1]), " ").concat(st.startDate.day, " - ").concat(months(language)[st.endDate.month - 1], " ").concat(st.endDate.day),
                link: st.fileURL,
            };
        }
        return {
            subtitle: '',
            link: '',
        };
    });
});
var createDate = function (dateParts) {
    return new Date(dateParts.year, dateParts.month - 1, dateParts.day);
};
export var selectStatementsList = createSelector([selectStatements, selectedLanguage], function (statements, language) {
    return statements
        .slice()
        .sort(function (a, b) {
        var aDate = createDate(a.startDate);
        var bDate = createDate(b.startDate);
        return bDate.getTime() - aDate.getTime();
    })
        .filter(function (st) {
        return st.productType.toLocaleUpperCase() === 'CREDITCARD';
    })
        .map(function (st) {
        var options = {
            month: 'short',
            day: 'numeric',
        };
        var startDate = createDate(st.startDate).toLocaleDateString(language, options);
        var endDate = createDate(st.endDate).toLocaleDateString(language, options);
        return {
            title: "".concat(startDate, " - ").concat(endDate),
            subtitle: st.startDate.year,
            onClick: function () {
                window.open(st.fileURL, '_blank');
            },
            trailingContent: _jsx(ImpIcon, { iconSrc: Icons.chevronRight }),
        };
    });
});
export var selectStatementMedium = function (state) { return state.statementsSelector.statementMedium; };
