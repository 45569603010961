// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* TODO: this should be removed when the button selector in Button.module.css is removed */\n.fzu1eOD3KPwv8x5yRxdT button {\n  height: auto;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/redeemRewards/ConfigureRedemptionPage.module.css"],"names":[],"mappings":"AAAA,0FAA0F;AAC1F;EACE,YAAY;AACd","sourcesContent":["/* TODO: this should be removed when the button selector in Button.module.css is removed */\n.rewardsButton button {\n  height: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rewardsButton": "fzu1eOD3KPwv8x5yRxdT"
};
module.exports = ___CSS_LOADER_EXPORT___;
