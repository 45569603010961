export var getEmptyCard = function () {
    return ({
        cardUUID: '',
        issuerName: '',
        issuerCardID: '',
        status: '',
        last4: '',
        pan: '',
        cvv: '',
        expiryMonth: '',
        expiryYear: '',
    });
};
