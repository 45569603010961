export var DOMAIN;
(function (DOMAIN) {
    DOMAIN["PROD"] = ".imprint.co";
    DOMAIN["STG"] = ".stg.imprintapi.co";
    DOMAIN["PREPRD"] = ".preprd.imprintapi.co";
})(DOMAIN || (DOMAIN = {}));
export var COOKIEKEY;
(function (COOKIEKEY) {
    COOKIEKEY["JWT"] = "jwt";
    COOKIEKEY["SHOWEDU"] = "showEDU";
    COOKIEKEY["PHONENUMBER"] = "phoneNumber";
    COOKIEKEY["DEVICEID"] = "deviceID";
    COOKIEKEY["DEVICEIDEXPIRATION"] = "deviceIDExpiration";
    COOKIEKEY["CONSUMERUUID"] = "consumerUUID";
    COOKIEKEY["REDIRECTED"] = "redirected";
    COOKIEKEY["PRODUCTUUID"] = "productUUID";
    COOKIEKEY["ISMULTICARD"] = "isMultiCard";
    COOKIEKEY["SESSIONID"] = "session_id";
    COOKIEKEY["REAPPLICATION"] = "reApplication";
})(COOKIEKEY || (COOKIEKEY = {}));
