import { getEmptyAmount } from './Amount';
import { getEmptyRewardBalances } from './RewardBalances';
import { InfoAction, PartnerMembershipStatus, PrimaryAction } from './RewardActionAssets';
export var getEmptyProductAccountRewards = function () {
    return {
        errorMessage: '',
        totalRewards: getEmptyAmount(),
        availableRewards: getEmptyAmount(),
        pendingRewards: getEmptyAmount(),
        signUpRewards: getEmptyAmount(),
        redemptionEnabled: false,
        showRedemptionOption: false,
        displayTitle: '',
        displayActionTitle: '',
        rewardBalances: getEmptyRewardBalances(),
        redemptionTypes: null,
        primaryAction: PrimaryAction.CASHBACK,
        infoAction: InfoAction.REWARDS_DETAILS,
        partnerMembershipStatus: PartnerMembershipStatus.UNLINKED,
    };
};
