var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
/* tslint:disable */
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { EVENT } from '../../analytics/analyticsConsts';
import { FlyoutContext, setFlyoutContext, setIsFlyoutVisible } from '../../app/flyoutSlice';
import { ACCOUNT_STATUS, HomeScreen, ProductType, setHomeScreen, setShowAccountStatus, setShowWallet, } from '../../app/homeSlice';
import { useAppSelector } from '../../app/hooks';
import { SCREEN, setScreen } from '../../app/screenStateSlice';
import ArrowLeftBlack from '../../assets/icons/ArrowLeftBlack.svg';
import CreditCardNumbers from '../../assets/icons/CreditCardNumbers.svg';
import globe from '../../assets/icons/Globe.svg';
import Hamburger from '../../assets/icons/Hamburger.svg';
import ImprintLogoIcon from '../../assets/icons/ImprintLogoIcon.svg';
import Wallet from '../../assets/icons/Wallet.svg';
import Wordmark from '../../assets/icons/Wordmark.svg';
import { ANALYTICS } from '../../firebase/firebase';
import { COOKIEKEY } from '../../utils/cookieConstants';
import { clearServiceLoginState, navigateToAccountPage, removeCookieFor, } from '../../utils/functionUtils';
import { KEYTYPE } from '../../utils/keyConstants';
import { LANGUAGE_CODE_TO_TEXT, LANGUAGE_TO_NUM, LanguageCode, } from '../../utils/languageConstants';
import { handleLanguageSelect } from '../../utils/languagelocale';
import { PAGE } from '../../utils/routeConstant';
import Dropdown from '../Dropdown/Dropdown';
import Button, { ButtonColorScheme, ButtonSize } from '../button/Button';
import { ITEM_STYLE } from '../list/List';
import Spinner from '../spinner/Spinner';
import Topbar from '../topbar/Topbar';
import styles from './HeaderServicing.module.css';
export var HEADER_SERVICING_HEIGHT = '74px';
export var HEADER_SERVICING_TYPE;
(function (HEADER_SERVICING_TYPE) {
    HEADER_SERVICING_TYPE["DEFAULT"] = "DEFAULT";
    HEADER_SERVICING_TYPE["CENTER"] = "CENTER";
    HEADER_SERVICING_TYPE["LOGOUT"] = "LOGOUT";
    HEADER_SERVICING_TYPE["LOGIN"] = "LOGIN";
    HEADER_SERVICING_TYPE["GO_BACK"] = "GOBACK";
})(HEADER_SERVICING_TYPE || (HEADER_SERVICING_TYPE = {}));
export default function HeaderServicing(_a) {
    var _b = _a.type, type = _b === void 0 ? HEADER_SERVICING_TYPE.DEFAULT : _b, _c = _a.isScreenHeader, isScreenHeader = _c === void 0 ? true : _c, toggleScroll = _a.toggleScroll, onGoBack = _a.onGoBack;
    var dispatch = useDispatch();
    var navigate = useNavigate();
    var language = useAppSelector(function (state) { return state.languageSelector.languageLocalization; });
    var featureFlags = useAppSelector(function (state) { return state.featureFlagSelector.featureFlags; });
    var cardImg = useAppSelector(function (state) { var _a; return ((_a = state.homeSelector.selectedProductAccount) === null || _a === void 0 ? void 0 : _a.cardImg) || state.merchantDataSelector.cardImg; });
    var languageState = useAppSelector(function (state) { return state.languageSelector; });
    var langCode = languageState.languageCode;
    var homeSelector = useAppSelector(function (state) { return state.homeSelector; });
    var merchantData = useAppSelector(function (state) { return state.merchantDataSelector; });
    var screenState = useAppSelector(function (state) { return state.screenStateSelector; });
    var productAccount = useAppSelector(function (state) { return state.productAccountSelector; });
    var showWallet = homeSelector.showWallet;
    var showAccountStatus = homeSelector.showAccountStatus;
    // We need to change imprint logo on mobile UX.
    var isPhoneUX = window.matchMedia('(max-width: 600px)');
    var isOnHomePage = window.location.href.includes(PAGE.ACCOUNT);
    var isOnCardImgDisallowedPage = window.location.href.includes(PAGE.LOGIN) ||
        window.location.href.includes(PAGE.CHANGE_LANGUAGE) ||
        window.location.href.includes(PAGE.BANKING) ||
        window.location.href.includes(PAGE.EDU_APP_WEB) ||
        window.location.href.includes(PAGE.PERSONAL_INFO);
    var showIconOnly = isPhoneUX.matches && !window.location.href.includes(PAGE.EDU_APP_WEB);
    var showMultiCardOption = (homeSelector === null || homeSelector === void 0 ? void 0 : homeSelector.productAccounts.filter(function (productAccount) { return productAccount.productType !== ProductType.SpendCard; }).filter(function (productAccount) {
        var _a;
        return productAccount.productAccountUUID !==
            ((_a = homeSelector.selectedProductAccount) === null || _a === void 0 ? void 0 : _a.productAccountUUID);
    }).filter(function (productAccount) {
        return ![ACCOUNT_STATUS.FROZEN, ACCOUNT_STATUS.MANUAL_REVIEW].includes(productAccount.productAccountStatus);
    }).length) >= 1;
    // We will show wallet icon with notification if there is any card with pending offer.
    var walletHasNotification = (homeSelector === null || homeSelector === void 0 ? void 0 : homeSelector.productAccounts.filter(function (productAccount) {
        return [ACCOUNT_STATUS.OFFER_PENDING].includes(productAccount.productAccountStatus);
    }).length) >= 1;
    var activeCardList = homeSelector === null || homeSelector === void 0 ? void 0 : homeSelector.productAccounts.filter(function (productAccount) {
        return productAccount.productType !== ProductType.SpendCard &&
            productAccount.productAccountStatus === ACCOUNT_STATUS.ACTIVE;
    });
    function getLanguageHeader() {
        switch (languageState.languageCode) {
            case LanguageCode.Spanish:
                return LANGUAGE_CODE_TO_TEXT[LanguageCode.Spanish];
            default:
                return LANGUAGE_CODE_TO_TEXT[LanguageCode.English];
        }
    }
    function getLanguageDropdownItems() {
        return Array.from(Object.values(LanguageCode).map(function (code) {
            return {
                title: LANGUAGE_CODE_TO_TEXT[code],
                selected: code === languageState.languageCode,
                style: ITEM_STYLE.CHECK,
                handleClick: function () {
                    handleLanguageSelect(code, 'phone_entry_page');
                },
            };
        }));
    }
    function handleWordmarkClick() {
        if (type === HEADER_SERVICING_TYPE.DEFAULT) {
            // Hide the wallet and card details if it is shown.
            dispatch(setShowWallet(false));
            dispatch(setHomeScreen(HomeScreen.Home));
            if (toggleScroll) {
                toggleScroll();
            }
            ANALYTICS.logEvent(EVENT.HEADER_IMPRINT_LOGO_CLICKED);
            navigateToAccountPage(dispatch, navigate);
        }
        else if (type === HEADER_SERVICING_TYPE.LOGIN) {
            navigate(PAGE.LOGIN);
            dispatch(setScreen(SCREEN.LOGIN));
        }
    }
    var hideLeadingLogo = function () {
        if (!isScreenHeader) {
            return false;
        }
        if (!isPhoneUX.matches && showMultiCardOption) {
            if (isOnHomePage) {
                if (showWallet) {
                    return true;
                }
            }
            else {
                return !isOnCardImgDisallowedPage;
            }
        }
        return false;
    };
    // Render the current card image in the middle in multi-card selection UX
    var getMiddleCardImg = function () {
        if (featureFlags.multiCardUIEnabled === 'true' &&
            homeSelector.selectedProductAccount &&
            showMultiCardOption) {
            var showMiddleCardImg = showWallet || (!isOnHomePage && !isOnCardImgDisallowedPage);
            return (_jsx("img", { src: cardImg, className: "".concat(showMiddleCardImg ? styles.middleCardImgOpen : styles.middleCardImg), tabIndex: 0, alt: merchantData.merchantName + 'card image' }));
        }
        return _jsx(_Fragment, {});
    };
    var getTrailingIcons = function () {
        if (showWallet || showAccountStatus) {
            return (
            // We will hide the back icon if the user selected a bad card, and let user to
            // back button from the pop-up.
            _jsx("div", __assign({ className: styles.trailingIcons }, { children: _jsxs("div", __assign({ className: styles.goBackBtn, onClick: function () {
                        if (showWallet) {
                            dispatch(setShowWallet(false));
                        }
                        if (showAccountStatus) {
                            dispatch(setShowAccountStatus(false));
                            navigate(PAGE.ACCOUNT);
                        }
                        if (toggleScroll) {
                            toggleScroll();
                        }
                    } }, { children: [_jsx("img", { src: ArrowLeftBlack, className: styles.goBackBtnIcon }), _jsx("p", __assign({ className: styles.goBackBtnText }, { children: language.goBackBtn }))] })) })));
        }
        return (_jsxs("div", __assign({ className: styles.trailingIcons }, { children: [(productAccount === null || productAccount === void 0 ? void 0 : productAccount.virtualCard) && (_jsx(TrailingIcon, { onClick: function () {
                        ANALYTICS.logEvent(EVENT.HEADER_CARD_DETAILS_CLICKED);
                        navigateToAccountPage(dispatch, navigate);
                        dispatch(setHomeScreen(HomeScreen.CardDetails));
                    }, icon: CreditCardNumbers, text: language.cardDetails })), featureFlags.multiCardUIEnabled === 'true' && showMultiCardOption && (_jsx(TrailingIcon, { onClick: function () {
                        dispatch(setShowWallet(true));
                        if (toggleScroll) {
                            toggleScroll();
                        }
                    }, icon: Wallet, text: language.wallet, hasNotification: walletHasNotification })), _jsx(TrailingIcon, { onClick: function () {
                        ANALYTICS.logEvent(EVENT.USER_MENU_VIEWED);
                        dispatch(setFlyoutContext(FlyoutContext.UserMenu));
                        dispatch(setIsFlyoutVisible(true));
                    }, icon: Hamburger, text: language.menu })] })));
    };
    return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: isScreenHeader ? styles.container : styles.none }, { children: [_jsxs("div", __assign({ className: styles.main }, { children: [_jsx("div", __assign({ className: "".concat(type === HEADER_SERVICING_TYPE.CENTER ? styles.center : styles.leading, "\n              ").concat(hideLeadingLogo() ? styles.hideWordmark : '') }, { children: _jsx("img", { src: showIconOnly ? ImprintLogoIcon : Wordmark, onClick: handleWordmarkClick, onKeyDown: function (e) {
                                        if (e.key.toUpperCase() === KEYTYPE.ENTER) {
                                            handleWordmarkClick();
                                        }
                                    }, tabIndex: 0, alt: "logo" }) })), getMiddleCardImg(), type === HEADER_SERVICING_TYPE.DEFAULT && getTrailingIcons(), type === HEADER_SERVICING_TYPE.LOGIN && (_jsx("div", __assign({ className: styles.logoutButtonContainer }, { children: featureFlags.spanish && screenState.screen !== SCREEN.VERIFICATION_FAILED ? (_jsx(Dropdown, { value: getLanguageHeader(), icon: globe, hideOnSelect: true, dropdownItems: getLanguageDropdownItems(), onClickEvent: function () {
                                        ANALYTICS.logEvent(EVENT.LANGUAGE_MENU_TAB_CLICKED);
                                    }, defaultSelection: LANGUAGE_TO_NUM[langCode] })) : (_jsx(_Fragment, {})) }))), type === HEADER_SERVICING_TYPE.LOGOUT && (_jsx("div", __assign({ className: styles.logoutButtonContainer }, { children: _jsx(Button, { value: language.signOut, colorScheme: ButtonColorScheme.ghostImprint, size: ButtonSize.small, handleClick: function () {
                                        ANALYTICS.logEvent(EVENT.HEADER_SIGNOUT_CLICKED);
                                        clearServiceLoginState(dispatch);
                                        removeCookieFor(COOKIEKEY.ISMULTICARD);
                                        dispatch(setScreen(SCREEN.LOGIN));
                                        navigate(PAGE.LOGIN);
                                    } }) }))), type === HEADER_SERVICING_TYPE.GO_BACK && (_jsx("div", __assign({ className: styles.logoutButtonContainer }, { children: _jsx(Button, { value: language.goBackBtn, colorScheme: ButtonColorScheme.ghostImprint, size: ButtonSize.small, handleClick: onGoBack ||
                                        (function () {
                                            window.location.reload();
                                        }) }) })))] })), type === HEADER_SERVICING_TYPE.DEFAULT && (_jsx(Topbar, { close: function () {
                            dispatch(setShowWallet(false));
                            if (toggleScroll) {
                                toggleScroll();
                            }
                        } }))] })), homeSelector.loadingNewCard && (_jsx("div", __assign({ className: styles.spinnerContainer }, { children: _jsx(Spinner, {}) })))] }));
}
function TrailingIcon(_a) {
    var icon = _a.icon, text = _a.text, hasNotification = _a.hasNotification, onClick = _a.onClick;
    return (_jsxs("div", __assign({ className: styles.trailingContainer }, { children: [_jsxs("div", __assign({ className: styles.trailing, onClick: onClick, onKeyDown: function (e) {
                    if (e.key.toUpperCase() === KEYTYPE.ENTER) {
                        onClick();
                    }
                } }, { children: [hasNotification && _jsx("div", { className: styles.notificationDot }), _jsx("img", { src: icon, tabIndex: 0, alt: text + 'icon' })] })), _jsx("div", __assign({ className: styles.trailingText }, { children: text }))] })));
}
