export var getEmptyAmount = function () {
    return {
        displaySymbol: '',
        displayValue: '',
        displayUnit: '',
        exponent: 0,
        valueInMinUnit: 0,
        negative: false,
    };
};
