import { createSlice } from '@reduxjs/toolkit';
export var ActivatePhysicalCardScreen;
(function (ActivatePhysicalCardScreen) {
    ActivatePhysicalCardScreen[ActivatePhysicalCardScreen["EnterCode"] = 0] = "EnterCode";
    ActivatePhysicalCardScreen[ActivatePhysicalCardScreen["Success"] = 1] = "Success";
})(ActivatePhysicalCardScreen || (ActivatePhysicalCardScreen = {}));
var initialState = {
    screen: ActivatePhysicalCardScreen.EnterCode,
};
var physicalCardActivationSlice = createSlice({
    name: 'physicalCardActivation',
    initialState: initialState,
    reducers: {
        setPhysicalCardActivationScreen: function (state, action) {
            state.screen = action.payload;
        },
    },
});
export var setPhysicalCardActivationScreen = physicalCardActivationSlice.actions.setPhysicalCardActivationScreen;
export default physicalCardActivationSlice.reducer;
