import { createSlice } from '@reduxjs/toolkit';
var initialState = {
    featureFlags: {},
};
var FeatureFlaceSlice = createSlice({
    name: 'applicationScreen',
    initialState: initialState,
    reducers: {
        setFeatureFlags: function (state, action) {
            state.featureFlags = action.payload;
        },
    },
});
export var setFeatureFlags = FeatureFlaceSlice.actions.setFeatureFlags;
export default FeatureFlaceSlice.reducer;
