import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { ImpConfirmAddressPage, useModal } from '@imprint-payments/imprint-ui';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { EVENT } from '../../analytics/analyticsConsts';
import { replaceCard, requestNewPhysicalCard, } from '../../api/CardMenu/cardRequestHelper';
import { getCardOfferImg } from '../../api/api';
import { selectAuth } from '../../app/authSelectors';
import { setSuccessType } from '../../app/homeSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { stripeMigrationErrorCode } from '../../assets/consts/const';
import { ANALYTICS } from '../../firebase/firebase';
import { displayTemporarySnackbar } from '../../utils/functionUtils';
import { PAGE } from '../../utils/routeConstant';
import { RequestCardErrorModal } from '../popup/RequestCardErrorModal';
export function CardAddressConfirmation(_a) {
    var reason = _a.reason, successType = _a.successType;
    var navigate = useNavigate();
    var dispatch = useAppDispatch();
    var language = useAppSelector(function (state) { return state.languageSelector.languageLocalization; });
    var auth = useAppSelector(selectAuth);
    var merchantData = useAppSelector(function (state) { return state.merchantDataSelector; });
    var homeSelector = useAppSelector(function (state) { return state.homeSelector; });
    var consumerInfo = useAppSelector(function (state) { return state.consumerInfoSelector; });
    var apiScreenState = useAppSelector(function (state) { return state.apiScreenScreenSelector; });
    var address = homeSelector.userInformation.address;
    var physicalCardInfo = useAppSelector(function (state) { return state.productAccountSelector.physicalCard; });
    var cardUUID = physicalCardInfo === null || physicalCardInfo === void 0 ? void 0 : physicalCardInfo.cardUUID;
    var _b = useState(false), isLoading = _b[0], setIsLoading = _b[1];
    var _c = useModal(false), isOpen = _c[0], isClosing = _c[1], openModal = _c[2], closeModal = _c[3];
    useEffect(function () {
        var _a;
        dispatch(getCardOfferImg((_a = homeSelector.selectedProductAccount) === null || _a === void 0 ? void 0 : _a.productUUID));
    }, []);
    var getAddress = function () {
        return {
            line1: address.streetLine1,
            line2: address.streetLine2,
            line3: "".concat(address.city, ", ").concat(address.state, " ").concat(address.zipCode),
        };
    };
    function onError(code) {
        if (code === stripeMigrationErrorCode) {
            // Enable a modal to notify user about the Stripe migration
            ANALYTICS.logEvent(EVENT.REQUEST_CARD_ERROR_VIEWED);
            openModal();
        }
        else {
            // TODO Need to restore it after the stripe migration
            displayTemporarySnackbar(language.somethingWentWrong, dispatch);
        }
        setIsLoading(false);
    }
    function onSuccess() {
        dispatch(setSuccessType(successType));
        setIsLoading(false);
    }
    return (_jsxs(_Fragment, { children: [_jsx(RequestCardErrorModal, { closeModal: closeModal, isOpen: isOpen, isClosing: isClosing }), _jsx(ImpConfirmAddressPage, { pageState: isLoading ? 'submitting' : 'enabled', displayImage: merchantData.cardOfferImg, title: language.confirmHomeAddress, subtitle: language.confirmHomeAddressSubtitle, displayText: getAddress(), primaryActionButtonInfo: {
                    text: language.confirmBtn,
                    onClick: function () {
                        ANALYTICS.logEvent(EVENT.REPLACE_CARD_CONFIRM_CLICKED);
                        setIsLoading(true);
                        if (cardUUID) {
                            // replace the existing card
                            dispatch(replaceCard({ cardUUID: cardUUID, reason: reason, onSuccess: onSuccess, onError: onError }));
                        }
                        else {
                            // request for a new card
                            dispatch(requestNewPhysicalCard({
                                token: auth.token,
                                productAccountUUID: homeSelector.selectedProductAccount.productAccountUUID,
                                deviceID: apiScreenState.deviceID,
                                onSuccess: onSuccess,
                                onError: onError,
                            }));
                        }
                    },
                }, secondaryActionButtonInfo: {
                    text: language.changeAddress,
                    onClick: function () {
                        ANALYTICS.logEvent(EVENT.REPLACE_CARD_CHANGE_ADDRESS_CLICKED);
                        navigate(PAGE.PERSONAL_INFO);
                    },
                } })] }));
}
