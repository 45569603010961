import { compareDisplayAmounts, createDisplayAmount, formatDisplayAmount, } from '@imprint-payments/imprint-lib';
import { ActivityIcons, colors, } from '@imprint-payments/imprint-ui';
import { ActivityType } from '../../api/apiConstants';
import { PaymentAccountStatus } from '../../pages/dashboard/Dashboard';
import { epochSecToESTDate, epochSecToESTTimeString } from '../../utils/formatUtils';
import { ActivityStatus, Colors600, activityTypeToIcon, getIconTypeFromCategoryCode, iconTypeToIcon, statusToBadgeMap, } from './activityDetailsConsts';
var HighlightColor;
(function (HighlightColor) {
    HighlightColor["ContentPositive"] = "CONTENT_POSITIVE";
    HighlightColor["ContentNegative"] = "CONTENT_NEGATIVE";
})(HighlightColor || (HighlightColor = {}));
var getColorFromHighlight = function (contentColor) {
    if (contentColor === HighlightColor.ContentPositive)
        return 'positive';
    if (contentColor === HighlightColor.ContentNegative)
        return 'negative';
    return 'neutral';
};
var getHighlight = function (activity, language) {
    var _a;
    if (!((_a = activity.details) === null || _a === void 0 ? void 0 : _a.highlight))
        return undefined;
    var highlightProp = activity.details.highlight;
    var highlight = getColorFromHighlight(highlightProp.contentColor);
    var leadingText = highlightProp.body;
    var trailingText = formatDisplayAmount(highlightProp.amount);
    return {
        highlight: highlight,
        leadingText: leadingText,
        trailingText: trailingText,
    };
};
export var getIcon = function (activity) {
    var _a;
    // custom category icon
    var categoryCode = activity.categoryCode, type = activity.type;
    if ([ActivityType.transaction, ActivityType.auth].includes(type)) {
        if (categoryCode) {
            var customIconInfo = getIconTypeFromCategoryCode(categoryCode);
            if (customIconInfo) {
                var displayImageSrc_1 = customIconInfo.displayImageSrc, displayImageBackgroundColor = customIconInfo.displayImageBackgroundColor;
                return {
                    displayImageSrc: displayImageSrc_1,
                    displayImageBackgroundColor: displayImageBackgroundColor,
                };
            }
        }
        return {
            displayImageSrc: ActivityIcons.bag,
            displayImageBackgroundColor: Colors600.Grey,
        };
    }
    // default icon based on activity type, if category isn't configured
    var iconInfo = (_a = activityTypeToIcon[activity.type]) !== null && _a !== void 0 ? _a : getSecondaryIcon(activity);
    // ideally this will be a map, but for now it's an array
    var displayImageSrc = iconInfo[0], displayImageStroke = iconInfo[1];
    return { displayImageSrc: displayImageSrc, displayImageStroke: displayImageStroke };
};
var getSecondaryIcon = function (activity) {
    return iconTypeToIcon[activity.type] || [ActivityIcons.commerce, colors.content.onSurfaceSuccess];
};
export var generateHeaderConfig = function (activity, language) {
    var _a, _b;
    var activityDetailsAmount = (_b = (_a = activity === null || activity === void 0 ? void 0 : activity.details) === null || _a === void 0 ? void 0 : _a.heading) === null || _b === void 0 ? void 0 : _b.amount;
    var displayTitle = activityDetailsAmount
        ? formatDisplayAmount(activityDetailsAmount)
        : formatDisplayAmount(activity.amount);
    var leadingSubtitle = activity.header;
    var trailingSubtitle = activity.subheader1;
    var _c = getIcon(activity), displayImageSrc = _c.displayImageSrc, displayImageStroke = _c.displayImageStroke, displayImageBackgroundColor = _c.displayImageBackgroundColor;
    var headerBarConfig = getHighlight(activity, language);
    return {
        displayTitle: displayTitle,
        leadingSubtitle: leadingSubtitle,
        trailingSubtitle: trailingSubtitle,
        // necessary as Icons type is IconComponentType (which we can convert to string here)
        displayImageSrc: displayImageSrc,
        displayImageStroke: displayImageStroke,
        displayImageBackgroundColor: displayImageBackgroundColor,
        headerBarConfig: headerBarConfig,
    };
};
var getBadgeFromStatus = function (status, language) {
    var badge = statusToBadgeMap[status];
    if (badge) {
        return {
            text: language[badge.text],
            color: badge.color,
        };
    }
    return undefined;
};
export var generatePaymentInfoConfig = function (activity, language) {
    var _a;
    if (activity.type !== ActivityType.payment)
        return undefined;
    var title = language.paymentStatusTitle;
    var trailingBadgeConfig = getBadgeFromStatus(activity.status, language);
    if (!trailingBadgeConfig)
        return undefined;
    var editTimeToEpochSec = activity.editTimeToEpochSec, status = activity.status;
    trailingBadgeConfig.text = (_a = trailingBadgeConfig.text) === null || _a === void 0 ? void 0 : _a.toUpperCase();
    var paragraph = status === ActivityStatus.Scheduled && editTimeToEpochSec
        ? language.paymentEditOrCancelDisclosure
            .replace('{0}', epochSecToESTTimeString(editTimeToEpochSec))
            .replace('{1}', epochSecToESTDate(editTimeToEpochSec, language.selected))
        : undefined;
    return {
        title: title,
        trailingBadgeConfig: trailingBadgeConfig,
        paragraph: paragraph,
    };
};
// TODO: move to imprint-lib
export var formatPaymentAccount = function (paymentAccount, language) {
    return "".concat(paymentAccount.name === '' ? language.account : paymentAccount.name, " (\u2022\u2022\u2022\u2022 ").concat(paymentAccount.accountNumberLast4, ")");
};
var generateOtherInfo = function (activity, language) {
    var _a;
    var items = [];
    var pushItem = function (subtitle, trailingTitle) {
        items.push({ subtitle: subtitle, trailingTitle: trailingTitle });
    };
    var type = activity.type;
    switch (type) {
        case ActivityType.payment:
            if (activity.paymentAccount) {
                pushItem(language.bankInfoLabel, formatPaymentAccount(activity.paymentAccount, language));
            }
            break;
        case ActivityType.transaction:
        case ActivityType.auth:
            if ((_a = activity.details) === null || _a === void 0 ? void 0 : _a.other) {
                var _b = activity.details.other, location_1 = _b.location, category = _b.category, spendType = _b.spendType, customerName = _b.customerName;
                var items_1 = [
                    { key: location_1, label: language.location },
                    { key: category, label: language.category },
                    { key: spendType, label: language.type },
                    { key: customerName, label: language.purchasedBy },
                ];
                items_1.forEach(function (item) {
                    if (item.key) {
                        pushItem(item.label, item.key);
                    }
                });
            }
            break;
    }
    if (items.length === 0) {
        return undefined;
    }
    return { title: language.otherInfo, items: items };
};
var generateSummaryInfo = function (activity, language) {
    var items = [];
    if (items.length === 0) {
        return undefined;
    }
    return { title: language.summary, items: items };
};
export var generateBodyConfig = function (activity, language) {
    var toReturn = [];
    var otherSection = generateOtherInfo(activity, language);
    var summarySection = generateSummaryInfo(activity, language);
    if (!otherSection && !summarySection)
        return undefined;
    if (otherSection)
        toReturn.push(otherSection);
    if (summarySection)
        toReturn.push(summarySection);
    return toReturn;
};
export var getActionVisibility = function (activity, primaryPaymentAccount, productAccount) {
    var canMakeOrUpdatePayment = primaryPaymentAccount.status === PaymentAccountStatus.Approved &&
        compareDisplayAmounts(productAccount.cardBalanceV2.totalSpend, createDisplayAmount({ valueInMinUnit: 0 })) > 0;
    var now = Date.now();
    // multiply by 1000 to convert to milliseconds
    var editTime = (activity.editTimeToEpochSec || 0) * 1000;
    var states = {
        showEdit: false,
        showCancel: false,
        showNewPayment: false,
        showTryAgain: false,
    };
    if (activity.status === ActivityStatus.Scheduled && editTime > now) {
        states.showEdit = canMakeOrUpdatePayment;
        states.showCancel = true;
    }
    if (canMakeOrUpdatePayment) {
        states.showNewPayment = activity.status === ActivityStatus.Canceled;
        states.showTryAgain = activity.status === ActivityStatus.Rejected;
    }
    return states;
};
export var generateDetailsDescription = function (activity) {
    var _a, _b;
    var type = activity.type;
    switch (type) {
        case ActivityType.auth:
        case ActivityType.transaction:
        case ActivityType.reward:
        case ActivityType.oneTime:
        case ActivityType.offer:
        case ActivityType.signup:
            return (_b = (_a = activity.details) === null || _a === void 0 ? void 0 : _a.description) === null || _b === void 0 ? void 0 : _b.content;
        default:
            return undefined;
    }
};
