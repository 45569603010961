var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../app/hooks';
import styles from './CardWithDetails.module.css';
import { displayTemporarySnackbar, formatDate, } from '../../utils/functionUtils';
import { EVENT } from '../../analytics/analyticsConsts';
import { ANALYTICS } from '../../firebase/firebase';
import { CustomCopy } from '../../assets/icons/CustomCopy';
export function CardWithDetails(_a) {
    var featureEnabled = _a.featureEnabled, showCardNumber = _a.showCardNumber, virtualCard = _a.virtualCard;
    var dispatch = useDispatch();
    var merchantData = useAppSelector(function (state) { return state.merchantDataSelector; });
    var language = useAppSelector(function (state) { return state.languageSelector.languageLocalization; });
    var displayCardInfo = {
        cardNumber: showCardNumber
            ? virtualCard.pan
            : '••••••••••••' + virtualCard.last4,
        expMonth: showCardNumber ? virtualCard.expiryMonth : '••',
        expYear: showCardNumber ? virtualCard.expiryYear : '••',
        cvv: showCardNumber ? virtualCard.cvv : '•••',
    };
    var productAccount = useAppSelector(function (state) { return state.productAccountSelector; });
    function copyStringToClipboard(sName, s) {
        if (!showCardNumber)
            return;
        navigator.clipboard.writeText(s);
        displayTemporarySnackbar("".concat(sName, " ").concat(language.copiedToClipboard), dispatch);
    }
    var getCardDetailsColor = function () {
        var _a;
        return (_a = merchantData.color.contrastLight) !== null && _a !== void 0 ? _a : '#ffffff';
    };
    if (!featureEnabled) {
        return (_jsx("div", __assign({ className: styles.cardContainer }, { children: _jsx("img", { className: styles.cardImg, src: merchantData.cardImg }) })));
    }
    return (_jsxs("div", __assign({ className: styles.cardContainer }, { children: [_jsx("img", { className: styles.cardBackImg, src: merchantData.cardBackImg }), _jsxs("div", __assign({ className: styles.cardDetailsSection }, { children: [_jsxs("div", __assign({ className: styles.cardNumberSection, onClick: function () {
                            ANALYTICS.logEvent(EVENT.CARD_DETAILS_COPY_CLICKED);
                            copyStringToClipboard(language.cardNumber, displayCardInfo.cardNumber || '');
                        } }, { children: [_jsx(SectionTitle, { title: language.cardNumber, canCopy: showCardNumber }), _jsx(CardNumberDisplay, { numbers: displayCardInfo.cardNumber || '', isShowing: showCardNumber }), showCardNumber && _jsx(Overlay, { prompt: language.clickToCopy })] })), _jsxs("div", __assign({ className: styles.expAndCVV }, { children: [_jsxs("div", __assign({ className: styles.exp, onClick: function () {
                                    copyStringToClipboard(language.expirationDate, displayCardInfo.expMonth + '/' + displayCardInfo.expYear);
                                } }, { children: [_jsx(SectionTitle, { title: language.expirationAbbrev, canCopy: showCardNumber }), _jsx("div", __assign({ className: styles.expContent }, { children: formatDate(displayCardInfo.expMonth || '', displayCardInfo.expYear || '') })), showCardNumber && _jsx(Overlay, { prompt: language.clickToCopy })] })), _jsxs("div", __assign({ className: styles.cvv, onClick: function () {
                                    copyStringToClipboard(language.CVV, displayCardInfo.cvv || '');
                                } }, { children: [_jsx(SectionTitle, { title: language.CVV, canCopy: showCardNumber }), _jsx("div", __assign({ className: styles.cvvContent }, { children: displayCardInfo.cvv })), showCardNumber && _jsx(Overlay, { prompt: language.clickToCopy })] }))] }))] }))] })));
}
function SectionTitle(_a) {
    var title = _a.title, canCopy = _a.canCopy;
    return (_jsxs("div", __assign({ className: styles.sectionTitle }, { children: [_jsx("div", { children: title }), canCopy && _jsx(CustomCopy, { stroke: 'var(--contrastLight, white)' })] })));
}
function Overlay(_a) {
    var prompt = _a.prompt;
    return _jsx("div", __assign({ className: styles.overlay }, { children: prompt }));
}
function CardNumberDisplay(_a) {
    var numbers = _a.numbers, isShowing = _a.isShowing;
    var numberStyle = isShowing
        ? styles.cardNumberItem
        : styles.cardNumberBullet;
    return (_jsxs("div", __assign({ className: styles.cardNumber }, { children: [_jsxs("div", __assign({ className: styles.row }, { children: [_jsx("span", __assign({ className: numberStyle, "data-testid": "card-number" }, { children: numbers.slice(0, 4) })), _jsx("span", __assign({ className: numberStyle }, { children: numbers.slice(4, 8) }))] })), _jsxs("div", __assign({ className: styles.row }, { children: [_jsx("span", __assign({ className: numberStyle }, { children: numbers.slice(8, 12) })), _jsx("span", __assign({ className: styles.cardNumberItem }, { children: numbers.slice(12, 16) }))] }))] })));
}
