var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useNavigate } from 'react-router-dom';
import { navigateToAccountPage } from '../../utils/functionUtils';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { ANALYTICS } from '../../firebase/firebase';
import { EVENT } from '../../analytics/analyticsConsts';
import { useEffect, useState } from 'react';
import HeaderServicing from '../../components/HeaderServicing/HeaderServicing';
import { SUCCESS_TYPE } from '../../app/homeSlice';
import { LIST_STYLE, ListSection, } from '../../components/list/List';
import { PAGE } from '../../utils/routeConstant';
import { SCREEN, setScreen } from '../../app/screenStateSlice';
import { CardAddressConfirmation } from '../../components/CardDetails/ConfirmAddress';
import { ImpBreadcrumbs, ImpButton, ImpContainer, ImpFlex, ImpPad, ImpSpacer, ImpText, colors } from '@imprint-payments/imprint-ui';
import { CardActionReason } from '../../api/CardMenu/cardRequestHelper';
import { selectPhysicalCardWidget } from '../../app/productAccountSelectors';
import { ShippingWidgetState } from '../../types/ShippingWidget';
import { FORM_BUTTON_WIDTH } from '../../assets/consts/const';
export function ReplaceCard() {
    var navigate = useNavigate();
    var dispatch = useAppDispatch();
    var language = useAppSelector(function (state) { return state.languageSelector.languageLocalization; });
    var homeSelector = useAppSelector(function (state) { return state.homeSelector; });
    var physicalCardWidget = useAppSelector(selectPhysicalCardWidget);
    var physicalCardInfo = useAppSelector(function (state) { return state.productAccountSelector.physicalCard; });
    var cardUUID = physicalCardInfo === null || physicalCardInfo === void 0 ? void 0 : physicalCardInfo.cardUUID;
    var last4 = physicalCardInfo === null || physicalCardInfo === void 0 ? void 0 : physicalCardInfo.last4;
    var _a = useState(CardActionReason.LOST), reason = _a[0], setReason = _a[1];
    var _b = useState(false), inConfirmation = _b[0], setInConfirmation = _b[1];
    useEffect(function () {
        // handle the case where the user has not received their card
        if ((physicalCardWidget === null || physicalCardWidget === void 0 ? void 0 : physicalCardWidget.state) === ShippingWidgetState.NotReceived) {
            setReason(CardActionReason.USER_REQUESTED);
            setInConfirmation(true);
            return;
        }
        if (
        // return to home page if the user has not activated its card
        !cardUUID &&
            !last4) {
            navigateToAccountPage(dispatch, navigate);
        }
        else {
            ANALYTICS.logEvent(EVENT.REPLACE_CARD_VIEWED);
        }
    }, []);
    useEffect(function () {
        if (homeSelector.successType !== SUCCESS_TYPE.default) {
            dispatch(setScreen(SCREEN.SUCCESS));
            navigate(PAGE.SUCCESS);
        }
    }, [homeSelector.successType]);
    var replacementReasonOptions = [
        {
            title: language.replacementReasonOption1,
            handleClick: function () {
                ANALYTICS.logEvent(EVENT.REPLACE_CARD_OPTION_MISSING_CLICKED);
                setReason(CardActionReason.LOST);
            },
        },
        {
            title: language.replacementReasonOption2,
            handleClick: function () {
                ANALYTICS.logEvent(EVENT.REPLACE_CARD_OPTION_STOLEN_CLICKED);
                setReason(CardActionReason.STOLEN);
            },
        },
        {
            title: language.replacementReasonOption3,
            handleClick: function () {
                ANALYTICS.logEvent(EVENT.REPLACE_CARD_OPTION_DAMAGED_CLICKED);
                setReason(CardActionReason.DAMAGED);
            },
        },
    ];
    return (_jsxs(_Fragment, { children: [_jsx(HeaderServicing, {}), _jsx(ImpBreadcrumbs, { breadcrumbs: [], onBackClick: function () {
                    if (inConfirmation) {
                        setInConfirmation(false);
                    }
                    else {
                        navigateToAccountPage(dispatch, navigate);
                    }
                }, onCancelClick: function () { return navigateToAccountPage(dispatch, navigate); } }), _jsx(ImpSpacer, { height: '2xl' }), _jsx(ImpFlex, __assign({ direction: "column", align: "center", fluid: true }, { children: _jsx(ImpContainer, __assign({ width: "min(748px, 100vw - 32px)" }, { children: inConfirmation ? (_jsx(CardAddressConfirmation, { reason: reason, successType: SUCCESS_TYPE.cardReplaced })) : (_jsxs(_Fragment, { children: [_jsx(ImpContainer, __assign({ textAlign: "center" }, { children: _jsx(ImpText, __assign({ typography: "headline1" }, { children: language.replacementReason })) })), _jsx(ImpSpacer, { height: '2xl' }), _jsx(ListSection, { style: LIST_STYLE.SELECT, items: replacementReasonOptions }), _jsx(ImpSpacer, { height: 'xl' }), _jsx(ImpPad, __assign({ padding: "0px 18px" }, { children: _jsx(ImpText, __assign({ typography: "body2", mobileTypography: "body3", color: colors.content.onBackgroundVariant }, { children: language.replacementReasonSubtitle })) })), _jsx(ImpSpacer, { height: '3xl' }), _jsx(ImpFlex, __assign({ direction: "column", align: "center" }, { children: _jsx(ImpContainer, __assign({ width: FORM_BUTTON_WIDTH }, { children: _jsx(ImpButton, __assign({ size: "fill", variant: "primary", buttonStyle: "branded", onClick: function () {
                                            setInConfirmation(true);
                                        } }, { children: language.continueBtn })) })) }))] })) })) }))] }));
}
