var _a;
import { createSlice } from '@reduxjs/toolkit';
export var CARD_SELECTION_SCREEN;
(function (CARD_SELECTION_SCREEN) {
    CARD_SELECTION_SCREEN["LOADING"] = "LOADING";
    CARD_SELECTION_SCREEN["CARD_SELECTION"] = "CARD_SELECTION";
    CARD_SELECTION_SCREEN["CONFIRM_ADDRESS"] = "CONFIRM_ADDRESS";
    CARD_SELECTION_SCREEN["CONFIRM_ADDRESS_LATER"] = "CONFIRM_ADDRESS_LATER";
})(CARD_SELECTION_SCREEN || (CARD_SELECTION_SCREEN = {}));
var initialState = {
    screen: CARD_SELECTION_SCREEN.LOADING,
    selectedCardDesignID: '',
};
var cardDesignSlice = createSlice({
    name: 'cardDesign',
    initialState: initialState,
    reducers: {
        setCardDesignSelectionScreen: function (state, action) {
            state.screen = action.payload;
        },
        setSelectedCardDesignID: function (state, action) {
            state.selectedCardDesignID = action.payload;
        },
    },
});
export var setCardDesignSelectionScreen = (_a = cardDesignSlice.actions, _a.setCardDesignSelectionScreen), setSelectedCardDesignID = _a.setSelectedCardDesignID;
export default cardDesignSlice.reducer;
