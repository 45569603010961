var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useAppSelector } from '../../app/hooks';
import styles from './Popup.module.css';
import { useDispatch } from 'react-redux';
import { setShowModal, MODAL_ENUM } from '../../app/applicationScreenSlice';
import { useEffect } from 'react';
import { EVENT } from '../../analytics/analyticsConsts';
import { ANALYTICS } from '../../firebase/firebase';
import close from '../../assets/icons/close.svg';
export default function PaymentDefinitionModal() {
    var dispatch = useDispatch();
    var language = useAppSelector(function (state) { return state.languageSelector.languageLocalization; });
    useEffect(function () {
        ANALYTICS.logEvent(EVENT.PAYMENT_DEFINITION_VIEWED);
    }, []);
    if (language === null) {
        // eslint-disable-next-line react/jsx-no-useless-fragment
        return _jsx(_Fragment, {});
    }
    return (_jsx("div", __assign({ className: styles.overlay }, { children: _jsxs("div", __assign({ className: styles.modal }, { children: [_jsxs("div", __assign({ className: styles.headerContainer }, { children: [_jsx("p", __assign({ className: styles.header }, { children: _jsx("b", { children: language.paymentDefinition }) })), _jsx("img", { src: close, alt: "close", className: styles.close, onClick: function () { return dispatch(setShowModal(MODAL_ENUM.noModal)); } })] })), _jsx("div", { className: styles.paymentHeaderUnderline }), _jsx("p", __assign({ className: styles.paymentHeader }, { children: _jsx("b", { children: language.paymentDefinitionModalHeader1 }) })), _jsx("p", __assign({ className: styles.paymentBody }, { children: language.paymentDefinitionModalBody1 })), _jsx("p", __assign({ className: styles.paymentHeader }, { children: _jsx("b", { children: language.paymentDefinitionModalHeader2 }) })), _jsx("p", __assign({ className: styles.paymentBody }, { children: language.paymentDefinitionModalBody2 })), _jsx("p", __assign({ className: styles.paymentHeader }, { children: _jsx("b", { children: language.paymentDefinitionModalHeader3 }) })), _jsx("p", __assign({ className: styles.paymentBody }, { children: language.paymentDefinitionModalBody3 }))] })) })));
}
