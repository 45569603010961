var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { EVENT } from '../../analytics/analyticsConsts';
import { getCardBenefitsImage, getColor, getRewardDetails, setOfferPageForCard, } from '../../api/api';
import { loadHome } from '../../api/loadHome.api';
import { loadProductAccount } from '../../api/loadProductAccount/loadProductAccount';
import { ACCOUNT_STATUS, HomeScreen, ProductType, setAccountStatusProductUUID, setHomeScreen, setLoadingNewCard, setSelectedProductAccount, setShowAccountStatus, setShowWallet, } from '../../app/homeSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { SCREEN, setScreen } from '../../app/screenStateSlice';
import { ANALYTICS } from '../../firebase/firebase';
import { navigateToAccountPage } from '../../utils/functionUtils';
import { KEYTYPE } from '../../utils/keyConstants';
import { PAGE } from '../../utils/routeConstant';
import styles from './Topbar.module.css';
export default function Topbar(_a) {
    var close = _a.close;
    var navigate = useNavigate();
    var dispatch = useAppDispatch();
    var language = useAppSelector(function (state) { return state.languageSelector.languageLocalization; });
    var homeSelector = useAppSelector(function (state) { return state.homeSelector; });
    var featureFlags = useAppSelector(function (state) { return state.featureFlagSelector.featureFlags; });
    var productAccounts = homeSelector === null || homeSelector === void 0 ? void 0 : homeSelector.productAccounts;
    var showWallet = homeSelector.showWallet;
    // Tabbing and arrow accessibility logic
    var cardContainerRefs = useRef([]);
    var refIndex = 0;
    useEffect(function () {
        if (showWallet) {
            ANALYTICS.logEvent(EVENT.MULTI_CARD_MENU_VIEWED);
        }
    }, [showWallet]);
    // disable scrolling when wallet is open
    useEffect(function () {
        if (showWallet) {
            document.documentElement.style.overflow = 'hidden';
        }
        else {
            document.documentElement.style.overflow = 'unset';
        }
        return function () {
            document.documentElement.style.overflow = 'unset';
        };
    }, [showWallet]);
    var getCardStatusCircleColorStyle = function (cardStatus) {
        switch (cardStatus) {
            case ACCOUNT_STATUS.ACTIVE:
                return styles.greenCircle;
            case ACCOUNT_STATUS.OFFER_PENDING:
            case ACCOUNT_STATUS.MANUAL_REVIEW:
                return styles.yellowCircle;
            case ACCOUNT_STATUS.CANCEL:
            case ACCOUNT_STATUS.FROZEN:
            case ACCOUNT_STATUS.INACTIVE:
            default:
                return styles.redCircle;
        }
    };
    var getCardStatusColorStyle = function (cardStatus) {
        switch (cardStatus) {
            case ACCOUNT_STATUS.ACTIVE:
                return styles.green;
            case ACCOUNT_STATUS.OFFER_PENDING:
            case ACCOUNT_STATUS.MANUAL_REVIEW:
                return styles.yellow;
            case ACCOUNT_STATUS.CANCEL:
            case ACCOUNT_STATUS.FROZEN:
            case ACCOUNT_STATUS.INACTIVE:
            default:
                return styles.red;
        }
    };
    var getCardStatusText = function (cardStatus) {
        switch (cardStatus) {
            case ACCOUNT_STATUS.ACTIVE:
                return language.active;
            case ACCOUNT_STATUS.OFFER_PENDING:
                return language.offerPending;
            case ACCOUNT_STATUS.MANUAL_REVIEW:
                return language.manualReview;
            case ACCOUNT_STATUS.CANCEL:
                return language.cancelled;
            case ACCOUNT_STATUS.FROZEN:
                return language.frozen;
            case ACCOUNT_STATUS.INACTIVE:
                return language.inactive;
            default:
                return '';
        }
    };
    var getCardContainers = function () {
        var cardContainers = [];
        var clickCardContainer = function (productAccount) {
            ANALYTICS.logEvent(EVENT.MULTI_CARD_MENU_CARD_SELECTED, {
                merchantName: productAccount.merchantName,
                productUUID: productAccount.productUUID,
            });
            if (productAccount.productAccountStatus !== ACCOUNT_STATUS.FROZEN &&
                productAccount.productAccountStatus !== ACCOUNT_STATUS.MANUAL_REVIEW) {
                dispatch(setSelectedProductAccount(productAccount));
            }
            if (productAccount.productAccountStatus === ACCOUNT_STATUS.FROZEN ||
                productAccount.productAccountStatus === ACCOUNT_STATUS.MANUAL_REVIEW) {
                dispatch(setAccountStatusProductUUID(productAccount.productUUID));
            }
            // Navigate user to activate card if the user selects a offer pending card
            if (productAccount.productAccountStatus === ACCOUNT_STATUS.OFFER_PENDING) {
                dispatch(setSelectedProductAccount(productAccount));
                dispatch(setLoadingNewCard(true));
                dispatch(setOfferPageForCard(productAccount));
                dispatch(setLoadingNewCard(false));
                dispatch(setShowWallet(false));
                ANALYTICS.logEvent(EVENT.WALLET_CARD_CLICKED, {
                    product_uuid: productAccount.productUUID,
                    card_state: ACCOUNT_STATUS.OFFER_PENDING,
                });
                dispatch(setScreen(SCREEN.OFFER));
                navigate(PAGE.OFFER);
            }
            else if (productAccount.productAccountStatus === ACCOUNT_STATUS.FROZEN) {
                dispatch(setShowWallet(false));
                dispatch(setShowAccountStatus(true));
                dispatch(setSelectedProductAccount(productAccount));
                loadProductAccount({
                    productAccountUUID: productAccount.productAccountUUID,
                });
                dispatch(getRewardDetails(productAccount.productUUID));
                dispatch(getCardBenefitsImage(productAccount.productUUID));
                dispatch(getColor(productAccount.productUUID));
                ANALYTICS.logEvent(EVENT.WALLET_CARD_CLICKED, {
                    product_uuid: productAccount.productUUID,
                    card_state: ACCOUNT_STATUS.FROZEN,
                });
                dispatch(setScreen(SCREEN.CREDIT_FROZEN));
                navigate(PAGE.ACCOUNT_STATUS);
            }
            else if (productAccount.productAccountStatus === ACCOUNT_STATUS.MANUAL_REVIEW) {
                dispatch(setShowWallet(false));
                dispatch(setShowAccountStatus(true));
                dispatch(setSelectedProductAccount(productAccount));
                loadProductAccount({
                    productAccountUUID: productAccount.productAccountUUID,
                });
                dispatch(getRewardDetails(productAccount.productUUID));
                dispatch(getCardBenefitsImage(productAccount.productUUID));
                dispatch(getColor(productAccount.productUUID));
                ANALYTICS.logEvent(EVENT.WALLET_CARD_CLICKED, {
                    product_uuid: productAccount.productUUID,
                    card_state: ACCOUNT_STATUS.MANUAL_REVIEW,
                });
                dispatch(setScreen(SCREEN.APPLICATION_IN_REVIEW));
                navigate(PAGE.ACCOUNT_STATUS);
            }
            else {
                dispatch(setLoadingNewCard(true));
                dispatch(setHomeScreen(HomeScreen.Home));
                loadProductAccount({
                    productAccountUUID: productAccount.productAccountUUID,
                });
                dispatch(setLoadingNewCard(false));
                dispatch(loadHome());
                navigateToAccountPage(dispatch, navigate);
                close();
            }
        };
        productAccounts.forEach(function (productAccount) {
            // We don't show the card if the account status is manual-review or frozen.
            // We don't show the card if the card is already selected on dashboard.
            // We don't show the card if the card is a spend card.
            if (homeSelector.selectedProductAccount &&
                productAccount.productUUID !== homeSelector.selectedProductAccount.productUUID &&
                productAccount.productType !== ProductType.SpendCard) {
                cardContainers.push(_jsx("div", __assign({ className: styles.cardContainerBoundary, ref: function (el) {
                        if (el !== null) {
                            cardContainerRefs.current[refIndex++] = el;
                        }
                    }, onClick: function () {
                        clickCardContainer(productAccount);
                    }, onKeyDown: function (e) {
                        var _a, _b, _c, _d;
                        var index = cardContainerRefs.current.indexOf(e.currentTarget);
                        if (e.key.toUpperCase() === KEYTYPE.ENTER) {
                            clickCardContainer(productAccount);
                        }
                        if (e.key.toUpperCase() === KEYTYPE.ARROW_RIGHT) {
                            var nextIndex = index + 1 < cardContainerRefs.current.length ? index + 1 : 0;
                            (_a = cardContainerRefs.current[nextIndex]) === null || _a === void 0 ? void 0 : _a.focus();
                        }
                        else if (e.key.toUpperCase() === KEYTYPE.ARROW_LEFT) {
                            var prevIndex = index > 0 ? index - 1 : cardContainerRefs.current.length - 1;
                            (_b = cardContainerRefs.current[prevIndex]) === null || _b === void 0 ? void 0 : _b.focus();
                        }
                        if (e.key.toUpperCase() == KEYTYPE.TAB) {
                            e.preventDefault();
                            if (e.shiftKey) {
                                var prevIndex = index > 0 ? index - 1 : cardContainerRefs.current.length - 1;
                                (_c = cardContainerRefs.current[prevIndex]) === null || _c === void 0 ? void 0 : _c.focus();
                            }
                            else {
                                var nextIndex = index + 1 < cardContainerRefs.current.length ? index + 1 : 0;
                                (_d = cardContainerRefs.current[nextIndex]) === null || _d === void 0 ? void 0 : _d.focus();
                            }
                        }
                    }, tabIndex: 0 }, { children: _jsxs("div", __assign({ className: styles.cardContainer }, { children: [_jsx("img", { className: styles.cardImage, src: productAccount.cardImg }), _jsxs("div", __assign({ className: styles.cardInfo }, { children: [_jsx("div", __assign({ className: styles.cardTitle }, { children: productAccount.merchantName })), _jsxs("div", __assign({ className: styles.cardStatus }, { children: [_jsx("div", { className: "".concat(styles.cardStatusCircle, " \n                ").concat(getCardStatusCircleColorStyle(productAccount.productAccountStatus)) }), _jsx("div", __assign({ className: "".concat(styles.cardStatusText, " \n                ").concat(getCardStatusColorStyle(productAccount.productAccountStatus)) }, { children: getCardStatusText(productAccount.productAccountStatus) }))] }))] }))] })) }), productAccount.merchantName));
            }
        });
        return cardContainers;
    };
    return (_jsx("div", __assign({ className: showWallet ? styles.overlayOpen : styles.overlay }, { children: _jsx("div", __assign({ className: showWallet ? styles.containerOpen : styles.container, onClick: function (e) { return e.stopPropagation(); } }, { children: _jsx("div", __assign({ className: styles.cardListContainer }, { children: getCardContainers() })) })) })));
}
