var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Icons, ImpBreadcrumbs, ImpButton, ImpContainer, ImpFlex, ImpIcon, ImpInput, ImpSpacer, ImpText, colors, } from '@imprint-payments/imprint-ui';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { EVENT } from '../../analytics/analyticsConsts';
import { addPaymentAccount } from '../../api/api';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { setAccountAndRoutingError } from '../../app/validatorSlice';
import { FORM_INPUT_WIDTH, TEXT_WIDTH } from '../../assets/consts/const';
import { ANALYTICS } from '../../firebase/firebase';
import { displayTemporarySnackbar, navigateToAccountPage, userHasBankAccount, } from '../../utils/functionUtils';
import { getValidationString } from '../../utils/validateUtils';
export function LinkWithAcctRouting(_a) {
    var startBankLinking = _a.startBankLinking, setStartedLinking = _a.setStartedLinking, onSuccess = _a.onSuccess, onFail = _a.onFail;
    var primaryPaymentAccount = useAppSelector(function (state) { return state.homeSelector.primaryPaymentAccount; });
    var dispatch = useAppDispatch();
    var navigate = useNavigate();
    var _b = useState(''), accountInput = _b[0], setAccountInput = _b[1];
    var _c = useState(''), routingInput = _c[0], setRoutingInput = _c[1];
    var _d = useState(true), acctMasked = _d[0], setAcctMasked = _d[1];
    var _e = useState(true), routingMasked = _e[0], setRoutingMasked = _e[1];
    var _f = useState(false), isConfirming = _f[0], setIsConfirming = _f[1];
    var _g = useState(''), confirmedAccountInput = _g[0], setConfirmedAccountInput = _g[1];
    var _h = useState(''), confirmedRoutingInput = _h[0], setConfirmedRoutingInput = _h[1];
    var language = useAppSelector(function (state) { return state.languageSelector.languageLocalization; });
    var _j = useState(false), isSubmitting = _j[0], setIsSubmitting = _j[1];
    useEffect(function () {
        return function () {
            dispatch(setAccountAndRoutingError('empty'));
        };
    }, []);
    useEffect(function () {
        if (isConfirming) {
            ANALYTICS.logEvent(EVENT.CONFIRM_BANK_INFO_VIEWED);
            return;
        }
        if (startBankLinking) {
            ANALYTICS.logEvent(EVENT.ENTER_BANK_INFO_VIEWED);
        }
    }, [startBankLinking, isConfirming]);
    var validator = useAppSelector(function (state) { return state.validatorSelector; });
    function handleContinueClick() {
        if (!isConfirming) {
            ANALYTICS.logEvent(EVENT.ENTER_BANK_INFO_CONTINUE_CLICKED);
            setIsConfirming(true);
            return;
        }
        ANALYTICS.logEvent(EVENT.CONFIRM_BANK_INFO_CONTINUE_CLICKED);
        if (confirmedAccountInput !== accountInput ||
            confirmedRoutingInput !== routingInput) {
            ANALYTICS.logEvent(EVENT.ENTER_BANK_INFO_NO_MATCH_VIEWED);
            dispatch(setAccountAndRoutingError('noMatch'));
            setIsConfirming(false);
            return;
        }
        setIsSubmitting(true);
        dispatch(addPaymentAccount({
            cancelCurrent: userHasBankAccount(primaryPaymentAccount.status),
            paymentAccount: {
                accountNumber: accountInput,
                routingNumber: routingInput,
            },
            paymentAccountType: 'manual',
            onSuccess: function () {
                setIsSubmitting(false);
                if (onSuccess) {
                    onSuccess();
                }
                else {
                    displayTemporarySnackbar(language.bankUpdateSuccess, dispatch);
                    navigateToAccountPage(dispatch, navigate);
                }
            },
            onFail: function () {
                setIsSubmitting(false);
                onFail && onFail();
            },
        }));
    }
    var getActionButtonState = function () {
        if (isSubmitting) {
            return 'submitting';
        }
        if (!isConfirming) {
            return routingInput.length === 9 && accountInput.length >= 4
                ? 'enabled'
                : 'disabled';
        }
        else {
            return confirmedRoutingInput.length === 9 &&
                confirmedAccountInput.length >= 4
                ? 'enabled'
                : 'disabled';
        }
    };
    return (_jsxs(ImpFlex, __assign({ fluid: true, direction: "column", align: "center" }, { children: [_jsx(ImpBreadcrumbs, { breadcrumbs: [language.breadcrumbBanking], onBackClick: function () {
                    if (isConfirming) {
                        setConfirmedAccountInput('');
                        setConfirmedRoutingInput('');
                        setIsConfirming(false);
                    }
                    else {
                        setStartedLinking && setStartedLinking(false);
                    }
                }, onCancelClick: function () { return navigateToAccountPage(dispatch, navigate); } }), _jsx(ImpSpacer, { height: "2xl" }), _jsx(ImpContainer, __assign({ width: TEXT_WIDTH, textAlign: "center" }, { children: _jsx(ImpText, __assign({ typography: "headline2" }, { children: !isConfirming ? language.enterAccountAndRouting : language.confirmAccountAndRouting })) })), _jsx(ImpSpacer, { height: "xl" }), _jsxs(ImpContainer, __assign({ width: FORM_INPUT_WIDTH }, { children: [_jsxs(ImpContainer, __assign({ textAlign: "center" }, { children: [_jsx(ImpInput, { label: language.routingNumPlaceholder, onChange: function (e) {
                                    return isConfirming
                                        ? setConfirmedRoutingInput(e.target.value)
                                        : setRoutingInput(e.target.value);
                                }, value: isConfirming ? confirmedRoutingInput : routingInput, errorMessage: getValidationString(validator.routingNumError, language), mask: routingMasked, isDisabled: isSubmitting, numeric: true, maxLength: 9, trailingIcon: _jsx(ImpContainer, __assign({ cursor: "pointer", onClick: function () { return setRoutingMasked(!routingMasked); } }, { children: _jsx(ImpIcon, { iconSrc: routingMasked ? Icons.eyeClosed : Icons.eyeOpen, size: "24px", stroke: colors.content.onSurfaceDisabled }) })) }), _jsx(ImpSpacer, { height: "l" }), _jsx(ImpInput, { label: language.accountNumPlaceholder, onChange: function (e) {
                                    return isConfirming
                                        ? setConfirmedAccountInput(e.target.value)
                                        : setAccountInput(e.target.value);
                                }, value: isConfirming ? confirmedAccountInput : accountInput, errorMessage: getValidationString(validator.accountNumError, language), mask: acctMasked, isDisabled: isSubmitting, numeric: true, maxLength: 17, trailingIcon: _jsx(ImpContainer, __assign({ cursor: "pointer", onClick: function () { return setAcctMasked(!acctMasked); } }, { children: _jsx(ImpIcon, { iconSrc: routingMasked ? Icons.eyeClosed : Icons.eyeOpen, size: "24px", stroke: colors.content.onSurfaceDisabled }) })) }), _jsx(ImpText, __assign({ typography: "body3", color: colors.content.onBackgroundError }, { children: validator.accountAndRoutingError !== 'empty'
                                    ? language[validator.accountAndRoutingError]
                                    : '' }))] })), _jsx(ImpSpacer, { height: "xl" }), _jsx(ImpButton, __assign({ size: "fill", onClick: handleContinueClick, state: getActionButtonState() }, { children: language.continueBtn }))] }))] })));
}
