var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { colors, Icons, ImpContainer, ImpFlex, ImpFlexItem, ImpIcon, ImpImage, ImpPad, ImpSpacer, ImpText, spacing, } from "@imprint-payments/imprint-ui";
import { PAGE } from "../../utils/routeConstant";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { getAlternateLogoIcon } from "../../api/api";
import { useEffect, useState } from "react";
import { getMembership } from "../../api/linkAccount/getMembership";
import { setMembershipNumber } from "../../app/accountLinkSlice";
import { selectAccountLink } from "../../app/accountLinkSelector";
import { selectLanguage } from "../../app/languageSelectors";
import { selectSelectedProductAccountUUID } from "../../app/homeSelectors";
import { ANALYTICS } from "../../firebase/firebase";
import { EVENT } from "../../analytics/analyticsConsts";
import { PARTNER_TYPE } from "../../utils/productUUIDConstants";
var Entry = function () {
    var _a;
    var dispatch = useAppDispatch();
    var navigate = useNavigate();
    var merchantData = useAppSelector(function (state) { return state.merchantDataSelector; });
    var consumerData = useAppSelector(function (state) { return state.consumerInfoSelector; });
    var accountLinkInfo = useAppSelector(selectAccountLink);
    var language = useAppSelector(selectLanguage);
    var _b = useState(false), error = _b[0], setError = _b[1];
    var selectedProductAccountUUID = useAppSelector(selectSelectedProductAccountUUID);
    var _c = useState(''), logoSrc = _c[0], setLogoSrc = _c[1];
    var _d = useState(true), isLoading = _d[0], setIsLoading = _d[1];
    useEffect(function () {
        var fetchSrc = function () { return __awaiter(void 0, void 0, void 0, function () {
            var src;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, getAlternateLogoIcon(merchantData.productUUID)];
                    case 1:
                        src = _a.sent();
                        setLogoSrc(src || '');
                        return [2 /*return*/];
                }
            });
        }); };
        fetchSrc().catch(function (e) {
            setError(true);
            console.log(e);
        });
    }, [merchantData]);
    useEffect(function () {
        if (!isLoading)
            return;
        getMembership({
            productAccountUUID: selectedProductAccountUUID,
            partnerType: PARTNER_TYPE[merchantData.productUUID],
            onSuccess: function (res) {
                dispatch(setMembershipNumber(res.membershipSourceID));
                setIsLoading(false);
            },
            onError: function () {
                setError(true);
                setIsLoading(false);
            },
        });
    }, []);
    var goToUpdateLinkedAccount = function () {
        ANALYTICS.logEvent("".concat(EVENT.MERCHANT_NUMBER_VIEW_ACCOUNT_CLICKED), {
            product_uuid: merchantData.productUUID,
            consumer_uuid: consumerData.consumerUUID,
        });
        navigate(PAGE.UPDATE_LINKED_ACCOUNT);
    };
    if (isLoading || error) {
        return (_jsx(_Fragment, {}));
    }
    return (_jsx(ImpContainer, __assign({ width: "100%", backgroundColor: colors.surface.surfaceMid, borderRadius: "12px", onClick: goToUpdateLinkedAccount, isLoading: isLoading, dataTestId: "linked-account-entry" }, { children: _jsx(ImpPad, __assign({ padding: spacing.l, fluid: true }, { children: _jsxs(ImpFlex, __assign({ direction: "row", align: "center", justify: "space-between", fluid: true, mobileAlign: "center" }, { children: [_jsx(ImpFlexItem, { children: _jsxs(ImpFlex, __assign({ direction: "row", align: "center", gap: "l", mobileGap: "s" }, { children: [_jsx(ImpImage, { src: logoSrc, alt: "merchant-icon", height: "45px", width: "45px", borderRadius: "8px" }), _jsxs(ImpFlex, __assign({ direction: "column", align: "flex-start" }, { children: [_jsx(ImpText, __assign({ typography: "label1", dataTestId: "linked-account-entry-title" }, { children: (_a = language[merchantData.productUUID]) === null || _a === void 0 ? void 0 : _a.accountLinking.membershipName })), _jsx(ImpSpacer, { height: "xs" }), _jsx(ImpText, __assign({ typography: "body2", color: colors.content.onSurfaceVariant, dataTestId: "linked-account-membership-number" }, { children: accountLinkInfo.membershipNumber }))] }))] })) }), _jsx(ImpFlexItem, { children: _jsx(ImpFlex, { children: _jsx(ImpIcon, { stroke: colors.content.onSurfaceVariant, iconSrc: Icons.chevronRight }) }) })] })) })) })));
};
export default Entry;
