var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import './App.css';
import './font.css';
import { ImpSnackbarProvider } from '@imprint-payments/imprint-ui';
import { useEffect } from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';
import { resetSessionID, setSessionID } from './app/apiScreenSlice';
import { setLoading } from './app/applicationScreenSlice';
import { useAppDispatch, useAppSelector } from './app/hooks';
import { selectLanguage } from './app/languageSelectors';
import { ProtectedRoute } from './components/ProtectedRoute/ProtectedRoute';
import { SessionManager } from './components/ProtectedRoute/SessionManager';
import { ScrollToTop } from './components/ScrollToTop';
import Flyout from './components/flyout/Flyout';
import Snackbar from './components/snackbar/Snackbar';
import fetchFirebaseApiKey, { ANALYTICS, config, initFeatureFlags } from './firebase/firebase';
import FindYourAccountFlow from './pages/FindYourAccount/FindYourAccountFlow';
import { PageNotFound } from './pages/PageNotFound/PageNotFound';
import Error from './pages/error/Error';
import Login from './pages/login/Login';
import { RootPage } from './pages/root/RootPage';
import { protectedRoutes } from './routes/protectedRoutes';
import { useStatsigUserUpdaters } from './statsig/user';
import { COOKIEKEY } from './utils/cookieConstants';
import { fetchEnvFromDomain } from './utils/environments';
import { getCookieValueFor, setSessionUrlParam } from './utils/functionUtils';
import { LOGIN_PAGE, PAGE } from './utils/routeConstant';
import { useExternalScript } from './utils/useExternalScript';
import { useLanguage } from './utils/useLanguage';
import { useMerchantAssets } from './utils/useMerchantAssets';
import { useSelectedProductAccount } from './utils/useSelectedProductAccount';
export default function StatsigApp() {
    var dispatch = useAppDispatch();
    var language = useAppSelector(selectLanguage);
    var apiScreenData = useAppSelector(function (state) { return state.apiScreenScreenSelector; });
    var snackbarManager = useAppSelector(function (state) { return state.snackbarSelector; });
    // get language from session
    useLanguage();
    useSelectedProductAccount();
    useMerchantAssets();
    useStatsigUserUpdaters();
    useEffect(function () {
        // store session_id in store when it's in imprint.co domain
        // reset when it's not
        if (getCookieValueFor(COOKIEKEY.SESSIONID) !== undefined) {
            dispatch(setSessionID(getCookieValueFor(COOKIEKEY.SESSIONID)));
        }
        else if (apiScreenData.sessionID !== '') {
            dispatch(resetSessionID());
        }
    }, [apiScreenData.sessionID]);
    useEffect(function () {
        dispatch(setLoading(true));
        fetchEnvFromDomain(window.location.hostname.toLowerCase());
        fetchFirebaseApiKey(); // TODO: should be awaited ?
        initFeatureFlags();
        setSessionUrlParam(window.location.search);
        dispatch(setLoading(false));
    }, []);
    useEffect(function () {
        window.onbeforeunload = function (e) {
            ANALYTICS.analyticsUtil.writeStoredEventsAndMetrics();
        };
    }, []);
    // placesLoadedCallback is required for the Google Places API (added in index.html)
    useExternalScript('https://maps.googleapis.com/maps/api/js?key=' +
        config.mapKey +
        '&libraries=places&loading=async&callback=placesLoadedCallback');
    if (!language) {
        // eslint-disable-next-line react/jsx-no-useless-fragment
        return _jsx(_Fragment, {});
    }
    return (_jsx("div", __assign({ className: "App" }, { children: _jsxs(ImpSnackbarProvider, { children: [_jsx(ScrollToTop, {}), _jsxs(Routes, { children: [_jsx(Route, { path: '/', element: _jsx(RootPage, {}) }), _jsx(Route, { path: PAGE.FIND_USER_ACCOUNT, element: _jsx(FindYourAccountFlow, {}) }), _jsx(Route, { path: PAGE.ERROR, element: _jsx(Error, {}) }), Object.values(LOGIN_PAGE).map(function (path) { return (_jsx(Route, { path: path, element: _jsx(Login, {}) }, path)); }), _jsx(Route, { path: PAGE.LOGIN, element: _jsx(Login, {}) }), _jsx(Route, __assign({ element: _jsxs(ProtectedRoute, { children: [_jsx(SessionManager, {}), _jsx(Outlet, {}), _jsx(Flyout, {})] }) }, { children: protectedRoutes.map(function (route) { return (_jsx(Route, { path: route.path, element: _jsx(route.component, {}) }, route.path)); }) })), _jsx(Route, { path: "*", element: _jsx(PageNotFound, {}) })] }), _jsx(Snackbar, { visible: snackbarManager.snackbarVisible, text: snackbarManager.snackbarText, type: snackbarManager.snackbarType })] }) })));
}
