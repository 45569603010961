import { getAnalytics, isSupported } from 'firebase/analytics';
import { store } from '../app/store';
import { analyticsMock } from '../testing/mocks/analytics';
import { isDev } from '../utils/environments';
import AnalyticsUtil from './analyticsUtil';
var AnalyticsWrapper = /** @class */ (function () {
    function AnalyticsWrapper(firebaseApp) {
        var _this = this;
        this.analyticsObj = analyticsMock;
        isSupported().then(function (res) {
            if (res) {
                _this.analyticsObj = getAnalytics(firebaseApp);
            }
        });
        this.analyticsUtil = new AnalyticsUtil();
    }
    AnalyticsWrapper.prototype.funcError = function (pkgName, funcName, reason) {
        this.analyticsUtil.funcError(pkgName, funcName, reason);
    };
    AnalyticsWrapper.prototype.logEvent = function (eventName, parameters, description) {
        if (isDev()) {
            // this is to make testing analytics easier
            console.log('logging event', {
                eventName: eventName,
                parameters: parameters,
                description: description,
            });
        }
        var sessionID = store.getState().apiScreenScreenSelector.sessionID;
        if (!parameters)
            parameters = {};
        if (sessionID !== '') {
            parameters.session_id = sessionID;
        }
        if (description) {
            parameters.description = description;
        }
        var analyticsObj = this.analyticsObj === analyticsMock ? undefined : this.analyticsObj;
        this.analyticsUtil.writeEvent(eventName, parameters, analyticsObj);
    };
    return AnalyticsWrapper;
}());
export default AnalyticsWrapper;
