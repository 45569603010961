var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { ImpBulletList, ImpButton, ImpContainer, ImpDivider, ImpFlex, ImpInlineLink, ImpPad, ImpSpacer, ImpText, colors, } from '@imprint-payments/imprint-ui';
import { AuthUsersPage, setAuthUsersPage } from '../../app/authUsersSlice';
import { FORM_BUTTON_WIDTH } from '../../assets/consts/const';
import { MerchantInfos, PRODUCTUUID_TO_MERCHANT, } from '../../utils/productUUIDConstants';
import { ANALYTICS } from '../../firebase/firebase';
import { EVENT } from '../../analytics/analyticsConsts';
export default function CreateAuthUserPage() {
    var _a, _b;
    var language = useAppSelector(function (state) { return state.languageSelector.languageLocalization; });
    var languageCode = useAppSelector(function (state) { return state.languageSelector.languageCode; });
    var homeState = useAppSelector(function (state) { return state.homeSelector; });
    var productUUID = ((_a = homeState.selectedProductAccount) === null || _a === void 0 ? void 0 : _a.productUUID) || '';
    var dispatch = useAppDispatch();
    return (_jsx(ImpContainer, __assign({ textAlign: "center" }, { children: _jsxs(ImpFlex, __assign({ direction: "column", align: "center" }, { children: [_jsx(ImpText, __assign({ typography: "headline2", mobileTypography: "headline3" }, { children: language.authorizedUsers })), _jsx(ImpSpacer, { height: 'l' }), _jsx(ImpText, __assign({ typography: "body1", mobileTypography: "body2" }, { children: language.authUserTagline })), _jsx(ImpSpacer, { height: 'l' }), _jsx(ImpDivider, { color: colors.border.borderVariant }), _jsx(ImpSpacer, { height: 'l' }), _jsx(ImpContainer, __assign({ width: "100%" }, { children: _jsx(ImpPad, __assign({ padding: "0px 8px" }, { children: _jsx(ImpBulletList, { items: language.authValueProps }) })) })), _jsx(ImpSpacer, { height: 'xl' }), _jsx(ImpContainer, __assign({ width: 'min(504px, 100vw - 32px)' }, { children: _jsxs(ImpText, __assign({ typography: "body3", color: "#5E5D5C" }, { children: [language.peopleYouShareYourCardWith, _jsx(ImpInlineLink, __assign({ url: (_b = MerchantInfos[PRODUCTUUID_TO_MERCHANT[productUUID]]) === null || _b === void 0 ? void 0 : _b.rewardTerms[languageCode], color: 'var(--merchant-color)' }, { children: language.rewardsTermsConditions })), "."] })) })), _jsx(ImpSpacer, { height: 'xl' }), _jsx(ImpContainer, __assign({ width: FORM_BUTTON_WIDTH }, { children: _jsx(ImpButton, __assign({ size: "fill", variant: "primary", buttonStyle: "branded", onClick: function () {
                            ANALYTICS.logEvent(EVENT.AUTH_USER_APPLICATION_STARTED);
                            dispatch(setAuthUsersPage(AuthUsersPage.VERIFY_PIN));
                        } }, { children: language.addAuthorizedUser })) }))] })) })));
}
