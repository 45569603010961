import { getEmptyAmount } from './Amount';
import { getEmptyDisplayPercent } from './DisplayPercent';
import { getEmptyStatementSpendDisplay } from './StatementSpendDisplay';
export var getEmptyCardBalanceV2 = function () {
    return {
        totalSpend: getEmptyAmount(),
        available: getEmptyAmount(),
        spendPercentage: getEmptyDisplayPercent(),
        pendingSpendPercentage: getEmptyDisplayPercent(),
        previousStatementSpend: getEmptyStatementSpendDisplay(),
        currentStatementSpend: getEmptyStatementSpendDisplay(),
    };
};
