var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { ConfirmationScreen } from './ConfirmationScreen';
import { ImpButton, ImpContainer, ImpFlex, ImpOtpField, ImpSpacer, ImpText, } from '@imprint-payments/imprint-ui';
import { AuthUsersPage, setAuthUsersPage } from '../../app/authUsersSlice';
import { activatePhysicalCard } from '../../api/physicalCardActions/activatePhysicalCard.api';
import { loadProductAccount } from '../../api/loadProductAccount/loadProductAccount';
import { ANALYTICS } from '../../firebase/firebase';
import { EVENT } from '../../analytics/analyticsConsts';
export function AuthActivateCard() {
    var _a = useState(false), isConfirmed = _a[0], setIsConfirmed = _a[1];
    var dispatch = useAppDispatch();
    var authUserState = useAppSelector(function (state) { return state.authUsersSelector; });
    var language = useAppSelector(function (state) { return state.languageSelector.languageLocalization; });
    var homeSelector = useAppSelector(function (state) { return state.homeSelector; });
    var _b = useState(false), clearWithError = _b[0], setClearWithError = _b[1];
    var handleChange = function () {
        setClearWithError(false);
    };
    if (isConfirmed) {
        return (_jsx(ConfirmationScreen, { title: language.theirCardReady, subtitle: language.theirCardReadySubtitle, actionButton: _jsx(ImpButton, __assign({ size: "fill", buttonStyle: "branded", variant: "primary", onClick: function () {
                    var _a;
                    var productAccountUUID = ((_a = homeSelector.selectedProductAccount) === null || _a === void 0 ? void 0 : _a.productAccountUUID) || '';
                    loadProductAccount({ productAccountUUID: productAccountUUID });
                    dispatch(setAuthUsersPage(AuthUsersPage.DEFAULT));
                } }, { children: language.done })) }));
    }
    return (_jsx(ImpContainer, __assign({ textAlign: "center" }, { children: _jsxs(ImpFlex, __assign({ direction: "column", align: "center" }, { children: [_jsx(ImpText, __assign({ typography: "headline2", mobileTypography: "headline3" }, { children: language.authActivateCardTitle })), _jsx(ImpSpacer, { height: "l" }), _jsx(ImpText, __assign({ typography: "body2" }, { children: language.authActivatePhysicalCardSubtitle })), _jsx(ImpSpacer, { height: "xl" }), _jsx(ImpContainer, __assign({ width: "min(393px, 100vw - 32px)" }, { children: _jsx(ImpOtpField, { numInputs: 4, onChange: handleChange, clearWithError: clearWithError, onFull: function (last4) {
                            var cardUUID = authUserState.selectedAuthorizedUser.cardUUID;
                            activatePhysicalCard({
                                cardUUID: cardUUID,
                                last4: last4,
                                onSuccess: function () {
                                    ANALYTICS.logEvent(EVENT.AUTH_USER_ACTIVATE_CARD_COMPLETED);
                                    setIsConfirmed(true);
                                },
                                onError: function () {
                                    setClearWithError(true);
                                },
                            });
                        } }) }))] })) })));
}
