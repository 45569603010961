import { createSelector } from '@reduxjs/toolkit';
var getWidgets = function (state) { return state.productAccountSelector.widgets; };
export var selectPhysicalCardWidget = function (state) { return state.productAccountSelector.shippingWidget; };
export var selectPhysicalCard = function (state) { return state.productAccountSelector.physicalCard; };
export var selectProductAccount = function (state) { return state.productAccountSelector; };
export var selectSelectedProductUUID = function (state) { var _a; return (_a = state.productAccountSelector.productDetails) === null || _a === void 0 ? void 0 : _a.productUUID; };
export var selectPhysicalCardUUID = function (state) { var _a; return (_a = state.productAccountSelector.physicalCard) === null || _a === void 0 ? void 0 : _a.cardUUID; };
export var selectVirtualCardUUID = function (state) { var _a; return (_a = state.productAccountSelector.virtualCard) === null || _a === void 0 ? void 0 : _a.cardUUID; };
export var selectVirtualCardIssuer = function (state) { var _a; return (_a = state.productAccountSelector.virtualCard) === null || _a === void 0 ? void 0 : _a.issuerName; };
export var selectSpendReviewWidget = createSelector([getWidgets], function (widgets) {
    return widgets === null || widgets === void 0 ? void 0 : widgets.find(function (widget) { return widget.type === 'spend_review'; });
});
export var selectPaymentDueWidget = createSelector([getWidgets], function (widgets) {
    return widgets === null || widgets === void 0 ? void 0 : widgets.find(function (widget) { return widget.type === 'payment_due'; });
});
export var selectConnectABankWidget = createSelector([getWidgets], function (widgets) {
    return widgets === null || widgets === void 0 ? void 0 : widgets.find(function (widget) { return widget.type === 'link_payment_account'; });
});
export var selectPaymentOverdueWidget = createSelector([getWidgets], function (widgets) {
    return widgets === null || widgets === void 0 ? void 0 : widgets.find(function (widget) { return widget.type === 'payment_overdue'; });
});
export var selectAutopayWidget = createSelector([getWidgets], function (widgets) {
    return widgets === null || widgets === void 0 ? void 0 : widgets.find(function (widget) { return widget.type === 'set_up_payment'; });
});
export var selectPaymentDetails = function (state) {
    return state.productAccountSelector.paymentDetails;
};
export var selectUpcomingPaymentNoAutopayWidget = createSelector([getWidgets], function (widgets) {
    return widgets === null || widgets === void 0 ? void 0 : widgets.filter(function (widget) { return widget.type === 'upcoming_payment_no_autopay'; });
});
export var selectAutopayAmount = function (state) { var _a; return (_a = state.productAccountSelector.autopay.paymentPlan) === null || _a === void 0 ? void 0 : _a.amount; };
export var selectAutopay = function (state) { return state.productAccountSelector.autopay; };
export var selectCardBalanceV2 = function (state) { return state.productAccountSelector.cardBalanceV2; };
export var selectTotalSpend = function (state) { var _a; return (_a = state.productAccountSelector.cardBalanceV2) === null || _a === void 0 ? void 0 : _a.totalSpend; };
export var selectProductAccountRewards = function (state) {
    return state.productAccountSelector.rewards;
};
export var selectRewardsAvailableBalanceAmount = function (state) { var _a, _b, _c, _d; return (_d = (_c = (_b = (_a = state.productAccountSelector.rewards) === null || _a === void 0 ? void 0 : _a.rewardBalances) === null || _b === void 0 ? void 0 : _b.balances) === null || _c === void 0 ? void 0 : _c.available) === null || _d === void 0 ? void 0 : _d.amount; };
export var selectRewards = function (state) { return state.productAccountSelector.rewards; };
export var selectPrimaryAction = function (state) { var _a; return (_a = state.productAccountSelector.rewards) === null || _a === void 0 ? void 0 : _a.primaryAction; };
export var selectPartnerMembershipStatus = function (state) { var _a; return (_a = state.productAccountSelector.rewards) === null || _a === void 0 ? void 0 : _a.partnerMembershipStatus; };
export var selectRedemptionTypes = function (state) { var _a; return (_a = state.productAccountSelector.rewards) === null || _a === void 0 ? void 0 : _a.redemptionTypes; };
export var selectVirtualCardStatus = function (state) { var _a; return (_a = state.productAccountSelector.virtualCard) === null || _a === void 0 ? void 0 : _a.status; };
