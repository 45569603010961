import language from '../assets/languages/en.json';
// TODO: use in imprint-lib shared package (since these are the same in application and servicing)
export var ValidationResponse;
(function (ValidationResponse) {
    ValidationResponse["valid"] = "valid";
    ValidationResponse["empty"] = "empty";
    ValidationResponse["shortFullSSN"] = "shortFullSSN";
    ValidationResponse["shortLastFourSSN"] = "shortLastFourSSN";
    ValidationResponse["emailPotentialTypo"] = "emailPotentialTypo";
    ValidationResponse["invalidAge"] = "invalidAge";
    ValidationResponse["invalidAgeAuth"] = "invalidAgeAuth";
    ValidationResponse["invalidDate"] = "invalidDate";
    ValidationResponse["invalidFNShort"] = "invalidFNShort";
    ValidationResponse["invalidLNShort"] = "invalidLNShort";
    ValidationResponse["invalidNameLong"] = "invalidNameLong";
    ValidationResponse["invalidName"] = "invalidName";
    ValidationResponse["invalidEmail"] = "invalidEmail";
    ValidationResponse["invalidHhEmail"] = "invalidHhEmail";
    ValidationResponse["invalidEmailLong"] = "invalidEmailLong";
    ValidationResponse["invalidEmailSameAsCurrent"] = "invalidEmailSameAsCurrent";
    ValidationResponse["invalidPhoneNumber"] = "invalidPhoneNumber";
    ValidationResponse["invalidPhoneNumberOccupied"] = "invalidPhoneNumberOccupied";
    ValidationResponse["invalidActivationCode"] = "invalidActivationCode";
    ValidationResponse["invalidSSN"] = "invalidSSN";
    ValidationResponse["invalidSSNMatchLastFour"] = "invalidSSNMatchLastFour";
    ValidationResponse["invalidAddressLong"] = "invalidAddressLong";
    ValidationResponse["invalidAddressShort"] = "invalidAddressShort";
    ValidationResponse["invalidAddressPOBox"] = "invalidAddressPOBox";
    ValidationResponse["invalidCityLong"] = "invalidCityLong";
    ValidationResponse["invalidCityShort"] = "invalidCityShort";
    ValidationResponse["invalidZip"] = "invalidZip";
    ValidationResponse["invalidState"] = "invalidState";
    ValidationResponse["invalidServiceLogin"] = "invalidServiceLogin";
    ValidationResponse["invalidServiceDob"] = "invalidServiceDob";
    ValidationResponse["invalidServicePin"] = "invalidServicePin";
    ValidationResponse["invalidAccountNum"] = "invalidAccountNum";
    ValidationResponse["invalidRoutingNum"] = "invalidRoutingNum";
    ValidationResponse["accountAndRoutingNoMatch"] = "accountAndRoutingNoMatch";
    ValidationResponse["unableVerifyAccount"] = "unableVerifyAccount";
    ValidationResponse["invalidPinNoMatch"] = "invalidPinNoMatch";
    ValidationResponse["invalidPin"] = "invalidPin";
    ValidationResponse["invalidIncome"] = "invalidIncome";
    ValidationResponse["invalidHousing"] = "invalidHousing";
})(ValidationResponse || (ValidationResponse = {}));
export var noError = [
    ValidationResponse.valid,
    ValidationResponse.empty,
    ValidationResponse.emailPotentialTypo,
];
export var inputWarning = [ValidationResponse.emailPotentialTypo];
export var hideWhileTyping = [
    ValidationResponse.emailPotentialTypo,
    ValidationResponse.empty,
    ValidationResponse.invalidAddressShort,
    ValidationResponse.invalidCityShort,
    ValidationResponse.invalidEmail,
    ValidationResponse.invalidFNShort,
    ValidationResponse.invalidLNShort,
    ValidationResponse.invalidPhoneNumber,
    ValidationResponse.invalidPhoneNumberOccupied,
    ValidationResponse.invalidSSN,
    ValidationResponse.invalidZip,
    ValidationResponse.shortFullSSN,
    ValidationResponse.shortLastFourSSN,
];
export var getShowError = function (r) {
    return !noError.includes(r);
};
export var handleValueChange = function (validationResponse, setError) {
    if (!hideWhileTyping.includes(validationResponse)) {
        setError(validationResponse);
    }
    else {
        setError(ValidationResponse.empty);
    }
};
export function getValidationString(r, language) {
    switch (r) {
        case ValidationResponse.valid:
            return '';
        case ValidationResponse.invalidAge:
            return language.invalidBirthDateTooYoung;
        case ValidationResponse.invalidAgeAuth:
            return language.authInvalidBirthDateTooYoung;
        case ValidationResponse.invalidDate:
            return language.invalidBirthDate;
        case ValidationResponse.invalidFNShort:
            return language.invalidFNShort;
        case ValidationResponse.invalidLNShort:
            return language.invalidLNShort;
        case ValidationResponse.invalidName:
            return language.invalidName;
        case ValidationResponse.invalidNameLong:
            return language.invalidNameLong;
        case ValidationResponse.invalidEmail:
            return language.invalidEmail;
        case ValidationResponse.invalidHhEmail:
            return language.invalidHhEmail;
        case ValidationResponse.invalidEmailLong:
            return language.invalidEmailLong;
        case ValidationResponse.invalidEmailSameAsCurrent:
            return language.sameEmailError;
        case ValidationResponse.invalidPhoneNumber:
            return language.invalidPhoneNumber;
        case ValidationResponse.invalidPhoneNumberOccupied:
            return language.invalidPhoneNumberOccupied;
        case ValidationResponse.invalidSSN:
            return language.invalidSSN;
        case ValidationResponse.shortLastFourSSN:
            return language.invalidSSN;
        case ValidationResponse.invalidSSNMatchLastFour:
            return language.invalidSSNMatchLastFour;
        case ValidationResponse.shortFullSSN:
            return language.fullSSNErrmsg;
        case ValidationResponse.invalidAddressLong:
            return language.invalidAddressLong;
        case ValidationResponse.invalidCityLong:
            return language.invalidCityLong;
        case ValidationResponse.invalidAddressShort:
            return language.invalidAddressShort;
        case ValidationResponse.invalidAddressPOBox:
            return language.invalidAddressPOBox;
        case ValidationResponse.invalidCityShort:
            return language.invalidCityShort;
        case ValidationResponse.invalidZip:
            return language.invalidZip;
        case ValidationResponse.invalidState:
            return language.invalidState;
        case ValidationResponse.invalidServiceLogin:
            return language.invalidServiceLogin;
        case ValidationResponse.invalidServiceDob:
            return language.invalidServiceDob;
        case ValidationResponse.invalidAccountNum:
            return language.invalidAccountNum;
        case ValidationResponse.invalidRoutingNum:
            return language.invalidRoutingNum;
        case ValidationResponse.accountAndRoutingNoMatch:
            return language.accountAndRoutingNoMatch;
        case ValidationResponse.unableVerifyAccount:
            return language.unableVerifyAccount;
        case ValidationResponse.invalidPinNoMatch:
            return language.makeSurePinsMatch;
        case ValidationResponse.invalidPin:
            return language.invalidPin;
        case ValidationResponse.emailPotentialTypo:
            return language.emailTypo;
        default:
            return 'Something went wrong';
    }
}
var nameMinLength = 2;
var nameMaxLength = 26;
var minAge = 18;
var emailMaxLength = 254;
var addressMaxLength = 40;
var addressMinLength = 2;
var cityMaxLength = 30;
var cityMinLength = 2;
// TODO - move to imprint lib
export var validateEmail = function (e) {
    // check length
    if (e.length > emailMaxLength) {
        return ValidationResponse.invalidEmailLong;
    }
    // check valid characters
    var re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(com|net|co|edu|org|us|es|gov|me|ca|biz|fr|uk|eu|au|io|mil|info|au|nz|tv|de|jp|cn|es|in|it|nl|se|ch|ru|br|mx|ar|kr|tw|hk|sg|dk|no|fi|be|at|be|cz|ie|pl|pt|tr|za|gr|hu|is|ro)$/;
    if (!re.test(e.toLowerCase())) {
        return ValidationResponse.invalidEmail;
    }
    if (hasDomainTypo(e)) {
        return ValidationResponse.emailPotentialTypo;
    }
    return ValidationResponse.valid;
};
var COMMON_PROVIDERS = ['gmail.com', 'yahoo.com', 'hotmail.com', 'aol.com', 'icloud.com'];
function hasDomainTypo(email) {
    var domain = email.split('@').pop();
    // If domain typed in is up to 2 characters off
    return COMMON_PROVIDERS.some(function (provider) {
        return levenshteinDistance(provider, domain) >= 1 && levenshteinDistance(provider, domain) <= 2;
    });
}
function levenshteinDistance(s1, s2) {
    var dp = Array.from({ length: s1.length + 1 }, function () { return Array(s2.length + 1).fill(0); });
    for (var i = 0; i <= s1.length; i++) {
        for (var j = 0; j <= s2.length; j++) {
            if (i === 0) {
                dp[0][j] = j;
            }
            else if (j === 0) {
                dp[i][0] = i;
            }
            else {
                dp[i][j] = Math.min(dp[i - 1][j - 1] + (s1[i - 1] === s2[j - 1] ? 0 : 1), dp[i - 1][j] + 1, dp[i][j - 1] + 1);
            }
        }
    }
    return dp[s1.length][s2.length];
}
export var validatePhoneNumber = function (p) {
    var result = p.match(/\d/g);
    if (result && result[0] !== '0' && result[0] !== '1' && result.join('').length === 10) {
        return ValidationResponse.valid;
    }
    return ValidationResponse.invalidPhoneNumber;
};
export var validateBirthDate = function (d) {
    // check if date is valid length
    if (d.length !== 10) {
        return ValidationResponse.invalidDate;
    }
    // check if date is valid format
    var dateObj = new Date(d);
    if (Number.isNaN(dateObj.getDate())) {
        return ValidationResponse.invalidDate;
    }
    // check reasonable year
    var currentDate = new Date();
    if (dateObj.getFullYear() < 1900 || dateObj.getFullYear() > currentDate.getFullYear()) {
        return ValidationResponse.invalidDate;
    }
    var _a = d.split('/'), month = _a[0], day = _a[1], year = _a[2];
    if (Number.isNaN(dateObj.getDate()) ||
        // validates that generated date is same as intended date (solves for casting issues)
        dateObj.getDate() !== Number(day) ||
        dateObj.getMonth() !== Number(month) - 1 ||
        dateObj.getFullYear() !== Number(year)) {
        return ValidationResponse.invalidDate;
    }
    // check age
    var diff = currentDate.getTime() - dateObj.getTime();
    var minAgeMS = minAge * 365 * 24 * 60 * 60 * 1000;
    if (diff < minAgeMS) {
        return ValidationResponse.invalidAge;
    }
    return ValidationResponse.valid;
};
export var validateAuthBirthDate = function (d) {
    // check if date is valid length
    if (d.length !== 10) {
        return ValidationResponse.invalidDate;
    }
    // check if date is valid format
    var dateObj = new Date(d);
    if (Number.isNaN(dateObj.getDate())) {
        return ValidationResponse.invalidDate;
    }
    // check reasonable year
    var currentDate = new Date();
    if (dateObj.getFullYear() < 1900 || dateObj.getFullYear() > currentDate.getFullYear()) {
        return ValidationResponse.invalidDate;
    }
    var authMinAge = 13;
    var diff = currentDate.getTime() - dateObj.getTime();
    var minAgeMS = authMinAge * 365 * 24 * 60 * 60 * 1000;
    if (diff < minAgeMS) {
        return ValidationResponse.invalidAgeAuth;
    }
    return ValidationResponse.valid;
};
export var validateName = function (n, isFirst, needCheckLength) {
    if (needCheckLength === void 0) { needCheckLength = true; }
    // check valid characters
    var re = /^[A-Za-zñíáúóüéÑÍÁÚÓÜÉ`'‘’\-\ ,.]*$/;
    if (!re.test(n.toLowerCase())) {
        return ValidationResponse.invalidName;
    }
    if (!needCheckLength) {
        return ValidationResponse.valid;
    }
    if (n.length < nameMinLength) {
        return isFirst ? ValidationResponse.invalidFNShort : ValidationResponse.invalidLNShort;
    }
    // check against max length
    if (n.length > nameMaxLength) {
        return ValidationResponse.invalidNameLong;
    }
    return ValidationResponse.valid;
};
export var validateAddress = function (a, optional) {
    if (optional && a.length === 0) {
        return ValidationResponse.valid;
    }
    // po box regex
    var re = /(p|p\s|p\.|post)(\s|)(((o\s|o|o\.)|(0\s|0|0\.))|office)(\s|)(box)\b(.*?)/gim;
    if (a.toLowerCase().match(re)) {
        return ValidationResponse.invalidAddressPOBox;
    }
    if (a.length > addressMaxLength) {
        return ValidationResponse.invalidAddressLong;
    }
    if (a.length < addressMinLength) {
        return ValidationResponse.invalidAddressShort;
    }
    return ValidationResponse.valid;
};
export var validateCity = function (c) {
    if (c.length > cityMaxLength) {
        return ValidationResponse.invalidCityLong;
    }
    if (c.length < cityMinLength) {
        return ValidationResponse.invalidCityShort;
    }
    return ValidationResponse.valid;
};
export var validateState = function (c) {
    var validationRes = ValidationResponse.invalidState;
    if (c.length > 0) {
        for (var i = 0; i <= language.stateOption.length; i++) {
            if (c === language.stateOption[i]) {
                validationRes = ValidationResponse.valid;
                break;
            }
        }
    }
    return validationRes;
};
// given [ssn, last4?], returns True if valid SSN
// if last4 is provided, also checks that last4 matches last 4 digits of ssn
export var validateFullSSN = function (ssn, last4) {
    if (ssn.length < 11) {
        return ValidationResponse.invalidSSN;
    }
    // can't have 9 as first digit
    // can't have 666, 000 as first 3 digits
    // can't have 00 as middle 2 digits
    // can't have 0000 as last 4 digits
    // correct length/format
    var re = /^(?!666|000)[0-8][0-9_]{2}-(?!00)[0-9_]{2}-(?!0000)[0-9_]{4}$/;
    if (!re.test(ssn)) {
        return ValidationResponse.invalidSSN;
    }
    if (last4 && ssn.slice(-4) !== last4) {
        return ValidationResponse.invalidSSNMatchLastFour;
    }
    return ValidationResponse.valid;
};
export var validateLastFourSSN = function (ssn) {
    if (ssn.length < 4) {
        return ValidationResponse.shortLastFourSSN;
    }
    // cannot be 0000, must be 4 digits
    var re = /^(?!0000)[0-9]{4}$/;
    if (!re.test(ssn)) {
        return ValidationResponse.invalidSSN;
    }
    return ValidationResponse.valid;
};
export var validateZip = function (z) {
    if (z.length < 5) {
        return ValidationResponse.invalidZip;
    }
    return ValidationResponse.valid;
};
export var validateAccountNum = function (z) {
    if (z.length < 4) {
        return ValidationResponse.invalidAccountNum;
    }
    return ValidationResponse.valid;
};
export var validateRoutingNum = function (z) {
    if (z.length < 9) {
        return ValidationResponse.invalidRoutingNum;
    }
    return ValidationResponse.valid;
};
export var verifyAccountAndRoutingError = function (z) {
    if (z === 'noMatch') {
        return ValidationResponse.accountAndRoutingNoMatch;
    }
    if (z === 'error') {
        return ValidationResponse.unableVerifyAccount;
    }
    return ValidationResponse.empty;
};
export var isValidPin = function (pToValidate) {
    var p = Array.from(pToValidate);
    if (p.some(function (val) { return isNaN(parseInt(val)); }) || // Check for invalid characters
        p.every(function (val) { return val === p[0]; }) || // Check for same number
        p.every(function (val, i) { return val === (parseInt(p[0]) + i).toString(); }) // Check for sequential numbers
    ) {
        return ValidationResponse.invalidPin;
    }
    else {
        return ValidationResponse.valid;
    }
};
