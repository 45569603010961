var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ImpAccountStatus, ImpButton, ImpContainer, ImpSpacer, ImpText, } from '@imprint-payments/imprint-ui';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { EVENT } from '../../analytics/analyticsConsts';
import { selectAuth } from '../../app/authSelectors';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectOtpScreenState } from '../../app/otpScreenSelectors';
import { SCREEN } from '../../app/screenStateSlice';
import { supportEmail, transUnionArticleURL, transUnionLoginURL } from '../../assets/consts/const';
import HeaderServicing, { HEADER_SERVICING_TYPE, } from '../../components/HeaderServicing/HeaderServicing';
import { ANALYTICS } from '../../firebase/firebase';
import { handleReApplicationCookie, redirectToSignUp } from '../../utils/functionUtils';
export default function AccountStatusPage() {
    var dispatch = useAppDispatch();
    var navigate = useNavigate();
    var merchantData = useAppSelector(function (state) { return state.merchantDataSelector; });
    var screenState = useAppSelector(function (state) { return state.screenStateSelector; });
    var language = useAppSelector(function (state) { return state.languageSelector.languageLocalization; });
    var homeState = useAppSelector(function (state) { return state.homeSelector; });
    var showAccountStatus = homeState.showAccountStatus;
    var loading = useAppSelector(function (state) { return state.applicationScreenSelector.loading; });
    var apiScreenState = useAppSelector(function (state) { return state.apiScreenScreenSelector; });
    var appStatus = useAppSelector(function (state) { return state.applicationStatusSelector; });
    var consumerInfo = useAppSelector(function (state) { return state.consumerInfoSelector; });
    var _a = useState('false'), isMultiCardUser = _a[0], setIsMultiCardUser = _a[1];
    var auth = useAppSelector(selectAuth);
    var _b = useState(''), accountStatusProductUUID = _b[0], setAccountStatusProductUUID = _b[1];
    var otpScreenState = useAppSelector(selectOtpScreenState);
    var paragraphStyle = {
        whiteSpace: 'pre-line',
    };
    useEffect(function () {
        if (consumerInfo.multiCardUser) {
            setIsMultiCardUser('true');
            if (homeState.accountStatusProductUUID) {
                setAccountStatusProductUUID(homeState.accountStatusProductUUID);
            }
        }
        else {
            setAccountStatusProductUUID(merchantData.productUUID);
        }
    }, [consumerInfo.multiCardUser]);
    var getButtonState = function (loading) {
        if (loading) {
            return 'loading';
        }
        else {
            return 'enabled';
        }
    };
    var notApprovedHeader = function () {
        if (screenState.screen === SCREEN.APPLICATION_NOT_APPROVED_WITHIN_COOLDOWN) {
            return language.applicationNotApprovedTitle;
        }
        else {
            return language.applyAgainTitle;
        }
    };
    var renderNotApprovedBody = function () {
        return (_jsxs(ImpContainer, __assign({ textAlign: "center" }, { children: [_jsx(ImpSpacer, { height: "xl" }), _jsxs(ImpText, __assign({ typography: "body2", mobileTypography: "body3" }, { children: [language.applicationNotApprovedBody.replace('$cardName$', merchantData.rewardDetails.cardName), screenState.state === SCREEN.APPLICATION_NOT_APPROVED_AFTER_COOLDOWN
                            ? language.applyAgainText
                            : language.rejectedWithinCoolDownSubText] }))] })));
    };
    var handleApplyAgain = function () {
        var analyticsEventName = '';
        switch (screenState.screen) {
            case SCREEN.APPLICATION_NOT_APPROVED_AFTER_COOLDOWN: {
                analyticsEventName = EVENT.SERVICING_DECISION_DENIED_ACTION_CLICKED;
                break;
            }
            case SCREEN.CREDIT_FROZEN: {
                analyticsEventName = EVENT.SERVICING_DECISION_FROZEN_ACTION_CLICKED;
                break;
            }
            case SCREEN.ELIGIBLE: {
                analyticsEventName = EVENT.SERVICING_DECISION_ELIGIBLE_ACTION_CLICKED;
                break;
            }
            case SCREEN.REAPPLY: {
                analyticsEventName = EVENT.SERVICING_DECISION_REAPPLY_ACTION_CLICKED;
                break;
            }
            case SCREEN.APPLICATION_EXPIRED: {
                analyticsEventName = EVENT.SERVICING_DECISION_EXPIRED_OFFER_ACTION_CLICKED;
                break;
            }
        }
        ANALYTICS.logEvent(analyticsEventName, {
            product_uuid: accountStatusProductUUID,
        });
        if (!(screenState.screen === SCREEN.ELIGIBLE || screenState.screen === SCREEN.REAPPLY)) {
            handleReApplicationCookie(auth.token, apiScreenState.deviceID, accountStatusProductUUID, isMultiCardUser);
        }
        // TODO: we may need a better place to store the phone that has been otped
        var signUpPhone = otpScreenState.destination;
        redirectToSignUp(accountStatusProductUUID, false, signUpPhone);
    };
    var renderApplyAgainButton = function () {
        var buttonCopy = language.applyAgain;
        switch (screenState.screen) {
            case SCREEN.ELIGIBLE: {
                buttonCopy = language.apply;
                break;
            }
            case SCREEN.CREDIT_FROZEN: {
                buttonCopy = language.unfrozeMyCredit;
                break;
            }
        }
        if (screenState.screen !== SCREEN.APPLICATION_NOT_APPROVED_WITHIN_COOLDOWN) {
            return (_jsxs(ImpContainer, __assign({ textAlign: "center" }, { children: [_jsx(ImpSpacer, { height: "2xl" }), _jsx(ImpButton, __assign({ variant: "primary", buttonStyle: "branded", size: "m", state: getButtonState(loading), onClick: function () { return handleApplyAgain(); } }, { children: buttonCopy }))] })));
        }
        else {
            return _jsx(_Fragment, {});
        }
    };
    var frozenCreditHeader = function () {
        return language.afterOtpAppStatusFrozenCreditHeader;
    };
    var renderFrozenCreditBody2 = function () {
        return (_jsxs(ImpContainer, { children: [_jsx(ImpSpacer, { height: "xl" }), _jsx(ImpText, __assign({ typography: "body2", mobileTypography: "body3" }, { children: _jsxs("div", __assign({ style: paragraphStyle }, { children: [language.enumerateOne, _jsx(ImpButton, __assign({ variant: "inline", onClick: function () {
                                    window.open(transUnionLoginURL, '_blank');
                                } }, { children: language.LogIn })), language.creditFrozenBody2] })) }))] }));
    };
    var renderFrozenCreditBody3 = function () {
        return (_jsxs(ImpContainer, { children: [_jsx(ImpSpacer, { height: "xl" }), _jsx(ImpText, __assign({ typography: "body2", mobileTypography: "body3" }, { children: _jsxs("div", __assign({ style: paragraphStyle }, { children: [language.creditFrozenBody3ReApplication.replace('$expireDate$', appStatus.expiresAt), _jsx(ImpButton, __assign({ variant: "inline", onClick: function () {
                                    window.open(transUnionArticleURL, '_blank');
                                } }, { children: language.thisArticle })), language.creditFrozenBody5] })) }))] }));
    };
    var renderManualReviewBody = function () {
        return (_jsxs(ImpContainer, __assign({ textAlign: "center" }, { children: [_jsx(ImpSpacer, { height: "xl" }), _jsxs(ImpText, __assign({ typography: "body2", mobileTypography: "body3" }, { children: [language.applicationInReviewBody, _jsx(ImpButton, __assign({ variant: "inline", onClick: function () {
                                window.location.href = 'mailto:support@imprint.co';
                            } }, { children: supportEmail })), language.period] }))] })));
    };
    var renderAccountStatus = function () {
        switch (screenState.screen) {
            case SCREEN.APPLICATION_IN_REVIEW: {
                return (_jsx(ImpAccountStatus, { header: language.applicationInReviewHeader, body1: renderManualReviewBody(), body1CenterAlign: true }));
            }
            case SCREEN.APPLICATION_NOT_APPROVED_WITHIN_COOLDOWN:
            case SCREEN.APPLICATION_NOT_APPROVED_AFTER_COOLDOWN: {
                return (_jsx(ImpAccountStatus, { header: notApprovedHeader(), body1: renderNotApprovedBody(), body1CenterAlign: true, buttons: renderApplyAgainButton() }));
            }
            case SCREEN.ELIGIBLE: {
                return (_jsx(ImpAccountStatus, { header: language.eligibleSignUpTitle, body1: language.eligibleSignUpBody.replace('$cardName$', merchantData.rewardDetails.cardName), body1CenterAlign: true, buttons: renderApplyAgainButton() }));
            }
            case SCREEN.REAPPLY: {
                return (_jsx(ImpAccountStatus, { header: language.applyAgainTitle, body1: language.reApplySignUpBody.replace('$cardName$', merchantData.rewardDetails.cardName), body1CenterAlign: true, buttons: renderApplyAgainButton() }));
            }
            case SCREEN.CREDIT_FROZEN: {
                return (_jsx(ImpAccountStatus, { header: frozenCreditHeader(), body1: language.creditFrozenBody, body2: renderFrozenCreditBody2(), body3: renderFrozenCreditBody3(), buttons: renderApplyAgainButton() }));
            }
            case SCREEN.APPLICATION_EXPIRED: {
                return (_jsx(ImpAccountStatus, { header: language.applyAgainTitle, body1: language.reApplySignUpBody.replace('$cardName$', merchantData.rewardDetails.cardName), body1CenterAlign: true, buttons: renderApplyAgainButton() }));
            }
        }
    };
    return (_jsxs(_Fragment, { children: [_jsx(HeaderServicing, { type: showAccountStatus ? HEADER_SERVICING_TYPE.DEFAULT : HEADER_SERVICING_TYPE.LOGOUT }), renderAccountStatus()] }));
}
