import { jsx as _jsx } from "react/jsx-runtime";
import { Navigate } from 'react-router-dom';
import { PAGE } from '../../utils/routeConstant';
import { useRootRedirect } from './useRootRedirect';
export function RootPage() {
    var expectedPage = useRootRedirect();
    if (expectedPage === 'account') {
        return _jsx(Navigate, { to: PAGE.ACCOUNT });
    }
    else if (expectedPage === 'loading') {
        return _jsx("h1", { children: "Loading..." });
    }
    else if (expectedPage === 'pin') {
        return _jsx(Navigate, { to: PAGE.LOGIN });
    }
    else {
        return _jsx(Navigate, { to: PAGE.LOGIN });
    }
}
