var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../app/hooks';
import { sanitize, validateUrlOrReturnEmpty } from '../../utils/functionUtils';
import { useAppSelector } from '../../app/hooks';
import { MERCHANT_QR_INFO } from '../../utils/productUUIDConstants';
export default function DynamicQRCode(_a) {
    var height = _a.height, width = _a.width, error = _a.error, image = _a.image;
    var dispatch = useAppDispatch();
    var navigate = useNavigate();
    var productUUID = useAppSelector(function (state) { return state.merchantDataSelector.productUUID; });
    var qrInfo = MERCHANT_QR_INFO.find(function (i) { return i.productUUID === productUUID; });
    var utmSource = sessionStorage.getItem('utm_source');
    var sanitizedProductUUID = sanitize(productUUID || '');
    var sanitizedLpName = sanitize((qrInfo === null || qrInfo === void 0 ? void 0 : qrInfo.lpName) || '');
    var sanitizedIosLink = validateUrlOrReturnEmpty((qrInfo === null || qrInfo === void 0 ? void 0 : qrInfo.iosLink) || '');
    var sanitizedAndroidLink = validateUrlOrReturnEmpty((qrInfo === null || qrInfo === void 0 ? void 0 : qrInfo.androidLink) || '');
    var sanitizedUtmSource = sanitize(utmSource || '');
    if (error) {
        image = qrInfo === null || qrInfo === void 0 ? void 0 : qrInfo.jpegError;
    }
    return (_jsxs("div", __assign({ id: "qr-code-1" }, { children: [_jsx("script", { src: "https://app.stg.imprint.co/qr-code/dynamic-qrcode.js" }), _jsxs("script", { children: ["window.dynamicQrcode.generateDeeplink(", sanitizedProductUUID, ", \"#qr-code-1\",", sanitizedUtmSource, ",", sanitizedLpName, ",", sanitizedIosLink, ",", sanitizedAndroidLink, ");"] }), _jsx("img", { src: image, width: width, height: height, tabIndex: 0, alt: "download app dynamic qr code" })] })));
}
