// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DHy_91C9AUvbzzizTVrl{\n    display: grid;\n    grid-template-rows: 64px 1fr;\n    height: 100vh;\n}\n.k5FfIuulSRkAg7DPgYLO{\n    height: 100%;\n}", "",{"version":3,"sources":["webpack://./src/pages/offerPage/OfferPage.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,4BAA4B;IAC5B,aAAa;AACjB;AACA;IACI,YAAY;AAChB","sourcesContent":[".container{\n    display: grid;\n    grid-template-rows: 64px 1fr;\n    height: 100vh;\n}\n.offerWrapper{\n    height: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "DHy_91C9AUvbzzizTVrl",
	"offerWrapper": "k5FfIuulSRkAg7DPgYLO"
};
module.exports = ___CSS_LOADER_EXPORT___;
