var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a, _b;
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ImpInlineLink, ImpText, colors } from '@imprint-payments/imprint-ui';
import { ImprintConstants } from '../../api/apiConstants';
import { useAppSelector } from '../../app/hooks';
import { selectLanguage, selectLanguageCode } from '../../app/languageSelectors';
import { selectMerchantData } from '../../app/merchantDataSelectors';
import { LanguageCode } from '../../utils/languageConstants';
import { MerchantInfos, PRODUCTUUID, PRODUCTUUID_TO_MERCHANT, } from '../../utils/productUUIDConstants';
export default function Terms(_a) {
    var isConversion = _a.isConversion;
    var language = useAppSelector(selectLanguage);
    var languageCode = useAppSelector(selectLanguageCode);
    var productUUID = useAppSelector(selectMerchantData).productUUID;
    var getRewardsTermsAndConditions = function () {
        var _a;
        if (isConversion && productUUID === PRODUCTUUID.BB) {
            return temporaryBBConversionLinks.tc[languageCode];
        }
        return ((_a = MerchantInfos[PRODUCTUUID_TO_MERCHANT[productUUID]]) === null || _a === void 0 ? void 0 : _a.rewardTerms[languageCode]) || '';
    };
    var getPrivacyPolicy = function () {
        return languageCode === LanguageCode.English
            ? ImprintConstants.ImprintPrivacy
            : ImprintConstants.ImprintPrivacyES;
    };
    var getEsignLink = function () {
        return languageCode === LanguageCode.English
            ? ImprintConstants.ImprintEsign
            : ImprintConstants.ImprintEsignES;
    };
    var getCardholderAgreement = function () {
        var _a, _b;
        if (isConversion && productUUID === PRODUCTUUID.BB) {
            return temporaryBBConversionLinks.cha[languageCode];
        }
        return (((_b = (_a = MerchantInfos[PRODUCTUUID_TO_MERCHANT[productUUID]]) === null || _a === void 0 ? void 0 : _a.cardholderAgreement) === null || _b === void 0 ? void 0 : _b[languageCode]) || '');
    };
    var getCardholderInfoSharingNotice = function () {
        var _a;
        return ((_a = MerchantInfos[PRODUCTUUID_TO_MERCHANT[productUUID]]) === null || _a === void 0 ? void 0 : _a.infoSharing[languageCode]) || '';
    };
    var getCardIssuerDisclaimer = function () {
        switch (productUUID) {
            case PRODUCTUUID.BB:
                return language.issuerDisclaimerBB;
            // Add more cases here if used by other merchants
            default:
                return language.issuerDisclaimer;
        }
    };
    return (_jsxs(ImpText, __assign({ typography: 'body3', color: colors.content.onBackgroundVariant }, { children: [language.termsClickingContinue, _jsx(ImpInlineLink, __assign({ url: getCardholderAgreement(), bold: true, branded: true }, { children: language.creditCardholderAgreement })), ', ', _jsx(ImpInlineLink, __assign({ url: getRewardsTermsAndConditions(), bold: true, branded: true }, { children: language.rewardsTermsConditions })), ', ', _jsx(ImpInlineLink, __assign({ url: getPrivacyPolicy(), bold: true, branded: true }, { children: language.privacyPolicy })), ', ', _jsx(ImpInlineLink, __assign({ url: getEsignLink(), bold: true, branded: true }, { children: language.eSignPolicy })), language.clickingContinue3, _jsx(ImpInlineLink, __assign({ url: getCardholderInfoSharingNotice(), bold: true, branded: true }, { children: language.cardholderInfoSharingNotice })), '. ', getCardIssuerDisclaimer()] })));
}
var temporaryBBConversionLinks = {
    tc: (_a = {},
        _a[LanguageCode.English] = 'https://www.brooksbrotherscreditcard.com/credit/legal/rewards-terms/en-us ',
        _a[LanguageCode.Spanish] = 'https://www.brooksbrotherscreditcard.com/credit/legal/rewards-terms/es-us ',
        _a),
    cha: (_b = {},
        _b[LanguageCode.English] = 'https://www.brooksbrotherscreditcard.com/credit/legal/conversion-credit-cardholder-agreement/en-us',
        _b[LanguageCode.Spanish] = 'https://www.brooksbrotherscreditcard.com/credit/legal/conversion-credit-cardholder-agreement/es-us',
        _b),
};
