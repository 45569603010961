import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useMemo } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { selectAuth } from '../../app/authSelectors';
import { useAppSelector } from '../../app/hooks';
import { PAGE } from '../../utils/routeConstant';
import { invalidSession } from './protectedRouteUtils';
var useAuth = function () {
    var auth = useAppSelector(selectAuth);
    var isValidSession = useMemo(function () {
        return !invalidSession(auth);
    }, [auth]);
    return {
        isLoggedIn: isValidSession,
    };
};
export function ProtectedRoute(_a) {
    var children = _a.children;
    var isLoggedIn = useAuth().isLoggedIn;
    var location = useLocation();
    if (!isLoggedIn) {
        return _jsx(Navigate, { to: PAGE.LOGIN, state: { from: location }, replace: true });
    }
    return _jsx(_Fragment, { children: children });
}
