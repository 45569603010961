var _a;
export var ResponseCode;
(function (ResponseCode) {
    ResponseCode[ResponseCode["OK"] = 200] = "OK";
    ResponseCode[ResponseCode["INCORRECT_DOB"] = 101] = "INCORRECT_DOB";
    ResponseCode[ResponseCode["CREATED"] = 201] = "CREATED";
    ResponseCode[ResponseCode["ACCEPTED"] = 202] = "ACCEPTED";
    ResponseCode[ResponseCode["NO_CONTENT"] = 204] = "NO_CONTENT";
    ResponseCode[ResponseCode["MOVED_PERMANENTLY"] = 301] = "MOVED_PERMANENTLY";
    ResponseCode[ResponseCode["FOUND"] = 302] = "FOUND";
    ResponseCode[ResponseCode["SEE_OTHER"] = 303] = "SEE_OTHER";
    ResponseCode[ResponseCode["NOT_MODIFIED"] = 304] = "NOT_MODIFIED";
    ResponseCode[ResponseCode["TEMPORARY_REDIRECT"] = 307] = "TEMPORARY_REDIRECT";
    ResponseCode[ResponseCode["BAD_REQUEST"] = 400] = "BAD_REQUEST";
    ResponseCode[ResponseCode["UNAUTHORIZED"] = 401] = "UNAUTHORIZED";
    ResponseCode[ResponseCode["FORBIDDEN"] = 403] = "FORBIDDEN";
    ResponseCode[ResponseCode["NOT_FOUND"] = 404] = "NOT_FOUND";
    ResponseCode[ResponseCode["CONFLICT"] = 409] = "CONFLICT";
    ResponseCode[ResponseCode["UNPROCESSABLE_ENTITY"] = 422] = "UNPROCESSABLE_ENTITY";
    ResponseCode[ResponseCode["INTERNAL_SERVER_ERROR"] = 500] = "INTERNAL_SERVER_ERROR";
    ResponseCode[ResponseCode["NOT_IMPLEMENTED"] = 501] = "NOT_IMPLEMENTED";
    ResponseCode[ResponseCode["BAD_GATEWAY"] = 502] = "BAD_GATEWAY";
    ResponseCode[ResponseCode["SERVICE_UNAVAILABLE"] = 503] = "SERVICE_UNAVAILABLE";
    ResponseCode[ResponseCode["HTTP_ERROR"] = 600] = "HTTP_ERROR";
})(ResponseCode || (ResponseCode = {}));
export var AuthError;
(function (AuthError) {
    AuthError["SessionExpired"] = "301";
    AuthError["AuthError"] = "101";
    AuthError["AttemptsExhausted"] = "201";
})(AuthError || (AuthError = {}));
export var CardError;
(function (CardError) {
    CardError["Last4NotMatch"] = "310";
})(CardError || (CardError = {}));
export var Method;
(function (Method) {
    Method["GET"] = "get";
    Method["POST"] = "post";
    Method["PUT"] = "put";
    Method["PATCH"] = "patch";
    Method["DELETE"] = "delete";
})(Method || (Method = {}));
export var RETRY_CONFIG = (_a = {},
    // should be moved to API config
    _a[Method.GET] = 3,
    _a[Method.POST] = 1,
    _a[Method.PUT] = 1,
    _a[Method.PATCH] = 1,
    _a[Method.DELETE] = 1,
    _a);
export var RequestType;
(function (RequestType) {
    RequestType[RequestType["INTERNAL"] = 0] = "INTERNAL";
    RequestType[RequestType["EXTERNAL"] = 1] = "EXTERNAL";
})(RequestType || (RequestType = {}));
export var HEADERS = {
    device: 'x-imprint-device-id',
    signature: 'x-imprint-signature',
    language: 'Accept-Language',
    token: 'x-imprint-access-token',
    platform: 'x-imprint-platform',
};
export var PLATFORM = 'WEB';
export var DEFAULT_DELAY = 5000; // 5 seconds - TODO: make this configurable per API
