var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ImpButton, ImpContainer, ImpFlex, ImpSpacer, ImpText, colors } from '@imprint-payments/imprint-ui';
import { FORM_BUTTON_WIDTH } from '../../assets/consts/const';
export default function CancelPage(_a) {
    var header = _a.header, subheader = _a.subheader, goBackBtnValue = _a.goBackBtnValue, confirmBtnValue = _a.confirmBtnValue, isLoading = _a.isLoading, goBackClick = _a.goBackClick, confirmClick = _a.confirmClick;
    return (_jsxs(_Fragment, { children: [_jsx(ImpSpacer, { height: '5xl' }), _jsx(ImpContainer, __assign({ textAlign: "center", width: 'min(454px, 100%)' }, { children: _jsxs(ImpFlex, __assign({ direction: "column", align: "center" }, { children: [_jsx(ImpText, __assign({ typography: "headline2" }, { children: header })), _jsx(ImpSpacer, { height: 'l' }), _jsx(ImpContainer, __assign({ textAlign: "center" }, { children: _jsx(ImpText, __assign({ typography: "body2", mobileTypography: "body3", color: colors.content.onBackgroundVariant }, { children: subheader })) })), _jsx(ImpSpacer, { height: '2xl' }), _jsx(ImpContainer, __assign({ width: FORM_BUTTON_WIDTH }, { children: _jsx(ImpButton, __assign({ onClick: goBackClick, variant: 'primary', size: "fill" }, { children: goBackBtnValue })) })), _jsx(ImpSpacer, { height: 'm' }), _jsx(ImpContainer, __assign({ width: FORM_BUTTON_WIDTH }, { children: _jsx(ImpButton, __assign({ onClick: confirmClick, state: isLoading ? 'submitting' : 'enabled', variant: 'secondary', size: "fill" }, { children: confirmBtnValue })) }))] })) }))] }));
}
