var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ImpButton, ImpContainer, ImpFlex, ImpInput, ImpSpacer, ImpText, } from '@imprint-payments/imprint-ui';
import { useEffect, useState } from 'react';
import { EVENT } from '../../analytics/analyticsConsts';
import { editPhone } from '../../api/PersonalInfo/UpdatePhone/editPhone.api';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectLanguage } from '../../app/languageSelectors';
import { ANALYTICS } from '../../firebase/firebase';
import { formatPhoneNumber } from '../../utils/formatUtils';
import { displayTemporarySnackbar } from '../../utils/functionUtils';
import { ValidationResponse, getShowError, getValidationString, handleValueChange, validatePhoneNumber, } from '../../utils/validateUtils';
export function EditPhonePage(_a) {
    var onOtpSent = _a.onOtpSent;
    var dispatch = useAppDispatch();
    var language = useAppSelector(selectLanguage);
    var _b = useState(''), phone = _b[0], setPhone = _b[1];
    var _c = useState(ValidationResponse.empty), phoneError = _c[0], setPhoneError = _c[1];
    var _d = useState(false), isSubmitting = _d[0], setIsSubmitting = _d[1];
    useEffect(function () {
        handleValueChange(validatePhoneNumber(phone), setPhoneError);
    }, [phone]);
    var getContinueButtonState = function () {
        if (isSubmitting)
            return 'submitting';
        if (phoneError === ValidationResponse.valid)
            return 'enabled';
        return 'disabled';
    };
    var handleContinueClick = function () {
        ANALYTICS.logEvent(EVENT.PERSONAL_INFO_PHONE_EDIT_CONTINUE_CLICKED);
        setIsSubmitting(true);
        editPhone({
            phone: phone.replace(/\D/g, ''),
            sendVoice: false,
            onFail: function () {
                setIsSubmitting(false);
                displayTemporarySnackbar(language.somethingWentWrong, dispatch);
            },
            onSuccess: function () {
                setIsSubmitting(false);
                onOtpSent();
            },
        });
    };
    return (_jsx(ImpContainer, __assign({ width: "100%", textAlign: "center" }, { children: _jsxs(ImpFlex, __assign({ direction: "column", align: "center" }, { children: [_jsx(ImpText, __assign({ typography: "headline2" }, { children: language.editPhoneHeader })), _jsx(ImpSpacer, { height: "xl" }), _jsx(ImpInput, { label: language.phoneNumber, value: phone, maxLength: 16, onChange: function (e) { return setPhone(formatPhoneNumber(e.target.value)); }, onBlur: function () { return setPhoneError(validatePhoneNumber(phone)); }, showError: getShowError(phoneError), errorMessage: getValidationString(phoneError, language), isDisabled: isSubmitting, autofocus: true, numeric: true, type: "tel" }), _jsx(ImpSpacer, { height: "xl" }), _jsx(ImpButton, __assign({ buttonStyle: "imprint", state: getContinueButtonState(), onClick: handleContinueClick }, { children: language.continueBtn }))] })) })));
}
