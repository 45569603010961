var _a, _b, _c;
import { ActivityIcons, Icons, colors } from '@imprint-payments/imprint-ui';
import { ActivityType } from '../../api/apiConstants';
export var ActivityStatus;
(function (ActivityStatus) {
    ActivityStatus["Scheduled"] = "SCHEDULED";
    ActivityStatus["Submitted"] = "SUBMITTED";
    ActivityStatus["Pending"] = "PENDING";
    ActivityStatus["Confirmed"] = "CONFIRMED";
    ActivityStatus["Rejected"] = "REJECTED";
    ActivityStatus["Canceled"] = "CANCELED";
})(ActivityStatus || (ActivityStatus = {}));
export var statusToBadgeMap = (_a = {},
    _a[ActivityStatus.Scheduled] = {
        text: 'scheduled',
        color: colors.content.onSurfaceVariant,
    },
    _a[ActivityStatus.Submitted] = {
        text: 'pending',
        color: colors.content.onSurfaceVariant,
    },
    _a[ActivityStatus.Pending] = {
        text: 'pending',
        color: colors.content.onSurfaceVariant,
    },
    _a[ActivityStatus.Confirmed] = {
        text: 'complete',
        color: colors.content.onSurfaceSuccess,
    },
    _a[ActivityStatus.Rejected] = {
        text: 'declined',
        color: colors.content.onSurfaceError,
    },
    _a[ActivityStatus.Canceled] = {
        text: 'canceled',
        color: colors.content.onSurfaceDisabled,
    },
    _a);
export var activityTypeToIcon = (_b = {},
    _b[ActivityType.auth] = [ActivityIcons.bag, colors.content.onSurface],
    _b[ActivityType.transaction] = [ActivityIcons.bag, colors.content.onSurface],
    _b[ActivityType.dispute] = [ActivityIcons.bag, colors.content.onSurface],
    _b[ActivityType.payment] = [ActivityIcons.commerce, colors.content.onSurfaceSuccess],
    _b[ActivityType.refund] = [ActivityIcons.receipt, colors.content.onSurfaceSuccess],
    _b[ActivityType.paymentCheck] = [ActivityIcons.commerce, colors.content.onSurfaceSuccess],
    _b[ActivityType.rewardWithdrawalACH] = [ActivityIcons.arrow, colors.content.onSurface],
    _b[ActivityType.rewardWithdrawalCheck] = [ActivityIcons.arrow, colors.content.onSurface],
    _b[ActivityType.reward] = [ActivityIcons.circleWavyCheck, colors.content.onSurfaceSuccess],
    _b[ActivityType.oneTime] = [ActivityIcons.circleWavyCheck, colors.content.onSurfaceSuccess],
    _b[ActivityType.offer] = [ActivityIcons.circleWavyCheck, colors.content.onSurfaceSuccess],
    _b[ActivityType.signup] = [ActivityIcons.circleWavyCheck, colors.content.onSurfaceSuccess],
    _b[ActivityType.fee] = [ActivityIcons.circleMinus, colors.content.onSurface],
    _b[ActivityType.rejectedPaymentFee] = [ActivityIcons.circleMinus, colors.content.onSurface],
    _b[ActivityType.latePaymentFee] = [ActivityIcons.circleMinus, colors.content.onSurface],
    _b[ActivityType.interest] = [ActivityIcons.percent, colors.content.onSurface],
    _b[ActivityType.interestNegativeAdjustment] = [ActivityIcons.percent, colors.content.onSurface],
    _b[ActivityType.interestPositiveAdjustment] = [ActivityIcons.percent, colors.content.onSurface],
    _b[ActivityType.interestCharge] = [ActivityIcons.percent, colors.content.onSurface],
    _b[ActivityType.credit] = [ActivityIcons.circlePlus, colors.content.onSurface],
    _b[ActivityType.withdraw] = [Icons.circleWavyCheck, colors.content.onSurfaceSuccess],
    _b);
export var ICON_TYPE;
(function (ICON_TYPE) {
    ICON_TYPE["SHOP"] = "SHOP";
    ICON_TYPE["RECEIPT"] = "RECEIPT";
    ICON_TYPE["INTEREST"] = "INTEREST";
    ICON_TYPE["FEE"] = "FEE";
    ICON_TYPE["REFUND"] = "REFUND";
    ICON_TYPE["CREDIT"] = "CREDIT";
    ICON_TYPE["REDEMPTION"] = "REDEMPTION";
})(ICON_TYPE || (ICON_TYPE = {}));
export var iconTypeToIcon = (_c = {},
    _c[ICON_TYPE.SHOP] = [ActivityIcons.bag, colors.content.onSurface],
    _c[ICON_TYPE.RECEIPT] = [ActivityIcons.commerce, colors.content.onSurfaceSuccess],
    _c[ICON_TYPE.INTEREST] = [ActivityIcons.percent, colors.content.onSurface],
    _c[ICON_TYPE.FEE] = [ActivityIcons.circleMinus, colors.content.onSurface],
    _c[ICON_TYPE.REFUND] = [ActivityIcons.commerce, colors.content.onSurfaceSuccess],
    _c[ICON_TYPE.CREDIT] = [ActivityIcons.circlePlus, colors.content.onSurface],
    _c[ICON_TYPE.REDEMPTION] = [ActivityIcons.star, colors.content.onSurfaceWarning],
    _c);
var CategoryCode;
(function (CategoryCode) {
    // Grocery, drug stores, food stores
    CategoryCode["Grocery"] = "5411";
    CategoryCode["DrugStores"] = "5912";
    CategoryCode["MiscFoodStores"] = "5499";
    CategoryCode["WholesaleClubs"] = "5300";
    // Restaurants, bars, eating out
    CategoryCode["FastFood"] = "5814";
    CategoryCode["EatingPlaces"] = "5812";
    CategoryCode["Bar"] = "5813";
    // Gas stations, car services
    CategoryCode["Fuel"] = "5542";
    CategoryCode["ServiceStations"] = "5541";
    // Retail
    CategoryCode["BookStores"] = "5942";
    CategoryCode["DiscountStores"] = "5310";
    CategoryCode["HomeSupplyWarehouse"] = "5200";
    CategoryCode["VarietyStore"] = "5331";
    CategoryCode["MiscRetail"] = "5999";
    CategoryCode["DigitalGoodsRetail"] = "5815";
    // Vacation, travel
    CategoryCode["Timeshares"] = "7012";
    // Media
    CategoryCode["ElectronicSales"] = "5732";
    CategoryCode["CableSatelliteRadio"] = "4899";
    CategoryCode["DigitalGoodsLarge"] = "5818";
})(CategoryCode || (CategoryCode = {}));
export var Colors600;
(function (Colors600) {
    Colors600["Green"] = "#34AA46";
    Colors600["Yellow"] = "#D4A21A";
    Colors600["Blue"] = "#427AFE";
    Colors600["Red"] = "#F04C2E";
    Colors600["Pink"] = "#E952A2";
    Colors600["Purple"] = "#AB57FF";
    Colors600["Grey"] = "#808080";
})(Colors600 || (Colors600 = {}));
var groceryIcon = {
    displayImageSrc: ActivityIcons.shoppingCart,
    displayImageBackgroundColor: Colors600.Green,
};
var restaurantIcon = {
    displayImageSrc: ActivityIcons.forkKnife,
    displayImageBackgroundColor: Colors600.Yellow,
};
var gasStationIcon = {
    displayImageSrc: ActivityIcons.car,
    displayImageBackgroundColor: Colors600.Blue,
};
var retailIcon = {
    displayImageSrc: ActivityIcons.bag,
    displayImageBackgroundColor: Colors600.Red,
};
var vacationIcon = {
    displayImageSrc: ActivityIcons.houseLine,
    displayImageBackgroundColor: Colors600.Pink,
};
var mediaIcon = {
    displayImageSrc: ActivityIcons.headphones,
    displayImageBackgroundColor: Colors600.Purple,
};
export var getIconTypeFromCategoryCode = function (code) {
    switch (code) {
        case CategoryCode.Grocery:
        case CategoryCode.DrugStores:
        case CategoryCode.MiscFoodStores:
        case CategoryCode.WholesaleClubs:
            return groceryIcon;
        case CategoryCode.FastFood:
        case CategoryCode.EatingPlaces:
        case CategoryCode.Bar:
            return restaurantIcon;
        case CategoryCode.Fuel:
        case CategoryCode.ServiceStations:
            return gasStationIcon;
        case CategoryCode.BookStores:
        case CategoryCode.DiscountStores:
        case CategoryCode.HomeSupplyWarehouse:
        case CategoryCode.VarietyStore:
        case CategoryCode.MiscRetail:
        case CategoryCode.DigitalGoodsRetail:
            return retailIcon;
        case CategoryCode.Timeshares:
        case CategoryCode.ElectronicSales:
            return vacationIcon;
        case CategoryCode.CableSatelliteRadio:
        case CategoryCode.DigitalGoodsLarge:
            return mediaIcon;
        default:
            return null;
    }
};
