import { useState } from 'react';
import { useAppSelector } from '../../app/hooks';
import { selectLanguage } from '../../app/languageSelectors';
import { LAST_ACTIVE_TIME_KEY, TIMEOUT_MS, WARNING_THRESHOLD_MS } from './SessionManager';
export var throttle = function (func, limit) {
    var lastFunc;
    var lastRan = undefined;
    return function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var context = this;
        if (lastRan === undefined) {
            func.apply(context, args);
            lastRan = Date.now();
        }
        else {
            clearTimeout(lastFunc);
            lastFunc = setTimeout(function () {
                if (Date.now() - lastRan >= limit) {
                    func.apply(context, args);
                    lastRan = Date.now();
                }
            }, limit - (Date.now() - lastRan));
        }
    };
};
export var addListeners = function (fn) {
    window.addEventListener('mousemove', fn);
    window.addEventListener('keydown', fn);
    window.addEventListener('scroll', fn);
    window.addEventListener('click', fn);
};
export var removeListeners = function (fn) {
    window.removeEventListener('mousemove', fn);
    window.removeEventListener('keydown', fn);
    window.removeEventListener('scroll', fn);
    window.removeEventListener('click', fn);
};
// e.g. 1 minute 30 seconds
export var minToSecString = function (remainingSec, localization) {
    if (remainingSec < 0) {
        return '';
    }
    var mins = Math.floor(remainingSec / 60);
    var sec = remainingSec % 60;
    var minText = mins === 1 ? localization.minute : localization.minutes;
    var secText = sec === 1 ? localization.second : localization.seconds;
    if (mins === 0) {
        return "".concat(sec, " ").concat(secText);
    }
    return "".concat(mins, " ").concat(minText, " ").concat(sec, " ").concat(secText);
};
export var invalidSession = function (auth) {
    var lastActiveTimeStr = localStorage.getItem(LAST_ACTIVE_TIME_KEY);
    var lastActiveTime = lastActiveTimeStr ? new Date(parseInt(lastActiveTimeStr, 10)) : null;
    var now = new Date();
    if (!lastActiveTime) {
        return true;
    }
    var logoutConditions = [!auth.token, now.getTime() - lastActiveTime.getTime() > TIMEOUT_MS];
    return logoutConditions.some(function (cond) { return cond; });
};
var getRemainingTime = function (timeElapsed) { return TIMEOUT_MS - timeElapsed; };
var toSeconds = function (ms) { return Math.ceil(ms / 1000); };
export var useSessionTimer = function () {
    var _a = useState(0), timeElapsedMS = _a[0], setTimeElapsedMS = _a[1];
    var remainingMS = getRemainingTime(timeElapsedMS);
    var remainingSec = toSeconds(remainingMS);
    var isWithinWarningThreshold = remainingMS <= WARNING_THRESHOLD_MS;
    var isPastTimeout = remainingMS <= 0;
    var language = useAppSelector(selectLanguage);
    var warningText = language.sessionExpirationWarning.replace('{time}', minToSecString(remainingSec, language));
    var timeoutText = language.sessionExpiredPrompt;
    return {
        remainingSec: remainingSec,
        setTimeElapsedMS: setTimeElapsedMS,
        isWithinWarningThreshold: isWithinWarningThreshold,
        isPastTimeout: isPastTimeout,
        warningText: warningText,
        timeoutText: timeoutText,
    };
};
