var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import { createSlice } from '@reduxjs/toolkit';
var initialState = {
    method: 'phone',
    destination: '',
    isIncorrectOtpCode: false,
    reachedMaxRetries: false,
    otpServerError: false,
};
var otpScreenSlice = createSlice({
    name: 'otpScreen',
    initialState: initialState,
    reducers: {
        setIncorrectOtpCode: function (state, action) {
            state.isIncorrectOtpCode = action.payload;
        },
        setReachedMaxRetries: function (state, action) {
            state.reachedMaxRetries = action.payload;
        },
        setOtpServerError: function (state, action) {
            state.otpServerError = action.payload;
        },
        setOtpScreenState: function (state, action) {
            return __assign(__assign({}, state), action.payload);
        },
        resetOtpScreenSlice: function () { return initialState; },
    },
});
export var setIncorrectOtpCode = (_a = otpScreenSlice.actions, _a.setIncorrectOtpCode), setReachedMaxRetries = _a.setReachedMaxRetries, setOtpServerError = _a.setOtpServerError, setOtpScreenState = _a.setOtpScreenState, resetOtpScreenSlice = _a.resetOtpScreenSlice;
export default otpScreenSlice.reducer;
