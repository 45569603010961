/*

 Google Pay Web Push Provisioning Integration Library
 API version 1.6

 @see https://developers.google.com/pay/issuers/apis/push-provisioning/web

 This code is provided to you by Google on an as-is basis, non-exclusively and
 only for the purpose of implementing Web Push Provisioning for Google Pay.

 $Id: integration.min.js#10 $
*/
'use strict';
function p(b) {
  var g = 0;
  return function () {
    return g < b.length ? { done: !1, value: b[g++] } : { done: !0 };
  };
}
function q(b) {
  var g = 'undefined' != typeof Symbol && Symbol.iterator && b[Symbol.iterator];
  if (g) return g.call(b);
  if ('number' == typeof b['length']) return { next: p(b) };
  throw Error(String(b) + ' is not an iterable or ArrayLike');
}
var t =
  'function' == typeof Object.defineProperties
    ? Object.defineProperty
    : function (b, g, f) {
        if (b == Array.prototype || b == Object.prototype) return b;
        b[g] = f.value;
        return b;
      };
function u(b) {
  b = [
    'object' == typeof globalThis && globalThis,
    b,
    'object' == typeof window && window,
    'object' == typeof self && self,
    'object' == typeof global && global,
  ];
  for (var g = 0; g < b.length; ++g) {
    var f = b[g];
    if (f && f['Math'] == Math) return f;
  }
  throw Error('Cannot find global object');
}
var w = u(this);
function x(b, g) {
  if (g)
    a: {
      var f = w;
      b = b.split('.');
      for (var h = 0; h < b.length - 1; h++) {
        var k = b[h];
        if (!(k in f)) break a;
        f = f[k];
      }
      b = b[b.length - 1];
      h = f[b];
      g = g(h);
      g != h && null != g && t(f, b, { configurable: !0, writable: !0, value: g });
    }
}
x('Object.is', function (b) {
  return b
    ? b
    : function (g, f) {
        return g === f ? 0 !== g || 1 / g === 1 / f : g !== g && f !== f;
      };
});
x('Array.prototype.includes', function (b) {
  return b
    ? b
    : function (g, f) {
        var h = this;
        h instanceof String && (h = String(h));
        var k = h.length;
        f = f || 0;
        for (0 > f && (f = Math.max(f + k, 0)); f < k; f++) {
          var m = h[f];
          if (m === g || Object.is(m, g)) return !0;
        }
        return !1;
      };
});
x('String.prototype.includes', function (b) {
  return b
    ? b
    : function (g, f) {
        if (null == this)
          throw new TypeError(
            "The 'this' value for String.prototype.includes must not be null or undefined"
          );
        if (g instanceof RegExp)
          throw new TypeError(
            'First argument to String.prototype.includes must not be a regular expression'
          );
        return -1 !== this.indexOf(g, f || 0);
      };
});
x('Array.from', function (b) {
  return b
    ? b
    : function (g, f, h) {
        f =
          null != f
            ? f
            : function (d) {
                return d;
              };
        var k = [],
          m = 'undefined' != typeof Symbol && Symbol.iterator && g[Symbol.iterator];
        if ('function' == typeof m) {
          g = m.call(g);
          for (var n = 0; !(m = g.next()).done; ) k.push(f.call(h, m.value, n++));
        } else for (m = g.length, n = 0; n < m; n++) k.push(f.call(h, g[n], n));
        return k;
      };
});
x('Symbol', function (b) {
  function g(m) {
    if (this instanceof g) throw new TypeError('Symbol is not a constructor');
    return new f(h + (m || '') + '_' + k++, m);
  }
  function f(m, n) {
    this.g = m;
    t(this, 'description', { configurable: !0, writable: !0, value: n });
  }
  if (b) return b;
  f.prototype.toString = function () {
    return this.g;
  };
  var h = 'jscomp_symbol_' + ((1e9 * Math.random()) >>> 0) + '_',
    k = 0;
  return g;
});
x('Symbol.iterator', function (b) {
  if (b) return b;
  b = Symbol('Symbol.iterator');
  for (
    var g =
        'Array Int8Array Uint8Array Uint8ClampedArray Int16Array Uint16Array Int32Array Uint32Array Float32Array Float64Array'.split(
          ' '
        ),
      f = 0;
    f < g.length;
    f++
  ) {
    var h = w[g[f]];
    'function' === typeof h &&
      'function' != typeof h.prototype[b] &&
      t(h.prototype, b, {
        configurable: !0,
        writable: !0,
        value: function () {
          return y(p(this));
        },
      });
  }
  return b;
});
function y(b) {
  b = { next: b };
  b[Symbol.iterator] = function () {
    return this;
  };
  return b;
}
function z(b, g) {
  return Object.prototype.hasOwnProperty.call(b, g);
}
x('WeakMap', function (b) {
  function g(c) {
    this.g = (d += Math.random() + 1).toString();
    if (c) {
      c = q(c);
      for (var a; !(a = c.next()).done; ) (a = a.value), this.set(a[0], a[1]);
    }
  }
  function f() {}
  function h(c) {
    var a = typeof c;
    return ('object' === a && null !== c) || 'function' === a;
  }
  function k(c) {
    if (!z(c, n)) {
      var a = new f();
      t(c, n, { value: a });
    }
  }
  function m(c) {
    var a = Object[c];
    a &&
      (Object[c] = function (e) {
        if (e instanceof f) return e;
        Object.isExtensible(e) && k(e);
        return a(e);
      });
  }
  if (
    (function () {
      if (!b || !Object.seal) return !1;
      try {
        var c = Object.seal({}),
          a = Object.seal({}),
          e = new b([
            [c, 2],
            [a, 3],
          ]);
        if (2 != e.get(c) || 3 != e.get(a)) return !1;
        e.delete(c);
        e.set(a, 4);
        return !e.has(c) && 4 == e.get(a);
      } catch (l) {
        return !1;
      }
    })()
  )
    return b;
  var n = '$jscomp_hidden_' + Math.random();
  m('freeze');
  m('preventExtensions');
  m('seal');
  var d = 0;
  g.prototype.set = function (c, a) {
    if (!h(c)) throw Error('Invalid WeakMap key');
    k(c);
    if (!z(c, n)) throw Error('WeakMap key fail: ' + c);
    c[n][this.g] = a;
    return this;
  };
  g.prototype.get = function (c) {
    return h(c) && z(c, n) ? c[n][this.g] : void 0;
  };
  g.prototype.has = function (c) {
    return h(c) && z(c, n) && z(c[n], this.g);
  };
  g.prototype.delete = function (c) {
    return h(c) && z(c, n) && z(c[n], this.g) ? delete c[n][this.g] : !1;
  };
  return g;
});
x('Map', function (b) {
  function g() {
    var d = {};
    return (d.l = d.next = d.head = d);
  }
  function f(d, c) {
    var a = d[1];
    return y(function () {
      if (a) {
        for (; a.head != d[1]; ) a = a.l;
        for (; a.next != a.head; ) return (a = a.next), { done: !1, value: c(a) };
        a = null;
      }
      return { done: !0, value: void 0 };
    });
  }
  function h(d, c) {
    var a = c && typeof c;
    'object' == a || 'function' == a
      ? m.has(c)
        ? (a = m.get(c))
        : ((a = '' + ++n), m.set(c, a))
      : (a = 'p_' + c);
    var e = d[0][a];
    if (e && z(d[0], a))
      for (d = 0; d < e.length; d++) {
        var l = e[d];
        if ((c !== c && l.key !== l.key) || c === l.key) return { id: a, list: e, index: d, h: l };
      }
    return { id: a, list: e, index: -1, h: void 0 };
  }
  function k(d) {
    this[0] = {};
    this[1] = g();
    this.size = 0;
    if (d) {
      d = q(d);
      for (var c; !(c = d.next()).done; ) (c = c.value), this.set(c[0], c[1]);
    }
  }
  if (
    (function () {
      if (!b || 'function' != typeof b || !b.prototype.entries || 'function' != typeof Object.seal)
        return !1;
      try {
        var d = Object.seal({ x: 4 }),
          c = new b(q([[d, 's']]));
        if (
          's' != c.get(d) ||
          1 != c.size ||
          c.get({ x: 4 }) ||
          c.set({ x: 4 }, 't') != c ||
          2 != c.size
        )
          return !1;
        var a = c.entries(),
          e = a.next();
        if (e.done || e.value[0] != d || 's' != e.value[1]) return !1;
        e = a.next();
        return e.done || 4 != e.value[0].x || 't' != e.value[1] || !a.next().done ? !1 : !0;
      } catch (l) {
        return !1;
      }
    })()
  )
    return b;
  var m = new WeakMap();
  k.prototype.set = function (d, c) {
    d = 0 === d ? 0 : d;
    var a = h(this, d);
    a.list || (a.list = this[0][a.id] = []);
    a.h
      ? (a.h.value = c)
      : ((a.h = { next: this[1], l: this[1].l, head: this[1], key: d, value: c }),
        a.list.push(a.h),
        (this[1].l.next = a.h),
        (this[1].l = a.h),
        this.size++);
    return this;
  };
  k.prototype.delete = function (d) {
    d = h(this, d);
    return d.h && d.list
      ? (d.list.splice(d.index, 1),
        d.list.length || delete this[0][d.id],
        (d.h.l.next = d.h.next),
        (d.h.next.l = d.h.l),
        (d.h.head = null),
        this.size--,
        !0)
      : !1;
  };
  k.prototype.clear = function () {
    this[0] = {};
    this[1] = this[1].l = g();
    this.size = 0;
  };
  k.prototype.has = function (d) {
    return !!h(this, d).h;
  };
  k.prototype.get = function (d) {
    return (d = h(this, d).h) && d.value;
  };
  k.prototype.entries = function () {
    return f(this, function (d) {
      return [d.key, d.value];
    });
  };
  k.prototype.keys = function () {
    return f(this, function (d) {
      return d.key;
    });
  };
  k.prototype.values = function () {
    return f(this, function (d) {
      return d.value;
    });
  };
  k.prototype.forEach = function (d, c) {
    for (var a = this.entries(), e; !(e = a.next()).done; )
      (e = e.value), d.call(c, e[1], e[0], this);
  };
  k.prototype[Symbol.iterator] = k.prototype.entries;
  var n = 0;
  return k;
});
var A =
  'function' == typeof Object.assign
    ? Object.assign
    : function (b, g) {
        for (var f = 1; f < arguments.length; f++) {
          var h = arguments[f];
          if (h) for (var k in h) z(h, k) && (b[k] = h[k]);
        }
        return b;
      };
x('Object.assign', function (b) {
  return b || A;
});
(function () {
  function b(a) {
    this.C = b.m(a);
    this.u = a.C;
    this.s = a.F;
    this.o =
      'googlepay-webpp-v1_6-app-window-1' === window.name
        ? 'googlepay-webpp-v1_6-app-window-2'
        : 'googlepay-webpp-v1_6-app-window-1';
    this.m = a.A ? 'https://pay.sandbox.google.com' : 'https://pay.google.com';
    var e = b.i(a);
    a = b.g(a, e);
    this.g = window.open(this.m + '/gp/v/a/pushprovisioning/frame' + a, this.o, e);
    f(!!this.g, 'E411');
    this.v = void 0;
    this.j = !1;
    this.A();
  }
  function g(a) {
    function e(l) {
      return h(l, ['function', 'undefined'], 'E408');
    }
    this.G = h(a['onSessionCreated'], ['function'], 'E407');
    this.D = e(a['onReady']);
    this.H = e(a['onSuccess']);
    this.B = e(a['onFailure']);
    this.C = e(a['onFinish']);
    this.F = e(a['onCancel']);
    this.g = h(a['contentHeight'], ['number', 'undefined'], 'E409');
    this.j = h(a['contentWidth'], [typeof this.g], 'E409');
    this.i = h(a['cardExtraOptions'], ['object', 'undefined'], 'E412') || {};
    this.v = a['integratorId'];
    this.I = a['tokenSetting'];
    this.o = a['cardSetting'];
    this.A = a['isTestEnvironment'];
    this.s = a['clientSessionId'];
    this.m = a['allowSupervisedProvisioning'];
    this.u = a['hl'];
  }
  function f(a, e) {
    if (!a) throw Error(e);
  }
  function h(a, e, l) {
    f(e.includes(typeof a), l);
    return a;
  }
  function k(a, e) {
    return Array.from(a, function (l) {
      var r = q(l);
      l = r.next().value;
      r = r.next().value;
      return l + '=' + r;
    }).join(e);
  }
  function m() {
    c && c.F();
  }
  function n(a) {
    c && c.B(a);
  }
  function d() {
    window.removeEventListener('message', n);
    window.removeEventListener('unload', d);
    m();
  }
  b.prototype.i = function () {
    return !!this.g && !this.g.closed;
  };
  b.prototype.F = function () {
    this.i() && this.g.close();
  };
  b.prototype.B = function (a) {
    var e = a.data || {};
    if (this.i() && a.origin === this.m && e['appWindowId'] === this.o && e['action']) {
      a = e['action'];
      e = e['payload'];
      if ('sessionCreated' === a) this.v = e;
      else if ('success' === a || 'failure' === a) this.j = !0;
      (a = this.C.get(a)) && a(e);
    }
  };
  b.m = function (a) {
    return new Map()
      .set('sessionCreated', a.G)
      .set('ready', a.D)
      .set('success', a.H)
      .set('failure', a.B);
  };
  b.j = function (a) {
    if (!('virtualCardsSetting' in a.i)) return '';
    a = new Map([['virtualCardsSetting', encodeURIComponent(String(a.i['virtualCardsSetting']))]]);
    return k(a, ',');
  };
  b.i = function (a) {
    return a.g && a.j
      ? k(
          new Map([
            ['height', Math.max(700, a.g).toString()],
            ['width', Math.max(1100, a.j).toString()],
          ]),
          ','
        )
      : '';
  };
  b.g = function (a, e) {
    function l(B, v) {
      'undefined' !== typeof v &&
        '' !== v &&
        null !== v &&
        r.set(B, encodeURIComponent(v.toString()));
    }
    var r = new Map([
      ['apiVersion', 'V1_6'],
      ['integrationJsRevision', '12'],
      [
        'origin',
        encodeURIComponent(
          window.location.origin || window.location.protocol + '//' + window.location.host
        ),
      ],
    ]);
    l('integratorId', a.v);
    l('tokenSetting', a.I);
    l('cardSetting', a.o);
    l('csid', a.s);
    l('allowSupervisedProvisioning', a.m);
    l('hl', a.u);
    l('windowFeatures', e);
    a = b.j(a);
    l('cardExtraOptions', a);
    return '?' + k(r, '&');
  };
  b.prototype.A = function () {
    var a = this;
    this.g.closed
      ? this.u && this.j
        ? this.u({})
        : this.s && !this.j && this.s({})
      : (this.u || (this.s && !this.j)) &&
        setTimeout(function () {
          return a.A();
        }, 200);
  };
  b.prototype.D = function (a) {
    var e = {},
      l = {};
    this.g.postMessage(
      ((l['action'] = 'pushPaymentCredentials'),
      (l['appWindowId'] = this.o),
      (l['payload'] = ((e['paymentCredentials'] = a), (e['sessionContext'] = this.v), e)),
      l),
      this.m
    );
  };
  var c = null;
  window.addEventListener('message', n);
  window.addEventListener('unload', d);
  window['googlepay'] = Object.assign(window['googlepay'] || {}, {
    openAppWindow: function (a) {
      h(a, ['object'], 'E406');
      f(null !== a, 'E406');
      a = new g(a);
      f(!c || !c.i(), 'E410');
      c = new b(a);
    },
    closeAppWindow: m,
    pushPaymentCredentials: function (a) {
      f(!!c && c.i(), 'E420');
      h(c.v, ['object'], 'E421');
      h(a, ['object'], 'E422');
      f(null !== a, 'E422');
      c.D(a);
    },
  });
})();
