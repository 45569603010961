var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ImpActivityDetails, ImpContainer, ImpFlex, ImpPad, colors, spacing, } from '@imprint-payments/imprint-ui';
import { selectActivity } from '../../app/flyoutSelectors';
import { useAppSelector } from '../../app/hooks';
import { selectLanguage } from '../../app/languageSelectors';
import { ActivityDetailsActions } from './ActivityDetailsActions';
import { generateBodyConfig, generateDetailsDescription, generateHeaderConfig, generatePaymentInfoConfig, } from './activityDetailsHelpers';
export function ActivityDetailsHandler() {
    var language = useAppSelector(selectLanguage);
    var activity = useAppSelector(selectActivity);
    var headerConfig = generateHeaderConfig(activity, language);
    var detailsDescription = generateDetailsDescription(activity);
    var paymentInfoConfig = generatePaymentInfoConfig(activity, language);
    var bodyConfig = generateBodyConfig(activity, language);
    return (_jsx(ImpContainer, __assign({ backgroundColor: colors.surface.surfaceLow, height: "100%", width: "100%" }, { children: _jsxs(ImpFlex, __assign({ direction: "column", align: "stretch", fluid: true, justify: "space-between" }, { children: [_jsx(ImpPad, __assign({ padding: "0px ".concat(spacing.l) }, { children: _jsx(ImpActivityDetails, { headerConfig: headerConfig, paymentInfoConfig: paymentInfoConfig, bodyConfig: bodyConfig, detailsDescription: detailsDescription }) })), _jsx(ActivityDetailsActions, { activity: activity })] })) })));
}
