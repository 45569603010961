var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import styles from './PersonalInfo.module.css';
import { useNavigate } from 'react-router-dom';
import HeaderServicing from '../../components/HeaderServicing/HeaderServicing';
import { PAGE } from '../../utils/routeConstant';
import { ImpBreadcrumbs, ImpButton, ImpContainer, ImpFlex, ImpPad, ImpSpacer, ImpText, } from '@imprint-payments/imprint-ui';
import { EVENT } from '../../analytics/analyticsConsts';
import { generateUpdateEmailOtp } from '../../api/PersonalInfo/UpdateEmail/generateUpdateEmailOtp';
import { loadHome } from '../../api/loadHome.api';
import { updateAddress } from '../../api/updateAddress/updateAddress';
import { SUCCESS_TYPE, setSuccessType } from '../../app/homeSlice';
import { SCREEN, setScreen } from '../../app/screenStateSlice';
import { FORM_INPUT_WIDTH } from '../../assets/consts/const';
import { ITEM_STYLE, LIST_STYLE, ListSection } from '../../components/list/List';
import { ANALYTICS } from '../../firebase/firebase';
import { formatPhoneNumber } from '../../utils/formatUtils';
import { displayTemporarySnackbar, getAddressText, navigateToAccountPage, } from '../../utils/functionUtils';
import AddAddressPage from '../authorizedUsers/AddAddressPage';
import VerifyPinPage from '../authorizedUsers/VerifyPinPage';
import { EditEmailPage } from './EditEmailPage';
import { EditIncomePage } from './EditIncomePage';
import { EditPhoneOTPPage } from './EditPhoneOTPPage';
import { EditPhonePage } from './EditPhonePage';
import { VerifyEmailPage } from './VerifyEmailPage';
// PersonalInfoPageStage will keep track of the Personal Info UX logic flow.
var PersonalInfoPageStage;
(function (PersonalInfoPageStage) {
    PersonalInfoPageStage["Overview"] = "overview";
    PersonalInfoPageStage["VerifyPin"] = "verifyPin";
    PersonalInfoPageStage["EditEmail"] = "editEmail";
    PersonalInfoPageStage["EditPhone"] = "editPhone";
    PersonalInfoPageStage["EditAddress"] = "editAddress";
    PersonalInfoPageStage["EditIncome"] = "editIncome";
    PersonalInfoPageStage["VerifyEmail"] = "verifyEmail";
    PersonalInfoPageStage["VerifyPhone"] = "verifyPhone";
})(PersonalInfoPageStage || (PersonalInfoPageStage = {}));
export default function PersonalInfo() {
    var dispatch = useAppDispatch();
    var navigate = useNavigate();
    var language = useAppSelector(function (state) { return state.languageSelector.languageLocalization; });
    var homeSelector = useAppSelector(function (state) { return state.homeSelector; });
    var _a = useState(false), isSubmitting = _a[0], setIsSubmitting = _a[1];
    var _b = useState(PersonalInfoPageStage.Overview), personalInfoPageStage = _b[0], setPersonalInfoPageStage = _b[1];
    // personalInfoPageStageIntent is used to keep track of the user's intent before entering pin stage.
    var _c = useState(PersonalInfoPageStage.Overview), personalInfoPageStageIntent = _c[0], setPersonalInfoPageStageIntent = _c[1];
    useEffect(function () {
        if (personalInfoPageStage === PersonalInfoPageStage.Overview) {
            dispatch(loadHome());
        }
    }, [personalInfoPageStage]);
    useEffect(function () {
        dispatch(loadHome());
        return function () {
            setPersonalInfoPageStageIntent(PersonalInfoPageStage.Overview);
        };
    }, []);
    function handleSuccess() {
        if (personalInfoPageStageIntent === PersonalInfoPageStage.VerifyEmail) {
            ANALYTICS.logEvent(EVENT.PERSONAL_INFO_EMAIL_OTP_SUCCESS);
        }
        else if (personalInfoPageStageIntent === PersonalInfoPageStage.VerifyPhone) {
            ANALYTICS.logEvent(EVENT.PERSONAL_INFO_PHONE_OTP_SUCCESS);
        }
        else {
            ANALYTICS.logEvent(EVENT.PERSONAL_INFO_PIN_SUCCESS);
        }
        switch (personalInfoPageStageIntent) {
            case PersonalInfoPageStage.EditEmail:
                setPersonalInfoPageStage(PersonalInfoPageStage.EditEmail);
                break;
            case PersonalInfoPageStage.EditPhone:
                setPersonalInfoPageStage(PersonalInfoPageStage.EditPhone);
                break;
            case PersonalInfoPageStage.EditAddress:
                setPersonalInfoPageStage(PersonalInfoPageStage.EditAddress);
                break;
            case PersonalInfoPageStage.EditIncome:
                setPersonalInfoPageStage(PersonalInfoPageStage.EditIncome);
                break;
            case PersonalInfoPageStage.VerifyEmail:
                dispatch(setSuccessType(SUCCESS_TYPE.emailUpdate));
                break;
            case PersonalInfoPageStage.VerifyPhone:
                dispatch(setSuccessType(SUCCESS_TYPE.phoneUpdate));
                break;
        }
    }
    useEffect(function () {
        // Reset page stage intent when user navigates to overview stage.
        if (personalInfoPageStage === PersonalInfoPageStage.Overview) {
            setPersonalInfoPageStageIntent(PersonalInfoPageStage.Overview);
        }
    }, [personalInfoPageStage]);
    useEffect(function () {
        if (homeSelector.successType !== SUCCESS_TYPE.default) {
            dispatch(setScreen(SCREEN.SUCCESS));
            navigate(PAGE.SUCCESS);
        }
    }, [homeSelector.successType]);
    var getNameList = function () {
        return [
            {
                title: language.personalInfoName,
                subtitle: homeSelector.userInformation.firstName + ' ' + homeSelector.userInformation.lastName,
                noPointerCursor: true,
            },
        ];
    };
    var getInfoList = function () {
        return [
            {
                title: language.personalInfoEmail,
                subtitle: homeSelector.userInformation.email,
                style: ITEM_STYLE.CHEVRON_RIGHT,
                handleClick: function () {
                    setPersonalInfoPageStageIntent(PersonalInfoPageStage.EditEmail);
                    setPersonalInfoPageStage(PersonalInfoPageStage.VerifyPin);
                },
            },
            {
                title: language.personalInfoPhone,
                subtitle: formatPhoneNumber(homeSelector.userInformation.phone),
                style: ITEM_STYLE.CHEVRON_RIGHT,
                handleClick: function () {
                    setPersonalInfoPageStageIntent(PersonalInfoPageStage.EditPhone);
                    setPersonalInfoPageStage(PersonalInfoPageStage.VerifyPin);
                },
            },
            {
                title: language.personalInfoAddress,
                disabled: !(homeSelector.userInformation.addressDaysUntilEditable === 0),
                subtitle: getAddressText(homeSelector.userInformation.address),
                style: ITEM_STYLE.CHEVRON_RIGHT,
                handleClick: function () {
                    setPersonalInfoPageStageIntent(PersonalInfoPageStage.EditAddress);
                    setPersonalInfoPageStage(PersonalInfoPageStage.VerifyPin);
                },
            },
            {
                title: language.personalInfoIncomeHousing,
                style: ITEM_STYLE.CHEVRON_RIGHT,
                titleTopBottomMargin: true,
                handleClick: function () {
                    setPersonalInfoPageStageIntent(PersonalInfoPageStage.EditIncome);
                    setPersonalInfoPageStage(PersonalInfoPageStage.VerifyPin);
                },
            },
        ];
    };
    function handleBackClick() {
        switch (personalInfoPageStage) {
            case PersonalInfoPageStage.VerifyPin:
            case PersonalInfoPageStage.EditEmail:
            case PersonalInfoPageStage.EditPhone:
            case PersonalInfoPageStage.EditAddress:
            case PersonalInfoPageStage.EditIncome:
                setPersonalInfoPageStage(PersonalInfoPageStage.Overview);
                break;
            case PersonalInfoPageStage.VerifyEmail:
                setPersonalInfoPageStage(PersonalInfoPageStage.EditEmail);
                break;
            case PersonalInfoPageStage.VerifyPhone:
                setPersonalInfoPageStage(PersonalInfoPageStage.EditPhone);
                break;
            case PersonalInfoPageStage.Overview:
            default:
                navigateToAccountPage(dispatch, navigate);
                break;
        }
    }
    var overviewPage = function () {
        return (_jsxs("div", __assign({ className: styles.overviewPage }, { children: [_jsx(ListSection, { style: LIST_STYLE.DEFAULT, items: getNameList() }), _jsx(ImpPad, __assign({ padding: "12px 0 0 24px" }, { children: _jsxs(ImpText, __assign({ typography: "body3" }, { children: [language.personalInfoNameChange, _jsx(ImpButton, __assign({ variant: "inline", onClick: function () {
                                    ANALYTICS.logEvent(EVENT.PERSONAL_INFO_NAME_CHANGE_EMAIL_CLICKED);
                                    window.open('mailto:' + language.imprintSupportEmail);
                                } }, { children: language.imprintSupportEmail })), "."] })) })), _jsx(ListSection, { style: LIST_STYLE.DEFAULT, items: getInfoList() })] })));
    };
    var getPageFromStage = function () {
        switch (personalInfoPageStage) {
            case PersonalInfoPageStage.VerifyPin:
                return _jsx(VerifyPinPage, { handleSuccess: handleSuccess });
            case PersonalInfoPageStage.EditEmail:
                return (_jsx(EditEmailPage, { onContinue: function (email) {
                        ANALYTICS.logEvent(EVENT.PERSONAL_INFO_EMAIL_EDIT_CONTINUE_CLICKED);
                        dispatch(generateUpdateEmailOtp(email));
                        setPersonalInfoPageStage(PersonalInfoPageStage.VerifyEmail);
                        setPersonalInfoPageStageIntent(PersonalInfoPageStage.VerifyEmail);
                    } }));
            case PersonalInfoPageStage.EditPhone:
                return (_jsx(EditPhonePage, { onOtpSent: function () {
                        setPersonalInfoPageStage(PersonalInfoPageStage.VerifyPhone);
                        setPersonalInfoPageStageIntent(PersonalInfoPageStage.VerifyPhone);
                    } }));
            case PersonalInfoPageStage.EditAddress:
                return (_jsx(ImpContainer, __assign({ width: FORM_INPUT_WIDTH }, { children: _jsx(AddAddressPage, { title: language.editAddressHeader, handleContinueClick: function (address) {
                            setIsSubmitting(true);
                            var onSuccess = function () {
                                setIsSubmitting(false);
                                setPersonalInfoPageStage(PersonalInfoPageStage.Overview);
                                displayTemporarySnackbar(language.addressUpdated, dispatch);
                            };
                            var onError = function () {
                                setIsSubmitting(false);
                                displayTemporarySnackbar(language.somethingWentWrong, dispatch);
                            };
                            updateAddress({ address: address, onSuccess: onSuccess, onError: onError });
                        }, isSubmitting: isSubmitting, analytics: {
                            streetAddress: function () {
                                ANALYTICS.logEvent(EVENT.PERSONAL_INFO_ADDRESS_EDIT, {
                                    field: 'street_address',
                                });
                            },
                            apartment: function () {
                                ANALYTICS.logEvent(EVENT.PERSONAL_INFO_ADDRESS_EDIT, {
                                    field: 'apartment',
                                });
                            },
                            city: function () {
                                ANALYTICS.logEvent(EVENT.PERSONAL_INFO_ADDRESS_EDIT, {
                                    field: 'city',
                                });
                            },
                            zip: function () {
                                ANALYTICS.logEvent(EVENT.PERSONAL_INFO_ADDRESS_EDIT, {
                                    field: 'zip',
                                });
                            },
                            state: function () {
                                ANALYTICS.logEvent(EVENT.PERSONAL_INFO_ADDRESS_EDIT, {
                                    field: 'state',
                                });
                            },
                        } }) })));
            case PersonalInfoPageStage.EditIncome:
                return _jsx(EditIncomePage, {});
            case PersonalInfoPageStage.VerifyEmail:
                return (_jsx(VerifyEmailPage, { onSuccess: function () {
                        dispatch(setSuccessType(SUCCESS_TYPE.emailUpdate));
                    } }));
            case PersonalInfoPageStage.VerifyPhone:
                return (_jsx(EditPhoneOTPPage, { onSuccess: function () {
                        dispatch(setSuccessType(SUCCESS_TYPE.phoneUpdate));
                    }, onBack: function () {
                        setPersonalInfoPageStage(PersonalInfoPageStage.EditPhone);
                    } }));
            case PersonalInfoPageStage.Overview:
            default:
                return overviewPage();
        }
    };
    var getBreadcrumbTitle = function () {
        switch (personalInfoPageStage) {
            case PersonalInfoPageStage.VerifyPin:
                return language.verifyYourPin;
            case PersonalInfoPageStage.EditAddress:
                return language.breadcrumbAddress;
            case PersonalInfoPageStage.EditIncome:
                return language.breadcrumbEditIncome;
            case PersonalInfoPageStage.EditEmail:
            case PersonalInfoPageStage.VerifyEmail:
                return language.breadcrumbEditEmail;
            case PersonalInfoPageStage.EditPhone:
            case PersonalInfoPageStage.VerifyPhone:
                return language.breadcrumbEditPhone;
            case PersonalInfoPageStage.Overview:
            default:
                return language.personalInfo;
        }
    };
    return (_jsxs("div", __assign({ className: styles.container }, { children: [_jsx(HeaderServicing, {}), _jsx(ImpBreadcrumbs, { breadcrumbs: [getBreadcrumbTitle()], onBackClick: handleBackClick, onCancelClick: function () { return navigateToAccountPage(dispatch, navigate); } }), _jsx(ImpSpacer, { height: "2xl" }), _jsx(ImpContainer, __assign({ width: 'min(748px, 100vw - 48px)' }, { children: _jsx(ImpFlex, __assign({ direction: "column", align: "center", fluid: true }, { children: getPageFromStage() })) }))] })));
}
