import { configureStore, } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import acceptScreenReducer from './acceptScreenSlice';
import accountLinkSlice from './accountLinkSlice';
import activityReducer from './activitySlice';
import apiScreenSlice from './apiScreenSlice';
import applicationScreenReducer from './applicationScreenSlice';
import applicationStatusReducer from './applicationStatusSlice';
import authSlice from './authSlice';
import authUsersSlice from './authUsersSlice';
import cardDesignSlice from './cardDesignSlice';
import consumerInfoReducer from './consumerInfoSlice';
import featureFlagSlice from './featureFlagSlice';
import flyoutSlice from './flyoutSlice';
import forgotPinSlice from './forgotPinSlice';
import homeReducer from './homeSlice';
import languageReducer from './languageSlice';
import merchantDataReducer from './merchantDataSlice';
import otpScreenReducer from './otpScreenSlice';
import paymentsPageSlice from './paymentsPageSlice';
import physicalCardActivationSlice from './physicalCardActivationSlice';
import productAccountReducer from './productAccountSlice';
import rewardsDetailsSlice from './rewardsDetailsSlice';
import rewardsRedemptionSlice from './rewardsRedemptionSlice';
import screenStateReducer from './screenStateSlice';
import snackbarReducer from './snackbarSlice';
import statementsReducer from './statementsSlice';
import validatorReducer from './validatorSlice';
import accountOnboardingSlice from './accountOnboardingSlice';
var rootReducers = combineReducers({
    languageSelector: languageReducer,
    screenStateSelector: screenStateReducer,
    otpScreenSelector: otpScreenReducer,
    applicationScreenSelector: applicationScreenReducer,
    consumerInfoSelector: consumerInfoReducer,
    merchantDataSelector: merchantDataReducer,
    applicationStatusSelector: applicationStatusReducer,
    acceptScreenSelector: acceptScreenReducer,
    apiScreenScreenSelector: apiScreenSlice,
    validatorSelector: validatorReducer,
    homeSelector: homeReducer,
    activitySelector: activityReducer,
    statementsSelector: statementsReducer,
    productAccountSelector: productAccountReducer,
    featureFlagSelector: featureFlagSlice,
    physicalCardActivationSelector: physicalCardActivationSlice,
    cardDesignSelector: cardDesignSlice,
    snackbarSelector: snackbarReducer,
    flyoutSelector: flyoutSlice,
    authUsersSelector: authUsersSlice,
    rewardsRedemptionSelector: rewardsRedemptionSlice,
    forgotPinSlice: forgotPinSlice,
    accountLinkSelector: accountLinkSlice,
    paymentsPageSelector: paymentsPageSlice,
    rewardsDetailsSlice: rewardsDetailsSlice,
    authSlice: authSlice,
    accountOnboardingSelector: accountOnboardingSlice,
});
var persistConfig = {
    key: 'root',
    storage: storage,
    // TODO: consider persist transform to just persist consumerUUID
    whitelist: ['apiScreenScreenSelector', 'authSlice', 'consumerInfoSelector'],
};
var persistedReducer = persistReducer(persistConfig, rootReducers);
export var initializeStore = function (preloadedState) {
    return configureStore({
        reducer: persistedReducer,
        preloadedState: preloadedState,
        middleware: function (getDefaultMiddleware) {
            return getDefaultMiddleware({
                serializableCheck: {
                    ignoredActions: ['persist/PERSIST'],
                },
            });
        },
    });
};
export var store = initializeStore();
export var persistor = persistStore(store);
