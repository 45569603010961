var _a;
import { createSlice } from '@reduxjs/toolkit';
import { getEmptyAuthorizedUser, } from '../types/AuthorizedUser';
import { getEmptyDate } from '../types/Date';
export var AuthUsersPage;
(function (AuthUsersPage) {
    AuthUsersPage[AuthUsersPage["ACTIVATE_PHYSICAL_CARD"] = 0] = "ACTIVATE_PHYSICAL_CARD";
    AuthUsersPage[AuthUsersPage["ADDITIONAL_DETAILS"] = 1] = "ADDITIONAL_DETAILS";
    AuthUsersPage[AuthUsersPage["ADD_ADDRESS"] = 2] = "ADD_ADDRESS";
    AuthUsersPage[AuthUsersPage["ADD_INFORMATION"] = 3] = "ADD_INFORMATION";
    AuthUsersPage[AuthUsersPage["CARD_MAILED"] = 4] = "CARD_MAILED";
    AuthUsersPage[AuthUsersPage["CONFIRM_PIN"] = 5] = "CONFIRM_PIN";
    AuthUsersPage[AuthUsersPage["CREATE_AUTH_USER"] = 6] = "CREATE_AUTH_USER";
    AuthUsersPage[AuthUsersPage["DEACTIVATE_USER"] = 7] = "DEACTIVATE_USER";
    AuthUsersPage[AuthUsersPage["DEFAULT"] = 8] = "DEFAULT";
    AuthUsersPage[AuthUsersPage["REJECTED"] = 9] = "REJECTED";
    AuthUsersPage[AuthUsersPage["REPLACE_CARD"] = 10] = "REPLACE_CARD";
    AuthUsersPage[AuthUsersPage["SUBMITTING"] = 11] = "SUBMITTING";
    AuthUsersPage[AuthUsersPage["UPDATE_ADDRESS"] = 12] = "UPDATE_ADDRESS";
    AuthUsersPage[AuthUsersPage["USER_DEACTIVATED"] = 13] = "USER_DEACTIVATED";
    AuthUsersPage[AuthUsersPage["VERIFY_IDENTITY"] = 14] = "VERIFY_IDENTITY";
    AuthUsersPage[AuthUsersPage["VERIFY_PIN"] = 15] = "VERIFY_PIN";
    AuthUsersPage[AuthUsersPage["VERIFY_PIN_ADDRESS"] = 16] = "VERIFY_PIN_ADDRESS";
})(AuthUsersPage || (AuthUsersPage = {}));
var initialState = {
    page: AuthUsersPage.DEFAULT,
    application: {
        authApplicationUUID: '',
        displayState: '',
        status: '',
    },
    selectedAuthorizedUser: getEmptyAuthorizedUser(),
    consumer: {
        name: {
            firstName: '',
            lastName: '',
        },
        email: '',
        dob: getEmptyDate(),
        address: {
            streetLine1: '',
            streetLine2: '',
            city: '',
            state: '',
            country: 'US',
            zipCode: '',
        },
        phone: '',
        relationshipToPrimaryUser: '',
        ssn4: '',
        ssn: '',
    },
};
var authUsersSlice = createSlice({
    name: 'authUsers',
    initialState: initialState,
    reducers: {
        setAuthUsersPage: function (state, action) {
            state.page = action.payload;
        },
        setAuthUserInformation: function (state, action) {
            // clean phone number
            var phone = action.payload.phone.replace(/\D/g, '');
            state.consumer.name.firstName = action.payload.name.firstName;
            state.consumer.name.lastName = action.payload.name.lastName;
            state.consumer.email = action.payload.email;
            state.consumer.dob.month = action.payload.dob.month;
            state.consumer.dob.day = action.payload.dob.day;
            state.consumer.dob.year = action.payload.dob.year;
            state.consumer.phone = phone;
            state.consumer.relationshipToPrimaryUser =
                action.payload.relationshipToPrimaryUser;
        },
        setAuthUserAddress: function (state, action) {
            state.consumer.address = action.payload;
        },
        setAuthUserSsn4: function (state, action) {
            state.consumer.ssn4 = action.payload.ssn4;
        },
        setAuthUserFullSsn: function (state, action) {
            // clean ssn
            var ssn = action.payload.ssn.replace(/\D/g, '');
            state.consumer.ssn = ssn;
        },
        setSelectedAuthorizedUser: function (state, action) {
            state.selectedAuthorizedUser = action.payload;
        },
        setDisplayState: function (state, action) {
            state.application.displayState = action.payload.displayState;
        },
        setAuthApplicationUUID: function (state, action) {
            state.application.authApplicationUUID =
                action.payload.authApplicationUUID;
        },
        setAuthApplicationStatus: function (state, action) {
            state.application.status = action.payload.status;
        },
        setReturningIncompleteAuthUser: function (state, action) {
            state.application.displayState = action.payload.displayState;
            state.application.authApplicationUUID =
                action.payload.authApplicationUUID;
            state.consumer.name = action.payload.name;
            state.page = AuthUsersPage.ADDITIONAL_DETAILS;
        },
        resetAuthUsers: function () { return initialState; },
    },
});
export var setAuthUsersPage = (_a = authUsersSlice.actions, _a.setAuthUsersPage), setAuthUserInformation = _a.setAuthUserInformation, setAuthUserAddress = _a.setAuthUserAddress, setAuthUserSsn4 = _a.setAuthUserSsn4, setAuthUserFullSsn = _a.setAuthUserFullSsn, setSelectedAuthorizedUser = _a.setSelectedAuthorizedUser, resetAuthUsers = _a.resetAuthUsers, setDisplayState = _a.setDisplayState, setAuthApplicationUUID = _a.setAuthApplicationUUID, setAuthApplicationStatus = _a.setAuthApplicationStatus, setReturningIncompleteAuthUser = _a.setReturningIncompleteAuthUser;
export default authUsersSlice.reducer;
