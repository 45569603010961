var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import styles from './AccessMore.module.css';
import HeaderServicing from '../../components/HeaderServicing/HeaderServicing';
import { SCREEN } from '../../app/screenStateSlice';
import { useNavigate } from 'react-router-dom';
import { EVENT } from '../../analytics/analyticsConsts';
import { ANALYTICS } from '../../firebase/firebase';
import { QRLogoComponent } from '../dashboard/DownloadAppValueProps';
import { ImpBreadcrumbs, ImpContainer, ImpDivider, ImpFlex, ImpPad, ImpSpacer, ImpText, colors, } from '@imprint-payments/imprint-ui';
import { navigateToAccountPage } from '../../utils/functionUtils';
export default function AccessMore() {
    var dispatch = useAppDispatch();
    var navigate = useNavigate();
    var language = useAppSelector(function (state) { return state.languageSelector.languageLocalization; });
    var screenState = useAppSelector(function (state) { return state.screenStateSelector; });
    var bullets = [
        language.viewBalanceInDetail,
        language.getInAppRemindersLong,
        language.stayTunedForWebsiteFeatures,
    ];
    useEffect(function () {
        if (screenState.screen === SCREEN.ACCESS_MORE) {
            ANALYTICS.logEvent(EVENT.MORE_APP_INFO_PAGE_VIEWED);
        }
    }, []);
    return (_jsxs(ImpContainer, __assign({ width: "100%" }, { children: [_jsx(HeaderServicing, {}), _jsx(ImpBreadcrumbs, { breadcrumbs: [language.findMore], onBackClick: function () { return navigateToAccountPage(dispatch, navigate); }, onCancelClick: function () { return navigateToAccountPage(dispatch, navigate); } }), _jsx(ImpSpacer, { height: "2xl" }), _jsx(ImpFlex, __assign({ direction: "row", justify: "center", fluid: true }, { children: _jsxs(ImpContainer, __assign({ width: "min(552px, 100% - 32px)" }, { children: [_jsx(ImpText, __assign({ typography: "headline1" }, { children: language.getAppAccessMore })), _jsx(ImpSpacer, { height: "l" }), _jsx(ImpText, __assign({ color: colors.content.onBackgroundVariant }, { children: language.downloadAppParagraph })), _jsx(ImpPad, __assign({ paddingTop: "xl", paddingBottom: "xl" }, { children: _jsx(ImpDivider, { color: colors.border.borderVariant }) })), _jsx(QRLogoComponent, {})] })) }))] })));
}
function SimpleBullet(props) {
    return (_jsxs("div", __assign({ className: styles.bulletContainer }, { children: [_jsx("div", { className: styles.bullet }), _jsx("div", __assign({ className: styles.bulletText, tabIndex: 0 }, { children: props.text }))] })));
}
