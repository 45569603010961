import * as Sentry from "@sentry/react";
export var initializeSentry = function () {
    if (process.env.REACT_APP_SENTRY_DSN_WEB_SERVICING) {
        Sentry.init({
            dsn: "".concat(process.env.REACT_APP_SENTRY_DSN_WEB_SERVICING),
            integrations: [
                new Sentry.BrowserTracing({
                    tracePropagationTargets: ["localhost", "*imprint.co"],
                }),
                new Sentry.Replay({
                    maskAllText: true,
                    blockAllMedia: true,
                    block: ["iframe", '[src]'],
                }),
            ],
            tracesSampleRate: 1.0,
            replaysSessionSampleRate: 0.1,
            replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        });
    }
};
