var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { formatPhoneNumber } from '@imprint-payments/imprint-lib';
import { ImpButton, ImpContainer, ImpFlex, ImpNavIcon, ImpOtpField, ImpPad, ImpSpacer, ImpText, colors, } from '@imprint-payments/imprint-ui';
import { useEffect, useState } from 'react';
import { selectVoiceOTPEnabledEnabledFlag } from '../../app/featureFlagSelectors';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectLanguage } from '../../app/languageSelectors';
import { selectOtpScreenState } from '../../app/otpScreenSelectors';
import { setIncorrectOtpCode, setOtpServerError } from '../../app/otpScreenSlice';
import { CONTAINER_WIDTH, TEXT_WIDTH } from '../../assets/consts/const';
import { ANALYTICS } from '../../firebase/firebase';
import { displayTemporarySnackbar } from '../../utils/functionUtils';
export function OtpCard(_a) {
    var onFull = _a.onFull, handleGoBack = _a.handleGoBack, handleResend = _a.handleResend, events = _a.events;
    var dispatch = useAppDispatch();
    var language = useAppSelector(selectLanguage);
    var otpScreenState = useAppSelector(selectOtpScreenState);
    var isVoiceOTPEnabled = useAppSelector(selectVoiceOTPEnabledEnabledFlag);
    var _b = useState(false), voiceSelected = _b[0], setVoiceSelected = _b[1];
    var _c = useState(''), input = _c[0], setInput = _c[1];
    var _d = useState(false), clearWithError = _d[0], setClearWithError = _d[1];
    var _e = useState(false), clear = _e[0], setClear = _e[1];
    useEffect(function () {
        dispatch(setIncorrectOtpCode(false));
        dispatch(setOtpServerError(false));
        ANALYTICS.logEvent(events.viewed);
    }, []);
    useEffect(function () {
        if (input !== '') {
            setClearWithError(false);
            setClear(false);
            dispatch(setIncorrectOtpCode(false));
        }
    }, [input]);
    useEffect(function () {
        if (otpScreenState.isIncorrectOtpCode) {
            ANALYTICS.logEvent(events.incorrect);
            setClearWithError(true);
        }
    }, [otpScreenState.isIncorrectOtpCode]);
    useEffect(function () {
        if (otpScreenState.otpServerError) {
            setClear(true);
            displayTemporarySnackbar(language.somethingWentWrong, dispatch);
            dispatch(setOtpServerError(false));
        }
    }, [otpScreenState.otpServerError]);
    var handleFull = function (otp) {
        ANALYTICS.logEvent(events.submitted);
        onFull(otp);
    };
    var handleResendFn = function () {
        ANALYTICS.logEvent(events.resend);
        setClear(true);
        dispatch(setIncorrectOtpCode(false));
        setClearWithError(false);
        // resend otp by sms
        handleResend(false);
    };
    var handleResendVoiceFn = function (eventName) {
        eventName && ANALYTICS.logEvent(eventName);
        setClear(true);
        dispatch(setIncorrectOtpCode(false));
        setClearWithError(false);
        // resend otp by voice
        handleResend(true);
        setVoiceSelected(true);
    };
    var title = otpScreenState.method === 'phone'
        ? language.verifyYourPhoneNumber
        : language.verifyYourEmailAddress;
    var getSubtitle = function () {
        var subtitleWithoutVoice = language.enterThe6DigitCodeSentTo +
            (otpScreenState.method === 'phone'
                ? formatPhoneNumber(otpScreenState.destination)
                : otpScreenState.destination);
        var subtitleWithVoice = language.enterThe6DigitCodeUpdatedWithVoice.replace('[SEND_TO]', formatPhoneNumber(otpScreenState.destination));
        var subtitleWithVoiceOnly = language.enterThe6DigitCodeUpdatedWithVoiceOnly.replace('[SEND_TO]', formatPhoneNumber(otpScreenState.destination));
        if (isVoiceOTPEnabled && otpScreenState.method === 'phone') {
            if (voiceSelected) {
                return subtitleWithVoiceOnly;
            }
            return subtitleWithVoice;
        }
        return subtitleWithoutVoice;
    };
    var getSendAgainButtons = function () {
        if (isVoiceOTPEnabled && otpScreenState.method === 'phone') {
            if (voiceSelected) {
                return (_jsx(ImpButton, __assign({ variant: "secondary", onClick: function () {
                        handleResendVoiceFn(events.callAgain);
                    } }, { children: language.callMeAgain })));
            }
            else {
                return (_jsxs(_Fragment, { children: [_jsx(ImpButton, __assign({ variant: "secondary", onClick: handleResendFn }, { children: language.sendSMSAgain })), _jsx(ImpButton, __assign({ variant: "text", onClick: function () {
                                handleResendVoiceFn(events.callInstead);
                            } }, { children: language.callMeInstead }))] }));
            }
        }
        return (_jsx(ImpButton, __assign({ variant: "secondary", onClick: handleResendFn }, { children: language.sendCodeAgain })));
    };
    return (_jsxs(ImpContainer, __assign({ textAlign: "center", width: CONTAINER_WIDTH }, { children: [handleGoBack && (_jsxs(_Fragment, { children: [_jsx(ImpFlex, __assign({ direction: "column", align: "left" }, { children: _jsx(ImpPad, __assign({ paddingLeft: "xs", paddingTop: "xs", paddingBottom: "xs" }, { children: _jsx(ImpNavIcon.Back, { onClick: handleGoBack }) })) })), _jsx(ImpSpacer, { height: "2xl" })] })), _jsxs(ImpFlex, __assign({ direction: "column", align: "center", gap: "xl" }, { children: [_jsxs(ImpFlex, __assign({ direction: "column", align: "center", gap: "s" }, { children: [_jsx(ImpText, __assign({ typography: "headline2", mobileTypography: "headline3" }, { children: title })), _jsx(ImpContainer, __assign({ textAlign: "center", width: TEXT_WIDTH }, { children: _jsx(ImpText, __assign({ typography: "body2", color: colors.content.onSurfaceVariant }, { children: getSubtitle() })) }))] })), _jsx(ImpOtpField, { numInputs: 6, onChange: function (otp) { return setInput(otp); }, onFull: function (otp) { return handleFull(otp); }, clearWithError: clearWithError, clear: clear, autoComplete: "one-time-code", autoFocus: true }), otpScreenState.isIncorrectOtpCode && (_jsx(ImpText, __assign({ typography: "body2", color: colors.content.onSurfaceError }, { children: language.otpError2 }))), getSendAgainButtons()] }))] })));
}
