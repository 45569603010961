import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../app/hooks';
import { SCREEN } from '../app/screenStateSlice';
import { PAGE, loginPath } from '../utils/routeConstant';
export default function useNextPage() {
    var navigate = useNavigate();
    var screenState = useAppSelector(function (state) { return state.screenStateSelector; });
    var nextPage = function () {
        var screen = screenState.screen;
        switch (screen) {
            case SCREEN.APPLICATION: {
                navigate(PAGE.HOME);
                break;
            }
            case SCREEN.APP_OTPSCREEN: {
                navigate(PAGE.OTP);
                break;
            }
            case SCREEN.ERROR: {
                navigate(PAGE.ERROR);
                break;
            }
            case SCREEN.LOGIN: {
                navigate(loginPath());
                break;
            }
            case SCREEN.ONE_TIME_PAYMENT: {
                navigate(PAGE.ONE_TIME_PAYMENT);
                break;
            }
            case SCREEN.ACCOUNT: {
                navigate(PAGE.ACCOUNT);
                break;
            }
            case SCREEN.CONFIRM_EMAIL: {
                navigate(PAGE.ACCOUNT_SETUP);
                break;
            }
            case SCREEN.DOCUMENTS: {
                navigate(PAGE.DOCUMENTS);
                break;
            }
            case SCREEN.BANKING: {
                navigate(PAGE.BANKING);
                break;
            }
            case SCREEN.DOWNLOAD_APP: {
                navigate(PAGE.DOWNLOAD_APP);
                break;
            }
            case SCREEN.EDU_APP_WEB: {
                navigate(PAGE.EDU_APP_WEB);
                break;
            }
            case SCREEN.OFFER: {
                navigate(PAGE.OFFER);
                break;
            }
            case SCREEN.ACCEPT: {
                navigate(PAGE.ACCEPT);
                break;
            }
            case SCREEN.APPLICATION_IN_REVIEW:
            case SCREEN.APPLICATION_NOT_APPROVED_WITHIN_COOLDOWN:
            case SCREEN.APPLICATION_NOT_APPROVED_AFTER_COOLDOWN:
            case SCREEN.CREDIT_FROZEN:
            case SCREEN.ELIGIBLE:
            case SCREEN.REAPPLY:
            case SCREEN.APPLICATION_EXPIRED: {
                navigate(PAGE.ACCOUNT_STATUS);
                break;
            }
            case SCREEN.FIND_ACCOUNT:
            case SCREEN.FORGOT_PIN:
            case SCREEN.SERVICE_OTPSCREEN:
            case SCREEN.PIN:
            case SCREEN.DOB:
            case SCREEN.ACCOUNT_PENDING:
            case SCREEN.LANGUAGE_DISCLAIMER:
            case SCREEN.VERIFICATION_FAILED: {
                break;
            }
            default: {
                navigate(PAGE.ERROR);
                break;
            }
        }
    };
    return nextPage;
}
