var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { ImpContainer, colors, borderRadius, ImpPad, ImpFlex, ImpProgressRing, } from '@imprint-payments/imprint-ui';
import { useAppSelector } from '../../app/hooks';
import { selectLanguage } from '../../app/languageSelectors';
import { selectCardBalanceV2, } from '../../app/productAccountSelectors';
import { generateBalanceRings } from '../cardBalance/CardBalanceUtils';
import { useEffect, useMemo, useState } from 'react';
import { formatDisplayAmount } from '@imprint-payments/imprint-lib';
var useCardBalanceRingConfig = function () {
    var language = useAppSelector(selectLanguage);
    var cardBalance = useAppSelector(selectCardBalanceV2);
    var renderSubtitle = function (balance) {
        if (balance.valueInMinUnit < 0) {
            return language.balanceOverLimit;
        }
        return language.available;
    };
    var renderBalanceAmount = function (balance) {
        if (balance.valueInMinUnit < 0) {
            return "(".concat(balance.displaySymbol).concat(balance.displayValue, ")");
        }
        return balance.displaySymbol + balance.displayValue;
    };
    var toReturn = useMemo(function () {
        return {
            title: formatDisplayAmount(cardBalance.totalSpend),
            subtitle: renderBalanceAmount(cardBalance.available) + renderSubtitle(cardBalance.available),
            rings: generateBalanceRings(cardBalance),
        };
    }, [cardBalance, language]);
    return toReturn;
};
export function CardBalanceRing() {
    var cardBalanceRingConfig = useCardBalanceRingConfig();
    var _a = useState(window.innerWidth), width = _a[0], setWidth = _a[1];
    useEffect(function () {
        var handleResize = function () { return setWidth(window.innerWidth); };
        window.addEventListener('resize', handleResize);
        return function () { return window.removeEventListener('resize', handleResize); };
    }, []);
    return (_jsx(ImpContainer, __assign({ width: "100%", backgroundColor: width < 600 ? undefined : colors.surface.surfaceHigh, borderRadius: borderRadius.s }, { children: _jsx(ImpPad, __assign({ fluid: true, padding: "48px 0px", mobilePadding: "24px 0px" }, { children: _jsx(ImpFlex, __assign({ fluid: true, justify: "center" }, { children: _jsx(ImpProgressRing, __assign({}, cardBalanceRingConfig)) })) })) })));
}
