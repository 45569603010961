var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { autocompleteAddress } from '@imprint-payments/imprint-lib';
import { ImpButton, ImpContainer, ImpFlex, ImpFlexItem, ImpInput, ImpSelect, ImpSpacer, ImpText, } from '@imprint-payments/imprint-ui';
import { useEffect, useState } from 'react';
import { useAppSelector } from '../../app/hooks';
import { FORM_INPUT_WIDTH } from '../../assets/consts/const';
import { ValidationResponse, getShowError, getValidationString, handleValueChange, validateAddress, validateCity, validateZip, } from '../../utils/validateUtils';
export default function AddAddressPage(_a) {
    var title = _a.title, isSubmitting = _a.isSubmitting, autofillConsumerAddress = _a.autofillConsumerAddress, continueButtonStyle = _a.continueButtonStyle, analytics = _a.analytics, handleContinueClick = _a.handleContinueClick;
    var userInformation = useAppSelector(function (state) { return state.homeSelector.userInformation; });
    var _b = useState(''), street = _b[0], setStreet = _b[1];
    var _c = useState(ValidationResponse.empty), streetError = _c[0], setStreetError = _c[1];
    var _d = useState(''), street2 = _d[0], setStreet2 = _d[1];
    var _e = useState(ValidationResponse.empty), street2Error = _e[0], setStreet2Error = _e[1];
    var _f = useState(''), city = _f[0], setCity = _f[1];
    var _g = useState(ValidationResponse.empty), cityError = _g[0], setCityError = _g[1];
    var _h = useState(''), zip = _h[0], setZip = _h[1];
    var _j = useState(ValidationResponse.empty), zipError = _j[0], setZipError = _j[1];
    var _k = useState(''), state = _k[0], setState = _k[1];
    var _l = useState(ValidationResponse.empty), stateError = _l[0], setStateError = _l[1];
    var language = useAppSelector(function (state) { return state.languageSelector.languageLocalization; });
    useEffect(function () {
        var _a, _b, _c;
        var field = document.getElementById('street');
        // @ts-ignore-next-line
        var autocomplete = (_c = (_b = (_a = window.google) === null || _a === void 0 ? void 0 : _a.maps) === null || _b === void 0 ? void 0 : _b.places) === null || _c === void 0 ? void 0 : _c.Autocomplete;
        if (!field || !autocomplete)
            return;
        autocompleteAddress(field, autocomplete, function (address) {
            if (address) {
                var streetLine1 = address.streetLine1, streetLine2 = address.streetLine2, city_1 = address.city, state_1 = address.state, zipCode = address.zipCode;
                setStreet(streetLine1);
                setStreet2(streetLine2);
                setCity(city_1);
                setState(state_1);
                setZip(zipCode);
            }
        });
    }, []);
    useEffect(function () {
        if (autofillConsumerAddress) {
            setStreet(userInformation.address.streetLine1);
            setStreet2(userInformation.address.streetLine2);
            setCity(userInformation.address.city);
            setZip(userInformation.address.zipCode);
            setState(userInformation.address.state);
        }
    }, [autofillConsumerAddress, userInformation]);
    useEffect(function () {
        handleValueChange(validateAddress(street), setStreetError);
    }, [street]);
    useEffect(function () {
        handleValueChange(validateAddress(street2, true), setStreet2Error);
    }, [street2]);
    useEffect(function () {
        handleValueChange(validateCity(city), setCityError);
    }, [city]);
    useEffect(function () {
        handleValueChange(validateZip(zip), setZipError);
    }, [zip]);
    useEffect(function () {
        handleValueChange(validateAddress(state), setStateError);
    }, [state]);
    function getButtonState() {
        if (isSubmitting) {
            return 'submitting';
        }
        else if (streetError === ValidationResponse.valid &&
            street2Error === ValidationResponse.valid &&
            cityError === ValidationResponse.valid &&
            zipError === ValidationResponse.valid &&
            stateError === ValidationResponse.valid) {
            return 'enabled';
        }
        else {
            return 'disabled';
        }
    }
    return (_jsx(ImpContainer, __assign({ width: FORM_INPUT_WIDTH }, { children: _jsxs(ImpFlex, __assign({ fluid: true, direction: "column", align: "center" }, { children: [_jsx(ImpText, __assign({ typography: "headline2", mobileTypography: "headline3" }, { children: title })), _jsx(ImpSpacer, { height: 'xl' }), _jsxs(ImpFlex, __assign({ fluid: true, direction: "column", align: "flex-start", gap: "l" }, { children: [_jsx(ImpContainer, __assign({ width: "100%" }, { children: _jsx(ImpInput, { id: "street", label: language.street, showError: getShowError(streetError), errorMessage: getValidationString(streetError, language), value: street, onChange: function (e) { return setStreet(e.target.value); }, onBlur: function () {
                                    setStreetError(validateAddress(street));
                                }, isDisabled: isSubmitting, instrumentation: analytics === null || analytics === void 0 ? void 0 : analytics.streetAddress }) })), _jsx(ImpContainer, __assign({ width: "100%" }, { children: _jsx(ImpInput, { label: language.apartment, showError: getShowError(street2Error), errorMessage: getValidationString(street2Error, language), value: street2, onChange: function (e) { return setStreet2(e.target.value); }, onBlur: function () {
                                    setStreet2Error(validateAddress(street2, true));
                                }, isDisabled: isSubmitting, instrumentation: analytics === null || analytics === void 0 ? void 0 : analytics.apartment }) })), _jsx(ImpContainer, __assign({ width: "100%" }, { children: _jsx(ImpInput, { label: language.city, showError: getShowError(cityError), errorMessage: getValidationString(cityError, language), value: city, onChange: function (e) { return setCity(e.target.value); }, onBlur: function () {
                                    setCityError(validateCity(city));
                                }, isDisabled: isSubmitting, instrumentation: analytics === null || analytics === void 0 ? void 0 : analytics.city }) })), _jsxs(ImpContainer, __assign({ width: "100%" }, { children: [_jsxs(ImpFlex, __assign({ direction: "row", gap: "m", justify: "space-between" }, { children: [_jsx(ImpFlexItem, __assign({ flexGrow: 1 }, { children: _jsx(ImpInput, { label: language.zip, showError: getShowError(zipError), errorMessage: getValidationString(zipError, language), value: zip, onChange: function (e) { return setZip(e.target.value); }, onBlur: function () {
                                                    setZipError(validateZip(zip));
                                                }, maxLength: 5, isDisabled: isSubmitting, instrumentation: analytics === null || analytics === void 0 ? void 0 : analytics.zip }) })), _jsx(ImpFlexItem, __assign({ flexGrow: 1 }, { children: _jsx(ImpSelect, { label: language.state, showError: getShowError(stateError), errorMessage: getValidationString(stateError, language), onOptionChange: function (val) {
                                                    setState(val);
                                                }, defaultOption: { value: state, label: state }, options: language.stateOption.map(function (value) {
                                                    return { value: value, label: value };
                                                }), isDisabled: isSubmitting, instrumentation: analytics === null || analytics === void 0 ? void 0 : analytics.state }) }))] })), _jsx(ImpSpacer, { height: 'm' }), _jsx(ImpText, __assign({ typography: "body3", color: "#5E5D5C" }, { children: language.addressDisclaimer }))] })), _jsx(ImpContainer, __assign({ width: "100%" }, { children: _jsxs(ImpFlex, __assign({ fluid: true, direction: "column", align: "center" }, { children: [_jsx(ImpSpacer, { height: "xl" }), _jsx(ImpButton, __assign({ buttonStyle: continueButtonStyle || 'imprint', variant: "primary", state: getButtonState(), onClick: function () {
                                            handleContinueClick({
                                                streetLine1: street,
                                                streetLine2: street2,
                                                city: city,
                                                state: state,
                                                country: 'US',
                                                zipCode: zip,
                                            });
                                        } }, { children: language.continueBtn })), _jsx(ImpSpacer, { height: "xl" })] })) }))] }))] })) })));
}
