var _a;
import { createSlice } from '@reduxjs/toolkit';
export var FlyoutContext;
(function (FlyoutContext) {
    FlyoutContext[FlyoutContext["Activity"] = 0] = "Activity";
    FlyoutContext[FlyoutContext["UserMenu"] = 1] = "UserMenu";
})(FlyoutContext || (FlyoutContext = {}));
var initialState = {
    context: FlyoutContext.UserMenu,
    isVisible: false,
    isClosing: false,
    activity: {},
};
export var flyoutSlice = createSlice({
    name: 'flyout',
    initialState: initialState,
    reducers: {
        setFlyoutContext: function (state, action) {
            state.context = action.payload;
        },
        setIsFlyoutVisible: function (state, action) {
            state.isVisible = action.payload;
        },
        setFlyoutActivity: function (state, action) {
            state.activity = action.payload;
        },
        setIsClosing: function (state, action) {
            state.isClosing = action.payload;
        },
        resetFlyoutState: function (state) { return initialState; },
    },
});
export var setFlyoutContext = (_a = flyoutSlice.actions, _a.setFlyoutContext), setIsFlyoutVisible = _a.setIsFlyoutVisible, setFlyoutActivity = _a.setFlyoutActivity, setIsClosing = _a.setIsClosing, resetFlyoutState = _a.resetFlyoutState;
export default flyoutSlice.reducer;
export var closeFlyout = function (dispatch) {
    dispatch(setIsClosing(true));
    dispatch(setIsFlyoutVisible(false));
    setTimeout(function () {
        dispatch(setIsClosing(false));
    }, 100);
};
