var _a;
import { createSlice } from '@reduxjs/toolkit';
import { RewardRedemptionType } from '../types/RewardRedemptionType';
export var RewardsRedemptionPage;
(function (RewardsRedemptionPage) {
    RewardsRedemptionPage[RewardsRedemptionPage["ConfigureRedemption"] = 0] = "ConfigureRedemption";
    RewardsRedemptionPage[RewardsRedemptionPage["ConfirmRedemption"] = 1] = "ConfirmRedemption";
    RewardsRedemptionPage[RewardsRedemptionPage["UpdateAddress"] = 2] = "UpdateAddress";
    RewardsRedemptionPage[RewardsRedemptionPage["UpdateBank"] = 3] = "UpdateBank";
})(RewardsRedemptionPage || (RewardsRedemptionPage = {}));
var initialState = {
    page: RewardsRedemptionPage.ConfigureRedemption,
    redemptionType: RewardRedemptionType.Empty,
    redeemVal: '0',
};
var rewardsRedemptionSlice = createSlice({
    name: 'rewardsRedemption',
    initialState: initialState,
    reducers: {
        setRewardsRedemptionPage: function (state, action) {
            state.page = action.payload;
        },
        setRedemptionType: function (state, action) {
            state.redemptionType = action.payload;
        },
        setRedeemVal: function (state, action) {
            state.redeemVal = action.payload;
        },
        resetRewardsRedemption: function () { return initialState; },
    },
});
export var setRewardsRedemptionPage = (_a = rewardsRedemptionSlice.actions, _a.setRewardsRedemptionPage), setRedemptionType = _a.setRedemptionType, setRedeemVal = _a.setRedeemVal, resetRewardsRedemption = _a.resetRewardsRedemption;
export default rewardsRedemptionSlice.reducer;
