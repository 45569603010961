var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { BreakpointsPx, Icons, ImpButton, ImpContainer, ImpFlex, ImpFlexItem, ImpHidden, ImpIcon, ImpSpacer, ImpText, } from '@imprint-payments/imprint-ui';
import { useAppSelector } from '../../app/hooks';
import { selectLanguage } from '../../app/languageSelectors';
export function HeaderBanner(_a) {
    var primaryAction = _a.primaryAction, primaryActionText = _a.primaryActionText, primaryActionIcon = _a.primaryActionIcon, closeHeaderBanner = _a.closeHeaderBanner, headerText = _a.headerText;
    var language = useAppSelector(selectLanguage);
    return (_jsx(ImpContainer, __assign({ backgroundColor: "#232323", width: "100%", height: "70px" }, { children: _jsx(ImpFlex, __assign({ justify: "center", align: "center!important", fluid: true }, { children: _jsx(ImpContainer, __assign({ width: 'min(1140px, 100vw - 48px)' }, { children: _jsxs(ImpFlex, __assign({ direction: "row", justify: "space-between", align: "center" }, { children: [_jsx(ImpHidden, __assign({ hideBelow: BreakpointsPx.Mobile }, { children: _jsx(ImpText, __assign({ typography: "body2", color: "#FFFFFF" }, { children: headerText })) })), _jsx(ImpHidden, __assign({ hideAbove: BreakpointsPx.Mobile }, { children: _jsx(ImpButton, __assign({ variant: "inline", onClick: primaryAction }, { children: _jsx(ImpContainer, __assign({ textAlign: "left" }, { children: _jsx(ImpText, __assign({ typography: "label3", color: "#FFFFFF", underline: true }, { children: headerText })) })) })) })), _jsx(ImpContainer, { children: _jsxs(ImpFlex, __assign({ direction: "row", align: "center" }, { children: [_jsx(ImpFlexItem, __assign({ flex: "0 0 auto" }, { children: _jsx(ImpHidden, __assign({ hideBelow: BreakpointsPx.Mobile }, { children: _jsx(ImpButton, __assign({ variant: "secondary", buttonStyle: "color", color: "white", onClick: primaryAction, size: "hug", trailingIcon: primaryActionIcon }, { children: primaryActionText })) })) })), _jsx(ImpSpacer, { width: "xl" }), _jsx(ImpIcon, { onClick: closeHeaderBanner, ariaLabel: "close-banner-btn", stroke: "white", iconSrc: Icons.close })] })) })] })) })) })) })));
}
