var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { colors, Icons, ImpButton, ImpContainer, ImpFlex, ImpIcon, ImpImage, ImpPad, ImpSpacer, ImpSurface, ImpText, spacing, } from '@imprint-payments/imprint-ui';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { HomeScreen, setHomeScreen, setRevealCardNumber } from '../../app/homeSlice';
import { PARAM_SHOW_WPP } from '../../assets/consts/const';
import tapToPayImg from '../../assets/images/tapToPay.svg';
import useVirtualNoImg from '../../assets/images/useVirtualCard.svg';
import AddToWalletButton from '../../components/addToWalletButton/AddToWalletButton';
import { useStatsigClient } from '@statsig/react-bindings';
import { Gates } from '../../statsig/gates';
import { selectLanguage } from '../../app/languageSelectors';
import { useEffect } from 'react';
import { ANALYTICS } from '../../firebase/firebase';
import { EVENT } from '../../analytics/analyticsConsts';
function Header() {
    var language = useAppSelector(selectLanguage);
    return (_jsx(ImpContainer, __assign({ dataTestId: 'useCardToday-header', width: '100%', textAlign: 'center' }, { children: _jsx(ImpPad, __assign({ paddingTop: 'l', paddingBottom: 'l' }, { children: _jsx(ImpFlex, __assign({ fluid: true, direction: 'column', align: 'center' }, { children: _jsxs(ImpText, __assign({ typography: 'headline3' }, { children: [language.useYourCardToPay, " ", _jsx("br", {}), " ", language.inStoreToday] })) })) })) })));
}
function Section1() {
    var productAccount = useAppSelector(function (state) { return state.productAccountSelector; });
    var checkGate = useStatsigClient().checkGate;
    var language = useAppSelector(selectLanguage);
    return (_jsx(ImpContainer, __assign({ width: "100%" }, { children: _jsx(ImpSurface, __assign({ surfaceColor: colors.surface.surfaceHigh, fluid: true, hasBorderRadius: true }, { children: _jsx(ImpPad, __assign({ padding: spacing.l }, { children: _jsxs(ImpFlex, __assign({ direction: 'column', gap: spacing.s, align: 'center' }, { children: [_jsx(ImpPad, __assign({ padding: spacing.l }, { children: _jsx(ImpImage, { objectFit: 'contain', width: '100%', alt: '', src: tapToPayImg }) })), _jsx(ImpContainer, __assign({ textAlign: 'center' }, { children: _jsxs(ImpPad, __assign({ padding: spacing.l }, { children: [_jsx(ImpText, __assign({ typography: 'headline3' }, { children: language.useTapToPay })), _jsx(ImpSpacer, { height: spacing.xs }), _jsx(ImpText, __assign({ typography: 'body2', color: colors.content.onSurfaceVariant }, { children: language.downloadToUseTapToPay }))] })) })), checkGate(Gates.addToWallet) ? (_jsx(AddToWalletButton, { cardUUID: productAccount.virtualCard.cardUUID, onClick: function () {
                                //TODO: [WS] Analytics + break out of anonymous function
                                console.log('Add to wallet clicked');
                            }, onSuccess: function () {
                                //TODO: [WS] Analytics if needed + break out of anonymous function
                                console.log('Your card is added to wallet!');
                            } })) : (_jsx(ImpButton, __assign({ variant: 'primary', buttonStyle: 'branded', onClick: function () {
                                ANALYTICS.logEvent(EVENT.downloadAppClicked);
                                window.open('https://link.imprint.co/', '_blank');
                            } }, { children: language.downloadBtn })))] })) })) })) })));
}
function Section2(_a) {
    var closePage = _a.closePage;
    var dispatch = useAppDispatch();
    var language = useAppSelector(selectLanguage);
    var revealCardNumber = function () {
        ANALYTICS.logEvent(EVENT.viewVCNClicked);
        dispatch(setRevealCardNumber(true));
        dispatch(setHomeScreen(HomeScreen.CardDetails));
        closePage();
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    return (_jsx(ImpContainer, __assign({ width: "100%" }, { children: _jsx(ImpSurface, __assign({ surfaceColor: colors.surface.surfaceHigh, fluid: true, hasBorderRadius: true }, { children: _jsx(ImpPad, __assign({ padding: spacing.l }, { children: _jsxs(ImpFlex, __assign({ direction: 'column', gap: spacing.s, align: 'center' }, { children: [_jsx(ImpPad, __assign({ padding: spacing.l }, { children: _jsx(ImpImage, { objectFit: 'contain', width: '100%', alt: '', src: useVirtualNoImg }) })), _jsx(ImpContainer, __assign({ textAlign: 'center' }, { children: _jsxs(ImpPad, __assign({ padding: spacing.l }, { children: [_jsx(ImpText, __assign({ typography: 'headline3' }, { children: language.useYourVN })), _jsx(ImpSpacer, { height: spacing.xs }), _jsx(ImpText, __assign({ typography: 'body2', color: colors.content.onSurfaceVariant }, { children: language.immediateAccessToVN }))] })) })), _jsx(ImpButton, __assign({ variant: 'primary', buttonStyle: 'branded', onClick: revealCardNumber }, { children: language.viewYourVN }))] })) })) })) })));
}
function NavBar(props) {
    function closeClick() {
        ANALYTICS.logEvent(EVENT.useTapToPayClosed);
        props.close();
    }
    return (_jsx(ImpFlex, __assign({ fluid: true, direction: "row-reverse", align: "center" }, { children: _jsx(ImpPad, __assign({ padding: spacing.xs }, { children: _jsx(ImpContainer, __assign({ width: "56px", height: "56px" }, { children: _jsx(ImpFlex, __assign({ fluid: true, align: "center", justify: "center" }, { children: _jsx(ImpIcon, { iconSrc: Icons.close, size: "24px", onClick: closeClick }) })) })) })) })));
}
function UseCardToday(_a) {
    var closeUseCardToday = _a.closeUseCardToday;
    useEffect(function () {
        ANALYTICS.logEvent(EVENT.useTapToPayViewed);
    }, []);
    var close = function () {
        sessionStorage.setItem(PARAM_SHOW_WPP, '');
        closeUseCardToday();
    };
    return (_jsxs(ImpFlex, __assign({ fluid: true, direction: "column" }, { children: [_jsxs(ImpContainer, __assign({ width: "100%" }, { children: [_jsx(NavBar, { close: close }), _jsxs(ImpPad, __assign({ paddingLeft: 'l', paddingRight: 'l' }, { children: [_jsx(Header, {}), _jsx(Section1, {}), _jsx(ImpSpacer, { height: spacing.s }), _jsx(Section2, { closePage: close })] }))] })), _jsx(ImpSpacer, { height: spacing.xl })] })));
}
export default UseCardToday;
