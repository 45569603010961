var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ImpButton, ImpContainer, ImpFlex, ImpSpacer, ImpText, } from '@imprint-payments/imprint-ui';
import { useAppSelector } from '../../app/hooks';
import { FORM_BUTTON_WIDTH } from '../../assets/consts/const';
export function ErrorPage() {
    var language = useAppSelector(function (state) { return state.languageSelector.languageLocalization; });
    return (_jsx(ImpContainer, __assign({ textAlign: "center" }, { children: _jsxs(ImpFlex, __assign({ fluid: true, direction: "column", align: "center" }, { children: [_jsx(ImpText, __assign({ typography: "headline2", mobileTypography: "headline3" }, { children: language.sorryExperiencingIssues })), _jsx(ImpSpacer, { height: 'xl' }), _jsx(ImpText, __assign({ typography: "body2", color: "#5E5D5C" }, { children: language.errorPageMessage })), _jsx(ImpSpacer, { height: 'xl' }), _jsx(ImpContainer, __assign({ width: FORM_BUTTON_WIDTH }, { children: _jsx(ImpButton, __assign({ size: "fill", onClick: function () {
                            window.location.reload();
                        } }, { children: language.errorPageCTA })) }))] })) })));
}
