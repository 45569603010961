var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { ImpButton } from '@imprint-payments/imprint-ui';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { ConfirmationScreen } from './ConfirmationScreen';
import { setSelectedAuthorizedUser } from '../../app/authUsersSlice';
import { getEmptyAuthorizedUser } from '../../types/AuthorizedUser';
import { navigateToAccountPage } from '../../utils/functionUtils';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
export function AuthUserDeactivated() {
    var language = useAppSelector(function (state) { return state.languageSelector.languageLocalization; });
    var dispatch = useAppDispatch();
    var navigate = useNavigate();
    useEffect(function () {
        return function () {
            dispatch(setSelectedAuthorizedUser(getEmptyAuthorizedUser()));
        };
    }, []);
    return (_jsx(ConfirmationScreen, { title: language.authUserDeactivatedTitle, subtitle: language.authUserDeactivatedSubtitle, actionButton: _jsx(ImpButton, __assign({ size: "fill", buttonStyle: "branded", variant: "primary", onClick: function () {
                navigateToAccountPage(dispatch, navigate);
            } }, { children: language.done })) }));
}
