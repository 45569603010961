import { getEmptyAmount } from './Amount';
export var getEmptyCardTerms = function () {
    return {
        apr: '',
        creditLimit: getEmptyAmount(),
        lateFee: '',
        returnedFee: '',
        annualFee: '',
        internationalFee: '',
    };
};
