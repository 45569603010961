import { useEffect } from 'react';
import { getCardBack, getCardBenefitsImage, getColor, getRewardDetails } from '../api/api';
import { selectProductAccounts, selectSelectedProductAccount } from '../app/homeSelectors';
import { ProductType, setSelectedProductAccount } from '../app/homeSlice';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { selectMerchantData } from '../app/merchantDataSelectors';
import { COOKIEKEY } from './cookieConstants';
import { getCookieValueFor, removeCookieFor } from './functionUtils';
export var useSelectedProductAccount = function () {
    var selectedProductAccount = useAppSelector(selectSelectedProductAccount);
    var merchantData = useAppSelector(selectMerchantData);
    var productAccounts = useAppSelector(selectProductAccounts);
    var dispatch = useAppDispatch();
    // TODO: this is legacy, and doesn't make a ton of sense
    var isProductAccountToShow = function (productAccount) {
        if (getCookieValueFor(COOKIEKEY.ISMULTICARD) === 'true' && merchantData.productUUID !== '') {
            return productAccount.productUUID === merchantData.productUUID;
        }
        else {
            return true;
        }
    };
    useEffect(function () {
        var lastSelected = localStorage.getItem('imprint:productUUID');
        if (!selectedProductAccount) {
            var creditProductAccounts = productAccounts.filter(function (productAccount) { return productAccount.productType === ProductType.CreditCard; });
            // if last selected, we want to try to show that one
            // ex. if a user refreshes the page
            if (lastSelected) {
                var productAccount = creditProductAccounts.find(function (pa) { return pa.productUUID === lastSelected; });
                if (productAccount && isProductAccountToShow(productAccount)) {
                    dispatch(setSelectedProductAccount(productAccount));
                    return;
                }
            }
            creditProductAccounts.forEach(function (productAccount) {
                if (isProductAccountToShow(productAccount)) {
                    var productUUID = productAccount.productUUID;
                    if (productUUID) {
                        dispatch(getRewardDetails(productUUID));
                        dispatch(getCardBenefitsImage(productUUID));
                        dispatch(getColor(productUUID));
                        dispatch(getCardBack(productUUID));
                        dispatch(setSelectedProductAccount(productAccount));
                        removeCookieFor(COOKIEKEY.ISMULTICARD);
                    }
                }
            });
        }
    }, [productAccounts, selectedProductAccount]);
};
