var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { EVENT, PKG } from '../analytics/analyticsConsts';
import { setDeviceID } from '../app/apiScreenSlice';
import { setLoading } from '../app/applicationScreenSlice';
import { setAuth } from '../app/authSlice';
import { setActions, setConsumerProfile, setHasSpendCardOnly, setMultiCardUser, setNotAcceptedOffer, } from '../app/consumerInfoSlice';
import { ACCOUNT_STATUS, ProductType, setHasCreditCard, setHasSpendCard, setPaymentRequirements, setPrimaryPaymentAccount, setProductAccounts, setUserInformation, } from '../app/homeSlice';
import { setLanguageChangedBeforeLogin } from '../app/languageSlice';
import { setProductUUID, setRewardDetails } from '../app/merchantDataSlice';
import { SCREEN, setScreen } from '../app/screenStateSlice';
import { store } from '../app/store';
import { ANALYTICS } from '../firebase/firebase';
import { clearServiceLoginState, getNewDeviceID } from '../utils/functionUtils';
import { getAccountLinkModalBackgroundImg, getAccountLinkModalLogo, getCardBack, getCardBenefitsImage, getCardImgForProductAccounts, getCardOfferImg, getColor, getConfirmLaterQR, getLogoIcon, getOfferDetails, getRewardDetails, home, } from './api';
import { ACTION_TYPE, validAccountLinkMerchants } from './apiConstants';
import { getVirtualCard } from './cardDesign/cardDesign';
import { getVirtualCardDisabled } from './cardDesign/getVirtualCardDisabled';
import { SetBackendLanguage } from './setLanguage';
export var loadHome = function () {
    return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
        var auth, navigate, consumerProfile, token, userInfo, featureFlags, accountLinkEnabled, selectedProductAccount, rewardDetails, deviceID, _a, homeResponse, homeError, productUUID, allInReview, inReviewProductUUIDs, languageChanged, _b, _, error, activeCardList, applicationUUID, productAccountsWithOfferPending, productUUID;
        var _c, _d, _e, _f;
        return __generator(this, function (_g) {
            switch (_g.label) {
                case 0:
                    dispatch(setLoading(true));
                    auth = getState().authSlice;
                    navigate = getState().apiScreenScreenSelector.navigate;
                    consumerProfile = getState().consumerInfoSelector.consumerProfile;
                    token = auth.token;
                    userInfo = getState().homeSelector.userInformation;
                    featureFlags = getState().featureFlagSelector.featureFlags;
                    accountLinkEnabled = featureFlags.accountLinkEnabled === 'true';
                    selectedProductAccount = getState().homeSelector.selectedProductAccount;
                    if (getState().apiScreenScreenSelector.deviceID === '') {
                        dispatch(setDeviceID(getNewDeviceID()));
                    }
                    rewardDetails = getState().merchantDataSelector.rewardDetails;
                    deviceID = getState().apiScreenScreenSelector.deviceID;
                    return [4 /*yield*/, home(token, deviceID)];
                case 1:
                    _a = _g.sent(), homeResponse = _a[0], homeError = _a[1];
                    if (homeError || !homeResponse) {
                        clearServiceLoginState(dispatch);
                        return [2 /*return*/];
                    }
                    if ((homeResponse === null || homeResponse === void 0 ? void 0 : homeResponse.hasSpendCard) && !(homeResponse === null || homeResponse === void 0 ? void 0 : homeResponse.hasCreditCard)) {
                        dispatch(setHasSpendCardOnly(true));
                        dispatch(setScreen(SCREEN.DOWNLOAD_APP));
                        return [2 /*return*/];
                    }
                    dispatch(setHasCreditCard(homeResponse === null || homeResponse === void 0 ? void 0 : homeResponse.hasCreditCard));
                    dispatch(setHasSpendCard(homeResponse === null || homeResponse === void 0 ? void 0 : homeResponse.hasSpendCard));
                    if (parseActions(homeResponse === null || homeResponse === void 0 ? void 0 : homeResponse.actions)) {
                        if (homeResponse.actions[0].type == ACTION_TYPE.SHOW_EXPIRED_APPLICATION_SCREEN) {
                            productUUID = homeResponse.actions[0].params.productUUID;
                            dispatch(getColor(productUUID));
                            dispatch(setProductUUID(productUUID));
                            ANALYTICS.logEvent(EVENT.SERVICING_DECISION_EXPIRED_VIEWED, {
                                product_uuid: homeResponse.actions[0].params.productUUID,
                            });
                            dispatch(dispatch(setRewardDetails(__assign(__assign({}, rewardDetails), { cardName: homeResponse.creditAccount.statusInfoParams.cardName }))));
                            dispatch(setScreen(SCREEN.APPLICATION_EXPIRED));
                            dispatch(setLoading(false));
                            return [2 /*return*/];
                        }
                        else if (homeResponse.actions[0].type === ACTION_TYPE.SHOW_REJECTION_SCREEN) {
                            ANALYTICS.logEvent(EVENT.SERVICING_DECISION_DENIED_VIEWED, {
                                product_uuid: homeResponse.actions[0].params.productUUID,
                            });
                            dispatch(setProductUUID(homeResponse.actions[0].params.productUUID));
                            dispatch(getColor(homeResponse.actions[0].params.productUUID));
                            dispatch(setRewardDetails(__assign(__assign({}, rewardDetails), { cardName: (_c = homeResponse.creditAccount) === null || _c === void 0 ? void 0 : _c.statusInfoParams.cardName })));
                            if (((_d = homeResponse.creditAccount) === null || _d === void 0 ? void 0 : _d.canApply) === true) {
                                dispatch(setScreen(SCREEN.APPLICATION_NOT_APPROVED_AFTER_COOLDOWN));
                            }
                            else {
                                dispatch(setScreen(SCREEN.APPLICATION_NOT_APPROVED_WITHIN_COOLDOWN));
                            }
                            dispatch(setLoading(false));
                            return [2 /*return*/];
                        }
                    }
                    if (!(homeResponse === null || homeResponse === void 0 ? void 0 : homeResponse.userInformation.email)) {
                        // If user dropped during onboarding and hasn't setup an email,
                        // redirect them to account setup when they attempt to load home
                        dispatch(setScreen(SCREEN.CONFIRM_EMAIL));
                        return [2 /*return*/];
                    }
                    dispatch(setUserInformation(__assign(__assign({}, userInfo), { address: homeResponse === null || homeResponse === void 0 ? void 0 : homeResponse.userInformation.address, firstName: homeResponse === null || homeResponse === void 0 ? void 0 : homeResponse.userInformation.firstName, lastName: homeResponse === null || homeResponse === void 0 ? void 0 : homeResponse.userInformation.lastName, memberSince: homeResponse === null || homeResponse === void 0 ? void 0 : homeResponse.userInformation.memberSince, phone: homeResponse === null || homeResponse === void 0 ? void 0 : homeResponse.userInformation.phone, email: homeResponse === null || homeResponse === void 0 ? void 0 : homeResponse.userInformation.email, addressDaysUntilEditable: homeResponse === null || homeResponse === void 0 ? void 0 : homeResponse.userInformation.addressDaysUntilEditable })));
                    dispatch(setPrimaryPaymentAccount(homeResponse === null || homeResponse === void 0 ? void 0 : homeResponse.primaryPaymentAccount));
                    allInReview = homeResponse.productAccounts.every(function (productAccount) { return productAccount.productAccountStatus === ACCOUNT_STATUS.MANUAL_REVIEW; });
                    if (allInReview) {
                        inReviewProductUUIDs = homeResponse.productAccounts.map(function (productAccount) { return productAccount.productUUID; });
                        ANALYTICS.logEvent(EVENT.SERVICING_DECISION_IN_REVIEW_VIEWED, {
                            product_uuid: inReviewProductUUIDs,
                        });
                        dispatch(setScreen(SCREEN.APPLICATION_IN_REVIEW));
                        dispatch(setLoading(false));
                        return [2 /*return*/];
                    }
                    dispatch(setProductAccounts(homeResponse.productAccounts));
                    if (homeResponse.productAccounts && homeResponse.productAccounts.length > 1) {
                        dispatch(setMultiCardUser(true));
                    }
                    if (homeResponse === null || homeResponse === void 0 ? void 0 : homeResponse.hasCreditCard) {
                        dispatch(setPaymentRequirements(homeResponse === null || homeResponse === void 0 ? void 0 : homeResponse.creditBalances.paymentRequirements));
                    }
                    languageChanged = store.getState().languageSelector.languageChangedBeforeLogin;
                    if (!languageChanged) return [3 /*break*/, 3];
                    return [4 /*yield*/, SetBackendLanguage()];
                case 2:
                    _b = _g.sent(), _ = _b[0], error = _b[1];
                    if (error) {
                        ANALYTICS.funcError(PKG, 'error setting language on login', String(error));
                    }
                    dispatch(setLanguageChangedBeforeLogin(false));
                    _g.label = 3;
                case 3:
                    if (!(selectedProductAccount === null || selectedProductAccount === void 0 ? void 0 : selectedProductAccount.productUUID)) return [3 /*break*/, 7];
                    dispatch(getRewardDetails(selectedProductAccount === null || selectedProductAccount === void 0 ? void 0 : selectedProductAccount.productUUID));
                    dispatch(getCardBenefitsImage(selectedProductAccount === null || selectedProductAccount === void 0 ? void 0 : selectedProductAccount.productUUID));
                    dispatch(getColor(selectedProductAccount === null || selectedProductAccount === void 0 ? void 0 : selectedProductAccount.productUUID));
                    dispatch(getCardBack(selectedProductAccount === null || selectedProductAccount === void 0 ? void 0 : selectedProductAccount.productUUID));
                    dispatch(setProductUUID(selectedProductAccount === null || selectedProductAccount === void 0 ? void 0 : selectedProductAccount.productUUID));
                    return [4 /*yield*/, dispatch(getLogoIcon(selectedProductAccount === null || selectedProductAccount === void 0 ? void 0 : selectedProductAccount.productUUID))];
                case 4:
                    _g.sent();
                    if (!(accountLinkEnabled &&
                        validAccountLinkMerchants.includes(selectedProductAccount === null || selectedProductAccount === void 0 ? void 0 : selectedProductAccount.productUUID))) return [3 /*break*/, 7];
                    return [4 /*yield*/, dispatch(getAccountLinkModalBackgroundImg(selectedProductAccount === null || selectedProductAccount === void 0 ? void 0 : selectedProductAccount.productUUID))];
                case 5:
                    _g.sent();
                    return [4 /*yield*/, dispatch(getAccountLinkModalLogo(selectedProductAccount === null || selectedProductAccount === void 0 ? void 0 : selectedProductAccount.productUUID))];
                case 6:
                    _g.sent();
                    _g.label = 7;
                case 7:
                    activeCardList = homeResponse.productAccounts.filter(function (productAccount) {
                        return productAccount.productType !== ProductType.SpendCard &&
                            productAccount.productAccountStatus === ACCOUNT_STATUS.ACTIVE;
                    });
                    // Get the card images for multi-card experience
                    dispatch(getCardImgForProductAccounts(homeResponse.productAccounts));
                    if (!parseActions(homeResponse === null || homeResponse === void 0 ? void 0 : homeResponse.actions)) return [3 /*break*/, 18];
                    applicationUUID = (_f = (_e = homeResponse === null || homeResponse === void 0 ? void 0 : homeResponse.actions[0]) === null || _e === void 0 ? void 0 : _e.params) === null || _f === void 0 ? void 0 : _f.applicationUUID;
                    if (!applicationUUID || applicationUUID === '') {
                        ANALYTICS.funcError(PKG, 'loadHome - get applicationUUID for new card', JSON.stringify(homeResponse === null || homeResponse === void 0 ? void 0 : homeResponse.actions));
                        return [2 /*return*/];
                    }
                    productAccountsWithOfferPending = homeResponse.productAccounts.filter(function (productAccount) {
                        return productAccount.productAccountStatus === 'OFFER_PENDING' &&
                            productAccount.productUUID === (homeResponse === null || homeResponse === void 0 ? void 0 : homeResponse.actions[0].params.productUUID);
                    });
                    if (productAccountsWithOfferPending.length == 0) {
                        ANALYTICS.funcError(PKG, 'loadHome - no action with matching productUUID', JSON.stringify(homeResponse === null || homeResponse === void 0 ? void 0 : homeResponse.actions));
                        return [2 /*return*/];
                    }
                    productUUID = productAccountsWithOfferPending[0].productUUID;
                    if (!(selectedProductAccount === undefined ||
                        (selectedProductAccount === null || selectedProductAccount === void 0 ? void 0 : selectedProductAccount.productUUID) === productUUID)) return [3 /*break*/, 18];
                    dispatch(setNotAcceptedOffer(true));
                    dispatch(setProductAccounts(homeResponse.productAccounts));
                    dispatch(setActions(homeResponse === null || homeResponse === void 0 ? void 0 : homeResponse.actions));
                    dispatch(setProductUUID(productUUID));
                    dispatch(setAuth(__assign(__assign({}, auth), { applicationUUID: applicationUUID })));
                    dispatch(setConsumerProfile(__assign(__assign({}, consumerProfile), { address: homeResponse.userInformation.address })));
                    return [4 /*yield*/, dispatch(getColor(productUUID))];
                case 8:
                    _g.sent();
                    return [4 /*yield*/, dispatch(getLogoIcon(productUUID))];
                case 9:
                    _g.sent();
                    if (!(accountLinkEnabled && validAccountLinkMerchants.includes(productUUID))) return [3 /*break*/, 12];
                    return [4 /*yield*/, dispatch(getAccountLinkModalBackgroundImg(productUUID))];
                case 10:
                    _g.sent();
                    return [4 /*yield*/, dispatch(getAccountLinkModalLogo(productUUID))];
                case 11:
                    _g.sent();
                    _g.label = 12;
                case 12: return [4 /*yield*/, dispatch(getRewardDetails(productUUID))];
                case 13:
                    _g.sent();
                    return [4 /*yield*/, dispatch(getCardBenefitsImage(productUUID))];
                case 14:
                    _g.sent();
                    return [4 /*yield*/, dispatch(getCardOfferImg(productUUID))];
                case 15:
                    _g.sent();
                    return [4 /*yield*/, dispatch(getConfirmLaterQR(productUUID))];
                case 16:
                    _g.sent();
                    return [4 /*yield*/, dispatch(getOfferDetails({ applicationUUID: applicationUUID }))];
                case 17:
                    _g.sent();
                    getVirtualCard({ productUUID: productUUID });
                    getVirtualCardDisabled({ productUUID: productUUID });
                    dispatch(setScreen(SCREEN.OFFER));
                    ANALYTICS.logEvent(EVENT.SERVICING_DECISION_PREAPPROVED_VIEWED, {
                        product_uuid: productUUID,
                    });
                    return [2 /*return*/];
                case 18:
                    dispatch(setNotAcceptedOffer(false));
                    dispatch(setLoading(false));
                    dispatch(setScreen(SCREEN.ACCOUNT));
                    return [2 /*return*/];
            }
        });
    }); };
};
var parseActions = function (actions) {
    if (!actions || actions.length === 0) {
        return null;
    }
    // check if action type is ""
    if (actions[0].type === '') {
        return null;
    }
    return actions;
};
