import { jsx as _jsx } from "react/jsx-runtime";
import { EVENT } from '../../analytics/analyticsConsts';
import { generateUpdateEmailOtp } from '../../api/PersonalInfo/UpdateEmail/generateUpdateEmailOtp';
import { verifyUpdateEmailOtp } from '../../api/PersonalInfo/UpdateEmail/verifyUpdateEmailOtp';
import { confirmPhone } from '../../api/findAccount/confirmPhone.api';
import { verifyServicingOtp } from '../../api/verifyServicingOtp';
import { selectNewEmail, selectPhone } from '../../app/accountOnboardingSelectors';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { SCREEN, setScreen } from '../../app/screenStateSlice';
import { OtpCard } from '../../components/otp/OtpCard';
var analyticsEvents = {
    phone: {
        viewed: EVENT.LOGIN_OTP_VIEWED,
        submitted: EVENT.LOGIN_OTP_SUBMITTED,
        incorrect: EVENT.LOGIN_OTP_TRY_AGAIN_VIEWED,
        resend: EVENT.LOGIN_OTP_SEND_CODE_AGAIN_CLICKED,
        callInstead: EVENT.LOGIN_OTP_SEND_VOICE_CODE_CLICKED,
        callAgain: EVENT.LOGIN_OTP_SEND_VOICE_CODE_AGAIN_CLICKED,
    },
    email: {
        viewed: EVENT.CONFIRM_EMAIL_OTP_VIEWED,
        submitted: EVENT.CONFIRM_EMAIL_OTP_SUBMITTED,
        incorrect: EVENT.CONFIRM_EMAIL_OTP_TRY_AGAIN_VIEWED,
        resend: EVENT.CONFIRM_EMAIL_OTP_RESEND_CLICKED,
    },
};
export function FindAccountOtpPage(_a) {
    var method = _a.method, onBackClick = _a.onBackClick;
    var dispatch = useAppDispatch();
    var phone = useAppSelector(selectPhone);
    var email = useAppSelector(selectNewEmail);
    var submitOtpHandler = function (code) {
        switch (method) {
            case 'phone':
                dispatch(verifyServicingOtp({ code: code }));
                return;
            case 'email':
                dispatch(verifyUpdateEmailOtp(code, function () {
                    dispatch(setScreen(SCREEN.GO_PAPERLESS));
                }));
                return;
        }
    };
    var resendOtpHandler = function (sendVoice) {
        switch (method) {
            case 'phone':
                confirmPhone({ phone: phone, sendVoice: sendVoice });
                return;
            case 'email':
                dispatch(generateUpdateEmailOtp(email));
                return;
        }
    };
    return (_jsx(OtpCard, { onFull: submitOtpHandler, handleGoBack: onBackClick, handleResend: resendOtpHandler, events: analyticsEvents[method] }));
}
