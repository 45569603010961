/**
 * This file is part of the integration with the Google Pay Web Push Provisioning API.
 * For full API documentation, refer to:
 * https://developers.google.com/pay/issuers/apis/push-provisioning/web/frontend-javascript-api
 */
import '../../lib/googlepay-wpp';
import { provisionGoogleWallet } from './provisionGoogleWallet';
export function launchGooglePayWebApp(cardUUID, integratorId, handleSuccess, handleError) {
    var _a;
    (_a = window.googlepay) === null || _a === void 0 ? void 0 : _a.openAppWindow({
        integratorId: integratorId,
        tokenSetting: 1,
        cardSetting: 1,
        isTestEnvironment: false,
        onSessionCreated: function (payload) {
            console.log('onSessionCreated called');
            console.log('Sending payload to server:', JSON.stringify(payload));
            provisionGoogleWallet({
                cardUUID: cardUUID,
                integratorId: integratorId,
                payload: payload,
                onSuccess: function () {
                    //TODO: [WS] log for debug only. Can be removed once BE is tested.
                    console.log('Imprint BE google wpp success. ');
                },
                onError: function () {
                    //TODO: [WS] log for debug only. Can be removed once BE is tested.
                    console.log('Imprint BE google wpp error. ');
                    handleError();
                },
            });
        },
        onSuccess: function (payload) {
            console.log('onSuccess:', JSON.stringify(payload));
            handleSuccess();
        },
        onFailure: function (payload) {
            console.error('onFailure:', JSON.stringify(payload));
            handleError();
        },
        onCancel: function () {
            //TODO: [WS] For test only. Remove after e2e flow can succeed.
            console.log("onCancel: pretend it's an error");
            handleError();
        },
    });
}
