var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ImpButton, ImpContainer, ImpFlex, ImpModal, ImpPad, ImpText, ImpTracker, colors, useModal, } from '@imprint-payments/imprint-ui';
import { useProgressBar } from '@imprint-payments/imprint-ui';
import { selectSelectedProductAccount } from '../../app/homeSelectors';
import { useAppSelector } from '../../app/hooks';
import { epochToMMDD, getPhysicalCardWidgetConfig } from './physicalCardWidgetHelpers';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { selectLanguage } from '../../app/languageSelectors';
import { selectPhysicalCardUUID, selectPhysicalCardWidget, } from '../../app/productAccountSelectors';
import { ANALYTICS } from '../../firebase/firebase';
import { ShippingWidgetState } from '../../types/ShippingWidget';
import { PAGE } from '../../utils/routeConstant';
import { NeverReceivedCardModal } from './NeverReceivedCardModal';
export function PhysicalCardWidget() {
    var state = useAppSelector(selectPhysicalCardWidget).state;
    var language = useAppSelector(selectLanguage);
    var config = getPhysicalCardWidgetConfig(language);
    var _a = useProgressBar(config, -1), currentMainStep = _a.currentMainStep, moveForward = _a.moveForward;
    var _b = useModal(false), isOpen = _b[0], isClosing = _b[1], openModal = _b[2], closeModal = _b[3];
    useEffect(function () {
        // adjust position based on state
        if (state === ShippingWidgetState.Ordered) {
            moveForward();
        }
        if ([ShippingWidgetState.Shipped, ShippingWidgetState.NotReceived].includes(state)) {
            moveForward();
            moveForward();
        }
    }, [state]);
    return (_jsxs(ImpContainer, __assign({ width: "100%", borderRadius: "12px", backgroundColor: colors.surface.surfaceHigh }, { children: [_jsx(ImpModal, __assign({ isOpen: isOpen, isClosing: isClosing, closeModal: closeModal }, { children: _jsx(NeverReceivedCardModal, { closeModal: closeModal }) })), _jsx(ImpPad, __assign({ fluid: true, padding: "24px" }, { children: _jsxs(ImpFlex, __assign({ direction: "column", justify: "flex-start", fluid: true, gap: "m" }, { children: [_jsxs(ImpFlex, __assign({ fluid: true, direction: "column", gap: "s" }, { children: [_jsx(PhysicalCardWidgetHeader, {}), _jsx(ImpContainer, __assign({ width: "100%", zIndex: 0 }, { children: _jsx(ImpTracker, { config: config, currentMainStep: currentMainStep }) }))] })), _jsx(PhysicalCardWidgetButtons, { openModal: openModal })] })) }))] })));
}
function PhysicalCardWidgetHeader() {
    var _a;
    var language = useAppSelector(selectLanguage);
    var selectedProductAccount = useAppSelector(selectSelectedProductAccount);
    var _b = useAppSelector(selectPhysicalCardWidget), state = _b.state, deliveryDate = _b.deliveryDate;
    var shouldShowDeliveryDate = [
        ShippingWidgetState.Shipped,
        ShippingWidgetState.NotReceived,
    ].includes(state);
    var textMap = (_a = {},
        _a[ShippingWidgetState.Init] = language.cardTeaser,
        _a[ShippingWidgetState.Ordered] = language.orderProcessing,
        _a[ShippingWidgetState.Shipped] = language.estimatedDeliveryLabel,
        _a[ShippingWidgetState.NotReceived] = language.estimatedDeliveryLabel,
        _a);
    var leadingText = textMap[state];
    if (!deliveryDate && shouldShowDeliveryDate) {
        return _jsx(_Fragment, {});
    }
    return (_jsxs(ImpFlex, __assign({ fluid: true, justify: "space-between" }, { children: [leadingText && _jsx(ImpText, { children: leadingText }), deliveryDate && shouldShowDeliveryDate && (_jsx(ImpText, __assign({ typography: "label2" }, { children: epochToMMDD(deliveryDate) })))] })));
}
function PhysicalCardWidgetButtons(_a) {
    var openModal = _a.openModal;
    var language = useAppSelector(selectLanguage);
    var state = useAppSelector(selectPhysicalCardWidget).state;
    var physicalCardUUID = useAppSelector(selectPhysicalCardUUID);
    var navigate = useNavigate();
    var handleOrderCardClicked = function () {
        ANALYTICS.logEvent('order_card_clicked');
        navigate(PAGE.GET_PHYSICAL_CARD);
    };
    var handleActivateCardClicked = function () {
        ANALYTICS.logEvent('activate_card_clicked', {
            card_uuid: physicalCardUUID,
        });
        navigate(PAGE.ACTIVATE_PHYSICAL_CARD);
    };
    var handleNeverReceivedClicked = function () {
        ANALYTICS.logEvent('card_not_received_clicked', {
            card_uuid: physicalCardUUID,
        });
        openModal();
    };
    switch (state) {
        case ShippingWidgetState.Init:
            return (_jsx(ImpButton, __assign({ size: "fill", variant: "primary", buttonStyle: "branded", onClick: handleOrderCardClicked }, { children: language.orderCardLabel })));
        case ShippingWidgetState.Ordered:
            return _jsx(_Fragment, {});
        case ShippingWidgetState.Shipped:
            return (_jsx(ImpButton, __assign({ size: "fill", buttonStyle: "branded", onClick: handleActivateCardClicked }, { children: language.activateCardLabel })));
        case ShippingWidgetState.NotReceived:
            return (_jsxs(ImpFlex, __assign({ direction: "row", gap: "s", fluid: true, align: "center", mobileDirection: "column-reverse", mobileGap: "xl" }, { children: [_jsx(ImpButton, __assign({ size: "fill", buttonStyle: "branded", variant: "text", onClick: handleNeverReceivedClicked }, { children: language.neverReceivedCardLabel })), _jsx(ImpButton, __assign({ size: "fill", buttonStyle: "branded", onClick: handleActivateCardClicked }, { children: language.activateCardLabel }))] })));
        default:
            return null;
    }
}
